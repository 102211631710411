import { systemConstants } from "../_constants";
import axios from "axios";
import * as urls from "../../components/maps/urls";

export async function getMapAlertsAndMapItems(url, secret) {
  let data = null;

  let alertsReceived = await axios
    .get(url)
    .then((res) => {
      data = res.data;
      console.log("getMapAlertsAndMapItems retrieved");
      console.log(data);
    })
    .catch((err) => {
      console.log("FAILURE data!!");
      console.log(err);
    });

  //let dataRes = await fetch(url, getNoCache);
  //let data = await dataRes.json();
  // console.log(url)
  // //console.log(data)

  // let object = data

  // var objectList = [];
  // var stack = [ object ];
  // var bytes = 0;

  // while ( stack.length ) {
  //     var value = stack.pop();

  //     if ( typeof value === 'boolean' ) {
  //         bytes += 4;
  //     }
  //     else if ( typeof value === 'string' ) {
  //         bytes += value.length * 2;
  //     }
  //     else if ( typeof value === 'number' ) {
  //         bytes += 8;
  //     }
  //     else if
  //     (
  //         typeof value === 'object'
  //         && objectList.indexOf( value ) === -1
  //     )
  //     {
  //         objectList.push( value );

  //         for( var i in value ) {
  //             stack.push( value[ i ] );
  //         }
  //     }
  // }
  // console.log("THE SIZE of map alerts is "+bytes)

  // let dataLayer = new window.google.maps.Data();
  // console.log(data)
  // if(data){
  // dataLayer.addGeoJson(data);
  // }

  return data;
}

export async function getMapAlertsAndMapItemsWithLocations(
  url,
  locations,
  secret
) {
  let result = null;

  let data = { location_list: locations };

  await axios({ method: "post", url: url, data: data })
    .then((res) => {
      console.log("SUCCESS!!");
      //console.log(res.data)
      console.log("alerts retrieved from the database.");

      result = res.data;
    })
    .catch((err) => {
      console.log("FAILURE!!");
      console.log(err);

      result = null;
    });

  //let dataRes = await fetch(url, getNoCache);
  //let data = await dataRes.json();
  console.log(url);
  //console.log(data)

  let object = result;

  var objectList = [];
  var stack = [object];
  var bytes = 0;

  while (stack.length) {
    var value = stack.pop();

    if (typeof value === "boolean") {
      bytes += 4;
    } else if (typeof value === "string") {
      bytes += value.length * 2;
    } else if (typeof value === "number") {
      bytes += 8;
    } else if (typeof value === "object" && objectList.indexOf(value) === -1) {
      objectList.push(value);

      for (var i in value) {
        stack.push(value[i]);
      }
    }
  }
  console.log("THE SIZE of map alerts is " + bytes);

  // let dataLayer = new window.google.maps.Data();
  // console.log(data)
  // if(data){
  // dataLayer.addGeoJson(data);
  // }
  return result;
}

export async function getMapRiskLocations(locations, secret, preferences) {
  let payload = null;

  console.log(preferences);

  let data = { location_list: locations };

  if (
    preferences &&
    (preferences.food_hygiene == true || preferences.food_hygiene == "True")
  ) {
    data.food_hygiene = true;
  }
  if (
    preferences &&
    (preferences.pandemic == true || preferences.pandemic == "True")
  ) {
    data.pandemic = true;
  }

  await axios({
    method: "post",
    url: urls.API_GATEWAY + "locations/property-by-risk",
    data: data,
  })
    .then((res) => {
      console.log(res.data);
      payload = res.data;

      if (
        payload === "null" ||
        payload === null ||
        payload === "Failure" ||
        typeof payload === "undefined"
      ) {
        payload = [];
        console.log("Properties risk getting ERROR");
      } else if (payload === "Invalid Details") {
        console.log(
          "Credentials for getting Properties risk, details are INVALID"
        );
        payload = [];
      } else if (payload === "No properties found") {
        console.log("No properties were found!");
        payload = [];
      } else {
        console.log("SUCCESS!!");
        console.log("Properties risk Data retrieved from the database.");
      }
    })
    .catch((err) => {
      console.log("FAILURE!!");
      console.log(err);
      payload = null;
    });

  // let object = payload

  // var objectList = [];
  // var stack = [ object ];
  // var bytes = 0;

  // while ( stack.length ) {
  //     var value = stack.pop();

  //     if ( typeof value === 'boolean' ) {
  //         bytes += 4;
  //     }
  //     else if ( typeof value === 'string' ) {
  //         bytes += value.length * 2;
  //     }
  //     else if ( typeof value === 'number' ) {
  //         bytes += 8;
  //     }
  //     else if
  //     (
  //         typeof value === 'object'
  //         && objectList.indexOf( value ) === -1
  //     )
  //     {
  //         objectList.push( value );

  //         for( var i in value ) {
  //             stack.push( value[ i ] );
  //         }
  //     }
  // }
  // console.log("THE SIZE of risk locations is "+bytes)

  //console.log(data)

  return payload;
}
