import React from "react";
import PropTypes from "prop-types";
import c from "./Select.module.scss";
import _Select from "react-select";

/**
  `import Select from "balkerne-components/Select"` 

  - onChange provides object of form { value: "", label: "" } to the callback
  - Further documentation: https://react-select.com/home  
**/

const Select = ({ label, children, ...props }) => (
  <div className={c.container}>
    <div className={c.label}>
      {label}
      <span className={c.error}>{props.error}</span>
    </div>
    <_Select {...props} className={c.input} />
  </div>
);

Select.defaultProps = {
  label: "",
  error: "",
  options: [{ value: "", label: "" }],
  onChange: ({value, label}) => console.log('[Select] default callback, none provided. Label: ', label, ' value: ', value)
};

Select.propTypes = {
  label: PropTypes.string,
  error: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func,
};

export default Select;