import React from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";

const MySelector = (props) => {
  const options = props.options;
  return (
    <DropdownButton
      title={props.title}
      className="dropdown-button"
      onSelect={props.onSelect}
      data-testid={props.data_testid}
    >
      {Object.keys(options).map((option) => (
        <Dropdown.Item key={option} eventKey={option}>
          {options[option]}
        </Dropdown.Item>
      ))}
    </DropdownButton>
  );
};

export default MySelector;
