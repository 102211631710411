/**
 * Commonly used URLs/IP addresses
 */

/**
 * Backend API
 */
/*Testing*/
export const BACKEND = "http://localhost:5000";

/*Production*/
//export const BACKEND = 'https://backend.smartresilience.app';

/**
 * Tile server
 */
export const TILE_SERVER = "https://tile.balkerne.com";

export const TILE_SERVER_ALERTS = "https://tilealerts.balkerne.com";

export const MITIGATIONS = "https://s3-eu-west-1.amazonaws.com/mitigations/";

export const LOSS_INCIDENTS =
  "https://loss-incidents.s3-eu-west-1.amazonaws.com/";

export const DOCUMENTS =
  "https://balkerne-documents.s3-eu-west-1.amazonaws.com/";

export const ORGANISATIONS =
  "https://organisations-logo.s3.eu-west-2.amazonaws.com/";

//DEVELOPMENT
export const API_GATEWAY =
  "https://r8fd6rsk66.execute-api.eu-west-1.amazonaws.com/Version1/";

//PRODUCTION
// export const API_GATEWAY ="https://06hgssi2zb.execute-api.eu-west-1.amazonaws.com/Production/"

export const UK_POLYGON = [
  [55.8937963, -1.8237305],
  [55.235288, -2.6477051],
  [54.6611237, -3.7683105],
  [52.1199987, -6.262207],
  [49.4252672, -5.7788086],
  [51.1517861, 2.2412109],
  [55.3291444, 1.1425781],
  [55.8937963, -1.8237305],
]; //using https://www.keene.edu/campus/maps/tool/
