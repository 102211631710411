import React from 'react'
import { useLocation } from 'react-router-dom'
import { AdminTool } from 'balkerne-components/AdminTool'
import PageContainer from 'balkerne-components/Container'
import { Logout } from '../components/Logout'
import Header from '../components/Header'
import { connect } from 'react-redux'

const AdminToolPage = (...props) => {
  const location = useLocation()
  return (
    <>
      <Header
        user_permissions={props[0].userData.data.permissions}
        user_portfolio_type={props[0].userData.data.portfolio_type}
        organisation={props[0].userData.data.orgid}
        customstyle={props[0].userData.data.customstyle}
        logout={() => Logout(props[0])}
      />
      <PageContainer>
        <PageContainer.Center>
          <AdminTool {...location.state} {...props[0]} />
        </PageContainer.Center>
      </PageContainer>
    </>
  )
}

function mapStateToProps(state) {
  //console.log(state);
  const { userData, dashboardData } = state

  return {
    dashboardData,
    userData,
  }
}
export default connect(mapStateToProps)(AdminToolPage)
