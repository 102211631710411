/**
 * Common map layers.
 *
 * All layer objects MUST have the following properties:
 *     name - Name of the layer (will be shown in the sidebar)
 *     url - URL where the data for the layer can be found
 *     type - Type of the layer; 'tile' or 'data'
 *     visible - Default visibility of the layer
 *     layer - Google Maps layer object for this layer (set on application load)
 *
 *  A layer object MAY have the following properties:
 *      icon - OptionIcon to be displayed next to the name
 *
 * Tile layers MAY have the following properties:
 *     opacity - Opacity of the tile images of the layer
 *
 * Data layers MAY have the following properties:
 *     filterable - If true, filters affect this layer
 *     onClick - Handler click events on layer features
 *     style - Function or object for styling layer features
 */

import * as urls from "./urls";
import * as events from "./events";
import * as styles from "./styles";

/**
 * Tile layer for flood risk rainfall sea.
 */
export const floodRisk_rofrs = {
  name: "River Floods",
  url: [urls.TILE_SERVER, "flood-risk-rofrs"].join("/"),
  type: "tile",
  visible: false,
  toggleable: true,
  layer: null,
  opacity: 0.6,
};

/**
 * Tile layer for flood risk rainfall sea.
 */
export const floodRisk_rofsw = {
  name: "Surface Water Floods",
  url: [urls.TILE_SERVER, "flood-risk-rofsw"].join("/"),
  type: "tile",
  visible: false,
  toggleable: true,
  layer: null,
  opacity: 0.6,
};

/**
 * Tile layer for historical flooding.
 */
export const historicalFlooding = {
  name: "Historical Flooding",
  url: [urls.TILE_SERVER, "historical-flooding"].join("/"),
  type: "tile",
  visible: false,
  layer: null,
  toggleable: true,
  opacity: 0.6,
};

/**
 * Data layer for flood alerts
 */
export const floodAlerts = {
  name: "Flood Alerts",
  url: urls.API_GATEWAY + "flood/alerts-filtering",
  type: "data",
  visible: true,
  layer: null,
  filterable: true,
  toggleable: true,
  onClick: events.floodAlertOnClick,
  style: styles.floodAlert,
};

export const news_map_layer = {
  name: "Map News",
  //url: [urls.BACKEND, "flood", "alerts"].join("/"),
  type: "data",
  visible: false,
  layer: null,
  // filterable: true,
  onClick: events.news_map_on_click,
  style: styles.news_map_style,
};

export const floodAlertsPolygons = {
  name: "Flood Alerts Polygons",
  url: [urls.TILE_SERVER_ALERTS, "flood-alerts"].join("/"),
  type: "tile",
  visible: true,
  layer: null,
  toggleable: false,
  opacity: 0.6,
};

export const floodAlertsOperations = {
  name: "Flood Alerts",
  url: [urls.API_GATEWAY, "flood", "alerts-filtering"].join("/"),
  type: "data",
  visible: true,
  layer: null,
  filterable: true,
  toggleable: true,
  onClick: events.floodAlertOnClick,
  style: styles.floodAlert,
};

export const floodAlertsProperty = {
  name: "Flood Alerts Property",
  url: [urls.API_GATEWAY, "flood", "map-alerts-by-property"].join("/"),
  type: "data",
  visible: true,
  layer: null,
  filterable: true,
  toggleable: true,
  onClick: events.floodAlertOnClick,
  style: styles.floodAlert,
};

// export const floodAlertsPropertyHistorical = {
//   name: "Flood Alerts Property",
//   url: [urls.API_GATEWAY, "flood", "map-alerts-by-property-historical"].join("/"),
//   type: "data",
//   visible: true,
//   layer: null,
//   filterable: true,
//   toggleable: true,
//   onClick: events.floodAlertOnClick,
//   style: styles.floodAlert
// };

export const floodAlertsHistorical = {
  name: "Flood Alerts Historical",
  url: [urls.API_GATEWAY, "flood", "alerts-historical"].join("/"),
  type: "data",
  visible: true,
  layer: null,
  filterable: true,
  toggleable: true,
  onClick: events.floodAlertOnClick,
  style: styles.floodAlert,
};

export const crime = {
  name: "Crime",
  url: [urls.API_GATEWAY, "incidents", "crime"].join("/"),
  type: "data",
  visible: false,
  toggleable: true,
  layer: null,
};
