import React from "react";
import PropTypes from "prop-types";
import cn from 'classnames'
import c from './FileIcon.module.scss'
import extensionsToMime from './extensionToMime'

/**
    `import FileIcon from 'balkerne-components/FileIcon'`
 */

const icons = {
  "image": require("../../images/mime_type/img.svg"),
  "audio": require("../../images/mime_type/audio.svg"),
  "video": require("../../images/mime_type/video.svg"),
  "application/pdf": require("../../images/mime_type/pdf.svg"),
  "application/msword": require("../../images/mime_type/word.svg"),
  "application/vnd.ms-word": require("../../images/mime_type/word.svg"),
  "application/vnd.oasis.opendocument.text": require("../../images/mime_type/word.svg"),
  "application/vnd.openxmlformats-officedocument.wordprocessingml": require("../../images/mime_type/word.svg"),
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": require("../../images/mime_type/word.svg"),
  "application/vnd.ms-excel": require("../../images/mime_type/excel.svg"),
  "application/vnd.openxmlformats-officedocument.spreadsheetml": require("../../images/mime_type/excel.svg"),
  "application/vnd.oasis.opendocument.spreadsheet": require("../../images/mime_type/excel.svg"),
  "application/vnd.ms-powerpoint": require("../../images/mime_type/powerpoint.svg"),
  "application/vnd.openxmlformats-officedocument.presentationml": require("../../images/mime_type/powerpoint.svg"),
  "application/vnd.oasis.opendocument.presentation": require("../../images/mime_type/powerpoint.svg"),
  "text/plain": require("../../images/mime_type/text.svg"),
  "text/html": require("../../images/mime_type/code.svg"),
  "application/json": require("../../images/mime_type/code.svg"),
  "application/gzip": require("../../images/mime_type/archive.svg"),
  "application/zip": require("../../images/mime_type/archive.svg"),
  "empty": require("../../images/mime_type/empty.svg"),
}


export const FileIcon = ({size, mimeType, inferFromFileName, ...props}) => {

  if (inferFromFileName.length > 0) {
    const [extenstion] = inferFromFileName.split('.').slice(-1)
    mimeType = extensionsToMime(extenstion)
  } else {
    mimeType = ["image", "video", "audio"].includes(mimeType.split("/")[0]) ? mimeType.split("/")[0] : mimeType
  }

  let icon
  if (icons.hasOwnProperty(mimeType)) {
    icon = icons[mimeType]
  } else {
    icon = icons["empty"]
  }

  const sizes = {
    'large': [c.large],
    'normal': [c.normal],
    'small': [c.small],
  }

  const classes = cn(sizes[size])

  return <img src={icon} className={classes} {...props} />
}

FileIcon.defaultProps = {
  mimeType: "empty",
  size: "normal",
  inferFromFileName: "",
}

FileIcon.propTypes = {
  mimeType: PropTypes.oneOf(Object.keys(icons)),
  size: PropTypes.oneOf(["large", "normal", "small"]),
  inferFromFileName: PropTypes.string,
}

export default FileIcon

