import React from "react";

import { GoogleMap } from "./GoogleMap";
import { OptionCategory } from "./Options";
import * as styles from "./styles";
import * as gmaps from "./gmaps";
import * as urls from "./urls";
import axios from "axios";
import "./MediumMap.css";

import { LocationAssignUsers } from "./../userManagement/LocationAssignUsers";
import {
  rofrsMapLayers,
  rofswMapLayers,
  activeDashboardLayers,
  churchLayers,
} from "./config";

//special Map component for Irsk Insights page which displays either rofrs or rofsw maps with no UI or toggles

//---------------------------- AT THE MOMENT SHOWS HISTORICAL FLOODING INSTEAD OF ROFSW -------------------------------------------------

export class MediumMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mounted: false,
      map: null,
      infoWindow: null,
      layers: null,
      geoFeature: this.props.geoFeature,
      locationData:
        this.props.geoFeature !== null
          ? this.props.geoFeature.properties
          : null,
      type: this.props.type,
      churchFeatures: null,
      geometryLoaded: false,
    };
    this.onLayerChange = this.onLayerChange.bind(this);
    this.onMapLoad = this.onMapLoad.bind(this);
    this.getChurchGeometry = this.getChurchGeometry.bind(this);
  }

  //sets the type of the map to be either rofrs or rofsw according to props

  loadType() {
    if (this.state.geometryLoaded == true && this.state.mounted != true) {
      console.log("lodddd");
      this.setState({ mounted: true });
    } else if (this.state.mounted != true) {
      if (this.state.type === "rofrs") {
        this.state.geometryLoaded = true;
        this.state.layers = rofrsMapLayers;
      } else if (this.state.type === "rofsw") {
        this.state.geometryLoaded = true;
        this.state.layers = rofswMapLayers;
      } else if (this.state.type === "church") {
        console.log("chobb");
        this.getChurchGeometry();

        this.state.layers = churchLayers;
      }

      if (this.state.geometryLoaded) {
        console.log("mounted 2" + this.state.mounted);
        this.setState({ mounted: true });
      }
    }
  }
  async getChurchGeometry() {
    let church_geometries_url = [
      urls.BACKEND,
      "locations",
      "church",
      `geometry?id=${this.state.locationData.id}`,
    ].join("/");

    let churchGeometries = await axios
      .get(church_geometries_url)
      .then((res) => {
        const churchGeometries = res.data.features;
        console.log("churchGeometries below");
        console.log(churchGeometries);
        this.state.churchGeometries = churchGeometries;
        this.setState({ geometryLoaded: true });
      });
  }

  //loads the map with the feature and the specified layers.

  onMapLoad(map) {
    this.setState({ map });
    console.log("Loading map");
    if (this.state.type === "church") {
      console.log("churchtime");
      console.log(this.state.churchGeometries);
      if (this.state.geoFeature.geometry) {
        let layer = new window.google.maps.Data();

        layer.setStyle(styles.churchLandArea);
        layer.addGeoJson(this.state.geoFeature);

        gmaps.zoom(map, layer);
        map.setZoom(map.getZoom() - 2);

        layer.setMap(map);
      }
      if (this.state.churchGeometries.length > 0) {
        console.log("drawing geometries");
        for (let i = 0; i < this.state.churchGeometries.length; i++) {
          let layer = new window.google.maps.Data();
          console.log("iter " + i);
          let failed = false;
          if (this.state.churchGeometries[i].properties.type === "Land Area") {
            console.log("drawing Land Area");
            layer.setStyle(styles.churchLandArea);
          } else if (
            this.state.churchGeometries[i].properties.type === "Building Area"
          ) {
            console.log("drawing Building Area");
            layer.setStyle(styles.churchBuildingArea);
          } else if (
            this.state.churchGeometries[i].properties.type.includes(
              "Copper Area"
            )
          ) {
            console.log("drawing Copper Area");
            layer.setStyle(styles.churchCopperArea);
          } else if (
            this.state.churchGeometries[i].properties.type.includes("Lead Area")
          ) {
            console.log("drawing Lead Area");
            console.log(styles.churchLeadArea);
            layer.setStyle(styles.churchLeadArea);
          } else if (
            this.state.churchGeometries[i].properties.type === "Parking Spot"
          ) {
            console.log("drawing Car Park Area");
            layer.setStyle(styles.churchCarParkArea);
          } else if (
            this.state.churchGeometries[i].properties.type === "Roof Access"
          ) {
            console.log("drawing Roof Access");
            layer.setStyle(styles.churchRoofAccess);
          } else if (
            this.state.churchGeometries[i].properties.type.includes("Building")
          ) {
            console.log("drawing building");
            layer.setStyle(styles.churchSiteBuilding);
          } else {
            failed = true;
          }
          if (failed === false) {
            layer.addGeoJson(this.state.churchGeometries[i]);

            layer.setMap(map);
          }
          failed = false;
        }
      }
    } else {
      if (this.state.geoFeature.geometry) {
        let layer = new window.google.maps.Data();

        layer.setStyle(styles.dashboardLocation);
        layer.addGeoJson(this.state.geoFeature);

        gmaps.zoom(map, layer);
        map.setZoom(map.getZoom());

        layer.setMap(map);
      }
    }

    for (let layerName in this.state.layers) {
      let layer = this.state.layers[layerName];
      layer.visible = false;
      switch (layer.type) {
        case "data":
          if (layer.name === "Crime") {
            let query = [
              "dst=2000",
              `lng=${this.state.locationData.lon}`,
              `lat=${this.state.locationData.lat}`,
              "previousMonths=6",
              "pointOnly=1",
            ].join("&");
            gmaps
              .heatmapLayerFromURL([layer.url, query].join("?"))
              .then((heatmapLayer) => {
                this.setState((state) => {
                  let layers = state.layers;
                  let layer = layers[layerName];
                  layer.layer = heatmapLayer;
                  if (layer.visible) {
                    this.showLayer(state.map, layer);
                  }
                  return layers;
                });
              });
          } else {
            // console.log(" data layer below");
            // console.log(layer);
            gmaps
              .dataLayerFromURL(
                layer.url,
                this.props.user.user.signInUserSession.idToken.jwtToken
              )
              .then((dataLayer) => {
                if (layer.style) {
                  dataLayer.setStyle(layer.style);
                }
                if (layer.onClick) {
                  dataLayer.addListener("click", layer.onClick.bind(this));
                }
                this.setState((state) => {
                  let layers = state.layers;
                  let layer = layers[layerName];
                  layer.layer = dataLayer;
                  if (layer.visible) {
                    this.showLayer(state.map, layer);
                  }
                  return layers;
                });
              });
          }
          break;
        case "tile":
          //  console.log("tile layer below");
          //  console.log(layer);
          gmaps.tileLayerFromURL(layer.url, layer).then((tileLayer) => {
            this.setState((state) => {
              let layers = state.layers;
              let layer = layers[layerName];
              layer.layer = tileLayer;
              layer.visible = true;
              this.showLayer(state.map, layer);

              return layers;
            });
          });
          break;
        default:
      }
    }
  }

  //toggles layer changes

  async onLayerChange(e) {
    let layerId = e.target.id;
    this.setState((state) => {
      let layers = state.layers;
      let layer = layers[layerId];
      if (layer) {
        layer.visible = !layer.visible;
      }
      if (layer.visible) {
        this.showLayer(state.map, layer);
      } else {
        this.hideLayer(state.map, layer);
      }
      return { layers };
    });
  }

  //control buttons

  handleAlertButton = () => {
    this.setState({ redirect: true, setRiskreportmenu: 1 });
  };
  handleRiskInsightsButton = () => {
    this.setState({ redirect: true, setRiskreportmenu: 2 });
  };
  handleMitigationsButton = () => {
    this.setState({ redirect: true, setRiskreportmenu: 3 });
  };

  //shows the data of the given layer

  async showLayer(map, layer) {
    if (!layer) return;
    switch (layer.type) {
      case "data":
        gmaps.showDataLayer(map, layer.layer);
        break;
      case "tile":
        // console.log("showing tile layer");
        gmaps.showTileLayer(map, layer.layer);
        break;
      default:
    }
  }

  //hides the data of the given layer

  async hideLayer(map, layer) {
    if (!layer) return;
    switch (layer.type) {
      case "data":
        gmaps.hideDataLayer(map, layer.layer);
        break;
      case "tile":
        gmaps.hideTileLayer(map, layer.layer);
        break;
      default:
    }
  }

  //renders the map with given layers

  render() {
    let LocationAssignUsers;
    let lat;
    let lon;
    if (this.state.locationData) {
      lat = this.state.locationData.lat;
      lon = this.state.locationData.lon;
    }

    if (this.state.mounted === false) {
      this.loadType();
    }
    if (this.state.mounted === true && this.state.locationData) {
      return (
        <div className="dashboard__map_risk">
          {this.state.mounted === true ? (
            <GoogleMap
              id={"mediumMap " + this.state.type}
              className="map"
              options={{
                zoom: 15,
                tilt: 0,
                center: { lat: lat, lng: lon },
                styles: styles.maps.mono,
                disableDefaultUI: true,
                zoomControl: true,
                mapTypeControl: true,
                fullscreenControl: true,
                styles: [
                  {
                    featureType: "poi",
                    elementType: "labels",
                    stylers: [
                      {
                        visibility: "simplified",
                      },
                    ],
                  },
                ],
              }}
              apiKey="AIzaSyD9n6ddk1Z6E2L5hZqCVRo94I8lbHjm30Q"
              onMapLoad={this.onMapLoad}
            />
          ) : (
            ""
          )}
        </div>
      );
    }

    return <div className="dashboard__map" />;
  }
}
export default MediumMap;
