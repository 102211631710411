import React from 'react'

export const FormComment = ({ ...props }) => {
  console.log(props)
  const commentAuthor = props.comment.full_name
  const commentDate = props.comment.date_created.toLocaleString(undefined, {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  })
  const commentEmail = props.comment.email
  const comment = props.comment.comment
  return (
    <div key={props.key}>
      <div>
        <p style={{ marginBottom: 0, fontSize: 13 }}>{commentAuthor}</p>
        <p style={{ marginBottom: 10, fontSize: 13 }}>{commentEmail}</p>
      </div>
      <p style={{ marginRight: 5, marginLeft: 5, fontSize: 16 }}>{comment}</p>
      <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
        <p style={{ fontSize: 13 }}>{commentDate}</p>
      </div>
      <hr></hr>
    </div>
  )
}

export default FormComment
