import { systemActions } from "../_actions";
import {
  getMapAlertsAndMapItems,
  getMapAlertsAndMapItemsWithLocations,
  getMapRiskLocations,
} from "./getMapAlertsAndMapItems";
// import { getAlertLocations } from "./getAlertLocations";
// import { getRiskTable } from "./getRiskTable";
import { BACKEND, API_GATEWAY } from "../../components/maps/urls";

const TIME40 = 1000 * 60 * 40;
const TIME10 = 1000 * 60 * 10;
const TIME15 = 1000 * 60 * 15;
const TIME60 = 1000 * 60 * 60;
let date;

export const systemReduxMapHandler = {
  directLayer,
};

async function directLayer(props, layer, location_list, secret, preferences) {
  // //console.log(props)

  switch (layer.name) {
    case "Flood Alerts":
      return await updateMapAlerts(props, location_list, secret);

      break;

    case "Flood Alerts Historical":
      return await getMapAlertsHistorical(secret);

      break;

    case "Risk Properties":
      return await updateRiskLocations(
        props,
        location_list,
        secret,
        preferences
      );

    //   break;
    // case "properties_rofrs_medium":

    //   return  await updateRiskLocation_rofrs_medium(props,location_list);

    //     break;

    // case "properties_rofrs_low":

    //   return   await updateRiskLocation_rofrs_low(props,location_list);

    //         break;

    // case "properties_rofsw_high":

    //   return   await updateRiskLocation_rofsw_high(props,location_list);

    //         break;

    // case "properties_rofsw_medium":

    //   return   await updateRiskLocation_rofsw_medium(props,location_list);

    //   break;
    // case "properties_rofsw_low":

    //   return  await updateRiskLocation_rofsw_low(props,location_list);

    //      break;

    //      case "properties_crime_high":

    //       return   await updateRiskLocation_crime_high(props,location_list);

    //             break;

    //     case "properties_crime_medium":

    //       return   await updateRiskLocation_crime_medium(props,location_list);

    //       break;
    //     case "properties_crime_low":

    //       return  await updateRiskLocation_crime_low(props,location_list);

    //          break;

    //          case "properties_fire_high":

    //           return   await updateRiskLocation_fire_high(props,location_list);

    //                 break;

    //         case "properties_fire_medium":

    //           return   await updateRiskLocation_fire_medium(props,location_list);

    //           break;
    //         case "properties_fire_low":

    //           return  await updateRiskLocation_fire_low(props,location_list);

    //              break;
    default:
      return null;
  }
}
async function getMapAlertsHistorical(secret) {
  // //console.log(props.mapAlertData)

  //console.log("update alerts 1")
  // let message_url = BACKEND + '/flood/messages' + id;
  let alerts1 = await getMapAlertsAndMapItems(
    API_GATEWAY + "/flood/alerts-historical"
  );
  let alerts = alerts1;

  let layer = new window.google.maps.Data();
  layer.addGeoJson(alerts1);

  return layer;
}
function calculateSize(object) {
  var objectList = [];
  var stack = [object];
  var bytes = 0;

  while (stack.length) {
    var value = stack.pop();

    if (typeof value === "boolean") {
      bytes += 4;
    } else if (typeof value === "string") {
      bytes += value.length * 2;
    } else if (typeof value === "number") {
      bytes += 8;
    } else if (typeof value === "object" && objectList.indexOf(value) === -1) {
      objectList.push(value);

      for (var i in value) {
        stack.push(value[i]);
      }
    }
  }

  return bytes;
}

async function updateMapAlerts(props, locations, secret) {
  // //console.log(props.mapAlertData)
  let state = props.mapAlertData.data;

  //      //console.log(state)

  if (state.alerts === "empty" || state.alerts === null) {
    //console.log("update alerts 1")
    let alerts1 = await getMapAlertsAndMapItemsWithLocations(
      API_GATEWAY + "flood/alerts-filtering",
      locations,
      secret
    );
    if (calculateSize(alerts1) < 2500000) {
      state.alerts = alerts1;
      state.alerts_updated = new Date().getTime();

      props.dispatch(systemActions.updateMapAlerts(state));

      return alerts1;
    } else {
      // let layer = new window.google.maps.Data();
      // layer.addGeoJson(alerts1);
      return alerts1;
    }
  }
  date = new Date() - TIME10;

  if (state.alerts_updated && date > state.alerts_updated) {
    //console.log("update alerts 2")
    let alerts1 = await getMapAlertsAndMapItemsWithLocations(
      API_GATEWAY + "flood/alerts-filtering",
      locations,
      secret
    );
    if (calculateSize(alerts1) < 2500000) {
      state.alerts = alerts1;
      state.alerts_updated = new Date().getTime();

      props.dispatch(systemActions.updateMapAlerts(state));

      return alerts1;
    } else {
      // let layer = new window.google.maps.Data();
      // layer.addGeoJson(alerts1);
      return alerts1;
    }
  }

  //console.log("return alerts 3 (no update)")

  return state.alerts;
}

async function updateRiskLocations(props, location_list, secret, preferences) {
  //console.log(props.userLogged.loggedIn)
  let state = props.mapLocationData.data;

  if (
    props.userLogged.loggedIn === false ||
    typeof props.user.user === "undefined" ||
    props.user.user === null
  ) {
    return null;
  } else {
    if (state.locations === "empty" || state.locations === null) {
      //console.log("update map rofrs_high risk locations 1")

      let locations1 = await getMapRiskLocations(
        location_list,
        secret,
        preferences
      );
      //console.log(props.userLogged.loggedIn)
      if (
        props.userLogged.loggedIn === false ||
        typeof props.user.user === "undefined" ||
        props.user.user === null
      ) {
        return null;
      }

      state.locations = locations1;
      state.locations_updated = new Date().getTime();

      props.dispatch(systemActions.updateRiskLocationsData(state));

      // let layer = new window.google.maps.Data();
      // layer.addGeoJson(state.locations);

      return state.locations;
    }
    date = new Date() - TIME40;

    if (state.locations_updated && date > state.locations_updated) {
      //console.log("update map rofrs_high risk locations 2")

      let locations1 = await getMapRiskLocations(
        location_list,
        secret,
        preferences
      );
      //console.log(props.userLogged.loggedIn)

      if (
        props.userLogged.loggedIn === false ||
        typeof props.user.user === "undefined" ||
        props.user.user === null
      ) {
        return null;
      }

      state.locations = locations1;
      state.locations_updated = new Date().getTime();

      props.dispatch(systemActions.updateRiskLocationsData(state));

      // let layer = new window.google.maps.Data();
      // layer.addGeoJson(state.rofrs_high);

      return state.locations;
    }

    //console.log("return map rofrs_high risk locations 3 (no update)")

    // let layer = new window.google.maps.Data();
    // layer.addGeoJson(state.rofrs_high);

    return state.locations;
  }
}
