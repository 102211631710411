import { userConstants } from "../_constants";
import axios from "axios";
import * as urls from "../../components/maps/urls";

export async function getUserLocationList(user_id, aws_id, secret) {
  let payload = null;

  let data = { user_id: user_id, aws_id: aws_id };

  await axios({
    method: "get",
    url: urls.API_GATEWAY + "users/get-user-location-list",
    params: data,
  })
    .then((res) => {
      payload = res.data;
      console.log(payload);
      if (
        payload === "null" ||
        payload === null ||
        payload === "Failure" ||
        typeof payload === "undefined"
      ) {
        payload = null;
        console.log("Error retrieving user location list");
      } else if (payload == "Invalid Details" || payload == "Empty List") {
        payload = null;
        console.log(
          "Invalid credentials or empty, location list not received!!"
        );
        console.log(payload);
      } else {
        console.log("user location list received from database!!");
        console.log(payload);
      }
    })
    .catch((err) => {
      payload = null;
      console.log("location list failed to retrieve from database!!");
      console.log(err);
    });
  return payload;
}
