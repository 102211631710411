import { userConstants } from '../_constants'

import { alertActions } from './'
import { history } from '../_helpers'
import { getUserDetails } from '../_helpers/getUserDetails'

export const userActions = {
  login,
  loginLogged,
  logout,
  logoutLogged,
  clearUserData,
  getUserData,
  update_jwt_token,
  setUserData,
  updateUserLocations,
  changedPassword,
  changedPasswordLogged,
}

function login(data) {
  console.log(data)

  //localStorage.setItem('user', JSON.stringify(user));
  return dispatch => {
    dispatch({ type: userConstants.LOGIN_SUCCESS, data })
  }

  // function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
  // function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
  // function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function loginLogged() {
  return dispatch => {
    dispatch({ type: userConstants.LOGIN_SUCCESS_LOGGED })
  }
}

function logoutLogged() {
  return dispatch => {
    dispatch({ type: userConstants.LOGOUT_LOGGED })
  }
}

function changedPasswordLogged() {
  return dispatch => {
    dispatch({ type: userConstants.changedPasswordLogged })
  }
}

function changedPassword(user) {
  let data = user
  console.log(user)
  // localStorage.setItem('user', JSON.stringify(user));
  return dispatch => {
    dispatch({ type: userConstants.changedPassword, data })

    // let response = userService.login(username, password)
    // if (response !== null){
    //     (console.log(response))
    //             dispatch(success(response));
    //             history.push('/');
    // }
    // else{
    //     dispatch(failure("Failed to Login"));
    //     dispatch(alertActions.error("Failed to Login"));
    // }
  }
}

function update_jwt_token(data, new_token) {
  //console.log(data)
  //console.log(new_token)
  data.signInUserSession.idToken.jwtToken = new_token
  data.signInUserSession.idToken.payload.exp = Math.round(Date.now() / 1000) + 3400 //(expires in 60 mins)
  data.signInUserSession.idToken.payload.auth_time = Math.round(Date.now() / 1000)
  return dispatch => {
    dispatch({ type: userConstants.UPDATE_TOKENS, data })
  }
}

function updateUserLocations(data) {
  return dispatch => {
    dispatch({ type: userConstants.updateUserLocations, data })
  }
}

function getUserData() {
  return dispatch => {
    dispatch({ type: userConstants.getUserData })
  }
}

function setUserData(data) {
  if (typeof data !== 'undefined' && data !== null && typeof data.orgid !== 'undefined') {
    let standardstyle = {
      background: '#0652dd',
    }
    let islamstyle = {
      background: '#009000',
    }
    let church = {
      background: '#804FB3',
    }
    let bandqorange = {
      background: '#FF6600',
    }
    let screwfixblue = {
      background: '#00486E',
    }
    let aiusgreen = {
      background: '#336D79',
    }
    let marshblue = {
      background: '#00A8C7',
    }
    let coopblue = {
      background: '#418CFC',
    }

    let customstyle = {}
    if (data.orgid === 101) customstyle = islamstyle
    else if (data.orgid === 2) customstyle = church
    else customstyle = standardstyle

    switch (data.orgid) {
      case 0:
        data.org_url = require('../../images/sainsburys.jpg')
        data.org_name = 'Sainsburys Supermarkets Ltd'
        data.customstyle = customstyle
        data.keywords = 'flooding rain uk,arson,robbery,criminal damage,theft'
        break
      case 1:
        data.org_name = 'Argos Ltd'
        data.customstyle = customstyle
        data.keywords = 'flooding rain uk'
        break
      case 2:
        data.org_name = 'Trinitas Insurance Services'
        data.customstyle = customstyle
        data.keywords = 'flooding rain uk,arson,robbery,criminal damage,stolen lead church uk'

        break
      case 4:
        data.org_name = 'Commercial MGA Ltd'
        data.customstyle = customstyle
        data.keywords = 'flooding rain uk,arson,robbery,criminal damage,theft'

        break
      case 4:
        data.org_url = require('../../images/Logo.png')
        data.org_name = 'Marks and Spencers Ltd'
        data.customstyle = customstyle
        data.keywords = 'flooding rain uk,arson,robbery,criminal damage,theft'

        break

      case 3:
        data.org_name = 'Waitrose Ltd'
        data.customstyle = customstyle
        // data.keywords = 'flooding rain uk', 'criminal damage', 'theft','arson', 'robbery', 'theft';
        data.keywords = 'flooding rain uk,arson,robbery,criminal damage,theft'

        break

      case 7:
        data.org_name = 'Screwfix Ltd'
        data.customstyle = screwfixblue
        // data.keywords = 'flooding rain uk', 'criminal damage', 'theft','arson', 'robbery', 'theft';
        data.keywords = 'flooding rain uk,arson,robbery,criminal damage,theft'

        break
      case 8:
        data.org_name = 'Kingfisher UK Ltd'
        data.customstyle = customstyle
        // data.keywords = 'flooding rain uk', 'criminal damage', 'theft','arson', 'robbery', 'theft';
        data.keywords = 'flooding rain uk,arson,criminal damage'

        break

      case 14:
        data.org_name = 'Asda Ltd'
        data.customstyle = customstyle
        data.keywords = 'flooding rain uk,arson,robbery,criminal damage,theft'

        break

      case 30:
        data.org_name = 'Aldi Ltd'
        data.customstyle = customstyle
        data.keywords = 'flooding rain uk,arson,robbery,criminal damage,theft'

        break

      case 11:
        data.org_name = 'Lidl Ltd'
        data.customstyle = customstyle
        data.keywords = 'flooding rain uk,arson,robbery,criminal damage,theft'

        break
      case 35:
        data.org_name = 'Morrisons Ltd'
        data.customstyle = customstyle
        data.keywords = 'flooding rain uk,arson,robbery,criminal damage,theft'

        break

      case 41:
        data.org_url = require('../../images/tesco_icon.png')
        data.org_name = 'Tesco Plc'
        data.customstyle = customstyle
        data.keywords = 'flooding rain uk,arson,robbery,criminal damage,theft'

        break

      case 42:
        data.org_url = require('../../images/coop_icon.png')
        data.org_name = 'The Co-operative Group'
        data.customstyle = customstyle
        data.keywords = 'flooding rain uk,arson,robbery,criminal damage,theft'

        break
      case 43:
        data.org_name = 'Iceland'
        data.customstyle = customstyle
        data.keywords = 'flooding rain uk,arson,robbery,criminal damage,theft'
      case 44:
        data.org_url = require('../../images/boots_logo.png')
        data.org_name = 'Boots'
        data.customstyle = customstyle
        data.keywords = 'flooding rain uk,arson,robbery,criminal damage,theft'
        break
      case 101:
        data.org_name = 'Himaya'
        data.customstyle = customstyle
        data.keywords = 'flooding rain uk,arson,robbery,criminal damage,theft'

        break
      case 160:
      case 161:
      case 162:
        data.customstyle = aiusgreen
        data.keywords = 'flooding rain uk,arson,robbery,criminal damage,theft'

        break
      case 167:
        data.customstyle = marshblue
        data.keywords = 'flooding rain uk,arson,robbery,criminal damage,theft'

        break

      case 169:
        data.customstyle = coopblue
        data.keywords = 'flooding rain uk,arson,robbery,criminal damage,theft'
        data.customstyle.dashboards = [
          {
            Dashboard_Name: 'Incident History',
            Dashboard_ID: 'f60eb941-836d-4abf-bccf-2499363f0f3c',
          },
          {
            Dashboard_Name: 'Co-op Property Risk',
            Dashboard_ID: '05f7314d-8fb4-4b1a-9fe0-d582a19582a6',
          },
          {
            Dashboard_Name: 'Alerts History',
            Dashboard_ID: '238f5905-f0da-43a8-9be5-9b478a83ca51',
          },
        ]
        break

      default:
        data.org_url = require('../../images/Logo.png')
        data.org_name = 'Balkerne Ltd'
        data.customstyle = customstyle
        data.keywords = 'flooding rain uk,arson,robbery,criminal damage,theft'

        break
    }
  }
  return dispatch => {
    dispatch({ type: userConstants.setUserData, data })
  }
}

function clearUserData(data) {
  console.log(data)
  return dispatch => {
    dispatch({ type: userConstants.clearUserData, data })
  }
}

function logout() {
  // localStorage.removeItem('user');
  // //userService.logout();
  // history.push('/');

  return dispatch => {
    dispatch({ type: userConstants.LOGOUT })
  }
}
