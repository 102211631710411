import React from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import Button from "balkerne-components/Button";
import Badge from "balkerne-components/Badge";
import Select from "balkerne-components/Select";
import Input from "balkerne-components/Input";
import Card from "react-bootstrap/Card";
import c from "./AssignUsers.module.scss";

export class AssignUsers extends React.Component {
  constructor(props) {
    super(props);
    //setting TEMPORARY STATE
    this.state = {
      users: this.props.users,
      addUsers: [],
      filter_string: "",
      filter_type: "",
      filteredLocations: null,
      selectedLocations: [],
      selectedUsers: [],
      selectedAddUsers: [],
      ctrl: false,
      shifts: false,
      lastIndexLocation: 0,
      lastIndexUsers: 0,
      lastIndexAddUsers: 0,
      loaded: false,
    };
  }

  componentDidMount() {
    document.addEventListener("keydown", this.handleKeyDown);
    document.addEventListener("keyup", this.handleKeyUp);
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown);
    document.removeEventListener("keyup", this.handleKeyUp);
  }

  applyFilter = () => {
    this.state.filteredLocations = this.props.locations;
    let filteredLocations;
    switch (this.state.filter_type) {
      case "Name":
        filteredLocations = this.props.locations.filter((location) => {
          if (location.name) {
            return location.name
              .toLowerCase()
              .includes(this.state.filter_string.toLowerCase());
          }
        });
        break;
      case "Region":
        filteredLocations = this.props.locations.filter((location) => {
          if (location.region) {
            return location.region
              .toLowerCase()
              .includes(this.state.filter_string.toLowerCase());
          }
        });
        break;
      case "CRN":
        filteredLocations = this.props.locations.filter((location) => {
          if (location.property_customer_reference) {
            return location.property_customer_reference
              .toLowerCase()
              .includes(this.state.filter_string.toLowerCase());
          }
        });
        break;
      case "Address":
        filteredLocations = this.props.locations.filter((location) => {
          if (location.address_street) {
            return location.address_street
              .toLowerCase()
              .includes(this.state.filter_string.toLowerCase());
          }
        });
        break;
      case "Postcode":
        filteredLocations = this.props.locations.filter((location) => {
          if (location.address_postcode) {
            return location.address_postcode
              .toLowerCase()
              .includes(this.state.filter_string.toLowerCase());
          }
        });
        break;
      default:
        filteredLocations = this.props.locations;
    }

    this.setState({ filteredLocations });
  };

  clearFilter = () => {
    this.state.filter_string = "";

    let filteredLocations = this.props.locations;

    this.setState({ filteredLocations });
  };

  handleKeyDown = (event) => {
    if (event.ctrlKey) {
      this.state.ctrl = true;
    }
    if (event.shiftKey) {
      this.state.shift = true;
    }
  };

  handleKeyUp = (event) => {
    if (event.keyCode === 17) {
      this.state.ctrl = false;
    }
    if (event.keyCode === 16) {
      this.state.shift = false;
    }
  };

  handleChange = (e) => {
    let filter = {};
    filter[e.target.name] = e.target.value;
    this.setState(filter);
    //this.props.handleChange("users",this.state.users);
  };

  setCtrl = (press) => {
    this.state.ctrl = press;
  };

  setShift = (press) => {
    this.state.shift = press;
  };

  onRowSelectLocations = (row, isSelected, e, rowIndex) => {
    if (this.state.ctrl) {
      if (isSelected) {
        this.state.selectedLocations = [row.id];
      } else {
        this.state.selectedLocations = [row.id];
      }
    } else if (this.state.shift) {
      if (isSelected) {
        document.getSelection().removeAllRanges();
        if (this.state.lastIndexLocation > rowIndex) {
          for (let i = rowIndex; i <= this.state.lastIndexLocation; i++) {
            if (
              !this.state.selectedLocations.includes(
                this.state.filteredLocations[i].id
              )
            ) {
              this.state.selectedLocations.push(
                this.state.filteredLocations[i].id
              );
            }
          }
        } else {
          for (let i = this.state.lastIndexLocation; i <= rowIndex; i++) {
            if (
              !this.state.selectedLocations.includes(
                this.state.filteredLocations[i].id
              )
            ) {
              this.state.selectedLocations.push(
                this.state.filteredLocations[i].id
              );
            }
          }
        }
      }
    } else {
      if (isSelected) {
        this.state.selectedLocations.push(row.id);
      } else {
        console.log(
          this.state.selectedLocations.findIndex((location) => {
            return location === row.id;
          }, 1)
        );
        this.state.selectedLocations.splice(
          this.state.selectedLocations.findIndex((location) => {
            return location === row.id;
          }),
          1
        );
      }
    }

    this.props.updateSelectedLocations(this.state.selectedLocations);

    if (isSelected) {
      this.state.lastIndexLocation = rowIndex;
    }

    // RESET SELECTED ADD USERS
    if (
      this.state.selectedLocations &&
      this.state.selectedLocations.length !== 1
    ) {
      let selectedAddUsers = [];
      this.setState({ selectedAddUsers });

      this.props.updateSelectedAddUsers(selectedAddUsers);
    }
  };

  onRowSelectUsers = (row, isSelected, e, rowIndex) => {
    if (this.state.ctrl) {
      if (isSelected) {
        this.state.selectedUsers = [row.email];
      } else {
        this.state.selectedUsers = [row.email];
      }
    } else if (this.state.shift) {
      if (isSelected) {
        document.getSelection().removeAllRanges();
        if (this.state.lastIndexUsers > rowIndex) {
          for (let i = rowIndex; i <= this.state.lastIndexUsers; i++) {
            if (!this.state.selectedUsers.includes(this.props.users[i].email)) {
              this.state.selectedUsers.push(this.props.users[i].email);
            }
          }
        } else {
          for (let i = this.state.lastIndexUsers; i <= rowIndex; i++) {
            if (!this.state.selectedUsers.includes(this.props.users[i].email)) {
              this.state.selectedUsers.push(this.props.users[i].email);
            }
          }
        }
      }
    } else {
      if (isSelected) {
        this.state.selectedUsers.push(row.email);
      } else {
        this.state.selectedUsers.splice(
          this.state.selectedUsers.findIndex((user) => {
            return user === row.email;
          }),
          1
        );
      }
    }

    this.props.updateSelectedUsers(this.state.selectedUsers);

    if (isSelected) {
      this.state.lastIndexUsers = rowIndex;
    }

    this.setState({ selectedUsers: this.state.selectedUsers });
  };

  onRowSelectAddUsers = (row, isSelected, e, rowIndex) => {
    if (this.state.ctrl) {
      if (isSelected) {
        this.state.selectedAddUsers = [row.email];
      } else {
        this.state.selectedAddUsers = [row.email];
      }
    } else if (this.state.shift) {
      if (isSelected) {
        document.getSelection().removeAllRanges();
        if (this.state.lastIndexAddUsers > rowIndex) {
          for (let i = rowIndex; i <= this.state.lastIndexAddUsers; i++) {
            if (
              !this.state.selectedAddUsers.includes(
                this.props.addUsersList[i].email
              )
            ) {
              this.state.selectedAddUsers.push(
                this.props.addUsersList[i].email
              );
            }
          }
        } else {
          for (let i = this.state.lastIndexAddUsers; i <= rowIndex; i++) {
            if (
              !this.state.selectedAddUsers.includes(
                this.props.addUsersList[i].email
              )
            ) {
              this.state.selectedAddUsers.push(
                this.props.addUsersList[i].email
              );
            }
          }
        }
      }
    } else {
      if (isSelected) {
        this.state.selectedAddUsers.push(row.email);
      } else {
        this.state.selectedAddUsers.splice(
          this.state.selectedAddUsers.findIndex((user) => {
            return user === row.email;
          }),
          1
        );
      }
    }

    this.props.updateSelectedAddUsers(this.state.selectedAddUsers);

    if (isSelected) {
      this.state.lastIndexAddUsers = rowIndex;
    }

    this.setState({ selectedAddUsers: this.state.selectedAddUsers });
  };

  // indexN = (cell, row, enumObject, index) => {
  //   return <div>{index + 1}</div>;
  // };

  selectAllUsers = (select) => {
    if (select) {
      for (let i = 0; i < this.props.users.length; i++) {
        if (!this.state.selectedUsers.includes(this.props.users[i].email)) {
          this.state.selectedUsers.push(this.props.users[i].email);
        }
      }

      this.props.updateSelectedUsers(this.state.selectedUsers);
    } else {
      let selectedUsers = [];
      this.setState({ selectedUsers });

      this.props.updateSelectedUsers(selectedUsers);
    }
  };

  selectAllProperties = (select) => {
    if (select) {
      for (let i = 0; i < this.state.filteredLocations.length; i++) {
        if (
          !this.state.selectedLocations.includes(
            this.state.filteredLocations[i].id
          )
        ) {
          this.state.selectedLocations.push(this.state.filteredLocations[i].id);
        }
      }

      this.props.updateSelectedLocations(this.state.selectedLocations);
    } else {
      let selectedLocations = [];
      this.setState({ selectedLocations });

      this.props.updateSelectedLocations(selectedLocations);
    }

    // RESET SELECTED ADD USERS
    if (
      this.state.selectedLocations &&
      this.state.selectedLocations.length !== 1
    ) {
      let selectedAddUsers = [];
      this.setState({ selectedAddUsers });

      this.props.updateSelectedAddUsers(selectedAddUsers);
    }
  };

  selectAllAddUsers = (select) => {
    if (
      this.state.selectedLocations &&
      this.state.selectedLocations.length === 1
    ) {
      if (select) {
        for (let i = 0; i < this.props.addUsersList.length; i++) {
          if (
            !this.state.selectedAddUsers.includes(
              this.props.addUsersList[i].email
            )
          ) {
            this.state.selectedAddUsers.push(this.props.addUsersList[i].email);
          }
        }
        console.log(this.state.selectedAddUsers);

        this.props.updateSelectedAddUsers(this.state.selectedAddUsers);
      } else {
        let selectedAddUsers = [];
        this.setState({ selectedAddUsers });

        this.props.updateSelectedAddUsers(selectedAddUsers);
      }
    }
  };

  resetChanges = () => {
    let selectedAddUsers = [];
    this.setState({ selectedAddUsers });
    this.props.updateSelectedAddUsers(selectedAddUsers);

    let selectedLocations = [];
    this.setState({ selectedLocations });
    this.props.updateSelectedLocations(selectedLocations);

    this.props.resetChanges();
  };

  render() {
    if (this.props.currentStep !== 2) {
      return null;
    }

    const selectRowPropLocations = {
      mode: "checkbox",
      clickToSelect: true,
      bgColor: "var(--focus-background)",
      selected: this.state.selectedLocations,
      // hideSelectColumn: true,
      onSelect: this.onRowSelectLocations,
      onSelectAll: this.selectAllProperties,
    };

    const selectRowPropUsers = {
      mode: "checkbox",
      clickToSelect: true,
      bgColor: "var(--focus-background)",
      //hideSelectColumn: true,
      selected: this.state.selectedUsers,
      onSelect: this.onRowSelectUsers,
      onSelectAll: this.selectAllUsers,
    };

    const selectRowPropAddUsers = {
      mode: "checkbox",
      clickToSelect: true,
      bgColor: "var(--focus-background)",
      //hideSelectColumn: true,
      selected: this.state.selectedAddUsers,
      onSelect: this.onRowSelectAddUsers,
      onSelectAll: this.selectAllAddUsers,
    };

    if (
      this.state.loaded === false &&
      this.props.locations &&
      this.props.locations.length
    ) {
      this.state.filteredLocations = this.props.locations;
      this.state.loaded = true;
    }

    if (this.state.filteredLocations === null) {
      this.state.filteredLocations = this.props.locations;
    }

    return (
      <div className="d-flex flex-column flex-gap-3">
        {/* New users */}
        <Card>
          <Card.Header>
            <h4>
              Select Users (Ctrl + Left click) to properties and press Add User
            </h4>
          </Card.Header>
          <BootstrapTable
            selectRow={selectRowPropUsers}
            data={this.props.users}
            striped
            hover
          >
            <TableHeaderColumn isKey hidden dataField="email">
              Email
            </TableHeaderColumn>
            <TableHeaderColumn dataField="full_name">Name</TableHeaderColumn>
          </BootstrapTable>
        </Card>

        {/* Assigned / remove controls */}
        <div className="d-flex justify-content-center align-items-center flex-gap-1">
          <Button size="small" onClick={this.props.addUsers}>
            Assign User
          </Button>
          <Button size="small" light onClick={this.props.removeUser}>
            Remove User
          </Button>
          <span className={c.divider}></span>
          <Button size="small" light onClick={this.resetChanges}>
            Reset Users
          </Button>
        </div>

        {/* Assigned users */}
        {this.state.selectedLocations &&
        this.state.selectedLocations.length === 1 ? (
          <Card>
            <Card.Header>
              <h4>Assigned users</h4>
            </Card.Header>
            <BootstrapTable
              selectRow={selectRowPropAddUsers}
              data={this.props.addUsersList}
              striped
              hover
            >
              <TableHeaderColumn isKey hidden dataField="email">
                Email
              </TableHeaderColumn>
              <TableHeaderColumn dataField="full_name">Name</TableHeaderColumn>
            </BootstrapTable>
          </Card>
        ) : (
          <div className="d-flex justify-content-center">
            <span>{this.props.event_message}</span>
          </div>
        )}

        <div className="d-flex flex-column flex-gap-1">
          {/* Property filters */}
          <Card>
            <Card.Body>
              <form onChange={this.handleChange} className={c.controls}>
                <Input
                  label="Filter"
                  id="filter_string"
                  name="filter_string"
                  value={this.state.filter_string}
                />
                <Select
                  id="filter_type"
                  label="Category"
                  onChange={({ value }) =>
                    this.handleChange({
                      target: {
                        value: value,
                        name: "filter_type",
                      },
                    })
                  }
                  options={[
                    { value: "Name", label: "Name" },
                    { value: "Region", label: "Region" },
                    { value: "CRN", label: "CRN" },
                    { value: "Address", label: "Address" },
                    { value: "Postcode", label: "Postcode" },
                  ]}
                />
                <Button light onClick={this.applyFilter}>
                  Apply
                </Button>
                <Button light onClick={this.clearFilter}>
                  Clear
                </Button>
              </form>
            </Card.Body>
          </Card>

          {/* All existing properties */}
          <Card>
            <Card.Header>
              <h4>
                Select Properties (Ctrl + Left click) you'd like to add users to
              </h4>
            </Card.Header>
            <div className={c.portfolio}>
              <BootstrapTable
                selectRow={selectRowPropLocations}
                data={this.state.filteredLocations}
                striped
                hover
              >
                <TableHeaderColumn width="0%" isKey dataField="id" hidden>
                  ID
                </TableHeaderColumn>
                <TableHeaderColumn width="25%" dataField="name">
                  Property Name
                </TableHeaderColumn>
                <TableHeaderColumn width="30%" dataField="address_street">
                  Address
                </TableHeaderColumn>
                <TableHeaderColumn width="15%" dataField="address_postcode">
                  Postcode
                </TableHeaderColumn>
                <TableHeaderColumn width="15%" dataField="region">
                  Region
                </TableHeaderColumn>
                <TableHeaderColumn
                  width="15%"
                  dataField="property_customer_reference"
                >
                  Property Reference
                </TableHeaderColumn>
              </BootstrapTable>
            </div>
          </Card>
        </div>
      </div>
    );
  }
}

export default AssignUsers;
