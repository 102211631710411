import React from 'react'
import PropTypes from 'prop-types'
import { BrowserRouter as Router, Link, useHistory } from 'react-router-dom'
import { HeaderToggle, TextToggle, BurgerToggle } from 'balkerne-components/DropDown'
import Can from 'balkerne-components/Can'
import Dropdown from 'react-bootstrap/Dropdown'
import cn from 'classnames'
import c from './Header.module.scss'

const Header = props => {
  const history = useHistory()
  const user_permissions = props.user_permissions
  const user_portfolio_type = props.user_portfolio_type
  const linkClass = url => cn(c.item, { [c.selected]: url === props.currentpage || url === props.currentpage })

  return (
    <div className={c.header} style={props.customstyle}>
      {/* Desktop Header */}
      <div className={c.container}>
        <div className={c.left}>
          <Dropdown>
            <Dropdown.Toggle as={HeaderToggle} src={require('../images/icons/SquaresWhite.svg')} />
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => history.push('/')}>Smart Resilience</Dropdown.Item>
              <Dropdown.Item onClick={() => history.push('/TemplateManager')}>Template Manager</Dropdown.Item>
              {props.customstyle.dashboards
                ? props.customstyle.dashboards.map((dashboard, index) => (
                    <Dropdown.Item
                      onClick={() =>
                        history.push({ pathname: '/QuickSightDashboard', state: { id: dashboard.Dashboard_ID } })
                      }>
                      {dashboard.Dashboard_Name}
                    </Dropdown.Item>
                  ))
                : ''}

              {/* <Dropdown.Item onClick={() => history.push('/SmartVault')}>Smart Vault</Dropdown.Item> */}
            </Dropdown.Menu>
          </Dropdown>
          <div className={c.logo}>
            <Link to="/">
              <img className={c.logoImage} src={require('../images/balkerne-logo.png')} />
            </Link>
          </div>

          <div className={c.navigation}>
            {user_portfolio_type != 'Single' ? (
              <Can
                portfolio_type={user_portfolio_type}
                actions="/"
                yes={() => (
                  <Link to={'/'} className={linkClass('/')}>
                    <span>Portfolio Dashboard</span>
                  </Link>
                )}
              />
            ) : (
              <Can
                portfolio_type={user_portfolio_type}
                actions="/"
                yes={() => (
                  <Link to={'/'} className={linkClass('/')}>
                    <span style={{ color: '#FFFFFF', fontSize: 14 + 'px' }}>Property Dashboard</span>
                  </Link>
                )}
              />
            )}
            {/* <Can portfolio_type={user_portfolio_type} actions='/' yes={() => <Link to={"/"} className={linkClass("/")}><span>Portfolio Dashboard</span></Link>} /> */}
            {/* <Can portfolio_type={user_portfolio_type} actions='/Operationsmap' yes={() => <Link to={"/Operationsmap"} className={linkClass("/Operationsmap")}><span>Operations Map</span></Link>} /> */}
          </div>
        </div>

        <div className={c.right}>
          <div className={c.dropdowns}>
            <Dropdown>
              <Dropdown.Toggle as={TextToggle} light={true}>
                <span className={c.item}>Add</span>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Can
                  permissions={user_permissions}
                  actions="locations:add"
                  yes={() => (
                    <Dropdown.Item onClick={() => history.push('/MainAddLocation')}>Add New Property</Dropdown.Item>
                  )}
                />
                <Can
                  permissions={user_permissions}
                  actions="locations:add"
                  yes={() => (
                    <Dropdown.Item onClick={() => history.push('/MainBulkAddProperties')}>
                      Add Bulk Properties
                    </Dropdown.Item>
                  )}
                />
                <Can
                  permissions={user_permissions}
                  actions="users:add-admin"
                  yes={() => <Dropdown.Item onClick={() => history.push('/CreateAdmin')}>Add Admin User</Dropdown.Item>}
                />
                <Can
                  permissions={user_permissions}
                  actions={['users:add', 'users:add-any', 'users:add-all', 'users:add-admin']}
                  yes={() => (
                    <Dropdown.Item onClick={() => history.push('/MainAddUsers')}>Add Management User</Dropdown.Item>
                  )}
                />
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown>
              <Dropdown.Toggle as={TextToggle} light={true}>
                <span className={c.item}>Options</span>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Can
                  permissions={user_permissions}
                  actions="pass"
                  yes={() => (
                    <Dropdown.Item onClick={() => history.push('/ChangeAccDetails')}>Account Settings</Dropdown.Item>
                  )}
                />
                <Can
                  permissions={user_permissions}
                  actions="users:read"
                  yes={() => (
                    <Dropdown.Item onClick={() => history.push('/UserListPage')}>User List Page</Dropdown.Item>
                  )}
                />
                <Can
                  permissions={user_permissions}
                  actions="users:assign"
                  yes={() => (
                    <Dropdown.Item onClick={() => history.push('/ReassignUserProperties')}>
                      Reassign User Properties
                    </Dropdown.Item>
                  )}
                />
              </Dropdown.Menu>
            </Dropdown>
          </div>

          <div onClick={() => props.logout()}>
            <img className={c.signout} src={require('../images/sign-out.png')} />
          </div>
        </div>
      </div>

      {/* Mobile header */}
      <div className={c.smallContainer}>
        <div className={c.smallLeft}>
          <Dropdown>
            <Dropdown.Toggle as={HeaderToggle} src={require('../images/icons/SquaresWhite.svg')} />
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => history.push('/')}>Smart Resilience</Dropdown.Item>
              {/* <Dropdown.Item onClick={() => history.push('/SmartVault')}>Smart Vault</Dropdown.Item> */}
            </Dropdown.Menu>
          </Dropdown>
          <Link to="/">
            <img className={c.smallLogo} src={require('../images/balkerne-logo.png')} />
          </Link>
        </div>
        <Dropdown>
          <Dropdown.Toggle as={BurgerToggle} />
          <Dropdown.Menu>
            <Can
              portfolio_type={user_portfolio_type}
              actions="/"
              yes={() => <Dropdown.Item onClick={() => history.push('/')}>Portfolio Dashboard</Dropdown.Item>}
            />
            {/* <Can portfolio_type={user_portfolio_type} actions='/Operationsmap' yes={() => <Dropdown.Item className="d-none d-md-block" onClick={() => history.push("/Operationsmap")} >Operations Map</Dropdown.Item>} />  */}
            <Can
              permissions={user_permissions}
              actions="locations:add"
              yes={() => (
                <Dropdown.Item className="d-none d-md-block" onClick={() => history.push('/MainAddLocation')}>
                  Add New Property
                </Dropdown.Item>
              )}
            />
            <Can
              permissions={user_permissions}
              actions="locations:add"
              yes={() => (
                <Dropdown.Item className="d-none d-md-block" onClick={() => history.push('/MainBulkAddProperties')}>
                  Add Bulk Properties
                </Dropdown.Item>
              )}
            />
            <Can
              permissions={user_permissions}
              actions="users:add-all"
              yes={() => (
                <Dropdown.Item className="d-none d-md-block" onClick={() => history.push('/CreateAdmin')}>
                  Add Admin User
                </Dropdown.Item>
              )}
            />
            <Can
              permissions={user_permissions}
              actions="users:add"
              yes={() => (
                <Dropdown.Item className="d-none d-md-block" onClick={() => history.push('/MainAddUsers')}>
                  Add Management User
                </Dropdown.Item>
              )}
            />
            <Can
              permissions={user_permissions}
              actions="pass"
              yes={() => (
                <Dropdown.Item className="d-none d-md-block" onClick={() => history.push('/ChangeAccDetails')}>
                  Account Settings
                </Dropdown.Item>
              )}
            />
            <Can
              permissions={user_permissions}
              actions="users:read"
              yes={() => (
                <Dropdown.Item className="d-none d-md-block" onClick={() => history.push('/UserListPage')}>
                  User List Page
                </Dropdown.Item>
              )}
            />
            <Can
              permissions={user_permissions}
              actions="users:assign"
              yes={() => (
                <Dropdown.Item className="d-none d-md-block" onClick={() => history.push('/ReassignUserProperties')}>
                  Reassign User Properties
                </Dropdown.Item>
              )}
            />
            <Dropdown.Divider />
            <Dropdown.Item onClick={() => props.logout()}>Sign Out</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  )
}

// Header.defaultProps = {
//   user: "Property Manager"
// }

// Header.propTypes = {
//   user: PropTypes.string.isRequired,
// }

export const EmptyHeader = props => (
  <div className={c.header} style={props.customstyle}>
    {/* Desktop Header */}
    <div className={c.container}>
      <div className={c.left}>
        <div className={c.logo}>
          <Link to="/">
            <img className={c.logoImage} src={require('../images/balkerne-logo.png')} />
          </Link>
        </div>
      </div>
    </div>

    {/* Mobile header */}
    <div className={c.smallContainer}>
      <div className={c.smallLeft}>
        <Link to="/">
          <img className={c.smallLogo} src={require('../images/balkerne-logo.png')} />
        </Link>
      </div>
    </div>
  </div>
)

export default Header
