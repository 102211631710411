import React from "react";
import "./GoogleMap.css";

//Loads a google map with a given marker on the location provided

//---------------TEMPORARY HARDCODED COORDINATES AND MARKER NAME ----------------------------

export class GoogleMapMini extends React.Component {
  constructor(props) {
    super(props);
    this.onScriptLoad = this.onScriptLoad.bind(this);
  }

  onScriptLoad() {
    const map = new window.google.maps.Map(
      document.getElementById(this.props.id),
      this.props.options
    );
    var marker = new window.google.maps.Marker({
      position: { lat: this.props.lat, lng: this.props.lon },
      map: map,
      title: "Target Location",
    });
  }

  componentDidMount() {
    if (!window.google) {
      var s = document.createElement("script");
      s.type = "text/javascript";
      // s.tagName = "Google Map Normal"
      s.src = `https://maps.google.com/maps/api/js?v=quarterly&key=${this.props.apiKey}&libraries=drawing,visualization`;
      var x = document.getElementsByTagName("script")[0];
      x.parentNode.insertBefore(s, x);
      // Below is important.
      // We cannot access google.maps until it's finished loading
      s.addEventListener("load", (e) => {
        this.onScriptLoad();
      });
    } else {
      this.onScriptLoad();
    }
  }

  render() {
    return <div className={this.props.className} id={this.props.id} />;
  }
}
