import React from "react";
import "./addmitigation.css";
import axios from "axios";
import * as urls from "../maps/urls";
import DatePicker from "react-datepicker";
import moment from "moment";
import uniqueId from "lodash.uniqueid";

import "react-datepicker/dist/react-datepicker.css";

export class AddIncident extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      incidentname: null,
      incidentdescription: this.props.description || null,
      incidentsource: this.props.incident || null,
      files: [],
      uploadFiles: null,
      propid: this.props.propid,
      success: null,
      incidentDate: new Date(),
      errorMessage: [],
    };

    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
  }

  handleDateChange(date) {
    this.setState({
      incidentDate: date,
    });
  }

  handleFile(e) {
    console.log("adding file");
    console.log(e.target.files[0]);
    let fail = false;

    let uploadfile = e.target.files[0];
    console.log(uploadfile);
    if (uploadfile) {
      let files = this.state.files;
      if (files && files.length > 0) {
        for (var i = 0; i < files.length; i += 1) {
          if (files[i].name === uploadfile.name) {
            fail = true;
          }
        }
      }
      console.log(files);
      if (fail === false) {
        files.push(uploadfile);

        console.log(files);
        this.setState({ files: files });
      }
    } else {
      let validationerrors = [];

      validationerrors.push("File with this filename already added.");
      this.setState({ errorMessage: validationerrors });
    }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  async handleUpload(e) {
    if (this.validateLossIncidents() === true) {
      console.log(this.state.uploadFile, "THE Current State");

      let failure = false;
      let uploadfiles = null;
      if (this.state.files && this.state.files.length > 0) {
        uploadfiles = [];
        for (let i = 0; i < this.state.files.length; i++) {
          // let uploadfile={}
          // uploadfile.data= await this.toBase64(this.state.files[i].data);
          let file1 = null;
          try {
            file1 = {};
            file1.data = await this.toBase64(this.state.files[i]);
            file1.name = this.state.files[i].name;
          } catch (error) {
            console.log(error);
            failure = true;
            // expected output: ReferenceError: nonExistentFunction is not defined
            // Note - error messages will vary depending on browser
            let validationerrors = [];

            validationerrors.push(
              "Error processing file " + this.state.files[i].name
            );
            this.setState({ errorMessage: validationerrors });
          }
          uploadfiles.push(file1);
        }
        console.log("setting up");
      }

      // let uploadfile = null
      // if(this.state.uploadFile){
      //   console.log("setting up")
      //   uploadfile = await this.toBase64(this.state.uploadFile);
      // }
      // else{
      //   uploadfile = null
      // }

      // let uploadFileName = null
      // if(this.state.uploadFile && this.state.uploadFile.name){
      //   console.log("setting up name")
      //   uploadFileName = this.state.uploadFile.name
      // }
      // else{
      //   uploadFileName = null
      // }

      // console.log(uploadfile)
      if (failure === false) {
        let newdate = moment(this.state.incidentDate).format(
          "YYYY-MM-DD HH:mm:ss"
        );

        let data = {
          name: this.state.incidentname,
          description: this.state.incidentdescription,
          source: this.state.incidentsource,
          incident_time: newdate,
          propid: this.state.propid,
          files: uploadfiles,
        };

        await axios({
          method: "post",
          url: urls.API_GATEWAY + "loss-incidents/add-incident",
          data: data,
        })
          .then((res) => {
            console.log(res);
            let result = res.data;
            console.log(result);

            if (
              result === "null" ||
              result === null ||
              result === "Failure" ||
              typeof result === "undefined"
            ) {
              result = null;
              console.log("Error creating loss incidents document");

              this.state.errorMessage.push(
                "Failed to add loss-incident to the database."
              );
            } else if (result === "Invalid Details") {
              console.log("Details for adding loss-incident are INVALID");
              result = null;

              this.state.errorMessage.push(
                "Failed to add loss-incident to the database."
              );
            } else {
              console.log("SUCCESS!!");
              console.log("Loss incident successfully added to the database");
              this.state.errorMessage.push(
                "SUCCESS logged incident to database!!"
              );
              this.props.updateLossIncidents();
              this.setState({ success: true });
            }

            if (result === null) {
              this.state.errorMessage.push(
                "Failed to add loss-incident to the database."
              );

              this.setState({ success: false });
            }
          })
          .catch((err) => {
            this.state.errorMessage.push(
              "Failed to add loss-incident to database"
            );
            console.log(err);
            this.setState({ success: false });
          });

        // let uploadFileurl = [urls.bckend, "loss-incidents/create"].join("/");

        // let newdate = moment(this.state.incidentDate).format(
        //   "YYYY-MM-DD HH:mm:ss"
        // );

        // console.log(uploadFileurl);
        // let uploadfile = this.state.uploadFile;

        // let formData = new FormData();

        // // formData.append("file", uploadfile);
        // // formData.append("name", this.state.incidentname);
        // // formData.append("description", this.state.incidentdescription);
        // // formData.append("source", this.state.incidentsource);
        // // formData.append("incident_time", newdate);
        // // formData.append("propid", this.state.propid);
        // console.log(formData);

        // await axios
        //   .post(uploadFileurl, formData, {
        //     headers: {
        //       "Content-Type": "multipart/form-data"
        //     }
        //   })
        //   .then(res => {
        //     console.log(res);
        //     this.state.errorMessage.push("SUCCESS logged incident to database!!");
        //     this.props.updateLossIncidents()

        //     this.setState({ success: true });
        //   })
        //   .catch(err => {
        //     this.state.errorMessage.push(
        //       "Failed to add loss-incident to database"
        //     );
        //     console.log(err);
        //     this.setState({ success: false });
        //   });
      }
    } else console.log("validation failed");
  }

  //Validates the form for adding incidents - check if all fields are filled in
  validateLossIncidents() {
    let validate = true;
    let validationerrors = [];

    if (
      typeof this.state.incidentname === "undefined" ||
      this.state.incidentname < 1
    ) {
      validate = false;
      validationerrors.push("Incident name cannot be empty.\n");
    }
    if (
      typeof this.state.incidentdescription === "undefined" ||
      this.state.incidentdescription < 1
    ) {
      validate = false;
      validationerrors.push("Incident description cannot be empty.\n");
    }
    if (
      typeof this.state.incidentDate === "undefined" ||
      this.state.incidentDate < 1
    ) {
      validate = false;
      validationerrors.push("Incident date cannot be empty.\n");
    }
    this.setState({ errorMessage: validationerrors });

    if (validate === false) {
      this.setState({ success: false });
    }

    return validate;
  }

  showNotice() {
    if (this.state.success) {
      // this.props.updateIncidents()
      return (
        <div className="alert-success-small" role="alert">
          <strong>Success</strong> Incident has been recorded
        </div>
      );
    } else if (this.state.success === false) {
      return this.state.errorMessage.map((error) => (
        <div className="alert-danger-small" role="alert">
          {error}
        </div>
      ));
    }
  }

  reseterrorstate() {
    if (this.state.errorMessage.length !== 0) {
      this.setState({ errorMessage: [] });
    }
  }

  removeFile(index) {
    if (this.state.files && this.state.files.length >= index) {
      let files = this.state.files;
      files.splice(index, 1);
      console.log("File removed at" + index);
      this.setState({ files });
    }
  }

  renderFiles() {
    return (
      <div className="form-group">
        <label for="FormControlFile1">Attach File</label>
        {this.state.files !== null && this.state.files.length > 0
          ? this.state.files.map((file, index) => (
              <div>
                <label for="mitigation_type">{file.name}</label>
                <button
                  type="button"
                  className="delete"
                  aria-label="Delete"
                  onClick={() => this.removeFile(index)}
                >
                  Remove
                </button>
              </div>
            ))
          : ""}

        {this.state.files !== null && this.state.files.length > 0 ? (
          <div>
            <label for="mitigation_type">Add another: </label>
            <input
              type="file"
              name="file"
              className="form-control-file"
              id={uniqueId("FormControlFile-")}
              onChange={(e) => this.handleFile(e)}
            />
          </div>
        ) : (
          <input
            type="file"
            name="file"
            className="form-control-file"
            id={uniqueId("FormControlFile1-")}
            onChange={(e) => this.handleFile(e)}
          />
        )}
      </div>
    );
  }

  render() {
    return (
      <form className="popup">
        <div className="formbackground">
          <div className="formcontent">
            <h2>Add Loss Incident</h2>
            <button
              type="button"
              aria-label="Close"
              className="close_cross_button"
              onClick={this.props.onClickClose}
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <div className="form-group">
              <label for="incident_type">Incident Type</label>
              <select
                className="form-control"
                name="incidentname"
                onChange={this.handleChange}
                // defaultValue={}
              >
                {" "}
                <option />
                <option>Situation Report </option>
                <option>Car Park Flood</option>
                <option>Building Flood</option>
                <option>Roof Leak</option>
                <option>Wind Damage</option>
                <option>Hot Weather</option>
                <option>Hail Damage</option>
                <option>Roof Wind Damage</option>
                <option>Criminal Damage</option>
                <option>Arson</option>
                <option>Burglary</option>
                <option>Robbery</option>
                <option>ATM Theft'</option>
                <option>ATM Attempted Theft</option>
                <option>Robbery with Weapons</option>
                <option>Store evacuation - Fire Alarm</option>
                <option>Store evacuation - Bomb Threat</option>
                <option>Assault on Staff</option>
                <option>Theft</option>
                <option>Shoplifting</option>
                <option>Fire</option>
                <option>False Fire Alarm</option>
                <option>Lift Failure</option>
                <option>Other</option>
              </select>
            </div>
            <div className="form-group">
              <label for="FormControlInput1">Decription</label>
              <input
                type="description"
                name="incidentdescription"
                className="form-control"
                id="FormControlInput1"
                placeholder="eg: Car park flooding near the loading bay"
                defaultValue={this.state.incidentdescription}
                onChange={this.handleChange}
              />
            </div>
            <div className="form-group">
              <label for="FormControlInput1">Source</label>
              <input
                type="source"
                name="incidentsource"
                className="form-control"
                id="FormControlInput1"
                placeholder="eg: bbc.co.uk/article1234"
                defaultValue={this.state.incidentsource}
                onChange={this.handleChange}
              />
            </div>
            <div className="form-group-date">
              <label for="FormControlInput1">Incident Date</label>

              <DatePicker
                selected={this.state.incidentDate}
                onChange={this.handleDateChange}
                showTimeSelect
                timeFormat="HH:mm"
                dateFormat="yyyy-MM-dd HH:mm:ss"
                timeCaption="time"
              />
            </div>
            {this.renderFiles()}
            {/*             
            <div className="form-group">
              <label for="FormControlFile1">Attach File</label>
              <input
                type="file"
                name="file"
                className="form-control-file"
                id="FormControlFile1"
                onChange={e => this.handleFile(e)}
              />
            </div> */}
            <div className="form-group">
              <div className="submitbutton">
                <button
                  type="button"
                  className="uploadfilesubmit"
                  onClick={(e) => this.handleUpload(e)}
                >
                  Submit
                </button>
              </div>
            </div>
            {this.showNotice()}
          </div>
        </div>
      </form>
    );
  }
}
export default AddIncident;
