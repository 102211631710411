import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { withRouter } from 'react-router-dom'

import Home from '../temp/Home'
import TestDashboard from '../components/TestDashboard'
import PropertyHeader from '../components/HeaderProperty'

import axios from 'axios'
import * as urls from '../components/maps/urls'

import { userActions } from '../redux/_actions'
import { connect } from 'react-redux'
import { Logout } from '../components/Logout'
// import Loader from "react-loader-spinner";
import Loader from '../components/spinner.js'
import { RefreshAuthTokens } from '../components/RefreshAuthTokens'
import Footer from '../components/footer'
import './Userdashboard.css'

import { systemReduxDashboardHandler } from '../redux/_helpers/systemReduxDashboardHandler.js'
// import { getCrimeRisks } from "../redux/_helpers/getCrimeRisks.js";

import { systemReduxHandler } from '../redux/_helpers/systemReduxHandler.js'

//Page that gets the data (props/backend), loadsand & contains the dashboard (test dashboard)

export class UserDashboard extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      country: null,
      id: null,
      dashboardFeature: [],
      mounted: false,
      locationData: null,
      flood_risks: [],

      metal_theft_risks: [],
      messages: [],
      mitigations: [],
      weatherwarnings: [],
      weather_risks: [],
      crime_risks: [0],
      historical: null,
      alerts: null,
      errMessage: '',
      current_page: this.props.location.pathname || '',
      gaugereadings: [],
      success: true,
      formInstance: [],
    }
    this.updateLossIncidents = this.updateLossIncidents.bind(this)
    this.updateMitigations = this.updateMitigations.bind(this)
  }

  showNotice = () => {
    if (this.state.success === false) {
      return <div className="alertbox">{this.state.errMessage}</div>
    }
  }

  async componentDidMount() {
    await RefreshAuthTokens(this.props)

    let user_id = 'Temp'

    let location_id = null

    let country = null

    //  let alerts= null

    if (this.props.userData.data.aws_id) {
      user_id = this.props.userData.data.aws_id
    } else {
      user_id = this.props.user.user.username
    }

    if (this.props.location.state) {
      console.log('STATE IS TRUE')
      if (this.props.location.state.redirectCountry) {
        country = this.props.location.state.redirectCountry
      } else {
        country = this.props.dashboardData.data.country
      }

      if (this.props.location.state.redirectID && this.props.location.state.redirectID !== null) {
        location_id = this.props.location.state.redirectID
      } else {
        location_id = this.props.dashboardData.data.id
      }

      // if(this.props.location.state.alerts){
      //   alerts =this.props.location.state.alerts
      //   }
      //   else{
      //     alerts =this.props.dashboardData.data.live_alerts}

      // if(this.props.location.state.historical){
      //   this.state.historical =this.props.location.state.historical
      //   }
      //   else{ this.state.historical =this.props.dashboardData.data.historical_alerts
      //   }
    } else {
      console.log('STATE IS FALSE')
      country = this.props.dashboardData.data.country
      location_id = this.props.dashboardData.data.id
      // this.state.alerts =this.props.dashboardData.data.live_alerts
      // this.state.historical =this.props.dashboardData.data.historical_alerts
    }

    this.setState({ id: location_id, country: country, user_id: user_id })

    console.log(location_id)

    // if the property redirected via id, this gets called
    if (this.props.location.state && location_id) {
      await systemReduxDashboardHandler.setDashboardData(
        this.props,
        location_id,
        country,
        user_id,
        this.props.userData.data.orgid,
        this.props.user.user.signInUserSession.idToken.jwtToken,
      )
    } // if the property object itself was passed, property is only set
    // else if (this.props.location.state &&this.props.location.state.location) {

    //   console.log("locationDATA IS TRUE")
    //   console.log(this.state.country)
    //   this.state.locationData = this.props.location.state.location;

    // } // otherwise, the old dashboard gets set
    else {
      console.log('LOCATIONDATA IS FALSE')
      //this.state.locationData = this.props.dashboardData.data.location_data
      if (this.props.dashboardData.data.location_data !== null) {
        this.state.locationData = this.props.dashboardData.data.location_data
        this.state.alerts = this.props.dashboardData.data.live_alerts
        this.state.historical = this.props.dashboardData.data.historical_alerts
        await systemReduxDashboardHandler.setDashboardData(
          this.props,
          location_id,
          country,
          user_id,
          this.props.userData.data.orgid,
          this.props.user.user.signInUserSession.idToken.jwtToken,
        )
      } else if (this.state.success === true && this.props.dashboardData.data.location_data === null) {
        console.log('Error when loading dashboard data: ')
        //  Alert.alert('Error when signing in: ', err.message);
        this.setState({
          success: false,
          errMessage: 'Failed to reach server.',
        })
      }

      // if(!this.state.locationData){
      //   await systemReduxDashboardHandler.setDashboardData(this.props,location_id,country,user_id,this.props.userData.data.orgid,this.props.user.user.signInUserSession.idToken.jwtToken);
      //   if(this.props.dashboardData.data.location_data!== null){
      //     this.state.locationData = this.props.dashboardData.data.location_data

      //      }
      //      else if(this.state.success === true && this.props.dashboardData.data.location_data === null){
      //        console.log("Error when loading dashboard data: ");
      //          //  Alert.alert('Error when signing in: ', err.message);
      //          this.setState({success:false, errMessage:"Failed to reach server."})
      //      }
    }

    console.log(this.props)
    await systemReduxDashboardHandler.updateLocationData(
      this.props,
      location_id,
      this.props.userData.data.orgid,
      this.props.user.user.signInUserSession.idToken.jwtToken,
    )
    if (this.props.dashboardData.data.location_data !== null) {
      this.state.locationData = this.props.dashboardData.data.location_data
    } else if (this.state.success === true && this.props.dashboardData.data.location_data === null) {
      console.log('Error when loading historical_alerts: ')
      //  Alert.alert('Error when signing in: ', err.message);
      this.setState({ success: false, errMessage: 'Failed to reach server.' })
    }
    //this.state.locationData = this.props.dashboardData.data.location_data

    await systemReduxDashboardHandler.updateLiveAlerts(
      this.props,
      location_id,
      country,
      this.props.user.user.signInUserSession.idToken.jwtToken,
    )
    if (this.props.dashboardData.data.live_alerts !== null) {
      this.state.alerts = this.props.dashboardData.data.live_alerts
    } else if (this.state.success === true && this.props.dashboardData.data.live_alerts === null) {
      console.log('Error when loading live alerts: ')
      //  Alert.alert('Error when signing in: ', err.message);
      //this.setState({success:false, errMessage:"Failed to reach server."})
    }
    //this.state.alerts = this.props.dashboardData.data.live_alerts

    await systemReduxDashboardHandler.updateHistoricalAlerts(
      this.props,
      location_id,
      country,
      this.props.user.user.signInUserSession.idToken.jwtToken,
    )
    if (this.props.dashboardData.data.historical_alerts !== null) {
      this.state.historical = this.props.dashboardData.data.historical_alerts
    } else if (this.state.success === true && this.props.dashboardData.data.historical_alerts === null) {
      console.log('Error when loading historical_alerts: ')
      //  Alert.alert('Error when signing in: ', err.message);
      //  this.setState({success:false, errMessage:"Failed to reach server."})
    }
    //this.state.historical = this.props.dashboardData.data.historical_alerts

    await systemReduxHandler.updateWeatherWarnings(this.props, this.props.user.user.signInUserSession.idToken.jwtToken)

    await systemReduxDashboardHandler.updateFloodRisks(
      this.props,
      location_id,
      this.props.user.user.signInUserSession.idToken.jwtToken,
    )
    if (this.props.dashboardData.data.flood_risks !== null) {
      this.state.flood_risks = this.props.dashboardData.data.flood_risks
    } else if (this.state.success === true && this.props.dashboardData.data.flood_risks === null) {
      console.log('Error when loading flood_risks: ')
      //  Alert.alert('Error when signing in: ', err.message);
      // this.setState({success:false, errMessage:"Failed to reach server."})
    }

    //get metal risks for a property

    await systemReduxDashboardHandler.updateCrimeRisks(
      this.props,
      location_id,
      this.props.user.user.signInUserSession.idToken.jwtToken,
    )
    if (this.props.dashboardData.data.crime_risks !== null) {
      this.state.crime_risks = this.props.dashboardData.data.crime_risks
    } else if (this.state.success === true && this.props.dashboardData.data.crime_risks === null) {
      console.log('Error when loading crime_risks: ')
      //  Alert.alert('Error when signing in: ', err.message);
      //this.setState({success:false, errMessage:"Failed to reach server."})
    }

    // //GET CRIME DATA

    // if (location_id != null) {
    //   let crime_data = await getCrimeRisks(location_id, null);
    //   this.setState({ crime_data });
    // }

    //get mitigations for a property

    await systemReduxDashboardHandler.updateMitigations(
      this.props,
      location_id,
      this.props.user.user.signInUserSession.idToken.jwtToken,
    )
    if (this.props.dashboardData.data.mitigations !== null) {
      this.state.mitigations = this.props.dashboardData.data.mitigations
    } else if (this.state.success === true && this.props.dashboardData.data.mitigations === null) {
      console.log('Error when loading mitigations: ')
      //  Alert.alert('Error when signing in: ', err.message);
      //this.setState({success:false, errMessage:"Failed to reach server."})
    }
    // this.state.mitigations = this.props.dashboardData.data.mitigations

    // let mitigations_url = [urls.BACKEND, "mitigations", "property", query].join(
    //   "/"
    // );

    // let mitigations = await axios.get(mitigations_url).then(res => {
    //   const mitigations = res.data.mitigations;
    //   console.log("mitigations below");
    //   console.log(mitigations);
    //   this.setState({ mitigations });
    // });

    //get loss incidents for a property

    await systemReduxDashboardHandler.updateLossIncidents(
      this.props,
      location_id,
      user_id,
      this.props.user.user.signInUserSession.idToken.jwtToken,
    )
    if (this.props.dashboardData.data.loss_incidents !== null) {
      this.state.loss_incidents = this.props.dashboardData.data.loss_incidents
    } else if (this.state.success === true && this.props.dashboardData.data.loss_incidents === null) {
      console.log('Error when loading loss_incidents: ')
      //  Alert.alert('Error when signing in: ', err.message);
      // this.setState({success:false, errMessage:"Failed to reach server."})
    }

    // let lossincidents_url = [urls.BACKEND, "loss-incidents", query].join("/");

    // let lossincidents = await axios.get(lossincidents_url).then(res => {
    //   const lossincidents = res.data.items;
    //   console.log("res data lossincidents below");

    //   console.log(res.data);
    //   console.log("lossincidents below");
    //   console.log(lossincidents);
    //   this.setState({ lossincidents });
    // });

    //get real alerts if historical not provided

    // if (!this.state.alerts > 0) {
    //         let queryAlert = [`alerts_by_property?id=${this.state.id}&country=${this.state.country}`];

    //   let alertsToGetUrl = [urls.BACKEND, "flood", queryAlert].join("/");
    //   console.log("alerts url" + alertsToGetUrl);

    //   let alertsReceived = await axios.get(alertsToGetUrl).then(res => {
    //     const alerts = res.data;
    //     this.setState({ alerts });
    //   });
    // } else {
    //   this.setState({ alerts: this.props.location.state.alerts });
    // }

    //get weather warnings for a property

    // let weather_query = [`?active=true`];

    // let weatherwarnings_url = [urls.BACKEND, "met_office", weather_query].join(
    //   "/"
    // );

    // let weatherwarning = await axios.get(weatherwarnings_url).then(res => {
    //   const weatherwarnings = res.data.features;
    //   console.log("weather warnings below");
    //   console.log(weatherwarnings);

    //   // only show warnings which contain the relevant county

    //   this.setState({ weatherwarnings: filteredwarnings });
    // });
    await systemReduxHandler.updateWeatherWarnings(this.props, this.props.user.user.signInUserSession.idToken.jwtToken)

    let weatherwarnings = this.props.systemData.data.weather_warnings

    if (weatherwarnings) {
      console.log('WEATHERWARNINGS TRUE')
      let filteredwarnings = weatherwarnings.filter(warning => this.checkWarningRegion(warning.summary))
      this.state.weatherwarnings = filteredwarnings
    } else {
      if (this.state.success === true && this.props.dashboardData.data.weather_warnings === null) {
        console.log('Error when loading weatherwarnings: ')
        //  Alert.alert('Error when signing in: ', err.message);
        //this.setState({success:false, errMessage:"Failed to reach server."})
      }
    }

    //TEMP CRIME RETRIEVAL

    // let crimes_url = [urls.BACKEND, 'risks', 'crimes', query].join('/');
    //     let crime_risks = await axios.get(crimes_url)
    //         .then(res => {
    //         console.log("crime risks below");
    //         const crime_risks = res.data;
    //         console.log(crime_risks)
    //         this.setState({ crime_risks });
    //       });
    // const crime_risks = 0;
    // console.log(crime_risks);
    // this.setState({ crime_risks });

    //Removed Fetch River Gauge Data
    // this.fetchGaugeData();

    //getFormData

    this.getLiveReports()

    console.log('MOUNTED')

    this.setState({ mounted: true })

    // if(this.state.mounted ===true && this.state.locationData === null){
    //   this.setState({success:false, errMessage:"Failed to reach server."})
    // }
  }
  async getLiveReports() {
    console.log('Form Instances Loaded')
    const propertyid = this.state.locationData.properties.id

    const fetchurl = 'https://r8fd6rsk66.execute-api.eu-west-1.amazonaws.com/Version1/forms/property/' + propertyid

    console.log(fetchurl)
    await axios.get(fetchurl).then(response => {
      console.log('Form Instances')
      // console.log(response.data)
      // console.log(response)

      // this.setState({ formInstance: [] })
      const formInstances = response.data.data
      console.log(formInstances)
      this.setState({ formInstance: formInstances })
    })
  }

  async updateLossIncidents() {
    await systemReduxDashboardHandler.resetLossIncidents(
      this.props,
      this.state.id,
      this.props.userData.data.id,
      this.props.user.user.signInUserSession.idToken.jwtToken,
    )
    // await systemReduxDashboardHandler.updateLossIncidents(this.props,this.state.id,user_id,true);
    if (this.props.dashboardData.data.loss_incidents !== null) {
      this.state.loss_incidents = this.props.dashboardData.data.loss_incidents
    }
  }

  async updateMitigations() {
    await systemReduxDashboardHandler.resetMitigations(
      this.props,
      this.state.id,
      this.props.user.user.signInUserSession.idToken.jwtToken,
    )
    if (this.props.dashboardData.data.mitigations !== null) {
      this.state.mitigations = this.props.dashboardData.data.mitigations
    }
  }
  async fetchGaugeData() {
    //Gauge data is here
    //console.log(this.locationData)
    if (this.state.locationData) {
      let temp_gaugereadings = null
      let params = { propid: this.state.locationData.properties.id }

      await axios({
        method: 'get',
        url: urls.API_GATEWAY + 'flood/gauges-latest',
        params: params,
      })
        .then(res => {
          temp_gaugereadings = res.data
          console.log(temp_gaugereadings)

          if (
            temp_gaugereadings === 'null' ||
            temp_gaugereadings === null ||
            temp_gaugereadings === 'Failure' ||
            typeof temp_gaugereadings === 'undefined'
          ) {
            temp_gaugereadings = null
            console.log('Error retrieving Latest river gauge readings')
          } else if (temp_gaugereadings === 'Invalid Details') {
            console.log('Details for Latest river gauge readings are INVALID')
            temp_gaugereadings = null
          } else if (temp_gaugereadings === 'No stations found') {
            console.log('No Latest river gauge reading (STATIONS) found for the property')
            temp_gaugereadings = null
          } else {
            console.log('SUCCESS!!')

            console.log('Latest river gauge readings retrieved from the database.')
          }
          // temp_gaugereadings = res.data.relevantGauges;

          // console.log("gauge data below");
          // console.log(temp_gaugereadings);
          // console.log(gaugereadings)
          this.setState({ gaugereadings: temp_gaugereadings })
        })
        .catch(err => {
          console.log(temp_gaugereadings)
          if (this.state.success === true) {
            console.log('Error when loading gauge readings list: ')
            //  Alert.alert('Error when signing in: ', err.message);
            this.setState({
              success: false,
              errMessage: 'Failed to reach server.',
            })
          }
          console.log('FAILURE for gauges!!')
          console.log(err)
        })
    } else {
      this.setState({ gaugereadings: [] })
    }
  }

  // checks any text if it contains county information
  checkWarningRegion(warning) {
    // console.log("see warnings below")
    // console.log(warning)
    // console.log(this.state.locationData.properties.address_county)
    //if warning contains location county/ region, return true
    if (
      this.state.locationData &&
      this.state.locationData.properties &&
      this.state.locationData.properties.address_county == '' &&
      this.state.locationData.properties.address_town == ''
    )
      return false
    else {
      if (
        this.state.locationData &&
        this.state.locationData.properties &&
        warning.includes(
          this.state.locationData.properties.address_county || this.state.locationData.properties.address_town,
        )
      )
        return true
      else return false
    }
  }

  getActiveAlerts = alerts => {
    this.state.activealerts = alerts
      .filter(item => item.active === true)
      .map(({ id, description, message, severityLevel, timeRaised, region }) => ({
        id,
        description,
        message,
        severityLevel,
        timeRaised,
        region,
      }))
    console.log(this.state.activealerts)
  }

  //Render the dahboard when mounted (loaded)

  render() {
    console.log(this.state.locationData)
    if (this.state.mounted === false) {
      return (
        <React.Fragment>
          <PropertyHeader
            user_permissions={this.props.userData.data.permissions}
            user_portfolio_type={this.props.userData.data.portfolio_type}
            organisation={this.props.userData.data.orgid}
            customstyle={this.props.userData.data.customstyle}
          />
          <Loader color="#0652dd" />
        </React.Fragment>
      )
    } else if (this.state.locationData === null) {
      if (this.state.mounted === true) {
        return (
          <React.Fragment>
            <PropertyHeader
              user_permissions={this.props.userData.data.permissions}
              user_portfolio_type={this.props.userData.data.portfolio_type}
              organisation={this.props.userData.data.orgid}
              locationData2={this.state.locationData}
              alerts2={this.state.alerts}
              flood_risks={this.state.flood_risks}
              mitigations={this.state.mitigations}
              logout={() => Logout(this.props)}
              lossincidents={this.state.lossincidents}
              activeweatherwarnings={this.state.weatherwarnings}
              propid={this.state.id}
              customstyle={this.props.userData.data.customstyle}
              updateLossIncidents={this.updateLossIncidents}
              updateMitigations={this.updateMitigations}
              secret={this.props.user.user.signInUserSession.idToken.jwtToken}
            />
            {this.showNotice()}
            {/* <Loader color="#0652dd" /> */}
          </React.Fragment>
        )
      } else {
        return (
          <React.Fragment>
            <PropertyHeader
              user_permissions={this.props.userData.data.permissions}
              user_portfolio_type={this.props.userData.data.portfolio_type}
              organisation={this.props.userData.data.orgid}
              locationData2={this.state.locationData}
              alerts2={this.state.alerts}
              flood_risks={this.state.flood_risks}
              mitigations={this.state.mitigations}
              logout={() => Logout(this.props)}
              lossincidents={this.state.lossincidents}
              activeweatherwarnings={this.state.weatherwarnings}
              propid={this.state.id}
              customstyle={this.props.userData.data.customstyle}
              updateLossIncidents={this.updateLossIncidents}
              updateMitigations={this.updateMitigations}
              secret={this.props.user.user.signInUserSession.idToken.jwtToken}
            />
            {this.showNotice()}
            <Loader color="#0652dd" />
          </React.Fragment>
        )
      }
    } else {
      let org_image = require('../images/Logo.png')
      if (
        typeof this.props.userData.data.organisation.logo !== 'undefined' &&
        this.props.userData.data.organisation.logo !== 'None'
      ) {
        org_image = urls.ORGANISATIONS + this.props.userData.data.organisation.logo
      } else if (typeof this.props.userData.data.org_url !== 'undefined') {
        org_image = this.props.userData.data.org_url
      }

      return (
        <React.Fragment>
          <PropertyHeader
            user_permissions={this.props.userData.data.permissions}
            user_portfolio_type={this.props.userData.data.portfolio_type}
            organisation={this.props.userData.data.orgid}
            locationData2={this.state.locationData}
            alerts2={this.state.alerts}
            flood_risks={this.state.flood_risks}
            mitigations={this.state.mitigations}
            logout={() => Logout(this.props)}
            lossincidents={this.state.lossincidents}
            activeweatherwarnings={this.state.weatherwarnings}
            propid={this.state.id}
            customstyle={this.props.userData.data.customstyle}
            updateLossIncidents={this.updateLossIncidents}
            updateMitigations={this.updateMitigations}
            currentpage={this.state.current_page}
            secret={this.props.user.user.signInUserSession.idToken.jwtToken}
          />
          {this.showNotice()}

          <TestDashboard
            user={this.props.userData.data}
            className="reset-this"
            onClose={e => {
              let target = e.target
              if (target.id === 'fade' || target.id === 'close') {
                this.closeDashboard()
              }
            }}
            feature={this.state.locationData.properties}
            geoFeature={this.state.locationData}
            flood_risks={this.state.flood_risks}
            metal_theft_risks={this.state.metal_theft_risks}
            messages={this.state.messages}
            weather_risks={this.state.weather_risks}
            crime_risks={this.state.crime_risks}
            alerts={this.state.alerts}
            mitigations={this.state.mitigations}
            lossincidents={this.state.lossincidents}
            historical={this.state.historical}
            formInstance={this.state.formInstance}
            // activeAlerts={this.activeAlerts}
            activeAlerts={this.state.alerts}
            activeweatherwarnings={this.state.weatherwarnings}
            gaugereadings={this.state.gaugereadings}
            logo={org_image}
            secret={this.props.user.user.signInUserSession.idToken.jwtToken}
            covid_tiers={this.props.dashboardData.data.covid_tiers}
            epc={this.props.dashboardData.data.epc}
            user_permissions={this.props.userData.data.permissions}
          />
        </React.Fragment>
      )
    }
  }
}
function mapStateToProps(state) {
  //console.log(state);
  const { userLogged, user, userData, systemData, mapAlertData, mapLocationData, dashboardData } = state

  return {
    userLogged,
    user,
    userData,
    systemData,
    mapAlertData,
    mapLocationData,
    dashboardData,
  }
}
export default connect(mapStateToProps)(UserDashboard)
