import rules from '../../access-control'
import allowRender from './../userManagement/allowRender'

export const check = (actions, portfolio_type, user_permissions) => {
  //    console.log("checking user permissions")
  //    console.log(actions)
  //    console.log(portfolio_type)
  //    console.log(user_permissions)

  //CHECKS PERMISSION RENDERING (DYNAMIC)
  if (user_permissions) {
    if (typeof actions === 'string') {
      //sees if no permission check needed
      if (actions === 'pass') {
        return true
      }

      if (user_permissions.includes(actions)) {
        //console.log("user permissions match")
        return true
      } else {
        //console.log("user permissions match FAILED")
        return false
      }
    } else if (Array.isArray(actions)) {
      //console.log("ACTIONS ARRAY!!!!")
      var i
      for (i = 0; i < actions.length; i++) {
        //console.log(user_permissions)
        // console.log(actions[i])
        if (user_permissions.includes(actions[i])) {
          return true
        }
      }
      return false
    } else {
      // console.log("not array")
      //return false
    }
  }

  //CHECKS PAGE RENDERING (STATIC)
  else if (portfolio_type && !user_permissions) {
    return allowRender(actions, portfolio_type)
  } else {
    return false
  }
}

const Can = props =>
  //console.log(props),
  check(props.actions, props.portfolio_type, props.permissions) ? props.yes() : props.no()

Can.defaultProps = {
  yes: () => null,
  no: () => null,
}

export default Can
