import React from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Table from "react-bootstrap/Table";
import ReactTooltip from 'react-tooltip';
import {isMobile, isBrowser} from 'react-device-detect';
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";

import "./ControlledTabs.css";

/* eslint-disable no-unused-expressions */

export class ControlledTabs extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      alerts: [],
      displayedLocations: [],
      noSevere: 0,
      noWarning: 0,
      noAlert: 0,
      noAdvisory: 0,
      redirectID: null,
      clicks: [],
      timeout: null,
      tablesLoaded: false
    };
  }

  //sets the staet variable key which selects which tab is active in the table tabs
  selectTab(key) {
    this.setState({ key });
  }

  //displays when asingle click was done
  handleClick() {
  }

  //handles the actual redirect when double clicked a table row to a property dashboard

  handleDoubleClick( location) {
    // console.log(location);
    location = location
    if(location.severity==="Alert"){
      for (var l = 0; l < this.props.locations.alerts.features.length; l++) {
        if (this.props.locations.alerts.features[l].properties.id === location.id) {
          location = this.props.locations.alerts.features[l]
        }
      }


    }
    else if(location.severity==="Warning"){
      for (var l = 0; l < this.props.locations.warnings.features.length; l++) {
        if (this.props.locations.warnings.features[l].properties.id === location.id) {
          location = this.props.locations.warnings.features[l]
        }
      }


    }
    else if(location.severity==="Severe Warning"){
      for (var l = 0; l < this.props.locations.severe.features.length; l++) {
        if (this.props.locations.severe.features[l].properties.id === location.id) {
          location = this.props.locations.severe.features[l]
        }
      }


    }
   
  

    this.props.redirect(location);
  }

  //handles the single/double click logic

  clickHandler( location) {
    //event.preventDefault();
    this.state.clicks.push(new Date().getTime());
    window.clearTimeout(this.state.timeout);
    if(isMobile){
      this.handleDoubleClick( location);
    }
    else{
    this.state.timeout = window.setTimeout(() => {
      if (
        this.state.clicks.length > 1 &&
        this.state.clicks[this.state.clicks.length - 1] -
          this.state.clicks[this.state.clicks.length - 2] <
          250
      ) {
        this.handleDoubleClick( location);
      } else {
        this.handleClick();
      }
    }, 250);
  }
  }

  renderAlertsRow (alertslist) {

    if (!alertslist && alertslist.length <1){
      return ""

    }
    else if(alertslist.length===1){
      return <div>{(alertslist[0].type||"Flood") +" "+alertslist[0].severity+ " : " + alertslist[0].description}</div>
    }
    else if(alertslist.length===2){
      return (<div>{(alertslist[0].type||"Flood") +" " + alertslist[0].severity+ " : "  + alertslist[0].description}<br/><br/>{(alertslist[1].type||"Flood") +" " + alertslist[1].severity+ " : " + alertslist[1].description}</div>)
    }
    else if(alertslist.length===3){
      return (<div>{(alertslist[0].type||"Flood") +" " + alertslist[0].severity+ " : " + alertslist[0].description}<br/><br/>
      {(alertslist[1].type||"Flood") +" " + alertslist[1].severity+ " : "  + alertslist[1].description}<br/><br/>
      {(alertslist[2].type||"Flood") +" " + alertslist[2].severity+ " : " + alertslist[2].description}</div>)
    }
    else{
      return ""
    }


  }

  renderResponseStatusRow (alertslist) {
  
    if (!alertslist || alertslist.length <1 ){
      return ""

    }
    else if(alertslist.length===1){
      return <div>{alertslist[0].rbem_sent ?(alertslist[0].rbem_open_time !== null?(
        <a  data-tip data-for='messageopenedstatus'>
        <i id ="messageopened" className="fa fa-envelope" />
        </a>
        ):(
        <a  data-tip data-for='messagesentstatus'>
        <i id ="messagesent" className="fa fa-envelope" />
        </a>
        )):(
        <a  data-tip data-for='messagesentstatus'>
        <i id ="messagesent" className="fa fa-envelope" />
        </a>

        )}{" "}{
        <a  data-tip data-for='textsentstatus'>
        <i id ="messagesent" className="fa fa-phone" />
        </a>}</div>
    }
    else if(alertslist.length===2){
      return (<div>{alertslist[0].rbem_sent?(alertslist[0].rbem_open_time !== null?(
        <a  data-tip data-for='messageopenedstatus'>
        <i id ="messageopened" className="fa fa-envelope" />
        </a>
        ):(
        <a  data-tip data-for='messagesentstatus'>
        <i id ="messagesent" className="fa fa-envelope" />
        </a>
        )):(
        <a  data-tip data-for='messagesentstatus'>
        <i id ="messagesent" className="fa fa-envelope" />
        </a>

        )}{" "}{
        <a  data-tip data-for='textsentstatus'>
        <i id ="messagesent" className="fa fa-phone" />
        </a>}<br/><br/><br/><br/>{alertslist[1].rbem_sent ?(alertslist[1].rbem_open_time !== null?(
        <a  data-tip data-for='messageopenedstatus'>
        <i id ="messageopened" className="fa fa-envelope" />
        </a>
        ):(
        <a  data-tip data-for='messagesentstatus'>
        <i id ="messagesent" className="fa fa-envelope" />
        </a>
        )):(
        <a  data-tip data-for='messagesentstatus'>
        <i id ="messagesent" className="fa fa-envelope" />
        </a>

        )}{" "}{
        <a  data-tip data-for='textsentstatus'>
        <i id ="messagesent" className="fa fa-phone" />
        </a>}</div>)
    }
    else if(alertslist.length===3){
      return (<div>{alertslist[0].rbem_sent?(alertslist[0].rbem_open_time !== null?(
        <a  data-tip data-for='messageopenedstatus'>
        <i id ="messageopened" className="fa fa-envelope" />
        </a>
        ):(
        <a  data-tip data-for='messagesentstatus'>
        <i id ="messagesent" className="fa fa-envelope" />
        </a>
        )):(
        <a  data-tip data-for='messagesentstatus'>
        <i id ="messagesent" className="fa fa-envelope" />
        </a>

        )}{" "}{
        <a  data-tip data-for='textsentstatus'>
        <i id ="messagesent" className="fa fa-phone" />
        </a>}<br/><br/><br/><br/>
      {alertslist[1].rbem_sent?(alertslist[1].rbem_open_time !== null?(
        <a  data-tip data-for='messageopenedstatus'>
        <i id ="messageopened" className="fa fa-envelope" />
        </a>
        ):(
        <a  data-tip data-for='messagesentstatus'>
        <i id ="messagesent" className="fa fa-envelope" />
        </a>
        )):(
        <a  data-tip data-for='messagesentstatus'>
        <i id ="messagesent" className="fa fa-envelope" />
        </a>

        )}{" "}{
        <a  data-tip data-for='textsentstatus'>
        <i id ="messagesent" className="fa fa-phone" />
        </a>}<br/><br/><br/><br/>
      {alertslist[2].rbem_sent?(alertslist[2].rbem_open_time !== null?(
        <a  data-tip data-for='messageopenedstatus'>
        <i id ="messageopened" className="fa fa-envelope" />
        </a>
        ):(
        <a  data-tip data-for='messagesentstatus'>
        <i id ="messagesent" className="fa fa-envelope" />
        </a>
        )):(
        <a  data-tip data-for='messagesentstatus'>
        <i id ="messagesent" className="fa fa-envelope" />
        </a>

        )}{" "}{
        <a  data-tip data-for='textsentstatus'>
        <i id ="messagesent" className="fa fa-phone" />
        </a>}</div>)
    }
    else{
      return ""
    }


  }



  //adds the the locations to a severe alerts location list from matching alerts of that severity

  renderRowsSevereNEW() {
    let displaySevere = [];
    if (this.props.locations != null && this.props.locations.severe.features){
    if (this.props.locations.severe.features.length > 0) {
      let locations_severe =this.props.locations.severe.features
      for (var a = 0; a < locations_severe.length; a++) {
       let  property_warnings_severe = []
      
        for (var alert = 0; alert < locations_severe[a].properties.alerts.length; alert++){
          if (locations_severe[a].properties.alerts[alert].severityLevel === 1&& property_warnings_severe.length < 3) {
          
                let location = locations_severe[a].properties;
                let alert1 = locations_severe[a].properties.alerts[alert];
                this.state.displayedLocations.push(location.id);
                this.state.updateFlag = true;
                this.state.refreshed = false;
                property_warnings_severe.push({
                  id: location.id,
                  name: location.name,
                  type: alert1.type,
                  //data: location,
                  description: alert1.description,
                  severity: "Severe Warning",
                  alerts: location.alerts,
                  //rbem_open_time: location.rbem_open_time
                });
              
            
          
        }

        }
        if(property_warnings_severe.length > 0){
          displaySevere.push(property_warnings_severe)
        }
      }
        
      }
    }


    const selectRowPropAlert = {
      mode: "radio",
      clickToSelect: true,
      // bgColor: "cyan",
       hideSelectColumn: true,
       //selected: this.state.selectedProperty,
      onSelect: this.onRowSelectProperty
    };
    this.state.noSevere = displaySevere.length;
    return (<div><BootstrapTable
      className="table-smaller-users"
      id="portfoliotable"

      selectRow={selectRowPropAlert}
      data={displaySevere}
      striped
      hover
    >
      <TableHeaderColumn isKey width="30%" dataField="name" dataFormat={this.nameFormatter}>
        Name
      </TableHeaderColumn>

      {isMobile?(
      <TableHeaderColumn width="50%" dataField="description" hidden dataFormat={this.descriptionFormatter}>
        Description
      </TableHeaderColumn>

      ):(
        <TableHeaderColumn width="50%" dataField="description" dataFormat={this.descriptionFormatter}>
        Description
      </TableHeaderColumn>


      )}

      <TableHeaderColumn width="10%" dataField="severity" dataFormat={this.severityFormatter}>
        Severity
      </TableHeaderColumn>
      <TableHeaderColumn width="10%" dataField="rbem_sent" dataFormat={this.iconFormatter}>
        Response Status
      </TableHeaderColumn>
    </BootstrapTable>
    </div>)
    return displaySevere.map((items,index) => (
      <tr  key={index}  onClick={e => this.clickHandler(e, items)}>
        <td  data-tip data-for='propertydashboardtip'>{items[0].name}</td>
        <td  id="tablealerttype" data-tip data-for='propertydashboardtip'>{this.renderAlertsRow(items)}</td>
        <td>{items[0].severity}</td>
        <td>{this.renderResponseStatusRow(items)      }</td>
        {/* <td>{item.rbem_sent?(item.rbem_seen !== 0?(" RBEM SEEN "):("Email sent")):("email not sent")}</td>*/}
      </tr>
    ));
  }

  //adds the the locations to a warning alerts location list from matching alerts of that severity

  renderRowsWarningNEW() {
    let displayWarning = [];
  //  console.log(this.props.locations)
    if (this.props.locations != null && this.props.locations.warnings.features){
    if (this.props.locations.warnings.features.length > 0) {
      let locations_warning =this.props.locations.warnings.features
      for (var a = 0; a < locations_warning.length; a++) {
       let property_warning = []
      
        for (var alert = 0; alert < locations_warning[a].properties.alerts.length; alert++){
          if (locations_warning[a].properties.alerts[alert].severityLevel === 2 && property_warning.length < 3) {
          
                let location = locations_warning[a].properties;
                let alert1 = locations_warning[a].properties.alerts[alert];
                this.state.displayedLocations.push(location.id);
                this.state.updateFlag = true;
                this.state.refreshed = false;
                property_warning.push({
                  id: location.id,
                  name: location.name,
                  type: alert1.type,
                  //data: location,
                  description: alert1.description,
                  severity: "Warning",
                  alerts: location.alerts,
                  //rbem_open_time: location.rbem_open_time
                });
              
            
          
        }

        }
        if(property_warning.length > 0){
          displayWarning.push(property_warning)
        }
      }
        
      }
    }



    const selectRowPropAlert = {
      mode: "radio",
      clickToSelect: true,
      // bgColor: "cyan",
       hideSelectColumn: true,
       //selected: this.state.selectedProperty,
      onSelect: this.onRowSelectProperty
    };

    this.state.noWarning = displayWarning.length;
    return (<div><BootstrapTable
      className="table-smaller-users"
      // id="portfoliotable"

      selectRow={selectRowPropAlert}
      data={displayWarning}
      striped
      hover
    >
      <TableHeaderColumn isKey width="30%" dataField="name" dataFormat={this.nameFormatter}>
        Name
      </TableHeaderColumn>
      {isMobile?(

        <TableHeaderColumn width="40%" dataField="description" hidden dataFormat={this.descriptionFormatter}>
                Description
        </TableHeaderColumn>
      ):(
        <TableHeaderColumn width="40%" dataField="description" dataFormat={this.descriptionFormatter}>
        Description
      </TableHeaderColumn>
      )}

      <TableHeaderColumn width="10%" dataField="severity" dataFormat={this.severityFormatter}>
        Severity
      </TableHeaderColumn>
      <TableHeaderColumn width="20%" dataField="rbem_sent" dataFormat={this.iconFormatter}>
        Response Status
      </TableHeaderColumn>
    </BootstrapTable>
    </div>)
    return displayWarning.map((items,index) => (
      <tr  key={index}  onClick={e => this.clickHandler(e, items)}>
        <td  data-tip data-for='propertydashboardtip'>{items[0].name}</td>
        <td  id="tablealerttype" data-tip data-for='propertydashboardtip'>{this.renderAlertsRow(items)}</td>
        <td>{items[0].severity}</td>
        <td>{this.renderResponseStatusRow(items)          }</td>
        {/* <td>{item.rbem_sent?(item.rbem_seen !== 0?(" RBEM SEEN "):("Email sent")):("email not sent")}</td>*/}
      </tr>
    ));
  }



  //adds the the locations to an "alert" alerts location list from matching alerts of that severity

  nameFormatter(cell, row){

    if (!row && row.length <1){
      return ""

    }
    else if(row.length>0){
      return <div data-tip data-for='propertydashboardtip'>{row[0].name}</div>
    }
    else{
      return ""
    }

    
  }
  severityFormatter(cell, row){

    if (!row && row.length <1){
      return ""

    }
    else if(row.length>0){
      return <div data-tip data-for='propertydashboardtip'>{row[0].severity}</div>
    }
    else{
      return ""
    }
  }
  iconFormatter(cell, row){


    if (!row || row.length <1 ){
      return (<div></div>)

    }
    row= row[0].alerts


    if (!row || row.length <1 ){
      return (<div></div>)

    }
    else if(row.length===1){
      return <div>{row[0].rbem_sent ?(row[0].rbem_open_time !== null?(
        <a  data-tip data-for='messageopenedstatus'>
        <i id ="messageopened" className="fa fa-envelope" />
        </a>
        ):(
        <a  data-tip data-for='messagesentstatus'>
        <i id ="messagesent" className="fa fa-envelope" />
        </a>
        )):(
        <a  data-tip data-for='messagesentstatus'>
        <i id ="messagesent" className="fa fa-envelope" />
        </a>

        )}{" "}{
        <a  data-tip data-for='textsentstatus'>
        <i id ="messagesent" className="fa fa-phone" />
        </a>}</div>
    }
    else if(row.length===2){
      return (<div>{row[0].rbem_sent?(row[0].rbem_open_time !== null?(
        <a  data-tip data-for='messageopenedstatus'>
        <i id ="messageopened" className="fa fa-envelope" />
        </a>
        ):(
        <a  data-tip data-for='messagesentstatus'>
        <i id ="messagesent" className="fa fa-envelope" />
        </a>
        )):(
        <a  data-tip data-for='messagesentstatus'>
        <i id ="messagesent" className="fa fa-envelope" />
        </a>

        )}{" "}{
        <a  data-tip data-for='textsentstatus'>
        <i id ="messagesent" className="fa fa-phone" />
        </a>}<br/><br/><br/><br/>{row[1].rbem_sent?(row[1].rbem_open_time !== 0?(
        <a  data-tip data-for='messageopenedstatus'>
        <i id ="messageopened" className="fa fa-envelope" />
        </a>
        ):(
        <a  data-tip data-for='messagesentstatus'>
        <i id ="messagesent" className="fa fa-envelope" />
        </a>
        )):(
        <a  data-tip data-for='messagesentstatus'>
        <i id ="messagesent" className="fa fa-envelope" />
        </a>

        )}{" "}{
        <a  data-tip data-for='textsentstatus'>
        <i id ="messagesent" className="fa fa-phone" />
        </a>}</div>)
    }
    else if(row.length===3){
      return (<div>{row[0].rbem_sent ?(row[0].rbem_open_time !== null?(
        <a  data-tip data-for='messageopenedstatus'>
        <i id ="messageopened" className="fa fa-envelope" />
        </a>
        ):(
        <a  data-tip data-for='messagesentstatus'>
        <i id ="messagesent" className="fa fa-envelope" />
        </a>
        )):(
        <a  data-tip data-for='messagesentstatus'>
        <i id ="messagesent" className="fa fa-envelope" />
        </a>

        )}{" "}{
        <a  data-tip data-for='textsentstatus'>
        <i id ="messagesent" className="fa fa-phone" />
        </a>}<br/><br/><br/><br/>
      {row[1].rbem_sent ?(row[1].rbem_open_time !== null?(
        <a  data-tip data-for='messageopenedstatus'>
        <i id ="messageopened" className="fa fa-envelope" />
        </a>
        ):(
        <a  data-tip data-for='messagesentstatus'>
        <i id ="messagesent" className="fa fa-envelope" />
        </a>
        )):(
        <a  data-tip data-for='messagesentstatus'>
        <i id ="messagesent" className="fa fa-envelope" />
        </a>

        )}{" "}{
        <a  data-tip data-for='textsentstatus'>
        <i id ="messagesent" className="fa fa-phone" />
        </a>}<br/><br/><br/><br/>
      {row[2].rbem_sent?(row[2].rbem_open_time !== null?(
        <a  data-tip data-for='messageopenedstatus'>
        <i id ="messageopened" className="fa fa-envelope" />
        </a>
        ):(
        <a  data-tip data-for='messagesentstatus'>
        <i id ="messagesent" className="fa fa-envelope" />
        </a>
        )):(
        <a  data-tip data-for='messagesentstatus'>
        <i id ="messagesent" className="fa fa-envelope" />
        </a>

        )}{" "}{
        <a  data-tip data-for='textsentstatus'>
        <i id ="messagesent" className="fa fa-phone" />
        </a>}</div>)
    }
    else{
      return ""
    }
  }

  descriptionFormatter(cell, row){
    if (!row && row.length <1){
      return ""

    }
    else if(row.length===1){
      return <div data-tip data-for='propertydashboardtip'>{(row[0].type||"Flood") +" " +row[0].severity+ " : " + row[0].description}</div>
    }
    else if(row.length===2){
      return (<div data-tip data-for='propertydashboardtip'>{(row[0].type||"Flood") +" " + row[0].severity+ " : "  + row[0].description}<br/><br/>{(row[1].type||"Flood") +" " + row[1].severity+ " : " + row[1].description}</div>)
    }
    else if(row.length===3){
      return (<div data-tip data-for='propertydashboardtip'>{(row[0].type||"Flood") +" " + row[0].severity+ " : " + row[0].description}<br/><br/>
      {(row[1].type||"Flood") +" " + row[1].severity+ " : "  + row[1].description}<br/><br/>
      {(row[2].type||"Flood") +" " + row[2].severity+ " : " + row[2].description}</div>)
    }
    else{
      return ""
    }
  }

  onRowSelectProperty = (row, isSelected, e, rowIndex) => {

    if( row && row.length >0){
      this.clickHandler(row[0])
    }
    

  }

  renderRowsAlertNEW() {
    let displayAlerts = [];
    if (this.props.locations != null && this.props.locations.alerts.features){
    if (this.props.locations.alerts.features.length > 0) {
      let locations_alerts =this.props.locations.alerts.features
      for (var a = 0; a < locations_alerts.length; a++) {
        let property_alerts= []
      
        for (var alert = 0; alert < locations_alerts[a].properties.alerts.length; alert++){
          
          if (locations_alerts[a].properties.alerts[alert].severityLevel === 3&& property_alerts.length < 3) {
          
                let location = locations_alerts[a].properties;
                let alert1 = locations_alerts[a].properties.alerts[alert];
                this.state.displayedLocations.push(location.id);
                this.state.updateFlag = true;
                this.state.refreshed = false;
                if (property_alerts.length === 1){




                }
                property_alerts.push({
                  id: location.id,
                  name: location.name,
                  type: alert1.type,
                  data: location,
                  description: alert1.description,
                  severity: "Alert",
                  rbem_sent: location.rbem_sent,
                  rbem_open_time: location.rbem_open_time
                });
              
            
          
        }

        }
        if(property_alerts.length > 0){
          displayAlerts.push(property_alerts)
        }
      }
        
      }
    }

  


    const selectRowPropAlert = {
      mode: "radio",
      clickToSelect: true,
      // bgColor: "cyan",
       hideSelectColumn: true,
       //selected: this.state.selectedProperty,
      onSelect: this.onRowSelectProperty
    };



    //console.log(displayAlerts)
    this.state.noAlert = displayAlerts.length;
return (<div><BootstrapTable
              className="table-smaller-users"
              id="portfoliotable"

              selectRow={selectRowPropAlert}
              data={displayAlerts}
              striped
              hover
            >
              <TableHeaderColumn isKey width="30%" dataField="name" dataFormat={this.nameFormatter}>
                Name
              </TableHeaderColumn>

              {isMobile?(

              <TableHeaderColumn width="50%" dataField="description" hidden dataFormat={this.descriptionFormatter}>
                Description
              </TableHeaderColumn>
              ):(
            
              <TableHeaderColumn width="50%" dataField="description" dataFormat={this.descriptionFormatter}>
                Description
              </TableHeaderColumn>


              )}
              <TableHeaderColumn width="10%" dataField="severity" dataFormat={this.severityFormatter}>
                Severity
              </TableHeaderColumn>
              {/* <TableHeaderColumn width="10%" dataField="rbem_sent" dataFormat={this.iconFormatter}>
                Response Status
              </TableHeaderColumn> */}
            </BootstrapTable>
            </div>)
          
    return displayAlerts.map((items,index) => (
      <tr  key={index}  onClick={e => this.clickHandler(e, items)}>
        <td  data-tip data-for='propertydashboardtip'>{items[0].name}</td>
        <td  id="tablealerttype" data-tip data-for='propertydashboardtip'>{this.renderAlertsRow(items)}</td>
        <td>{items[0].severity}</td>
      
        {/* <td>{item.rbem_sent?(item.rbem_seen !== 0?(" RBEM SEEN "):("Email sent")):("email not sent")}</td>*/}
      </tr>
    ));
  }

  //adds the the locations to an advisory alerts location list from matching alerts of that severity

  renderRowsAdvisoryNEW() {
    let displayAdvisory = [];
    // if (this.props.locations != null && this.props.locations.severe.features){
    // if (this.props.locations.severe.features.length > 0) {
    //   let locations_severe =this.props.locations.severe.features
    //   for (var a = 0; a < locations_severe.length; a++) {
    //     let property_advisory = []
      
    //     for (var alert = 0; alert < locations_severe[a].properties.alerts.length; alert++){
    //       if (alert.severityLevel === 4&& property_warning.length < 3) {
          
    //             let location = locations_severe[a].properties;
    //             let alert = locations_severe[a].properties.alerts[a];
    //             this.state.displayedLocations.push(location.id);
    //             this.state.updateFlag = true;
    //             this.state.refreshed = false;
    //             property_advisory.push({
    //               id: location.id,
    //               name: location.name,
    //                type: alert1.type,
    //               data: location,
    //               description: alert.description,
    //               severity: "Severe Warning",
    //               rbem_sent: location.rbem_sent,
    //               rbem_open_time: location.rbem_open_time
    //             });
              
            
          
    //     }

    //     }
    //     if(property_advisory.length > 0){
    //       displaySevere.push(property_advisory)
    //     }
    //   }
        
    //   }
    // }


    //renders the location-alert rows on the table

   //loads fixes bubble counter updating and which one is selected
   if (this.state.updateFlag === true && this.state.tablesLoaded === false) {
    this.updateCounters();
    if (!this.state.noSevere > 0) {
      if (this.state.noWarning > 0) {
        this.selectTab("warning");
      } else {
        this.selectTab("alert");
      }
    }
  }

  

    this.state.noAdvisory = displayAdvisory.length;
    return (<div><BootstrapTable
      className="table-smaller-users"
      id="portfoliotable"
      //selectRow={selectRowPropAddUsers}
      data={displayAdvisory}
      striped
      hover
    >
      <TableHeaderColumn isKey width="30%" dataField="name" dataFormat={this.nameFormatter}>
        Name
      </TableHeaderColumn>
      {isMobile?(

      <TableHeaderColumn width="50%" dataField="description" hidden dataFormat={this.descriptionFormatter}>
        Description
      </TableHeaderColumn>
      ):
      (
        <TableHeaderColumn width="50%" dataField="description" dataFormat={this.descriptionFormatter}>
        Description
      </TableHeaderColumn>

      )}
      <TableHeaderColumn width="10%" dataField="severity" dataFormat={this.severityFormatter}>
        Severity
      </TableHeaderColumn>
      {/* <TableHeaderColumn width="10%" dataField="rbem_sent" dataFormat={this.iconFormatter}>
        Response Status
      </TableHeaderColumn> */}
    </BootstrapTable>
    </div>)
    return displayAdvisory.map((items,index) => (
      <tr  key={index}  onClick={e => this.clickHandler(e, items)}>
        <td  data-tip data-for='propertydashboardtip'>{items[0].name}</td>
        <td  id="tablealerttype" data-tip data-for='propertydashboardtip'>{this.renderAlertsRow(items)}</td>
        <td>{items[0].severity}</td>
        
        {/* <td>{item.rbem_sent?(item.rbem_seen !== 0?(" RBEM SEEN "):("Email sent")):("email not sent")}</td>*/}
      </tr>
    ));

    

    // this.state.noAdvisory = displayAdvisory.length;
    // return displayAdvisory.map((item,index) => (
    //   <tr  key={index}  onClick={e => this.clickHandler(e, item)}>
    //     <td  data-tip data-for='propertydashboardtip'>{item.name}</td>
    //     <td  data-tip data-for='propertydashboardtip'>{item.description}</td>
    //     <td>{item.severity}</td>
    //     <td>{item.rbem_sent?(item.rbem_seen !== 0?(<span className="glyphicon glyphicon-envelope"></span>):(<span className="glyphicon glyphicon-envelope"></span>)):(<span className="glyphicon glyphicon-envelope"></span>)}{" "}{<span className="glyphicon glyphicon-earphone"></span>}</td>
    //     {/* <td>{item.rbem_sent?(item.rbem_seen !== 0?(" RBEM SEEN "):("Email sent")):("email not sent")}</td>*/}
    //   </tr>
    // ));

    //renders the location-alert rows on the table ^
  }

  //re-renders the bubble counts - updates

  //------------------------------------------- CAN BE OPTIMIZED -----------------------------------------

  componentDidMount() {
    this.setState({ noSevere: this.state.noSevere });
    this.setState({ noWarning: this.state.noWarning });
    this.setState({ noAlert: this.state.noAlert });
    this.setState({ noAdvisory: this.state.noAdvisory });
  }

  //updates the alert counter changes after the first load, when called

  updateCounters = () => {
    this.state.tablesLoaded = true;
    this.setState({ noSevere: this.state.noSevere });
  };

  //Shows or hides critical alert bubble depending if such alerts are present or absent

  returnCriticalbubble() {
    if (this.state.noSevere > 0) {
      return (
        <div
         data-tip data-for='severewarningbubble'
          className="portfolioBubbleCritical"
          onClick={e => {
            this.selectTab("severeWarning");
            this.props.selectedAlert(1);
          }}
        >
          {this.state.noSevere}
          <h6>Severe Warnings</h6>
        </div>
      );
    } else {
      return;
    }
  }

  //another function refreshing the counters and setting approprate bubbles as first if some are empty

  refreshCounters = () => {
    if (this.state.refreshed === false) {
      this.state.refreshed = true;
      if (this.state.noSevere > 0) {
        this.state.key = "severeWarning";
      } else {
        if (this.state.noWarning > 0) {
          this.state.key = "warning";
        } else {
          this.state.key = "alert";
        }
      }
    }
  };

  //render the component

  render() {
    return (
      <div className = "currentthreats">
        <div className="bubbleHolder">
          {this.returnCriticalbubble()}

          <div
           data-tip data-for='warningbubble'
            className="portfolioBubbleWarning"
            onClick={e => {
              this.selectTab("warning");
              this.props.selectedAlert(2);
            }}
          >
            {this.state.noWarning}
            <h6>Warnings</h6>
          </div>

          <div
           data-tip data-for='alertbubble'
            className="portfolioBubbleAlert"
            onClick={e => {
              this.selectTab("alert");
              this.props.selectedAlert(3);
            }}
          >
            {this.state.noAlert}
            <h6>Alerts</h6>
          </div>

          <div
            className="portfolioBubbleAdvisory"
            onClick={e => {
              this.selectTab("advisory");
              this.props.selectedAlert(4);
            }}
          >
            {this.state.noAdvisory}
            <h6>Advisory</h6>
          </div>
        </div>

        <Tabs
          id="controlled-tab-example"
          activeKey={this.state.key}
          onSelect={key => this.setState({ key })}
          className="centerTable text-center tab"
          showlabel="false"
        >
          <Tab eventKey="severeWarning">
           {this.renderRowsSevereNEW()}
          </Tab>
          <Tab eventKey="warning">
           {this.renderRowsWarningNEW()}
          </Tab>
          <Tab eventKey="alert">
            {this.renderRowsAlertNEW()}

          </Tab>
          <Tab eventKey="advisory">
            {this.renderRowsAdvisoryNEW()}
           
          </Tab>
        </Tabs>
        {this.refreshCounters()}
          <ReactTooltip id='messageopenedstatus'  delayShow={500} aria-haspopup='true' role='example'>
                    <p>Email status</p>
                    <ul>
                      <li>Email delivered</li>
                      <li>Email read</li>
                    </ul>
          </ReactTooltip>
          <ReactTooltip id='messagesentstatus' delayShow={500}  aria-haspopup='true' role='example'>
                    <p>Email status</p>
                    <ul>
                      <li>Email delivered</li>
                      <li>Email unopened</li>
                    </ul>
          </ReactTooltip>
                    <ReactTooltip id='textsentstatus'  delayShow={500} aria-haspopup='true' role='example'>
                    <p>SMS status</p>
                    <ul>
                      <li>SMS sent</li>
                    </ul>
          </ReactTooltip>
          <ReactTooltip id='alertbubble'  delayShow={200} aria-haspopup='true' role='example'>
                <p>Be prepared to take action</p>
          </ReactTooltip>
          <ReactTooltip id='warningbubble'  delayShow={200} aria-haspopup='true' role='example'>
                <p>Immediate action required</p>
          </ReactTooltip>
          <ReactTooltip id='severewarningbubble'  delayShow={200} aria-haspopup='true' role='example'>
                <p>Risk to life</p>
          </ReactTooltip>
          <ReactTooltip id='propertydashboardtip'  delayShow={200} aria-haspopup='true' role='example'>
                    <p>double click for Property Dashboard</p>
                </ReactTooltip>
          
      </div>
    );
  }
}

export default ControlledTabs;
