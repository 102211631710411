import React, { useState, useEffect } from 'react'
import Card from 'react-bootstrap/Card'
import Badge from 'react-bootstrap/Badge'
import c from './AdminTool.module.scss'
import cn from 'classnames'
import Table from 'react-bootstrap/Table'
import Button from './Button'
import Input from './Input'
import { FormBuilder } from '@ginkgo-bioworks/react-json-schema-form-builder'
import { Tabs, Tab } from 'react-bootstrap'
import Form from '@rjsf/core'
import FileWidget from '../widgets/FileWidget'
import DateTimeWidget from '../widgets/DateTimeWidget'
import AttachedFile from './AttachedFile'
import axios from 'axios'
import overlay_c from './Overlay.module.scss'
import { useHistory } from 'react-router-dom'

export const AdminTool = ({ ...props }) => {
  let history = useHistory()

  const [alertDisplay, setAlertDisplay] = useState('none')
  const [alertMessage, setAlertMessage] = useState('')
  const [alertType, setAlertType] = useState('')
  const [categoriesObj, setCategoriesObj] = useState({})
  const [subCategoriesObj, setSubCategoriesObj] = useState({})
  const [schema, setSchema] = useState('{}')
  const [uischema, setUiSchema] = useState('{}')
  const [formType, setFormType] = useState(0)
  const [category, setCategory] = useState(0)
  const [subCategory, setSubCategory] = useState(0)
  const [subCategoryDisplay, setSubCategoryDisplay] = useState('flex')
  const [categoryOptions, setCategoryOptions] = useState([])
  const [subCategoryOptions, setSubCategoryOptions] = useState([])
  const baseurl = 'https://r8fd6rsk66.execute-api.eu-west-1.amazonaws.com/Version1'

  const [formdisplay, setFormDisplay] = useState('block')
  const [previewdisplay, setPreviewDisplay] = useState('none')

  const dropdownStyle = cn([c.dropdownStyle])
  const overlayclass = cn([overlay_c.overlay])
  const templateTypes = {
    2: 'Incident',
    3: 'Survey',
  }
  var initFlag = true

  const getCategoriesDisplay = async () => {
    const path = '/forms/categories'
    const fetchurl = baseurl + path
    var data
    await axios.get(fetchurl).then(response => {
      data = response.data.data
    })
    return data
  }

  useEffect(() => {
    let isSubscribed = true
    if (isSubscribed) {
      // Adding a margin-right to all radio buttons
      const radioelements = Array.from(document.getElementsByClassName('field-radio-group'))
      const checkboxelements = Array.from(document.getElementsByClassName('field-boolean'))
      const selectelements = Array.from(document.getElementsByTagName('select'))

      radioelements.map(radioelement => {
        Array.from(radioelement.children).map(children => {
          Array.from(children.getElementsByTagName('input')).map(input => {
            input.style = 'margin-right: 5px'
          })
        })
      })
      // Adding a margin-right to all checkboxes
      checkboxelements.map(checkboxelement => {
        Array.from(checkboxelement.children).map(children => {
          Array.from(children.getElementsByTagName('input')).map(input => {
            input.style = 'margin-right: 10px'
          })
        })
      })
      // Map through each select element and set their width and margins
      selectelements.map(selectelement => {
        selectelement.style = 'margin-right: auto; margin-left: auto; width: 83.5%;'
      })

      // Init categories dropdown object
      getCategoriesDisplay().then(categories => {
        console.log(categories)
        var tempCategories = {}
        var tempSubCategories = {}

        categories.map(category => {
          tempCategories[category.id] = category.title
          category.subcategories.map(subcategory => {
            tempSubCategories[subcategory.id] = subcategory.title
          })
        })
        setCategoriesObj(tempCategories)
        setSubCategoriesObj(tempSubCategories)

        // Load admin tool in edit or create mode

        if (props.createMode != undefined) {
          console.log('Creating new template version - Admin Tool')
          //document.getElementById('template_disable').style.display = 'block'
          document.getElementById('submit_new_version_button').style.display = 'inline-block'
          document.getElementById('formBuilder_Section').style.display = 'block'

          // Load in template principle details
          if (props.templateObj != undefined) {
            document.getElementById('template_title').value = props.templateObj['title']
            document.getElementById('template_description').value = props.templateObj['description']
            document.getElementById('current_template_title').innerHTML = props.templateObj['title']
            document.getElementById('current_template_description').innerHTML = props.templateObj['description']
            document.getElementById('current_template_formtype').innerHTML = templateTypes[props.templateObj['type_id']]
            document.getElementById('current_template_category').innerHTML =
              tempCategories[props.templateObj['category_id']]
            if (props.templateObj['type_id'] == 3) {
              document.getElementById('current_template_subcategory').innerHTML = '-'
            } else {
              document.getElementById('current_template_subcategory').innerHTML =
                tempSubCategories[props.templateObj['subcategory_id']]
            }
            setFormType(props.templateObj['type_id'])
            setCategory(props.templateObj['category_id'])
            setSubCategory(props.templateObj['subcategory_id'])
          } else {
            alert('BUG ALERT -- Create Mode: version, EditMode: template, TemplateObj is undefined')
          }
        } else {
          if (props.editMode != undefined) {
            switch (props.editMode) {
              case 'template':
                console.log('Editing Template - Admin Tool')
                // display only template details card
                document.getElementById('formBuilder_Section').style.display = 'none'
                document.getElementById('template_edit_button').style.display = 'none'
                document.getElementById('template_save_button').style.display = 'inline-block'
                document.getElementById('cancel_go_back_button').style.display = 'inline-block'

                // Show current details
                document.getElementById('template_title').value = props.templateObj['title']
                document.getElementById('template_description').value = props.templateObj['description']
                document.getElementById('current_template_title').innerHTML = props.templateObj['title']
                document.getElementById('current_template_description').innerHTML = props.templateObj['description']
                document.getElementById('current_template_formtype').innerHTML =
                  templateTypes[props.templateObj['type_id']]
                document.getElementById('current_template_category').innerHTML =
                  tempCategories[props.templateObj['category_id']]
                if (props.templateObj['type_id'] == 3) {
                  document.getElementById('current_template_subcategory').innerHTML = '-'
                } else {
                  document.getElementById('current_template_subcategory').innerHTML =
                    tempSubCategories[props.templateObj['subcategory_id']]
                }

                setFormType(props.templateObj['type_id'])
                setCategory(props.templateObj['category_id'])
                setSubCategory(props.templateObj['subcategory_id'])
                // Show Form Inputs
                document.getElementById('template_title').style.display = 'block'
                document.getElementById('template_description').style.display = 'block'
                document.getElementById('template_formtype_container').style.display = 'flex'
                document.getElementById('template_category_container').style.display = 'flex'
                document.getElementById('template_subcategory_container').style.display = 'flex'
                break
              case 'version':
                document.getElementById('formBuilder_Section').style.display = 'block'
                console.log('Editing Version - Admin Tool')
                // Temporarily disable template details section
                //document.getElementById('template_disable').style.display = 'block'
                document.getElementById('save_version_button').style.display = 'inline-block'
                // Show current details
                document.getElementById('template_title').value = props.templateObj['title']
                document.getElementById('template_description').value = props.templateObj['description']
                document.getElementById('current_template_title').innerHTML = props.templateObj['title']
                document.getElementById('current_template_description').innerHTML = props.templateObj['description']
                document.getElementById('current_template_formtype').innerHTML =
                  templateTypes[props.templateObj['type_id']]
                document.getElementById('current_template_category').innerHTML =
                  tempCategories[props.templateObj['category_id']]
                if (props.templateObj['type_id'] == 3) {
                  document.getElementById('current_template_subcategory').innerHTML = '-'
                } else {
                  document.getElementById('current_template_subcategory').innerHTML =
                    tempSubCategories[props.templateObj['subcategory_id']]
                }
                setFormType(props.templateObj['type_id'])
                setCategory(props.templateObj['category_id'])
                setSubCategory(props.templateObj['subcategory_id'])
                // Load template and uischema in form builder
                handleChange(JSON.parse(props.selectedVersion.template), JSON.parse(props.selectedVersion.ui_schema))
                break
            }
          } else {
            // default both cards enabled
            console.log('Creating new template and version - Admin Tool')
            document.getElementById('formBuilder_Section').style.display = 'block'
            document.getElementById('template_edit_button').style.display = 'none'
            document.getElementById('submit_new_template_button').style.display = 'inline-block'
            // Show Form Inputs
            document.getElementById('template_title').style.display = 'block'
            document.getElementById('template_description').style.display = 'block'
            document.getElementById('template_formtype_container').style.display = 'flex'
            document.getElementById('template_category_container').style.display = 'flex'
            document.getElementById('template_subcategory_container').style.display = 'flex'
          }
        }
      })
      // end init
    }
    return () => (isSubscribed = false)
  }, [initFlag])

  // Get the categories for the dropdown after form type is selected
  useEffect(() => {
    let isSubscribed = true
    if (isSubscribed) {
      if (formType != 0) {
        getCategories()
      }
    }
    return () => (isSubscribed = false)
  }, [formType])
  useEffect(() => {
    let isSubscribed = true
    if (isSubscribed) {
      // Adding a margin-right to all radio buttons
      const radioelements = Array.from(document.getElementsByClassName('field-radio-group'))
      const checkboxelements = Array.from(document.getElementsByClassName('field-boolean'))
      const selectelements = Array.from(document.getElementsByTagName('select'))

      radioelements.map(radioelement => {
        Array.from(radioelement.children).map(children => {
          Array.from(children.getElementsByTagName('input')).map(input => {
            input.style = 'margin-right: 5px'
          })
        })
      })
      // Adding a margin-right to all checkboxes
      checkboxelements.map(checkboxelement => {
        Array.from(checkboxelement.children).map(children => {
          Array.from(children.getElementsByTagName('input')).map(input => {
            input.style = 'margin-right: 10px'
          })
        })
      })
      // Map through each select element and set their width and margins
      selectelements.map(selectelement => {
        selectelement.style = 'margin-right: auto; margin-left: auto; width: 83.5%;'
      })
      disableObjectNameFormGroup()
      disableExtraCheckboxes()
    }
    return () => (isSubscribed = false)
  }, [schema, uischema])
  useEffect(() => {
    let isSubscribed = true
    if (isSubscribed) {
      console.log('setting category')
      if (category != 0) {
        switch (parseInt(formType)) {
          case 2:
            let subcategoryoptions = []
            categoryOptions.map(categoryoption => {
              if (categoryoption['value'] == category) {
                Object.values(categoryoption['subcategories']).map(subcategory => {
                  subcategoryoptions.push({
                    label: subcategory['title'],
                    value: subcategory['id'],
                  })
                })
                setSubCategoryOptions([...subcategoryoptions])
              }
            })
            break
        }
      }
    }
    return () => (isSubscribed = false)
  }, [category])
  const getCategories = async () => {
    console.log(formType)
    const categorypath = '/forms/categories?form_type=' + formType
    const fetchurl = baseurl + categorypath

    await axios.get(fetchurl).then(response => {
      let categoryoptions = []
      switch (parseInt(formType)) {
        case 2:
          // Report incidents
          Object.values(response.data.data).map(category => {
            categoryoptions.push({
              label: category['title'],
              value: category['id'],
              subcategories: category['subcategories'],
            })
            console.log(category['subcategories'])
          })
          setCategoryOptions([...categoryoptions])
          setSubCategoryDisplay('flex')
          break
        case 3:
          Object.values(response.data.data).map(category => {
            categoryoptions.push({
              label: category['title'],
              value: category['id'],
            })
          })
          setCategoryOptions([...categoryoptions])
          document.getElementById('subcategory_select').selectedIndex = 0
          setSubCategoryDisplay('none')
          break
      }
    })
  }
  const formTypes = [
    { label: 'Incident', value: 2 },
    { label: 'Survey', value: 3 },
  ]

  const disableObjectNameFormGroup = () => {
    var object_name_h5 = document.getElementsByTagName('h5')

    Array.from(object_name_h5).map(object_h5 => {
      if (object_h5.textContent == 'Object Name ') {
        if (object_h5.parentElement.style.display != 'none') {
          object_h5.parentElement.style.display = 'none'
        }
        //console.log("Found object name")
        //console.log(object_h5.textContent)
        //console.log(object_h5.parentElement)
      }
      console.log(object_h5.textContent)
      if (object_h5.textContent == 'Section Object Name ') {
        if (object_h5.parentElement.style.display != 'none') {
          object_h5.parentElement.style.display = 'none'
        }
        //console.log("Found section object name")
        //console.log(object_h5.textContent)
        //console.log(object_h5.parentElement)
      }
    })
  }
  const disableExtraCheckboxes = () => {
    var all_labels = document.getElementsByTagName('label')

    Array.from(all_labels).map(label => {
      if (label.textContent == 'Display label is different from value') {
        if (label.parentElement.parentElement.style.display != 'none') {
          label.parentElement.parentElement.style.display = 'none'
          //console.log("Found unwanted checkbox")
          //console.log(label.textContent)
        }
      }
      if (label.textContent == 'Force number') {
        if (label.parentElement.parentElement.style.display != 'none') {
          label.parentElement.parentElement.style.display = 'none'
          //console.log("Found unwanted force number checkbox")
          //console.log(label.textContent)
        }
      }
    })
  }

  const handleChange = (newSchema, newUiSchema) => {
    console.log(newSchema)
    console.log(newUiSchema)
    try {
      var o = JSON.parse(newSchema)
      var o2 = JSON.parse(newUiSchema)

      if (o && typeof o === 'object') {
        setSchema(newSchema)
      }

      if (o2 && typeof o2 === 'object') {
        setUiSchema(newUiSchema)
      }
    } catch (e) {
      console.log('Invalid schemas')
      setSchema('{}')
      setUiSchema('{}')
    }
  }

  const CustomDescriptionField = ({ description }) => {
    if (description !== undefined) {
      //console.log("This field has a description")
      if (description === JSON.parse(schema).description) {
        return <></>
      } else {
        return (
          <>
            <div style={{ marginBottom: 2, fontSize: 15 }}>{description}</div>
          </>
        )
      }
    } else {
      return <></>
    }
  }
  const CustomTitleField = ({ title, required }) => {
    const legend = required ? title + '*' : title
    if (title === JSON.parse(schema).title) {
      //console.log("This is the first title. Skip and do not display this title")
      return <></>
    } else {
      return (
        <div className="my-1">
          <h5 style={{ fontSize: 1.25 + 'rem' }}>{legend}</h5>
        </div>
      )
    }
  }
  function CustomFieldTemplate(props) {
    const { id, classNames, label, help, required, description, errors, children } = props
    if (props.schema.type === 'object') {
      if (props.schema.title === JSON.parse(schema).title) {
        // Not displaying the title and description
        return (
          <div className={classNames}>
            {children}
            {errors}
            {help}
          </div>
        )
      } else {
        // Style the section titles and description here
        return (
          <div className={classNames}>
            {children}
            {errors}
            {help}
          </div>
        )
      }
    } else if (props.schema.type === 'boolean') {
      return (
        <div style={{ marginBottom: 0 }} className={classNames}>
          {description}
          {children}
          {errors}
          {help}
        </div>
      )
    } else if (props.schema.type === 'array' && props.schema.items !== undefined) {
      // multiple files
      return (
        <div className={classNames}>
          {children}
          {errors}
          {help}
        </div>
      )
    } else if (props.schema.type === 'string' && props.schema.format == 'data-url') {
      // single file
      return (
        <div className={classNames}>
          {children}
          {errors}
          {help}
        </div>
      )
    } else if (props.schema.format == 'date-time') {
      return (
        <div className={classNames}>
          <label style={{ marginLeft: 0, marginTop: 10, marginBottom: 10 }} htmlFor={id}>
            {label}
            {required ? '*' : null}
          </label>
          {description}
          {children}
          {errors}
          {help}
        </div>
      )
    } else {
      return (
        <div className={classNames}>
          <label style={{ marginLeft: 0, marginTop: 10, marginBottom: 10 }} htmlFor={id}>
            {label}
            {required ? '*' : null}
          </label>
          {description}
          {children}
          {errors}
          {help}
        </div>
      )
    }
  }
  const fields = {
    TitleField: CustomTitleField,
    DescriptionField: CustomDescriptionField,
  }
  const widgets = {
    FileWidget: props => <FileWidget {...props} />,
    DateTimeWidget: DateTimeWidget,
  }

  const customFormInputs = {
    fileAttachment: {
      displayName: 'Attach File',
      matchIf: [
        {
          types: ['array'],
        },
      ],
      defaultDataSchema: {
        type: 'array',
        items: {
          type: 'string',
          format: 'data-url',
        },
      },
      defaultUiSchema: {},
      cardBody: (parameters, onChange) => <div></div>,
      modalBody: (parameters, onChange) => <div>Extra editing options in modal appear hear</div>,
    },
  }

  const validateTemplateDetails = () => {
    var flag = true
    console.log(subCategory)
    if (category == 0) {
      flag = false
    }
    if (formType == 0) {
      flag = false
    } else if (formType == 2) {
      if (subCategory == 0) {
        flag = false
      }
    }
    if (document.getElementById('template_title').value == '') {
      flag = false
    }
    if (document.getElementById('template_description').value == '') {
      flag = false
    }
    // Check if organisation id is present
    if (props.userData != undefined) {
      if (props.userData['data']['organisation']['id'] == null) {
        flag = false
      }
    } else {
      flag = false
    }

    return flag
  }
  const submitNewTemplate = async () => {
    const path = '/forms/templates'
    const fetchurl = baseurl + path

    if (validateTemplateDetails()) {
      const title = document.getElementById('template_title').value
      const description = document.getElementById('template_description').value
      const organisation_id = props.userData['data']['organisation']['id']
      const data = {
        category_id: category,
        subcategory_id: subCategory,
        description: description,
        title: title,
        type_id: formType,
        template: JSON.stringify(schema),
        ui_schema: JSON.stringify(uischema),
        organisation_id: organisation_id,
      }
      /* 
      if (formType == 3) {
        data['subcategory_id'] = 3
      } */
      console.log('Passed validation')
      console.log(data)
      await axios
        .post(fetchurl, data)
        .then(response => {
          console.log(response)
          // Redirect to Template Manager
          history.push({
            pathname: '/TemplateManager',
          })
        })
        .catch(error => {
          console.error('Error submitting form template: ', error.stack)
        })
    } else {
      console.log('Failed validation')
    }
  }

  const enableTemplateDetails = () => {
    //document.getElementById('template_disable').style.display = 'none'
    document.getElementById('template_save_button').style.display = 'inline-block'
    document.getElementById('template_cancel_button').style.display = 'inline-block'
    document.getElementById('template_edit_button').style.display = 'none'
    // Show Form Inputs
    document.getElementById('template_title').style.display = 'block'
    document.getElementById('template_description').style.display = 'block'
    document.getElementById('template_formtype_container').style.display = 'flex'
    document.getElementById('template_category_container').style.display = 'flex'
    document.getElementById('template_subcategory_container').style.display = 'flex'

    /* // set dropdown values using current value
    let formselect = document.getElementById('template_formtype_select')
    let categoryselect = document.getElementById('category_select')
    let subcategoryselect = document.getElementById('subcategory_select')
    let loopflag = true
    Array.from(formselect.options).map((formtypeoption, index) => {
      if (loopflag) {
        if (formtypeoption.value == formType) {
          //formselect.selectedIndex = index
          // set category
          Array.from(categoryselect.options).map((categoryoption, index1) => {
            if (loopflag) {
              if (categoryoption.value == category) {
                //categoryselect.selectedIndex = index1
                console.log("updating category value")
                if (formType == 3) {
                  loopflag = false
                  console.log("test")
                }
                else {
                  // set subcategory
                  Array.from(subcategoryselect.options).map((subcategoryoption, index2) => {
                    if (loopflag) {
                      console.log("test 3")
                      console.log(subcategoryoption)
                      console.log(subCategory)
                      if (subcategoryoption.value == subCategory) {
                        console.log("Test 2")
                        subcategoryselect.selectedIndex = index2
                        loopflag = false
                      }
                    }
                  })
                }
              }
            }
          })
        }
      }
    }) */
  }
  const disableTemplateDetails = () => {
    //document.getElementById('template_disable').style.display = 'block'
    document.getElementById('template_save_button').style.display = 'none'
    document.getElementById('template_cancel_button').style.display = 'none'
    document.getElementById('template_edit_button').style.display = 'inline-block'
    // Hide Form Inputs
    document.getElementById('template_title').style.display = 'none'
    document.getElementById('template_description').style.display = 'none'
    document.getElementById('template_formtype_container').style.display = 'none'
    document.getElementById('template_category_container').style.display = 'none'
    document.getElementById('template_subcategory_container').style.display = 'none'
  }
  const saveTemplateDetails = async () => {
    // update template details in database
    console.log('Saving new template principle details')
    if (props.templateObj != undefined) {
      const path = '/forms/templates/' + props.templateObj['id']
      const fetchurl = baseurl + path

      if (validateTemplateDetails()) {
        const data = {
          title: document.getElementById('template_title').value,
          description: document.getElementById('template_description').value,
          type_id: formType,
          category_id: category,
        }
        if (formType == 2) {
          data['subcategory_id'] = subCategory
        }
        if (formType == 3) {
          data['subcategory_id'] = 1
        }

        console.log('Saving Template Details: ')
        console.log(data)

        await axios
          .put(fetchurl, data)
          .then(response => {
            console.log(response)
            // Show new details
            document.getElementById('current_template_title').innerHTML = data['title']
            props.templateObj['category_id'] = data['category_id']
            props.templateObj['title'] = data['title']
            props.templateObj['description'] = data['description']
            props.templateObj['type_id'] = data['type_id']
            document.getElementById('current_template_description').innerHTML = data['description']
            document.getElementById('current_template_formtype').innerHTML = templateTypes[data['type_id']]
            document.getElementById('current_template_category').innerHTML = categoriesObj[data['category_id']]
            if (data['subcategory_id'] != undefined) {
              document.getElementById('current_template_subcategory').innerHTML =
                subCategoriesObj[data['subcategory_id']]
              props.templateObj['subcategory_id'] = data['subcategory_id']
            }
            console.log(props.templateObj)
            // Hide Form Inputs
            document.getElementById('template_title').style.display = 'none'
            document.getElementById('template_description').style.display = 'none'
            document.getElementById('template_formtype_container').style.display = 'none'
            document.getElementById('template_category_container').style.display = 'none'
            document.getElementById('template_subcategory_container').style.display = 'none'
          })
          .catch(error => {
            console.error('Error saving form template changes: ', error, error.stack)
          })

        //document.getElementById('template_disable').style.display = 'block'
        document.getElementById('template_save_button').style.display = 'none'
        document.getElementById('template_cancel_button').style.display = 'none'
        document.getElementById('template_edit_button').style.display = 'inline-block'
      }
    }
  }
  const validateTemplateSchema = () => {
    if (schema == '{}') {
      console.log('Trying to submit an empty schema, NOT PROCEEDING..')
      return false
    }
    return true
  }
  const submitNewTemplateVersion = async () => {
    // update template details in database
    console.log('Saving new template version')
    if (props.templateObj != undefined) {
      if (props.versionSum != undefined) {
        const path = '/forms/templates/' + props.templateObj['id'] + '?new_version=true'
        const fetchurl = baseurl + path
        console.log('Num of versions :' + props.versionSum)
        if (validateTemplateSchema()) {
          const data = {
            description: document.getElementById('current_template_description').textContent,
            template: JSON.stringify(schema),
            ui_schema: JSON.stringify(uischema),
            version: props.versionSum + 1,
            published: false,
            expired: false,
          }
          console.log('Creating New Template Version: ')
          console.log(data)

          await axios
            .put(fetchurl, data)
            .then(response => {
              console.log(response)
              // Redirect to Template Manager
              history.push({
                pathname: '/TemplateManager',
                viewingTemplate: props.templateObj,
              })
            })
            .catch(error => {
              console.error('Error saving template version changes: ', error, error.stack)
            })
        }
      }
    }
  }
  const submitTemplateVersion = async () => {
    // update template details in database
    console.log('Editing template version')
    if (props.templateObj != undefined && props.selectedVersion != undefined) {
      var instance_flag = false
      // Query form instances using version and template_id
      // if response.data > 0 set instance_flag to false
      const instance_path =
        '/forms/property?version=' + props.selectedVersion['version'] + '&template_id=' + props.templateObj['id']
      const instance_fetchurl = baseurl + instance_path

      await axios
        .get(instance_fetchurl)
        .then(response => {
          console.log(response)
          if (response.data.data.length == 0) {
            console.log('Could not find any form instances with this template id and version')
            console.log('It is safe to edit it')
            instance_flag = true
          }
        })
        .then(async () => {
          console.log('test')
          if (instance_flag) {
            const path = '/forms/templates/' + props.templateObj['id'] + '?version=' + props.selectedVersion['version']
            const fetchurl = baseurl + path

            if (validateTemplateSchema()) {
              const data = {
                description: document.getElementById('current_template_description').textContent,
                template: JSON.stringify(schema),
                ui_schema: JSON.stringify(uischema),
              }

              console.log('Saving Template Version: ')
              console.log(data)
              await axios
                .put(fetchurl, data)
                .then(response => {
                  console.log(response)
                  // Redirect to Template Manager
                  history.push({
                    pathname: '/TemplateManager',
                    state: {
                      viewingTemplate: props.templateObj,
                    },
                  })
                })
                .catch(error => {
                  console.error('Error saving template version changes: ', error, error.stack)
                })
            }
          } else {
            console.log('Found existing form instances using this version')
            console.log('Saving these changes will instead create a new version')
            var version_query = -1
            getTemplateVersions(props.templateObj['id']).then(versions => {
              version_query = versions.length
            })
            console.log('Current versions: ' + version_query)
            // new template version
            if (validateTemplateSchema()) {
              const path = '/forms/templates/' + props.templateObj['id'] + '?new_version=true'
              const fetchurl = baseurl + path
              const data = {
                description: document.getElementById('current_template_description').textContent,
                template: JSON.stringify(schema),
                ui_schema: JSON.stringify(uischema),
                version: version_query + 1,
                published: false,
                expired: false,
              }
              console.log('Creating New Template Version: ')
              console.log(data)

              await axios
                .put(fetchurl, data)
                .then(async response => {
                  console.log(response)
                  // Redirect to Template Manager
                  history.push({
                    pathname: '/TemplateManager',
                    state: {
                      viewingTemplate: props.templateObj,
                    },
                  })
                })
                .catch(error => {
                  console.error('Error saving template version changes: ', error, error.stack)
                })
            }
          }
        })
    }
  }
  const getTemplateVersions = async template_id => {
    const path = '/forms/templates?template_id='
    const fetchurl = baseurl + path + template_id
    var data
    await axios.get(fetchurl).then(response => {
      data = response.data.data
    })
    return data
  }
  // Delay for x seconds function
  async function delayForXSeconds(time) {
    console.log('start timer')
    await new Promise(resolve => setTimeout(resolve, time))
    console.log('after' + time / 1000 + ' second(s)')
  }
  //function to save template as publish

  const submitAndPublishTemplate = async () => {
    // check if template has latest_version published

    // update template details in database
    if (props.templateObj != undefined) {
      // new template version or edit template version
      if (props.is_published) {
        // disable published version
        const unpublish_path =
          '/forms/templates/' + props.templateObj['id'] + '?version=' + props.templateObj['latest_version']
        const unpublish_fetchurl = baseurl + unpublish_path
        const unpublish_data = {
          published: false,
        }
        console.log('Setting template version with id ' + props.templateObj['latest_version'] + ' as not published: ')
        console.log(unpublish_data)
        await axios
          .put(unpublish_fetchurl, unpublish_data)
          .then(unpublish_response => {
            console.log(unpublish_response)
          })
          .catch(error => {
            console.error('Error saving template version changes: ', error, error.stack)
          })
      }
      if (props.versionSum != undefined) {
        // new template version
        if (validateTemplateSchema()) {
          const path = '/forms/templates/' + props.templateObj['id'] + '?new_version=true'
          const fetchurl = baseurl + path
          console.log('Num of versions :' + props.versionSum)
          const data = {
            description: document.getElementById('current_template_description').textContent,
            template: JSON.stringify(schema),
            ui_schema: JSON.stringify(uischema),
            version: props.versionSum + 1,
            published: true,
            expired: false,
          }
          console.log('Creating and Publishing New Template Version: ')
          console.log(data)

          await axios
            .put(fetchurl, data)
            .then(async response => {
              console.log(response)
              props.templateObj['latest_version'] = props.versionSum + 1

              // update template latest_version
              const path = '/forms/templates/' + props.templateObj['id']
              const fetchurl = baseurl + path
              console.log(fetchurl)
              const data = {
                latest_version: props.versionSum + 1,
              }

              console.log('Setting template version as latest_version: ')
              console.log(data)

              await axios
                .put(fetchurl, data)
                .then(async response => {
                  console.log(response)

                  console.log('Updating local template instance')

                  setAlertDisplay('block')
                  setAlertMessage('Successfully created and published new template version.')
                  setAlertType('alert alert-success alert-dismissible fade show')
                  await delayForXSeconds(3000)
                  // Redirect to Template Manager
                  history.push({
                    pathname: '/TemplateManager',
                    state: {
                      viewingTemplate: props.templateObj,
                    },
                  })
                })
                .catch(error => {
                  console.error('Error saving template version changes: ', error, error.stack)
                  setAlertDisplay('block')
                  setAlertMessage('Error setting template active version.')
                  setAlertType('alert alert-danger alert-dismissible fade show')
                })
            })
            .catch(error => {
              console.error('Error saving template version changes: ', error, error.stack)
              setAlertDisplay('block')
              setAlertMessage('Error creating new template and version.')
              setAlertType('alert alert-danger alert-dismissible fade show')
            })
        }
      } else {
        // edit template version
        var instance_flag = false
        // Query form instances using version and template_id
        // if response.data > 0 set instance_flag to false
        const instance_path =
          '/forms/property?version=' + props.selectedVersion['version'] + '&template_id=' + props.templateObj['id']
        const instance_fetchurl = baseurl + instance_path

        await axios
          .get(instance_fetchurl)
          .then(response => {
            console.log(response)
            if (response.data.data.length == 0) {
              console.log('Could not find any form instances with this template id and version')
              console.log('It is safe to edit it')
              instance_flag = true
            }
          })
          .then(async () => {
            console.log('test')
            if (instance_flag) {
              const path =
                '/forms/templates/' + props.templateObj['id'] + '?version=' + props.selectedVersion['version']
              const fetchurl = baseurl + path

              if (validateTemplateSchema()) {
                const data = {
                  description: document.getElementById('current_template_description').textContent,
                  template: JSON.stringify(schema),
                  ui_schema: JSON.stringify(uischema),
                  published: true,
                }

                console.log('Saving Template Version: ')
                console.log(data)
                await axios
                  .put(fetchurl, data)
                  .then(async response => {
                    console.log(response)
                    props.templateObj['latest_version'] = props.selectedVersion['version']

                    // update template latest_version
                    const path = '/forms/templates/' + props.templateObj['id']
                    const fetchurl = baseurl + path
                    console.log(fetchurl)
                    const data = {
                      latest_version: props.selectedVersion['version'],
                    }

                    console.log('Setting template version as latest_version: ')
                    console.log(data)

                    await axios
                      .put(fetchurl, data)
                      .then(async response => {
                        console.log(response)

                        console.log('Updating local template instance')
                        setAlertDisplay('block')
                        setAlertMessage('Successfully updated and published template version.')
                        setAlertType('alert alert-success alert-dismissible fade show')
                        await delayForXSeconds(3000)
                        // Redirect to Template Manager
                        history.push({
                          pathname: '/TemplateManager',
                          state: {
                            viewingTemplate: props.templateObj,
                          },
                        })
                      })
                      .catch(error => {
                        console.error('Error saving template version changes: ', error, error.stack)
                        setAlertDisplay('block')
                        setAlertMessage('Error setting active version.')
                        setAlertType('alert alert-danger alert-dismissible fade show')
                      })
                  })
                  .catch(error => {
                    console.error('Error saving template version changes: ', error, error.stack)
                    setAlertDisplay('block')
                    setAlertMessage('Error updating template version.')
                    setAlertType('alert alert-danger alert-dismissible fade show')
                  })
              }
            } else {
              console.log('Found existing form instances using this version')
              console.log('Saving these changes will instead create a new version')
              var version_query = -1
              await getTemplateVersions(props.templateObj['id']).then(versions => {
                version_query = versions.length
              })
              console.log('Current versions: ' + version_query)
              // new template version
              if (validateTemplateSchema()) {
                const path = '/forms/templates/' + props.templateObj['id'] + '?new_version=true'
                const fetchurl = baseurl + path
                const data = {
                  description: document.getElementById('current_template_description').textContent,
                  template: JSON.stringify(schema),
                  ui_schema: JSON.stringify(uischema),
                  version: version_query + 1,
                  published: true,
                  expired: false,
                }
                console.log('Creating New Template Version: ')
                console.log(data)

                await axios
                  .put(fetchurl, data)
                  .then(async response => {
                    console.log(response)
                    props.templateObj['latest_version'] = version_query + 1

                    // update template latest_version
                    const path = '/forms/templates/' + props.templateObj['id']
                    const fetchurl = baseurl + path
                    console.log(fetchurl)
                    const data = {
                      latest_version: version_query + 1,
                    }

                    console.log('Setting template version as latest_version: ')
                    console.log(data)

                    await axios
                      .put(fetchurl, data)
                      .then(async response => {
                        console.log(response)

                        console.log('Updating local template instance')
                        setAlertDisplay('block')
                        setAlertMessage(
                          'Template version is already in use. Successfully created and published a new template version.',
                        )
                        setAlertType('alert alert-success alert-dismissible fade show')
                        await delayForXSeconds(3000)
                        // Redirect to Template Manager
                        history.push({
                          pathname: '/TemplateManager',
                          state: {
                            viewingTemplate: props.templateObj,
                          },
                        })
                      })
                      .catch(error => {
                        console.error('Error saving template version changes: ', error, error.stack)
                        setAlertDisplay('block')
                        setAlertMessage('Error setting active version.')
                        setAlertType('alert alert-danger alert-dismissible fade show')
                      })
                  })
                  .catch(error => {
                    console.error('Error saving template version changes: ', error, error.stack)
                    setAlertDisplay('block')
                    setAlertMessage('Template version is already in use. Error creating new template version.')
                    setAlertType('alert alert-danger alert-dismissible fade show')
                  })
              }
            }
          })
      }
    } else {
      // creating new template and new version
      const path = '/forms/templates'
      const fetchurl = baseurl + path
      var template_instance_id = -1
      if (validateTemplateDetails()) {
        const title = document.getElementById('template_title').value
        const description = document.getElementById('template_description').value
        const organisation_id = props.userData['data']['organisation']['id']
        const data = {
          category_id: category,
          subcategory_id: subCategory,
          description: description,
          title: title,
          type_id: formType,
          template: JSON.stringify(schema),
          ui_schema: JSON.stringify(uischema),
          organisation_id: organisation_id,
        }
        /* 
        if (formType == 3) {
          data['subcategory_id'] = 3
        } */
        console.log('Passed validation')
        console.log(data)
        await axios
          .post(fetchurl, data)
          .then(async response => {
            console.log(response)
            template_instance_id = response.data.data.instance_id
            const publish_path = '/forms/templates/' + template_instance_id + '?version=' + 1
            const publish_fetchurl = baseurl + publish_path
            const publish_data = {
              published: true,
            }
            console.log('Setting template version with id ' + 1 + ' as published: ')
            console.log(publish_data)
            await axios
              .put(publish_fetchurl, publish_data)
              .then(async publish_response => {
                console.log(publish_response)
                // Redirect to Template Manager
                data['id'] = template_instance_id
                data['latest_version'] = 1
                setAlertDisplay('block')
                setAlertMessage('Successfully created and published a new template and version.')
                setAlertType('alert alert-success alert-dismissible fade show')
                await delayForXSeconds(3000)
                history.push({
                  pathname: '/TemplateManager',
                  state: {
                    viewingTemplate: data,
                  },
                })
              })
              .catch(error => {
                console.error('Error saving template version changes: ', error, error.stack)
                setAlertDisplay('block')
                setAlertMessage('Error publishing template.')
                setAlertType('alert alert-danger alert-dismissible fade show')
              })
          })
          .catch(error => {
            console.error('Error submitting form template: ', error.stack)
            setAlertDisplay('block')
            setAlertMessage('Error creating new template and version.')
            setAlertType('alert alert-danger alert-dismissible fade show')
          })
      } else {
        console.log('Failed validation')
      }
    }
  }

  const selectFormType = form_type => {
    console.log(form_type.value + ' selected form')
    document.getElementById('category_select').selectedIndex = 0
    setFormType(form_type.value)
    if (form_type.value == 0) {
      document.getElementById('category_select').disabled = true
      document.getElementById('subcategory_select').disabled = true
      document.getElementById('subcategory_select').selectedIndex = 0
      setCategory(0)
      setSubCategory(0)
    } else if (form_type.value == 3) {
      setCategory(0)
      setSubCategory(0)
    } else {
      document.getElementById('category_select').disabled = false
    }
  }
  const selectCategory = category => {
    console.log('category updated')
    document.getElementById('subcategory_select').selectedIndex = 0
    setCategory(category.value)
    if (category.value == 0) {
      document.getElementById('subcategory_select').disabled = true
      setSubCategory(0)
    } else {
      document.getElementById('subcategory_select').disabled = false
    }
  }
  const selectSubCategory = subcategory => {
    setSubCategory(subcategory.value)
  }
  const parseDataSchema = callback => {
    var dataSchemaFile = document.getElementById('schema_file').files
    var dataSchema = ''
    if (dataSchemaFile.length > 0) {
      try {
        var fr = new FileReader()

        fr.onload = function (e) {
          var result = JSON.parse(e.target.result)
          console.log(e)
          var formatted = JSON.stringify(result, null, 2)
          console.log(formatted)
          dataSchema = formatted
          callback(dataSchema)
        }
        fr.readAsText(dataSchemaFile.item(0))
      } catch (e) {
        console.log(e)
        alert('Invalid Data Schema JSON file. Could not parse!')
      }
    }
  }
  const parseUISchema = callback => {
    var uiSchemaFile = document.getElementById('ui_schema_file').files
    var uiSchema = ''
    if (uiSchemaFile.length > 0) {
      try {
        var fr = new FileReader()

        fr.onload = function (e) {
          var result = JSON.parse(e.target.result)
          console.log(e)
          var formatted = JSON.stringify(result, null, 2)
          console.log(formatted)
          uiSchema = formatted
          callback(uiSchema)
        }
        fr.readAsText(uiSchemaFile.item(0))
      } catch (e) {
        console.log(e)
        alert('Invalid Data Schema JSON file. Could not parse!')
      }
    }
  }
  const parseSchemaFiles = async () => {
    var schema = ''
    var ui_schema = ''
    parseDataSchema(function (dataSchema) {
      console.log(dataSchema)
      setSchema(dataSchema)
    })
    parseUISchema(function (uiSchema) {
      console.log(uiSchema)
      setUiSchema(uiSchema)
    })
  }
  const goAllTemplates = () => {
    history.push({
      pathname: '/TemplateManager',
    })
  }
  const goTemplateDetails = () => {
    history.push({
      pathname: '/TemplateManager',
      state: {
        viewingTemplate: props.templateObj,
      },
    })
  }
  const goBack = () => {
    if (props.templateObj != undefined) {
      goTemplateDetails()
    } else {
      goAllTemplates()
    }
  }
  const dismissNotification = () => {
    setAlertDisplay('none')
  }
  return (
    <>
      <div
        style={{
          position: 'absolute',
          left: 0,
          marginLeft: 40,
          display: 'flex',
          alignItems: 'center',
        }}>
        <a style={{ cursor: 'pointer', display: 'flex' }} onClick={goAllTemplates}>
          <img style={{ marginRight: 10 }} src={require('../../images/icons/Back.svg')}></img>
          <p style={{ marginTop: 'auto', marginBottom: 'auto' }}>All Templates </p>
        </a>
        <p style={{ marginLeft: 10, marginRight: 10, marginBottom: 'auto', marginTop: 'auto' }}> / </p>

        <a style={{ cursor: 'pointer', display: 'flex' }} onClick={goTemplateDetails}>
          <p style={{ marginTop: 'auto', marginBottom: 'auto' }}>
            {props.templateObj ? props.templateObj['title'] : 'New Template'}
          </p>
        </a>
        <p style={{ marginLeft: 10, marginRight: 10, marginBottom: 'auto', marginTop: 'auto' }}> / </p>

        <p
          style={{
            marginRight: 10,
            marginBottom: 'auto',
            marginTop: 'auto',
            display: props.editMode == 'template' ? 'none' : 'block',
          }}>
          {props.selectedVersion ? props.selectedVersion['id'] : ''}
        </p>
        <p
          style={{
            marginRight: 10,
            marginBottom: 'auto',
            marginTop: 'auto',
            display: props.createMode == 'version' ? 'none' : 'block',
          }}>
          {props.editMode == 'template' ? 'Edit' : ''}
        </p>
        <p style={{ marginRight: 10, marginBottom: 'auto', marginTop: 'auto' }}>
          {props.createMode == 'version' ? 'New Version' : ''}
        </p>
      </div>

      {/* Form Template Details */}
      <Card style={{ marginTop: 40 }}>
        {/* <div id="template_disable" style={{ display: 'none' }} className={overlayclass}></div> */}
        <Card.Header>Template Builder - Create Form</Card.Header>
        <Card.Body>
          <div style={{ display: 'flex', flexDirection: 'column', marginRight: 'auto', marginLeft: 'auto' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: 100 + '%',
                marginBottom: 15,
              }}>
              <div style={{ width: 15 + '%', marginTop: 'auto', marginBottom: 'auto', fontSize: 15 }}>Title</div>
              <span
                style={{ width: 15 + '%', marginTop: 'auto', marginBottom: 'auto' }}
                id="current_template_title"></span>
              <Input id="template_title" type="text" style={{ display: 'none' }} />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: 100 + '%',
                marginBottom: 20,
              }}>
              <div style={{ width: 15 + '%', marginTop: 'auto', marginBottom: 'auto', fontSize: 15 }}>Description</div>
              <span
                style={{ width: 15 + '%', marginTop: 'auto', marginBottom: 'auto' }}
                id="current_template_description"></span>
              <Input id="template_description" style={{ display: 'none' }}></Input>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: 100 + '%',
              }}>
              <div style={{ width: 15 + '%', fontSize: 15 }}>Form Type</div>
              <span style={{ width: 15 + '%' }} id="current_template_formtype"></span>
              <div
                className={c.dropdownContainer}
                id="template_formtype_container"
                style={{ display: 'none', width: 30 + '%' }}>
                <select
                  id="template_formtype_select"
                  onChange={e => selectFormType(e.target.options[e.target.selectedIndex])}>
                  <option value={0}>Select Form Type</option>
                  <option value={2}>Incident Report</option>
                  <option value={3}>Survey</option>
                </select>
              </div>

              <div style={{ display: 'none' }} className={c.dropdown}>
                <button className={c.dropbtn}>
                  Dropdown{' '}
                  <img
                    style={{ width: 15, height: 15, marginBottom: 3, marginLeft: 5 }}
                    src={require('../../images/arrow-down.png')}></img>
                </button>
                <div className={c.dropdownContent}>
                  <a href="#">Link 1</a>
                  <a href="#">Link 2</a>
                  <a href="#">Link 3</a>
                </div>
              </div>

              {/*  

             <DropdownBootstrap
                className={c.dropdownStyle}
                style={{ backgroundColor: 'transparent', color: 'black' }}
                options={formTypes}
                title="Form Type"
                updateOption={setFormType}
              />
             */}
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: 15,
                width: 100 + '%',
              }}>
              <div style={{ width: 15 + '%', fontSize: 15 }}>Category</div>
              <span style={{ width: 15 + '%' }} id="current_template_category"></span>
              <div
                className={c.dropdownContainer}
                id="template_category_container"
                style={{ display: 'none', width: 30 + '%' }}>
                <select id="category_select" onChange={e => selectCategory(e.target.options[e.target.selectedIndex])}>
                  <option key="categoryoption-default" value={0}>
                    Select Category
                  </option>
                  {categoryOptions.map((categoryoption, i) => (
                    <option key={'categoryoption-' + i} value={categoryoption.value}>
                      {categoryoption.label}
                    </option>
                  ))}
                </select>
              </div>
              {/* <DropdownBootstrap options={categoryOptions} updateOption={setCategory} title="Category"></DropdownBootstrap> */}
            </div>

            <div
              style={{
                display: subCategoryDisplay,
                flexDirection: 'row',
                marginTop: 15,
                width: 100 + '%',
              }}>
              <div style={{ width: 15 + '%', fontSize: 15 }}>Sub-Category</div>
              <span style={{ width: 15 + '%' }} id="current_template_subcategory"></span>
              <div
                className={c.dropdownContainer}
                id="template_subcategory_container"
                style={{ display: 'none', width: 30 + '%' }}>
                <select
                  id="subcategory_select"
                  onChange={e => selectSubCategory(e.target.options[e.target.selectedIndex])}>
                  <option key="subcategoryoption-default" value={0}>
                    Select Sub Category
                  </option>
                  {subCategoryOptions.map((subcategoryoption, i) => (
                    <option key={'subcategoryoption-' + i} value={subcategoryoption.value}>
                      {subcategoryoption.label}
                    </option>
                  ))}
                </select>
              </div>
              {/* <DropdownBootstrap options={subCategoryOptions} updateOption={setSubCategory} title="Sub-Category"></DropdownBootstrap> */}
            </div>
          </div>
        </Card.Body>
      </Card>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
        <Button
          id="cancel_go_back_button"
          className="btn btn-danger"
          type="button"
          onClick={goBack}
          style={{ display: 'none', marginRight: 'auto' }}>
          Cancel
        </Button>
        <Button
          id="template_cancel_button"
          style={{ display: 'none', marginRight: 'auto' }}
          className="btn btn-danger"
          type="button"
          onClick={disableTemplateDetails}>
          Cancel
        </Button>
        <Button
          id="template_save_button"
          style={{ display: 'none', marginLeft: 20 }}
          className="btn btn-success"
          type="button"
          onClick={saveTemplateDetails}>
          Save Changes
        </Button>

        <Button id="template_edit_button" className="btn btn-dark" onClick={enableTemplateDetails}>
          Edit
        </Button>
      </div>

      {/* Form Builder Section */}
      <div style={{ display: 'none' }} id="formBuilder_Section">
        <Tabs
          style={{ marginTop: 30, fontWeight: 500 }}
          defaultActiveKey="form"
          id="uncontrolled-tab-example"
          className="mb-3">
          <Tab eventKey="form" title="Form">
            <Card>
              <Card.Body>
                <FormBuilder
                  schema={schema}
                  uischema={uischema}
                  onChange={(newSchema, newUiSchema) => handleChange(newSchema, newUiSchema)}
                  mods={{
                    deactivatedFormInputs: ['array'],
                    customFormInputs,
                  }}
                />
              </Card.Body>
            </Card>
          </Tab>

          {/* <Tab eventKey="upload_schema" title="Upload Form Schema">
            <Card>
              <Card.Body>
                <h4 style={{ fontSize: 14 }}>Upload Form Data and UI Schema</h4>
                <div>
                  <h5>Data Schema: </h5>
                  <input id="schema_file" type="file" />
                </div>
                <div style={{ marginTop: 10 }}>
                  <h5>UI Schema: </h5>
                  <input id="ui_schema_file" type="file" />
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button className="btn btn-primary" onClick={parseSchemaFiles}>
                    Import
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Tab> */}

          <Tab eventKey="preview" title="Preview">
            <Card>
              <Card.Header>
                {JSON.parse(schema)['title']} - {JSON.parse(schema).description}
              </Card.Header>
              <Card.Body>
                <Form
                  fields={fields}
                  widgets={widgets}
                  schema={JSON.parse(schema)}
                  uiSchema={JSON.parse(uischema)}
                  FieldTemplate={CustomFieldTemplate}>
                  <button style={{ display: 'none' }} type="submit"></button>
                </Form>
              </Card.Body>
            </Card>
          </Tab>
        </Tabs>

        <div style={{ display: 'block' }}>
          <div style={{ display: alertDisplay, marginTop: 20 }}>
            <div className={alertType} style={{ textAlign: 'center' }} role="alert">
              {alertMessage}
              <button
                type="button"
                style={{
                  paddingTop: '0.5rem',
                  paddingBottom: '0.5rem',
                  paddingLeft: '1.25rem',
                  paddingRight: '1.25rem',
                }}
                className="close"
                onClick={dismissNotification}
                aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 15 }}>
          <div>
            <Button className="btn btn-danger" type="button" onClick={goBack} style={{ display: 'flex' }}>
              Cancel
            </Button>
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              id="save_version_button"
              className="btn btn-success"
              type="button"
              style={{ display: 'none' }}
              onClick={submitTemplateVersion}>
              Save Changes
            </Button>
            <Button
              id="submit_new_version_button"
              className="btn btn-success"
              type="button"
              style={{ display: 'none' }}
              onClick={submitNewTemplateVersion}>
              Save Template
            </Button>
            <Button
              id="submit_new_template_button"
              className="btn btn-success"
              type="button"
              onClick={submitNewTemplate}
              style={{ display: 'none' }}>
              Save Template
            </Button>
            <Button
              id="publish_version_button"
              className="btn btn-success"
              type="button"
              style={{ marginLeft: 20 }}
              onClick={submitAndPublishTemplate}>
              Publish
            </Button>
          </div>
        </div>
      </div>

      {/* User Management */}
      <div className={c.progressBadge} style={{ display: 'none', marginTop: 30 }}>
        <Badge pill variant="primary">
          Step 2 of 3
        </Badge>
      </div>

      {/* User Management */}
      <Card style={{ display: 'none' }}>
        <Card.Header>Assign Template to properties</Card.Header>

        <Card.Body>
          <Table>
            <thead>
              <tr>
                <th>Checkbox</th>
                <th>Name</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ width: 10 + '%' }}>Checkbox</td>
                <td style={{ width: 90 + '%' }}>User</td>
              </tr>
            </tbody>
          </Table>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-evenly',
              width: 60 + '%',
              marginLeft: 'auto',
              marginRight: 'auto',
              marginTop: 20,
            }}>
            <Button>Assign User</Button>
            <Button light>Remove User</Button>
            <Button light>Something User</Button>
          </div>

          <div style={{ marginLeft: 'auto', marginRight: 'auto', width: 80 + '%', marginTop: 20 }}>
            <p style={{ textAlign: 'center' }}>Assigned users will be shown when a single property is selected.</p>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-evenly',
              marginTop: 20,
            }}>
            <Input type="text" name="filter" label="Filter"></Input>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              Category
              <select name="Category">
                <option>category</option>
              </select>
            </div>
            <Button light>Apply</Button>
            <Button light>Clear</Button>
          </div>

          <div style={{ marginLeft: 'auto', marginRight: 'auto', width: 80 + '%', marginTop: 20 }}>
            <p style={{ textAlign: 'center' }}>Select properties (CTRL + Left click) you would like to add users to</p>
          </div>

          <Table>
            <thead>
              <tr>
                <th>Cbox</th>
                <th>Property Name</th>
                <th>Address</th>
                <th>Post Code</th>
                <th>Region</th>
                <th>Property Reference</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ width: 5 + '%' }}>Cbox</td>
                <td style={{ width: 20 + '%' }}>Property Name</td>
                <td style={{ width: 25 + '%' }}>Address</td>
                <td style={{ width: 15 + '%' }}>Post Code</td>
                <td style={{ width: 15 + '%' }}>Region</td>
                <td style={{ width: 20 + '%' }}>Property Reference</td>
              </tr>
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </>
  )
}

export default AdminTool
