import React from "react";
import Weatherchart from "./weatherchart";
import moment from "moment";
import { Riskrating } from "../maps/riskrating";
import recommendations_extract from "../../tempjson/recommendations.json";
import ReactTooltip from "react-tooltip";

class Recommendation_notice extends React.Component {
  render() {
    return (
      <div className="notice_text">
        <p>
          <div>
            {" "}
            <img src={require("../../images/check-mark.jpg")} />
          </div>
          <div>
            {" "}
            {this.props.recommendation}
            <a href={this.props.link}>{this.props.filename}</a>
          </div>
        </p>
      </div>
    );
  }
}
class DisplayAirPollution extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentrecommendations: [],
      air_quality_type: "None selected",
    };
  }
  async componentDidMount() {
    this.setState({
      currentrecommendations: recommendations_extract.defaultfirerecommendation,
    });
  }
  Riskchartscore(air_quality_rating) {
    switch (air_quality_rating) {
      case "No Data":
        return 0;
      case "Very Low":
        return 1;

      case "Low":
        return 2;

      case "Medium":
        return 3;

      case "High":
        return 6;

      default:
        return 0;
    }
  }

  setCurrentRecommendations(air_quality_type) {
    console.log("Map Recommendaitons called");

    let setrecommendation = recommendations_extract.norecommendation;

    this.setState({ selected_crime: air_quality_type });

    switch (air_quality_type) {
      case "Very High":
        setrecommendation = recommendations_extract.air_quality_Very_High;
        break;

      case "High":
        setrecommendation = recommendations_extract.air_quality_High;
        break;

      case "Moderate":
        setrecommendation = recommendations_extract.air_quality_Medium;
        break;

      default:
        setrecommendation = recommendations_extract.norecommendation;
        break;
    }

    this.setState({ currentrecommendations: setrecommendation });
  }

  mapRecommendations = () => {
    {
    }
    if (
      this.state.currentrecommendations &&
      this.state.currentrecommendations.length
    ) {
      return this.state.currentrecommendations.map(
        (recommendationList, index) => (
          <Recommendation_notice
            recommendation={recommendationList.description}
            filename={recommendationList.filename}
            link={recommendationList.link}
          />
        )
      );
    }
  };
  render() {
    console.log("Air pollution being displayed now");

    let currentspecies = this.props.species;

    console.log("Air pollution being displayed now");

    console.log(currentspecies);

    return (
      <div className="Crimeriskhighlights">
        <h3 className="Crimeriskhighlightssheading">Air Quality Risk </h3>
        <div className="crimeoverview">
          <h3>
            Local Authority Name : {this.props.siteName} | Updated at:{" "}
            {this.props.updateTime}
          </h3>
        </div>
        <div className="Crimeriskcontent">
          <div className="crimstats">
            {currentspecies && currentspecies.length ? (
              currentspecies.map((airrisk) => (
                <div
                  id="stat_crime"
                  onClick={() =>
                    this.setCurrentRecommendations(airrisk["@AirQualityBand"])
                  }
                  a
                  data-tip
                  data-for={airrisk["@SpeciesName"]}
                >
                  <Riskrating
                    title={airrisk["@SpeciesName"]}
                    risk_rating={this.Riskchartscore(
                      airrisk["@AirQualityBand"]
                    )}
                    max_score={6}
                    rating_text={airrisk["@AirQualityBand"]}
                  />
                </div>
              ))
            ) : (
              <div
                id="stat_crime"
                onClick={() =>
                  this.setCurrentRecommendations(
                    currentspecies["@AirQualityBand"]
                  )
                }
                a
                data-tip
                data-for={currentspecies["@SpeciesName"]}
              >
                <Riskrating
                  title={currentspecies["@SpeciesName"]}
                  risk_rating={this.Riskchartscore(
                    currentspecies["@AirQualityBand"]
                  )}
                  max_score={6}
                  rating_text={currentspecies["@AirQualityBand"]}
                />
              </div>
            )}
          </div>
          <div className="crimerecommendation">
            <h4 className="crimerecommendationheading">
              Recommendations for : {this.state.selected_crime}{" "}
            </h4>
            {this.mapRecommendations()}
          </div>
        </div>
        <ReactTooltip id="Nitrogen Dioxide" aria-haspopup="true" role="example">
          <p>
            {" "}
            Nitrogen dioxide (NO2) is one of a group of gases called nitrogen
            oxides. Road transport is estimated to be responsible for about 50%
            of total emissions of nitrogen oxides, which means that nitrogen
            dioxide levels are highest close to busy roads and in large urban
            areas. Gas boilers in buildings are also a source of nitrogen
            oxides.
          </p>
        </ReactTooltip>
        <ReactTooltip id="Ozone" aria-haspopup="true" role="example">
          <p>
            Ground-level ozone – what we breathe – is formed primarily from
            photochemical reactions between two major classes of air pollutants,
            volatile organic compounds (VOC) and nitrogen oxides (NOx). These
            reactions have traditionally been viewed as depending upon the
            presence of heat and sunlight, resulting in higher ambient ozone
            concentrations in summer months.{" "}
          </p>
        </ReactTooltip>
        <ReactTooltip id="PM10 Particulate" aria-haspopup="true" role="example">
          <p>
            Particles or particulate matter (PM) are tiny bits of solids or
            liquids suspended in the air. Particles originating from road
            traffic include carbon emissions from engines, small bits of metal
            and rubber from engine wear and braking as well as dust from road
            surfaces. Others include material from building and industry as well
            as wind-blown dust, sea salt, pollens and soil particles.
          </p>
        </ReactTooltip>
      </div>
    );
  }
}

export class Air_Quality_Import extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      air_quality_output: [],
    };
  }

  async componentDidMount() {
    let siteCode = this.props.siteCode;
    let url2 =
      "http://api.erg.kcl.ac.uk/AirQuality/Hourly/MonitoringIndex/SiteCode=" +
      siteCode +
      "/Json";
    await fetch(url2)
      .then((response) => {
        return response.json();
      })
      .then((air_quality) => {
        this.setState({ air_quality_output: air_quality });

        // console.log("Airquality Index Below")
        // console.log(air_quality.HourlyAirQualityIndex.LocalAuthority)
        // console.log(this.state.air_quality_output.HourlyAirQualityIndex.LocalAuthority)
      });
  }

  render() {
    // Returns an event based on menu click
    console.log("Airquality Index Below");

    console.log(this.state.air_quality_output);

    return (
      <div>
        {this.state.air_quality_output &&
        this.state.air_quality_output.length ? (
          <DisplayAirPollution
            siteName={
              this.state.air_quality_output.HourlyAirQualityIndex.LocalAuthority
                .Site["@SiteName"]
            }
            updateTime={
              this.state.air_quality_output.HourlyAirQualityIndex.LocalAuthority
                .Site["@BulletinDate"]
            }
            species={
              this.state.air_quality_output.HourlyAirQualityIndex.LocalAuthority
                .Site.species
            }
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default Air_Quality_Import;
