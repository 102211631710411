import React from 'react'

import axios from 'axios'
import * as urls from '../components/maps/urls'
import { Auth } from 'aws-amplify'

import { userActions } from '../redux/_actions'
import { connect } from 'react-redux'
import { getUserDetails } from '../redux/_helpers/getUserDetails'
import Header from '../components/Header'

import { Logout } from '../components/Logout'
import ChangePasswordMini from '../components/ChangePasswordMini'
import { RefreshAuthTokens } from '../components/RefreshAuthTokens'
import './ChangeAccountDetails.css'

//Admin123!?

export class ChangeAccDetails extends React.Component {
  constructor(props) {
    super(props)
    //setting initial user row
    this.state = {
      userData: {
        email: this.props.userData.data.email,
        full_name: this.props.userData.data.full_name,
        position: this.props.userData.data.position,
        phone: this.props.userData.data.phone,
        password: '',
        v_code: '',
      },
      databaseClear: true,
      databaseSuccess: false,
      cognitoSuccess: false,
      errorArray: [],
      errorText: '',
      errMessage: '',
      success: true,
    }

    this.validateChangeDetails = this.validateChangeDetails.bind(this)
  }

  async validateChangeDetails() {
    let validate = true
    let errorMessage = []
    let user = this.state.userData

    //regex for validating emails and phoneNumbers - not final!!!
    var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    let phoneRegex = /\s*(([+](\s?\d)([-\s]?\d)|0)?(\s?\d)([-\s]?\d){9}|[(](\s?\d)([-\s]?\d)+\s*[)]([-\s]?\d)+)\s*/

    //test if any fields are undefined or empty (missing) as well as checking if email and phone number are valid
    if (typeof user.full_name === 'undefined' || user.full_name.length < 1) {
      user.full_name = this.props.userData.data.full_name
    }

    if (typeof user.phone === 'undefined' || user.phone.length < 1) {
      user.phone = this.props.userData.data.phone
    } else if (!phoneRegex.test(user.phone.toLowerCase())) {
      validate = false
      errorMessage.push("Field 'Phone Number' is not valid.\n")
    } else if (user.phone.toString().substring(0, 1) == '0') {
      user.phone = user.phone = '+44' + user.phone.slice(1)
    }
    if (typeof user.position === 'undefined' || user.position.length < 1) {
      user.position = this.props.userData.data.position
    }

    // if(typeof user.password === 'undefined' || user.password.length < 8){
    //     validate = false;
    //     errorMessage.push("UField 'Password' cannot be empty or shorter than 8 character + capital+symbol.\n")
    //   }

    //if everything valid, move to the next form, if not then print an error message.
    if (validate === true) {
      let success = false

      success = await this.postDatbase()
      console.log(success)
      if (success === true) {
        let data = this.props.userData.data
        data.full_name = this.state.userData.full_name
        data.position = this.state.userData.position
        data.phone = this.state.userData.phone

        this.props.dispatch(userActions.setUserData(data))

        document.location.reload()
      }
    } else {
      this.setState({
        errorText: this.printError(errorMessage),
      })
    }
  }

  //formats the error message to be on separate lines
  printError = errorMessage => {
    var lines = errorMessage
    var br = lines.map(function (line) {
      return (
        <span>
          {line}
          <br />
        </span>
      )
    })
    return <div>{br}</div>
  }

  // Use the submitted data to set the state
  handleChange = event => {
    this.setState({
      userData: {
        // object that we want to update
        ...this.state.userData, // keep all other key-value pairs
        [event.target.id]: event.target.value, // update the value of specific key
      },
    })

    // this.setState({userData:{
    //   [event.target.id]: event.target.value
    // }});
  }

  handleSubmit = () => {
    this.validateChangeDetails()
  }

  // this.setState({
  //     errorText: this.printError(errorMessage)
  //   })
  // async sendForgotPassword(email) {
  //   try {
  //     await Auth.forgotPassword(email);
  //     console.log("EMAIL SENT");
  //   } catch (e) {
  //     alert(e.message);
  //     console.log("EMAIL SEND FAIL");
  //   }
  // }

  async postDatbase() {
    let success = true

    let data = {
      email: this.state.userData.email,
      aws_id: this.props.userData.data.aws_id,
      phone: this.state.userData.phone,
      full_name: this.state.userData.full_name,
      position: this.state.userData.position,
    }

    await axios({
      method: 'post',
      url: urls.API_GATEWAY + 'users/change-user-details',
      data: data,
    })
      .then(res => {
        console.log('user details updated!!!!!')
        console.log(res.data)
        let payload = res.data

        if (payload === 'null' || payload === null || payload === 'Failure' || typeof payload === 'undefined') {
          success = false
          console.log('Error changing details')
        } else if (payload === 'Invalid Details') {
          console.log('Credentials for changind acc. details are INVALID')
          success = false
        }

        if (payload !== 'Success') {
          success = false
          console.log('user details update failed in database!!')
        } else {
          success = true
          alert('User details successfully updated')
        }
      })
      .catch(err => {
        console.log('user details update failed in database!!')

        this.setState({
          success: false,
          errMessage: 'Action Failed. Cannot reach Server',
        })

        console.log(err)
        success = false
      })

    return success
  }

  async componentDidMount() {
    await RefreshAuthTokens(this.props)
  }

  render() {
    return (
      <div>
        <Header
          user_permissions={this.props.userData.data.permissions}
          user_portfolio_type={this.props.userData.data.portfolio_type}
          customstyle={this.props.userData.data.customstyle}
          organisation={this.props.userData.data.orgid}
          logout={() => Logout(this.props)}
        />

        <div className="changeuserpage">
          <h2>Change Account Details</h2>

          <div className="changeuserform">
            <div>
              Logged In as: {this.props.userData.data.full_name} - {this.props.userData.data.email}
            </div>
            <br />
            <div>(Leave fields empty for old values)</div>
            <br />
            <div className="changeuserdetails">
              <form name="my-form" type="button" onChange={this.handleChange}>
                <div className="form-group row">
                  <label htmlFor="fullName">Full Name</label>

                  <input
                    type="name"
                    id="full_name"
                    className="form-control"
                    name="full_name"
                    onChange={this.handleChange}
                    placeholder={this.props.userData.data.full_name}
                  />
                </div>
                <div className="form-group row">
                  <label htmlFor="position">Position</label>

                  <input
                    type="text"
                    id="position"
                    className="form-control"
                    name="position"
                    onChange={this.handleChange}
                    placeholder={this.props.userData.data.position}
                  />
                </div>

                <div className="form-group row">
                  <label htmlFor="phone">Phone Number</label>

                  <input
                    type="text"
                    id="phone"
                    className="form-control"
                    onChange={this.handleChange}
                    placeholder={this.props.userData.data.phone}
                  />
                </div>

                <div>
                  <button id="changedetails" className="form-control" type="button" onClick={this.handleSubmit}>
                    Change Account Details
                  </button>

                  <br />
                </div>
              </form>
            </div>

            <h4>Change Password</h4>

            <ChangePasswordMini email={this.props.userData.data.email} user={this.props.user} />
          </div>
        </div>
        <div>{this.state.errorText}</div>
      </div>
    )
  }
}
function mapStateToProps(state) {
  const { userLogged, user, userData } = state

  return {
    userLogged,
    user,
    userData,
  }
}
export default connect(mapStateToProps)(ChangeAccDetails)
