import React, { Component } from "react";
//import PropTypes from "prop-types";
import Button from "balkerne-components/Button";

export default class SearchButton extends Component {
	render() {
		return (
			<Button
				
				type="button"
				onClick={this.props.search}
			>
				Search
			</Button>
		);
	}
}

// SearchButton.propTypes = {
// 	search: PropTypes.func.isRequired,
// };
