import React from 'react'
import { Route, Redirect, useHistory } from 'react-router-dom'
import Auth from '@aws-amplify/auth'
import { userActions, systemActions } from '../redux/_actions'
import { persistStore } from 'redux-persist'

export async function Logout(props) {
  console.log('logging out!!!!!!!!!!!!!!')

  console.log(props.userData)

  await Auth.signOut()
    .then(() => {
      props.dispatch(userActions.logoutLogged())
      props.dispatch(userActions.logout())
      props.dispatch(userActions.clearUserData())
      props.dispatch(systemActions.clearSystemData())
      props.dispatch(systemActions.clearMapData())
      props.dispatch(systemActions.clearDashboardData())
      props.dispatch(systemActions.clearRiskLocationsData())
      persistStore(props).purge()
      document.location.reload()

      // redirect to login page

      console.log('Sign out complete')
    })
    .catch(err => console.log('Error while signing out!', err))
}
