import React from "react"
import PropTypes from 'prop-types'
import cn from 'classnames'
import c from "./Search.module.scss"

/**
  `import Search from "balkerne-components/Search"` 

  - Default input element of type "search" 
  - Accepts same parameters (https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/search)
**/

const Search = ({transparent, ...props}) => {
  const classes = cn([c.container, {[c.transparent]: transparent}])

  return (
    <div className={classes}>
      <img src={require('../../images/icons/Search.svg')} className={c.icon}/>
      <input type="search" {...props} className={c.search} />
    </div>
  )
}

Search.defaultProps = {
  transparent: true,
}

Search.propTypes = {
  transparent: PropTypes.bool,
}

export default Search