import React from "react";
import { GoogleMap } from "./GoogleMap";
import { CloseButton } from "./Controls";
import { OptionCategory } from "./Options";
import * as styles from "./styles";
import * as gmaps from "./gmaps";
import axios from "axios";
import * as urls from "./urls";

import "./Dashboard.css";

import { activeDashboardLayers } from "./config";

class Notice extends React.Component {
  render() {
    return (
      <div className="notice__box">
        <img className="notice__icon" src={this.props.icon} alt="" />
        <div className="notice__content">{this.props.children}</div>
      </div>
    );
  }
}

class RiskBar extends React.Component {
  render() {
    let level;
    if (this.props.value / this.props.max <= 0.33) level = "low";
    else if (this.props.value / this.props.max <= 0.66) level = "medium";
    else level = "high";

    let classes = ["dashboard__risk_bar", `dashboard__risk_bar--${level}`].join(
      " "
    );

    return (
      <h4 className="dashboard__risk_score dashboard__risk_score--sub">
        <span className="dashboard__risk_category">{this.props.label}</span>
        <progress
          className={classes}
          max={this.props.max}
          value={this.props.value}
        />
      </h4>
    );
  }
}

/**
 * Dashboard of the application.
 */
export class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      map: null,
      infoWindow: null,
      layers: activeDashboardLayers,
      feature: this.props.feature,
      flood_risks: [],
      messages: [],
      weather_risks: [],
      crime_risks: [],
      risk_total: 0,
    };
    this.onLayerChange = this.onLayerChange.bind(this);
    this.onMapLoad = this.onMapLoad.bind(this);
  }

  async componentDidMount() {
    let id = `?id=${this.state.feature.getProperty("id")}`;

    let message_url = urls.BACKEND + "/flood/messages" + id;

    let messages = await axios.get(message_url).then((res) => {
      const messages = res.data;
      console.log(messages);
      this.setState({ messages });
    });

    let query = [
      `?lat=${this.state.feature.getProperty("lat")}`,
      `lon=${this.state.feature.getProperty("lng")}`,
    ].join("&");

    let flood_url = [urls.BACKEND, "risks", "floods", query].join("/");

    let flood_risk = await axios.get(flood_url).then((res) => {
      const flood_risks = res.data["properties"];
      console.log("flood risks below");
      console.log(flood_risks);
      this.setState({ flood_risks });
    });

    let weater_url = [urls.BACKEND, "risks", "weather", query].join("/");

    let weather_risk = await axios.get(weater_url).then((res) => {
      const weather_risks = res.data["Risk scoring"];
      console.log("weather risks below");
      console.log(weather_risks);
      this.setState({ weather_risks });
    });

    let crimes_url = [urls.BACKEND, "risks", "crimes", query].join("/");
    let crime_risks = await axios.get(crimes_url).then((res) => {
      console.log("crime risks below");
      const crime_risks = res.data;
      console.log(crime_risks);
      this.setState({ crime_risks });
    });
  }

  onMapLoad(map) {
    this.setState({ map });

    let layer = new window.google.maps.Data();
    layer.setStyle(styles.dashboardLocation);
    this.state.feature.toGeoJson((f) => {
      layer.addGeoJson(f);
      gmaps.zoom(map, layer);
      map.setZoom(map.getZoom() - 2);
    });
    layer.setMap(map);

    for (let layerName in this.state.layers) {
      let layer = this.state.layers[layerName];
      layer.visible = false;
      switch (layer.type) {
        case "data":
          if (layer.name === "Crime") {
            let query = [
              "dst=2000",
              `lng=${this.state.feature.getProperty("lng")}`,
              `lat=${this.state.feature.getProperty("lat")}`,
              "previousMonths=6",
              "pointOnly=1",
            ].join("&");
            gmaps
              .heatmapLayerFromURL([layer.url, query].join("?"))
              .then((heatmapLayer) => {
                this.setState((state) => {
                  let layers = state.layers;
                  let layer = layers[layerName];
                  layer.layer = heatmapLayer;
                  if (layer.visible) {
                    this.showLayer(state.map, layer);
                  }
                  return layers;
                });
              });
          } else {
            gmaps
              .dataLayerFromURL(
                layer.url,
                this.props.user.user.signInUserSession.idToken.jwtToken
              )
              .then((dataLayer) => {
                if (layer.style) {
                  dataLayer.setStyle(layer.style);
                }
                if (layer.onClick) {
                  dataLayer.addListener("click", layer.onClick.bind(this));
                }
                this.setState((state) => {
                  let layers = state.layers;
                  let layer = layers[layerName];
                  layer.layer = dataLayer;
                  if (layer.visible) {
                    this.showLayer(state.map, layer);
                  }
                  return layers;
                });
              });
          }
          break;
        case "tile":
          gmaps.tileLayerFromURL(layer.url, layer).then((tileLayer) => {
            this.setState((state) => {
              let layers = state.layers;
              let layer = layers[layerName];
              layer.layer = tileLayer;
              if (layer.visible) {
                this.showLayer(state.map, layer);
              }
              return layers;
            });
          });
          break;
        default:
      }
    }
  }

  async onLayerChange(e) {
    let layerId = e.target.id;
    this.setState((state) => {
      let layers = state.layers;
      let layer = layers[layerId];
      if (layer) {
        layer.visible = !layer.visible;
      }
      if (layer.visible) {
        this.showLayer(state.map, layer);
      } else {
        this.hideLayer(state.map, layer);
      }
      return { layers };
    });
  }

  async showLayer(map, layer) {
    if (!layer) return;
    switch (layer.type) {
      case "data":
        gmaps.showDataLayer(map, layer.layer);
        break;
      case "tile":
        gmaps.showTileLayer(map, layer.layer);
        break;
      default:
    }
  }

  async hideLayer(map, layer) {
    if (!layer) return;
    switch (layer.type) {
      case "data":
        gmaps.hideDataLayer(map, layer.layer);
        break;
      case "tile":
        gmaps.hideTileLayer(map, layer.layer);
        break;
      default:
    }
  }

  render() {
    const feature = this.state.feature;
    console.log(feature);

    const name = feature.getProperty("name") || "Unknown";
    const addressFull = feature.getProperty("addressFull") || "Address Unknown";
    const phone = feature.getProperty("phoneNumber") || "Phone Number Unknown";

    console.log(feature.getProperty("ogc_id"));
    const riskScores = feature.getProperty("riskScores") || {};
    // FIXME: Default values on the right are purely for demonstration
    // purposes, not to be used once risk score calculation is operational.
    const riskFire = riskScores.fire || 20;
    const riskFlood = this.state.flood_risks["total"] || 0;
    console.log("Flood risk: " + this.state.flood_risks["total"]);
    const riskCrime = this.state.crime_risks[0] || 0;
    console.log("Crime risk: " + this.state.crime_risks);
    const riskWeather = this.state.weather_risks["Final risk score"] || 0;
    console.log(
      "Weather risk: " + this.state.weather_risks["Final risk score"]
    );
    const riskScore =
      riskFlood + Math.round(this.state.weather_risks["Final risk score"]);
    const lat = feature.getProperty("lat");
    const lng = feature.getProperty("lng");

    let dashboardLayers = [];
    for (let layerId in activeDashboardLayers) {
      let layer = activeDashboardLayers[layerId];

      let option = (
        <OptionCategory
          key={layerId}
          label={layer.name}
          id={layerId}
          checked={this.state.layers[layerId].visible}
          onChange={this.onLayerChange}
          opt="dashboard"
        ></OptionCategory>
      );
      dashboardLayers.push(option);
    }

    // FIXME: Currently static placeholders. These should all be retrieved
    // from the backend API somehow.
    let recommendations = [
      <Notice icon="">
        <p className="notice__text">
          Flood Alert issued in the area &ndash; please consider:{" "}
          <a className="link">Flood Checklist</a>
        </p>
      </Notice>,
      <Notice icon="">
        <p className="notice__text">
          High winds are likely to impact property from 17:00 24/10/2018 &ndash;
          secure doors and any loose objects to prevent problems.
        </p>
      </Notice>,
    ];

    let alert_messages_array = [];

    this.state.messages.forEach(function (element) {
      alert_messages_array.push(
        <Notice icon="">
          {" "}
          <p className="notice__text">{element}</p>
        </Notice>
      );
    });

    if (alert_messages_array.length === 0)
      alert_messages_array.push(
        <Notice icon="">
          {" "}
          <p className="notice__text">
            There are no active flood alerts for this property!
          </p>
        </Notice>
      );

    let alerts = [
      <Notice icon="">
        <p className="notice__text">
          Flooding is possible over the high tides on Wednesday evening. High
          water at Newlyn is at 18:24, times of high water will vary along the
          coast.
        </p>
      </Notice>,
      <Notice icon="">
        <p className="notice__text">
          3 local river gauges have reported flooding. Nearest river gauge has
          risen by 39.2% to 1.4m.
        </p>
      </Notice>,
    ];

    let activity = [
      <Notice icon="">
        <p className="notice__title">Church Matters</p>
        <p className="notice__text">
          As weather warnings are issued across the UK, ensure your church is
          prepared before, during...
        </p>
      </Notice>,
      <Notice icon="">
        <p className="notice__text">
          Storm Callum: Almost 200 Welsh homes hit by flooding
        </p>
      </Notice>,
      <Notice icon="">
        <p className="notice__title">John The Walker</p>
        <p className="notice__text">
          UPDATE: Truckee breaks banks, begins to flood Wingfield{" "}
          <a className="link" href="http://dlvr.it/N3tTL7">
            http://dlvr.it/N3tTL7
          </a>
        </p>
      </Notice>,
    ];

    return (
      <div id="fade" className="fade-element" onClick={this.props.onClose}>
        <div id="dashboard" className="dashboard__box">
          <CloseButton id="close" onClick={this.props.onClose} />

          <div className="dashboard__header">
            <h1 className="dashboard__title">{name}</h1>
            <h2 className="dashboard__address">{addressFull}</h2>
            <h2 className="dashboard__phone">{phone}</h2>
          </div>

          <div className="dashboard__content">
            <div className="dashboard__left">
              <div className="dashboard__risk">
                <h2 className="dashboard__risk_score_title">Risk Score</h2>
                <h4 className="dashboard__risk_score dashboard__risk_score--main">
                  {riskScore}
                </h4>
                <div className="dashboard__risk_breakdown">
                  <RiskBar label="Flood &nbsp;" max="300" value={riskFlood} />
                  <RiskBar
                    label="Fire &nbsp; &nbsp; &nbsp; &nbsp;"
                    max="100"
                    value={riskFire}
                  />
                  <RiskBar
                    label="Crime &nbsp; &nbsp;"
                    max="100"
                    value={riskCrime}
                  />
                  <RiskBar
                    label="Weather &nbsp; &nbsp;"
                    max="40"
                    value={riskWeather}
                  />
                  <RiskBar
                    label="Metal Theft Vulnerability &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;"
                    max="100"
                    value={riskFire}
                  />
                </div>
              </div>
              <div className="dashboard__map">
                <GoogleMap
                  id="mapDashboard"
                  className="map"
                  options={{
                    zoom: 15,
                    tilt: 0,
                    center: { lat: lat, lng: lng },
                    styles: styles.maps.mono,
                    disableDefaultUI: true,
                    zoomControl: true,
                    mapTypeControl: true,
                    mapTypeControlOptions: {
                      style:
                        window.google.maps.MapTypeControlStyle.DROPDOWN_MENU,
                      mapTypeIds: ["roadmap", "satellite"],
                    },
                  }}
                  apiKey="AIzaSyD9n6ddk1Z6E2L5hZqCVRo94I8lbHjm30Q"
                  onMapLoad={this.onMapLoad}
                />
              </div>
              <div className="dashboard__map_controls">{dashboardLayers}</div>
            </div>

            <div className="dashboard__right">
              <div className="dashboard__notices">
                <h2 className="dashboard__notices_title dashboard__notices_title--recommendations">
                  Recommendations
                </h2>
                {recommendations}
              </div>
              <div className="dashboard__notices">
                <h2 className="dashboard__notices_title dashboard__notices_title--alerts">
                  Alerts
                </h2>
                {alert_messages_array}
              </div>
              <div className="dashboard__notices">
                <h2 className="dashboard__notices_title dashboard__notices_title--activity">
                  Activity
                </h2>
                {activity}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
