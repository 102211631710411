import React from 'react'
import c from './LabeledText.module.scss'
import cn from 'classnames'

export const LabeledTextLine = ({ label, children, rightAlign }) => (
  <div className={c.textLine}>
    <span className={cn(c.label, {[c.rightAlign]: rightAlign})}>{label}</span>
    <span className={cn(c.text, {[c.rightAlign]: rightAlign})}>{children}</span>
  </div>
)

export const LabeledTextBox = ({ label, children }) => (
  <div className={c.textBox}>
    <span className={c.label}>{label}</span>
    <div className={c.box}>{children}</div>
  </div>
);

