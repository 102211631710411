import React from "react";

import axios from "axios";
import * as urls from "../components/maps/urls";
import { Auth } from "aws-amplify";
import UserGetStarted from "../components/userManagement/UserGetStarted";
import OrgGetStarted from "../components/userManagement/OrgGetStarted";
import PropertiesGetStarted from "../components/userManagement/PropertiesGetStarted";
import LastPageGetStarted from "../components/userManagement/LastPageGetStarted";
import Button from "balkerne-components/Button";
import PageContainer from "balkerne-components/Container";

import moment from "moment";
//  import AWS from 'aws-sdk';
// // const AWS = require('aws-sdk')
// // const AWS = require('aws-sdk');
// // AWS.config.loadFromPath('C:\Users\Lukas\Documents\risk-management-application\frontend\config.json');
// const ses = new AWS.SES()

// load aws config

import { EmptyHeader } from "../components/Header";
import PreTitle from "balkerne-components/PreTitle";

import { Logout } from "../components/Logout";
//import "./GetStarted.css";
import c from "./GetStarted.module.scss";

export class GetStarted extends React.Component {
  constructor(props) {
    super(props);
    //setting initial user row
    this.state = {
      currentStep: 1,
      userData: {
        email: "",
        full_name: "",
        position: "",
        organisation: "",
        phone: "",
        password: "",
      },
      orgData: {
        name: "",
        uploadFile: null,
      },
      secret: null,
      databaseClear: true,
      databaseSuccess: false,
      cognitoSuccess: false,
      errorArray: [],
      errorText: "",
      properties: [],
    };
    this._next = this._next.bind(this);
    this._prev = this._prev.bind(this);
    this.sendForgotPassword = this.sendForgotPassword.bind(this);

    this.checkCognito = this.checkCognito.bind(this);
    //this.checkDatabase = this.checkDatabase.bind(this);
    this.sendEmail = this.sendEmail.bind(this);
    //this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmit2 = this.handleSubmit2.bind(this);
    this.handleChangeProperties = this.handleChangeProperties.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
    this.validateAddUser = this.validateAddUser.bind(this);
    this.updateUserData = this.updateUserData.bind(this);
    this.updateOrgData = this.updateOrgData.bind(this);
    this.updateOrgLogo = this.updateOrgLogo.bind(this);
    this._next = this._next.bind(this);
    this.checkEmail = this.checkEmail.bind(this);
    this.checkAllDetails = this.checkAllDetails.bind(this);
  }

  handleChangeProperties(name, value) {
    this.setState({
      [name]: value,
    });
  }

  validateUpload() {
    return true;
  }

  toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  async handleUpload() {
    let success = false;

    let data = {};

    console.log(this.state.orgData);
    console.log(this.state.userData);
    console.log(this.state.properties);
    console.log(this.state.orgLogo);

    let uploadfile = null;

    let uploadFileName = null;
    if (this.state.orgLogo && this.state.orgLogo.name) {
      console.log("setting up name");
      uploadFileName = this.state.orgLogo.name;
    }

    let userdata = this.state.userData;
    if (userdata.email && userdata.email.length > 3) {
      userdata.email = userdata.email.toLowerCase();
    }

    if (this.state.orgLogo) {
      uploadfile = await this.toBase64(this.state.orgLogo);
      data = {
        organisation: JSON.stringify(this.state.orgData),
        user: JSON.stringify(userdata),
        location_list: JSON.stringify(this.state.properties),
        logo_attached: true,
        file: uploadfile,
        filename: uploadFileName,
        secret: this.state.secret,
      };
    } else {
      console.log("no logo");
      uploadfile = null;
      data = {
        organisation: JSON.stringify(this.state.orgData),
        user: JSON.stringify(userdata),
        location_list: JSON.stringify(this.state.properties),
        logo_attached: false,
        file: uploadfile,
        filename: uploadFileName,
        secret: this.state.secret,
      };
      //formData.append("logo_attached", false);
    }

    await axios({
      method: "post",
      url: urls.API_GATEWAY + "organisation/get-started-make",
      data: data,
    })
      .then((res) => {
        console.log(res.data);
        if (res.data === "Success") {
          success = true;
        } else if (res.data === "Failure") {
          this.setState({
            errorText: this.printError([
              "Failure to add properties. Your account was created.",
            ]),
          });
          success = false;
        } else {
          success = false;
          this.setState({
            errorText: this.printError(res.data),
          });
        }
      })
      .catch((err) => {
        success = false;
        // this.state.errorMessage.push(
        //   "Failed to add mitigation to database to database"
        // );
        this.setState({
          errorText: this.printError(["Internal server error."]),
        });
        console.log(err);
        this.setState({ success: false });
      });

    return success;
  }

  async _next() {
    console.log(this.state.users);

    let currentStep = this.state.currentStep;

    if (currentStep === 1) {
      //   currentStep = 2

      // this.setState({currentStep});
      //validate added users
      let answer = await this.validateAddUser(currentStep);
      if (answer) {
        currentStep = 2;

        let orgData = this.state.orgData;

        orgData.name = this.state.userData.organisation;

        this.setState({ orgData });
        this.setState({ currentStep });
        this.setState({
          errorText: "",
        });
      }
    } else if (currentStep === 2) {
      // If the current step is 1 or 2, then add one on "next" button click
      //currentStep = currentStep >= 2 ? 3 : currentStep + 1;
      let answer = await this.validateOrganisation();
      if (answer) {
        currentStep = 3;

        //   let orgData = this.state.orgData
        // orgData.uploadFile = this.state.uploadFile;

        // this.setState({ orgData });

        this.setState({ currentStep });
        this.setState({
          errorText: "",
        });
      }
    }
  }

  //function moving the user to the previous form
  _prev() {
    let currentStep = this.state.currentStep;
    // If the current step is 2 or 3, then subtract one on "previous" button click
    currentStep = currentStep <= 1 ? 1 : currentStep - 1;
    this.setState({
      currentStep: currentStep,
    });
  }

  get previousButton() {
    let currentStep = this.state.currentStep;
    // If the current step is not 1, then render the "previous" button
    if (currentStep !== 1 && currentStep < 4) {
      return (
        <Button light onClick={this._prev}>
          Previous
        </Button>
      );
    }
    // ...else return nothing
    return null;
  }

  //render next page button
  get nextButton() {
    let currentStep = this.state.currentStep;
    // If the current step is not 3, then render the "next" button
    if (currentStep === 1) {
      return (
        <Button id="getstartedbutton" onClick={this._next}>
          Let's Get Started
        </Button>
      );
    } else if (currentStep < 3) {
      return (
        <Button id="getstartedbutton" onClick={this._next}>
          Next
        </Button>
      );
    }
    // ...else render nothing
    return null;
  }

  async componentDidMount() {
    //this.state.userData.manager = this.props.userData.data.id;
    //let address = await this.getGeocode("193 avon way colchester")
    // console.log(address)
    //this.sendEmailTest()
    this.setState({ mounted: true });
  }

  async sendEmail() {
    let success = true;

    let email = this.state.userData.email;
    if (email && email.length > 3) {
      email = email.toLowerCase();
    }

    let data = {
      email: email,
      password: this.state.userData.password,
      secret: this.state.secret,
      org_id: this.state.userData.org_id,
    };

    await axios({
      method: "post",
      url: urls.API_GATEWAY + "users/send-temp-psw",
      data: data,
    })
      .then((res) => {
        console.log(res.data);
        let response = res.data;

        if (
          response === "null" ||
          response === null ||
          response === "Failure" ||
          typeof response === "undefined"
        ) {
          success = false;
          console.log("Email sending FAILURE");
        } else if (response === "Invalid Details") {
          console.log("Details for sending temp emails are INVALID");
          success = false;
        } else {
          success = true;
          console.log("SUCCESS!! PSW successfully sent.");
          console.log(res.data);
        }
      })
      .catch((err) => {
        success = false;
        console.log("FAILURE!!");
        console.log(err);
        this.setState({
          errorText: this.printError(["Database email sending error"]),
        });
      });
    return success;
  }

  async validateAddUser() {
    let success = true;
    let validate = true;
    let errorMessage = [];
    let user = this.state.userData;

    //regex for validating emails and phoneNumbers - not final!!!
    var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    let phoneRegex = /^(?:0|\+?44)(?:\d\s?){9,10}$/;

    //test if any fields are undefined or empty (missing) as well as checking if email and phone number are valid
    if (typeof user.full_name === "undefined" || user.full_name.length < 1) {
      validate = false;
      errorMessage.push("Field 'Full Name' cannot be empty.\n");
    }
    if (typeof user.email === "undefined" || user.email.length < 1) {
      validate = false;
      errorMessage.push("Field 'Email' cannot be empty.\n");
    } else if (!emailRegex.test(user.email.toLowerCase())) {
      validate = false;
      errorMessage.push("Field 'Email' is not valid.\n");
    } else if (user.email.match(/[!$%^&*(){}+|~=`{}\[\]:";'<>?,\/]/)) {
      validate = false;
      errorMessage.push("Invalid symbols in email .\n");
    }
    // if (typeof user.phone === "undefined" || user.phone.length < 1) {
    //   validate = false;
    //   errorMessage.push("Field 'Phone Number' cannot be empty.\n");
    // } else if (!phoneRegex.test(user.phone)) {
    //   validate = false;
    //   errorMessage.push("Field 'Phone Number' is not valid.\n");
    // }
    // else if (
    //   user.phone.toString().substring(0, 1)=="0"
    // ) {
    //   user.phone = user.phone = "+44"+ user.phone.slice(1)
    // }
    // if (typeof user.position === "undefined" || user.position.length < 1) {
    //   validate = false;
    //   errorMessage.push("Field 'Position' cannot be empty.\n");
    // }

    if (
      typeof user.organisation === "undefined" ||
      user.organisation.length < 1
    ) {
      validate = false;
      errorMessage.push("Field 'Organisation' cannot be empty.\n");
    } else if (
      user.organisation.search(/[!$%^&*(){}+|~=`{}\[\]:";'<>?,\/]/) >= 0
    ) {
      validate = false;
      errorMessage.push("Invalid symbols in organisation name .\n");
    }

    //if everything valid, move to the next form, if not then print an error message.
    if (validate === true) {
      success = true;
      success = await this.checkEmail(this.state.userData.email);
      if (success === false) {
        console.log("these credentials already exist in the database");
      }
    } else {
      success = false;
      this.setState({
        errorText: this.printError(errorMessage),
      });
    }
    return success;
  }

  //formats the error message to be on separate lines
  printError = (errorMessage) => {
    var lines = errorMessage;
    var br = lines.map(function (line) {
      return (
        <div className="alert alert-danger" role="alert">
          <span>
            {line}
            <br />
          </span>
        </div>
      );
    });
    return <div>{br}</div>;
  };
  //loads updated values into the users array
  updateFields = (users) => {
    this.setState(this.state.users, this.props.users);
  };
  // Use the submitted data to set the state
  handleChange = (event) => {
    this.setState({
      userData: {
        // object that we want to update
        ...this.state.userData, // keep all other key-value pairs
        [event.target.id]: event.target.value, // update the value of specific key
      },
    });

    // this.setState({userData:{
    //   [event.target.id]: event.target.value
    // }});
  };

  validateOrganisation() {
    let success = true;
    let errorMessage = [];

    let orgData = this.state.orgData;
    console.log(orgData);
    if (!orgData.name || orgData.name === "") {
      success = false;
      errorMessage.push("Please provide an organisation name .\n");
    } else if (orgData.name.search(/[!$%^&*(){}+|~=`{}\[\]:";'<>?,\/]/) >= 0) {
      success = false;
      errorMessage.push("Invalid symbols in organisation name .\n");
    }
    if (success === false) {
      this.setState({
        errorText: this.printError(errorMessage),
      });
    }

    return success;
  }

  async checkAllDetails() {
    let success = false;

    let data = {};

    console.log(this.state.orgData);
    console.log(this.state.userData);
    console.log(this.state.properties);
    console.log(this.state.orgLogo);

    let uploadfile = null;

    let uploadFileName = null;
    if (this.state.orgLogo && this.state.orgLogo.name) {
      console.log("setting up name");
      uploadFileName = this.state.orgLogo.name;
    }

    let userdata = this.state.userData;
    if (userdata.email && userdata.email.length > 3) {
      userdata.email = userdata.email.toLowerCase();
    }

    if (this.state.orgLogo) {
      uploadfile = await this.toBase64(this.state.orgLogo);
      data = {
        organisation: JSON.stringify(this.state.orgData),
        user: JSON.stringify(userdata),
        location_list: JSON.stringify(this.state.properties),
        logo_attached: true,
        file: uploadfile,
        filename: uploadFileName,
      };
    } else {
      console.log("no logo");
      uploadfile = null;
      data = {
        organisation: JSON.stringify(this.state.orgData),
        user: JSON.stringify(userdata),
        location_list: JSON.stringify(this.state.properties),
        logo_attached: false,
        file: uploadfile,
        filename: uploadFileName,
      };
      //formData.append("logo_attached", false);
    }

    await axios({
      method: "post",
      url: urls.API_GATEWAY + "organisation/get-started-check",
      data: data,
    })
      .then((res) => {
        console.log(res.data);
        if (res.data === "Success") {
          success = true;
        } else {
          console.log(res.data);
          success = false;
          this.setState({
            errorText: this.printError(res.data),
          });
        }
      })
      .catch((err) => {
        success = false;
        // this.state.errorMessage.push(
        //   "Failed to add mitigation to database to database"
        // );
        this.setState({
          errorText: this.printError(["Cannot reach the Server."]),
        });
        console.log(err);
        this.setState({ success: false });
      });

    return success;
  }

  updateErrorText = (errorMessage) => {
    if (errorMessage === null) {
      this.setState({
        errorText: "",
      });
    } else {
      this.setState({
        errorText: this.printError(errorMessage),
      });
    }
  };

  validateLastStep = () => {
    let success = true;
    let errorMessage = [];
    for (let propertyNo in this.state.properties) {
      let property = this.state.properties[propertyNo];
      console.log(property);
      if (!property.address || property.address === "") {
        property.success = false;
        success = false;
        errorMessage.push(
          "address missing for property  " +
            (typeof property.name === "undefined"
              ? parseInt(propertyNo) + 1 + " "
              : property.name)
        );
        console.log(
          "address missing for property " +
            (typeof property.name === "undefined"
              ? parseInt(propertyNo) + 1 + " "
              : property.name)
        );
      }
      if (!property.name || property.name === "") {
        property.success = false;
        success = false;
        errorMessage.push(
          "name missing for property " + (parseInt(propertyNo) + 1 + " ")
        );
        console.log(
          "name missing for property " + (parseInt(propertyNo) + 1 + " ")
        );
      }
      if (!property.country || property.country === "") {
        property.success = false;
        success = false;
        errorMessage.push(
          "country missing for property " +
            (typeof property.name === "undefined"
              ? parseInt(propertyNo + 1) + " "
              : property.name)
        );
        console.log(
          "country missing for property " +
            (typeof property.name === "undefined"
              ? parseInt(propertyNo) + 1 + " "
              : property.name)
        );
      }
      if (!property.processed) {
        property.success = false;
        success = false;
        errorMessage.push(
          "property not fully processed for property " +
            (typeof property.name === "undefined"
              ? parseInt(propertyNo) + 1 + " "
              : property.name)
        );
        console.log(
          "property not fully processed for property " +
            (typeof property.name === "undefined"
              ? parseInt(propertyNo) + 1 + " "
              : property.name)
        );
      }
    }
    if (this.state.properties.length < 1) {
      success = false;
      errorMessage.push("No properties added");
      console.log("No properties added");
    }

    if (success === false) {
      success = false;
      console.log(errorMessage);
      this.setState({
        errorText: this.printError(errorMessage),
      });
    }

    console.log("submitting " + success);
    console.log(this.state.orgData);
    console.log(this.state.userData);
    console.log(this.state.properties);
    return success;
  };

  async handleSubmit2() {
    let success = false;
    let successDetails = false;
    let successCognito = false;
    let successEmail = false;
    let successDatabase = false;

    success = this.validateLastStep();

    if (success) {
      this.state.userData.password = this.password_generator();
      successDetails = await this.checkAllDetails();
      if (successDetails) {
        console.log("All good check cognito");
        successCognito = await this.checkCognito(this.state.userData);
        if (successCognito) {
          console.log("all good send email");
          successEmail = await this.sendEmail();
          if (successEmail) {
            console.log("all good email sent");
            successDatabase = await this.handleUpload();
            if (successDatabase) {
              //alert("Application submitted")
              let currentStep = 4;
              this.setState({ databaseSuccess: true });
              this.setState({ currentStep });
              console.log("All good all uploaded");
            } else {
              alert("Application submitted with errors");
              let currentStep = 4;
              this.setState({ databaseSuccess: true });
              this.setState({ currentStep });
              console.log("Application submitted with errors");
            }
          } else {
            console.log("send email fail");
          }
        } else {
          console.log("cognito fail");
        }
      } else {
        console.log("Error with form details");
      }
    } else {
      console.log("Error with address form");
    }
  }

  // this.setState({
  //     errorText: this.printError(errorMessage)
  //   })
  async sendForgotPassword(email1) {
    let email = email1;
    if (email && email.length > 3) {
      email = email.toLowerCase();
    }
    try {
      await Auth.forgotPassword(email);
      console.log("EMAIL SENT");
    } catch (e) {
      alert(e.message);
      console.log("EMAIL SEND FAIL");
    }
  }

  async checkEmail(email1) {
    let success = true;
    let checked = "exists";

    let email = email1;
    if (email && email.length > 3) {
      email = email.toLowerCase();
    }

    let params = { email: email };

    await axios({
      method: "get",
      url: urls.API_GATEWAY + "users/check-email-basic",
      params: params,
    })
      .then((res) => {
        checked = res.data;
        if (
          checked === "null" ||
          checked === null ||
          typeof checked === "undefined"
        ) {
          checked = null;
          console.log("FAILURE!!");
        }
      })
      .catch((err) => {
        console.log("FAILURE adding user!!");
        console.log(err);
        success = false;
      });

    if (checked === "available") {
      success = true;
      console.log("email aavailable");
      // this.postDatabase();
    } else {
      success = false;
      console.log("username already in database");
      this.setState({
        errorText: "User with this email already exists",
      });
    }

    console.log(this.state.checked);
    return success;
  }

  password_generator = () => {
    var length = 12;
    var string = "abcdefghijklmnopqrstuvwxyz"; //to upper
    var numeric = "0123456789";
    var punctuation = "!@#$%^&*";
    var password = "";
    var character = "";
    var crunch = true;
    while (password.length < length) {
      let entity1 = Math.ceil(string.length * Math.random() * Math.random());
      let entity2 = Math.ceil(numeric.length * Math.random() * Math.random());
      let entity3 = Math.ceil(
        punctuation.length * Math.random() * Math.random()
      );
      let hold = string.charAt(entity1);
      hold = password.length % 2 === 0 ? hold.toUpperCase() : hold;
      character += hold;
      character += numeric.charAt(entity2);
      character += punctuation.charAt(entity3);
      password = character;
    }
    password = password
      .split("")
      .sort(function () {
        return 0.5 - Math.random();
      })
      .join("");
    return password.substr(0, length);
  };

  async checkCognito(details) {
    let success = true;
    console.log(details.password);
    let email = details.email;
    if (email && email.length > 3) {
      email = email.toLowerCase();
    }

    await Auth.signUp({
      username: email,
      password: details.password,
      attributes: {
        email: email,
      },
      region: "region eu-west-1",
    })
      .then((data) => {
        console.log(data);

        this.state.cognitoSuccess = true;
        details.aws_id = data.userSub;
        this.state.secret = data.user.pool.userPoolId;
        // this.sendEmail();
        // this.postDatabase();
        success = true;
        // this.sendForgotPassword(email)
      })
      .catch((err) => {
        console.log(err);
        success = false;
        this.state.cognitoSuccess = false;

        if (err.code === "UsernameExistsException") {
          this.setState({
            errorText: this.printError(["User with this email already exists"]),
          });
        } else {
          this.setState({
            errorText: this.printError(["Cognito adding error"]),
          });
        }
      });

    return success;
  }

  updateUserData(userData) {
    this.setState({ userData });
  }

  updateOrgData(orgData) {
    this.setState({ orgData });
  }
  updateOrgLogo(orgLogo) {
    this.setState({ orgLogo });
    console.log(orgLogo);
  }

  render() {
    return (
      <div className={c.page}>
        <EmptyHeader customstyle={{ backgroundColor: "rgb(6, 82, 221)" }} />

        <PageContainer>
          <PageContainer.Center>
            <PreTitle className={c.chart}>Create an account</PreTitle>
            <h3 className="mainTitle">Get Started with Balkerne</h3>
            <h4> Step {this.state.currentStep} of 4 </h4>
            <hr></hr>

            <UserGetStarted
              currentStep={this.state.currentStep}
              updateUserData={this.updateUserData}
            />
            <OrgGetStarted
              currentStep={this.state.currentStep}
              updateOrgData={this.updateOrgData}
              updateOrgLogo={this.updateOrgLogo}
              name={this.state.userData.organisation}
            />
            <PropertiesGetStarted
              currentStep={this.state.currentStep}
              handleChangeProperties={this.handleChangeProperties}
              handleSubmit={this.handleSubmit2}
              printError={this.printError}
              updateErrorText={this.updateErrorText}
              portfolio_type={this.state.userData.portfolio_type}
            />

            <LastPageGetStarted
              currentStep={this.state.currentStep}
              databaseSuccess={this.state.databaseSuccess}
            />

            <div className="d-flex justify-content-between mt-3">
              {this.previousButton}
              {this.nextButton}
            </div>

            {/* <div className="navigation text-center d-block mt-5 w-100 clearfix">
                {this.previousButton}
                {this.nextButton}
              </div> */}

            <div>{this.state.errorText}</div>
          </PageContainer.Center>
        </PageContainer>
      </div>
    );
  }
}

export default GetStarted;
