import React from 'react'
import c from './InputBox.module.scss'
import cn from 'classnames'

/**
  `import InputBox from "balkerne-components/InputBox"` 

  - Passes all attributes to textarea element, except for label, sublabel and error message
  - InputBox automatically stretches to accomodate more text, scrolling is disabled
  - Further documentation: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/textarea
**/

const InputBox = ({ label, className, ...props }) => {
  const input = React.createRef()
  const onInput = () => {
    input.current.style.height = `${input.current.scrollHeight + 2}px`
  }
  return (
    <div className={cn([c.container, className])}>
      <div className={c.label}>
        <span>
          {label}
          {props.required ? '*' : null}
        </span>
        <span className={c.error}>{props.error}</span>
      </div>
      <div className={c.sublabel}>
        <span>{props.sublabel}</span>
      </div>
      <textarea {...props} onInput={onInput} className={c.input} ref={input} />
    </div>
  )
}

export default InputBox
