import React from 'react'
import './Login.css'
import PortfolioLandingPage from './PortfolioLandingPage'
import ReactDOM from 'react-dom'

//import {MainMenu, Auth1} from './MainMenu';
import { userActions } from '../redux/_actions'
import { connect } from 'react-redux'
import { getUserDetails } from '../redux/_helpers/getUserDetails'

import Auth from '@aws-amplify/auth'

import axios from 'axios'
import * as urls from '../components/maps/urls'
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom'
import { history } from '../redux/_helpers'
import Loader from '../components/spinner'
import { systemActions } from '../redux/_actions'
import { Logout } from '../components/Logout'

export class Login extends React.Component {
  _isMounted = false
  constructor(props) {
    super(props)
    this.state = {
      toPortfolioLanding: false,
      changePasswordMode: false,
      email: '',
      password: '',
      password_new_1: '',
      password_new_2: '',
      user: '',
      isLoading: false,
      errMessage: '',
      success: true,
    }

    this.forgotPassword = this.forgotPassword.bind(this)
    this.signIn = this.signIn.bind(this)
  }

  forgotPassword() {
    this.props.history.push({
      pathname: '/ResetPassword',
    })
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  // async changePassword() {
  //   let changed = false;

  //   if (this.validatePassword() === true) {
  //     let success = false;

  //     console.log("Password change WORKED");
  //     changed = await this.changePasswordDatabase(
  //       this.state.email,
  //       this.state.password
  //     );

  //     if (changed === true) {
  //       await Auth.completeNewPassword(
  //         this.state.user,
  //         this.state.password_new_1
  //       )
  //         .then(user => {
  //           console.log("Password change success");
  //           console.log(user);
  //           alert("Success, Please Login.");
  //           history.push("/");
  //           // document.location.reload()

  //           success = true;
  //         })
  //         .catch(e => {
  //           success = false;
  //           console.log(e);
  //           console.log("Password change failed at cognito");
  //         });
  //     } else {
  //       console.log("Password change failed at database");
  //     }
  //   } else {
  //     console.log("Please enter valid and identical passowrds.");
  //   }
  // }
  // async changePasswordDatabase(email, password) {
  //   let success = true;
  //   let formData = new FormData();
  //   formData.append("email", email);
  //   formData.append("old_password", password);
  //   formData.append("new_password", this.state.password_new_1);

  //   let UserPostUrl = [urls.BACKEND, "users", "update-user-password"].join("/");
  //   await axios
  //     .post(UserPostUrl, formData, {
  //       headers: {
  //         "Content-Type": "multipart/form-data"
  //       }
  //     })
  //     .then(res => {
  //       console.log("password updated!!!!!");
  //       console.log(res.data);
  //       success = true;
  //       if (res.data !== "Success") {
  //         success = false;
  //         console.log("user password update failed in database!!");
  //       }

  //       console.log("bleb");
  //     })
  //     .catch(err => {
  //       console.log("user password update failed in database!!");
  //       console.log(err);
  //       success = false;
  //     });

  //   return success;
  // }

  async signIn(event) {
    this._isMounted = true
    this.setState({ isLoading: true })
    let success = false
    event.preventDefault()
    const password = this.state.password
    let email = this.state.email
    if (email && email.length > 0 && !email.toLowerCase().endsWith('arcusfm.com')) {
      email = email.toLowerCase()
    }
    let secret = ''
    await Auth.signIn(email, password)
      .then(async user => {
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          console.log('-------------------------FOUND PASSWORD RESET---------------------------------')
          //if(this._isMounted === true){
          this.state.user = user
          document.getElementById('login').reset()
          this.setState({ changePasswordMode: true })
          // }
        } else {
          success = true
          secret = user.signInUserSession.idToken.jwtToken

          console.log('SIGNIN WORKED')
          console.log(user)
          //console.log(secret);

          this.props.dispatch(userActions.loginLogged())
          this.props.dispatch(userActions.login(user))

          //Sets the default headers to have AUTH
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + secret

          //user.clearCachedTokens();
          // Object.keys(sessionStorage).forEach(key => {
          //   if (key.includes("Cognito")) {
          //     sessionStorage.removeItem(key);
          //   }
          // });
        }
      })
      .catch(err => {
        if (!err.message) {
          console.log('Error when signing in: ', err)
          //  Alert.alert('Error when signing in: ', err);
        } else {
          console.log('Error when signing in: ', err.message)
          //  Alert.alert('Error when signing in: ', err.message);
          // if(this._isMounted === true){
          this.setState({ success: false, errMessage: err.message })
          // }
        }
      })

    if (success === true) {
      let userdata = null
      userdata = await getUserDetails(email, password, secret)
      console.log(userdata)
      if (userdata === null) {
        this.props.dispatch(userActions.logoutLogged())
        this.props.dispatch(userActions.login(null))
        console.log('BACKEND NOT WORKING')
        //if(this._isMounted === true){
        this.setState({
          success: false,
          errMessage: 'Cannot reach the Server.',
        })
        //}
      } else {
        this.props.dispatch(userActions.setUserData(userdata))

        history.push('/')
      }
      // this.props.dispatch(userActions.setUserData(userdata));
      // history.push("/");
      // document.location.reload()
    }
    if (this._isMounted === true) {
      this.setState({ isLoading: false })
    }
  }

  showNotice() {
    if (this.state.success === false) {
      return (
        <div className="alert alert-danger" role="alert">
          {this.state.errMessage}
        </div>
      )
    }
  }

  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0
  }

  validatePassword = () => {
    // console.log(this.state.password_new_1.length);
    // console.log(this.state.password_new_2.length);
    // console.log(this.state.password_new_1 === this.state.password_new_2);

    return (
      this.state.password_new_1.length > 8 &&
      this.state.password_new_2.length > 8 &&
      this.state.password_new_1 === this.state.password_new_2 &&
      this.state.password_new_1 !== this.state.password
    )
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value,
    })
  }

  async componentDidMount() {
    this._isMounted = true
    //console.log(userActions.getAll())
    //this.props.dispatch(userActions.getAll());
  }

  render() {
    if (this.state.toPortfolioLanding === true) {
      {
        console.log('redirecting')
      }
      return this.props.history.push({
        pathname: '/',
      })
    }

    // if (this.state.changePasswordMode === true) {
    //   return (
    //     <div id="content">
    //       <div className="wrapper fadeInDown">
    //         <div id="formContent">
    //           <div className="fadeIn first">
    //             <h1 className="text-primary m-4">Balkerne</h1>
    //           </div>

    //           <form id="form-change-password">
    //             <input
    //               autoFocus
    //               type="text"
    //               id="password_new_1"
    //               className="fadeIn second"
    //               name="password_new_1"
    //               placeholder="password1"
    //               onChange={this.handleChange}
    //             />
    //             <input
    //               type="text"
    //               id="password_new_2"
    //               className="fadeIn third"
    //               name="password_new_2"
    //               placeholder="password2"
    //               onChange={this.handleChange}
    //             />
    //             <input
    //               type="button"
    //               className="fadeIn fourth"
    //               value="Change password"
    //               onClick={this.changePassword}
    //               disabled={!this.validatePassword}
    //             />
    //           </form>
    //         </div>
    //       </div>
    //     </div>
    //   );
    // }
    return (
      <div id="content">
        <div className="wrapper fadeInDown">
          <div id="formContent">
            <img className="login_logo" src={require('../images/Logo.png')} />

            <form id="login">
              <div className="logininput">
                <input
                  autoFocus
                  type="email"
                  id="email"
                  className="fadeIn second"
                  name="login"
                  placeholder="email"
                  onChange={this.handleChange}
                />
                <input
                  type="password"
                  id="password"
                  className="fadeIn third"
                  name="password"
                  placeholder="password"
                  onChange={this.handleChange}
                />
                <input
                  type="button"
                  className="fadeIn fourth"
                  value="Log In"
                  onClick={this.signIn}
                  disabled={!this.validateForm()}
                />
              </div>
            </form>
            <div id="formFooter">
              <a className="underlineHover" href="#" onClick={this.forgotPassword}>
                Forgot Password?
              </a>
            </div>

            {this.state.isLoading ? 'Logging In...' : this.showNotice()}
          </div>
        </div>
      </div>
    )
  }
}
function mapStateToProps(state) {
  //console.log(state);
  const { userLogged, user, userData } = state

  return {
    user,
    userLogged,
    userData,
  }
}

//const connectedHomePage = connect(mapStateToProps)(HomePage);
export default connect(mapStateToProps)(Login)
