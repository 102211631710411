import { combineReducers } from "redux";

import { authentication } from "./authentication.reducer";
import {
  systemData,
  mapAlertData,
  mapLocationData,
  dashboardData,
} from "./system.reducer";
import { user, userData, userLogged } from "./users.reducer";

const rootReducer = combineReducers({
  //authentication,
  user,
  userData,
  userLogged,
  systemData,
  mapAlertData,
  mapLocationData,
  dashboardData,
});

export default rootReducer;
