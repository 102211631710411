import React, { lazy, Suspense } from 'react'
import LandingPageSearch from '../components/LandingPageSearch'
import ControlledTabs from '../components/ControlledTabs'
import axios from 'axios'
import * as urls from '../components/maps/urls'
import AppClone from '../components/maps/AppClone'
import AppCloneHistorical from '../components/maps/AppCloneHistorical'
import { activeLayersHistorical, activeLayers } from '../components/maps/config'
import ReactTooltip from 'react-tooltip'
import Header from '../components/Header'
import Loader from '../components/spinner'
import ReactGA from 'react-ga'
import './Portfoliolandingpage.css'
import { CSVLink, CSVDownload } from 'react-csv'
import { userActions } from '../redux/_actions'
import { systemActions } from '../redux/_actions'
import { connect } from 'react-redux'
import { Logout } from '../components/Logout'
import { RefreshAuthTokens } from '../components/RefreshAuthTokens'
import { systemReduxHandler } from '../redux/_helpers/systemReduxHandler.js'
import { systemReduxMapHandler } from '../redux/_helpers/systemReduxMapHandler.js'
import { isMobile, isBrowser } from 'react-device-detect'
import { getUserLocationList } from '../redux/_helpers/getUserLocationList'

import { Auth } from 'aws-amplify'
import Button, { DownloadButton } from 'balkerne-components/Button'
import Card from 'react-bootstrap/Card'
import { check } from 'balkerne-components/Can'
import { hotjar } from 'react-hotjar'

//const Logout = React.lazy(() => import ('../components/Logout'));
//const ControlledTabs = React.lazy(() => import ('../components/ControlledTabs'))
//const AppClone = React.lazy(() => ('../components/maps/AppClone'))
//const ReactTooltip = React.lazy(() => ('react-tooltip'))
//const AppCloneHistorical = React.lazy (() => ('../components/maps/AppCloneHistorical'))
//const systemReduxHandler = React.lazy (() => ('../redux/_helpers/systemReduxHandler.js'))

class PortfolioLandingPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      searchVisible: true,
      currentUser: 91,
      locations: [],
      alerts: [],
      historical: false,
      historicalLayersSwitch: activeLayers,
      selectedLocation: 1,
      selectedLocationIndex: 0,
      redirect: false,
      alert_locations: null,
      risk_table: null,

      redirectID: 1,
      redirectLocation: null,
      redirectAlerts: [],
      userLocations: [],
      search_stores_list: [],

      selectedAlertSeverity: 0,
      selectedRiskSeverity: 0,
      selectedPropertyType: 'rofrs',
      errorArray: [],
      errorText: '',
      mounted: false,
      rofrs: [0, 0, 0, 0, 0],
      rofsw: [0, 0, 0, 0, 0],
      crime: [0, 0, 0, 0, 0],
      fire: [0, 0, 0, 0, 0],
      food_hygiene: [0, 0, 0, 0, 0],
      display_food_hygiene: true,
      totalSainsburysLocations: 1200,
      isLoading: false,
      org_id: -1,
      showingBubbles: false,
      showingRisk: false,
      errMessage: '',
      success: true,
      mapLocationData: [],
      current_page: this.props.location.pathname || '',
      loadedMapLocationData: false,
      csvLocations: null,
      csvName: '',
      refreshedToken: false,
    }
    this.handleRedirect = this.handleRedirect.bind(this)
    this.loadRiskLocations = this.loadRiskLocations.bind(this)
    this.updateSearchLocations = this.updateSearchLocations.bind(this)
    this.getSearchLocations = this.getSearchLocations.bind(this)
  }

  // initialises google analytics tracking of the web application
  initialiseAnalytics = () => {
    ReactGA.initialize('UA-146992643-1')
    ReactGA.pageview('/PortfolioDashboard')
    hotjar.initialize(2311893, 6)
  }

  loadCSV = () => {
    let alertLocations = this.state.alertlocations
    console.log('csv loading')
    if (alertLocations) {
      let finalList = []
      for (let alert_loc in alertLocations.severe.features) {
        alert_loc = alertLocations.severe.features[alert_loc].properties

        for (let alert in alert_loc.alerts) {
          alert = alert_loc.alerts[alert]

          let alert_item = {}
          alert_item.created_at = alert.timeRaised
          alert_item.updated_at = alert.timeMessageChanged
          alert_item.active = alert.active

          alert_item.type = alert.type
          alert_item.ended_at = alert.timeEnded

          alert_item.message = alert.message
          // alert_item.description = alert.description

          if (alert.isActive) {
            alert_item.active = 'Active'
          } else {
            alert_item.active = 'Ended'
          }

          if (alert.severityLevel === 3) {
            alert_item.type = 'Flood Alert'
          } else if (alert.severityLevel === 2) {
            alert_item.type = 'Flood Warning'
          } else if (alert.severityLevel === 1) {
            alert_item.type = 'Severe Flood Warning'
          }

          if (alert_loc.name) {
            alert_item.location_name = alert_loc.name
          } else {
            alert_item.location_name = ''
          }
          if (alert_loc.property_customer_reference) {
            alert_item.location_crn = alert_loc.property_customer_reference
          } else {
            alert_item.location_crn = ''
          }

          finalList.push(alert_item)
        }
      }
      for (let alert_loc in alertLocations.warnings.features) {
        alert_loc = alertLocations.warnings.features[alert_loc].properties
        // console.log(alert_loc)
        // console.log(alert_loc.alerts)
        for (let alert in alert_loc.alerts) {
          alert = alert_loc.alerts[alert]

          let alert_item = {}
          alert_item.created_at = alert.timeRaised
          alert_item.updated_at = alert.timeMessageChanged
          alert_item.active = alert.active

          alert_item.type = alert.type
          alert_item.ended_at = alert.timeEnded

          alert_item.message = alert.message
          // alert_item.description = alert.description

          if (alert.isActive) {
            alert_item.active = 'Active'
          } else {
            alert_item.active = 'Ended'
          }

          if (alert.severityLevel === 3) {
            alert_item.type = 'Flood Alert'
          } else if (alert.severityLevel === 2) {
            alert_item.type = 'Flood Warning'
          } else if (alert.severityLevel === 1) {
            alert_item.type = 'Severe Flood Warning'
          }

          if (alert_loc.name) {
            alert_item.location_name = alert_loc.name
          } else {
            alert_item.location_name = ''
          }
          if (alert_loc.property_customer_reference) {
            alert_item.location_crn = alert_loc.property_customer_reference
          } else {
            alert_item.location_crn = ''
          }

          finalList.push(alert_item)
        }
      }
      for (let alert_loc in alertLocations.alerts.features) {
        alert_loc = alertLocations.alerts.features[alert_loc].properties
        // console.log(alert_loc)
        for (let alert in alert_loc.alerts) {
          alert = alert_loc.alerts[alert]
          // console.log(alert)
          let alert_item = {}
          alert_item.created_at = alert.timeRaised
          alert_item.updated_at = alert.timeMessageChanged
          alert_item.active = alert.active

          alert_item.type = alert.type
          alert_item.ended_at = alert.timeEnded

          alert_item.message = alert.message
          // alert_item.description = alert.description

          if (alert.isActive) {
            alert_item.active = 'Active'
          } else {
            alert_item.active = 'Ended'
          }

          if (alert.severityLevel === 3) {
            alert_item.type = 'Flood Alert'
          } else if (alert.severityLevel === 2) {
            alert_item.type = 'Flood Warning'
          } else if (alert.severityLevel === 1) {
            alert_item.type = 'Severe Flood Warning'
          }

          if (alert_loc.name) {
            alert_item.location_name = alert_loc.name
          } else {
            alert_item.location_name = ''
          }
          if (alert_loc.property_customer_reference) {
            alert_item.location_crn = alert_loc.property_customer_reference
          } else {
            alert_item.location_crn = ''
          }

          finalList.push(alert_item)
        }
      }

      let newDate = new Date()
      let date = newDate.getDate()
      let month = newDate.getMonth() + 1
      let year = newDate.getFullYear()
      let csv_string = date + '-' + month + '-' + year + ' Balkerne Alerts.csv'

      this.setState({ csvName: csv_string })
      this.setState({ csvLocations: finalList })
    }
    // if (this.state.success === false) {
    //   return (
    //     <div className="alertbox">
    //     {this.state.errMessage}
    //   </div>
    //   )
    // }
  }

  async loadRiskLocations() {
    let layer = { name: 'Risk Properties' }
    await systemReduxMapHandler.directLayer(
      this.props,
      layer,
      this.state.userLocations,
      this.props.user.user.signInUserSession.idToken.jwtToken,
      this.props.userData.data.organisation,
    )
    if (this.props.mapLocationData.data.locations !== null) {
      this.state.loadedMapLocationData = true
      this.setState({
        mapLocationData: this.props.mapLocationData.data.locations,
      })
    } else {
      if (
        this.state.success === true &&
        this.props.mapLocationData.data &&
        this.props.mapLocationData.data.locations === null
      ) {
        console.log('Error when loading map risk locations: ')
        //  Alert.alert('Error when signing in: ', err.message);
        this.setState({
          success: false,
          errMessage: 'Failed to reach server.',
        })
      }
    }
  }

  showNotice = () => {
    // if (this.state.success === false) {
    //   return (
    //     <div className="alertbox">
    //     {this.state.errMessage}
    //   </div>
    //   )
    // }
  }

  async getSearchLocations(location_list) {
    let data = { location_list: location_list }

    await axios({
      method: 'post',
      url: urls.API_GATEWAY + 'locations/autocomplete-search',
      data: data,
    })
      .then(res => {
        console.log(res.data)
        let search_stores_list = res.data

        if (
          search_stores_list === 'null' ||
          search_stores_list === null ||
          search_stores_list === 'Failure' ||
          typeof search_stores_list === 'undefined'
        ) {
          search_stores_list = []
          console.log('Property-search list getting ERROR')
        } else if (search_stores_list === 'Invalid Details') {
          console.log('Credentials for getting property-search-list, details are INVALID')
          search_stores_list = []
        } else if (search_stores_list === 'No properties found') {
          console.log('No properties were found!')
          search_stores_list = []
        } else {
          console.log('SUCCESS!!')
          console.log('Properties search list Data retrieved from the database.')
          this.setState({ search_stores_list })
        }
      })
      .catch(err => {
        if (this.state.success === true) {
          console.log('Error when loading stores list: ')
          //  Alert.alert('Error when signing in: ', err.message);
          this.setState({
            success: false,
            errMessage: 'Failed to reach server.',
          })
        }
        console.log('FAILURE for store retrieval!!')
        console.log(err)
      })
  }

  //Function setting the severity of the map filter that is provided by the controlled tabs child component
  //(clicking on bubbles)
  //--- TO BE OPTIMIZED ---

  setAlertSeverity = severity => {
    if (this.state.selectedAlertSeverity !== severity) {
      this.state.selectedAlertSeverity = severity
      this.state.showingBubbles = true
      this.state.showingRisk = false
      this.state.selectedRiskSeverity = 0

      this.setState({ alertUpdated: true })
    }
  }

  //Fetches and filters the location data based on what has been clicked in the Risk Bar
  setLocationData = (riskSeverity, type) => {
    // console.log("clicked location severity " + riskSeverity + " and type: " + type);
    // console.log(
    //   "old location severity " + this.state.selectedRiskSeverity + " and type: " + this.state.selectedPropertyType
    // );
    if (
      this.state.loadedMapLocationData &&
      (this.state.selectedRiskSeverity !== riskSeverity || this.state.selectedPropertyType !== type)
    ) {
      this.state.showingBubbles = false
      this.state.showingRisk = true
      this.state.selectedAlertSeverity = 0

      // console.log("FIRE");

      if (type === 'rofrs') {
        console.log('rofrs')
        if (riskSeverity === 4) {
          if (this.props.mapLocationData.data.rofrs_high !== 'empty') {
            this.state.selectedRiskSeverity = riskSeverity
            this.state.selectedPropertyType = type
            this.setState({ locationFilterDataUpdated: true })
          }
        } else if (riskSeverity === 3) {
          if (this.props.mapLocationData.data.rofrs_medium !== 'empty') {
            this.state.selectedRiskSeverity = riskSeverity
            this.state.selectedPropertyType = type
            this.setState({ locationFilterDataUpdated: true })
          }
        } else if (riskSeverity === 1) {
          if (this.props.mapLocationData.data.rofrs_low !== 'empty') {
            this.state.selectedRiskSeverity = riskSeverity
            this.state.selectedPropertyType = type
            this.setState({ locationFilterDataUpdated: true })
          }
        }
      }

      if (type === 'rofsw') {
        //console.log("rofsw");
        if (riskSeverity === 4) {
          if (this.props.mapLocationData.data.rofsw_high !== 'empty') {
            this.state.selectedRiskSeverity = riskSeverity
            this.state.selectedPropertyType = type
            this.setState({ locationFilterDataUpdated: true })
          }
        } else if (riskSeverity === 3) {
          if (this.props.mapLocationData.data.rofsw_medium !== 'empty') {
            this.state.selectedRiskSeverity = riskSeverity
            this.state.selectedPropertyType = type
            this.setState({ locationFilterDataUpdated: true })
          }
        } else if (riskSeverity === 1) {
          if (this.props.mapLocationData.data.rofsw_low !== 'empty') {
            this.state.selectedRiskSeverity = riskSeverity
            this.state.selectedPropertyType = type
            this.setState({ locationFilterDataUpdated: true })
          }
        }
      }

      if (type === 'crime') {
        // console.log("crime");
        if (riskSeverity === 4) {
          if (this.props.mapLocationData.data.crime_high !== 'empty') {
            this.state.selectedRiskSeverity = riskSeverity
            this.state.selectedPropertyType = type
            this.setState({ locationFilterDataUpdated: true })
          }
        } else if (riskSeverity === 3) {
          if (this.props.mapLocationData.data.crime_medium !== 'empty') {
            this.state.selectedRiskSeverity = riskSeverity
            this.state.selectedPropertyType = type
            this.setState({ locationFilterDataUpdated: true })
          }
        } else if (riskSeverity === 1) {
          if (this.props.mapLocationData.data.crime_low !== 'empty') {
            this.state.selectedRiskSeverity = riskSeverity
            this.state.selectedPropertyType = type
            this.setState({ locationFilterDataUpdated: true })
          }
        }
      }

      if (type === 'fire') {
        // console.log("fire");
        if (riskSeverity === 4) {
          if (this.props.mapLocationData.data.fire_high !== 'empty') {
            this.state.selectedRiskSeverity = riskSeverity
            this.state.selectedPropertyType = type
            this.setState({ locationFilterDataUpdated: true })
          }
        } else if (riskSeverity === 3) {
          if (this.props.mapLocationData.data.fire_medium !== 'empty') {
            this.state.selectedRiskSeverity = riskSeverity
            this.state.selectedPropertyType = type
            this.setState({ locationFilterDataUpdated: true })
          }
        } else if (riskSeverity === 1) {
          if (this.props.mapLocationData.data.fire_low !== 'empty') {
            this.state.selectedRiskSeverity = riskSeverity
            this.state.selectedPropertyType = type
            this.setState({ locationFilterDataUpdated: true })
          }
        }
      }

      if (type === 'food_hygiene') {
        // console.log("food_hygiene");
        if (riskSeverity === 4) {
          if (this.props.mapLocationData.data.food_hygiene_high !== 'empty') {
            this.state.selectedRiskSeverity = riskSeverity
            this.state.selectedPropertyType = type
            this.setState({ locationFilterDataUpdated: true })
          }
        } else if (riskSeverity === 3) {
          if (this.props.mapLocationData.data.food_hygiene_medium !== 'empty') {
            this.state.selectedRiskSeverity = riskSeverity
            this.state.selectedPropertyType = type
            this.setState({ locationFilterDataUpdated: true })
          }
        } else if (riskSeverity === 1) {
          if (this.props.mapLocationData.data.food_hygiene_low !== 'empty') {
            this.state.selectedRiskSeverity = riskSeverity
            this.state.selectedPropertyType = type
            this.setState({ locationFilterDataUpdated: true })
          }
        }
      }

      if (type === 'pandemic') {
        // console.log("pandemic");
        if (riskSeverity === 4) {
          if (this.props.mapLocationData.data.pandemic_high !== 'empty') {
            this.state.selectedRiskSeverity = riskSeverity
            this.state.selectedPropertyType = type
            this.setState({ locationFilterDataUpdated: true })
          }
        } else if (riskSeverity === 3) {
          if (this.props.mapLocationData.data.pandemic_medium !== 'empty') {
            this.state.selectedRiskSeverity = riskSeverity
            this.state.selectedPropertyType = type
            this.setState({ locationFilterDataUpdated: true })
          }
        } else if (riskSeverity === 1) {
          if (this.props.mapLocationData.data.pandemic_low !== 'empty') {
            this.state.selectedRiskSeverity = riskSeverity
            this.state.selectedPropertyType = type
            this.setState({ locationFilterDataUpdated: true })
          }
        }
      }
    }
  }

  //function setting up behaviour to activate a function in a child component appclone

  passToApp = functsetalertfilter => {
    this.setAlertFilter = functsetalertfilter
  }

  passToApp2 = functsetlocationfilter => {
    this.setLocationFilter = functsetlocationfilter
  }

  //Redirects the page to the chosen user dashboard for a location (redirection inside render method)

  async handleRedirect(location1) {
    this.state.redirectCountry = location1.properties.address_country
    this.state.redirectID = location1.properties.id

    this.state.redirectLocation = null
    if (check('locations:read', null, this.props.userData.data.permissions)) {
      this.props.history.push({
        pathname: '/UserDashboard',
        state: {
          redirectCountry: this.state.redirectCountry,
          redirectID: this.state.redirectID,
          historical: this.state.historical,
          location: this.state.redirectLocation,
          handleLogout: this.props.handleLogout,
        },
      })
    } else {
      console.log('User permissions failed')
    }
  }

  redirectLocation = () => {
    if (check('locations:read', null, this.props.userData.data.permissions)) {
      this.props.history.push({
        pathname: '/LocationListPage',
        state: {
          handleLogout: this.props.handleLogout,
        },
      })
    } else {
      console.log('User permissions failed')
    }
  }

  //Switches to historical mode, sets alerts to be displayed to historical along with the layers

  switchHistorical = () => {
    this.state.mounted = false
    if (this.state.historical === false) {
      this.state.historical = true
      this.componentDidMount()
      this.state.historicalLayersSwitch = activeLayersHistorical
    } else {
      this.state.historical = false
      this.componentDidMount()
      this.state.historicalLayersSwitch = activeLayers
    }
  }

  receivedAlertLocations = () => {
    this.state.alertlocations = this.state.alertlocations
  }

  //Resets the risk location table, retrieves all alerts all locations relevant to the portfoliolanding page
  //and loads the data for the map
  //(Communicates with backend)

  async updateSearchLocations() {
    let userdata1 = await getUserLocationList(
      this.props.userData.data.id,
      this.props.userData.data.aws_id,
      this.props.user.user.signInUserSession.idToken.jwtToken,
    )
    console.log(userdata1)
    if (userdata1 !== null) {
      let userData = this.props.userData.data
      userData.locations = userdata1

      await this.props.dispatch(userActions.updateUserLocations(userData))
      await this.getSearchLocations(userdata1)
    }
  }

  async componentDidMount() {
    this.initialiseAnalytics()

    this.setState({ isLoading: true })
    this.state.rofrs = [0, 0, 0, 0, 0]
    this.state.rofsw = [0, 0, 0, 0, 0]
    this.state.crime = [0, 0, 0, 0, 0]
    this.state.fire = [0, 0, 0, 0, 0]
    this.state.food_hygiene = [0, 0, 0, 0, 0]

    //THIS PIECE OF CODE REFRESHES THE ACCESS JWT CODE AND SETS AXIOS HEADERS
    await RefreshAuthTokens(this.props)

    this.state.org_id = this.props.userData.data.orgid
    this.state.current_user_id = this.props.userData.data.id

    if (
      typeof this.props.location.state !== 'undefined' &&
      this.props.location.state !== null &&
      typeof this.props.location.state.locations_updated !== 'undefined' &&
      this.props.location.state.locations_updated === true
    ) {
      let userdata1 = await getUserLocationList(
        this.props.userData.data.id,
        this.props.userData.data.aws_id,
        this.props.user.user.signInUserSession.idToken.jwtToken,
      )
      if (userdata1 !== null) {
        let userData = this.props.userData.data
        userData.locations = userdata1

        await this.props.dispatch(userActions.updateUserLocations(userData))

        await this.getSearchLocations(userdata1)
      }
    }

    let userLocations = this.props.userData.data.locations
    this.state.userLocations = userLocations
    if (isBrowser) {
      await systemReduxHandler.setSystemData(
        this.props,
        userLocations,
        this.props.user.user.signInUserSession.idToken.jwtToken,
        this.props.userData.data.organisation,
      )
      this.setState({ csvLocations: null })
    } else {
      await systemReduxHandler.setSystemDataMobile(
        this.props,
        userLocations,
        this.props.user.user.signInUserSession.idToken.jwtToken,
      )
    }

    //gets either real or historical alerts
    if (this.state.historical === true && isBrowser) {
      let alertAndLocation_url = urls.BACKEND + '/locations/alert-locations-historical'

      let alertLocationTableFormData = new FormData()
      alertLocationTableFormData.append('location_list', this.state.userLocations)

      await axios
        .post(alertAndLocation_url, alertLocationTableFormData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(res => {
          const alertsAndLocations = res.data

          this.state.alertlocations = alertsAndLocations
        })
        .catch(err => {
          console.log('FAILURE!!')
          console.log(err)
          if (!err.message) {
            console.log('Error when loading alert-locations: ', err)
            //  Alert.alert('Error when signing in: ', err);
          } else {
            console.log('Error when loading alert-locations: ', err.message)
            //  Alert.alert('Error when signing in: ', err.message);
            this.setState({
              success: false,
              errMessage: 'Could not Reach Server',
            })
          }
        })
    } else {
      // console.log(this.props.systemData.data.alert_locations);

      await systemReduxHandler.updateAlertLocations(
        this.props,
        userLocations,
        this.props.user.user.signInUserSession.idToken.jwtToken,
      )
      if (this.props.systemData.data.alert_locations === null) {
        //console.log(this.props.systemData.data.alert_locations);
        await this.props.dispatch(systemActions.clearSystemData())
        if (isBrowser) {
          await systemReduxHandler.setSystemData(
            this.props,
            userLocations,
            this.props.user.user.signInUserSession.idToken.jwtToken,
            this.props.userData.data.organisation,
          )
        } else {
          await systemReduxHandler.setSystemDataMobile(
            this.props,
            userLocations,
            this.props.user.user.signInUserSession.idToken.jwtToken,
          )
        }
        if (this.state.success === true && this.props.systemData.data.alert_locations === null) {
          console.log('Error when loading alert-locations: ')
          //  Alert.alert('Error when signing in: ', err.message);
          //  this.setState({success:false, errMessage:"Failed to reach server."})
        }
      } else {
        const alertsAndLocations = this.props.systemData.data.alert_locations
        if (alertsAndLocations !== this.state.alertlocations) {
          this.setState({ csvLocations: null })
        }
        // this.state.alerts = alertsAndLocations.alerts.features;
        this.state.alertlocations = alertsAndLocations
      }
    }

    if (this.state.search_stores_list.length < 1 && this.state.userLocations) {
      await this.getSearchLocations(this.state.userLocations)
    }
    if (isBrowser) {
      await systemReduxHandler.updateRiskTable(
        this.props,
        userLocations,
        this.props.user.user.signInUserSession.idToken.jwtToken,
        this.props.userData.data.organisation,
      )
      if (this.props.systemData.data.risk_table !== null) {
        this.state.rofrs = this.props.systemData.data.risk_table.rofrs

        this.state.rofsw = this.props.systemData.data.risk_table.rofsw
        if (this.props.systemData.data.risk_table.crime) {
          this.state.crime = this.props.systemData.data.risk_table.crime
        }

        if (this.props.systemData.data.risk_table.fire) {
          this.state.fire = this.props.systemData.data.risk_table.fire
        }
        if (this.props.systemData.data.risk_table.food_hygiene) {
          this.state.food_hygiene = this.props.systemData.data.risk_table.food_hygiene
          this.state.display_food_hygiene = true
        }
      } else {
        if (
          this.state.success === true &&
          this.props.systemData.data &&
          this.props.systemData.data.risk_table === null
        ) {
          console.log('Error when loading risk table: ')
          //  Alert.alert('Error when signing in: ', err.message);
          this.setState({
            success: false,
            errMessage: 'Failed to reach server.',
          })
        }
      }
    }

    if (isBrowser) {
      await systemReduxHandler.updateWeatherWarnings(
        this.props,
        this.props.user.user.signInUserSession.idToken.jwtToken,
      )
      if (this.props.systemData.data.weather_warnings !== null) {
        this.state.weather_warnings = this.props.systemData.data.weather_warnings
      } else {
        if (
          this.state.success === true &&
          this.props.systemData.data &&
          this.props.systemData.data.weather_warnings === null
        ) {
          console.log('Error when loading weather warnings: ')
          //  Alert.alert('Error when signing in: ', err.message);
          this.setState({
            success: false,
            errMessage: 'Failed to reach server.',
          })
        }
      }
    }

    if (isBrowser) {
      this.loadRiskLocations()
    }

    this.setState({ mounted: true })
    this.setState({ locationData: this.state.alertlocations })

    this.setState({ isLoading: false })
  }

  // selects a location from location list by index
  selectLocationIndex = index => {
    this.setState({ selectedLocationIndex: index })
  }

  //displays the map based on whether real time or historical mode is selected
  //with the given severity filter and communication method function to change the filters in the map

  displayMap = () => {
    if (this.state.mounted === true && this.state.alertUpdated !== false && isBrowser) {
      if (this.state.historical === false) {
        return (
          <AppClone
            //props={this.props}
            setAlertFilter={this.passToApp.bind(this)}
            setLocationFilter={this.passToApp2.bind(this)}
            selectedAlertSeverity={this.state.selectedAlertSeverity}
            selectedRiskSeverity={this.state.selectedRiskSeverity}
            selectedPropertyType={this.state.selectedPropertyType}
            redirect={this.handleRedirect}
            locations={this.state.userLocations}
            locationsList={this.state.alertlocations}
            alerts={this.state.alerts}
            showingBubbles={this.state.showingBubbles}
            showingRisk={this.state.showingRisk}
            mapLocationDataList={this.state.mapLocationData}
          />
        )
      } else {
        return (
          <AppCloneHistorical
            //props={this.props}
            setAlertFilter={this.passToApp.bind(this)}
            setLocationFilter={this.passToApp2.bind(this)}
            selectedAlertSeverity={this.state.selectedAlertSeverity}
            selectedRiskSeverity={this.state.selectedRiskSeverity}
            selectedPropertyType={this.state.selectedPropertyType}
            redirect={this.handleRedirect}
            locations={this.state.userLocations}
            locationsList={this.state.alertlocations}
            alerts={this.state.alerts}
            showingBubbles={this.state.showingBubbles}
            showingRisk={this.state.showingRisk}
            mapLocationData={this.state.mapLocationData}
          />
        )
      }
    }
  }
  // popover() {
  //   <Popover id="popover-basic">
  //     <Popover.Title as="h3">Popover right</Popover.Title>
  //     <Popover.Content>
  //       And here's some <strong>amazing</strong> content. It's very engaging.
  //       right?
  //     </Popover.Content>
  //   </Popover>;
  // }

  // Example = () => {
  //  <OverlayTrigger trigger="click" placement="right" overlay={this.popover}>
  //     <Button variant="success">Click me to see</Button>
  //   </OverlayTrigger>;
  // };

  render() {
    const headers = [
      { label: 'Issued at', key: 'created_at' },
      { label: 'Updated at', key: 'updated_at' },
      { label: 'Ended At', key: 'ended_at' },
      { label: 'Location', key: 'location_name' },
      { label: 'CRN', key: 'location_crn' },
      { label: 'Alert Type', key: 'type' },
      { label: 'Status', key: 'active' },
      { label: 'Message', key: 'message' },
      // { label: "Description", key: "description" },
      // { label: "Updated", key: "updated_at" },
      // { label: "Issued", key: "created_at" },
      // { label: "Updated", key: "updated_at" },
      // { label: "Issued", key: "created_at" },
      // { label: "Updated", key: "updated_at" },
      // { label: "Issued", key: "created_at" },
      // { label: "Updated", key: "updated_at" },
    ]

    if (isBrowser && this.state.risk_table) {
      this.state.rofrs = this.state.risk_table.rofrs

      this.state.rofsw = this.state.risk_table.rofsw
    }

    //if no redirect, render the porfolio landing page

    if (this.state.mounted === false)
      return (
        <React.Fragment>
          <Header
            user_permissions={this.props.userData.data.permissions}
            user_portfolio_type={this.props.userData.data.portfolio_type}
            customstyle={this.props.userData.data.customstyle}
            currentpage={this.state.current_page}
            organisation={this.props.userData.data.orgid}
            logout={() => Logout(this.props)}
          />
          <Loader color="#0652dd" />
        </React.Fragment>
      )

    //console.log(this.props.userData.data.organisation.organisation_name)
    //console.log(this.props.userData.data.organisation.logo)

    let org_name = 'Organisation'
    if (
      typeof this.props.userData.data.organisation.organisation_name !== 'undefined' &&
      this.props.userData.data.organisation.organisation_name !== 'None'
    ) {
      org_name = this.props.userData.data.organisation.organisation_name
    } else if (typeof this.props.userData.data.org_name !== 'undefined') {
      org_name = this.props.userData.data.org_name
    }

    let org_image = require('../images/Logo.png')
    if (
      typeof this.props.userData.data.organisation.logo !== 'undefined' &&
      this.props.userData.data.organisation.logo !== 'None'
    ) {
      org_image = urls.ORGANISATIONS + this.props.userData.data.organisation.logo
    } else if (typeof this.props.userData.data.org_url !== 'undefined') {
      org_image = this.props.userData.data.org_url
    }

    return (
      <React.Fragment>
        {/* <YMInitializer accounts={[55276393]} /> */}

        <Header
          user_permissions={this.props.userData.data.permissions}
          user_portfolio_type={this.props.userData.data.portfolio_type}
          logout={() => Logout(this.props)}
          customstyle={this.props.userData.data.customstyle}
          organisation={this.props.userData.data.orgid}
          currentpage={this.state.current_page}
        />

        <div className="main-page">
          <div className="pagecontent">
            <div className="Orgdetails">
              <img src={org_image}></img>

              <h2>{org_name}</h2>
            </div>
            {this.showNotice()}
            <div style={{ zIndex: '2' }}>
              <LandingPageSearch
                currentStep={this.state.currentStep}
                handleChange={this.handleChange}
                locations={this.state.search_stores_list}
                redirect={this.handleRedirect}
                onClickLocationList={this.redirectLocation}
                customstyle={this.props.userData.data.customstyle}
                refreshLocations={this.updateSearchLocations}
                secret={this.props.user.user.signInUserSession.idToken.jwtToken}
                orgid={this.props.userData.data.orgid}
              />
            </div>

            <div id="propertyrisktable" className="Riskbyproperty">
              <h3>Portfolio Risk Breakdown : {this.state.search_stores_list.length} Properties</h3>

              <div className="Riskmatrix">
                <p />
                <h6 className="matrixheading">
                  <a data-tip data-for="rofrstip">
                    <i className="fa fa-info-circle" />
                  </a>
                  <br />
                  River Flood
                </h6>
                <h6 className="matrixheading">
                  <a data-tip data-for="rofswtip">
                    <i className="fa fa-info-circle" />
                  </a>
                  <br />
                  Surface Water Flood
                </h6>
                <h6 className="matrixheading">
                  <a data-tip data-for="crimetip">
                    <i className="fa fa-info-circle" />
                  </a>
                  <br />
                  Crime
                </h6>
                <h6 className="matrixheading">
                  <a data-tip data-for="firetip">
                    <i className="fa fa-info-circle" />
                  </a>
                  <br />
                  Fire
                </h6>

                {/* {this.state.display_food_hygiene?(
                  <h6 className="matrixheading">
                  <a data-tip data-for="foodtip">
                    <i className="fa fa-info-circle" />
                  </a>
                  <br />
                  Food Hygiene
                </h6>
                ):("")} */}

                <h6 className="rowheading">High</h6>
                <div
                  className="highbox"
                  onClick={e => {
                    this.setLocationData(4, 'rofrs')
                  }}>
                  <a href="#dashboard_map">{this.state.rofrs[4]}</a>
                </div>

                <div
                  className="highbox"
                  onClick={e => {
                    this.setLocationData(4, 'rofsw')
                  }}>
                  <a href="#dashboard_map">{this.state.rofsw[4]}</a>
                </div>

                <div
                  className="highbox"
                  onClick={e => {
                    this.setLocationData(4, 'crime')
                  }}>
                  <a href="#dashboard_map">{this.state.crime[4]}</a>
                </div>

                <div
                  className="highbox"
                  onClick={e => {
                    this.setLocationData(4, 'fire')
                  }}>
                  <a href="#dashboard_map">{this.state.fire[4]}</a>
                </div>

                {/* {this.state.display_food_hygiene?(
                   <div
                   className="highbox"
                   onClick={(e) => {
                     this.setLocationData(4, "food_hygiene");
                   }}
                 >
                   <a href="#dashboard_map">{this.state.food_hygiene[3]+this.state.food_hygiene[4]}</a>
                 </div>
                ):("")} */}

                <h6 className="rowheading">Medium</h6>
                <div
                  className="mediumbox"
                  onClick={e => {
                    this.setLocationData(3, 'rofrs')
                  }}>
                  <a href="#dashboard_map">{this.state.rofrs[3]}</a>
                </div>

                <div
                  className="mediumbox"
                  onClick={e => {
                    this.setLocationData(3, 'rofsw')
                  }}>
                  <a href="#dashboard_map">{this.state.rofsw[3]}</a>
                </div>

                <div
                  className="mediumbox"
                  onClick={e => {
                    this.setLocationData(3, 'crime')
                  }}>
                  <a href="#dashboard_map">{this.state.crime[3]}</a>
                </div>

                <div
                  className="mediumbox"
                  onClick={e => {
                    this.setLocationData(3, 'fire')
                  }}>
                  <a href="#dashboard_map">{this.state.fire[3]}</a>
                </div>

                {/* {this.state.display_food_hygiene?(
                   <div
                   className="mediumbox"
                   onClick={(e) => {
                     this.setLocationData(3, "food_hygiene");
                   }}
                 >
                   <a href="#dashboard_map">{this.state.food_hygiene[2]}</a>
                 </div>
                ):("")} */}

                <h6 className="rowheading">Low</h6>
                <div
                  className="lowbox"
                  onClick={e => {
                    this.setLocationData(1, 'rofrs')
                  }}>
                  <a href="#dashboard_map">{this.state.rofrs[2] + this.state.rofrs[1]}</a>
                </div>

                <div
                  className="lowbox"
                  onClick={e => {
                    this.setLocationData(1, 'rofsw')
                  }}>
                  <a href="#dashboard_map">{this.state.rofsw[2] + this.state.rofsw[1]}</a>
                </div>

                <div
                  className="lowbox"
                  onClick={e => {
                    this.setLocationData(1, 'crime')
                  }}>
                  <a href="#dashboard_map">{this.state.crime[2] + this.state.crime[0]} </a>
                </div>

                <div
                  className="lowbox"
                  onClick={e => {
                    this.setLocationData(1, 'fire')
                  }}>
                  {this.state.fire[2] + this.state.fire[0]}
                </div>

                {/* {this.state.display_food_hygiene?(
                  <div
                  className="lowbox"
                  onClick={(e) => {
                    this.setLocationData(1, "food_hygiene");
                  }}
                >
                  {this.state.food_hygiene[1]}
                </div>
                ):("")} */}

                <h6 className="rowheading">Negligible</h6>
                <div
                  className="negligiblebox"
                  onClick={e => {
                    this.setLocationData(0, 'rofrs')
                  }}>
                  <a
                  // href="#dashboard_map"
                  >
                    {this.state.rofrs[0]}
                  </a>
                </div>
                <div
                  className="negligiblebox"
                  onClick={e => {
                    this.setLocationData(0, 'rofsw')
                  }}>
                  <a href="#dashboard_map">{this.state.rofsw[0]}</a>
                </div>
                <div className="negligiblebox">
                  <a href="#dashboard_map">0</a>
                </div>
                <div className="negligiblebox">
                  <a href="#dashboard_map">0</a>
                </div>

                {/* {this.state.display_food_hygiene?(
                 <div
                 className="negligiblebox"
                 onClick={(e) => {
                   this.setLocationData(0, "food_hygiene");
                 }}
               >
                 <a href="#dashboard_map">{this.state.food_hygiene[0]}</a>
               </div>
                ):("")} */}
              </div>

              {this.state.loadedMapLocationData === false ? 'Loading All Properties...' : ''}
            </div>

            <Card className="current-threats-card">
              <Card.Header className="d-flex justify-content-between align-items-center">
                <h4>Current Threats</h4>
                <div>
                  {this.state.csvLocations ? (
                    <DownloadButton
                      data={this.state.csvLocations}
                      filename={this.state.csvName}
                      headers={headers}
                      size="small">
                      Download Ready
                    </DownloadButton>
                  ) : (
                    <Button onClick={() => this.loadCSV()} size="small" light>
                      Download Alerts
                    </Button>
                  )}
                </div>
              </Card.Header>
              <div className="current-threats-card-content">
                <ControlledTabs
                  currentStep={this.state.currentStep}
                  handleChange={this.handleChange}
                  locations={this.state.alertlocations}
                  alerts={this.state.alerts}
                  user={this.state.currentUser}
                  redirect={this.handleRedirect}
                  selectedAlert={this.setAlertSeverity}
                  customstyle={this.props.userData.data.customstyle}
                />
              </div>
            </Card>

            <ReactTooltip id="rofrstip" delayShow={500} color="white" place="right" aria-haspopup="true" role="example">
              <p>Flood Risk Definition</p>
              <ul>
                <li>High : each year, this area has a chance of flooding of greater than 1 in 30 (3.3%)</li>
                <li>
                  Medium : each year, this area has a chance of flooding of between 1 in 100 (1%) and 1 in 30 (3.3%)
                </li>
                <li>Low : each year, this area has a chance of flooding of between 1 in 1000 (0.1%) 1 in 100 (1%)</li>
                <li>Negligible : each year, this area has negligible chance of flooding</li>
              </ul>
            </ReactTooltip>
            <ReactTooltip id="rofswtip" delayShow={500} place="right" aria-haspopup="true" role="example">
              <p>Flood Risk Definition</p>
              <ul>
                <li>High : each year, this area has a chance of flooding of greater than 1 in 30 (3.3%)</li>
                <li>
                  Medium : each year, this area has a chance of flooding of between 1 in 100 (1%) and 1 in 30 (3.3%)
                </li>
                <li>Low : each year, this area has a chance of flooding of between 1 in 1000 (0.1%) 1 in 100 (1%)</li>
                <li>Negligible : each year, this area has negligible chance of flooding</li>
              </ul>
            </ReactTooltip>
            <ReactTooltip id="crimetip" delayShow={500} place="right" aria-haspopup="true" role="example">
              <p>Crime Risk Definition</p>
              <p>Calculated based on frequency of specific crime incidents nearby each property</p>
            </ReactTooltip>
            <ReactTooltip id="firetip" delayShow={500} place="right" aria-haspopup="true" role="example">
              <p>Flood Risk Definition</p>
              <p>
                Calculated based on the assessed time taken for Fire Service resources to physically respond to a
                primary fire at the named location
              </p>
            </ReactTooltip>
          </div>
          <div id="dashboard_map_main" className="App">
            <h3 id="dashboard_map" className="dashboard__map_title">
              Property Map
            </h3>
            {this.displayMap()}
          </div>
        </div>
      </React.Fragment>
    )
  }
}
function mapStateToProps(state) {
  const { userLogged, user, userData, systemData, mapAlertData, mapLocationData, dashboardData } = state

  return {
    userLogged,
    user,
    userData,
    systemData,
    mapAlertData,
    mapLocationData,
    dashboardData,
  }
}
export default connect(mapStateToProps)(PortfolioLandingPage)
