import React from "react";
import "./HistoricalAlertPagination.scss";

const HistoricalAlertPagination = (props) => {
  const current = props.index;
  const min =
    props.maxPages < current + 9
      ? Math.max(0, props.maxPages - 17)
      : Math.max(0, current - 9);
  const max = Math.min(min + 17, props.maxPages);
  const range = max - min + 1;
  const indices = Array.from({ length: range }, (_, i) => min + i);

  const back = () => {
    props.setIndex(Math.max(0, props.index - 1));
  };

  const next = () => {
    props.setIndex(Math.min(props.index + 1, props.maxPages));
  };

  return (
    <div className="pagination">
      <button onClick={back} className="pagination__button">
        Back
      </button>
      <div className="indices">
        {indices.map((i) => (
          <button
            key={i}
            onClick={() => props.setIndex(i)}
            className={
              "indices__index " +
              (i === props.index ? "indices__index--selected" : "")
            }
          >
            {i + 1}
          </button>
        ))}
      </div>
      <button onClick={next} className="pagination__button">
        Next
      </button>
    </div>
  );
};

export default HistoricalAlertPagination;
