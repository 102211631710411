import React from "react";
import PropTypes from "prop-types";
import c from "./IconButton.module.scss";

const icons = {
  "arrow-down": require("../../images/card_icons/card-arrow-down.png"),
  "trash": require("../../images/card_icons/trash-icon.png"),
  "external": require("../../images/card_icons/card-external-site.png"),
};

const IconButton = ({icon, onClick}) => (
  <div className={c.button} onClick={onClick}>
    <img src={icons[icon]} />
  </div>
);

IconButton.propTypes = {
  icon: PropTypes.oneOf(Object.keys(icons)).isRequired,
  onClick: PropTypes.func.isRequired,
};



export default IconButton;
