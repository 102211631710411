import COMMON_RENDERS from "./permissions_common_portfolio_type.js";
import PORTFOLIO_TYPE_PAGES from "./permissions_portfolio_type.json";

export const allowRender = (page, portfolio_type) => {
  if (COMMON_RENDERS.includes(page)) {
    return true;
  }

  if (portfolio_type) {
    let allowed_pages = null;
    if (PORTFOLIO_TYPE_PAGES[portfolio_type]) {
      allowed_pages = PORTFOLIO_TYPE_PAGES[portfolio_type];
    } else {
      return false;
    }

    if (allowed_pages) {
      if (allowed_pages.includes(page)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export default allowRender;
