/**
 * URLs for common resources (e.g. icons).
 */

import * as urls from "./urls";

/**
 * Severity level -> icon mapping for flood alerts.
 */
export const floodIcons = {
  1: "static/img/severeFloodWarning.png",
  2: "static/img/floodWarning.png",
  3: "static/img/floodAlert.png",
};

export const propertyRiskTableIcons = {
  1: "static/img/risk_table_high.png",
  2: "static/img/risk_table_low.png",
  3: "static/img/risk_table_medium.png",
};

export const news_icons = {
  1: "static/img/newsAlertAlt.png",
  2: "static/img/crime_news.png",
  3: "static/img/fire.png",
};

/**
 * Various icons for map markers.
 */
export const markerIcons = {
  church: [urls.BACKEND, "static/img/church.svg"].join("/"),
  scrapyard: [urls.BACKEND, "static/img/car-repair.svg"].join("/"),
  travellerSite: [urls.BACKEND, "static/img/rv-park.svg"].join("/"),
  crime: [urls.BACKEND, "static/img/crosshairs.svg"].join("/"),
};
