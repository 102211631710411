import React from "react";
import ModalImage from "react-modal-image";
import moment from "moment";
import c from "./Incident.module.scss";
import makeURL from "balkerne-fn/makeURL";
import TimePassed from "../general/TimePassed";
import IconButton from "../general/IconButton";
import FileIcon from "balkerne-components/FileIcon";
import { LabeledTextLine } from "../general/LabeledText";
import * as urls from "../maps/urls";
import Can from "balkerne-components/Can";

const Incident = (props) => {
  const showIncidentImages = () => {
    console.log(props);
    //NEW format if .url present
    if (
      props.url &&
      props.url !== "None" &&
      props.attachments &&
      props.attachments !== ["None"] &&
      props.attachments.length > 0
    ) {
      return (
        <div>
          <div className={c.images}>
            {props.attachments.map((attachment) => (
              <ImageViewer path={props.url} filename={attachment} />
            ))}
          </div>
        </div>
      );
    }
    //OLD format if .url missing
    else if (
      props.attachments &&
      props.attachments !== ["None"] &&
      props.attachments.length > 0
    ) {
      return (
        <div>
          <div className={c.images}>
            {props.attachments.map((attachment) => (
              <ImageViewer path={""} filename={attachment} />
            ))}
          </div>
        </div>
      );
    }
  };

  const deleteIncident = () => {
    if (window.confirm("Are you sure you wish to delete this incident?")) {
      props.deleteIncident(props.lossincident_id);
    }
  };

  const date = moment(props.textincidentdate).format("DD/MM/YYYY hh:mm");
  const re = /^(?:https?:\/\/)?(?:www\.)?([^:\/?\n]+)/;
  const domain = re.test(props.source) ? props.source.match(re)[1] : "";

  return (
    <div className={c.incident}>
      <div className={c.top}>
        <div className={c.left}>
          <LabeledTextLine label="Incident type">
            {props.textincidentname}
          </LabeledTextLine>
          <LabeledTextLine label="Date">{date}</LabeledTextLine>
          <LabeledTextLine label="Source">
            {domain}
            <IconButton
              icon="external"
              onClick={() => window.open(props.source, "_blank")}
            />
          </LabeledTextLine>
        </div>
        <div className={c.right}>
          <span>{<TimePassed date={props.textincidentdate} />}</span>
          {/* <Can permissions={props.user_permissions} actions='locations:add' yes={() => <Dropdown.Item onClick={() => history.push("/MainAddLocation")}>Add New Property</Dropdown.Item>} /> */}

          <Can
            permissions={props.user_permissions}
            actions={[
              "loss-incidents:delete",
              "loss-incidents:delete-all",
              "loss-incidents:delete-any",
            ]}
            yes={() => <IconButton icon="trash" onClick={deleteIncident} />}
          />
        </div>
      </div>
      <span className={c.description}>
        <hr />
        {props.textdescription}
      </span>
      {showIncidentImages()}
    </div>
  );
};

const ImageViewer = ({ path, filename }) => {
  console.log(path);
  console.log(filename);
  const imageUrl = makeURL([urls.LOSS_INCIDENTS, path, filename]);
  console.log(imageUrl);
  if (filename !== "None") {
    return (
      <div>
        <ModalImage
          className={c.img}
          small={imageUrl}
          large={imageUrl}
          showRotate={true}
        />
      </div>
    );
  } else {
    return "";
  }
};

export default Incident;
