/**
 * Application configuration.
 *
 * This file controls the active features of the application, e.g., enabling common layers/filters
 * or specifying custom layers/filters.
 *
 * Exports `activeLayers` and `activeFilters`, which is used by the application
 * to generate the appropriate layers and filters.
 * 
 
 */

import React from "react";
import { OptionIcon } from "./Options";
import * as urls from "./urls";
import * as layers from "./layers";
import * as filters from "./filters";
import * as styles from "./styles";

const alert_location_severe_style = {
  zIndex: 14,
  strokeColor: "#8B0000",
  strokeOpacity: 1,
  strokeWeight: 9,
  fillColor: "#8B0000",
  fillOpacity: 0.7,
};

const alert_location_alert_style = {
  zIndex: 12,
  strokeColor: "#ff6500",
  strokeOpacity: 0.8,
  strokeWeight: 7,
  fillColor: "#ff6500",
  fillOpacity: 0.4,
};

const alert_location_warning_style = {
  zIndex: 13,
  strokeColor: "#ff0000",
  strokeOpacity: 0.9,
  strokeWeight: 8,
  fillColor: "#ff0000",
  fillOpacity: 0.6,
};

const propertyStyle_high = {
  zIndex: 10,
  strokeColor: "#E52922",
  strokeOpacity: 0.8,
  strokeWeight: 4,
  fillColor: "#E52922",
  fillOpacity: 0.4,
};

const propertyStyle_medium = {
  zIndex: 10,
  strokeColor: "#ff9900",
  strokeOpacity: 0.8,
  strokeWeight: 4,
  fillColor: "#ff9900",
  fillOpacity: 0.4,
};

const propertyStyle_low = {
  zIndex: 10,
  strokeColor: "#00cc00",
  strokeOpacity: 0.8,
  strokeWeight: 4,
  fillColor: "#00cc00",
  fillOpacity: 0.4,
};

const propertyStyle_high_point = {
  // icon: resources.floodIcons[0]
  zIndex: 10,
  strokeColor: "#E52922",
  strokeOpacity: 0.8,
  strokeWeight: 4,
  fillColor: "#E52922",
  fillOpacity: 0.4,
  // icon: icon
};

const propertyStyle_medium_point = {
  zIndex: 10,
  strokeColor: "#ff9900",
  strokeOpacity: 0.8,
  strokeWeight: 4,
  fillColor: "#ff9900",
  fillOpacity: 0.4,
};

const propertyStyle_low_point = {
  zIndex: 10,
  strokeColor: "#00cc00",
  strokeOpacity: 0.8,
  strokeWeight: 4,
  fillColor: "#00cc00",
  fillOpacity: 0.4,
};

const genGenericInfo = function (feature) {
  console.log("Ding Dong Info Bong");
  console.log(feature);
  let id = feature.getProperty("property_customer_reference") || "Unknown";
  let name = feature.getProperty("name");
  const address =
    feature.getProperty("address_street") +
    " " +
    feature.getProperty("address_postcode");
  const propClass = feature.getProperty("property_class");

  const container = document.createElement("div");
  container.classList.add("info_window");

  const heading = document.createElement("h1");
  heading.classList.add("info_window__heading");

  if (!id) {
    id = "Unknown";
  }

  if (!name) {
    name = "Unknown";
  }

  heading.textContent = name;
  container.appendChild(heading);

  const denomination = document.createElement("h3");
  denomination.classList.add("info_window__subheading");

  const denomination2 = document.createElement("h4");
  denomination2.classList.add("info_window__subheading2");

  container.appendChild(document.createElement("br"));

  if (address) {
    denomination.textContent =
      address.charAt(0).toUpperCase() + address.slice(1);
    container.appendChild(denomination);
  }

  if (propClass) {
    denomination2.textContent =
      propClass.charAt(0).toUpperCase() + propClass.slice(1);
    container.appendChild(denomination2);
  }

  const button = document.createElement("button");
  button.classList.add("dashboardButton");
  button.textContent = "View Dashboard";
  button.addEventListener("click", () => {
    this.openDashboard(feature);
  });
  container.appendChild(button);

  return container;
};

const genericOnClick = function (event) {
  console.log("Generic do click");
  let feature = event.feature;

  // Display info window for the property.
  if (this.state.infoWindow) this.state.infoWindow.close();
  let infoWindow = new window.google.maps.InfoWindow({
    content: genGenericInfo.bind(this)(feature),
    position: {
      lat: feature.getProperty("lat"),
      lng: feature.getProperty("lon"),
    },
  });

  infoWindow.open(this.state.map);
  this.setState({ infoWindow });
};

export const activeFiltersOperation = {
  floodAlertsSevere: filters.floodAlertsSevere,
  floodAlertsWarning: filters.floodAlertsWarning,
  floodAlertsAlert: filters.floodAlertsAlert,
};

/**
 * Layers to be available within the application.
 */
export const activeLayersOperation = {
  // FIXME: This seemingly useless step of converting the object to JSON
  // string and then parsing it again is a hacky way of cloning the object
  // (so that the floodRisk layer in the main app and in the dashboard are
  // separate and do not point to the same object). This method does _NOT_
  // work on data layers because JSON cannot represent the onChange functions
  // that are stored in the layer object. FIND ANOTHER WAY TO CLONE OBJECTS
  // RELIABLY.
  floodRisk_rofrs: JSON.parse(JSON.stringify(layers.floodRisk_rofrs)),
  floodRisk_rofsw: JSON.parse(JSON.stringify(layers.floodRisk_rofsw)),
  historicalFlooding: JSON.parse(JSON.stringify(layers.historicalFlooding)),
  floodAlerts: layers.floodAlertsOperations,
  floodAlertsPolygons: JSON.parse(JSON.stringify(layers.floodAlertsPolygons)),
  news_map_layer: layers.news_map_layer,
  alert_properties_severe: {
    name: "alert_properties_severe",
    icon: <OptionIcon box="" />,
    type: "alert-locations",
    visible: false,
    layer: null,
    toggleable: false,
    onClick: genericOnClick,
    style: alert_location_severe_style,
  },
  alert_properties_warning: {
    name: "alert_properties_warning",
    icon: <OptionIcon box="" />,
    type: "alert-locations",
    visible: false,
    layer: null,
    toggleable: false,
    onClick: genericOnClick,
    style: alert_location_warning_style,
  },
  alert_properties_alert: {
    name: "alert_properties_alert",
    icon: <OptionIcon box="" />,
    type: "alert-locations",
    visible: false,
    layer: null,
    toggleable: false,
    onClick: genericOnClick,
    style: alert_location_alert_style,
  },
};

/**
 * Filters to be available within the application.
 */
export const activeFilters = {
  floodRiskHigh: filters.floodRiskHigh,
  floodRiskMed: filters.floodRiskMed,
  floodRiskLow: filters.floodRiskLow,
  floodRiskVlow: filters.floodRiskVlow,
  floodRiskNegligible: filters.floodRiskNegligible,
  hasFlooded: filters.hasFlooded,
  // hasNotFlooded: filters.hasNotFlooded,
  floodAlertsSevere: filters.floodAlertsSevere,
  floodAlertsWarning: filters.floodAlertsWarning,
  floodAlertsAlert: filters.floodAlertsAlert,

  // pr: filters.floodAlertsSevere
  // propertiesRofrsHigh: filters.propertiesRofrsHigh,
  // propertiesRofrsMedium: filters.propertiesRofrsMedium,
  // propertiesRofrsLow: filters.propertiesRofrsLow,
  // propertiesRofswHigh: filters.propertiesRofswHigh,
  // propertiesRofswMedium: filters.propertiesRofswMedium,
  // propertiesRofswLow: filters.propertiesRofswLow
};

/**
 * Layers to be available within the application.
 */
export const activeLayers = {
  // FIXME: This seemingly useless step of converting the object to JSON
  // string and then parsing it again is a hacky way of cloning the object
  // (so that the floodRisk layer in the main app and in the dashboard are
  // separate and do not point to the same object). This method does _NOT_
  // work on data layers because JSON cannot represent the onChange functions
  // that are stored in the layer object. FIND ANOTHER WAY TO CLONE OBJECTS
  // RELIABLY.
  floodRisk_rofrs: JSON.parse(JSON.stringify(layers.floodRisk_rofrs)),
  floodRisk_rofsw: JSON.parse(JSON.stringify(layers.floodRisk_rofsw)),
  historicalFlooding: JSON.parse(JSON.stringify(layers.historicalFlooding)),
  floodAlerts: layers.floodAlerts,
  floodAlertsPolygons: JSON.parse(JSON.stringify(layers.floodAlertsPolygons)),
  alert_properties_severe: {
    name: "alert_properties_severe",
    icon: <OptionIcon box="" />,
    type: "alert-locations",
    visible: false,
    layer: null,
    toggleable: false,
    onClick: genericOnClick,
    style: alert_location_severe_style,
  },
  alert_properties_warning: {
    name: "alert_properties_warning",
    icon: <OptionIcon box="" />,
    type: "alert-locations",
    visible: false,
    layer: null,
    toggleable: false,
    onClick: genericOnClick,
    style: alert_location_warning_style,
  },
  alert_properties_alert: {
    name: "alert_properties_alert",
    icon: <OptionIcon box="" />,
    type: "alert-locations",
    visible: false,
    layer: null,
    toggleable: false,
    onClick: genericOnClick,
    style: alert_location_alert_style,
  },
  properties_rofrs_high: {
    name: "properties_rofrs_high",
    icon: <OptionIcon box="" />,
    url: [urls.BACKEND, "locations", "property-by-risk?risk=4&type=rofrs"].join(
      "/"
    ),
    type: "property_risk",
    visible: false,
    layer: null,
    toggleable: false,
    onClick: genericOnClick,
    style: propertyStyle_high,
    styleRofrs: styles.riskLocationDataRofrs,
  },
  properties_rofrs_medium: {
    name: "properties_rofrs_medium",
    icon: <OptionIcon box="#E52922" />,
    url: [urls.BACKEND, "locations", "property-by-risk?risk=3&type=rofrs"].join(
      "/"
    ),
    type: "property_risk",
    visible: false,
    layer: null,
    toggleable: false,
    onClick: genericOnClick,
    style: propertyStyle_medium,
    styleRofrs: styles.riskLocationDataRofrs,
  },
  properties_rofrs_low: {
    name: "properties_rofrs_low",
    icon: <OptionIcon box="#E52922" />,
    url: [urls.BACKEND, "locations", "property-by-risk?risk=1&type=rofrs"].join(
      "/"
    ),
    type: "property_risk",
    visible: false,
    layer: null,
    toggleable: false,
    onClick: genericOnClick,
    style: propertyStyle_low,
    styleRofrs: styles.riskLocationDataRofrs,
  },
  properties_rofsw_high: {
    name: "properties_rofsw_high",
    icon: <OptionIcon box="#E52922" />,
    url: [urls.BACKEND, "locations", "property-by-risk?risk=4&type=rofsw"].join(
      "/"
    ),
    type: "property_risk",
    visible: false,
    layer: null,
    toggleable: false,
    onClick: genericOnClick,
    style: propertyStyle_high,
    styleRofsw: styles.riskLocationDataRofsw,
  },
  properties_rofsw_medium: {
    name: "properties_rofsw_medium",
    icon: <OptionIcon box="#E52922" />,
    url: [urls.BACKEND, "locations", "property-by-risk?risk=3&type=rofsw"].join(
      "/"
    ),
    type: "property_risk",
    visible: false,
    layer: null,
    toggleable: false,
    onClick: genericOnClick,
    style: propertyStyle_medium,
    styleRofsw: styles.riskLocationDataRofsw,
  },
  properties_rofsw_low: {
    name: "properties_rofsw_low",
    icon: <OptionIcon box="#E52922" />,
    url: [urls.BACKEND, "locations", "property-by-risk?risk=1&type=rofsw"].join(
      "/"
    ),
    type: "property_risk",
    visible: false,
    layer: null,
    toggleable: false,
    onClick: genericOnClick,
    style: propertyStyle_low,
    styleRofsw: styles.riskLocationDataRofsw,
  },

  properties_crime_high: {
    name: "properties_crime_high",
    icon: <OptionIcon box="#E52922" />,
    url: [urls.BACKEND, "locations", "property-by-risk?risk=4&type=crime"].join(
      "/"
    ),
    type: "property_risk",
    visible: false,
    layer: null,
    toggleable: false,
    onClick: genericOnClick,
    style: propertyStyle_high,
    styleCrime: styles.riskLocationDataCrime,
  },
  properties_crime_medium: {
    name: "properties_crime_medium",
    icon: <OptionIcon box="#E52922" />,
    url: [urls.BACKEND, "locations", "property-by-risk?risk=3&type=crime"].join(
      "/"
    ),
    type: "property_risk",
    visible: false,
    layer: null,
    toggleable: false,
    onClick: genericOnClick,
    style: propertyStyle_medium,
    styleCrime: styles.riskLocationDataCrime,
  },
  properties_crime_low: {
    name: "properties_crime_low",
    icon: <OptionIcon box="#E52922" />,
    url: [urls.BACKEND, "locations", "property-by-risk?risk=1&type=rofsw"].join(
      "/"
    ),
    type: "property_risk",
    visible: false,
    layer: null,
    toggleable: false,
    onClick: genericOnClick,
    style: propertyStyle_low,
    styleCrime: styles.riskLocationDataCrime,
  },

  properties_fire_high: {
    name: "properties_fire_high",
    icon: <OptionIcon box="#E52922" />,
    url: [urls.BACKEND, "locations", "property-by-risk?risk=4&type=fire"].join(
      "/"
    ),
    type: "property_risk",
    visible: false,
    layer: null,
    toggleable: false,
    onClick: genericOnClick,
    style: propertyStyle_high,
    styleFire: styles.riskLocationDataFire,
  },
  properties_fire_medium: {
    name: "properties_fire_medium",
    icon: <OptionIcon box="#E52922" />,
    url: [urls.BACKEND, "locations", "property-by-risk?risk=3&type=fire"].join(
      "/"
    ),
    type: "property_risk",
    visible: false,
    layer: null,
    toggleable: false,
    onClick: genericOnClick,
    style: propertyStyle_medium,
    styleFire: styles.riskLocationDataFire,
  },
  properties_fire_low: {
    name: "properties_fire_low",
    icon: <OptionIcon box="#E52922" />,
    url: [urls.BACKEND, "locations", "property-by-risk?risk=1&type=fire"].join(
      "/"
    ),
    type: "property_risk",
    visible: false,
    layer: null,
    toggleable: false,
    onClick: genericOnClick,
    style: propertyStyle_low,
    styleFire: styles.riskLocationDataFire,
  },

  properties_food_hygiene_high: {
    name: "properties_food_hygiene_high",
    icon: <OptionIcon box="#E52922" />,
    url: [
      urls.BACKEND,
      "locations",
      "property-by-risk?risk=4&type=food_hygiene",
    ].join("/"),
    type: "property_risk",
    visible: false,
    layer: null,
    toggleable: false,
    onClick: genericOnClick,
    style: propertyStyle_high,
    styleFoodHygiene: styles.riskLocationDataFoodHygiene,
  },
  properties_food_hygiene_medium: {
    name: "properties_food_hygiene_medium",
    icon: <OptionIcon box="#E52922" />,
    url: [
      urls.BACKEND,
      "locations",
      "property-by-risk?risk=3&type=food_hygiene",
    ].join("/"),
    type: "property_risk",
    visible: false,
    layer: null,
    toggleable: false,
    onClick: genericOnClick,
    style: propertyStyle_medium,
    styleFoodHygiene: styles.riskLocationDataFoodHygiene,
  },
  properties_food_hygiene_low: {
    name: "properties_food_hygiene_low",
    icon: <OptionIcon box="#E52922" />,
    url: [
      urls.BACKEND,
      "locations",
      "property-by-risk?risk=1&type=food_hygiene",
    ].join("/"),
    type: "property_risk",
    visible: false,
    layer: null,
    toggleable: false,
    onClick: genericOnClick,
    style: propertyStyle_low,
    styleFoodHygiene: styles.riskLocationDataFoodHygiene,
  },

  properties_pandemic_high: {
    name: "properties_pandemic_high",
    icon: <OptionIcon box="#E52922" />,
    url: [
      urls.BACKEND,
      "locations",
      "property-by-risk?risk=4&type=pandemic",
    ].join("/"),
    type: "property_risk",
    visible: false,
    layer: null,
    toggleable: false,
    onClick: genericOnClick,
    style: propertyStyle_high,
    stylePandemic: styles.riskLocationDataPandemic,
  },
  properties_pandemic_medium: {
    name: "properties_pandemic_medium",
    icon: <OptionIcon box="#E52922" />,
    url: [
      urls.BACKEND,
      "locations",
      "property-by-risk?risk=3&type=pandemic",
    ].join("/"),
    type: "property_risk",
    visible: false,
    layer: null,
    toggleable: false,
    onClick: genericOnClick,
    style: propertyStyle_medium,
    stylePandemic: styles.riskLocationDataPandemic,
  },
  properties_pandemic_low: {
    name: "properties_pandemic_low",
    icon: <OptionIcon box="#E52922" />,
    url: [
      urls.BACKEND,
      "locations",
      "property-by-risk?risk=1&type=pandemic",
    ].join("/"),
    type: "property_risk",
    visible: false,
    layer: null,
    toggleable: false,
    onClick: genericOnClick,
    style: propertyStyle_low,
    stylePandemic: styles.riskLocationDataPandemic,
  },

  // properties: {
  //   name: "Properties",
  //   icon: <OptionIcon box="#E52922" />,
  //   url: [urls.BACKEND, "locations", "property-by-risk"].join("/"),
  //   type: "data",
  //   visible: false,
  //   layer: null,
  //   filterable:true,
  //   onClick: genericOnClick,
  //   style: propertyStyle
  // }
};

export const activeLayersHistorical = {
  // FIXME: This seemingly useless step of converting the object to JSON
  // string and then parsing it again is a hacky way of cloning the object
  // (so that the floodRisk layer in the main app and in the dashboard are
  // separate and do not point to the same object). This method does _NOT_
  // work on data layers because JSON cannot represent the onChange functions
  // that are stored in the layer object. FIND ANOTHER WAY TO CLONE OBJECTS
  // RELIABLY.
  floodRisk_rofrs: JSON.parse(JSON.stringify(layers.floodRisk_rofrs)),
  floodRisk_rofsw: JSON.parse(JSON.stringify(layers.floodRisk_rofsw)),
  historicalFlooding: JSON.parse(JSON.stringify(layers.historicalFlooding)),
  floodAlerts: layers.floodAlertsHistorical,

  properties_rofrs_high: {
    name: "properties_rofrs_high",
    icon: <OptionIcon box="" />,
    url: [urls.BACKEND, "locations", "property-by-risk?risk=4&type=rofrs"].join(
      "/"
    ),
    type: "property_risk",
    visible: false,
    layer: null,
    toggleable: false,
    onClick: genericOnClick,
    style: propertyStyle_high,
  },
  properties_rofrs_medium: {
    name: "properties_rofrs_medium",
    icon: <OptionIcon box="#E52922" />,
    url: [urls.BACKEND, "locations", "property-by-risk?risk=3&type=rofrs"].join(
      "/"
    ),
    type: "property_risk",
    visible: false,
    layer: null,
    toggleable: false,
    onClick: genericOnClick,
    style: propertyStyle_medium,
  },
  properties_rofrs_low: {
    name: "properties_rofrs_low",
    icon: <OptionIcon box="#E52922" />,
    url: [urls.BACKEND, "locations", "property-by-risk?risk=1&type=rofrs"].join(
      "/"
    ),
    type: "property_risk",
    visible: false,
    layer: null,
    toggleable: false,
    onClick: genericOnClick,
    style: propertyStyle_low,
  },
  properties_rofsw_high: {
    name: "properties_rofsw_high",
    icon: <OptionIcon box="#E52922" />,
    url: [urls.BACKEND, "locations", "property-by-risk?risk=4&type=rofsw"].join(
      "/"
    ),
    type: "property_risk",
    visible: false,
    layer: null,
    toggleable: false,
    onClick: genericOnClick,
    style: propertyStyle_high,
  },
  properties_rofsw_medium: {
    name: "properties_rofsw_medium",
    icon: <OptionIcon box="#E52922" />,
    url: [urls.BACKEND, "locations", "property-by-risk?risk=3&type=rofsw"].join(
      "/"
    ),
    type: "property_risk",
    visible: false,
    layer: null,
    toggleable: false,
    onClick: genericOnClick,
    style: propertyStyle_medium,
  },
  properties_rofsw_low: {
    name: "properties_rofsw_low",
    icon: <OptionIcon box="#E52922" />,
    url: [urls.BACKEND, "locations", "property-by-risk?risk=1&type=rofsw"].join(
      "/"
    ),
    type: "property_risk",
    visible: false,
    layer: null,
    toggleable: false,
    onClick: genericOnClick,
    style: propertyStyle_low,
  },

  properties_crime_high: {
    name: "properties_crime_high",
    icon: <OptionIcon box="#E52922" />,
    url: [urls.BACKEND, "locations", "property-by-risk?risk=4&type=crime"].join(
      "/"
    ),
    type: "property_risk",
    visible: false,
    layer: null,
    toggleable: false,
    onClick: genericOnClick,
    style: propertyStyle_high,
  },
  properties_crime_medium: {
    name: "properties_crime_medium",
    icon: <OptionIcon box="#E52922" />,
    url: [urls.BACKEND, "locations", "property-by-risk?risk=3&type=crime"].join(
      "/"
    ),
    type: "property_risk",
    visible: false,
    layer: null,
    toggleable: false,
    onClick: genericOnClick,
    style: propertyStyle_medium,
  },
  properties_crime_low: {
    name: "properties_crime_low",
    icon: <OptionIcon box="#E52922" />,
    url: [urls.BACKEND, "locations", "property-by-risk?risk=1&type=rofsw"].join(
      "/"
    ),
    type: "property_risk",
    visible: false,
    layer: null,
    toggleable: false,
    onClick: genericOnClick,
    style: propertyStyle_low,
  },

  properties_fire_high: {
    name: "properties_fire_high",
    icon: <OptionIcon box="#E52922" />,
    url: [urls.BACKEND, "locations", "property-by-risk?risk=4&type=fire"].join(
      "/"
    ),
    type: "property_risk",
    visible: false,
    layer: null,
    toggleable: false,
    onClick: genericOnClick,
    style: propertyStyle_high,
  },
  properties_fire_medium: {
    name: "properties_fire_medium",
    icon: <OptionIcon box="#E52922" />,
    url: [urls.BACKEND, "locations", "property-by-risk?risk=3&type=fire"].join(
      "/"
    ),
    type: "property_risk",
    visible: false,
    layer: null,
    toggleable: false,
    onClick: genericOnClick,
    style: propertyStyle_medium,
  },
  properties_fire_low: {
    name: "properties_fire_low",
    icon: <OptionIcon box="#E52922" />,
    url: [urls.BACKEND, "locations", "property-by-risk?risk=1&type=fire"].join(
      "/"
    ),
    type: "property_risk",
    visible: false,
    layer: null,
    toggleable: false,
    onClick: genericOnClick,
    style: propertyStyle_low,
  },
};

export const churchLayers = {};

export const activeDashboardLayers = {
  floodRiskRofrsDashboard: JSON.parse(JSON.stringify(layers.floodRisk_rofrs)),
  floodRiskRofswDashboard: JSON.parse(JSON.stringify(layers.floodRisk_rofsw)),
  floodAlerts: layers.floodAlertsProperty,
  historicalFloodingDashboard: JSON.parse(
    JSON.stringify(layers.historicalFlooding)
  ),
};
// export const activeDashboardLayersHistorical = {
//   floodRiskRofrsDashboard: JSON.parse(JSON.stringify(layers.floodRisk_rofrs)),
//   floodRiskRofswDashboard: JSON.parse(JSON.stringify(layers.floodRisk_rofsw)),
//   floodAlerts: layers.floodAlertsPropertyHistorical,
//   historicalFloodingDashboard: JSON.parse(
//     JSON.stringify(layers.historicalFlooding)
//   )
// };

//floodRiskRofswDashboard: JSON.parse(JSON.stringify(layers.floodRisk_rofsw)),
//crimeDashboard: layers.crime

export const rofrsMapLayers = {
  floodRiskRofrsDashboard: JSON.parse(JSON.stringify(layers.floodRisk_rofrs)),
};

export const rofswMapLayers = {
  floodRiskRofswDashboard: JSON.parse(JSON.stringify(layers.floodRisk_rofsw)),
};
