import React from "react";
import UserInputs from "./UserInputs";
import Axios from "axios";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Redirect } from "react-router";

import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";

import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import c from "../../pages/GetStarted.module.scss";

import "./LastPageGetStarted.css";
//
export class LastPageGetStarted extends React.Component {
  constructor(props) {
    super(props);
    //setting initial user row
    this.state = {};
  }

  render() {
    if (this.props.currentStep !== 4) {
      return null;
    }

    if (this.props.databaseSuccess !== true) {
      return (
        <div className={c.page}>
          <Container>
            <Card>
              <Card.Header>Error</Card.Header>
              <Card.Body>
                <div>
                  <p>
                    There was a problem with the application process, please try
                    again.
                  </p>
                </div>
              </Card.Body>
            </Card>
          </Container>
        </div>
      );
    }

    return (
      <div className={c.page}>
        <Container>
          <Card>
            <Card.Header>Success</Card.Header>
            <Card.Body>
              <div className="submissionProperty">
                <span
                  id="submittedBulkProperty"
                  class="glyphicon glyphicon-ok-sign"
                />

                <p>
                  Thank you for your application, please check your email for
                  your login details.
                </p>
              </div>
            </Card.Body>
          </Card>
        </Container>
      </div>
    );
  }
}
export default LastPageGetStarted;
