import React from 'react'
import PreTitle from './PreTitle'
import PropTypes from 'prop-types'
import c from './PageHeader.module.scss'
import cn from 'classnames'

const PageHeader = ({children}) => {
  const classes = cn([
    c.header, 
  ])
  return (
    <div className={classes}>
      {children}
    </div>
  )
}
PageHeader.defaultProps = {
  [c.fixed]: false,
}

PageHeader.propTypes = {
  fixed: PropTypes.bool,
}

PageHeader.Title = ({pretitle, children}) => (
  <div className={c.title}>
    <PreTitle>{pretitle}</PreTitle>
    {children}
  </div>
)
PageHeader.Title.displayName = 'PageHeader.Title'
PageHeader.Title.defaultProps = {
  pretitle: '',
}

export default PageHeader
