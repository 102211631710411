import { systemConstants } from "../_constants";
// import { getMapAlertsAndMapItems,getMapRiskLocations } from "../_helpers/getMapAlertsAndMapItems";
// import { getAlertLocations } from "../_helpers/getAlertLocations";
// import { getRiskTable } from "../_helpers/getRiskTable";

const TIME40 = 1000 * 60 * 40;
const TIME10 = 1000 * 60 * 10;
const TIME15 = 1000 * 60 * 15;
const TIME60 = 1000 * 60 * 60;
let date;

export function systemData(
  state = {
    data: {
      loaded: false,
      time_loaded: null,
      alert_locations: null,
      risk_table: null,
      weather_warnings: null,
    },
  },
  action
) {
  // console.log(state)

  switch (action.type) {
    case systemConstants.setSystemData:
      // console.log("SETTING STATE")
      // console.log(action.data)
      return { data: action.data };

    // if(!state.loggedIn){
    //   return Object.assign({}, state, {
    //     loggedIn:false,
    //   });

    // }
    // else{
    //   return Object.assign({}, state, {
    //     state
    //   });

    case systemConstants.updateAlertLocations:
      return { data: action.data };

    // return Object.assign({}, state, {
    //     alert_locations: alert_locations1,
    //     alert_locations_updated: new Date()
    //   });
    case systemConstants.updateWeatherWarnings:
      return { data: action.data };

    case systemConstants.updateRiskTable:
      return { data: action.data };

    case systemConstants.clearSystemData:
      return {
        data: {
          loaded: false,
          time_loaded: null,
          alert_locations: null,
          risk_table: null,
          weather_warnings: null,
        },
      };

    default:
      return state;
  }
}

export function dashboardData(
  state = {
    data: {
      id: 0,
      country: "None",
      loaded: false,
      time_loaded: null,
      location_data: null,
      live_alerts: null,
      historical_alerts: null,
      historical_weather_alerts: null,
      property_details: null,
      crime_risks: null,
      flood_risks: null,
      mitigations: null,
      loss_incidents: null,
      epc: null,
    },
  },
  action
) {
  switch (action.type) {
    case systemConstants.setDashboardData:
      // console.log("SETTING STATE")
      // console.log(action.data)
      return { data: action.data };

    case systemConstants.updateLocationData:
      //console.log(action.data)
      return { data: action.data };

    case systemConstants.updateLossIncidents:
      //console.log(action.data)
      return { data: action.data };

    case systemConstants.updateMitigations:
      //console.log(action.data)
      return { data: action.data };

    case systemConstants.updateLiveAlerts:
      //console.log(action.data)
      return { data: action.data };

    case systemConstants.updateHistoricalAlerts:
      //console.log(action.data)
      return { data: action.data };

    case systemConstants.updateHistoricalWeatherAlerts:
      //console.log(action.data)
      return { data: action.data };

    case systemConstants.updatePropertyDetails:
      //console.log(action.data)
      return { data: action.data };

    case systemConstants.updateEPC:
      //console.log(action.data)
      return { data: action.data };

    case systemConstants.updateCrimeRisks:
      //console.log(action.data)
      return { data: action.data };

    case systemConstants.updateFloodRisks:
      //console.log(action.data)
      return { data: action.data };

    case systemConstants.updateCovidTiers:
      //console.log(action.data)
      return { data: action.data };

    case systemConstants.clearSystemData:
      return {
        data: {
          id: 0,
          country: "None",
          loaded: false,
          time_loaded: null,
          location_data: null,
          live_alerts: null,
          historical_alerts: null,
          historical_weather_alerts: null,
          property_details: null,
          crime_risks: null,
          flood_risks: null,
          mitigations: null,
          loss_incidents: null,
        },
      };

    default:
      // console.log(state)
      return state;
  }
}

export function mapAlertData(state = { data: { alerts: "empty" } }, action) {
  //  console.log(action)
  switch (action.type) {
    case systemConstants.updateMapAlerts:
      return { data: action.data };

    case systemConstants.clearMapData:
      return { data: { alerts: "empty" } };

    // if(!state.loggedIn){
    //   return Object.assign({}, state, {
    //     loggedIn:false,
    //   });

    // }
    // else{
    //   return Object.assign({}, state, {
    //     state
    //   });

    default:
      return state;
  }
}

// export function orgData( state={data:{organisation:'empty'}}, action) {
//     //  console.log(action)
//         switch (action.type) {

//             case systemConstants.setOrgData:
//                     return {data:action.data}

//             case systemConstants.clearOrgData:
//                 return {data:{organisation:'empty'}}

//             // if(!state.loggedIn){
//             //   return Object.assign({}, state, {
//             //     loggedIn:false,
//             //   });

//             // }
//             // else{
//             //   return Object.assign({}, state, {
//             //     state
//             //   });

//             default:

//                     return state;

//         }
//     }

export function mapLocationData(
  state = { data: { locations: "empty" } },
  action
) {
  //  console.log(action)
  switch (action.type) {
    case systemConstants.updateRiskLocationsData:
      return { data: action.data };

    case systemConstants.clearRiskLocationsData:
      return { data: { locations: "empty" } };

    default:
      return state;
  }
}
