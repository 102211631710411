import React, { useEffect } from 'react'
import Table from 'react-bootstrap/Table'
import Search from 'balkerne-components/Search'
import Input from 'balkerne-components/Input'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import DropdownBootstrap from 'balkerne-components/DropdownBootstrap'
import ProgressBar from 'react-bootstrap/ProgressBar'
import './ListIncidents.module.scss'

import { useState } from 'react'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import c from './Overlay.module.scss'
import cn from 'classnames'

export const ListIncidents = ({ ...props }) => {
  let history = useHistory()
  const [completedforms, setCompletedForms] = useState([])
  const [initForms, setInitForms] = useState(false)
  const [filterBy, setFilterBy] = useState('')
  const [progressBarValue, setProgressBarValue] = useState(0)
  const [progressBarDisplay, setProgressBarDisplay] = useState('flex')
  const [filterdisabled, setFilterDisabled] = useState(true)
  const baseurl = 'https://r8fd6rsk66.execute-api.eu-west-1.amazonaws.com/Version1'
  //Array for filter dropdown options
  const options = [
    { label: 'Date Created', value: 'Date Created' },
    { label: 'Date Updated', value: 'Date Updated' },
    { label: 'Incident Type', value: 'Incident Type' },
    { label: 'Severity', value: 'Severity' },
    { label: 'User', value: 'User' },
  ]
  let examineFlag = true

  const overlayclass = cn([c.overlay])
  useEffect(() => {
    let isSubscribed = true
    getCompletedForms().then(data => {
      if (isSubscribed) {
        setCompletedForms(data)
        setInitForms(true)
      }
    })

    return () => (isSubscribed = false)
  }, [])
  useEffect(() => {
    displayCompletedForms()
    let isSubscribed = true
    const getFormsInfo = async () => {
      let progress = 0
      const progressMax = completedforms.length
      completedforms.map(async form => {
        //console.log(form)
        const templateid = form.template_id
        const path = '/forms/templates/' + templateid
        const fetchurl = baseurl + path
        await axios.get(fetchurl).then(response => {
          if (isSubscribed) {
            const templateSchema = JSON.parse(response.data.data.template)
            const subcategoryid = response.data.data.subcategory_id

            examineNode(templateSchema)

            const form_data = JSON.parse(form.data)
            let tempObj = JSON.parse(form_data)
            foundpath.map(key => {
              if (tempObj[key] != undefined) {
                tempObj = tempObj[key]
              } else {
                tempObj = 'NONE'
              }
            })
            let formseverity = ''
            switch (tempObj) {
              case 'NONE':
                formseverity = 'N/A'
                break
              default:
                formseverity = String(tempObj)
                break
            }
            let formtablerow = document.getElementById(form.instance_id + '-form')
            if (formtablerow !== null) {
              let severitycolumn = formtablerow.children[3]
              severitycolumn.innerHTML = formseverity
            }
          }
        })
        examineFlag = true
        schemapath = []
        progress += 1
        let tempprogress = (progress / progressMax) * 100
        if (isSubscribed) {
          setProgressBarValue(tempprogress)
          if (progress == progressMax) {
            setProgressBarDisplay('none')
            setFilterDisabled(false)
          }
        }
      })
    }
    if (initForms) {
      getFormsInfo()
    }
    return () => (isSubscribed = false)
  }, [initForms])

  useEffect(() => {
    clearFilter()
    displayCompletedForms()
    switch (filterBy) {
      case 'Date Created':
        document.getElementById('filter_button').style.display = 'block'
        document.getElementById('clear_filter_button').style.display = 'block'
        document.getElementById('from_date').style.display = 'block'
        document.getElementById('to_date').style.display = 'block'
        break
      case 'Date Updated':
        document.getElementById('filter_button').style.display = 'block'
        document.getElementById('clear_filter_button').style.display = 'block'
        document.getElementById('from_date').style.display = 'block'
        document.getElementById('to_date').style.display = 'block'
        break
      default:
        document.getElementById('filter_button').style.display = 'none'
        document.getElementById('clear_filter_button').style.display = 'none'
        document.getElementById('from_date').style.display = 'none'
        document.getElementById('to_date').style.display = 'none'
        break
    }
  }, [filterBy])
  let schemapath = []
  let foundpath = []
  const examineNode = templateSchemaNode => {
    if (examineFlag) {
      //console.log("Examining node: " + JSON.stringify(templateSchemaNode))
      if (templateSchemaNode.properties !== undefined) {
        Object.keys(templateSchemaNode.properties).map(sectionkey => {
          schemapath.push(sectionkey)
          if (templateSchemaNode['type'] == 'object') {
            examineNode(templateSchemaNode.properties[sectionkey])
          }
          schemapath.pop()
        })
      } else {
        // node has no properties, must be an element
        if (templateSchemaNode.type != 'object') {
          // element node
          //console.log("This is an element: " + JSON.stringify(templateSchemaNode))
          if (templateSchemaNode['title'] == 'Severity') {
            //console.log(schemapath)
            foundpath = [...schemapath]
            //console.log("Found severity")
            examineFlag = false
          }
        }
      }
    }
  }

  const getCompletedForms = async () => {
    const path = '/forms/property/'
    console.log(props.dashboardData.data)
    const propertyID = props.dashboardData.data.id
    console.log('Property ID: ' + propertyID)
    const fetchurl = baseurl + path + 57210
    var data
    await axios.get(fetchurl).then(response => {
      console.log(response.data.data)
      data = response.data.data
    })
    return data
  }

  const viewForm = async forminstanceid => {
    if (forminstanceid !== undefined) {
      const baseurl = 'https://r8fd6rsk66.execute-api.eu-west-1.amazonaws.com/Version1'
      // get form instance to retrieve template ID
      let path = '/forms/'
      let fetchurl = baseurl + path + forminstanceid // Get Saved form data
      let templateID = null
      let formData = null
      await axios.get(fetchurl).then(response => {
        //console.log('Loading saved form: ' + response.data.data)
        templateID = response.data.data.template_id
        formData = response.data
      })
      path = '/forms/templates/'
      fetchurl = baseurl + path + templateID // Get Form Template details
      //console.log('Getting form template with ID: ' + templateID)
      let formDetails = {}
      await axios.get(fetchurl).then(template_response => {
        //console.log(template_response.data)
        formDetails = JSON.stringify({
          title: template_response.data.data.title,
          template_id: template_response.data.data.id,
          version_id: template_response.data.data.version,
          instance_id: forminstanceid,
        })
        console.log(formDetails)
        history.push({
          pathname: '/DynamicForm',
          state: {
            templateSchema: JSON.parse(template_response.data.data.template),
            uiSchema: JSON.parse(template_response.data.data.uiSchema),
            formDetails: formDetails,
            formData: JSON.parse(formData.data.data),
            readonly: true,
            typeID: 2,
          },
        })
      })
    } else {
      console.log('No form ID detected')
    }

    /* 
    console.log(forminstanceid)
    history.push({
      pathname: '/LoadForm',
      state: {
        formId: forminstanceid,
        typeID: 2,
      },
    }) */
  }

  const displayCompletedForms = () => {
    const todayDate = new Date()
    const pastDate = Date.parse(new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate() - 7))
    const convertedPastDate = new Date(pastDate)
    let completedforms = document.getElementById('completed_forms').children
    Object.values(completedforms).map(completedform => {
      let columndate = new Date(completedform.children[4].textContent)
      if (convertedPastDate <= columndate && columndate <= todayDate) {
        if (completedform.style.display == 'none') {
          completedform.style.display = 'table-row'
        }
      } else {
        completedform.style.display = 'none'
      }
    })
  }

  const clearFilter = () => {
    let completedforms = document.getElementById('completed_forms').children
    Object.values(completedforms).map(completedform => {
      if (completedform.style.display == 'none') {
        completedform.style.display = 'table-row'
      }
      displayCompletedForms()
      console.log('clear filter')
    })
  }
  const applyFilter = () => {
    if (document.getElementById('from_date').value != '' && document.getElementById('to_date').value != '') {
      const from_date = new Date(document.getElementById('from_date').value)
      const to_date = new Date(document.getElementById('to_date').value)
      let searchcolumn = -1
      switch (filterBy) {
        case 'Date Created':
          searchcolumn = 4
          break
        case 'Date Updated':
          searchcolumn = 5
          break
        default:
          break
      }

      let completedforms = document.getElementById('completed_forms').children
      Object.values(completedforms).map(completedform => {
        let columndate = new Date(completedform.children[searchcolumn].textContent)
        if (from_date <= columndate && columndate <= to_date) {
          if (completedform.style.display == 'none') {
            completedform.style.display = 'table-row'
          }
        } else {
          completedform.style.display = 'none'
        }
      })
    } else {
      console.log('No dates selected for filter')
    }
  }
  const searchTable = searchvalue => {
    let searchcolumn = -1
    switch (filterBy) {
      case 'User':
        searchcolumn = 2
        break
      case 'Severity':
        searchcolumn = 3
        break

      case 'Incident Type':
        searchcolumn = 1
        break
      case '':
        return
    }
    let completedforms = document.getElementById('completed_forms').children
    if (searchvalue != '') {
      searchvalue = searchvalue.toUpperCase()
      Object.values(completedforms).map(completedform => {
        let columnvalue = completedform.children[searchcolumn].textContent.toUpperCase()
        if (columnvalue.includes(searchvalue)) {
          if (completedform.style.display == 'none') {
            completedform.style.display = 'table-row'
          }
        } else {
          completedform.style.display = 'none'
        }
      })
    } else {
      Object.values(completedforms).map(completedform => {
        completedform.style.display = 'table-row'
      })
    }
  }
  return (
    <>
      <Card>
        <Card.Header>Completed Forms</Card.Header>
        <ProgressBar variant="info" now={progressBarValue} style={{ zIndex: 100, display: progressBarDisplay }} />
        {/*
        <ProgressBar variant="info" percentage={progressBarValue} style={{ display: progressBarDisplay }} />

        */}

        <Card.Body>
          <div className={overlayclass} style={{ display: progressBarDisplay }}></div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ marginTop: 'auto', marginBottom: 'auto' }}>
              <DropdownBootstrap
                disabled={filterdisabled}
                style={{ marginLeft: 20, marginRight: 20 }}
                options={options}
                title="Select Filter"
                updateOption={setFilterBy}></DropdownBootstrap>
            </div>
            <div style={{ marginLeft: 20, marginRight: 20 }}>
              <Input id="from_date" type="date" style={{ display: 'none' }} />
            </div>
            <div>
              <Input id="to_date" type="date" style={{ display: 'none' }} />
            </div>

            <Button
              type="button"
              className="btn btn-primary"
              id="filter_button"
              onClick={applyFilter}
              style={{ marginLeft: 20, marginRight: 20, marginTop: 'auto', marginBottom: 'auto', display: 'none' }}>
              Apply Filter
            </Button>
            <Button
              type="button"
              className="btn btn-primary"
              id="clear_filter_button"
              onClick={clearFilter}
              style={{ display: 'none', marginTop: 'auto', marginBottom: 'auto' }}>
              Clear Filter
            </Button>
          </div>

          <div style={{ marginTop: 5 }}>
            <Search onChange={e => searchTable(e.target.value)} placeholder="Search" />
          </div>
          <Table responsive>
            <thead>
              <tr>
                <th style={{ verticalAlign: 'middle' }}>Title</th>
                <th style={{ verticalAlign: 'middle' }}>Incident Type</th>
                <th style={{ verticalAlign: 'middle' }}>Author</th>
                <th style={{ verticalAlign: 'middle' }}>Severity</th>
                <th style={{ verticalAlign: 'middle' }}>Created At</th>
                <th style={{ verticalAlign: 'middle' }}>Last Updated</th>
              </tr>
            </thead>
            <tbody id="completed_forms">
              {completedforms.map(form => (
                <tr
                  style={{ cursor: 'pointer' }}
                  key={form.instance_id}
                  id={form.instance_id + '-form'}
                  onClick={() => viewForm(form.instance_id)}
                  searchdescription={form.title}>
                  <td style={{ width: '20%' }}>{form.title}</td>
                  <td style={{ width: '20%', textAlign: 'center' }}> - </td>
                  <td style={{ width: '10%' }}>{form.assignee_name}</td>
                  <td style={{ width: '10%' }}>-</td>
                  <td style={{ width: '20%' }}>{form.started_at}</td>
                  <td style={{ width: '20%' }}>{form.updated_at}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </>
  )
}
export default ListIncidents
