import { systemConstants } from "../_constants";

import { alertActions } from "./";
import { history } from "../_helpers";
import { getUserDetails } from "../_helpers/getUserDetails";
//import { systemConstants } from './../_constants/system.constants';

export const systemActions = {
  setSystemData,
  updateAlertLocations,
  updateRiskTable,
  clearSystemData,

  updateWeatherWarnings,
  updateMitigations,
  setDashboardData,
  updateLocationData,
  updateLiveAlerts,
  updateLossIncidents,
  updateHistoricalAlerts,
  updateHistoricalWeatherAlerts,
  updateCrimeRisks,
  updateEPC,
  updateFloodRisks,
  clearDashboardData,
  updatePropertyDetails,
  updateCovidTiers,

  updateMapAlerts,
  clearMapData,
  updateRiskLocationsData,
  clearRiskLocationsData,
};

// SYSTEM DATA

function setSystemData(data) {
  console.log(data);

  return (dispatch) => {
    dispatch({ type: systemConstants.setSystemData, data });
  };
}

function updateAlertLocations(data) {
  console.log(data);

  return async (dispatch) => {
    await dispatch({ type: systemConstants.updateAlertLocations, data });
  };
}

function updateRiskTable(data) {
  return (dispatch) => {
    dispatch({ type: systemConstants.updateRiskTable, data });
  };
}

function clearSystemData() {
  return (dispatch) => {
    dispatch({ type: systemConstants.clearSystemData });
  };
}

// DASHBOARD DATA

function setDashboardData(data) {
  //console.log(data)

  return (dispatch) => {
    dispatch({ type: systemConstants.setDashboardData, data });
  };
}

function updateLocationData(data) {
  // console.log(data)

  return async (dispatch) => {
    await dispatch({ type: systemConstants.updateLocationData, data });
  };
}

function updateLiveAlerts(data) {
  // console.log(variable)

  return (dispatch) => {
    dispatch({ type: systemConstants.updateLiveAlerts, data });
  };
}

function updateMitigations(data) {
  return (dispatch) => {
    dispatch({ type: systemConstants.updateMitigations, data });
  };
}

function updateLossIncidents(data) {
  //console.log(data)

  return async (dispatch) => {
    await dispatch({ type: systemConstants.updateLossIncidents, data });
  };
}

function updateHistoricalAlerts(data) {
  // console.log(variable)

  return (dispatch) => {
    dispatch({ type: systemConstants.updateHistoricalAlerts, data });
  };
}

function updateHistoricalWeatherAlerts(data) {
  return (dispatch) => {
    dispatch({ type: systemConstants.updateHistoricalWeatherAlerts, data });
  };
}

function updateEPC(data) {
  return (dispatch) => {
    dispatch({ type: systemConstants.updateEPC, data });
  };
}

function updatePropertyDetails(data) {
  return (dispatch) => {
    dispatch({ type: systemConstants.updatePropertyDetails, data });
  };
}

function updateCrimeRisks(data) {
  // console.log(variable)

  return (dispatch) => {
    dispatch({ type: systemConstants.updateCrimeRisks, data });
  };
}

function updateFloodRisks(data) {
  // console.log(variable)

  return (dispatch) => {
    dispatch({ type: systemConstants.updateFloodRisks, data });
  };
}
function updateWeatherWarnings(data) {
  // console.log(variable)

  return (dispatch) => {
    dispatch({ type: systemConstants.updateWeatherWarnings, data });
  };
}
function updateCovidTiers(data) {
  //console.log(variable)

  return (dispatch) => {
    dispatch({ type: systemConstants.updateCovidTiers, data });
  };
}
function clearDashboardData() {
  return (dispatch) => {
    dispatch({ type: systemConstants.clearDashboardData });
  };
}

// MAP ALERTS

function updateMapAlerts(data) {
  console.log(data);

  return (dispatch) => {
    dispatch({ type: systemConstants.updateMapAlerts, data });
  };
}

// RISK TABLE

function updateRiskLocationsData(data) {
  return (dispatch) => {
    dispatch({ type: systemConstants.updateRiskLocationsData, data });
  };
}

function clearRiskLocationsData() {
  return (dispatch) => {
    dispatch({ type: systemConstants.clearRiskLocationsData });
  };
}

function clearMapData() {
  return (dispatch) => {
    dispatch({ type: systemConstants.clearMapData });
  };
}
