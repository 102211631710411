import { systemConstants } from "../_constants";
import axios from "axios";
import * as urls from "../../components/maps/urls";

export async function getLossIncidents(location_id, user_id, secret) {
  let payload = null;

  let data = { propid: location_id, secret: user_id };

  await axios({
    method: "post",
    url: urls.API_GATEWAY + "loss-incidents/get-loss-incidents",
    data: data,
  })
    .then((res) => {
      console.log(res.data);
      payload = res.data;

      if (
        payload === "null" ||
        payload === null ||
        payload === "Failure" ||
        typeof payload === "undefined"
      ) {
        payload = null;
        console.log("Loss-incidents getting ERROR");
      } else if (payload === "Invalid Details") {
        console.log(
          "Credentials for getting Loss-incidents, details are INVALID"
        );
        payload = null;
      } else if (payload === "No loss incidents found") {
        console.log("No Loss-incidents were found!");
        payload = null;
      } else {
        console.log("SUCCESS!!");
        console.log("Loss-incidents Data retrieved from the database.");
        // payload = res.data.items;
      }
    })
    .catch((err) => {
      payload = null;
      console.log("FAILURE!!");
      console.log(err);
    });

  // let data =null;

  // let query = [`get?propid=${location_id}`];

  // let lossincidents_url = [urls.BACKEND, "loss-incidents", query].join("/");

  //   let lossIndcidentsFormData = new FormData();
  //   lossIndcidentsFormData.append(
  //     "user_id",
  //     user_id
  //   );

  //   await axios
  //     .post(lossincidents_url, lossIndcidentsFormData, {
  //       headers: {
  //         "Content-Type": "multipart/form-data"
  //       }
  //     })
  //     .then(res => {
  //       console.log("SUCCESS!!");
  //       console.log(res);
  //       console.log("LossIncidents Data retrieved from the database.");
  //       data = res.data.items;

  //     //   this.state.alerts = alertsAndLocations.alerts.features;
  //     //   this.state.locations = alertsAndLocations.locations.features;
  //     })
  //     .catch(err => {
  //         data = null;
  //       console.log("FAILURE!!");
  //       console.log(err);
  //     });

  //     return data;

  // let query = [`?propid=${location_id}`];

  // let lossincidents_url = [urls.BACKEND, "loss-incidents", query].join("/");

  // let lossincidents = await axios.get(lossincidents_url).then(res => {
  //   data = res.data.items;

  //     console.log("SUCCESS!!");
  //     console.log(data);
  //     console.log("LossIncidents Data retrieved from the database.");
  // })
  // .catch(err => {
  //     data = null;
  //   console.log("FAILURE!!");
  //   console.log(err);
  // });

  return payload;
}
