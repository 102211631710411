import React from "react";
import PropTypes from "prop-types";
import "./Spinner.css";

import { Puff } from "./Puff";
import { Spinner } from "react-bootstrap";

export default class Loader extends React.Component {
  static propTypes = {
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    style: PropTypes.shape({})
  };

  static defaultProps = {
    color: "#71238",
    type: "Audio",
    height: 80,
    width: 80
  };

  svgRenderer() {
    return <Puff {...this.props} />;
  }

  render() {
    return (
      <div className="loader">
        <div className={this.props.style}>{this.svgRenderer()}</div>
      </div>
    );
  }
}
