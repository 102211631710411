import React from "react";
import axios from "axios";
import * as urls from "./maps/urls";
import DatePicker from "react-datepicker";

import "./addDocument.css";
import moment from "moment";
import { AssignUsers } from './userManagement/AssignUsers';
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { MiniUserSearch } from './userManagement/MiniUserSearch';

export class AddDocument extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      documentName: null,
      documentCategory: null,
      documentDescription: "",
      documentFromDate: new Date(),
      documentUntilDate: null,
      documentTags: "",
      expiry_notification:null,
      uploadFile: null,
      selectedUser:0,
      orgid: this.props.orgid,
      success: null,
      errorMessage: [],
      users:null,
      assigned_users:[],

    };

    this.handleUpload = this.handleUpload.bind(this);
    this.handleFromDate = this.handleFromDate.bind(this);
    this.handleExpiryDate = this.handleExpiryDate.bind(this);
    this.validateDocument = this.validateDocument.bind(this);

    this.addUser = this.addUser.bind(this);
    this.removeUser = this.removeUser.bind(this);
  }



  handleExpiryDate(date) {
    this.setState({
      documentUntilDate: date
    });
  }

  handleFromDate(date) {
    this.setState({
      documentFromDate: date
    });
  }



  handleFile(e) {
    console.log("adding file")
    console.log(e.target.files[0])
    
    let uploadfile = e.target.files[0];
    
    this.setState({ uploadFile: uploadfile });
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
    console.log(e.target.value)
  };


   toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});


async getUsers () {

  let users = null;


let data = {"user_id": this.props.userid, "org_id": this.props.orgid};

  await  axios({ method: 'get', url: urls.API_GATEWAY+"users/get-users",params:data})
  .then(res=> {
    users = res.data;
    console.log(users);

    if(users === "null"|| users === null || users === "Failure" || typeof users === "undefined"){
      users = [];
      console.log("Users retrieval FAILURE")
    }
    else if(users === "Invalid Details"||users === "No users found"){
      console.log("Details for users retrieved are INVALID or NO USERS WERE FOUND")
      users = [];
    }
    else{
      console.log("SUCCESS!!");
      console.log("Users retrieved from the database.");
    }

    this.setState({users})
  })
    .catch(err=>{
      users = null
      this.setState({users})
      console.log("FAILURE!!");
      console.log(err);
      if (!err.message) {
        console.log("Error when loading users: ", err);
        //  Alert.alert('Error when signing in: ', err);
      } else {
        console.log("Error when loading users: ", err.message);
        //  Alert.alert('Error when signing in: ', err.message);
        this.setState({success:false, errMessage:"Could not Reach Server"})
      }
    });

};

async componentDidMount() {
   await this.getUsers()
}


  async handleUpload(e) {
    if (this.validateDocument()) {

      console.log(this.state.uploadFile, "THE Current State");

    let uploadfile = null
    if(this.state.uploadFile){
      console.log("setting up")
      uploadfile = await this.toBase64(this.state.uploadFile);
    }

    let uploadFileName = null
    if(this.state.uploadFile && this.state.uploadFile.name){
      console.log("setting up name")
      uploadFileName = this.state.uploadFile.name
    }

    let user_list = null
    if(this.state.assigned_users && this.state.assigned_users.length >0){
      user_list =[]
      console.log("users found")
      for (let i = 0; i < this.state.assigned_users.length; i++) {
        console.log("doing user "+i)
        user_list.push(this.state.assigned_users[i].id)
      }
      user_list.push(this.props.userid)
    }


  

    let newFromDate = moment(this.state.documentFromDate).format(
      "YYYY-MM-DD HH:mm:ss"
    );

    let newUntilDate = moment(this.state.documentUntilDate).format(
      "YYYY-MM-DD HH:mm:ss"
    );


    
      let data = {"name": this.state.documentName, "category": this.state.documentCategory,"organisation": this.props.orgid,"created_by": this.props.userid,
      "description": this.state.documentDescription, "from_date": newFromDate, "expiry_notification": this.state.expiry_notification,"user_list": user_list,"tags": this.state.documentTags,
      "until_date": newUntilDate, "file": uploadfile,  "filename": uploadFileName
    
    };
    
      await  axios({ method: 'post', url: urls.API_GATEWAY+"documents/add-document",data:data})
      .then(res=> {
        let result = res.data;
        console.log(result);
        
    
        if(result === "null"|| result === null || result === "Failure"|| typeof result === "undefined"){
          result = null;
          console.log("Error adding document")

          this.state.errorMessage.push(
            "Failed to upload document."
          );
        }
        else if(result === "Invalid Details"){
          console.log("Details for adding document are INVALID")
          result = null;

          this.state.errorMessage.push(
            "Failed to upload document."
          );
        }
        else if(result === "Error processing attachment"){
          console.log("Attachemnt is INVALID")
          result = null;

          this.state.errorMessage.push(
            "File attachment is invalid"
          );
        }
        else if(result === "Invalid file extension"){
          console.log("File extension is INVALID")

          this.state.errorMessage.push(
            "File extension is unsupported."
          );
          result = null;
        }
        else{
          console.log("SUCCESS!!");
          console.log("Document successfully added to the database");
        }


        console.log(res);
          if(result==="Success"){
            this.setState({ success: true });
            this.props.updateDocuments()
          }
          else{
            console.log("FAILURE!!");
            console.log(res.data);
            this.setState({ success: false });
          }
          
      })
        .catch(err=>{
          this.state.errorMessage.push(
            "Failed to upload document."
          );
          console.log(err);
          this.setState({ success: false });
        });



    } else console.log("validation failed");
  }

  //Validates the form for adding documents - check if all fields are filled in
  validateDocument() {
    let validate = true;
    let validationerrors = [];

    if (
      typeof this.state.documentName === "undefined" || this.state.documentName ===null ||
      this.state.documentName.length < 1
    ) {
      validate = false;
      validationerrors.push("Document name cannot be empty.\n");
    }

    if (
        typeof this.state.documentCategory === "undefined" || this.state.documentCategory ===null ||
        this.state.documentCategory.length < 1
      ) {
        validate = false;
        validationerrors.push("Document category cannot be empty.\n");
      }

    if (
    typeof this.state.documentUntilDate === "undefined" || this.state.documentUntilDate ===null 
    ) {
    validate = false;
    validationerrors.push("Document expiry date cannot be empty.\n");
    }

    if (
    typeof this.state.documentFromDate === "undefined" || this.state.documentFromDate ===null 
    ) {
    validate = false;
    validationerrors.push("Document from date cannot be empty.\n");
    }

    if (
      typeof this.state.expiry_notification === "undefined" || this.state.expiry_notification ===null 
      ) {
      validate = false;
      validationerrors.push("Document expiry notification cannot be empty.\n");
      }

    if (
    typeof this.state.documentName === "undefined" || this.state.documentName ===null ||
    this.state.documentName.length < 1
    ) {
    validate = false;
    validationerrors.push("Document name cannot be empty.\n");
    }

    if (
    typeof this.state.uploadFile === "undefined" || this.state.uploadFile ===null 
    ) {
    validate = false;
    validationerrors.push("File needs to be attached empty.\n");
    }

    
    this.setState({ errorMessage: validationerrors });

    if (validate === false) {
      this.setState({ success: false });
    }

    return validate;
  }

  showNotice() {
    if (this.state.success) {
      return (
        <div className="alert alert-success" role="alert">
          <strong>Success</strong> Document has been uploaded
        </div>
      );
    } else if (this.state.success === false) {
      return this.state.errorMessage.map(error => (
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
      ));
    }
  }

  onRowSelectUser = (row, isSelected, e, rowIndex) => {
    console.log(row)
    if (isSelected) {
      this.setState({ selectedUser: [row.id] });
      // this.state.selectedDocuments = [row.id];
    } else {
      this.setState({ selectedUser: [row.id] })
      this.removeUser(row)
    }
  }

    removeUser(row){
      const index = this.state.assigned_users.indexOf(row);
      console.log(index)
    if (index > -1) {
      let assigned_users =this.state.assigned_users
      assigned_users.splice(index, 1);
      this.setState({assigned_users})
      }
    }

    addUser(id) {
      let assigned_users = this.state.assigned_users
      const index = this.state.assigned_users.indexOf(id);
      console.log(index)
    if (index == -1) {
      assigned_users.push(id)
      this.setState({assigned_users});
    }
    }

  render() {
    const selectRowPropUsers = {
      mode: "radio",
      clickToSelect: true,
      bgColor: "cyan",
      selected: this.state.selectedUser,
      hideSelectColumn: true,
      onSelect: this.onRowSelectUser
    };
    return (
      <form className="popup">
        <div className="formbackground">
          <div className="formcontent">
            <h2>Add New Document</h2>
            <button
              type="button"
              className="close"
              aria-label="Close"
              className="close_cross_button"
              onClick={this.props.onClickClose}
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <div className="form-group">
              <label for="FormControlInput1">Name</label>
              <input
                type="documentName"
                name="documentName"
                className="form-control"
                id="FormControlInput1"
                onChange={this.handleChange}
                placeholder="Document name."
                onChange={this.handleChange}
              />
            </div>

            <div className="form-group">
              <label for="FormControlInput1">Description</label>
              <input
                type="documentDescription"
                name="documentDescription"
                className="form-control"
                id="FormControlInput1"
                onChange={this.handleChange}
                placeholder="eg. This file shows the safety guidelines for the next 2 months."
                onChange={this.handleChange}
              />
            </div>

            <div className="form-group">
              <label for="documentCategory">Document Category:</label>
              <select
                className="form-control"
                name="documentCategory"
                id="documentCategory"
                onChange={this.handleChange}
              >
                <option />
                <option value="Insurance" >Insurance</option>
                <option value="Business Continuity" >Business Continuity</option>
                <option value="Receipts" >Receipts</option>
                <option value="Reports" >Reports</option>


              </select>
            </div>


            <div className="form-group-date">
              <label for="FormControlInput1">From Date</label>

              <DatePicker
                selected={this.state.documentFromDate}
                onChange={this.handleFromDate}
                dateFormat="yyyy-MM-dd"
              />
            </div>
            <div className="form-group-date">
              <label for="FormControlInput1">Expiry Date</label>

              <DatePicker
                selected={this.state.documentUntilDate}
                onChange={this.handleExpiryDate}
                dateFormat="yyyy-MM-dd"
              />
            </div>
            <div className="form-expiry-notification">
              <label for="FormControlInput1">Expiry Notification</label>
              <select
                className="form-control"
                name="expiry_notification"
                id="expiry_notification"
                onChange={this.handleChange}
              >
                <option />
                <option value="1" >1 Day</option>
                <option value="30" >30 Days</option>
                <option value="60" >60 Days</option>
                <option value="90" >90 Days</option>


              </select>
              
            </div>
            <div className="form-group">
              <label for="FormControlFile1">Attach File</label>
              <input
                type="file"
                name="file"
                className="form-control-file"
                id="FormControlFile1"
                onChange={e => this.handleFile(e)}
              />
            </div>
            <div>
              <MiniUserSearch
                handleChange={this.handleChange}
                users={this.state.users}
                addUser={this.addUser}
                secret={this.props.secret}
              />
            </div>
            <div className="propertylistresults">
            <BootstrapTable
              className="table-smaller-locations"
              selectRow={selectRowPropUsers}
              data={this.state.assigned_users}
              striped
              hover
            >
              <TableHeaderColumn isKey dataField="id" hidden>
                ID
              </TableHeaderColumn>
              <TableHeaderColumn width="30%" dataField="full_name">
                Name
              </TableHeaderColumn>
              <TableHeaderColumn width="10%" dataField="email">
                Email
              </TableHeaderColumn>
            </BootstrapTable>
          </div>
            <div className="form-group">
              <div className="submitbutton">
                <button
                  type="button"
              
                  className="uploadfilesubmit"
                  onClick={e => this.handleUpload(e)}
                >
                  Submit
                </button>
              </div>
            </div>
            {this.showNotice()}
          </div>
        </div>
      </form>
    );
  }
}
export default AddDocument;
