import React from "react"
import PropTypes from "prop-types"
import BootstrapBadge from "react-bootstrap/Badge"

const Badge = ({children, ...props}) => <BootstrapBadge {...props}>{children}</BootstrapBadge>

Badge.defaultProps = {
  pill: false,
  variant: "primary"
}

Badge.propTypes = {
  pill: PropTypes.bool,
  variant: PropTypes.oneOf(['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark']),
}

export default Badge