import axios from 'axios'
import React from 'react'
import { useHistory } from 'react-router-dom'

const LoadForm = (formId, ...props) => {
  let history = useHistory()
  const loadform = async () => {
    if (formId !== undefined) {
      //const baseurl = 'https://c2600835-8b88-409b-abd1-9b36ebc94cb5.mock.pstmn.io'
      const baseurl = 'https://r8fd6rsk66.execute-api.eu-west-1.amazonaws.com/Version1'
      // get form instance to retrieve template ID
      let path = '/forms/'
      let fetchurl = baseurl + path + formId.formId // Get Saved form data
      let templateID = null
      let formData = null
      await axios.get(fetchurl).then(response => {
        //console.log('Loading saved form: ' + response.data.data)
        templateID = response.data.data.template_id
        formData = response.data
      })
      path = '/forms/templates/'
      fetchurl = baseurl + path + templateID // Get Form Template details
      //console.log('Getting form template with ID: ' + templateID)
      let formDetails = {}
      await axios.get(fetchurl).then(template_response => {
        //console.log(template_response.data)
        formDetails = JSON.stringify({
          title: template_response.data.data.title,
          template_id: template_response.data.data.id,
          version_id: template_response.data.data.version,
          instance_id: formId.formId,
        })
        console.log(formDetails)
        history.push({
          pathname: '/DynamicForm',
          state: {
            templateSchema: JSON.parse(template_response.data.data.template),
            uiSchema: JSON.parse(template_response.data.data.uiSchema),
            formDetails: formDetails,
            formData: JSON.parse(formData.data.data),
            readonly: true,
          },
        })
      })
    } else {
      console.log('No form ID detected')
    }
  }
  if (formId !== undefined) {
    loadform()
  }

  return <></>
}

export default LoadForm
