import React from "react";
import ReactGA from "react-ga";
import ReactTooltip from "react-tooltip";
import "./AssignUsers.css";
import "./MiniUserSearch.css";
import axios from "axios";
import * as urls from "../maps/urls";
import Autosuggest from "react-autosuggest";

export class MiniUserSearch extends React.Component {
  constructor(props) {
    super(props);
    //setting TEMPORARY STATE
    this.state = {
      usersStrings: ["John", "Smith"],
      users_list: this.props.users,
      users: this.props.users,
      value: "",
      suggestions: [],
      found: "",
      isLoading: false,
    };

    //this.handleSubmit = this.handleSubmit.bind(this);
  }

  // Teach Autosuggest how to calculate suggestions for any given input value.
  getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    //console.log(this.props.locations);

    return inputLength === 0
      ? []
      : this.props.users.filter((user) => {
          if (user.full_name && user.email) {
            //console.log(store.name)
            //console.log(store.property_customer_reference)
            return (
              user.full_name.toLowerCase().includes(inputValue) ||
              user.email.toLowerCase().slice(0, inputLength) === inputValue
            );
          } else if (user.full_name) {
            //console.log(store.full_name.toLowerCase().includes(inputValue))
            return user.full_name.toLowerCase().includes(inputValue);
          }
        });
  };

  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion. Teach Autosuggest how to calculate the
  // input value for every given suggestion.
  getSuggestionValue = (suggestion) => {
    this.props.addUser(suggestion);
    return suggestion.full_name;
  };

  // Use your imagination to render suggestions.
  renderSuggestion = (suggestion) => (
    <div>
      {suggestion.full_name !== null
        ? suggestion.full_name + " - " + suggestion.email
        : suggestion.email}
    </div>
  );

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
    });
  };

  // Autosuggest will call this function every time you need to update suggestions.
  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value),
    });
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  //handles changes in serach string

  //render the component

  render() {
    const { value, suggestions } = this.state;

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      placeholder: "Enter a Name or Email",
      value,
      onChange: this.onChange,
    };

    return (
      <div>
        <label for="search">Assign Users</label>
        <div className="inputarea">
          <Autosuggest
            name="search"
            suggestions={suggestions}
            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
            getSuggestionValue={this.getSuggestionValue}
            renderSuggestion={this.renderSuggestion}
            inputProps={inputProps}
          />
          <button
            type="button"
            onClick={this.handleSubmit}
            className="propertysearch"
            style={this.props.customstyle}
          >
            Add
          </button>
        </div>
      </div>
    );
  }
}
export default MiniUserSearch;
