import { systemActions } from "../_actions";
import { getWeatherWarnings } from "./getWeatherWarnings";
import { getAlertLocations } from "./getAlertLocations";
import { getRiskTable } from "./getRiskTable";

const TIME40 = 1000 * 60 * 40;
const TIME10 = 1000 * 60 * 10;
const TIME15 = 1000 * 60 * 15;
const TIME60 = 1000 * 60 * 60;
let date;

export const systemReduxHandler = {
  setSystemData,
  setSystemDataMobile,
  updateAlertLocations,
  updateWeatherWarnings,
  updateRiskTable,
};

async function setSystemDataMobile(props, location_list, secret) {
  if (props.systemData === null) {
    console.log("set system data mobile null");
    console.log("set system data  mobile null");

    let alert_locations1 = await getAlertLocations(location_list, secret);
    // let weather_warnings1 = await getWeatherWarnings(secret)

    // let  risk_table1 = await getRiskTable(location_list)

    let state_new = {
      loaded: false,
      time_loaded: null,
      alert_locations: null,
      risk_table: null,
      weather_warnings: null,
    };
    state_new.alert_locations = alert_locations1;
    // state_new.weather_warnings = weather_warnings1;
    // state_new.risk_table = risk_table1;
    state_new.loaded = true;
    state_new.time_loaded = new Date().getTime();

    props.dispatch(systemActions.setSystemData(state_new));
    return state_new;
  }

  let systemData = props.systemData.data;

  if (systemData.loaded === false) {
    console.log("set system data mobile   0");
    console.log("set system data mobile   0");

    let alert_locations1 = await getAlertLocations(location_list, secret);
    // let weather_warnings1 = await getWeatherWarnings(secret)

    // let  risk_table1 = await getRiskTable(location_list)

    let state_new = systemData;
    state_new.alert_locations = alert_locations1;
    // state_new.weather_warnings = weather_warnings1;
    // state_new.risk_table = risk_table1;
    state_new.loaded = true;
    state_new.time_loaded = new Date().getTime();

    props.dispatch(systemActions.setSystemData(state_new));
    return state_new;
  }

  date = new Date() - TIME60;
  if (date > systemData.time_loaded) {
    console.log("set system data  mobile  1");
    console.log("set system data  mobile  1");

    let alert_locations1 = await getAlertLocations(location_list, secret);
    // let weather_warnings1 = await getWeatherWarnings(secret)

    // let  risk_table1 = await getRiskTable(location_list)

    let state_new = systemData;
    state_new.alert_locations = alert_locations1;
    // state_new.weather_warnings = weather_warnings1;
    // state_new.risk_table1 = risk_table1;
    state_new.loaded = true;
    state_new.time_loaded = new Date().getTime();

    props.dispatch(systemActions.setSystemData(state_new));
    return state_new;
  }
  console.log("return system data mobile  2");
  console.log("return system data  mobile  2");

  return systemData;
}

async function setSystemData(props, location_list, secret, preferences) {
  if (props.systemData === null) {
    console.log("set system data  null");
    console.log("set system data  null");

    let alert_locations1 = await getAlertLocations(location_list, secret);
    let weather_warnings1 = await getWeatherWarnings(secret);

    let risk_table1 = await getRiskTable(location_list, secret, preferences);

    let state_new = {
      loaded: false,
      time_loaded: null,
      alert_locations: null,
      risk_table: null,
      weather_warnings: null,
    };
    state_new.alert_locations = alert_locations1;
    state_new.weather_warnings = weather_warnings1;
    state_new.risk_table = risk_table1;
    state_new.loaded = true;
    state_new.time_loaded = new Date().getTime();

    props.dispatch(systemActions.setSystemData(state_new));
    return state_new;
  }

  let systemData = props.systemData.data;

  if (systemData.loaded === false) {
    console.log("set system data  0");
    console.log("set system data  0");

    let alert_locations1 = await getAlertLocations(location_list, secret);
    let weather_warnings1 = await getWeatherWarnings(secret);

    let risk_table1 = await getRiskTable(location_list, secret, preferences);

    let state_new = systemData;
    state_new.alert_locations = alert_locations1;
    state_new.weather_warnings = weather_warnings1;
    state_new.risk_table = risk_table1;
    state_new.loaded = true;
    state_new.time_loaded = new Date().getTime();

    props.dispatch(systemActions.setSystemData(state_new, secret));
    return state_new;
  }

  date = new Date() - TIME60;
  if (date > systemData.time_loaded) {
    console.log("set system data  1");
    console.log("set system data  1");

    let alert_locations1 = await getAlertLocations(location_list, secret);
    let weather_warnings1 = await getWeatherWarnings(secret);

    let risk_table1 = await getRiskTable(location_list, secret, preferences);

    let state_new = systemData;
    state_new.alert_locations = alert_locations1;
    state_new.weather_warnings = weather_warnings1;
    state_new.risk_table1 = risk_table1;
    state_new.loaded = true;
    state_new.time_loaded = new Date().getTime();

    props.dispatch(systemActions.setSystemData(state_new));
    return state_new;
  }
  console.log("return system data  2");
  console.log("return system data  2");

  return systemData;
}

async function updateAlertLocations(props, location_list, secret) {
  let stateAlertLocations = props.systemData.data;

  if (
    typeof stateAlertLocations.alert_locations === "undefined" ||
    stateAlertLocations.alert_locations === null
  ) {
    console.log("update alert-locations 0");

    let alert_locations1 = await getAlertLocations(location_list, secret);
    stateAlertLocations.alert_locations = alert_locations1;
    stateAlertLocations.alert_locations_updated = new Date().getTime();
    props.dispatch(systemActions.updateAlertLocations(stateAlertLocations));

    return stateAlertLocations.alert_locations;
  }

  date = new Date() - TIME15;

  if (
    typeof stateAlertLocations.alert_locations_updated === "undefined" &&
    date > stateAlertLocations.time_loaded
  ) {
    console.log("update alert-locations 1");

    let alert_locations1 = await getAlertLocations(location_list, secret);
    stateAlertLocations.alert_locations = alert_locations1;
    stateAlertLocations.alert_locations_updated = new Date().getTime();
    props.dispatch(systemActions.updateAlertLocations(stateAlertLocations));
    return stateAlertLocations.alert_locations;
  }
  if (
    stateAlertLocations.alert_locations_updated &&
    date > stateAlertLocations.alert_locations_updated
  ) {
    console.log("update alert-locations 2");

    let alert_locations1 = await getAlertLocations(location_list, secret);
    stateAlertLocations.alert_locations = alert_locations1;
    stateAlertLocations.alert_locations_updated = new Date().getTime();
    props.dispatch(systemActions.updateAlertLocations(stateAlertLocations));

    return stateAlertLocations.alert_locations;
  }

  return stateAlertLocations.alert_locations;
}

async function updateWeatherWarnings(props, secret) {
  let state = props.systemData.data;

  if (
    typeof state.weather_warnings === "undefined" ||
    state.weather_warnings === null
  ) {
    console.log("update weather_warnings 0");

    let weather_warnings1 = await getWeatherWarnings(secret);
    state.weather_warnings = weather_warnings1;
    state.weather_warnings_updated = new Date().getTime();
    props.dispatch(systemActions.updateWeatherWarnings(state));

    return state.weather_warnings;
  }

  date = new Date() - TIME10;

  if (
    typeof state.weather_warnings_updated === "undefined" &&
    date > state.time_loaded
  ) {
    console.log("update weather_warnings 1");

    let weather_warnings1 = await getWeatherWarnings(secret);
    state.weather_warnings = weather_warnings1;
    state.weather_warnings_updated = new Date().getTime();
    props.dispatch(systemActions.updateWeatherWarnings(state));
    return state.weather_warnings;
  }
  if (state.weather_warnings_updated && date > state.weather_warnings_updated) {
    console.log("update weather_warnings 2");

    let weather_warnings1 = await getWeatherWarnings(secret);
    state.weather_warnings = weather_warnings1;
    state.weather_warnings_updated = new Date().getTime();
    props.dispatch(systemActions.updateWeatherWarnings(state));

    return state.weather_warnings;
  }

  return state.weather_warnings;
}

async function updateRiskTable(props, location_list, secret, preferences) {
  let state = props.systemData.data;

  if (typeof state.risk_table === "undefined" || state.risk_table === null) {
    console.log("update alert-locations 0");

    let risk_table1 = await getRiskTable(location_list, secret, preferences);
    state.risk_table = risk_table1;
    state.risk_table_updated = new Date().getTime();
    props.dispatch(systemActions.updateRiskTable(state));

    return state.risk_table;
  }

  date = new Date() - TIME15;

  if (
    typeof state.risk_table_updated === "undefined" &&
    date > state.time_loaded
  ) {
    console.log("update alert-locations 1");

    let risk_table1 = await getRiskTable(location_list, secret, preferences);
    state.risk_table = risk_table1;
    state.risk_table_updated = new Date().getTime();
    props.dispatch(systemActions.updateRiskTable(state));

    return state.risk_table;
  }
  if (state.risk_table_updated && date > state.risk_table_updated) {
    console.log("update alert-locations 2");

    let risk_table1 = await getRiskTable(location_list, secret, preferences);
    state.risk_table = risk_table1;
    state.risk_table_updated = new Date().getTime();
    props.dispatch(systemActions.updateRiskTable(state));

    return state.risk_table;
  }

  return state.risk_table;
}
