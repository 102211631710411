import React, { PureComponent } from "react";
// import * as styles from "../maps/styles";

import {
  ComposedChart,
  Area,
  Bar,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Brush,
  Legend,
} from "recharts";

import moment from "moment";

import "./Weatherchart.css";

export default class Weatherchart extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      weatherreadings: this.props.weatherreadings,
    };
  }

  pickChartType() {
    if (this.props.charttype === "Area")
      return (
        <Area
          dataKey={this.props.readingtype}
          name={this.props.readingtitle}
          fill={this.props.chartcolour}
        />
      );
    else if (this.props.charttype === "Bar")
      return (
        <Bar
          dataKey={this.props.readingtype}
          name={this.props.readingtitle}
          fill={this.props.chartcolour}
        />
      );
  }

  showGauge() {
    return (
      <div className="Weather">
        <h3>
          12 Hour {this.props.readingtitle} Forecast for{" "}
          {this.props.locationName}
        </h3>

        <div>{this.plotAreaChart()}</div>
      </div>
    );
  }

  plotAreaChart() {
    let newweatherreadings = this.state.weatherreadings;
    // console.log("Forecasttime")

    return (
      <div className="Weatherchart">
        <ComposedChart
          width={900}
          height={500}
          data={newweatherreadings}
          // syncId="anyId"

          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="forecasttime"
            width={40}
            name="Time"
            tickFormatter={(unixTime) =>
              moment(unixTime, "DD/MM/YYYY HH:mm").format("DD-MMM HH:mm")
            }
          />
          <YAxis />
          <Tooltip />
          <Legend />
          <Brush dataKey="name" height={30} stroke="#8884d8" />

          {/* <Bar dataKey={this.props.readingtype} fill={this.props.chartcolour} /> */}
          {this.pickChartType()}

          <Line
            dataKey="maxreading"
            name={this.props.readingtitle + " Alert Level"}
            fill="#f0932b"
          />
        </ComposedChart>
      </div>
    );
  }

  render() {
    return <div>{this.showGauge()}</div>;
  }
}
