import React from "react";
import AddMap from "../components/userManagement/AddMap";
import SingleUserLocationsAssign from "../components/userManagement/SingleUserLocationsAssign";
import AssignUsersLastStep from "../components/userManagement/AssignUsersLastStep";
import axios from "axios";
import * as urls from "../components/maps/urls";
//import Home from './Home'
import { Auth } from "aws-amplify";
import { userActions } from "../redux/_actions";
import { connect } from "react-redux";
import { Logout } from "../components/Logout";
import Header from "../components/Header";
import ReactGA from "react-ga";
import { RefreshAuthTokens } from "../components/RefreshAuthTokens";

import "./MainAddLocation.css";

class ReassignUserProperties extends React.Component {
  constructor(props) {
    super(props);
    //initial cunstructor for the master class containng the 3 forms

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    // this.addUsersToDatabaseAndCognito = this.addUsersToDatabaseAndCognito.bind(this)
    this.state = {
      //data arrays
      user: null,
      locations: null,
      assignedLocations: null,
      selectedAssignedLocations: [],
      //selections in assign users form
      selectedLocations: [],
      user_full_name: null,

      ctrl: false,
      shifts: false,

      org_id: 0,
      current_user_id: 0,

      //error displaying
      errorArray: [],
      errorText: "",
    };
    this.getOrganisationLocations = this.getOrganisationLocations.bind(this);
    this.getUserLocations = this.getUserLocations.bind(this);
  }
  //  72^C#bB12#$b
  async componentDidMount() {
    // console.log(this.props.location)
    // console.log(this.props.location.state)

    //console.log(this.props.location.state.userRedirect)

    await RefreshAuthTokens(this.props);

    if (
      typeof this.props.location.state !== "undefined" &&
      typeof this.props.location.state.userRedirectId !== "undefined" &&
      typeof this.props.location.state.userRedirectGroup !== "undefined" &&
      this.props.location.state.userRedirectId !== null &&
      this.props.location.state.userRedirectGroup !== null
    ) {
      this.state.org_id = this.props.userData.data.orgid;
      this.state.current_user_id = this.props.location.state.userRedirectId;
      this.state.group = this.props.location.state.userRedirectGroup;
      if (
        typeof this.props.location.state.userRedirectName !== "undefined" &&
        this.props.location.state.userRedirectName !== null
      ) {
        this.state.user_full_name = this.props.location.state.userRedirectName;
      }
    } else {
      // console.log(this.props.userData);
      this.state.org_id = this.props.userData.data.orgid;
      this.state.current_user_id = this.props.userData.data.id;
      this.state.group = this.props.userData.data.group;
    }

    //DISPALY REGION, customer prop reference, name, address, postcode

    await this.getOrganisationLocations(
      this.props.user.user.signInUserSession.idToken.jwtToken,
      this.props.userData.data.orgid
    );

    await this.getUserLocations(
      this.props.user.user.signInUserSession.idToken.jwtToken,
      this.state.current_user_id
    );

    this.setState({ mounted: true });
  }

  async getUserLocations(secret, user_id) {
    let assignedLocations = [];
    //let success = true;

    let params = { user_id: user_id };

    await axios({
      method: "get",
      url: urls.API_GATEWAY + "locations/property",
      params: params,
    })
      .then((res) => {
        console.log("assigned locations received!!");
        assignedLocations = res.data;
        if (assignedLocations === "Invalid Details") {
          console.log("Details for Locations retrieved are INVALID");
          assignedLocations = [];
        } else if (
          assignedLocations === null ||
          assignedLocations === "Failure" ||
          typeof assignedLocations === "undefined"
        ) {
          console.log("Location retrieval has FAILED");
          assignedLocations = [];
        } else if (assignedLocations === "No properties found") {
          console.log("No properties was Found");
          assignedLocations = [];
        } else if (!Array.isArray(assignedLocations)) {
          console.log("Location retrieval ERROR");
          assignedLocations = [];
        }

        this.setState({ assignedLocations });
      })
      .catch((err) => {
        assignedLocations = [];
        console.log("FAILURE!!");
        console.log(err);
        //alert("No connection to the server")
        if (!err.message) {
          console.log("Error when getting locations: ", err);
          //  Alert.alert('Error when signing in: ', err);
        } else {
          console.log("Error when getting locations: ", err.message);
          //  Alert.alert('Error when signing in: ', err.message);
          this.setState({
            success: false,
            errMessage: "Could not retrieve locations. (Server)",
          });
        }
      });
  }

  async getOrganisationLocations(secret, org_id) {
    let locations = [];
    //let success = true;

    let params = { org_id: org_id };

    await axios({
      method: "get",
      url: urls.API_GATEWAY + "locations/property",
      params: params,
    })
      .then((res) => {
        console.log("locations received!!");
        locations = res.data;
        if (locations === "Invalid Details") {
          console.log("Details for Locations retrieved are INVALID");
          locations = [];
        } else if (
          locations === null ||
          locations === "Failure" ||
          typeof locations === "undefined"
        ) {
          console.log("Location retrieval has FAILED");
          locations = [];
        } else if (locations === "No properties found") {
          console.log("No properties was Found");
          locations = [];
        } else if (!Array.isArray(locations)) {
          console.log("Location retrieval ERROR");
          locations = [];
        }

        this.setState({ locations });
      })
      .catch((err) => {
        locations = [];
        console.log("FAILURE!!");
        console.log(err);
        //alert("No connection to the server")
        if (!err.message) {
          console.log("Error when getting locations: ", err);
          //  Alert.alert('Error when signing in: ', err);
        } else {
          console.log("Error when getting locations: ", err.message);
          //  Alert.alert('Error when signing in: ', err.message);
          this.setState({
            success: false,
            errMessage: "Could not retrieve locations. (Server)",
          });
        }
      });
  }

  //Validates the assigning (Adding users to location that same user can't get added twice to the same location)
  validateAssignLocations(location) {
    let validate = true;
    let errorMessage = [];
    if (this.state.assignedLocations && this.state.assignedLocations.length) {
      console.log("CHECKING " + location);
      for (var i = 0; i < this.state.assignedLocations.length; i++) {
        // console.log("CHECKING2");
        // console.log(user.email);
        // console.log(this.state.addUsers[i].email);
        //Checks if addUsers list already has the selected person in the list
        if (location === this.state.assignedLocations[i].id) {
          console.log("invalidated");
          validate = false;
          errorMessage.push(
            this.state.assignedLocations[i].name +
              "' is already assigned to this user.\n"
          );
        }
      }
    }

    //return true or false as well as printing an error
    if (validate === true) {
      this.setState({
        errorText: "",
      });
      return true;
    } else {
      this.setState({
        errorText: this.printError(errorMessage),
      });
      return false;
    }
  }

  // Use the submitted data to set the state
  handleChange(name, value) {
    this.setState({
      [name]: value,
    });
  }

  // trigger submission (saving to database later - saving) - prints dataset values at the moment
  async handleSubmit(event) {
    event.preventDefault();
    let locationAddedSuccess = false;
    let mappingsAddedSuccess = false;
    let error = false;
    let location = this.state.location;
    let analyticOutcome = "";

    let payload = {
      user_list: [],
      location_list: [],
    };

    if (this.state.assignedLocations && this.state.assignedLocations.length) {
      // console.log("found location")

      for (var assignedLocation1 in this.state.assignedLocations) {
        var assignedLocation = this.state.assignedLocations[assignedLocation1];
        //console.log(assignedUser)

        payload.user_list.push(this.state.current_user_id);

        payload.location_list.push(assignedLocation.id);
      }
    } else {
      console.log("assigned locations empty");
      analyticOutcome = " assigned locations empty";
    }

    // console.log("submited");

    // console.log(payload);

    mappingsAddedSuccess = await this.postDatabaseUserPropertyMatch(payload);
    if (mappingsAddedSuccess === true) {
      // console.log("ADDING USERS-mappings COMPLETE!!!!!!!!");
      alert("Properties successfully reassigned");
      analyticOutcome = " Successfully added property";

      this.props.history.push({
        pathname: "/",
      });
    } else {
      console.log("adding USER PROPERTY MATCHES FAILED");
      analyticOutcome = " Failed to add mappings to property";
    }

    ReactGA.event({
      category: "ReassignUserProperties",
      action: analyticOutcome,
    });
  }

  async postDatabaseUserPropertyMatch(user_locations_map) {
    let user = this.props.user.user;

    console.log(user_locations_map.user_list);
    console.log(user_locations_map.location_list);

    let success = true;

    let data = {
      aws_id: user.username.split("").reverse().join(""),
      secret_key:
        user.userDataKey +
        " " +
        user.signInUserSession.accessToken.jwtToken +
        " " +
        user.pool.userPoolId,
      user_list: user_locations_map.user_list,
      location_list: user_locations_map.location_list,
    };

    await axios({
      method: "post",
      url: urls.API_GATEWAY + "users/map-user-locations-reassign",
      data: data,
    })
      .then((res) => {
        console.log(res.data);
        let response = res.data;

        if (
          response === "null" ||
          response === null ||
          response === "Failure" ||
          typeof response === "undefined"
        ) {
          success = false;
          console.log("Mapping users FAILURE");
        } else if (
          response === "Invalid Details" ||
          response === "No locations or users"
        ) {
          console.log(
            "Details for mapping users to locations are INVALID or NO LOCATIONS OR USERS"
          );
          success = false;
        } else if (response == "Success") {
          success = true;
          console.log("SUCCESS!!");
          console.log("PROPERTY-USER  Successfully added to database");
        } else {
          console.log("FAILURE, UNKNOWN RESPONSE");
          success = false;
        }
      })
      .catch((err) => {
        console.log("FAILURE!!");
        console.log(err);
        this.setState({
          errorText: "Database adding error",
        });
        success = false;
      });

    return success;
  }

  //render previous page button
  get previousButton() {
    let currentStep = this.state.currentStep;
    // If the current step is not 1, then render the "previous" button
    if (currentStep !== 1) {
      return (
        <button type="button" onClick={this._prev}>
          <span className="glyphicon glyphicon-menu-left" /> Previous
        </button>
      );
    }
    // ...else return nothing
    return null;
  }

  //render next page button
  get nextButton() {
    return (
      <button
        className="btn btn-primary col-sm-2  "
        type="button"
        onClick={this.handleSubmit}
      >
        Submit <span className="glyphicon glyphicon-menu-right" />
      </button>
    );
  }

  //formats the error message to be on separate lines
  printError = (errorMessage) => {
    var lines = errorMessage;
    var br = lines.map(function (line) {
      return (
        <div className="alert alert-danger" role="alert">
          <span>
            {line}
            <br />
          </span>
        </div>
      );
    });
    return <div>{br}</div>;
  };

  //ASSIGNED Users

  // selects a location from location list by index

  updateSelectedLocations = (array) => {
    this.setState({ selectedLocations: array });
  };

  updateSelectedAssignedLocations = (array) => {
    this.setState({ selectedAssignedLocations: array });
  };

  //updates theAddUsers list depending on the selected location and its users
  //   updateAssignedUsers = () => {
  //     this.state.assignedLocationsList = this.state.assignedLocations;
  //     this.setState({ addUsers: this.state.assignedLocationsList });

  //     //this.render();
  //   };

  //adds a valid selected user from users array to the addUsers list by appending locations.assignedUsers
  addLocation = () => {
    for (var location1 in this.state.selectedLocations) {
      var location = this.state.locations.find((location) => {
        return this.state.selectedLocations[location1] === location.id;
      });

      if (this.validateAssignLocations(location.id) === true) {
        if (this.state.group !== "Property Manager") {
          this.state.assignedLocations.push(location);
        } else if (
          this.state.group === "Property Manager" &&
          this.state.assignedLocations &&
          !this.state.assignedLocations.length >= 1
        ) {
          this.state.assignedLocations.push(location);
        } else if (this.state.group === "Property Manager") {
          this.setState({
            errorText: this.printError([
              "Property Managers cannot have more than 1 property assigned to them.",
            ]),
          });
        }
      }
    }
    this.setState({ assignedLocations: this.state.assignedLocations });
  };

  //adds a removes a user from locations.assignedUsers
  removeLocation = () => {
    var selectedAssignedLocations = Array.from(
      this.state.selectedAssignedLocations
    );
    var assignedLocations = this.state.assignedLocations;
    console.log(selectedAssignedLocations);
    for (var location1 in this.state.selectedAssignedLocations) {
      var location = this.state.assignedLocations.find((location) => {
        return selectedAssignedLocations[location1] === location.id;
      });
      console.log(location1);
      console.log(location);
      console.log(selectedAssignedLocations[location1]);

      var result = assignedLocations.find((assignedLocations) => {
        return assignedLocations === location;
      });
      console.log(result);

      if (result) {
        this.state.selectedAssignedLocations = selectedAssignedLocations.filter(
          (e) => e !== location.id
        );

        assignedLocations = assignedLocations.filter((e) => e !== result);
      }
    }
    this.state.selectedAssignedLocations = selectedAssignedLocations;

    this.setState({ assignedLocations });
  };

  //ASSIGNED USERS END

  render() {
    console.log("loaded");
    let user_name = "Yourself";
    if (this.state.user_full_name) {
      user_name = this.state.user_full_name;
    }
    return (
      <React.Fragment>
        <Header
          user_permissions={this.props.userData.data.permissions}
          user_portfolio_type={this.props.userData.data.portfolio_type}
          customstyle={this.props.userData.data.customstyle}
          organisation={this.props.userData.data.orgid}
          logout={() => Logout(this.props)}
        />

        <div className="addlocationform">
          <div className="addlocationheading">
            <h2>Reassign User Properties</h2>
            Reassigning for: {user_name}
          </div>

          <SingleUserLocationsAssign
            currentStep={this.state.currentStep}
            handleChange={this.handleChange}
            assignedLocations={this.state.assignedLocations}
            locations={this.state.locations}
            selectedLocations={this.state.selectedLocations}
            selectedAssignedLocations={this.state.selectedAssignedLocations}
            updateAssignedLocations={this.updateAssignedLocations}
            addLocation={this.addLocation}
            removeLocation={this.removeLocation}
            updateSelectedLocations={this.updateSelectedLocations}
            updateSelectedAssignedLocations={
              this.updateSelectedAssignedLocations
            }
          />

          <div className="navigation text-center d-block mt-5 w-100 clearfix">
            {this.nextButton}
          </div>

          <div>{this.state.errorText}</div>
        </div>
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  //console.log(state);
  const { userLogged, user, userData } = state;
  return {
    userLogged,
    user,
    userData,
  };
}
export default connect(mapStateToProps)(ReassignUserProperties);
