import { systemConstants } from "../_constants";
import axios from "axios";
import * as urls from "../../components/maps/urls";

export async function getEPC(location_id, secret) {
  let payload = null;

  const data = {
    params: {
      propid: location_id,
    },
  };

  await axios
    .get(urls.API_GATEWAY + "locations/property-epc", data)
    .then((res) => {
      if (res.data === "Invalid Details") {
        return null;
      }
      payload = res.data;
    })
    .catch((err) => {
      console.log(err);
    });

  console.log("EPC PAYLOAD");
  console.log(payload);
  return payload;
}
