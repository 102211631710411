import React from "react";
import axios from "axios";
import * as urls from "../maps/urls";
import DatePicker from "react-datepicker";
import uniqueId from "lodash.uniqueid";
import "./addmitigation.css";
import moment from "moment";

export class AddMitigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mitigationname: null,
      mitigatingagainst: null,
      mitigationdescription: null,
      uploadFiles: null,
      files: [],
      propid: this.props.propid,
      success: null,
      mitigationDate: new Date(),
      errorMessage: [],
    };

    this.handleUpload = this.handleUpload.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
  }

  handleDateChange(date) {
    this.setState({
      mitigationDate: date,
    });
  }

  handleFile(e) {
    console.log("adding file");
    console.log(e.target.files[0]);
    let fail = false;

    let uploadfile = e.target.files[0];
    console.log(uploadfile);
    if (uploadfile) {
      let files = this.state.files;
      if (files && files.length > 0) {
        for (var i = 0; i < files.length; i += 1) {
          if (files[i].name === uploadfile.name) {
            fail = true;
          }
        }
      }
      console.log(files);
      if (fail === false) {
        files.push(uploadfile);

        console.log(files);
        this.setState({ files: files });
      }
    } else {
      let validationerrors = [];

      validationerrors.push("File with this filename already added.");
      this.setState({ errorMessage: validationerrors });
    }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    console.log(e.target.value);
  };

  toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  async handleUpload(e) {
    if (this.validateMitigations()) {
      console.log(this.state.uploadFile, "THE Current State");
      console.log(this.state.files, "THE Current State");

      let failure = false;

      let uploadfiles = null;
      if (this.state.files && this.state.files.length > 0) {
        uploadfiles = [];
        for (let i = 0; i < this.state.files.length; i++) {
          // let uploadfile={}
          // uploadfile.data= await this.toBase64(this.state.files[i].data);
          let file1 = null;
          try {
            file1 = {};
            file1.data = await this.toBase64(this.state.files[i]);
            file1.name = this.state.files[i].name;
          } catch (error) {
            console.log(error);
            failure = true;
            // expected output: ReferenceError: nonExistentFunction is not defined
            // Note - error messages will vary depending on browser
            let validationerrors = [];

            validationerrors.push(
              "Error processing file " + this.state.files[i].name
            );
            this.setState({ errorMessage: validationerrors });
          }
          uploadfiles.push(file1);
        }
        console.log("setting up");
      }

      // let uploadFileName = null
      // if(this.state.uploadFile && this.state.uploadFile.name){
      //   console.log("setting up name")
      //   uploadFileName = this.state.uploadFile.name
      // }

      // console.log(uploadfile)
      if (failure === false) {
        let newdate = moment(this.state.mitigationDate).format(
          "YYYY-MM-DD HH:mm:ss"
        );

        let data = {
          mitigation_type: this.state.mitigationname,
          risk_mitigation_category: this.state.mitigatingagainst,
          description: this.state.mitigationdescription,
          installation_date: newdate,
          propid: this.state.propid,
          files: uploadfiles,
        };

        await axios({
          method: "post",
          url: urls.API_GATEWAY + "mitigations/add-mitigation",
          data: data,
        })
          .then((res) => {
            let result = res.data;
            console.log(result);

            if (
              result === "null" ||
              result === null ||
              result === "Failure" ||
              typeof result === "undefined"
            ) {
              result = null;
              console.log("Error creating mitigation document");

              this.state.errorMessage.push(
                "Failed to add mitigation to the database."
              );
            } else if (result === "Invalid Details") {
              console.log("Details for adding document are INVALID");
              result = null;

              this.state.errorMessage.push(
                "Failed to add mitigation to the database."
              );
            } else {
              console.log("SUCCESS!!");
              console.log("Mitigation successfully added to the database");
              this.props.updateMitigations();
              this.setState({ success: true });
            }

            if (result === null) {
              this.state.errorMessage.push(
                "Failed to add mitigation to the database."
              );
              this.setState({ success: false });
            }
          })
          .catch((err) => {
            this.state.errorMessage.push(
              "Failed to add mitigation to the database."
            );
            console.log(err);
            this.setState({ success: false });
          });
      }
    } else console.log("validation failed");
  }

  //Validates the form for adding mitigations - check if all fields are filled in
  validateMitigations() {
    let validate = true;
    let validationerrors = [];

    if (
      typeof this.state.mitigationname === "undefined" ||
      this.state.mitigationname < 1
    ) {
      validate = false;
      validationerrors.push("Mitigation name cannot be empty.\n");
    }

    if (
      typeof this.state.mitigatingagainst === "undefined" ||
      this.state.mitigatingagainst < 1
    ) {
      validate = false;
      validationerrors.push("Mitigating against cannot be empty.\n");
    }
    if (
      typeof this.state.mitigationdescription === "undefined" ||
      this.state.mitigationdescription < 1
    ) {
      validate = false;
      validationerrors.push("Mitigation description cannot be empty.\n");
    }
    // if (
    //   typeof this.state.mitigationDate === "undefined" ||
    //   this.state.mitigationDate < 1
    // ) {
    //   validate = false;
    //   this.state.errorMessage.push("Mitigation date cannot be empty.\n");
    // }
    this.setState({ errorMessage: validationerrors });

    if (validate === false) {
      this.setState({ success: false });
    }

    return validate;
  }

  showNotice() {
    if (this.state.success) {
      return (
        <div className="alert-success-small" role="alert">
          <strong>Success</strong> Mitigation has been recorded
        </div>
      );
    } else if (this.state.success === false) {
      return this.state.errorMessage.map((error) => (
        <div className="alert-danger-small" role="alert">
          {error}
        </div>
      ));
    }
  }

  removeFile(index) {
    if (this.state.files && this.state.files.length >= index) {
      let files = this.state.files;
      files.splice(index, 1);
      console.log("File removed at" + index);
      this.setState({ files });
    }
  }

  renderFiles() {
    return (
      <div className="form-group">
        <label for="FormControlFile1">Attach File</label>
        {this.state.files !== null && this.state.files.length > 0
          ? this.state.files.map((file, index) => (
              <div>
                <label for="mitigation_type">{file.name}</label>
                <button
                  type="button"
                  className="delete"
                  aria-label="Delete"
                  onClick={() => this.removeFile(index)}
                >
                  Remove
                </button>
              </div>
            ))
          : ""}

        {this.state.files !== null && this.state.files.length > 0 ? (
          <div>
            <label for="mitigation_type">Add another: </label>
            <input
              type="file"
              name="file"
              className="form-control-file"
              id={uniqueId("FormControlFile-")}
              onChange={(e) => this.handleFile(e)}
            />
          </div>
        ) : (
          <input
            type="file"
            name="file"
            className="form-control-file"
            id={uniqueId("FormControlFile1-")}
            onChange={(e) => this.handleFile(e)}
          />
        )}
      </div>
    );
  }

  render() {
    return (
      <form className="popup">
        <div className="formbackground">
          <div className="formcontent">
            <h2>Add New Mitigation</h2>
            <button
              type="button"
              className="close"
              aria-label="Close"
              className="close_cross_button"
              onClick={this.props.onClickClose}
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <div className="form-group">
              <label for="mitigation_type">Mitigation Type</label>
              <select
                className="form-control"
                name="mitigationname"
                id="mitigation_type"
                onChange={this.handleChange}
              >
                <option />
                <option value="1">Property Survey Report</option>
                <option value="2">Flood Barrier</option>
                <option value="3">Floodsax</option>
                <option value="4">Flood Response Plan</option>
                <option value="5">Technicial Response</option>
                <option value="6">Leak Detector</option>
                <option value="7">Roof Alarm</option>
                <option value="8">CCTV Camera</option>
                <option value="9">Risk Management Advice</option>
              </select>
            </div>

            <div className="form-group">
              <label for="mitigation_against">Mitigating Against</label>
              <select
                className="form-control"
                name="mitigatingagainst"
                id="mitigation_against"
                onChange={this.handleChange}
              >
                <option />

                <option value="1">Flood</option>
                <option value="2">Extreme Rainfall</option>
                <option value="3">Criminal Damage</option>
                <option value="4">Arson</option>
                <option value="5">Fire</option>
                <option value="6">COVID-19</option>
              </select>
            </div>
            <div className="form-group">
              <label for="FormControlInput1">Description</label>
              <input
                type="mitigation_description"
                name="mitigationdescription"
                className="form-control"
                id="FormControlInput1"
                onChange={this.handleChange}
                placeholder="eg: Property Survey Report by Balkerne"
                onChange={this.handleChange}
              />
            </div>
            <div className="form-group-date">
              <label for="FormControlInput1">Implementation Date</label>

              <DatePicker
                selected={this.state.mitigationDate}
                onChange={this.handleDateChange}
                dateFormat="yyyy-MM-dd"
              />
            </div>

            {this.renderFiles()}

            <div className="form-group">
              <div className="submitbutton">
                <button
                  type="button"
                  className="uploadfilesubmit"
                  onClick={(e) => this.handleUpload(e)}
                >
                  Submit
                </button>
              </div>
            </div>
            {this.showNotice()}
          </div>
        </div>
      </form>
    );
  }
}
export default AddMitigation;
