import React, { Component } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import AddIncident from "./addincident";
import TimePassed from "../general/TimePassed";
import IconButton from "../general/IconButton";
import c from "./TweetTrainingCard.module.scss";
import Card from "react-bootstrap/Card";
import PreTitle from "../general/PreTitle";

export class TweetTrainingCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tweet: this.props.full_tweet,
    };
  }

  markIrrelevant = () => {
    this.props.removeMedia(this.props.news_id);
  };

  renderSubCategories() {
    switch (this.state.tweet.category) {
      case "Crime":
        return (
          <div className="form-group row">
            {/* <label htmlFor="sub_category">Sub Category: </label> */}

            <select
              name="sub_category"
              className="sub_category"
              data-id={this.props.index}
              id={this.props.index}
            >
              <option disabled selected>
                Select Sub-Category
              </option>
              <option value="Assault">Assault</option>
              <option value="Murder">Murder</option>
              <option value="Knife Crime">Knife Crime</option>
              <option value="Gun Crime">Gun Crime</option>
              <option value="Armed Robbery">Armed Robbery</option>
              <option value="Motor Vehicle Crime">Motor Vehicle Crime</option>
              <option value="Fraud">Fraud</option>
              <option value="Modern Slavery">Modern Slavery</option>
              <option value="Drug Trafficking">Drug Trafficking</option>
              <option value="Terror Incident">Terror Incident</option>
              <option value="Property Damage">Property Damage</option>
              <option value="Residential Damage">Residential Damage</option>
              <option value="Motor Damage">Motor Damage</option>
              <option value="Driving Incident">Driving Incident</option>
              <option value="Burglary">Burglary</option>
              <option value="Commercial Burglary">Commercial Burglary</option>
              <option value="Robbery">Robbery</option>
              <option value="ATM Robbery">ATM Robbery</option>
              <option value="Theft from a Person">Theft from a Person</option>
              <option value="Motor Theft">Motor Theft</option>
              <option value="Bike Theft">Bike Theft</option>
              <option value="Shoplifting">Shoplifting</option>
              <option value="Sexual Offences">Sexual Offences</option>
              <option value="Kidnapping">Kidnapping</option>
              <option value="Vandalism / Criminal Damage">
                Vandalism / Criminal Damage
              </option>
              <option value="Anti-social behaviour">
                Anti-social behaviour
              </option>
              <option value="Gang Incident">Gang Incident</option>
              <option value="Riots">Riots</option>
              <option value="Roof Theft">Roof Theft</option>
            </select>
          </div>
        );
        break;
      case "Weather":
        return (
          <div className="form-group row">
            {/* <label htmlFor="sub_category">Sub Category: </label> */}

            <select
              name="sub_category"
              className="sub_category"
              data-id={this.props.index}
              id={this.props.index}
            >
              <option disabled selected>
                Select Sub-Category
              </option>
              <option value="Rainfall">Rainfall</option>
              <option value="Windstorm">Windstorm</option>
              <option value="Heatwave">Heatwave</option>
              <option value="Winter Weather">Winter Weather</option>
              <option value="Hailstorm">Hailstorm</option>
              <option value="Fog">Fog</option>
              <option value="Heavy Snow">Heavy Snow</option>
              <option value="Thunderstorm">Thunderstorm</option>
              <option value="Severe Subsidence">Severe Subsidence</option>
              <option value="Solar Flare">Solar Flare</option>
            </select>
          </div>
        );
        // code block
        break;
      case "Fire":
        return (
          <div className="form-group row">
            {/* <label htmlFor="sub_category">Sub Category: </label> */}

            <select
              name="sub_category"
              className="sub_category"
              data-id={this.props.index}
              id={this.props.index}
            >
              <option disabled selected>
                Select Sub-Category
              </option>
              <option value="Fire">Fire</option>
              <option value="Arson">Arson</option>
              <option value="Commercial Fire">Commercial Fire</option>
              <option value="Residential Fire">Residential Fire</option>
              <option value="Explosion">Explosion</option>
              <option value="Explosion Gas">Explosion Gas</option>
              <option value="Vehicle Fire">Vehicle Fire</option>
              <option value="False Fire Alarm">False Fire Alarm</option>
            </select>
          </div>
        );

        // code block
        break;
      case "Flood":
        return (
          <div className="form-group row">
            {/* <label htmlFor="sub_category">Sub Category: </label> */}

            <select
              name="sub_category"
              className="sub_category"
              data-id={this.props.index}
              id={this.props.index}
            >
              <option disabled selected>
                Select Sub-Category
              </option>
              <option value="Flood">Flood</option>
              <option value="Surface Water Flood">Surface Water Flood</option>
              <option value="River Flood">River Flood</option>
              <option value="Escape of Water Flood">
                Escape of Water Flood
              </option>
              <option value="Groundwater Flood">Groundwater Flood</option>
            </select>
          </div>
        );
        // code block
        break;
      case "Pandemic":
        return (
          <div className="form-group row">
            {/* <label htmlFor="sub_category">Sub Category: </label> */}

            <select
              name="sub_category"
              className="sub_category"
              data-id={this.props.index}
              id={this.props.index}
            >
              <option disabled selected>
                Select Sub-Category
              </option>
              <option value="Pandemic Breakout">Pandemic Breakout</option>
              <option value="Pandemic Penalty">Pandemic Penalty</option>
              <option value="Covid Penalty Notice">Covid Penalty Notice</option>
            </select>
          </div>
        );
        // code block
        break;
      case "Other":
        return (
          <div className="form-group row">
            {/* <label htmlFor="sub_category">Sub Category: </label> */}

            <select
              name="sub_category"
              className="sub_category"
              data-id={this.props.index}
              id={this.props.index}
            >
              <option disabled selected>
                Select Sub-Category
              </option>
              <option value="Vehicle Accident">Vehicle Accident</option>
              <option value="Train Accident">Train Accident</option>
              <option value="Plane Accident">Plane Accident</option>
              <option value="Natural Disaster">Natural Disaster</option>
              <option value="Environmental Incident">
                Environmental Incident
              </option>
            </select>
          </div>
        );
        // code block
        break;
      default:
        return "";
    }
  }

  handleChangeCheckbox = (event) => {
    let name = event.target.name;
    console.log(this.state.tweet[name]);
    if (this.state.tweet[name] === null) {
      let value = true;
      console.log(value);

      let tweet = this.state.tweet;
      tweet[name] = value;
      this.setState({ tweet });
    } else {
      let value = this.state.tweet[name] === true ? false : true;
      console.log(value);

      let tweet = this.state.tweet;
      tweet[name] = value;
      this.setState({ tweet });
    }

    this.props.updateTweet(this.props.id, this.state.tweet);
  };

  handleChange = (e) => {
    //   console.log(e.target.value)
    //   console.log(e.target.className)
    if (
      [
        "category",
        "sub_category",
        "location_type",
        "time_type",
        "important",
      ].includes(e.target.className)
    ) {
      let tweet = this.state.tweet;
      tweet[e.target.className] = e.target.value;
      this.setState({ tweet });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
    this.props.updateTweet(this.props.id, this.state.tweet);
  };

  render = () => (
    <div>
      <div className={c.card}>
        <div className={c.content}>
          <div>
            <p className={c.title}>{this.props.date}</p>
            <p>ID: {this.props.tweet_id}</p>
          </div>
          <p>
            <h4>{this.props.location_address}</h4>
          </p>

          <p className="pb-4 pt-4">
            <h4>{this.props.tweet}</h4>
          </p>

          <div className="d-flex justify-content-between pb-4">
            <div>
              <PreTitle>{"Location accuracy:"}</PreTitle>
              <h4>{this.props.loc_accuracy}</h4>
            </div>

            <div>
              <PreTitle>{"Score:"}</PreTitle>
              <h4>{this.props.score}</h4>
            </div>

            <div>
              <PreTitle>{"Query:"}</PreTitle>
              <h4>{this.props.query}</h4>
            </div>

            <div>
              <PreTitle>{"Geocoder:"}</PreTitle>
              <h4>{this.props.geocoder}</h4>
            </div>

            <div>
              <PreTitle>{"Username:"}</PreTitle>
              <h4>{this.props.username}</h4>
            </div>
          </div>

          <div className="d-flex justify-content-between">
            <div>
              <PreTitle>{"Category:"}</PreTitle>
              <h4>{this.props.category}</h4>
            </div>

            <div>
              <PreTitle>{"Roads:"}</PreTitle>
              <h4>{this.props.roads}</h4>
            </div>

            <div>
              <PreTitle>{"Towns:"}</PreTitle>
              <h4>{this.props.towns}</h4>
            </div>

            <div>
              <PreTitle>{"Entities:"}</PreTitle>
              <h4>{this.props.entities}</h4>
            </div>

            <div>
              <PreTitle>{"Text Dates:"}</PreTitle>
              <h4>{this.props.datetime}</h4>
            </div>
          </div>
        </div>
        <img className={c.img} src={this.props.image} />
        {/* {this.displayIncidentPopup()} */}
      </div>

      <form
        className="pb-5"
        name="my-form"
        type="button"
        onChange={this.handleChange}
      >
        <Card>
          <Card.Body>
            <div className="d-flex justify-content-between">
              <div>
                <PreTitle>{"Irrelevant: "}</PreTitle>
                <div className="pl-4 pt-2">
                  <input
                    name="irrelevant"
                    id="irrelevant"
                    type="checkbox"
                    onChange={this.handleChangeCheckbox}
                  />
                </div>
              </div>

              <div>
                <PreTitle>{"Irrelevant Geographically: "}</PreTitle>
                <div className="pl-5 pt-2">
                  <input
                    name="irrelevant_geo"
                    id="irrelevant_geo"
                    type="checkbox"
                    onChange={this.handleChangeCheckbox}
                  />
                </div>
              </div>

              <div>
                <PreTitle>{"Event Type: "}</PreTitle>
                <div className="pt-2">
                  <select
                    name="time_type"
                    className="time_type"
                    data-id={this.props.index}
                    id={this.props.index}
                  >
                    <option selected value="None">
                      Select Type
                    </option>
                    <option value="Current Event">Current Event</option>
                    <option value="Recent Event">Recent Event</option>
                    <option value="Older Event">Older Event</option>
                    <option value="Informational">Informational</option>
                    <option value="Notice">Notice</option>
                  </select>
                </div>
              </div>

              <div>
                <PreTitle>{"Category: "}</PreTitle>
                <div className="pt-2">
                  <select
                    name="Category"
                    className="category"
                    data-id={this.props.index}
                    id={this.props.index}
                  >
                    <option disabled selected>
                      Select Category
                    </option>
                    <option value="Crime">Crime</option>
                    <option value="Weather">Weather</option>
                    <option value="Fire">Fire</option>
                    <option value="Flood">Flood</option>
                    <option value="Pandemic">Pandemic</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
              </div>

              <div>
                <PreTitle>{"Sub Category: "}</PreTitle>
                <div className="pt-2">{this.renderSubCategories()}</div>
              </div>

              <div>
                <PreTitle>{"Location Type: "}</PreTitle>
                <div className="pt-2">
                  <select
                    name="location_type"
                    className="location_type"
                    data-id={this.props.index}
                    id={this.props.index}
                  >
                    <option selected value="None">
                      Select Location Type
                    </option>
                    <option value="Commercial">Commercial</option>
                    <option value="Residential">Residential</option>
                    <option value="Retail">Retail</option>
                    <option value="Hotel">Hotel</option>
                    <option value="Industrial">Industrial</option>
                    <option value="Assembly and Leisure">
                      Assembly and Leisure
                    </option>
                    <option value="Business">Business</option>
                    <option value="Healthcare">Healthcare</option>
                    <option value="Other Institutions">
                      Other Institutions
                    </option>
                  </select>
                </div>
              </div>

              <div>
                <PreTitle>{"Important: "}</PreTitle>
                <div className="pl-4 pt-2">
                  <input
                    name="important"
                    id="important"
                    type="checkbox"
                    onChange={this.handleChangeCheckbox}
                  />
                </div>
              </div>

              <div>
                <PreTitle>{"Duplicate: "}</PreTitle>
                <div className="pl-4 pt-2">
                  <input
                    name="duplicate"
                    id="duplicate"
                    type="checkbox"
                    onChange={this.handleChangeCheckbox}
                  />
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>

        {/* <div className="tweet_details">

            <div className="form-group row">
            <label htmlFor="getstartedrisks" >Irrelevant: </label>
            <input
            name="irrelevant"
            id="irrelevant"
            type="checkbox"
            // defaultChecked={this.state.orgData.flooding}
            onChange={this.handleChangeCheckbox} 
            />
            </div>

           

            <div className="form-group row">
            <label htmlFor="getstartedrisks" >Irrelevant Geographically: </label>
            <input
            name="irrelevant_geo"
            id="irrelevant_geo"
            type="checkbox"
            // defaultChecked={this.state.orgData.flooding}
            onChange={this.handleChangeCheckbox} 
            />
            </div>

            <div className="form-group row">
                <label htmlFor="Category"> Category: </label>
  
                <select name="Category" className="category" data-id={this.props.index} id={this.props.index}>
                  <option disabled selected>
                    Select Category
                  </option>
                  <option value="Crime">Crime</option>
                  <option value="Weather">Weather</option>
                  <option value="Fire">Fire</option>
                  <option value="Flood">Flood</option>
                  <option value="Pandemic">Pandemic</option>
                  <option value="Other">Other</option>
                </select>
              </div>

              
              
              
              <div className="form-group row">
                <label htmlFor="time_type">Event Type: </label>
  
                <select name="time_type" className="time_type" data-id={this.props.index} id={this.props.index}>
                  <option selected value="None">
                  Select Type
                  </option>
                  <option value="Current Event">Current Event</option>
                  <option value="Recent Event">Recent Event</option>
                  <option value="Older Event">Older Event</option>
                  <option value="Informational">Informational</option>
                  <option value="Notice">Notice</option>

                </select>
              </div>

              
            <div className="form-group row">
                <label htmlFor="location_type">Location Type: </label>
  
                <select name="location_type" className="location_type" data-id={this.props.index} id={this.props.index}>
                  <option selected value="None">
                    Select Location Type
                  </option>
                  <option value="Residential">Residential</option>
                  <option value="Commercial">Commercial</option>

                </select>
              </div>


              <div className="form-group row">
            <label htmlFor="getstartedrisks" >Important: </label>
            <input
            name="important"
            id="important"
            type="checkbox"
            // defaultChecked={this.state.orgData.flooding}
            onChange={this.handleChangeCheckbox} 
            />
            </div> */}
      </form>
    </div>
  );
}

export default TweetTrainingCard;
