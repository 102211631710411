import React from "react";
import ReactGA from "react-ga";
import ReactTooltip from "react-tooltip";
import "./userManagement/AssignUsers.css";
import "./Landingpagesearch.css";
import axios from "axios";
import * as urls from "./maps/urls";
import Autosuggest from "react-autosuggest";
import Loader from "./spinner";
import Button from "./general/Button";

export class LandingPageSearch extends React.Component {
  constructor(props) {
    super(props);
    //setting TEMPORARY STATE
    this.state = {
      locationsStrings: ["St Andrews Church", "University of Essex"],
      stores_list: this.props.locations,
      stores: this.props.locations,
      value: "",
      suggestions: [],
      last_suggestion_id: null,
      found: "",
      isLoading: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  // Teach Autosuggest how to calculate suggestions for any given input value.
  getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : this.props.locations.filter((store) => {
          if (store.name && store.property_customer_reference) {

            return (
              store.name.toLowerCase().includes(inputValue) ||
              store.property_customer_reference.toLowerCase().slice(0, inputLength) === inputValue
            );
          } else if (store.name) {
            return store.name.toLowerCase().includes(inputValue);
          }
        });
  };

  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion. Teach Autosuggest how to calculate the
  // input value for every given suggestion.
  getSuggestionValue = (suggestion) => {
    this.handleSubmit(suggestion.id);
    return suggestion.name;
  };

  // Use your imagination to render suggestions.
  renderSuggestion = (suggestion) => (
    <div>
      {suggestion.property_customer_reference !== null
        ? suggestion.property_customer_reference + "   " + suggestion.name
        : suggestion.name}
    </div>
  );

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
    });
  };

  // Autosuggest will call this function every time you need to update suggestions.
  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value),
    });
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    if (this.state.suggestions && this.state.suggestions.length > 0) {
      this.setState({ last_suggestion_id: this.state.suggestions[0].id });
    }

    this.setState({
      suggestions: [],
    });
  };

  //handles changes in serach string

  //gets the locations given a search string, if they exist, user gets redirected to that dashboard

  async handleSubmit(id1) {
    let id = id1;
    if (id === null) {
      //console.log(this.state.suggestions);
      if (this.state.last_suggestion_id) {
        id = this.state.last_suggestion_id;
      } else {
        console.log("Invalid Property");
      }
    }

    // let success = true;
    // let checked = false;

    if (id !== null) {
      let params = { propid: id, org_id: this.props.orgid };

      await axios({ method: "get", url: urls.API_GATEWAY + "locations/property-specific", params: params })
        .then((res) => {
          let locationData = res.data;
          console.log(locationData);

          if (locationData === "null" || locationData === null || typeof locationData === "undefined") {
            console.log("Error retrieving location");
            alert("Property accessing error.");
          } else if (locationData === "Invalid Details") {
            console.log("Details for Location retrieved are INVALID");
            alert("Property cannot be accessed.");
          } else if (locationData === "Property not found") {
            console.log("No Location was Found");
            alert("No property found.");
          } else {
            this.props.redirect(locationData, null);
          }
          this.setState({ isLoading: true });
        })
        .catch((err) => {
          this.state.locationData = null;
          console.log("FAILURE!!");
          console.log(err);
          if (!err.message) {
            console.log("Error when loading locations: ", err);
            //  Alert.alert('Error when signing in: ', err);
          } else {
            console.log("Error when loading locations: ", err.message);
          }
        });

      ReactGA.event({
        category: "LocationDashboard",
        action: "Viewed using search",
      });
    } else {
      console.log("ID of searched property is missing.");
    }
  }

  //render the component

  render() {
    const { value, suggestions } = this.state;

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      placeholder: "Enter Property Name",
      value,
      onChange: this.onChange,
    };

    if (this.isLoading) return <Loader />;

    return (
      <div>
        <div className="clearfix w-100 text-center">
          <div className="Portfoliosearch">
            <a id="refreshicon" data-tip data-for="refreshicontip">
              <span className="glyphicon glyphicon-refresh" onClick={this.props.refreshLocations} />
            </a>
            <h3 htmlFor="searchString "> Search Property </h3>

            <div className="search-input-area">
              <Autosuggest
                name="search"
                suggestions={suggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                getSuggestionValue={this.getSuggestionValue}
                renderSuggestion={this.renderSuggestion}
                inputProps={inputProps}
                className="autosuggest-dialog"
              />

              <Button onClick={() => this.handleSubmit(null)} style={this.props.customstyle}>
                Search
              </Button>

              <Button id="viewallbutton" onClick={this.props.onClickLocationList} style={this.props.customstyle}>
                View all
              </Button>
            </div>
          </div>
        </div>
        <ReactTooltip id="refreshicontip" place="left" aria-haspopup="true" role="example">
          <p>Refresh search list of properties</p>
        </ReactTooltip>
      </div>
    );
  }
}
export default LandingPageSearch;
