import React from "react";
import "./DisplayCovid.css";
import { Riskrating } from "../maps/riskrating";
import ReactTooltip from "react-tooltip";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import moment from "moment";

export class DisplayCovid extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    console.log("COVID : PROPS ", this.props);

    return (
      <div className="covidHighlight">
        <h3 className="covidHighlightsheading">
          {
            this.props.title + ": " + this.props.area
            // + "     Last updated on : "
            // +
          }
        </h3>
        <div className="covidriskcontent">
          <div className="covidriskdetailsection">
            <Riskrating
              title={"Cumulative COVID-19 cases per 100,000 population"}
              risk_rating={parseInt(this.props.riskrating)}
              max_score={this.props.max_score}
              rating_text={this.props.riskrating}
            />

            <p>updated at: {this.props.timeUpdated}</p>
            <div className="covid_risk_insight_text">
              <p>{this.props.p}</p>
            </div>
          </div>
          <div className="riskright">
            <h4>
              COVID-19 cases at {this.props.area} local authority in the past 14
              days
            </h4>

            <BarChart
              width={500}
              height={300}
              data={this.props.historic_data}
              margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
            >
              <XAxis
                dataKey="date"
                width={40}
                name="Time"
                tickFormatter={(unixTime) =>
                  moment(unixTime, "DD-MM-YY").format("DD-MMM")
                }
              />
              <YAxis />
              <Tooltip />
              <CartesianGrid stroke="#f5f5f5" />
              <Bar dataKey="cases" fill="#8884d8" />
            </BarChart>
          </div>
        </div>
      </div>
    );
  }
}
export default DisplayCovid;
