import React, { PureComponent } from "react";
import { GoogleMapMini } from "../maps/GoogleMapMini";
import * as styles from "../maps/styles";

import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Brush,
  Legend,
} from "recharts";
import moment from "moment";
import "./Rivergaugechart.css";

export default class Rivergaugechart extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      gaugename: this.props.gaugename,
      gaugelat: this.props.gaugelat,
      gaugelon: this.props.gaugelon,
      gaugereadings: this.props.gaugereadings,
    };
  }

  showGauge() {
    if (this.props.gaugemax !== null)
      return (
        <div className="Rivergauge">
          <h3>River Gauge Level : {this.state.gaugename}</h3>

          <div className="Rivergaugeobjects">
            <div className="Gaugemap">
              <h4>River Gauge Location : </h4>
              <GoogleMapMini
                id={"mapDashboard" + String(this.props.id)}
                className="map"
                options={{
                  zoom: 15,
                  tilt: 0,
                  center: {
                    lat: this.state.gaugelat,
                    lng: this.state.gaugelon,
                  },
                  styles: styles.maps.mono,
                  disableDefaultUI: true,
                  zoomControl: true,
                  mapTypeControl: true,
                  fullscreenControl: true,
                  styles: [
                    {
                      featureType: "poi",
                      elementType: "labels",
                      stylers: [
                        {
                          visibility: "simplified",
                        },
                      ],
                    },
                  ],
                }}
                apiKey="AIzaSyD9n6ddk1Z6E2L5hZqCVRo94I8lbHjm30Q"
                lat={this.state.gaugelat}
                lon={this.state.gaugelon}
              />
            </div>
            <div className="gaugetext">
              <p>
                <b>The typical river level at {this.state.gaugename}</b> during
                normal weather conditions is between {this.props.gaugemin}m and{" "}
                {this.props.gaugetypicalmax}m. It has been between these levels
                for at least 150 days in the past year.
              </p>
              <p>
                <b>
                  The highest level ever recorded at {this.state.gaugename}{" "}
                </b>
                is {this.props.gaugemax}m
              </p>
            </div>
            <div>{this.plotAreaChart()}</div>
          </div>
        </div>
      );
  }

  plotAreaChart() {
    let chartheight = Math.round(this.props.gaugemax * 1.05);

    let newgaugereadings = this.state.gaugereadings;

    for (var i = 0; i < newgaugereadings.length; i++) {
      newgaugereadings[i].Highest_Level = this.props.gaugemax;
      newgaugereadings[i].Typical_High_Level = this.props.gaugetypicalmax;
      newgaugereadings[i].Minimum_Level = this.props.gaugemin;
    }

    return (
      <div className="Rivergaugechart">
        <AreaChart
          width={900}
          height={500}
          data={newgaugereadings}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="time_taken"
            name="Time"
            tickFormatter={(unixTime) =>
              moment(unixTime).format("DD-MMM HH:mm")
            }
          />
          <YAxis domain={[0, chartheight]} />
          <Tooltip />
          <Legend />

          <Brush dataKey="name" height={30} stroke="#8884d8" />
          <Area
            type="monotone"
            dataKey="Highest_Level"
            name="Highest River Level (m)"
            stroke="#b71540"
          />

          <Area
            type="monotone"
            dataKey="Typical_High_Level"
            name="Typical High River Level (m)"
            stroke="#e55039"
            fill="#dff9fb"
          />
          <Area
            type="monotone"
            dataKey="value"
            name="Current River Reading (m)"
            stroke="#4a69bd"
            fill="#f0932b"
          />
          {/* <Area type="monotone" dataKey="Minimum_Level" stroke="#6ab04c" fill="#0984e3" /> */}
          {/* <Area type="monotone" dataKey="flood_near_miss" stroke="#2d3436"strokeWidth={5}  fill="#2d3436"/> */}
        </AreaChart>
      </div>
    );
  }

  render() {
    return (
      <div>
        {
          //           console.log("gaugereadings below"),

          // console.log(this.props.gaugereadings),
          this.props.gaugereadings.length > 0 ? this.showGauge() : ""
        }
      </div>
    );
  }
}
