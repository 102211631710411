import React, { Component } from "react";
import ReactTooltip from "react-tooltip";
import c from "./SideNav.module.scss";
import cn from "classnames";

class SideNav extends Component {
  constructor(props) {
    super(props);
    this.sideRef = React.createRef();

    this.state = {
      fixRows: false,
      highlightRow: 1,
    };
  }

  componentDidMount = () => {
    window.addEventListener("scroll", () => {
      if (this.sideRef.current) {
        const topOffset = window.pageYOffset - this.sideRef.current.offsetTop;
        if (topOffset > 0 && !this.state.fixRows) {
          this.setState({ fixRows: true });
        } else if (topOffset < 0 && this.state.fixRows) {
          this.setState({ fixRows: false });
        }
      }
    });
  };

  changePage = (fn, i) => {
    this.setState({ highlightRow: i });
    fn();
  };

  render = () => (
    <div className={c.nav} style={this.props.customStyle} ref={this.sideRef}>
      <div className={cn(c.rows, { [c.fixRows]: this.state.fixRows })}>
        <SideItem
          highlight={this.state.highlightRow == 0}
          id="sidenav-alert"
          text="Alerts"
          tooltip="Displays live alert information from extreme weather, flooding, crime and fire"
          onClick={() => this.changePage(this.props.onClickAlert, 0)}
          icon={require("../../images/sidebar_icons/Alerts.svg")}
        />
        <SideItem
          highlight={this.state.highlightRow == 1}
          id="sidenav-insight"
          text="Risk Insights"
          tooltip="Displays long term risk insights from flooding, crime and fire"
          onClick={() => this.changePage(this.props.onClickRiskInsight, 1)}
          icon={require("../../images/sidebar_icons/Risk Insights.svg")}
        />
        <SideItem
          highlight={this.state.highlightRow == 2}
          id="sidenav-mitigation"
          text="Mitigation"
          tooltip="Displays current mitigation installed within premises"
          onClick={() => this.changePage(this.props.onClickMitigations, 2)}
          icon={require("../../images/sidebar_icons/Mitigation.svg")}
        />
        <SideItem
          highlight={this.state.highlightRow == 3}
          id="sidenav-integration"
          text="SMART Sensors"
          tooltip="Displays information from selected Internet of Things devices"
          onClick={() => this.changePage(this.props.onClickSmartIntegration, 3)}
          icon={require("../../images/sidebar_icons/Smart Integration.svg")}
        />
        <SideItem
          highlight={this.state.highlightRow == 4}
          id="sidenav-incident"
          text="Incident History"
          tooltip="Shows historic reports of incidents, near misses and actions taken on site"
          onClick={() => this.changePage(this.props.onClickIncidents, 4)}
          icon={require("../../images/sidebar_icons/Incident History.svg")}
        />
        <SideItem
          highlight={this.state.highlightRow == 5}
          id="sidenav-media"
          text="Media Activity"
          tooltip="Displays information from news and social media relevant to the property"
          onClick={() => this.changePage(this.props.onClickMedia, 5)}
          icon={require("../../images/sidebar_icons/Media Activity.svg")}
        />
      </div>
    </div>
  );
}

const SideItem = ({ id, text, tooltip, onClick, icon, highlight }) => (
  <div className={cn({ [c.highlight]: highlight })}>
    <a data-for={id} className={c.row} onClick={onClick} data-tip>
      <img src={icon} className={c.icon} />
      {text}
    </a>
    <ReactTooltip
      id={id}
      aria-haspopup="true"
      role="example"
      type="info"
      place="right"
      delayShow={1000}
      effect="solid"
    >
      <p>{tooltip}</p>
    </ReactTooltip>
  </div>
);

export default SideNav;
