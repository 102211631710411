import React, { useEffect } from 'react'
import Table from 'react-bootstrap/Table'
import Search from 'balkerne-components/Search'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import ProgressBar from 'react-bootstrap/ProgressBar'
import { Tabs, Tab } from 'react-bootstrap'
import { useState } from 'react'
import axios from 'axios'
import { useHistory, useLocation } from 'react-router-dom'
import c from './Overlay.module.scss'
import cn from 'classnames'
import i from './TemplateManager.module.scss'
import Form from '@rjsf/core'
import FileWidget from '../widgets/FileWidget'
import DateTimeWidget from '../widgets/DateTimeWidget'
import ReactTooltip from 'react-tooltip'

export const TemplateManager = ({ ...props }) => {
  let history = useHistory()
  let location = useLocation()

  const [sortedColumn, setSortedColumn] = useState(-1)
  const [alertDisplay, setAlertDisplay] = useState('none')
  const [alertMessage, setAlertMessage] = useState('')
  const [alertType, setAlertType] = useState('')
  const [balkerneAdminDisplay, setBalkerneAdminDisplay] = useState('none')
  const [balkerneAdminDisplayCells, setBalkerneAdminDisplayCells] = useState('none')
  const [templatesListDisplay, setTemplatesListDisplay] = useState('block')
  const [templateDetailDisplay, setTemplateDetailDisplay] = useState('none')
  const [globalTemplatePreviewDisplay, setGlobalTemplatePreviewDisplay] = useState('none')
  const [globalTemplatesListDisplay, setGlobalTemplatesListDisplay] = useState('block')
  const [previewTemplateID, setPreviewTemplateID] = useState(-1)
  const [preview_schema, setPreview_Schema] = useState('{}')
  const [preview_uischema, setPreview_UiSchema] = useState('{}')
  const [completedforms, setCompletedForms] = useState([])
  const [globalTemplates, setGlobalTemplates] = useState([])
  const [globalTemplates2, setGlobalTemplates2] = useState([])
  const [templateVersions, setTemplateVersions] = useState([])
  const [archivedVersions, setArchivedVersions] = useState([])
  const [templatesObj, setTemplatesObj] = useState({})
  const [selectedTemplate, setSelectedTemplate] = useState({})
  const [categoriesObj, setCategoriesObj] = useState({})
  const [subCategoriesObj, setSubCategoriesObj] = useState({})
  const [initForms, setInitForms] = useState(false)
  const [filterBy, setFilterBy] = useState('')
  const [filterdisabled, setFilterDisabled] = useState(true)
  const baseurl = 'https://r8fd6rsk66.execute-api.eu-west-1.amazonaws.com/Version1'
  const [displayTemplate, setDisplayTemplate] = useState('')
  const [selectedVersion, setSelectedVersion] = useState(-1)
  const [selectedVersionObj, setSelectedVersionObj] = useState({})
  const [progressBarValue, setProgressBarValue] = useState(0)
  const [progressBarDisplay, setProgressBarDisplay] = useState('flex')
  const [globalTemplateVersions, setGlobalTemplateVersions] = useState({})
  const [viewGlobalTemplate, setViewGlobalTemplate] = useState(-1)

  const templateTypes = {
    2: 'Incident',
    3: 'Survey',
  }
  let examineFlag = true

  const overlayclass = cn([c.overlay])

  //console.log(props.dashboardData)
  /*console.log(props.userData) */
  useEffect(() => {
    let isSubscribed = true
    if (isSubscribed) {
      if (viewGlobalTemplate != -1) {
      }
    }
    return () => (isSubscribed = false)
  }, [viewGlobalTemplate])
  useEffect(() => {
    let isSubscribed = true
    if (isSubscribed) {
      if (props.userData != undefined) {
        if (props.userData['data']['organisation']['id'] != null) {
          if (props.userData['data']['organisation']['id'] == 4) {
            console.log('test')
            // Balkernes ID
            setBalkerneAdminDisplayCells('table-cell')
            setBalkerneAdminDisplay('block')
          }
        }
      }
      // Store categories and subcategories inside object
      getCategories().then(categories => {
        // console.log(categories)
        var tempCategories = {}
        var tempSubCategories = {}

        categories.map(category => {
          tempCategories[category.id] = category.title
          category.subcategories.map(subcategory => {
            tempSubCategories[subcategory.id] = subcategory.title
          })
        })
        setProgressBarValue(82)
        setCategoriesObj(tempCategories)
        setSubCategoriesObj(tempSubCategories)
      })
    }
    return () => (isSubscribed = false)
  }, [])
  var globalArray = []
  var globalVersions = {}
  useEffect(() => {
    let isSubscribed = true

    if (isSubscribed) {
      var tempObj = {}
      var tempArray = []
      var tempDeletedArray = []
      getCompletedForms()
        .then(data => {
          // Create dictionary of forms using id as key
          data.map(template_principle => {
            tempObj[template_principle.id] = template_principle
            if (template_principle['is_active'] == false) {
              tempDeletedArray.push(template_principle)
            } else {
              if (template_principle['organisation_id'] != 4) {
                tempArray.push(template_principle)
              } else {
                globalArray.push(template_principle)
              }
            }
          })
        })
        .then(() => {
          console.log('Loop done')
          console.log(globalArray)
          console.log(tempDeletedArray)
          setGlobalTemplates(globalArray.reverse())
          setCompletedForms(tempArray.reverse())
          setTemplatesObj(tempObj)
        })
    }
    return () => (isSubscribed = false)
  }, [])
  var testVersions = {}
  var testTemplates = []
  useEffect(() => {
    let isSubscribed = true
    var globalArray2 = []
    var flag = false
    if (isSubscribed) {
      if (globalTemplates.length > 0) {
        if (flag == false) {
          if (props.userData != undefined) {
            if (props.userData['data']['organisation']['id'] != null) {
              if (props.userData['data']['organisation']['id'] != 4) {
                var counter = 1
                globalTemplates.map(global_template => {
                  var tempTemplate
                  var tempVersion
                  getTemplateVersions(global_template['id'])
                    .then(versions => {
                      //console.log(versions)
                      versions.map(version => {
                        if (global_template['latest_version'] == version['version']) {
                          if (version['published'] == true) {
                            //console.log('test')
                            tempTemplate = global_template
                            tempVersion = version
                            //console.log(globalArray2)
                          }
                        }
                      })
                    })
                    .then(() => {
                      //console.log(counter)
                      if (tempVersion != undefined) {
                        testVersions[global_template['id']] = tempVersion
                        //console.log('--- Version ---')
                        //console.log(testVersions)
                      }
                      if (tempTemplate != undefined) {
                        testTemplates.push(tempTemplate)
                        //console.log('--- Template --- ')
                        //console.log(testTemplates)
                      }

                      if (globalTemplates.length == counter) {
                        flag = true
                        //console.log('testing')
                        setGlobalTemplates2(testTemplates.reverse())
                        setGlobalTemplateVersions({ ...testVersions })
                      }
                      counter += 1
                    })
                })
              }
            }
          }
        }
      }
    }
    return () => (isSubscribed = false)
  }, [globalTemplates])
  useEffect(() => {
    let isSubscribed = true
    console.log(location.state)
    if (props.viewingTemplate != undefined) {
      console.log('test processing viewing template')
      console.log(location.state['viewingTemplate'])
      if (location.state['viewingTemplate'] != undefined) {
        console.log('test2')

        setSelectedTemplate(props.viewingTemplate)
        getTemplateVersions(props.viewingTemplate['id']).then(versions => {
          versions.sort((a, b) => {
            return parseInt(b['version'] - parseInt(a['version']))
          })
          setTemplateVersions(versions)
          var tempArchived = []
          versions.map(version => {
            if (version['expired'] == true) {
              // Archived version
              tempArchived.push(version)
            }
            if (props.viewingTemplate['latest_version'] == version['version']) {
              if (version['published'] == true) {
                document.getElementById('published_state_text').style.color = '#43b31f'
                document.getElementById('published_state_text').innerHTML = 'Published'
                document.getElementById('publish_button_text').innerHTML = 'Unpublish'
              } else {
                document.getElementById('published_state_text').innerHTML = 'Not Published'
                document.getElementById('published_state_text').style.color = '#FFBF00'
              }
            }
          })
          setArchivedVersions(tempArchived)
          console.log(versions)
        })
      }
    }
    return () => (isSubscribed = false)
  }, [completedforms])
  useEffect(() => {
    let isSubscribed = true
    if (isSubscribed) {
      // Adding a margin-right to all radio buttons
      const radioelements = Array.from(document.getElementsByClassName('field-radio-group'))
      const checkboxelements = Array.from(document.getElementsByClassName('field-boolean'))
      const selectelements = Array.from(document.getElementsByTagName('select'))

      radioelements.map(radioelement => {
        Array.from(radioelement.children).map(children => {
          Array.from(children.getElementsByTagName('input')).map(input => {
            input.style = 'margin-right: 5px'
          })
        })
      })
      // Adding a margin-right to all checkboxes
      checkboxelements.map(checkboxelement => {
        Array.from(checkboxelement.children).map(children => {
          Array.from(children.getElementsByTagName('input')).map(input => {
            input.style = 'margin-right: 10px'
          })
        })
      })
      // Map through each select element and set their width and margins
      selectelements.map(selectelement => {
        selectelement.style = 'margin-right: auto; margin-left: auto; width: 83.5%;'
      })
    }
    return () => (isSubscribed = false)
  }, [preview_schema, preview_uischema])
  useEffect(() => {
    let isSubscribed = true
    if (isSubscribed) {
      console.log(templateVersions)
      if (templateVersions.length > 0 || archivedVersions.length > 0) {
        setTemplatesListDisplay('none')
        setTemplateDetailDisplay('block')
      }
      /* if (templateVersions.length > 0) {
        /* 
        console.log(selectedTemplate['latest_version'])
        Array.from(document.getElementById('template_versions_list').rows).map(versionrow => {
          if (versionrow.cells[0].textContent == selectedTemplate['latest_version']) {
            console.log("found latest version")
            console.log(versionrow)
            versionrow.style.backgroundColor = 'green'
          }
        })
         
      } */
    }
    return () => (isSubscribed = false)
  }, [templateVersions])
  useEffect(() => {
    let isSubscribed = true
    if (isSubscribed) {
      var editButton1 = document.getElementById('version_action_edit')
      var activeButton1 = document.getElementById('version_action_active')
      var archiveButton1 = document.getElementById('version_action_archive')

      if (Object.keys(selectedVersionObj).length > 0) {
        console.log(selectedVersionObj)

        editButton1.className = i.option
        activeButton1.className = i.option
        archiveButton1.className = i.option
      } else {
        editButton1.className = i.buttonDisabled
        activeButton1.className = i.buttonDisabled
        archiveButton1.className = i.buttonDisabled
      }
    }
    return () => (isSubscribed = false)
  }, [selectedVersionObj])

  const getCompletedForms = async () => {
    if (props.userData != undefined) {
      if (props.userData['data']['organisation']['id'] != null) {
        const organisation_id = props.userData['data']['organisation']['id']
        const path = '/forms/templates?organisation_id=' + organisation_id
        const fetchurl = baseurl + path
        var data

        await axios.get(fetchurl).then(response => {
          console.log(response)
          data = response.data.data

          data.sort((a, b) => {
            return parseInt(a['id'] - parseInt(b['id']))
          })
          setProgressBarValue(100)
        })
        console.log(data)
        return data
      }
    }
  }

  const getCategories = async () => {
    const path = '/forms/categories'
    const fetchurl = baseurl + path
    var data
    setProgressBarValue(25)

    await axios.get(fetchurl).then(response => {
      data = response.data.data
      setProgressBarValue(66)
    })
    return data
  }
  useEffect(() => {
    let isSubscribed = true
    if (isSubscribed) {
      if (progressBarValue == 100) {
        setProgressBarDisplay('none')
      }
    }
    return () => (isSubscribed = false)
  }, [progressBarValue])

  /* Search function ends here*/

  const getTemplateVersions = async template_id => {
    const path = '/forms/templates?template_id='
    const fetchurl = baseurl + path + template_id
    var data
    await axios.get(fetchurl).then(response => {
      data = response.data.data
    })
    return data
  }
  const clickTemplateRow = template_id => {
    console.log(`Clicked row template-${template_id}`)
    console.log(`Object details :`)
    console.log(templatesObj[template_id])
    setSelectedTemplate(templatesObj[template_id])
    getTemplateVersions(template_id).then(versions => {
      versions.sort((a, b) => {
        return parseInt(b['version']) - parseInt(a['version'])
      })
      console.log(versions)
      var tempArchived = []
      // get active_version from template obj
      const active_version = templatesObj[template_id]['latest_version']
      console.log(active_version)
      var new_versions = []
      versions.map(version => {
        if (version['expired'] == true) {
          // Archived version
          tempArchived.push(version)
        } else {
          new_versions.push(version)
        }
        if (active_version == version['version']) {
          if (version['published'] == true) {
            console.log('Latest version is published')
            document.getElementById('publish_button_text').innerHTML = 'Unpublish'
            document.getElementById('published_state_text').innerHTML = 'Published'
            document.getElementById('published_state_text').style.color = '#43b31f'
          } else {
            document.getElementById('publish_button_text').innerHTML = 'Publish'
            document.getElementById('published_state_text').innerHTML = 'Not Published'
            document.getElementById('published_state_text').style.color = '#FFBF00'
          }
        }
      })
      setTemplateVersions(new_versions)
      setArchivedVersions(tempArchived)
    })
  }
  const clickTemplateRowTest = (template_id, data) => {
    console.log(`Clicked row template-${template_id}`)
    console.log(`Object details :`)
    console.log(data)
    setSelectedTemplate(data)
    getTemplateVersions(template_id).then(versions => {
      versions.sort((a, b) => {
        return parseInt(b['version']) - parseInt(a['version'])
      })
      console.log(versions)
      var tempArchived = []
      // get active_version from template obj
      const active_version = data['latest_version']
      console.log(active_version)
      var new_versions = []
      versions.map(version => {
        if (version['expired'] == true) {
          // Archived version
          tempArchived.push(version)
        } else {
          new_versions.push(version)
        }
        if (active_version == version['version']) {
          if (version['published'] == true) {
            console.log('Latest version is published')
            document.getElementById('publish_button_text').innerHTML = 'Unpublish'
            document.getElementById('published_state_text').innerHTML = 'Published'
            document.getElementById('published_state_text').style.color = '#43b31f'
          } else {
            document.getElementById('publish_button_text').innerHTML = 'Publish'
            document.getElementById('published_state_text').innerHTML = 'Not Published'
            document.getElementById('published_state_text').style.color = '#FFBF00'
          }
        }
      })
      setTemplateVersions(new_versions)
      setArchivedVersions(tempArchived)
    })
  }
  const previewGlobalTemplate = template_id => {
    setPreviewTemplateID(template_id)
    setTemplatesListDisplay('none')
  }
  useEffect(() => {
    let isSubscribed = true
    if (isSubscribed) {
      /* getTemplateVersions(previewTemplateID).then(versions => {
        versions.map(version => {
          if (templatesObj[previewTemplateID]['latest_version'] == version['version']) {
            setGlobalTemplatePreviewDisplay('block')
            setGlobalTemplatesListDisplay('none')
            setPreview_Schema(JSON.parse(version['template']))
            setPreview_UiSchema(JSON.parse(version['ui_schema']))
          }

        })
      }) */
      if (previewTemplateID != -1) {
        setGlobalTemplatePreviewDisplay('block')
        setGlobalTemplatesListDisplay('none')
        setPreview_Schema(JSON.parse(globalTemplateVersions[previewTemplateID]['template']))
        setPreview_UiSchema(JSON.parse(globalTemplateVersions[previewTemplateID]['ui_schema']))
      }
    }
    return () => (isSubscribed = false)
  }, [previewTemplateID])
  const backButton = () => {
    console.log(props.viewingTemplate)
    if (props.viewingTemplate != undefined) {
      console.log(location.state)
      location.state = {}
      console.log(location.state)
      props.viewingTemplate = undefined
      setSelectedTemplate({})

      history.replace()
      getCompletedForms()
      setTemplatesListDisplay('block')
      setTemplateDetailDisplay('none')
      if (selectedVersion != -1) {
        document.getElementById('version-' + selectedVersion).style.backgroundColor = 'white'
        setSelectedVersion(-1)
        setSelectedVersionObj({})
      }
      //history.go(0)
    } else {
      history.replace()
      setTemplatesListDisplay('block')
      setTemplateDetailDisplay('none')
      setSelectedTemplate({})
      if (selectedVersion != -1) {
        document.getElementById('version-' + selectedVersion).style.backgroundColor = 'white'
        setSelectedVersion(-1)
        setSelectedVersionObj({})
      }
    }
    document.getElementById('published_state_text').innerHTML = ''

    //console.log(props.viewingTemplate)
  }
  const selectVersionRow = version_id => {
    console.log('Selected row : ' + version_id)
    var versionRow = document.getElementById('version-' + version_id)
    if (selectedVersion == version_id) {
      versionRow.style.backgroundColor = 'white'
      setSelectedVersion(-1)
      setSelectedVersionObj({})
    } else if (selectedVersion == -1) {
      setSelectedVersion(version_id)
      versionRow.style.backgroundColor = 'rgba(142, 202, 250, 0.46)'
      var expired = versionRow.cells[7].textContent == 'True' ? true : false
      var published = versionRow.cells[4].textContent == 'True' ? true : false
      setSelectedVersionObj({
        version: versionRow.cells[0].textContent,
        description: versionRow.cells[1].textContent,
        created_at: versionRow.cells[2].textContent,
        updated_at: versionRow.cells[3].textContent,
        published: published,
        template: versionRow.cells[5].textContent,
        ui_schema: versionRow.cells[6].textContent,
        expired: expired,
        template_id: versionRow.cells[8].textContent,
        id: versionRow.cells[9].textContent,
      })
    } else if (selectedVersion != -1) {
      document.getElementById('version-' + selectedVersion).style.backgroundColor = 'white'
      versionRow.style.backgroundColor = 'rgba(142, 202, 250, 0.46)'
      var expired = versionRow.cells[7].textContent == 'True' ? true : false
      var published = versionRow.cells[4].textContent == 'True' ? true : false

      setSelectedVersionObj({
        version: versionRow.cells[0].textContent,
        description: versionRow.cells[1].textContent,
        created_at: versionRow.cells[2].textContent,
        updated_at: versionRow.cells[3].textContent,
        published: published,
        template: versionRow.cells[5].textContent,
        ui_schema: versionRow.cells[6].textContent,
        expired: expired,
        template_id: versionRow.cells[8].textContent,
        id: versionRow.cells[9].textContent,
      })
      setSelectedVersion(version_id)
    }
  }
  const newTemplate = () => {
    history.push({
      pathname: '/AdminTool',
    })
  }

  const newTemplateVersion = () => {
    console.log('Creating new template version')
    const is_published = document.getElementById('published_state_text').textContent
    var temp_published
    if (is_published == 'Published') {
      temp_published = true
    } else {
      temp_published = false
    }
    history.push({
      pathname: '/AdminTool',
      state: {
        createMode: 'version',
        editMode: 'template',
        templateObj: selectedTemplate,
        versionSum: document.getElementById('template_versions_list').rows.length,
        is_published: temp_published,
      },
    })
  }
  const editTemplate = () => {
    console.log('Editing template : ')
    console.log(selectedTemplate)
    history.push({
      pathname: '/AdminTool',
      state: {
        editMode: 'template',
        templateObj: selectedTemplate,
      },
    })
  }
  const editTemplateVersion = e => {
    const is_published = document.getElementById('published_state_text').textContent
    var temp_published
    if (is_published == 'Published') {
      temp_published = true
    } else {
      temp_published = false
    }
    if (e.target.parentElement.className == i.option) {
      console.log('Editing template version : ')
      console.log(selectedTemplate)
      history.push({
        pathname: '/AdminTool',
        state: {
          editMode: 'version',
          templateObj: selectedTemplate,
          selectedVersion: selectedVersionObj,
          is_published: temp_published,
        },
      })
    }
  }
  const setActiveVersion = async e => {
    if (e.target.parentElement.className == i.option) {
      console.log('Activating template version : ')
      console.log(selectedTemplate)

      if (selectedVersionObj['version'] != selectedTemplate['latest_version']) {
        disablePublishedVersion().then(async () => {
          //document.getElementById('version-'+selectedVersionObj['id']).cells[10].innerHTML = 'Active'
          const path = '/forms/templates/' + selectedTemplate['id']
          const fetchurl = baseurl + path
          console.log(fetchurl)
          const data = {
            latest_version: selectedVersionObj['version'],
          }

          console.log('Setting template version as latest_version: ')
          console.log(data)

          await axios
            .put(fetchurl, data)
            .then(response => {
              console.log(response)

              console.log('Updating local template instance')
              var tempObj = { ...selectedTemplate }
              tempObj['latest_version'] = selectedVersionObj['version']
              setSelectedTemplate({ ...tempObj })

              setAlertDisplay('block')
              setAlertMessage('Version ' + selectedVersionObj['version'] + ' is now set as the active version.')
              setAlertType('alert alert-success alert-dismissible fade show')
            })
            .catch(error => {
              console.error('Error saving template version changes: ', error, error.stack)
              setAlertDisplay('block')
              setAlertMessage('Error setting active version.')
              setAlertType('alert alert-danger alert-dismissible fade show')
            })
        })
      }
    }
  }
  const disablePublishedVersion = async () => {
    if (selectedTemplate['latest_version'] != undefined) {
      var set_published
      var version_id
      var version_version

      Array.from(document.getElementById('template_versions_list').rows).map(version_row => {
        if (version_row.cells[0].textContent == selectedTemplate['latest_version']) {
          if (version_row.cells[4].textContent == 'True') {
            version_id = version_row.cells[9].textContent
            //document.getElementById('version-' + version_id).style.backgroundColor = 'white'
            version_version = version_row.cells[0].textContent
            document.getElementById('publish_button_text').innerHTML = 'Publish'
            set_published = false
          }
        }
      })
      if (version_version != null) {
        const path = '/forms/templates/' + selectedTemplate['id'] + '?version=' + version_version
        const fetchurl = baseurl + path
        console.log(fetchurl)
        const data = {
          published: set_published,
        }
        console.log('Setting template version with id ' + version_id + ' as published: ')
        console.log(data)
        await axios
          .put(fetchurl, data)
          .then(response => {
            console.log(response)

            console.log('Updating local template version instance')
            document.getElementById('version-' + version_id).cells[4].innerHTML =
              set_published == true ? 'True' : 'False'
            document.getElementById('published_state_text').innerHTML = 'Not Published'
            document.getElementById('published_state_text').style.color = '#FFBF00'
          })
          .catch(error => {
            console.error('Error saving template version changes: ', error, error.stack)
          })
      }
    }
  }
  const publishTemplate = async e => {
    setSelectedVersion(-1)
    setSelectedVersionObj({})
    if (selectedTemplate['latest_version'] != undefined) {
      var set_published
      var version_id
      var version_version

      Array.from(document.getElementById('template_versions_list').rows).map(version_row => {
        if (version_row.cells[0].textContent == selectedTemplate['latest_version']) {
          version_id = version_row.cells[9].textContent
          document.getElementById('version-' + version_id).style.backgroundColor = 'white'
          version_version = version_row.cells[0].textContent

          if (version_row.cells[4].textContent == 'True') {
            set_published = false
            document.getElementById('publish_button_text').innerHTML = 'Publish'
            document.getElementById('published_state_text').innerHTML = 'Not Published'
            document.getElementById('published_state_text').style.color = '#FFBF00'
          } else {
            set_published = true
            document.getElementById('publish_button_text').innerHTML = 'Unpublish'
            document.getElementById('published_state_text').innerHTML = 'Published'
            document.getElementById('published_state_text').style.color = '#43b31f'
          }
        }
      })
      if (version_version != null) {
        const path = '/forms/templates/' + selectedTemplate['id'] + '?version=' + version_version
        const fetchurl = baseurl + path
        console.log(fetchurl)
        const data = {
          published: set_published,
        }
        console.log('Setting template version with id ' + version_id + ' as published: ')
        console.log(data)
        await axios
          .put(fetchurl, data)
          .then(response => {
            console.log(response)

            console.log('Updating local template version instance')
            document.getElementById('version-' + version_id).cells[4].innerHTML =
              set_published == true ? 'True' : 'False'

            if (set_published) {
              setAlertDisplay('block')
              setAlertMessage('Template sucessfully published.')
              setAlertType('alert alert-success alert-dismissible fade show')
            } else {
              setAlertDisplay('block')
              setAlertMessage('Template is no longer published.')
              setAlertType('alert alert-warning alert-dismissible fade show')
            }
          })
          .catch(error => {
            console.error('Error saving template version changes: ', error, error.stack)
            setAlertDisplay('block')
            setAlertMessage('Error publishing template.')
            setAlertType('alert alert-danger alert-dismissible fade show')
          })
      }
    }
  }
  const publishVersion = async e => {
    if (e.target.className == i.option) {
      console.log('Publishing template version : ')
      console.log(selectedTemplate)

      if (selectedVersionObj['published'] == false) {
        const path = '/forms/templates/' + selectedTemplate['id'] + '?version=' + selectedVersionObj['version']
        const fetchurl = baseurl + path
        console.log(fetchurl)
        const data = {
          published: true,
        }

        console.log('Setting template version as published: ')
        console.log(data)

        await axios
          .put(fetchurl, data)
          .then(response => {
            console.log(response)

            console.log('Updating local template version instance')
            var tempObj = { ...selectedVersionObj }
            tempObj['published'] = true
            setSelectedVersionObj({ ...tempObj })
            document.getElementById('version-' + selectedVersionObj['id']).cells[4].innerHTML = 'True'
          })
          .catch(error => {
            console.error('Error saving template version changes: ', error, error.stack)
          })
      }
    }
  }
  const archiveVersion = async e => {
    if (e.target.parentElement.className == i.option) {
      if (selectedVersionObj['expired'] == false) {
        if (selectedVersionObj['version'] != selectedTemplate['latest_version']) {
          console.log('Archiving template version : ')
          console.log(selectedTemplate)
          console.log(selectedVersionObj)
          disablePublishedVersion().then(async () => {
            const path = '/forms/templates/' + selectedTemplate['id'] + '?version=' + selectedVersionObj['version']
            const fetchurl = baseurl + path
            console.log(fetchurl)
            const data = {
              expired: true,
            }

            console.log('Setting template version as expired: ')
            console.log(data)

            await axios
              .put(fetchurl, data)
              .then(response => {
                console.log(response)

                console.log('Updating local template version instance')
                var tempObj = { ...selectedVersionObj }
                console.log(tempObj)
                tempObj['expired'] = true
                if (tempObj['published'] == true) {
                  tempObj['published'] = false
                }
                console.log(selectedVersionObj['id'])
                /* document.getElementById('version-' + selectedVersionObj['id']).cells[7].innerHTML = 'True'
              document.getElementById('version-' + selectedVersionObj['id']).style.display = 'none'
              document.getElementById('version-' + selectedVersionObj['id']).style.backgroundColor = 'white' */
                let template_versions = []
                templateVersions.map(template_version => {
                  if (template_version['id'] != selectedVersionObj['id']) {
                    template_versions.push(template_version)
                  }
                })
                setTemplateVersions(template_versions)
                let archived_versions = archivedVersions
                console.log(tempObj)
                archived_versions.push({ ...tempObj })
                setArchivedVersions(archived_versions)
                setSelectedVersion(-1)
                setSelectedVersionObj({})
              })
              .catch(error => {
                console.error('Error saving template version changes: ', error, error.stack)
              })
          })
        } else {
          alert('You cannot archive an active template. Please set another version as active!')
        }
      } else {
        alert('test')
      }
    }
  }
  const unArchiveVersion = async (e, version_id) => {
    if (e.target.className == i.option) {
      console.log('not disabled')
      e.target.className = i.buttonDisabled
      console.log('Un Archiving template version : ')
      console.log(selectedTemplate)
      var archivedRow = document.getElementById('archived_version-' + version_id)
      if (archivedRow.cells[7].textContent == 'True') {
        const path = '/forms/templates/' + selectedTemplate['id'] + '?version=' + archivedRow.cells[0].textContent
        const fetchurl = baseurl + path
        console.log(fetchurl)
        const data = {
          expired: false,
        }

        console.log('Setting template version as not expired: ')
        console.log(data)

        await axios
          .put(fetchurl, data)
          .then(response => {
            console.log(response)

            console.log('Updating local template version instance') /* 
            document.getElementById('version-' + version_id).cells[7].innerHTML = 'False'
            document.getElementById('version-' + version_id).style.display = 'table-row'
            document.getElementById('archived_version-' + version_id).style.display = 'none' */

            let archived_versions = []
            let template_versions = templateVersions
            archivedVersions.map(archived_version => {
              if (archived_version['id'] != version_id) {
                archived_versions.push(archived_version)
              } else {
                archived_version['expired'] = false
                template_versions.push(archived_version)
              }
            })
            console.log(archived_versions)
            console.log(template_versions)
            setTemplateVersions(template_versions)
            setArchivedVersions(archived_versions)
            setSelectedVersion(-1)
          })
          .catch(error => {
            console.error('Error saving template version changes: ', error, error.stack)
          })
      }
    } else if (e.target.className == i.buttonDisabled) {
      console.log('disabled')
    }
  }
  useEffect(() => {
    console.log('Updated selectedtemplate')
    console.log(selectedTemplate)
  }, [selectedTemplate])

  const rowHover = (template_id, keyword, target) => {
    switch (keyword) {
      case 'enter':
        if (target == 'template') {
          document.getElementById('local-template-' + template_id).style.backgroundColor = 'rgba(142, 202, 250, 0.28)'
        } else if (target == 'version') {
          if (selectedVersion != template_id) {
            document.getElementById('version-' + template_id).style.backgroundColor = 'rgba(142, 202, 250, 0.28)'
          }
        }
        break
      case 'leave':
        if (target == 'template') {
          document.getElementById('template-' + template_id).style.backgroundColor = 'white'
        } else if (target == 'version') {
          if (selectedVersion != template_id) {
            document.getElementById('version-' + template_id).style.backgroundColor = 'white'
          }
        }
        break
    }
  }
  const toggleArchive = () => {
    var archive_display = document.getElementById('archived_versions_section')
    if (archive_display.style.display == 'none') {
      archive_display.style.display = 'block'
      document.getElementById('archive_button_text').innerHTML = 'Hide'
    } else {
      archive_display.style.display = 'none'
      document.getElementById('archive_button_text').innerHTML = 'Show'
    }
  }

  function CustomFieldTemplate(props) {
    const { id, classNames, label, help, required, description, errors, children } = props
    if (props.schema.type === 'object') {
      if (props.schema.title === JSON.parse(preview_schema).title) {
        // Not displaying the title and description
        return (
          <div className={classNames}>
            {children}
            {errors}
            {help}
          </div>
        )
      } else {
        // Style the section titles and description here
        return (
          <div className={classNames}>
            {children}
            {errors}
            {help}
          </div>
        )
      }
    } else if (props.schema.type === 'boolean') {
      return (
        <div style={{ marginBottom: 0 }} className={classNames}>
          {description}
          {children}
          {errors}
          {help}
        </div>
      )
    } else if (props.schema.type === 'array' && props.schema.items !== undefined) {
      // multiple files
      return (
        <div className={classNames}>
          {children}
          {errors}
          {help}
        </div>
      )
    } else if (props.schema.type === 'string' && props.schema.format == 'data-url') {
      // single file
      return (
        <div className={classNames}>
          {children}
          {errors}
          {help}
        </div>
      )
    } else if (props.schema.format == 'date-time') {
      return (
        <div className={classNames}>
          <label style={{ marginLeft: 0, marginTop: 10, marginBottom: 10 }} htmlFor={id}>
            {label}
            {required ? '*' : null}
          </label>
          {description}
          {children}
          {errors}
          {help}
        </div>
      )
    } else {
      return (
        <div className={classNames}>
          <label style={{ marginLeft: 0, marginTop: 10, marginBottom: 10 }} htmlFor={id}>
            {label}
            {required ? '*' : null}
          </label>
          {description}
          {children}
          {errors}
          {help}
        </div>
      )
    }
  }
  const CustomDescriptionField = ({ description }) => {
    if (description !== undefined) {
      //console.log("This field has a description")
      if (description === JSON.parse(preview_schema).description) {
        return <></>
      } else {
        return (
          <>
            <div style={{ marginBottom: 2, fontSize: 15 }}>{description}</div>
          </>
        )
      }
    } else {
      return <></>
    }
  }
  const CustomTitleField = ({ title, required }) => {
    const legend = required ? title + '*' : title
    if (title === JSON.parse(preview_schema).title) {
      //console.log("This is the first title. Skip and do not display this title")
      return <></>
    } else {
      return (
        <div className="my-1">
          <h5 style={{ fontSize: 1.25 + 'rem' }}>{legend}</h5>
        </div>
      )
    }
  }
  const fields = {
    TitleField: CustomTitleField,
    DescriptionField: CustomDescriptionField,
  }
  const widgets = {
    FileWidget: props => <FileWidget {...props} />,
    DateTimeWidget: DateTimeWidget,
  }
  const goBackGlobal = () => {
    setGlobalTemplatesListDisplay('block')
    setGlobalTemplatePreviewDisplay('none')
    setTemplatesListDisplay('block')
    setPreviewTemplateID(-1)
  }
  const goTemplateDetails = data => {
    history.replace({
      pathname: '/TemplateManager',
      state: {
        viewingTemplate: data,
      },
    })
  }
  const useGlobalTemplate = async () => {
    const path = '/forms/templates'
    const fetchurl = baseurl + path

    if (props.userData != undefined) {
      if (props.userData['data']['organisation']['id'] != null) {
        const organisation_id = props.userData['data']['organisation']['id']
        const data = {
          category_id: templatesObj[previewTemplateID]['category_id'],
          subcategory_id: templatesObj[previewTemplateID]['subcategory_id'],
          description: templatesObj[previewTemplateID]['description'],
          title: templatesObj[previewTemplateID]['title'],
          type_id: templatesObj[previewTemplateID]['type_id'],
          template: JSON.stringify(preview_schema),
          ui_schema: JSON.stringify(preview_uischema),
          organisation_id: organisation_id,
        }
        /* 
        if (formType == 3) {
          data['subcategory_id'] = 3
        } */
        console.log(data)

        await axios
          .post(fetchurl, data)
          .then(response => {
            console.log(response)
            var tempObj = {}
            var tempArray = []
            // Redirect to Template Manager
            getCompletedForms()
              .then(data => {
                // Create dictionary of forms using id as key
                data.map(template_principle => {
                  tempObj[template_principle.id] = template_principle
                  if (template_principle['organisation_id'] != 4) {
                    tempArray.push(template_principle)
                  } else {
                    globalArray.push(template_principle)
                  }
                })
              })
              .then(() => {
                console.log('Loop done')
                console.log(globalArray)
                setGlobalTemplates(globalArray.reverse())
                setCompletedForms(tempArray.reverse())
                setTemplatesObj(tempObj)
              })
              .then(() => {
                data['id'] = response.data.data.instance_id
                clickTemplateRowTest(data['id'], data)
              })
            setTemplatesListDisplay('none')
            setTemplateDetailDisplay('none')
            setGlobalTemplatePreviewDisplay('none')
            //history.go(0)
            //goTemplateDetails(data)
          })
          .catch(error => {
            console.error('Error submitting form template: ', error.stack)
          })
      }
    }
  }
  const convertToGlobal = async () => {
    const path = '/forms/templates'
    const fetchurl = baseurl + path

    if (props.userData != undefined) {
      if (props.userData['data']['organisation']['id'] != null) {
        var version_template
        var version_ui_schema
        templateVersions.map(template_version => {
          if (template_version['version'] == selectedTemplate['latest_version']) {
            version_template = template_version['template']
            version_ui_schema = template_version['ui_schema']
          }
        })

        const organisation_id = props.userData['data']['organisation']['id']

        const data = {
          category_id: selectedTemplate['category_id'],
          subcategory_id: selectedTemplate['subcategory_id'],
          description: selectedTemplate['description'],
          title: selectedTemplate['title'],
          type_id: selectedTemplate['type_id'],
          template: version_template,
          ui_schema: version_ui_schema,
          organisation_id: parseInt(organisation_id),
        }

        console.log(data)
        await axios
          .post(fetchurl, data)
          .then(response => {
            console.log(response)
            // Redirect to Template Manager
            history.go(0)
          })
          .catch(error => {
            console.error('Error submitting form template: ', error.stack)
          })
      }
    }
  }
  const clickGlobalRow = template_id => {
    if (props.userData != undefined) {
      if (props.userData['data']['organisation']['id'] == 4) {
        clickTemplateRow(template_id)
      } else {
        previewGlobalTemplate(template_id)
      }
    }
  }
  const dismissNotification = () => {
    setAlertDisplay('none')
  }

  const formatDate = unformatted_date => {
    var date = new Date(unformatted_date)
    if (date == 'Invalid Date') {
      return <>{unformatted_date}</>
    } else {
      var formatted_date =
        date.getDate().toString().padStart(2, '0') +
        '-' +
        (date.getMonth() + 1).toString().padStart(2, '0') +
        '-' +
        date.getFullYear() +
        ' ' +
        date.getHours().toString().padStart(2, '0') +
        ':' +
        date.getMinutes().toString().padStart(2, '0') +
        ':' +
        date.getSeconds().toString().padStart(2, '0')

      return <>{formatted_date}</>
    }
  }

  const sortColumn = (keyword, sort_by, sorted_id) => {
    console.log(sortedColumn)
    console.log(sorted_id)
    if (sortedColumn == sorted_id) {
      console.log('Undo sorting')
      // reset sort based on id
      var tempArr = []

      // admin user
      if (props.userData['data']['organisation']['id'] == 4) {
        if (1 <= sorted_id && sorted_id <= 10) {
          // local templates
          tempArr = [...completedforms]
        }
        if (23 <= sorted_id && sorted_id <= 24) {
          tempArr = [...completedforms]
        }
        if (11 <= sorted_id && sorted_id <= 22) {
          // local templates
          tempArr = [...globalTemplates]
        }
      }
      // normal user
      else if (1 <= sorted_id && sorted_id <= 10) {
        // local templates
        tempArr = [...completedforms]
      } else if (11 <= sorted_id && sorted_id <= 20) {
        // local templates
        tempArr = [...globalTemplates2]
      }
      tempArr.sort((a, b) => {
        return parseInt(b['id'] - parseInt(a['id']))
      })
      document.getElementById('sorter-' + sortedColumn).style.color = 'rgb(149, 170, 201)'
      setSortedColumn(-1)
    } else {
      if (sortedColumn != -1) {
        // has sorted column currently
        // reset style
        document.getElementById('sorter-' + sortedColumn).style.color = 'rgb(149, 170, 201)'
      }
      // set new column as selected
      document.getElementById('sorter-' + sorted_id).style.color = 'red'
      setSortedColumn(sorted_id)
      var tempArr = []

      // admin user
      if (props.userData['data']['organisation']['id'] == 4) {
        if (1 <= sorted_id && sorted_id <= 10) {
          // local templates
          console.log('Admin Local templates')
          tempArr = [...completedforms]
        }
        if (23 <= sorted_id && sorted_id <= 24) {
          console.log('Admin Local templates')
          tempArr = [...completedforms]
        }
        if (11 <= sorted_id && sorted_id <= 22) {
          // global templates
          console.log('admin global templates')

          tempArr = [...globalTemplates]
        }
      }
      // normal user
      else if (1 <= sorted_id && sorted_id <= 10) {
        console.log('Normal User Local templates')
        // local templates
        tempArr = [...completedforms]
      } else if (11 <= sorted_id && sorted_id <= 20) {
        // local templates
        console.log('Normal User Local templates')

        tempArr = [...globalTemplates2]
      }

      switch (keyword) {
        case 'organisation':
          if (sort_by == 'asc') {
            tempArr.sort((a, b) => {
              return ('' + a['organisation_id']).localeCompare(b['organisation_id'])
            })
          } else if (sort_by == 'desc') {
            tempArr.sort((a, b) => {
              return ('' + b['organisation_id']).localeCompare(a['organisation_id'])
            })
          }
          break
        case 'title':
          if (sort_by == 'asc') {
            tempArr.sort((a, b) => {
              return ('' + a['title']).localeCompare(b['title'])
            })
          } else if (sort_by == 'desc') {
            tempArr.sort((a, b) => {
              return ('' + b['title']).localeCompare(a['title'])
            })
          }
          break
        case 'description':
          if (sort_by == 'asc') {
            tempArr.sort((a, b) => {
              return ('' + a['description']).localeCompare(b['description'])
            })
          } else if (sort_by == 'desc') {
            tempArr.sort((a, b) => {
              return ('' + b['description']).localeCompare(a['description'])
            })
          }
          break
        case 'type':
          if (sort_by == 'asc') {
            tempArr.sort((a, b) => {
              return ('' + templateTypes[a['type_id']]).localeCompare(templateTypes[b['type_id']])
            })
          } else if (sort_by == 'desc') {
            tempArr.sort((a, b) => {
              return ('' + templateTypes[b['type_id']]).localeCompare(templateTypes[a['type_id']])
            })
          }

          break
        case 'category':
          if (sort_by == 'asc') {
            tempArr.sort((a, b) => {
              return ('' + categoriesObj[a['category_id']]).localeCompare(categoriesObj[b['category_id']])
            })
          } else if (sort_by == 'desc') {
            tempArr.sort((a, b) => {
              return ('' + categoriesObj[b['category_id']]).localeCompare(categoriesObj[a['category_id']])
            })
          }
          break
        case 'subcategory':
          if (sort_by == 'asc') {
            tempArr.sort((a, b) => {
              return ('' + subCategoriesObj[a['subcategory_id']]).localeCompare(subCategoriesObj[b['subcategory_id']])
            })
          } else if (sort_by == 'desc') {
            tempArr.sort((a, b) => {
              return ('' + subCategoriesObj[b['subcategory_id']]).localeCompare(subCategoriesObj[a['subcategory_id']])
            })
          }
          break
      }
    }
    if (props.userData['data']['organisation']['id'] == 4) {
      if (1 <= sorted_id && sorted_id <= 10) {
        // local templates
        setCompletedForms(tempArr)
      }
      if (23 <= sorted_id && sorted_id <= 24) {
        setCompletedForms(tempArr)
      }
      if (11 <= sorted_id && sorted_id <= 22) {
        // local templates
        setGlobalTemplates(tempArr)
      }
    }
    // normal user
    else if (1 <= sorted_id && sorted_id <= 10) {
      // local templates
      setCompletedForms(tempArr)
    } else if (11 <= sorted_id && sorted_id <= 20) {
      // local templates
      setGlobalTemplates2(tempArr)
    }
  }

  /* Search function starts here*/
  const searchTable = (searchvalue, searchtable) => {
    let table_rows = []
    switch (searchtable) {
      case 'local_templates':
        console.log('Searching local templates table')
        table_rows = document.getElementById('local_templates_list').children
        break
      case 'global_templates':
        console.log('Searching global templates table')
        table_rows = document.getElementById('global_templates_list').children

        break
      case 'admin_global_templates':
        console.log('Searching admin global templates table')
        table_rows = document.getElementById('admin_global_templates_list').children
        break
    }
    if (searchvalue != '') {
      searchvalue = searchvalue.toUpperCase()

      Object.values(table_rows).map(table_row => {
        var search_flag = false
        //console.log(table_row)
        for (var col_index = 0; col_index <= 5; col_index++) {
          if (col_index == 0) {
            if (props.userData['data']['organisation']['id'] == 4) {
              console.log('Admin search, searching organisation column also..')
              let columnvalue = table_row.cells[col_index].textContent.toUpperCase()
              //console.log(columnvalue)
              if (columnvalue.includes(searchvalue)) {
                search_flag = true
              }
            }
          } else {
            let columnvalue = table_row.cells[col_index].textContent.toUpperCase()
            //console.log(columnvalue)
            if (columnvalue.includes(searchvalue)) {
              search_flag = true
            }
          }
        }
        if (search_flag) {
          if (table_row.style.display == 'none') {
            table_row.style.display = 'table-row'
          }
        } else {
          table_row.style.display = 'none'
        }
      })
    } else {
      Object.values(table_rows).map(table_row => {
        table_row.style.display = 'table-row'
      })
    }
  }
  // Delay for x seconds function
  async function delayForXSeconds(time) {
    console.log('start timer')
    await new Promise(resolve => setTimeout(resolve, time))
    console.log('after' + time / 1000 + ' second(s)')
  }
  const deleteTemplate = async () => {
    const template_id = selectedTemplate['id']
    const path = '/forms/templates/' + template_id
    const fetchurl = baseurl + path
    console.log(fetchurl)
    const data = {
      is_active: false,
    }

    console.log('Setting template as inactive: ')
    console.log(data)

    await axios
      .put(fetchurl, data)
      .then(async response => {
        console.log(response)

        console.log('Updating form template principle is_active')
        setAlertDisplay('block')
        setAlertMessage('Template has been deleted successfully.')
        setAlertType('alert alert-success alert-dismissible fade show')
        var tempArr = []
        if (props.userData['data']['organisation']['id'] == 4) {
          if (selectedTemplate['organisation_id'] == 4) {
            globalTemplates.map(global_template => {
              if (global_template['id'] != selectedTemplate['id']) {
                tempArr.push(global_template)
              }
            })
            setGlobalTemplates(tempArr)
          } else {
            completedforms.map(local_template => {
              if (local_template['id'] != selectedTemplate['id']) {
                tempArr.push(local_template)
              }
            })
            setCompletedForms(tempArr)
          }
        } else {
          completedforms.map(local_template => {
            if (local_template['id'] != selectedTemplate['id']) {
              tempArr.push(local_template)
            }
          })
          setCompletedForms(tempArr)
        }
        await delayForXSeconds(3000)
        backButton()
      })
      .catch(error => {
        console.error('Error deleting template: ', error, error.stack)
        setAlertDisplay('block')
        setAlertMessage('Error deleting template.')
        setAlertType('alert alert-danger alert-dismissible fade show')
      })
  }
  return (
    <>
      <div
        style={{
          position: 'absolute',
          left: 0,
          marginLeft: 40,
          display: 'flex',
          alignItems: 'center',
        }}>
        <a style={{ cursor: 'pointer', display: 'flex' }} onClick={backButton}>
          <img style={{ marginRight: 10 }} src={require('../../images/icons/Back.svg')}></img>
          <p className={i.link}>All Templates</p>
        </a>
        <p style={{ marginLeft: 10, marginRight: 10, marginBottom: 'auto', marginTop: 'auto' }}> / </p>
        <p style={{ marginTop: 'auto', marginBottom: 'auto' }}>
          {selectedTemplate['title'] ? selectedTemplate['title'] : ''}
        </p>
      </div>
      <Card style={{ display: templateDetailDisplay, marginTop: 50 }}>
        <Card.Header style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h4>Template Manager</h4>
          <h4 id="published_state_text"></h4>
        </Card.Header>
        <Card.Body>
          <div style={{ marginTop: 5, marginBottom: 10, display: 'flex', justifyContent: 'space-between' }}>
            <a style={{ cursor: 'pointer', marginTop: 'auto', marginBottom: 'auto' }} onClick={backButton}>
              <img src={require('../../images/icons/Back.svg')}></img>
            </a>
            <div style={{ display: 'none', flex: 'auto' }}>
              <Search placeholder="Search" />
            </div>

            <div style={{ marginTop: 'auto', marginBottom: 'auto', display: 'flex' }} className={i.menu}>
              {/* <Button size="sm" style={{ marginRight: 10 }} onClick={editTemplate}>
                Edit
              </Button> */}
              <a style={{ marginBottom: 'auto', marginTop: 'auto' }} className={i.option} onClick={editTemplate}>
                <span style={{ verticalAlign: 'middle' }}>Edit</span>
              </a>
              <a
                data-tip
                data-for="editTemplateTip"
                style={{ cursor: 'pointer', marginBottom: 'auto', marginTop: 'auto', marginLeft: 3 }}>
                <img style={{ width: 15, height: 15 }} src={require('../../images/icons/Info.svg')}></img>
              </a>
              <ReactTooltip id="editTemplateTip" place="top" effect="solid">
                Edit template details
              </ReactTooltip>
              {/* <Button size="sm">Manage Access</Button> */}
              <a
                style={{ marginBottom: 'auto', marginTop: 'auto' }}
                className={i.option}
                onClick={e => publishTemplate(e)}>
                <span style={{ verticalAlign: 'middle' }} id="publish_button_text">
                  Publish
                </span>
              </a>
              <a
                data-tip
                data-for="publishTip"
                style={{ cursor: 'pointer', marginBottom: 'auto', marginTop: 'auto', marginLeft: 3 }}>
                <img style={{ width: 15, height: 15 }} src={require('../../images/icons/Info.svg')}></img>
              </a>
              <ReactTooltip id="publishTip" place="top" effect="solid">
                Publish template version
              </ReactTooltip>
              <a
                style={{ marginBottom: 'auto', marginTop: 'auto' }}
                className={i.option}
                onClick={e => toggleArchive(e)}>
                <span style={{ verticalAlign: 'middle' }} id="archive_button_text">
                  View
                </span>{' '}
                Archive ({archivedVersions.length})
              </a>
              <a
                data-tip
                data-for="viewArchiveTip"
                style={{ cursor: 'pointer', marginBottom: 'auto', marginTop: 'auto', marginLeft: 3 }}>
                <img style={{ width: 15, height: 15 }} src={require('../../images/icons/Info.svg')}></img>
              </a>
              <ReactTooltip id="viewArchiveTip" place="top" effect="solid">
                View archived template versions
              </ReactTooltip>

              <a
                style={{
                  marginBottom: 'auto',
                  marginTop: 'auto',
                  display: selectedTemplate['organisation_id'] == 4 ? 'none' : balkerneAdminDisplay,
                }}
                className={i.option}
                onClick={convertToGlobal}>
                Set Global
              </a>
              <a
                style={{ marginBottom: 'auto', marginTop: 'auto', color: '#dc3545' }}
                className={i.option}
                onClick={deleteTemplate}>
                <span style={{ verticalAlign: 'middle' }} id="archive_button_text">
                  Delete
                </span>
              </a>
              <a
                data-tip
                data-for="deleteTemplateTip"
                style={{ cursor: 'pointer', marginBottom: 'auto', marginTop: 'auto', marginLeft: 3 }}>
                <img style={{ width: 15, height: 15 }} src={require('../../images/icons/Info.svg')}></img>
              </a>
              <ReactTooltip id="deleteTemplateTip" place="top" effect="solid">
                Delete selected template
              </ReactTooltip>
            </div>
          </div>
          <Table responsive>
            <thead>
              <tr style={{ fontSize: 11 }}>
                <th style={{ display: balkerneAdminDisplayCells }}>Organisation</th>
                <th>Title</th>
                <th>Description</th>
                <th>Type</th>
                <th colSpan={selectedTemplate.type_id == 3 ? 2 : 1}>Category</th>
                <th style={{ display: selectedTemplate.type_id == 3 ? 'none' : 'table-cell' }}>Sub-Category</th>
                <th>Active Version</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ display: balkerneAdminDisplayCells }}>{selectedTemplate.organisation_id}</td>
                <td>{selectedTemplate.title}</td>
                <td>{selectedTemplate.description}</td>
                <td>{templateTypes[selectedTemplate.type_id]}</td>
                <td colSpan={selectedTemplate.type_id == 3 ? 2 : 1}>{categoriesObj[selectedTemplate.category_id]}</td>
                <td style={{ display: selectedTemplate.type_id == 3 ? 'none' : 'table-cell' }}>
                  {selectedTemplate.type_id == 3 ? '' : subCategoriesObj[selectedTemplate.subcategory_id]}
                </td>
                <td>{selectedTemplate.latest_version}</td>
                {/* 
                  <td style={{ width: '10%' }}>

                    <Dropdown>
                      <Dropdown.Toggle as={OptionToggle} src={require('../../images/icons/vertical-dots.svg')} />
                      <Dropdown.Menu>
                        <Dropdown.Item>Edit</Dropdown.Item>
                        <Dropdown.Item>Archive</Dropdown.Item>
                        <Dropdown.Item>Manage Access</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td> */}
              </tr>
            </tbody>
          </Table>
          <hr style={{ marginLeft: -25, marginRight: -25 }} />
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 15, marginTop: 15 }}>
            <div style={{ display: 'flex', marginBottom: 'auto', marginTop: 'auto' }}>
              <h4 style={{ padding: 0 }}>Versions</h4>
            </div>

            <div
              className={i.menu}
              style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 'auto', marginTop: 'auto' }}>
              <a className={i.option} onClick={newTemplateVersion}>
                <span style={{ verticalAlign: 'middle' }}>New Version</span>
              </a>
              <a
                data-tip
                data-for="newVersionTip"
                style={{ cursor: 'pointer', marginBottom: 'auto', marginTop: 'auto', marginLeft: 3 }}>
                <img style={{ width: 15, height: 15 }} src={require('../../images/icons/Info.svg')}></img>
              </a>
              <ReactTooltip id="newVersionTip" place="top" effect="solid">
                Create a new version for this template
              </ReactTooltip>

              <a id="version_action_edit" onClick={e => editTemplateVersion(e)} className={i.buttonDisabled}>
                <span style={{ verticalAlign: 'middle' }}>Edit</span>
              </a>
              <a
                data-tip
                data-for="editTip"
                style={{ cursor: 'pointer', marginBottom: 'auto', marginTop: 'auto', marginLeft: 3 }}>
                <img style={{ width: 15, height: 15 }} src={require('../../images/icons/Info.svg')}></img>
              </a>
              <ReactTooltip id="editTip" place="top" effect="solid">
                Edit selected template version
              </ReactTooltip>
              <a id="version_action_active" onClick={e => setActiveVersion(e)} className={i.buttonDisabled}>
                <span style={{ verticalAlign: 'middle' }}>Set Active</span>
              </a>
              <a
                data-tip
                data-for="setActiveTip"
                style={{ cursor: 'pointer', marginBottom: 'auto', marginTop: 'auto', marginLeft: 3 }}>
                <img style={{ width: 15, height: 15 }} src={require('../../images/icons/Info.svg')}></img>
              </a>
              <ReactTooltip id="setActiveTip" place="top" effect="solid">
                Set selected template version as active
              </ReactTooltip>
              <a id="version_action_archive" onClick={e => archiveVersion(e)} className={i.buttonDisabled}>
                <span style={{ verticalAlign: 'middle' }}>Archive</span>
              </a>
              <a
                data-tip
                data-for="archiveTip"
                style={{ cursor: 'pointer', marginBottom: 'auto', marginTop: 'auto', marginLeft: 3 }}>
                <img style={{ width: 15, height: 15 }} src={require('../../images/icons/Info.svg')}></img>
              </a>
              <ReactTooltip id="archiveTip" place="top" effect="solid">
                Set selected version as Archived
              </ReactTooltip>
            </div>
          </div>

          <Table>
            <thead>
              <tr style={{ fontSize: 11 }}>
                <th>Version</th>
                <th>Description</th>
                <th>Created At</th>
                <th>Updated At</th>
                <th style={{ display: 'none' }}>Published</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody id="template_versions_list">
              {templateVersions.map(template_version => (
                <tr
                  onClick={() => selectVersionRow(template_version.id)}
                  disabled={template_version.expired}
                  id={'version-' + template_version.id}
                  key={'version-' + template_version.id}
                  style={{ cursor: 'pointer', display: template_version.expired ? 'none' : 'table-row' }}
                  onMouseEnter={() => rowHover(template_version.id, 'enter', 'version')}
                  onMouseLeave={() => rowHover(template_version.id, 'leave', 'version')}>
                  <td>{template_version.version}</td>
                  <td>{template_version.description}</td>
                  <td>{formatDate(template_version.created_at)}</td>
                  <td>{formatDate(template_version.updated_at)}</td>
                  <td style={{ display: 'none' }}>{template_version.published == true ? 'True' : 'False'}</td>
                  <td style={{ display: 'none' }}>{template_version.template}</td>
                  <td style={{ display: 'none' }}>{template_version.ui_schema}</td>
                  <td style={{ display: 'none' }}>{template_version.expired == true ? 'True' : 'False'}</td>
                  <td style={{ display: 'none' }}>{template_version.template_id}</td>
                  <td style={{ display: 'none' }}>{template_version.id}</td>
                  <td
                    className={template_version.version == selectedTemplate['latest_version'] ? i.Active : i.Inactive}>
                    {template_version.version == selectedTemplate['latest_version'] ? 'Active' : 'Inactive'}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div id="archived_versions_section" style={{ display: 'none' }}>
            <hr style={{ marginLeft: -25, marginRight: -25 }} />
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 15, marginTop: 15 }}>
              <div style={{ display: 'flex', marginBottom: 'auto', marginTop: 'auto' }}>
                <h4 style={{ padding: 0 }}>Archived</h4>
              </div>
            </div>

            <Table>
              <thead>
                <tr style={{ fontSize: 11 }}>
                  <th>Version</th>
                  <th>Description</th>
                  <th>Created At</th>
                  <th>Updated At</th>
                  <th style={{ display: 'none' }}>Published</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody id="template_versions_archived">
                {archivedVersions.map(archived_version => (
                  <tr
                    // onClick={() => selectArchivedVersionRow(archived_version.id)}
                    disabled={archived_version.expired}
                    id={'archived_version-' + archived_version.id}
                    key={'archived_version-' + archived_version.id}
                    style={{ cursor: 'pointer' }}
                    // onMouseEnter={() => rowHover(archived_version.id, 'enter', 'archived_version')}
                    // onMouseLeave={() => rowHover(archived_version.id, 'leave', 'archived_version')}
                  >
                    <td>{archived_version.version}</td>
                    <td>{archived_version.description}</td>
                    <td>{formatDate(archived_version.created_at)}</td>
                    <td>{formatDate(archived_version.updated_at)}</td>
                    <td style={{ display: 'none' }}>{archived_version.published == true ? 'True' : 'False'}</td>
                    <td style={{ display: 'none' }}>{archived_version.template}</td>
                    <td style={{ display: 'none' }}>{archived_version.ui_schema}</td>
                    <td style={{ display: 'none' }}>{archived_version.expired == true ? 'True' : 'False'}</td>
                    <td style={{ display: 'none' }}>{archived_version.template_id}</td>
                    <td style={{ display: 'none' }}>{archived_version.id}</td>
                    <td>
                      <div
                        className={i.menu}
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          marginBottom: 'auto',
                          marginTop: 'auto',
                        }}>
                        <a
                          id="version_action_edit"
                          onClick={e => unArchiveVersion(e, archived_version.id)}
                          className={i.option}>
                          Unarchive
                        </a>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Card.Body>
      </Card>
      <Card style={{ display: globalTemplatePreviewDisplay, marginTop: 50 }}>
        <Card.Header>
          {JSON.parse(preview_schema)['title']} - {JSON.parse(preview_schema)['description']}
        </Card.Header>
        <Card.Body>
          <div style={{ marginTop: 5, marginBottom: 10, display: 'flex', justifyContent: 'space-between' }}>
            <a style={{ cursor: 'pointer', marginTop: 'auto', marginBottom: 'auto' }} onClick={goBackGlobal}>
              <img src={require('../../images/icons/Back.svg')}></img>
            </a>

            <div style={{ marginTop: 'auto', marginBottom: 'auto', display: 'flex' }} className={i.menu}>
              <a style={{ marginBottom: 'auto', marginTop: 'auto' }} className={i.option} onClick={useGlobalTemplate}>
                <span>Use This Template</span>
              </a>
            </div>
          </div>
          <div>
            <Form
              fields={fields}
              widgets={widgets}
              schema={JSON.parse(preview_schema)}
              uiSchema={JSON.parse(preview_uischema)}
              FieldTemplate={CustomFieldTemplate}>
              <button style={{ display: 'none' }} type="submit"></button>
            </Form>
          </div>
        </Card.Body>
      </Card>
      <div style={{ display: 'block' }}>
        <div style={{ display: alertDisplay, marginTop: 20 }}>
          <div className={alertType} style={{ textAlign: 'center' }} role="alert">
            {alertMessage}
            <button
              type="button"
              style={{ paddingTop: '0.5rem', paddingBottom: '0.5rem', paddingLeft: '1.25rem', paddingRight: '1.25rem' }}
              className="close"
              onClick={dismissNotification}
              aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
      </div>
      <div style={{ display: templatesListDisplay }}>
        <Tabs
          style={{ marginTop: 50, fontWeight: 500 }}
          defaultActiveKey="local_templates"
          id="uncontrolled-tab-example"
          className="mb-3">
          <Tab eventKey="local_templates" title="Local">
            <Card>
              <Card.Header>
                <h4>Template Manager</h4>
                <h4 id="published_state_text"></h4>
              </Card.Header>
              <ProgressBar variant="info" now={progressBarValue} style={{ zIndex: 100, display: progressBarDisplay }} />
              <Card.Body style={{ display: templatesListDisplay }}>
                <div className={overlayclass} style={{ display: progressBarDisplay }}></div>
                <div style={{ marginTop: 5, marginBottom: 10, display: 'flex', justifyContent: 'space-between' }}>
                  <div style={{ width: 80 + '%' }}>
                    <Search onChange={e => searchTable(e.target.value, 'local_templates')} placeholder="Search" />
                  </div>
                  <Button style={{ marginTop: 'auto', marginBottom: 'auto' }} size="sm" onClick={newTemplate}>
                    New Template
                  </Button>
                </div>
                <Table responsive>
                  <thead>
                    <tr style={{ fontSize: 12 }}>
                      <th style={{ display: balkerneAdminDisplayCells }}>
                        Organisation{' '}
                        <a
                          id="sorter-23"
                          onClick={() => sortColumn('organisation', 'asc', 23)}
                          style={{ fontSize: 13, cursor: 'pointer', marginRight: 1 }}>
                          &#9650;
                        </a>
                        <a
                          id="sorter-24"
                          onClick={() => sortColumn('organisation', 'desc', 24)}
                          style={{ fontSize: 12, cursor: 'pointer' }}>
                          &#9660;
                        </a>
                      </th>
                      <th>
                        Title{' '}
                        <a
                          id="sorter-1"
                          onClick={() => sortColumn('title', 'asc', 1)}
                          style={{ fontSize: 13, cursor: 'pointer', marginRight: 1 }}>
                          &#9650;
                        </a>
                        <a
                          id="sorter-2"
                          onClick={() => sortColumn('title', 'desc', 2)}
                          style={{ fontSize: 12, cursor: 'pointer' }}>
                          &#9660;
                        </a>
                      </th>
                      <th>
                        Description{' '}
                        <a
                          id="sorter-3"
                          onClick={() => sortColumn('description', 'asc', 3)}
                          style={{ fontSize: 13, cursor: 'pointer', marginRight: 1 }}>
                          &#9650;
                        </a>
                        <a
                          id="sorter-4"
                          onClick={() => sortColumn('description', 'desc', 4)}
                          style={{ fontSize: 12, cursor: 'pointer' }}>
                          &#9660;
                        </a>
                      </th>
                      <th>
                        Type{' '}
                        <a
                          id="sorter-5"
                          onClick={() => sortColumn('type', 'asc', 5)}
                          style={{ fontSize: 13, cursor: 'pointer', marginRight: 1 }}>
                          &#9650;
                        </a>
                        <a
                          id="sorter-6"
                          onClick={() => sortColumn('type', 'desc', 6)}
                          style={{ fontSize: 12, cursor: 'pointer' }}>
                          &#9660;
                        </a>
                      </th>
                      <th>
                        Category{' '}
                        <a
                          id="sorter-7"
                          onClick={() => sortColumn('category', 'asc', 7)}
                          style={{ fontSize: 13, cursor: 'pointer', marginRight: 1 }}>
                          &#9650;
                        </a>
                        <a
                          id="sorter-8"
                          onClick={() => sortColumn('category', 'desc', 8)}
                          style={{ fontSize: 12, cursor: 'pointer' }}>
                          &#9660;
                        </a>
                      </th>
                      <th>
                        Sub-Category{' '}
                        <a
                          id="sorter-9"
                          onClick={() => sortColumn('subcategory', 'asc', 9)}
                          style={{ fontSize: 13, cursor: 'pointer', marginRight: 1 }}>
                          &#9650;
                        </a>
                        <a
                          id="sorter-10"
                          onClick={() => sortColumn('subcategory', 'desc', 10)}
                          style={{ fontSize: 12, cursor: 'pointer' }}>
                          &#9660;
                        </a>
                      </th>
                      {/* <th>Active Version</th> */}
                    </tr>
                  </thead>
                  <tbody id="local_templates_list">
                    {completedforms.map(template => (
                      <tr
                        id={`local-template-${template.id}`}
                        key={`local-template-${template.id}`}
                        style={{ cursor: 'pointer' }}
                        onClick={() => clickTemplateRow(template.id)} /* 
                        onMouseEnter={() => rowHover(template.id, 'enter', 'template')}
                        onMouseLeave={() => rowHover(template.id, 'leave', 'template')} */
                      >
                        <td style={{ display: balkerneAdminDisplayCells }}>{template.organisation_id}</td>
                        <td style={{ width: '20%' }}>{template.title}</td>
                        <td style={{ width: '25%' }}>{template.description}</td>
                        <td style={{ width: '15%' }}>{templateTypes[template.type_id]}</td>
                        <td colSpan={template.type_id == 3 ? 2 : 1} style={{ width: '15%' }}>
                          {categoriesObj[template.category_id]}
                        </td>
                        <td style={{ width: '20%', display: template.type_id == 3 ? 'none' : 'table-cell' }}>
                          {subCategoriesObj[template.subcategory_id]}
                        </td>
                        <td style={{ width: '10%', display: 'none' }}>{template.latest_version}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Tab>
          <Tab eventKey="global_templates_edit" title="Global">
            <Card>
              <Card.Header>
                <h4>Template Manager</h4>
                <h4 id="published_state_text"></h4>
              </Card.Header>
              <ProgressBar variant="info" now={progressBarValue} style={{ zIndex: 100, display: progressBarDisplay }} />
              <Card.Body style={{ display: templatesListDisplay }}>
                <div className={overlayclass} style={{ display: progressBarDisplay }}></div>
                <div style={{ marginTop: 5, marginBottom: 10, display: 'flex', justifyContent: 'space-between' }}>
                  <div
                    style={{
                      width: 80 + '%',
                      display: props.userData['data']['organisation']['id'] == 4 ? 'none' : 'flex',
                    }}>
                    <Search onChange={e => searchTable(e.target.value, 'global_templates')} placeholder="Search" />
                  </div>
                  <div
                    style={{
                      width: 80 + '%',
                      display: props.userData['data']['organisation']['id'] == 4 ? 'flex' : 'none',
                    }}>
                    <Search
                      onChange={e => searchTable(e.target.value, 'admin_global_templates')}
                      placeholder="Search"
                    />
                  </div>
                  <Button
                    style={{ marginTop: 'auto', marginBottom: 'auto', display: balkerneAdminDisplay }}
                    size="sm"
                    onClick={newTemplate}>
                    New Template
                  </Button>
                </div>
                <Table responsive>
                  <thead>
                    <tr style={{ fontSize: 12 }}>
                      <th style={{ display: balkerneAdminDisplayCells }}>
                        Organisation{' '}
                        <a
                          id="sorter-21"
                          onClick={() => sortColumn('organisation', 'asc', 21)}
                          style={{ fontSize: 13, cursor: 'pointer', marginRight: 1 }}>
                          &#9650;
                        </a>
                        <a
                          id="sorter-22"
                          onClick={() => sortColumn('organisation', 'desc', 22)}
                          style={{ fontSize: 12, cursor: 'pointer' }}>
                          &#9660;
                        </a>
                      </th>
                      <th>
                        Title{' '}
                        <a
                          id="sorter-11"
                          onClick={() => sortColumn('title', 'asc', 11)}
                          style={{ fontSize: 13, cursor: 'pointer', marginRight: 1 }}>
                          &#9650;
                        </a>
                        <a
                          id="sorter-12"
                          onClick={() => sortColumn('title', 'desc', 12)}
                          style={{ fontSize: 12, cursor: 'pointer' }}>
                          &#9660;
                        </a>
                      </th>
                      <th>
                        Description{' '}
                        <a
                          id="sorter-13"
                          onClick={() => sortColumn('description', 'asc', 13)}
                          style={{ fontSize: 13, cursor: 'pointer', marginRight: 1 }}>
                          &#9650;
                        </a>
                        <a
                          id="sorter-14"
                          onClick={() => sortColumn('description', 'desc', 14)}
                          style={{ fontSize: 12, cursor: 'pointer' }}>
                          &#9660;
                        </a>
                      </th>
                      <th>
                        Type{' '}
                        <a
                          id="sorter-15"
                          onClick={() => sortColumn('type', 'asc', 15)}
                          style={{ fontSize: 13, cursor: 'pointer', marginRight: 1 }}>
                          &#9650;
                        </a>
                        <a
                          id="sorter-16"
                          onClick={() => sortColumn('type', 'desc', 16)}
                          style={{ fontSize: 12, cursor: 'pointer' }}>
                          &#9660;
                        </a>
                      </th>
                      <th>
                        Category{' '}
                        <a
                          id="sorter-17"
                          onClick={() => sortColumn('category', 'asc', 17)}
                          style={{ fontSize: 13, cursor: 'pointer', marginRight: 1 }}>
                          &#9650;
                        </a>
                        <a
                          id="sorter-18"
                          onClick={() => sortColumn('category', 'desc', 18)}
                          style={{ fontSize: 12, cursor: 'pointer' }}>
                          &#9660;
                        </a>
                      </th>
                      <th>
                        Sub-Category{' '}
                        <a
                          id="sorter-19"
                          onClick={() => sortColumn('subcategory', 'asc', 19)}
                          style={{ fontSize: 13, cursor: 'pointer', marginRight: 1 }}>
                          &#9650;
                        </a>
                        <a
                          id="sorter-20"
                          onClick={() => sortColumn('subcategory', 'desc', 20)}
                          style={{ fontSize: 12, cursor: 'pointer' }}>
                          &#9660;
                        </a>
                      </th>
                      {/* <th>Active Version</th> */}
                    </tr>
                  </thead>
                  <tbody
                    id="global_templates_list"
                    style={{ display: props.userData['data']['organisation']['id'] == 4 ? 'none' : 'table-row-group' }}>
                    {globalTemplates2.map(template => (
                      <tr
                        id={`global-template-${template.id}`}
                        key={`global-template-${template.id}`}
                        style={{ cursor: 'pointer' }}
                        onClick={() => clickGlobalRow(template.id)}
                        /* onMouseEnter={() => rowHover(template.id, 'enter', 'template')}
                        onMouseLeave={() => rowHover(template.id, 'leave', 'template')} */
                      >
                        <td style={{ display: balkerneAdminDisplayCells }}>{template.organisation_id}</td>
                        <td style={{ width: '20%' }}>{template.title}</td>
                        <td style={{ width: '25%' }}>{template.description}</td>
                        <td style={{ width: '15%' }}>{templateTypes[template.type_id]}</td>
                        <td colSpan={template.type_id == 3 ? 2 : 1} style={{ width: '20%' }}>
                          {categoriesObj[template.category_id]}
                        </td>
                        <td style={{ width: '20%', display: template.type_id == 3 ? 'none' : 'table-cell' }}>
                          {subCategoriesObj[template.subcategory_id]}
                        </td>
                        <td style={{ width: '10%', display: 'none' }}>{template.latest_version}</td>
                      </tr>
                    ))}
                  </tbody>
                  <tbody
                    id="admin_global_templates_list"
                    style={{ display: props.userData['data']['organisation']['id'] == 4 ? 'table-row-group' : 'none' }}>
                    {globalTemplates.map(template => (
                      <tr
                        id={`admin-global-template-${template.id}`}
                        key={`admin-global-template-${template.id}`}
                        style={{ cursor: 'pointer' }}
                        onClick={() => clickGlobalRow(template.id)}
                        /* onMouseEnter={() => rowHover(template.id, 'enter', 'template')}
                        onMouseLeave={() => rowHover(template.id, 'leave', 'template')} */
                      >
                        <td style={{ display: balkerneAdminDisplayCells }}>{template.organisation_id}</td>
                        <td style={{ width: '20%' }}>{template.title}</td>
                        <td style={{ width: '25%' }}>{template.description}</td>
                        <td style={{ width: '5%' }}>{templateTypes[template.type_id]}</td>
                        <td colSpan={template.type_id == 3 ? 2 : 1} style={{ width: '25%' }}>
                          {categoriesObj[template.category_id]}
                        </td>
                        <td style={{ width: '25%', display: template.type_id == 3 ? 'none' : 'table-cell' }}>
                          {subCategoriesObj[template.subcategory_id]}
                        </td>
                        <td style={{ width: '10%', display: 'none' }}>{template.latest_version}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Tab>
        </Tabs>
      </div>
    </>
  )
}
export default TemplateManager
