import React from "react";
import axios from "axios";

import PropertyHeader from "../components/HeaderProperty";
import Loader from "../components/spinner";

import mediacontent_extract from "../tempjson/media.json";

import Mediacontent from "../components/riskReport/mediacontent";
import "./riskreport.css";
import * as urls from "../components/maps/urls";
import { Logout } from "../components/Logout";
import { connect } from "react-redux";
import { systemReduxDashboardHandler } from "../redux/_helpers/systemReduxDashboardHandler.js";
import { systemReduxHandler } from "../redux/_helpers/systemReduxHandler.js";

import TweetTrainingCard from "../components/riskReport/TweetTrainingCard";

class ErrorNotice extends React.Component {
  render() {
    return <div className="notice__error">{this.props.error}</div>;
  }
}

class TweetTraining extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tweets: null,
      user: null,

      errMessage: "",
      success: true,
    };

    this.fetchTrainingTweets = this.fetchTrainingTweets.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.submitTweets = this.submitTweets.bind(this);
    this.updateTweet = this.updateTweet.bind(this);
  }

  async componentDidMount() {
    this.setState({ isLoading: true });

    //await RefreshAuthTokens(this.props)

    //Weather warnings are obtained here

    this.fetchTrainingTweets();

    console.log("MOUNTED");
    this.setState({ isLoading: false });
  }

  async fetchTrainingTweets() {
    let params = { test: 0 };
    await axios({
      method: "get",
      url: urls.API_GATEWAY + "tweets/tweets-for-training",
      params: params,
      headers: { Authorization: "temp" },
    })
      .then((res) => {
        let tweets = res.data;
        console.log(tweets);

        if (
          tweets === "null" ||
          tweets === null ||
          typeof tweets === "undefined"
        ) {
          console.log("Error retrieving tweets");
          tweets = null;
        } else if (tweets === "No tweets found") {
          console.log("No tweets found for tweets retrieval");
          tweets = null;
        } else {
          console.log("SUCCESS!!");
          console.log("tweets Data retrieved from the database.");
        }

        this.setState({ tweets });
      })
      .catch((err) => {
        // console.log(temp_news_data)
        if (this.state.success === true) {
          console.log("Error when loading tweets list: ");
          //  Alert.alert('Error when signing in: ', err.message);
          this.setState({
            success: false,
            errMessage: "Failed to reach server.",
          });
        }
        console.log("FAILURE for getting tweets!!");
        console.log(err);
      });
  }

  async submitTweets() {
    if (this.state.tweets) {
      let response = false;

      let data = { tweets: this.state.tweets, user: this.state.user };

      await axios({
        method: "post",
        url: urls.API_GATEWAY + "tweets/training-upload",
        data: data,
        headers: { Authorization: "temp" },
      })
        .then((res) => {
          response = res.data;
          console.log(response);

          if (
            response === "null" ||
            response === null ||
            response === "Failure" ||
            typeof response === "undefined"
          ) {
            console.log("Error uploading tweets");
            alert("Error uploading response.");
            response = null;
          } else {
            console.log("SUCCESS!!");
            console.log("Tweet Data submitted successfully.");
          }
        })
        .catch((err) => {
          response = null;
          //console.log(this.state.temp_news_data)

          console.log("Error when uploading tweets");
          alert("Error uploading tweets");
          //  Alert.alert('Error when signing in: ', err.message);
          //this.setState({success:false, errMessage:"Failed to reach server."})

          console.log(err);
        });
      if (response) {
        alert("Tweets succesfully submitted.");

        document.location.reload();
      }
    }
  }

  async updateTweet(index, tweet) {
    console.log(index);
    console.log(tweet);
    if (index && tweet) {
      let tweets = this.state.tweets;
      tweets[index] = tweet;

      this.setState({ tweets });
    }
  }

  async validateForm() {
    let success = true;

    for (let i = 0; i < this.state.tweets.length; i++) {
      let tweet = this.state.tweets[i];

      if (tweet.irrelevant) {
        if (!tweet.time_type) {
          alert("Missing time type tweet " + i);
          success = false;
        }
      } else if (!tweet.duplicate) {
        if (!tweet.category || !tweet.sub_category) {
          alert("Missing Categories tweet " + i);
          success = false;
        }

        if (!tweet.time_type) {
          alert("Missing time type tweet " + i);
          success = false;
        }
      }
    }
    if (!this.state.user || this.state.user.length < 3) {
      alert("Enter you name.");
      success = false;
    }

    if (success === true) {
      await this.submitTweets();
    }
  }

  handleSubmit = () => {
    this.validateForm();
  };

  handleChange = (e) => {
    //   console.log(e.target.value)
    //   console.log(e.target.className)
    if (["name"].includes(e.target.className)) {
      let user = e.target.value;
      this.setState({ user });
    }
  };

  showAdvancedMediaContent = () => {
    let tweets = this.state.tweets;
    if (tweets) {
      return (
        <div>
          {tweets.length != 0
            ? tweets.map((mediaitem, index) => (
                <TweetTrainingCard
                  key={index}
                  id={index}
                  title={mediaitem.title}
                  full_tweet={mediaitem}
                  tweet={mediaitem.tweet}
                  date={mediaitem.created_at}
                  image={mediaitem.image}
                  tweet_id={mediaitem.id}
                  location_address={mediaitem.location_address}
                  username={mediaitem.username}
                  datetime={mediaitem.datetime}
                  entities={mediaitem.entities}
                  category={mediaitem.category}
                  updateTweet={this.updateTweet}
                  roads={mediaitem.roads}
                  towns={mediaitem.towns}
                  query={mediaitem.query}
                  geocoder={mediaitem.geocoder}
                  score={mediaitem.score}
                  loc_accuracy={mediaitem.loc_accuracy}
                />
              ))
            : "No Media Content Available"}
        </div>
      );
    } else {
      return "";
    }
  };

  render() {
    if (this.state.isLoading === true)
      return (
        <React.Fragment>
          <PropertyHeader
            user_permissions={this.props.userData.data.permissions}
            user_portfolio_type={this.props.userData.data.portfolio_type}
            organisation={this.props.userData.data.orgid}
            secret={this.props.user.user.signInUserSession.idToken.jwtToken}
            logout={() => Logout(this.props)}
            locationData2={this.state.locationData2}
            orgnisation={this.props.userData.data.org_id}
            customstyle={this.props.userData.data.customstyle}
            updateLossIncidents={this.updateLossIncidents}
            updateMitigations={this.updateMitigations}
            currentpage={this.state.current_page}
          />
          <Loader color="#0652dd" />
        </React.Fragment>
      );

    return (
      <React.Fragment>
        <div>
          {/* Diplays section with property name, address */}
          <div className="Propertydetailsection">
            <div className="riskPagesWrap">
              {this.showAdvancedMediaContent()}
            </div>

            <form>
              <div>
                <label htmlFor="name">Name: </label>
                <div className="pt-2">
                  <input
                    type="name"
                    id="name"
                    className="name"
                    name="name"
                    onChange={this.handleChange}
                    placeholder=""
                  />
                </div>
              </div>

              <div>
                <button
                  id="changedetails"
                  className="form-control"
                  type="button"
                  onClick={this.handleSubmit}
                >
                  Submit
                </button>

                <br />
              </div>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const {
    userLogged,
    user,
    userData,
    systemData,
    mapAlertData,
    mapLocationData,
    dashboardData,
  } = state;
  return {
    userLogged,
    user,
    userData,
    systemData,
    mapAlertData,
    mapLocationData,
    dashboardData,
  };
}

export default connect(mapStateToProps)(TweetTraining);
