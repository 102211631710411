import React from "react";
import "./Displayrisk.css";
import MediumMap from "../maps/MediumMap";
import { Riskrating } from "../maps/riskrating";
import ReactTooltip from "react-tooltip";

export class Displayrisk extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      risk_rating: this.props.riskrating,
    };
  }

  Riskchartscore() {
    switch (this.state.risk_rating) {
      case 1:
        return 1;
      case 2:
        return 2;
      case 3:
        return 3;
      case 4:
        return 6;
      default:
        return 0;
    }
  }

  render() {
    return (
      <div className="Riskhighlights">
        <h3 className="Riskhighlightsheading">
          {this.props.title + " | " + this.props.source}
        </h3>
        <div className="Riskcontent">
          <div className="riskmap">
            {console.log(this.props)}
            {this.props.title === "Church Metal Theft Risk" ? (
              <MediumMap geoFeature={this.props.geoFeature} type={"church"} />
            ) : this.props.title === "Rivers and Seas Flood Risk" ? (
              <MediumMap geoFeature={this.props.geoFeature} type={"rofrs"} />
            ) : (
              <MediumMap geoFeature={this.props.geoFeature} type={"rofsw"} />
            )}

            <div className="mapkey">
              <h4>
                Map Key{" "}
                <a data-tip data-for={this.props.title}>
                  <span className="glyphicon glyphicon-info-sign" />
                </a>
              </h4>
            </div>
          </div>
          <div className="riskright">
            <div className="riskdetailsection">
              <Riskrating
                title={this.props.title}
                risk_rating={this.Riskchartscore()}
                max_score={this.props.max_score}
                rating_text={this.props.riskratingdescription}
              />
              <div className="risk_insight_text">
                <p>
                  <img src={require("../../images/idea.png")} />
                  {this.props.p}
                </p>
              </div>
            </div>
          </div>
        </div>
        <ReactTooltip
          id="Rivers and Seas Flood Risk"
          aria-haspopup="true"
          role="example"
        >
          <p>Rivers and Seas Flood Risk Definition</p>
          <ul>
            <li className="riskhigh">
              <span className="glyphicon glyphicon glyphicon-asterisk" />
              High : each year, this area has a chance of flooding of greater
              than 1 in 30 (3.3%)
            </li>
            <li className="riskmedium">
              <span className="glyphicon glyphicon glyphicon-asterisk" />
              Medium : each year, this area has a chance of flooding of between
              1 in 100 (1%) and 1 in 30 (3.3%)
            </li>
            <li className="risklow">
              <span className="glyphicon glyphicon glyphicon-asterisk" />
              Low : each year, this area has a chance of flooding of between 1
              in 1000 (0.1%) 1 in 100 (1%)
            </li>
            <li className="riskverylow">
              <span className="glyphicon glyphicon glyphicon-asterisk" />
              Very Low - This Sainsbury’s location has a chance of flooding each
              year of less than 1 in 1000 (0.1%)
            </li>
          </ul>
        </ReactTooltip>
        <ReactTooltip
          id="Surface Water Risk"
          aria-haspopup="true"
          role="example"
        >
          <p>Surface Water Flood Risk Definition</p>
          <ul>
            <li className="riskhigh">
              <span className="glyphicon glyphicon glyphicon-asterisk" />
              High : each year, this area has a chance of flooding of greater
              than 1 in 30 (3.3%)
            </li>
            <li className="riskmedium">
              <span className="glyphicon glyphicon glyphicon-asterisk" />
              Medium : each year, this area has a chance of flooding of between
              1 in 100 (1%) and 1 in 30 (3.3%)
            </li>
            <li className="riskverylow">
              <span className="glyphicon glyphicon glyphicon-asterisk" />
              Low : each year, this area has a chance of flooding of between 1
              in 1000 (0.1%) 1 in 100 (1%)
            </li>
          </ul>
        </ReactTooltip>
      </div>
    );
  }
}
export default Displayrisk;
