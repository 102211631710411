import React from "react";

import "./Sidebar.css";

/**
 * Sidebar of the application, containing layers/filters.
 */
export class Sidebar extends React.Component {
  render() {
    return <div className="sidebar">{this.props.children}</div>;
  }
}

export default Sidebar;
