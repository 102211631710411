import React from 'react'
import { GoogleMap } from './maps/GoogleMap'
import { CloseButton } from './maps/Controls'
import { OptionCategory } from './maps/Options'
import * as styles from './maps/styles'
import * as gmaps from './maps/gmaps'
import * as urls from './maps/urls'
import { Route, Redirect, BrowserRouter, withRouter } from 'react-router-dom'
import './TestDashboard.css'
import PropertySubHeader from './PropertySubHeader'
import RiskBar from './riskReport/Riskbar.js'
import recommendations_extract from '../tempjson/recommendations.json'
import value_risk from '../tempjson/value_risk.json'
import { activeDashboardLayers } from './maps/config'
import ProgressBar from 'react-bootstrap/ProgressBar'
import ReactTooltip from 'react-tooltip'
import { isMobile } from 'react-device-detect'
import Card from 'react-bootstrap/Card'
import Button from 'balkerne-components/Button'
import Badge from 'balkerne-components/Badge'
import Can from 'balkerne-components/Can'
import { check } from 'balkerne-components/Can'
import axios from 'axios'
import i from './general/TemplateManager.module.scss'
//const value_risk = React.lazy(() => import('../tempjson/value_risk.json'))
//const Badge = React.lazy(() => import('balkerne-components/Badge'))

class Notice extends React.Component {
  render() {
    return (
      <div className="notice__box">
        <img className="notice__icon" src={this.props.icon} alt="" />
        <div className="notice__content">{this.props.children}</div>
      </div>
    )
  }
}

/**
 * Dashboard of the application. (derived from the original Dashboard.js)
 */

export class TestDashboard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mounted: false,
      map: null,
      infoWindow: null,
      layers: activeDashboardLayers,
      feature: this.props.feature,
      geoFeature: this.props.geoFeature,
      flood_risks: this.props.flood_risks,
      metal_theft_risks: this.props.metal_theft_risks,
      currentRecommendations: [],
      mitigationList: this.props.mitigations,
      weather_risks: this.props.weather_risks,
      crime_risks: this.props.crime_risks,
      alerts: this.props.alerts,
      activeweatherwarnings: this.props.activeweatherwarnings,
      activealerts: [],
      risk_total: 0,
      locationData: this.props.feature,
      userData: this.props.user,
      historical: this.props.historical,
      latestgaugereadings: this.props.gaugereadings,
      user_permissions: this.props.user_permissions,
      formInstance: this.props.formInstance,
      mapLoadingstate: 0,
      ROFRS_SCORES: [0, 8, 16, 24, 32],
      ROFSW_SCORES: [0, 1, 2, 3, 4],
      CRIME_SCORES: [8, 16, 24, 32, 40],
      FIRE_SCORES: [8, 16, 24, 32, 40],
      FoodHygiene: [100, 50, 30, 15, 0],
    }

    this.onLayerChange = this.onLayerChange.bind(this)
    this.onMapLoad = this.onMapLoad.bind(this)
  }

  //sets mounted to true as all data was retrieved by the parent UserDashboard
  async componentDidMount() {
    this.getRecommendations()
    this.getWarningRecommendations()

    this.setState({ mounted: true })
  }

  nFormatter(num, digits) {
    var si = [
      { value: 1, symbol: '' },
      { value: 1e3, symbol: 'K' },
      { value: 1e6, symbol: 'M' },
      { value: 1e9, symbol: 'G' },
      { value: 1e12, symbol: 'T' },
      { value: 1e15, symbol: 'P' },
      { value: 1e18, symbol: 'E' },
    ]
    var rx = /\.0+$|(\.[0-9]*[1-9])0+$/
    var i
    for (i = si.length - 1; i > 0; i--) {
      if (num >= si[i].value) {
        break
      }
    }
    return (num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol
  }

  //loads the map with the given property polygon and data/tile layers
  onMapLoad(map) {
    this.setState({ mapLoadingstate: 0 })
    this.setState({ map })

    let layer = new window.google.maps.Data()

    layer.setStyle(styles.dashboardLocation)
    layer.addGeoJson(this.state.geoFeature)

    gmaps.zoom(map, layer)
    map.setZoom(map.getZoom() - 2)

    layer.setMap(map)

    for (let layerName in this.state.layers) {
      let layer = this.state.layers[layerName]

      switch (layer.type) {
        case 'data':
          if (layer.name === 'Crime') {
            let query = [
              'dst=2000',
              `lng=${this.state.locationData.lon}`,
              `lat=${this.state.locationData.lat}`,
              'previousMonths=6',
              'pointOnly=1',
            ].join('&')
            gmaps.heatmapLayerFromURL([layer.url, query].join('?')).then(heatmapLayer => {
              this.setState(state => {
                let layers = state.layers
                let layer = layers[layerName]
                layer.layer = heatmapLayer
                if (layer.visible) {
                  this.showLayer(state.map, layer)
                }
                return layers
              })
            })
          } else if (layer.name === 'Flood Alerts Property' || 'Flood Alerts Property Historical') {
            gmaps
              .dataLayerFromURL(
                layer.url + '?propid=' + this.state.feature.id + '&country=' + this.state.feature.address_country,
                this.props.secret,
              )
              .then(dataLayer => {
                if (layer.style) {
                  dataLayer.setStyle(layer.style)
                }
                if (layer.onClick) {
                  dataLayer.addListener('click', layer.onClick.bind(this))
                }
                this.setState(state => {
                  let layers = state.layers
                  let layer = layers[layerName]
                  layer.layer = dataLayer
                  if (layer.visible) {
                    this.showLayer(state.map, layer)
                  }
                  return layers
                })
              })
          } else {
            gmaps.dataLayerFromURL(layer.url, this.props.secret).then(dataLayer => {
              if (layer.style) {
                dataLayer.setStyle(layer.style)
              }
              if (layer.onClick) {
                dataLayer.addListener('click', layer.onClick.bind(this))
              }
              this.setState(state => {
                let layers = state.layers
                let layer = layers[layerName]
                layer.layer = dataLayer
                if (layer.visible) {
                  this.showLayer(state.map, layer)
                }
                return layers
              })
            })
          }
          break

        case 'tile':
          gmaps.tileLayerFromURL(layer.url, layer).then(tileLayer => {
            this.setState(state => {
              let layers = state.layers
              let layer = layers[layerName]
              layer.layer = tileLayer
              if (layer.visible) {
                this.showLayer(state.map, layer)
              }
              return layers
            })
          })
          break
        default:
      }
    }
    this.setState({ mapLoadingstate: 100 })
  }

  // changes the layer upon toggle clicks
  async onLayerChange(e) {
    let layerId = e.target.id
    this.setState(state => {
      let layers = state.layers
      let layer = layers[layerId]
      if (layer) {
        layer.visible = !layer.visible
      }
      if (layer.visible) {
        this.showLayer(state.map, layer)
      } else {
        this.hideLayer(state.map, layer)
      }
      return { layers }
    })
  }

  // redirects to riskreport page and sets state to viewing Alerts
  handleAlertButton = () => {
    this.props.history.push({
      pathname: '/riskreport',
      state: {
        trackrisks: 1,
        handleLogout: this.props.handleLogout,
      },
    })
  }
  // redirects to activity page and sets state to viewing Alerts
  handleActivityButton = () => {
    this.props.history.push({
      pathname: '/ListIncidents',
      state: {
        trackrisks: 1,
        handleLogout: this.props.handleLogout,
      },
    })
  }

  // redirects to riskreport page and sets state to viewing Alerts
  handleGauge = () => {
    this.props.history.push({
      pathname: '/riskreport',
      state: {
        trackrisks: 4,
        handleLogout: this.props.handleLogout,
      },
    })
  }

  // redirects to riskreport page and sets state to viewing Risk Insights
  handleRiskInsightsButton = () => {
    this.props.history.push({
      pathname: '/riskreport',
      state: {
        trackrisks: 2,
        handleLogout: this.props.handleLogout,
      },
    })
  }

  // redirects to riskreport page and sets state to viewing current mitigations
  handleMitigationsButton = () => {
    if (check('mitigations:read', null, this.props.userData.data.permissions)) {
      this.props.history.push({
        pathname: '/riskreport',
        state: {
          trackrisks: 3,
          handleLogout: this.state.handleLogout,
        },
      })
    } else {
      console.log('User permissions failed')
    }
  }

  // makes the given layer visible
  async showLayer(map, layer) {
    if (!layer) return
    switch (layer.type) {
      case 'data':
        gmaps.showDataLayer(map, layer.layer)
        break
      case 'tile':
        console.log('showing tile layer')
        gmaps.showTileLayer(map, layer.layer)
        break
      default:
    }
  }

  // hides the given layer
  async hideLayer(map, layer) {
    if (!layer) return
    switch (layer.type) {
      case 'data':
        gmaps.hideDataLayer(map, layer.layer)
        break
      case 'tile':
        gmaps.hideTileLayer(map, layer.layer)
        break
      default:
    }
  }

  // sets the layers to display real time or historical alerts given the prop from portfolio dashboard
  setLayer() {
    if (this.state.layers !== activeDashboardLayers && this.state.historical === false) {
      this.state.layers = activeDashboardLayers
    }
  }

  getAlertImage(severity_level) {
    switch (severity_level) {
      case 'Flood_1':
        return require('../images/Severe_Flood_Warning_small.png')

      case 'Flood_2':
        return require('../images/Flood_Warning_small.png')

      case 'Flood_3':
        return require('../images/Flood_Alert_small.png')
      case 'Arson_3':
      case 'Metal Theft_3':
      case 'Armed Robbery_3':
      case 8:
      case 9:
        return require('../images/crime_alert_small.png')
      case 'Rainfall_3':
      case 'Heavy Rainfall_3':
        return require('../images/rainfall_alert_small.png')
      case 'Wind_3':
      case 'High Wind_3':
        return require('../images/wind_alert_small.png')
      case 'High Temperature_3':
        return require('../images/temp_alert_small.png')
      case 'YELLOW':
        return require('../images/yellowwarning.png')
      case 'AMBER':
        return require('../images/amberwarning.png')
      case 'RED':
        return require('../images/redwarning.png')
      case 'Pandemic_3':
        return require('../images/pandemic_alert_small.png')
      case 'River_3':
        return require('../images/river_level_warning_small.png')
      case 'River_2':
        return require('../images/river_level_alert_small.png')
      default:
        return require('../images/alert_icon.png')
    }
    return '0'
  }

  showAlerts = () => {
    if (this.state.alerts && this.state.alerts.length) {
      return this.state.alerts.map((alerts, index) => (
        <Notice
          key={index}
          children={alerts.message}
          icon={this.getAlertImage((alerts.type || 'Flood') + '_' + alerts.severityLevel)}
        />
      ))
    }
    // UI updated to remove the need for this section
    // else {
    //   return (
    //     <Notice icon={require('../images/info.png')}>
    //       <p className="notice__text">There are no active flood alerts or warnings for this property</p>
    //     </Notice>
    //   )
    // }
  }

  showGaugeInsight = () => {
    if (
      this.props.gaugereadings &&
      Object.keys(this.props.gaugereadings) !== undefined &&
      Object.keys(this.props.gaugereadings).length != 0
    ) {
      console.log('Step 1')

      if (this.props.gaugereadings[1] && this.props.gaugereadings[1].length) {
        let latestgaugereadings = this.props.gaugereadings[1]
        let latestreading = latestgaugereadings.latest
        let typicalmax = latestgaugereadings.typical_max
        let record_max = latestgaugereadings.record_max

        let name = latestgaugereadings.name

        if (latestreading >= typicalmax) {
          return (
            <Notice
              key={1}
              children={
                'River level at ' +
                name +
                ' river gauge has reached ' +
                latestreading +
                'm, which is above its typical level. ' +
                'When the river reaches above ' +
                typicalmax +
                'm, flooding is possible in the area. Highest level on record: ' +
                record_max +
                'm.'
              }
              icon={require('../images/river_level_alert_small.png')}
            />
          )
        }
      } else if (this.props.gaugereadings && this.props.gaugereadings[2] && this.props.gaugereadings[2].length) {
        let latestgaugereadings = this.props.gaugereadings[2]
        let latestreading = latestgaugereadings.latest
        let typicalmax = latestgaugereadings.typical_max
        let record_max = latestgaugereadings.record_max

        let name = latestgaugereadings.name

        if (latestreading >= typicalmax) {
          return (
            <Notice
              key={1}
              children={
                'River level at ' +
                name +
                ' river gauge has reached ' +
                latestreading +
                'm, which is above its typical level. ' +
                'When the river reaches above ' +
                typicalmax +
                'm, flooding is possible in the area. Highest level on record: ' +
                record_max +
                'm.'
              }
              icon={require('../images/river_level_alert_small.png')}
            />
          )
        }
      }
    }
  }

  showWeatherWarnings = () => {
    if (this.state.activeweatherwarnings && this.state.activeweatherwarnings.length) {
      return this.state.activeweatherwarnings.map((warning, index) => (
        <Notice key={index} children={warning.summary} icon={require('../images/alert_icon.png')} />
      ))
    }
  }

  getRecommendations = () => {
    if (this.state.alerts && this.state.alerts.length) {
      return this.state.alerts.map(alerts =>
        this.matchRecommendations((alerts.type || 'Flood') + '_' + alerts.severityLevel),
      )
    }
  }

  getWarningRecommendations = () => {
    if (this.state.activeweatherwarnings && this.state.activeweatherwarnings.length) {
      return this.state.activeweatherwarnings.map(warning =>
        this.matchRecommendations((warning.warning_type || '') + '_' + warning.warning_level),
      )
    }
  }

  matchRecommendations = severity_level => {
    switch (severity_level) {
      case 'Flood_1':
        recommendations_extract.severewarningrecommendation.forEach(recommendation => {
          this.state.currentRecommendations.push(recommendation.description)
        })
        break

      case 'Flood_2':
        recommendations_extract.warningrecommendation.forEach(recommendation => {
          this.state.currentRecommendations.push(recommendation.description)
        })
        break

      case 'Flood_3':
        recommendations_extract.alertrecommendation.forEach(recommendation => {
          this.state.currentRecommendations.push(recommendation.description)
        })
        break

      case 'Arson_3':
        recommendations_extract.arson_building.forEach(recommendation => {
          this.state.currentRecommendations.push(recommendation.description)
        })
        break

      case 'Metal Theft_3':
        recommendations_extract.metaltheftguidance.forEach(recommendation => {
          this.state.currentRecommendations.push(recommendation.description)
        })
        break

      case 'Armed Robbery_3':
        recommendations_extract.armedrobbery2.forEach(recommendation => {
          this.state.currentRecommendations.push(recommendation.description)
        })
        break
      case 'Wind_3':
      case 8:
      case 9:
      case 'High Wind_3':
      case 'WIND_YELLOW':
        recommendations_extract.yellow_warning_wind.forEach(recommendation => {
          this.state.currentRecommendations.push(recommendation.description)
        })
        break
      case 'WIND_AMBER':
        recommendations_extract.amber_warning_wind.forEach(recommendation => {
          this.state.currentRecommendations.push(recommendation.description)
        })
        break
      case 'RAIN_YELLOW':
      case 'Rainfall_3':
      case 'Heavy Rainfall_3':
      case 'THUNDERSTORM_YELLOW':
      case 'RAIN':
        recommendations_extract.yellow_warning_rain.forEach(recommendation => {
          this.state.currentRecommendations.push(recommendation.description)
        })
        break
      case 'RAIN_AMBER':
        recommendations_extract.amber_warning_rain.forEach(recommendation => {
          this.state.currentRecommendations.push(recommendation.description)
        })
        break
      case 'FOG_YELLOW':
        recommendations_extract.yellow_warning_fog.forEach(recommendation => {
          this.state.currentRecommendations.push(recommendation.description)
        })
        break
      case 'FOG_AMBER':
        recommendations_extract.amber_warning_fog.forEach(recommendation => {
          this.state.currentRecommendations.push(recommendation.description)
        })
        break

        return recommendations_extract.norecommendation
    }
    return recommendations_extract.norecommendation
  }

  showRecommendations = () => {
    if (this.state.currentRecommendations.length != 0) {
      return this.state.currentRecommendations.map((recommendations, index) => (
        <Notice key={index} icon={require('../images/check-mark.png')}>
          <p className="notice__text">{recommendations}</p>
        </Notice>
      ))
    } else {
      // return (
      //   <Notice icon={require('../images/check-mark.png')}>
      //     <p className="notice__text">There are no active recommendations for this property</p>
      //   </Notice>
      // )
    }
  }

  // Renders activity page

  showActivity = () => {
    console.log('this.props.formInstance')
    console.log(this.props.formInstance)
    if (this.props.formInstance.length === 0) {
      return (
        <Notice icon={require('../images/check-mark.png')}>
          <p className="notice__text">There are no activities reported</p>
        </Notice>
      )
    } else {
      return this.props.formInstance.slice(0, 5).map((formlist, index) => (
        <Notice key={index} icon={require('../images/report.png')}>
          <p className="notice__text">
            <a
              className="mitigationurl"
              onClick={() => this.viewForm(formlist.instance_id)}
              /* onClick={() =>
                this.props.history.push({
                  pathname: '/LoadForm',
                  state: {
                    formId: formlist.instance_id,
                  },
                })
              } */
            >
              {formlist.title}
            </a>
            {' form was completed by ' + formlist.assignee_name + ' at ' + formlist.updated_at}
          </p>
        </Notice>
      ))
    }
  }
  showMitigations = () => {
    if (this.state.mitigationList && this.state.mitigationList.length) {
      return this.state.mitigationList.map((mitigation, index) => (
        <Notice key={index} icon={require('../images/mitigation.png')}>
          <p className="notice__title">{this.getMitigationType(parseInt(mitigation.mitigation_type))}</p>
          <span className="notice__text">
            {mitigation && mitigation.url && mitigation.attachments && mitigation.attachments != 'None'
              ? mitigation.attachments.map(attachment => {
                  const fileURL = [mitigation.url, attachment].reduce(
                    (url, path) => (url.endsWith('/') ? url + path : url + '/' + path),
                    urls.MITIGATIONS,
                  )
                  return (
                    <p>
                      <a className="mitigationurl" href={fileURL}>
                        {attachment}
                      </a>
                      <br />
                    </p>
                  )
                })
              : mitigation.description}
          </span>
        </Notice>
      ))
    } else {
      return (
        <Notice icon={require('../images/mitigation.png')}>
          <p className="notice__text">There are no active mitigations for this property</p>
        </Notice>
      )
    }
  }

  getMitigationType(mitigation_type) {
    switch (mitigation_type) {
      case 1:
        return 'Property Survey Report'
      case 2:
        return 'Flood Barrier'
      case 3:
        return 'Floodsax'
      case 4:
        return 'Flood Response Plan'
      case 5:
        return 'Technical Response'
      case 6:
        return 'Leak Detector'
      case 7:
        return 'Roof Alarm'
      case 8:
        return 'CCTV Camera'
      case 9:
        return 'Risk Management Advice'
      default:
        return 'None'
    }
  }
  getEPC(epc_rating) {
    let modified_rating = 0

    if (epc_rating > 150) {
      modified_rating = 7
    } else if (epc_rating >= 126) {
      modified_rating = 6
    } else if (epc_rating >= 101) {
      modified_rating = 5
    } else if (epc_rating >= 76) {
      modified_rating = 4
    } else if (epc_rating >= 51) {
      modified_rating = 3
    } else if (epc_rating >= 26) {
      modified_rating = 2
    } else if (epc_rating >= 0) {
      modified_rating = 1
    }
    return modified_rating
  }

  getRiskToolTipMessage(RiskRating) {
    switch (RiskRating) {
      case 'RiverFlood_0':
        return 'Negligible risk from flooding'
      case 'RiverFlood_1':
        return 'Very Low - this area has a chance of flooding each year of less than 1 in 1000 (0.1%)'
      case 'RiverFlood_2':
        return 'Low : each year, this area has a chance of flooding of between 1 in 1000 (0.1%) 1 in 100 (1%)'
      case 'RiverFlood_3':
        return 'Medium : each year, this area has a chance of flooding of between 1 in 100 (1%) and 1 in 30 (3.3%)'
      case 'RiverFlood_4':
        return 'High : High : each year, this area has a chance of flooding of greater than 1 in 30 (3.3%)'
      case 'SurfaceWater_0':
        return 'Negligible risk from flooding'
      case 'SurfaceWater_2':
        return 'Low : each year, this area has a chance of flooding of between 1 in 1000 (0.1%) 1 in 100 (1%)'
      case 'SurfaceWater_3':
        return 'Medium : each year, this area has a chance of flooding of between 1 in 100 (1%) and 1 in 30 (3.3%)'
      case 'SurfaceWater_4':
        return 'High : High : each year, this area has a chance of flooding of greater than 1 in 30 (3.3%)'
      case 'Crime_4':
        return 'High: Calculated based on frequency of specific crime incidents nearby each property'
      case 'Crime_3':
        return 'Medium: Calculated based on frequency of specific crime incidents nearby each property'
      case 'Crime_2':
        return 'Low: Calculated based on frequency of specific crime incidents nearby each property'
      case 'Crime_1':
        return 'Very Low: Calculated based on frequency of specific crime incidents nearby each property'
      case 'Crime_-1':
        return 'Undefined : Due to lack of crime data in this area, crime rating is not available'

      case 'Fire_4':
        return 'High: Calculated based on the assessed time taken for Fire Service resources to physically respond to a primary fire at the named location'
      case 'Fire_3':
        return 'Medium: Calculated based on the assessed time taken for Fire Service resources to physically respond to a primary fire at the named location'
      case 'Fire_2':
        return 'Low: Calculated based on the assessed time taken for Fire Service resources to physically respond to a primary fire at the named location'
      case 'Fire_1':
        return 'Very Low: Calculated based on the assessed time taken for Fire Service resources to physically respond to a primary fire at the named location'
      case 'Fire_-1':
        return 'Undefined : Due to lack of fire data in this area, fire rating is not available'
      case 'Covid_4':
        return (
          'The area around the property has a reported ' +
          this.state.flood_risks.deaths_100k +
          ' deaths per 100K population, as a result the area has Very High COVID related death rate compared to all areas nationally'
        )
      case 'Covid_3':
        return (
          'The area around the property has a reported ' +
          this.state.flood_risks.deaths_100k +
          ' deaths per 100K population, as a result the area has Medium COVID related death rate compared to all areas nationally'
        )
      case 'Covid_2':
        return (
          'The area around the property has a reported ' +
          this.state.flood_risks.deaths_100k +
          ' deaths per 100K population, as a result the area has Low COVID related death rate compared to all areas nationally'
        )
      case 'Covid_1':
        return (
          'The area around the property has a reported ' +
          this.state.flood_risks.deaths_100k +
          ' deaths per 100K population, as a result the area has Very Low COVID related death rate compared to all areas nationally'
        )

      case 'Covid_0':
        return 'Very Low : No COVID related deaths reported in the area'

      case 'Covid_null':
        return 'Undefined : Due to lack of health data in this area, COVID rating is not available'
    }
  }
  async viewForm(forminstanceid) {
    if (forminstanceid !== undefined) {
      const baseurl = 'https://r8fd6rsk66.execute-api.eu-west-1.amazonaws.com/Version1'
      // get form instance to retrieve template ID
      let path = '/forms/'
      let fetchurl = baseurl + path + forminstanceid // Get Saved form data
      let templateID = null
      let formData = null
      await axios.get(fetchurl).then(response => {
        //console.log('Loading saved form: ' + response.data.data)
        templateID = response.data.data.template_id
        formData = response.data
      })
      path = '/forms/templates/'
      fetchurl = baseurl + path + templateID // Get Form Template details
      //console.log('Getting form template with ID: ' + templateID)
      let formDetails = {}
      await axios.get(fetchurl).then(template_response => {
        //console.log(template_response.data)
        formDetails = JSON.stringify({
          title: template_response.data.data.title,
          template_id: template_response.data.data.id,
          version_id: template_response.data.data.version,
          instance_id: forminstanceid,
        })
        console.log(formDetails)
        this.props.history.push({
          pathname: '/DynamicForm',
          state: {
            templateSchema: JSON.parse(template_response.data.data.template),
            uiSchema: JSON.parse(template_response.data.data.uiSchema),
            formDetails: formDetails,
            formData: JSON.parse(formData.data.data),
            readonly: true,
            typeID: 2,
          },
        })
      })
    } else {
      console.log('No form ID detected')
    }
  }
  render() {
    //firstly sets the layer type for alerts on the map
    this.setLayer()

    const feature = this.state.locationData

    const name = this.state.locationData.name || 'Unknown'
    const addressFull = this.state.locationData.address_street || 'Address Unknown'
    const addresstown = this.state.locationData.address_town || 'Town Unknown'
    const addresscountry = this.state.feature.address_country || 'Country Unknown'
    const postcode = this.state.locationData.address_postcode || 'Postcode Unknown'
    const propertyreference = this.state.locationData.property_customer_reference || 'Reference Unknown'

    const phone = 'Phone Number Unknown'

    const user_permissions = this.state.user_permissions

    let riskRivers = 0
    let riskSurfacewater = 0
    let riskCrime = 0
    let riskFire = 0
    let riskFoodHygiene = 0

    let floodalertscore = 0
    let weatherscore = 0

    let epc_rating = 0
    this.props.epc != null ? (epc_rating = this.props.epc.info.current_rating) : (epc_rating = -1)
    let totalScore = 0
    let covid_tier
    let rebuild = 0
    let turnover = 0
    let inventory = 0
    let mel = 0
    let maxdisconnection = 0
    let likelydisconnection = 0

    let value_risk_filtered = []
    if (this.state.locationData && this.state.locationData.property_customer_reference) {
      console.log('Customer Reference')
      console.log(this.state.locationData.property_customer_reference)
      value_risk_filtered = value_risk
        .filter(value => value.id === this.state.locationData.property_customer_reference)
        .map(({ id, retailer, rebuild, turnover, inventory, maxdisconnection, likelydisconnection, mel }) => ({
          id,
          retailer,
          rebuild,
          turnover,
          maxdisconnection,
          likelydisconnection,
          inventory,
          mel,
        }))

      if (value_risk_filtered[0] != null) {
        rebuild = value_risk_filtered[0].rebuild
        turnover = value_risk_filtered[0].turnover
        inventory = value_risk_filtered[0].inventory
        mel = value_risk_filtered[0].mel
        maxdisconnection = value_risk_filtered[0].maxdisconnection
        likelydisconnection = value_risk_filtered[0].likelydisconnection
        // console.log('value at risk')
        // console.log(value_risk_filtered[0])
      }
    }

    if (this.state.flood_risks !== null) {
      //checks of user has subscribed to flooding
      this.state.userData.organisation.flooding == 'True'
        ? (riskRivers = this.state.ROFRS_SCORES[this.state.flood_risks.rofrs_value] || 0)
        : (riskRivers = -1)

      //checks of user has subscribed to flooding
      this.state.userData.organisation.flooding == 'True'
        ? (riskSurfacewater = this.state.ROFRS_SCORES[this.state.flood_risks.rofsw_value] || 0)
        : (riskSurfacewater = -1)

      //checks of user has subscribed to crime
      this.state.userData.organisation.crime == 'True'
        ? (riskCrime = this.state.ROFRS_SCORES[this.state.flood_risks.crime] || 0)
        : (riskCrime = -1)
      //checks of user has subscribed to fire
      this.state.userData.organisation.fire == 'True'
        ? (riskFire = this.state.ROFRS_SCORES[this.state.flood_risks.fire] || 0)
        : (riskFire = -1)
      //checks of user has subscribed to food_hygiene
      this.state.userData.organisation.food_hygiene == 'True'
        ? (riskFoodHygiene = this.state.flood_risks.food_hygiene || 0)
        : (riskFoodHygiene = -1)
    }

    if (this.state.alerts && this.state.alerts.length > 0) {
      floodalertscore = 3
    }

    if (this.state.activeweatherwarnings && this.state.activeweatherwarnings.length) {
      weatherscore = 5
    }
    totalScore = riskRivers + riskSurfacewater + riskCrime + riskFire + floodalertscore + weatherscore

    const lat = this.state.locationData.lat
    const lon = this.state.locationData.lon
    // Get number of messages
    const numberOfRecommendations = this.state.currentRecommendations.length || 0
    const numberOfMitigations = this.state.mitigationList.length || 0
    const numberOfIncidents = this.props.formInstance.length || 0

    const numberOfAlerts = [
      this.state.alerts ? this.state.alerts.length : 0,
      this.state.gaugereadings ? this.state.gaugereadings.length : 0,
      this.state.activeweatherwarnings ? this.state.activeweatherwarnings.length : 0,
    ].reduce((sum, length) => sum + length, 0)
    // if (this.props.covid_tiers != undefined) {
    //   if (this.props.covid_tiers.restrictions != undefined) {
    //     if (covid_tier == "National") covid_tier = this.props.covid_tiers.restrictions + " Restrictions";
    //     else covid_tier = this.props.covid_tiers.restrictions + " Lockdown";
    //   } else if (this.props.covid_tiers.tier != undefined) {
    //     covid_tier = this.props.covid_tiers.tier + " Alert Level";
    //   } else {
    //     covid_tier = "COVID Tier Not Available";
    //   }
    // } else {
    //   covid_tier = "COVID Tier Not Available";
    // }

    if (isMobile) {
      return (
        <div>
          <div className="PropertyDashboard">
            <PropertySubHeader
              propertyname={name}
              propertyaddress={addressFull + ', ' + addresstown + ', ' + addresscountry + ', ' + postcode}
              propertyreference={propertyreference}
              organisationlogo={this.state.userData.org_url}
            />
            <div className="dashboardcontent">
              <div id="dashboardleft" className="dashboard_left"></div>

              {/* <div className="dashboard_right">
                <div className="dashboard__alerts">
                  <h2 className="dashboard__notices_title dashboard__notices_title--alerts">
                    Alerts
                    <button
                      id="viewmore"
                      className="Alertsriskreport"
                      onClick={this.handleAlertButton}
                      style={this.props.user.customstyle}>
                      View More
                    </button>
                  </h2>
                  {this.showAlerts()}
                  {this.showWeatherWarnings()}
                </div>
                <div className="dashboard__recommendations">
                  <h2 className="dashboard__notices_title dashboard__notices_title--recommendations">
                    Recommendations
                  </h2>

                  {this.showRecommendations()}
                </div>

                <div className="dashboard__mitigation">
                  <h2 className="dashboard__notices_title dashboard__notices_title--activity">
                    Mitigations
                    <button
                      id="viewmore"
                      className="Mitigationsriskreport"
                      onClick={this.handleMitigationsButton}
                      style={this.props.user.customstyle}>
                      View More
                    </button>
                  </h2>
                  {this.showMitigations()}
                </div>
              </div> */}

              <div className="dashboard_right">
                <Card>
                  <Card.Header className="d-flex justify-content-between align-items-center">Risk Ratings</Card.Header>
                  <div className="dashboard__risk_breakdown">
                    <RiskBar label="River and Coastal Flood" max="100" value={riskRivers} />
                    <RiskBar label="Surface Water Flood" max="100" value={riskSurfacewater} />
                    <RiskBar label="Fire" max="100" value={riskFire} />
                    <RiskBar label="Crime" max="100" value={riskCrime} />
                  </div>
                </Card>

                <Card>
                  <Card.Header className="d-flex justify-content-between align-items-center">
                    <h4>
                      Alerts
                      <Badge className="ml-1" variant="primary" pill>
                        {numberOfAlerts}
                      </Badge>
                    </h4>
                  </Card.Header>
                  {numberOfAlerts > 0 ? (
                    <div>
                      <div className="dashboard-card-body">
                        {this.showAlerts()}
                        {this.showWeatherWarnings()}
                        {/* {this.showGaugeInsight()} */}
                      </div>
                      <Button light size="small" onClick={this.handleAlertButton} className="d-none d-md-flex">
                        View more
                      </Button>
                    </div>
                  ) : null}
                </Card>

                <Card>
                  <Card.Header>
                    <h4>
                      Recommendations
                      <Badge className="ml-1" variant="primary" pill>
                        {numberOfRecommendations}
                      </Badge>
                    </h4>
                  </Card.Header>
                  {numberOfRecommendations > 0 ? (
                    <div className="dashboard-card-body">{this.showRecommendations()}</div>
                  ) : null}
                </Card>
                <Card>
                  <Card.Header>
                    <h4>
                      Activity
                      <Badge className="ml-1" variant="primary" pill>
                        {numberOfIncidents}
                      </Badge>
                    </h4>
                  </Card.Header>
                  {numberOfIncidents > 0 ? (
                    <div>
                      <div className="dashboard-card-body">{this.showActivity()}</div>
                      <Button light size="small" onClick={this.handleActivityButton} className="d-none d-md-flex">
                        View more
                      </Button>
                    </div>
                  ) : null}
                </Card>
                <Card>
                  <Card.Header className="d-flex justify-content-between align-items-center">
                    <h4>
                      Mitigations
                      <Badge className="ml-1" variant="primary" pill>
                        {numberOfMitigations}
                      </Badge>
                    </h4>
                  </Card.Header>
                  {numberOfMitigations > 0 ? (
                    <div>
                      <div className="dashboard-card-body">
                        <Can
                          permissions={user_permissions}
                          actions="mitigations:read"
                          yes={() => this.showMitigations()}
                        />
                      </div>
                      <Can
                        permissions={user_permissions}
                        actions="mitigations:read"
                        yes={() => (
                          <Button
                            light
                            size="small"
                            onClick={this.handleMitigationsButton}
                            className="d-none d-md-flex">
                            View more
                          </Button>
                        )}
                      />
                    </div>
                  ) : null}
                </Card>
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      let dashboardLayers = []
      for (let layerId in activeDashboardLayers) {
        let layer = activeDashboardLayers[layerId]
        let option = (
          <OptionCategory
            key={layerId}
            label={layer.name}
            id={layerId}
            checked={this.state.layers[layerId].visible}
            onChange={this.onLayerChange}
            opt="dashboard"
          />
        )
        dashboardLayers.push(option)
      }
      // Get number of messages
      const numberOfRecommendations = this.state.currentRecommendations.length || 0
      const numberOfMitigations = this.state.mitigationList.length || 0
      const numberOfIncidents = this.props.formInstance.length || 0

      const numberOfAlerts = [
        this.state.alerts ? this.state.alerts.length : 0,
        this.state.gaugereadings ? this.state.gaugereadings.length : 0,
        this.state.activeweatherwarnings ? this.state.activeweatherwarnings.length : 0,
      ].reduce((sum, length) => sum + length, 0)
      //Renders the Dashboard
      return (
        <div>
          <div
            style={{
              left: 0,
              marginLeft: 40,
              display: 'flex',
              alignItems: 'center',
              marginBottom: 20,
              marginTop: 20,
            }}>
            <a style={{ cursor: 'pointer', display: 'flex' }}>
              <img style={{ marginRight: 10 }} src={require('../images/icons/Back.svg')}></img>
              <p className={i.link}>Property Dashboard</p>
            </a>
            <p style={{ marginLeft: 10, marginRight: 10, marginBottom: 'auto', marginTop: 'auto' }}> / </p>
          </div>
          <div className="PropertyDashboard">
            <PropertySubHeader
              propertyname={name}
              propertyaddress={addressFull + ', ' + addresstown + ', ' + addresscountry + ', ' + postcode}
              propertyreference={propertyreference}
              organisationlogo={this.props.logo}
            />
            <div className="dashboardcontent">
              <div id="dashboardleft" className="dashboard_left">
                {this.state.userData.orgid === 0 ? (
                  <div className="test_dashboard__risk">
                    <h2 className="dashboard__risk_score_title">Risk Score</h2>

                    <div className="dashboard__risk_breakdown">
                      <a data-tip data-for="River Flood">
                        <RiskBar label="River and Coastal Flood" max="100" value={riskRivers} />
                      </a>
                      <a data-tip data-for="Surface Water">
                        <RiskBar id="rating" label="Surface Water Flood" max="100" value={riskSurfacewater} />
                      </a>
                      <a data-tip data-for="Fire">
                        <RiskBar id="rating" label="Fire" max="100" value={riskFire} />
                      </a>
                      <a data-tip data-for="Crime">
                        <RiskBar id="rating" label="Crime" max="100" value={riskCrime} />
                      </a>
                    </div>
                  </div>
                ) : (
                  <div className="test_dashboard__risk">
                    {value_risk_filtered[0] != null ? (
                      <div>
                        <h2 className="dashboard__risk_score_title">Value at Risk(estimate)</h2>
                        <div className="dashboard__risk_breakdown">
                          <a data-tip data-for="valueatrisk">
                            <h4 className="dashboard__risk_score dashboard__risk_score--sub">
                              Rebuild and Fit Out Value:
                              <b>
                                £{this.nFormatter(rebuild * 0.8, 2)} - £{this.nFormatter(rebuild * 1.2, 2)}{' '}
                              </b>
                            </h4>
                            {turnover != null ? (
                              <h4 className="dashboard__risk_score dashboard__risk_score--sub">
                                Annual Turnover:{' '}
                                <b>
                                  £{this.nFormatter(turnover * 0.8, 2)} - £{this.nFormatter(turnover * 1.2, 2)}{' '}
                                </b>
                              </h4>
                            ) : (
                              ''
                            )}

                            {maxdisconnection != null ? (
                              <h4 className="dashboard__risk_score dashboard__risk_score--sub">
                                Max Disconnection:{' '}
                                <b>
                                  £{this.nFormatter(maxdisconnection * 0.8, 2)}
                                  -£
                                  {this.nFormatter(maxdisconnection * 1.2, 2)}{' '}
                                </b>
                              </h4>
                            ) : (
                              ''
                            )}
                            {likelydisconnection != null ? (
                              <h4 className="dashboard__risk_score dashboard__risk_score--sub">
                                Likely Disconnection:{' '}
                                <b>
                                  £{this.nFormatter(likelydisconnection * 0.8, 2)}
                                  -£
                                  {this.nFormatter(likelydisconnection * 1.2, 2)}{' '}
                                </b>
                              </h4>
                            ) : (
                              ''
                            )}
                            {inventory != null ? (
                              <h4 className="dashboard__risk_score dashboard__risk_score--sub">
                                Inventory Value:{' '}
                                <b>
                                  £{this.nFormatter(inventory * 0.8, 2)}-£
                                  {this.nFormatter(inventory * 1.2, 2)}{' '}
                                </b>
                              </h4>
                            ) : (
                              ''
                            )}
                            <h4 className="dashboard__risk_score dashboard__risk_score--sub">
                              Estimated Maxmimum Loss:{' '}
                              <b>
                                £{this.nFormatter(mel * 0.8, 2)}-£
                                {this.nFormatter(mel * 1.2, 2)}
                              </b>
                            </h4>
                          </a>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}

                    {totalScore > 0 ? (
                      <div>
                        <h2 className="dashboard__risk_score_title">Risk Score</h2>

                        <h3 className="dashboard__risk_score--main">
                          {floodalertscore > 0 ? (
                            <i
                              a
                              data-tip
                              data-for="increasedfloodrating"
                              id="increasedrating"
                              class="fa fa-chevron-circle-up"
                            />
                          ) : (
                            ''
                          )}

                          {totalScore}
                          <a className="dashboard__risk_score--small">/120</a>
                        </h3>
                      </div>
                    ) : (
                      ''
                    )}

                    <div className="dashboard__risk_breakdown">
                      {riskRivers != -1 ? (
                        <a data-tip data-for="River Flood">
                          <RiskBar id="demoscore" label="Rivers and Coastal Flood " max="40" value={riskRivers} />
                        </a>
                      ) : (
                        ''
                      )}
                      {riskSurfacewater != -1 ? (
                        <a data-tip data-for="Surface Water">
                          <RiskBar id="demoscore" label="Surface Water Flood" max="4" value={riskSurfacewater} />
                        </a>
                      ) : (
                        ''
                      )}
                      {riskFire != -1 ? (
                        <a data-tip data-for="Fire">
                          <RiskBar id="demoscore" label="Fire" max="40" value={riskFire} />
                        </a>
                      ) : (
                        ''
                      )}
                      {riskCrime != -1 ? (
                        <a data-tip data-for="Crime">
                          <RiskBar id="demoscore" label="Crime" max="40" value={riskCrime} />
                        </a>
                      ) : (
                        ''
                      )}
                      {riskFoodHygiene != -1 ? (
                        <a data-tip data-for="foodhygiene">
                          <h4 className="dashboard__risk_score dashboard__risk_score--sub">
                            Food Hygiene:{' '}
                            <b>{riskFoodHygiene < 1 ? (riskFoodHygiene = 'Not Inspected') : riskFoodHygiene + '/5'}</b>
                          </h4>
                        </a>
                      ) : (
                        ''
                      )}
                      {/* <h2 className="dashboard__risk_score_title">
                        Demo Ratings
                      </h2>

                      {
                        <a data-tip data-for="Crime">
                          <RiskBar
                            id="demoscore"
                            label="Historic Rainfall"
                            max="100"
                            value={60}
                          />
                        </a>
                      }
                      {
                        <a data-tip data-for="">
                          <RiskBar
                            id="demoscore"
                            label="Snow & Ice"
                            max="100"
                            value={40}
                          />
                        </a>
                      }
                      {
                        <a data-tip data-for="">
                          <RiskBar
                            id="demoscore"
                            label="Wind & Gale"
                            max="100"
                            value={60}
                          />
                        </a>
                      } */}
                      {/* {
                        <a data-tip data-for="">
                          <RiskBar
                            id="demoscore"
                            label="Lightning"
                            max="100"
                            value={10}
                          />
                        </a>
                      }
                      {
                        <a data-tip data-for="">
                          <RiskBar
                            id="demoscore"
                            label="Erosion"
                            max="100"
                            value={50}
                          />
                        </a>
                      }
                      {
                        <a data-tip data-for="">
                          <RiskBar
                            id="demoscore"
                            label="Earthquake"
                            max="100"
                            value={10}
                          />
                        </a>
                      }
                      {
                        <a data-tip data-for="">
                          <RiskBar
                            id="demoscore"
                            label="Safety"
                            max="100"
                            value={10}
                          />
                        </a>
                      } */}
                    </div>
                  </div>
                )}

                <div id="dashboardmap">
                  <Card>
                    <Card.Header>
                      <div className="d-flex align-items-center">
                        <h4 className="mr-2">Risk Map</h4>
                        <a data-tip data-for="Flood Risk">
                          <i className="fa fa-info-circle" />
                        </a>
                      </div>
                    </Card.Header>

                    {/* Map content */}
                    <ProgressBar variant="info" now={this.state.mapLoadingstate} />
                    <div className="dashboard__map">
                      <GoogleMap
                        id="mapDashboard"
                        className="map"
                        options={{
                          zoom: 15,
                          tilt: 0,
                          center: { lat: lat, lng: lon },
                          styles: styles.maps.mono,

                          zoomControl: true,
                          mapTypeControl: true,

                          styles: [
                            {
                              featureType: 'poi',
                              elementType: 'labels',
                              stylers: [
                                {
                                  visibility: 'simplified',
                                },
                              ],
                            },
                          ],
                        }}
                        apiKey="AIzaSyD9n6ddk1Z6E2L5hZqCVRo94I8lbHjm30Q"
                        onMapLoad={this.onMapLoad}
                      />
                    </div>

                    <div className="dashboard__map_controls">
                      {
                        // map layers are for flooding only, if user hasn't subscribed for flooding, don't show the layers
                        this.state.userData.organisation.flooding == 'True' ? dashboardLayers : ''
                      }
                    </div>
                  </Card>
                </div>
              </div>
              <div className="dashboard_right">
                <Card>
                  <Card.Header className="d-flex justify-content-between align-items-center">
                    <h4>
                      Alerts
                      <Badge className="ml-1" variant="primary" pill>
                        {numberOfAlerts}
                      </Badge>
                    </h4>
                  </Card.Header>
                  {numberOfAlerts > 0 ? (
                    <div>
                      <div className="dashboard-card-body">
                        {this.showAlerts()}
                        {this.showWeatherWarnings()}
                        {/* {this.showGaugeInsight()} */}
                      </div>
                      <Button light size="small" onClick={this.handleAlertButton} className="d-none d-md-flex">
                        View more
                      </Button>
                    </div>
                  ) : null}
                </Card>

                <Card>
                  <Card.Header className="d-flex justify-content-between align-items-center">
                    <h4>
                      Recommendations
                      <Badge className="ml-1" variant="primary" pill>
                        {numberOfRecommendations}
                      </Badge>
                    </h4>
                  </Card.Header>
                  {numberOfRecommendations > 0 ? (
                    <div className="dashboard-card-body">{this.showRecommendations()}</div>
                  ) : null}
                </Card>
                <Card>
                  <Card.Header className="d-flex justify-content-between align-items-center">
                    <h4>
                      Activity
                      <Badge className="ml-1" variant="primary" pill>
                        {numberOfIncidents}
                      </Badge>
                    </h4>
                  </Card.Header>
                  {numberOfIncidents > 0 ? (
                    <div>
                      <div className="dashboard-card-body">{this.showActivity()}</div>
                      <Button light size="small" onClick={this.handleActivityButton} className="d-none d-md-flex">
                        View more
                      </Button>
                    </div>
                  ) : null}
                </Card>
                <Card>
                  <Card.Header className="d-flex justify-content-between align-items-center">
                    <h4>
                      Mitigations
                      <Badge className="ml-1" variant="primary" pill>
                        {numberOfMitigations}
                      </Badge>
                    </h4>
                  </Card.Header>
                  {numberOfMitigations > 0 ? (
                    <div>
                      <div className="dashboard-card-body">
                        <Can
                          permissions={user_permissions}
                          actions="mitigations:read"
                          yes={() => this.showMitigations()}
                        />
                      </div>
                      <Can
                        permissions={user_permissions}
                        actions="mitigations:read"
                        yes={() => (
                          <Button
                            light
                            size="small"
                            onClick={this.handleMitigationsButton}
                            className="d-none d-md-flex">
                            View more
                          </Button>
                        )}
                      />
                    </div>
                  ) : null}
                </Card>
              </div>
            </div>
          </div>

          <ReactTooltip id="River Flood" delayShow={500} place="right" aria-haspopup="true" role="example">
            <p>Rivers and Seas Flood Risk Definition</p>
            <p>{this.getRiskToolTipMessage('RiverFlood' + '_' + this.state.flood_risks.rofrs_value)}</p>
          </ReactTooltip>
          <ReactTooltip id="Surface Water" delayShow={500} place="right" aria-haspopup="true" role="example">
            <p>Surface Water Flood Risk Definition</p>
            <p>{this.getRiskToolTipMessage('SurfaceWater' + '_' + this.state.flood_risks.rofsw_value)}</p>
          </ReactTooltip>
          <ReactTooltip id="Crime" delayShow={500} place="right" aria-haspopup="true" role="example">
            <p>Crime Risk Definition</p>
            <p>{this.getRiskToolTipMessage('Crime' + '_' + this.state.flood_risks.crime)}</p>
          </ReactTooltip>
          <ReactTooltip id="Fire" delayShow={500} place="right" aria-haspopup="true" role="example">
            <p>Fire Risk Definition</p>
            <p>{this.getRiskToolTipMessage('Fire' + '_' + this.state.flood_risks.fire)}</p>
          </ReactTooltip>
          <ReactTooltip id="Covid" delayShow={500} place="right" aria-haspopup="true" role="example">
            <p>Covid-19 Restrictions</p>
            <p>COVID-19 restrictions are in place across the UK, please check the risk report for further details</p>
          </ReactTooltip>
          <ReactTooltip id="foodhygiene" delayShow={500} place="right" aria-haspopup="true" role="example">
            <p>Food hygiene rating</p>
            <p>
              The food hygiene rating reflects the standards of food hygiene found on the date of the inspection by the
              local authority.
            </p>
          </ReactTooltip>
          <ReactTooltip id="Flood Risk" delayShow={500} place="right" aria-haspopup="true" role="example">
            <p className="riskdefinition">Rivers and Surface Water Flood Risk Definition</p>
            <ul>
              <li id="riskhigh">
                <i class="fa fa-asterisk" />
                High : each year, this area has a chance of flooding of greater than 1 in 30 (3.3%)
              </li>
              <li id="riskmedium">
                <i class="fa fa-asterisk" />
                Medium : each year, this area has a chance of flooding of between 1 in 100 (1%) and 1 in 30 (3.3%)
              </li>
              <li id="risklow">
                <i class="fa fa-asterisk" />
                Low : each year, this area has a chance of flooding of between 1 in 1000 (0.1%) 1 in 100 (1%)
              </li>
              <li id="riskverylow">
                <i class="fa fa-asterisk" />
                Very Low - this area has a chance of flooding each year of less than 1 in 1000 (0.1%)
              </li>
            </ul>
            <p className="riskdefinition">Historical Flooding Definition</p>
            <ul>
              <li id="historicfloodingkey">
                <i class="fa fa-asterisk" />
                Area has historically flooded
              </li>
            </ul>
          </ReactTooltip>
          <ReactTooltip id="increasedfloodrating" place="right" delayShow={500} aria-haspopup="true" role="example">
            <p> Score increase due to emerging risk and alert issued to property</p>
          </ReactTooltip>
        </div>
      )
    }
  }
}
export default withRouter(TestDashboard)
