import React from "react"
import ModalImage from "react-modal-image"
import PropTypes from "prop-types"
import extensionToMime from "./extensionToMime"
import FileIcon from "balkerne-components/FileIcon"
import c from './FileImage.module.scss'
import cn from "classnames"

const FileImage = ({ url }) => {
  console.log('FILE IMAGE ---------------------')
  let extension
  try {
    extension = url.split("/").slice(-1)[0].split(".").slice(-1)[0].toLowerCase()
  } catch {
    console.warn("Failed to parse file extension")
    extension = ""
  }

  const mimeType = extensionToMime(extension)
  console.log(mimeType)

  return (
    <div className={c.icon}>
      {"image" === mimeType.split("/")[0] ? (
        <ModalImage small={url} large={url} showRotate={true} className={c.image} />
      ) : (
        <a href={url} target='_blank'>
          <FileIcon mimeType={mimeType} size='normal' />
        </a>
      )}
    </div>
  )
}

export default FileImage
