import Iframe from 'react-iframe'
import React from 'react'

import axios from 'axios'
import * as urls from '../components/maps/urls'
import { RefreshAuthTokens } from '../components/RefreshAuthTokens'
import { connect } from 'react-redux'
import Header from '../components/Header'
import Loader from '../components/spinner'
import { Logout } from '../components/Logout'
class QuickSightDashboard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      quicksight_url: null,
      dashboard_id: props.location.state.id,
    }
  }

  async componentDidMount() {
    await RefreshAuthTokens(this.props)

    let quicksight_url = null

    let session_tags = [
      {
        Key: 'org_id_tag',
        Value: 1,
      },
    ]

    let data = { dashboard_id: this.state.dashboard_id }

    await axios({
      method: 'post',
      url: urls.API_GATEWAY + 'quicksight/dashboard',
      data: data,
    })
      .then(res => {
        let response = res.data
        if (
          response &&
          response != 'None' &&
          response != 'Failure' &&
          response != 'Invalid Details' &&
          response.length > 10
        ) {
          quicksight_url = response
        }
      })
      .catch(err => {
        console.log('FAILURE getting quicksight url!!')
        console.log(err)
      })

    if (quicksight_url) {
      this.setState({ quicksight_url })
    }
  }

  render() {
    // console.log(Data)
    console.log(this.state.dashboard_id)
    console.log('dashboard_id')

    if (this.state.quicksight_url) {
      return (
        <div>
          <Header
            user_permissions={this.props.userData.data.permissions}
            user_portfolio_type={this.props.userData.data.portfolio_type}
            logout={() => Logout(this.props)}
            customstyle={this.props.userData.data.customstyle}
            organisation={this.props.userData.data.orgid}
          />
          <Iframe
            url={this.state.quicksight_url}
            width="100%"
            height="700px"
            id="myId"
            className="myClassname"
            display="initial"
            position="relative"
          />
        </div>
      )
    } else {
      return (
        <div>
          <Loader />
        </div>
      )
    }
  }
}

function mapStateToProps(state) {
  //console.log(state);
  const { userLogged, user, userData } = state

  return {
    userLogged,
    user,
    userData,
  }
}
export default connect(mapStateToProps)(QuickSightDashboard)
