import React from 'react';
import './GoogleMap.css';

export class GoogleMapDraw extends React.Component {
    constructor(props) {
        super(props);
    this.state = {
        polygon:null,
        marker:null,
        area:null,
        center:null,
        coords:null


    }
        this.onScriptLoad = this.onScriptLoad.bind(this)
    }

    async onScriptLoad() {
        this.state.coords =this.props.coords;
   
        const map = await new window.google.maps.Map(
            document.getElementById(this.props.id),
            this.props.options
        );

        var centerControlDiv = document.createElement('div');
       // console.log(centerControlDiv)
        centerControlDiv = this.deleteButton(centerControlDiv, map);
        //console.log(centerControlDiv)
        //centerControlDiv.index = 1;
        map.controls[window.google.maps.ControlPosition.RIGHT_CENTER].push(centerControlDiv);
        //console.log(centerControlDiv)
        

        
        var drawingManager = await new window.google.maps.drawing.DrawingManager({
            drawingMode: window.google.maps.drawing.OverlayType.POLYGON,
            drawingControl: true,
            drawingControlOptions: {
              position: window.google.maps.ControlPosition.TOP_CENTER,
              drawingModes: ['polygon']
            },
            markerOptions: {icon: 'https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png'},
            circleOptions: {
              fillColor: '#ffff00',
              fillOpacity: 1,
              strokeWeight: 5,
              clickable: false,
              editable: true,
              zIndex: 1
            }
          });
          drawingManager.setMap(map);

          

           window.google.maps.event.addListener(drawingManager, 'overlaycomplete', (event)=> {
            switch (event.type) {
            case window.google.maps.drawing.OverlayType.POLYGON:
                    if(this.state.polygon!=null){
                        this.state.polygon.setMap(null);
                    }
                
                let polygon=event.overlay;
                this.state.reset = true;
                this.state.area = window.google.maps.geometry.spherical.computeArea(polygon.getPath());

            
                    var bounds = new window.google.maps.LatLngBounds()
                    polygon.getPath().forEach(function(element,index){bounds.extend(element)})
                   
                    this.state.center = bounds.getCenter()
                   console.log(this.state.center)

                   this.state.polygon = polygon;
                   this.props.getCenter(this.state.center)
                this.props.getPolygon(polygon)
                this.props.getArea(this.state.area)
                //this.overlayClickListener(polygon);
            case window.google.maps.drawing.OverlayType.CIRCLE:
               // verifyMarkers(event.overlay, markers);
                break;
            case window.google.maps.drawing.OverlayType.MARKER:
                //marker=event.overlay;
                ///this.props.getMarker(marker)
                //output.innerHTML += "Marker #" + markers.length + " has been added <br />";
                break;
            default:
            }
        }


        );
       

        // window.google.maps.event.addListener(drawingManager, 'click', () =>{
        //     if(reset==true&&tpolygon!=null){
        //         polygon.setMap(null);
        //         reset=false
        //     }
        //     console.log('Vertex removed from inner path.');
        //   });
       



        // if(center!= null){
        // var marker = new window.google.maps.Marker({
        //     position: { lat: 53.885501551104400, lng: -1.262298812800550 },
        //     map: map,
        //     title: 'Target Location'
        //   });
   
    //}
        
        this.props.onMapLoad(map)
    }
    

    async componentDidMount() {
        if (!window.google) {
            console.log("LOAD 1")
            var s = document.createElement('script');
          
            s.type = 'text/javascript';
            s.src = `https://maps.google.com/maps/api/js?v=quarterly&key=${this.props.apiKey}&libraries=drawing,visualization`;
            var x = document.getElementsByTagName('script')[0];
            x.parentNode.insertBefore(s, x);
            // Below is important.
            // We cannot access google.maps until it's finished loading
            s.addEventListener('load',async e => {
                 await this.onScriptLoad()
            })
        }
        // else if (document.getElementsByTagName('Google Map Normal')[0]) {
        //     console.log("LOAD 3")
        //     let element = (document.getElementsByTagName('Google Map Normal')[0]);
        //     // element.tagName = "Google Map Draw"
        //     element.src =`https://maps.google.com/maps/api/js?v=quarterly&key=${this.props.apiKey}&libraries=drawing,visualization`;
        //     element.addEventListener('load',async e => {
        //         await this.onScriptLoad()
        //    })
        // }
           else{
            console.log("LOAD 3")
            await this.onScriptLoad()
           }
           
        
    }
    deleteShapes=() =>{
        if(this.state.polygon!=null){
            this.state.polygon.setMap(null);
        }
        if(this.state.marker!=null){
            this.state.marker=null
        }
        if(this.state.area!=null){
            this.state.area= null;
        }
        if(this.state.center!=null){
            this.state.center= null;
        }
    }

     deleteButton=(controlDiv, map)=>{
        console.log(controlDiv)
        // Set CSS for the control border.
        var controlUI = document.createElement('div');
        controlUI.className="Delete Button"
        controlUI.style.backgroundColor = '#fff';
        controlUI.style.border = '2px solid #fff';
        controlUI.style.borderRadius = '3px';
        controlUI.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)';
        controlUI.style.cursor = 'pointer';
        controlUI.style.marginBottom = '22px';
        controlUI.style.textAlign = 'center';
   
        controlUI.title = 'Click to clear the polygons';
        controlDiv.appendChild(controlUI);

        // Set CSS for the control interior.
        var controlText = document.createElement('div');
        controlText.style.color = 'rgb(25,25,25)';
        controlText.style.fontFamily = 'Roboto,Arial,sans-serif';
        controlText.style.fontSize = '16px';
        controlText.style.lineHeight = '38px';
        controlText.style.paddingLeft = '5px';
        controlText.style.paddingRight = '5px';
        controlText.innerHTML = 'Reset';
        controlUI.appendChild(controlText);

        // Setup the click event listeners: simply set the map to Chicago.
        controlUI.addEventListener('click', () =>{
         this.deleteShapes();
        });

        controlDiv.index = 1;
        return controlDiv;
      }

    render() {
        return (
            <div className={this.props.className} id={this.props.id} />
        );
    }
}