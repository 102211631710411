// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

import  {MyStorage}  from './redux/_helpers/MyStorage';

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_content_delivery_bucket": "balkerne-20190712113910-hostingbucket-current",
    "aws_content_delivery_bucket_region": "eu-west-1",
    "aws_content_delivery_url": "http://balkerne-20190712113910-hostingbucket-current.s3-website-eu-west-1.amazonaws.com",
    "aws_cognito_identity_pool_id": "eu-west-1:309f7090-f7ff-4cfe-8383-8ffe5971aac5",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_G37Kv2599",
    "aws_user_pools_web_client_id": "d0r39u7iouo94m9mtlafnrcqp",
    "oauth": {},
    "storage": sessionStorage
};


export default awsmobile;
