import React from "react";
import { ToggleSwitch } from "./Controls";
import "./Options.css";

export class OptionCategory extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    this.props.onChange(e);
  }

  render() {
    let toggle = null;
    if (this.props.id && this.props.onChange) {
      toggle = (
        <ToggleSwitch
          id={this.props.id}
          checked={this.props.checked}
          onChange={this.props.onChange}
        />
      );
    }

    let classes = ["option__category"];

    if (this.props.opt === "dashboard") {
      classes.push("option__category--dashboard");
    }

    if (!this.props.children || !this.props.children.length) {
      return (
        <div className={classes.join(" ")}>
          <span className="option__heading option__heading--single">
            {this.props.icon}
            <p>{this.props.label}</p>
            {toggle}
          </span>
        </div>
      );
    } else {
      return (
        <details className={classes.join(" ")}>
          <summary className="option__heading">
            <p>{this.props.label}</p>
            {toggle}
          </summary>
          <ul className="option__list">{this.props.children}</ul>
        </details>
      );
    }
  }
}

export class Option extends React.Component {
  render() {
    let toggle = (
      <ToggleSwitch
        id={this.props.id}
        checked={this.props.checked}
        onChange={this.props.onChange}
      />
    );

    return (
      <li className="option__item">
        <div>
          {this.props.icon}
          {this.props.label}
        </div>

        {toggle}
      </li>
    );
  }
}

export class OptionIcon extends React.Component {
  render() {
    let classes = ["option__icon"];
    let style = {};
    if (this.props.box) {
      classes.push("option__icon--box");
      style.backgroundColor = this.props.box;
    }

    if (this.props.src) {
      return (
        <img
          className={classes.join(" ")}
          src={this.props.src}
          alt={this.props.alt}
        />
      );
    } else {
      return <span style={style} className={classes.join(" ")} />;
    }
  }
}
