import React from "react";
import { useReactTable } from "react-table";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";

import "./LocationAssignUsers.css";

import Table from "react-bootstrap/Table";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";

//
export class SingleUserLocationsAssign extends React.Component {
  constructor(props) {
    super(props);
    //setting TEMPORARY STATE
    this.state = {
      locations: this.props.locations,
      assignedLocations: this.props.assignedLocations,
      assignedLocationsLoaded: false,
      user: this.props.user,
      filter_string: "",
      filter_type: "",
      filteredLocations: null,

      selectedLocations: [],

      selectedAssignedLocations: [],
      ctrl: false,
      shifts: false,
      lastIndexLocation: 0,
      lastIndexUsers: 0,
      lastIndexAssignedLocations: 0,
    };
  }

  componentDidMount() {
    document.addEventListener("keydown", this.handleKeyDown);
    document.addEventListener("keyup", this.handleKeyUp);
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown);
    document.removeEventListener("keyup", this.handleKeyUp);
  }

  applyFilter = () => {
    this.state.filteredLocations = this.props.locations;
    let filteredLocations;
    console.log(this.state.filter_string);
    console.log(this.state.filter_type);
    switch (this.state.filter_type) {
      case "Name":
        console.log("name");
        filteredLocations = this.props.locations.filter((location) => {
          if (location.name) {
            return location.name
              .toLowerCase()
              .includes(this.state.filter_string.toLowerCase());
          }
        });
        break;
      case "Region":
        filteredLocations = this.props.locations.filter((location) => {
          if (location.region) {
            return location.region
              .toLowerCase()
              .includes(this.state.filter_string.toLowerCase());
          }
        });
        break;
      case "CRN":
        filteredLocations = this.props.locations.filter((location) => {
          if (location.property_customer_reference) {
            return location.property_customer_reference
              .toLowerCase()
              .includes(this.state.filter_string.toLowerCase());
          }
        });
        break;
      case "Address":
        filteredLocations = this.props.locations.filter((location) => {
          if (location.address_street) {
            return location.address_street
              .toLowerCase()
              .includes(this.state.filter_string.toLowerCase());
          }
        });
        break;
      case "Postcode":
        filteredLocations = this.props.locations.filter((location) => {
          //console.log("Postcode")
          if (location.address_postcode) {
            //console.log(location.address_postcode)
            return location.address_postcode
              .toLowerCase()
              .includes(this.state.filter_string.toLowerCase());
          }
        });
        break;
      default:
        filteredLocations = this.props.locations;
    }

    this.setState({ filteredLocations });
  };

  clearFilter = () => {
    this.state.filter_string = "";

    let filteredLocations = this.props.locations;

    this.setState({ filteredLocations });
  };

  handleKeyDown = (event) => {
    if (event.ctrlKey) {
      this.state.ctrl = true;
    }
    if (event.shiftKey) {
      this.state.shift = true;
    }
  };

  handleKeyUp = (event) => {
    if (event.keyCode === 17) {
      this.state.ctrl = false;
    }
    if (event.keyCode === 16) {
      this.state.shift = false;
    }
  };

  selectFilter = (filter) => {
    this.setState({ chosenFilter: filter });
  };

  handleChange = (e) => {
    let filter = {};
    filter[e.target.name] = e.target.value;
    this.setState(filter);
    //this.props.handleChange("users",this.state.users);
  };

  setCtrl = (press) => {
    this.state.ctrl = press;
  };

  setShift = (press) => {
    this.state.shift = press;
  };

  onRowSelectLocations = (row, isSelected, e, rowIndex) => {
    console.log(row);
    if (this.state.ctrl) {
      if (isSelected) {
        this.state.selectedLocations = [row.id];
      } else {
        this.state.selectedLocations = [row.id];
      }
    } else if (this.state.shift) {
      if (isSelected) {
        document.getSelection().removeAllRanges();
        if (this.state.lastIndexLocation > rowIndex) {
          for (let i = rowIndex; i <= this.state.lastIndexLocation; i++) {
            if (
              !this.state.selectedLocations.includes(this.props.locations[i].id)
            ) {
              this.state.selectedLocations.push(this.props.locations[i].id);
            }
          }
        } else {
          for (let i = this.state.lastIndexLocation; i <= rowIndex; i++) {
            if (
              !this.state.selectedLocations.includes(this.props.locations[i].id)
            ) {
              this.state.selectedLocations.push(this.props.locations[i].id);
            }
          }
        }
      }
    } else {
      if (isSelected) {
        this.state.selectedLocations.push(row.id);
      } else {
        this.state.selectedLocations.splice(
          this.state.selectedLocations.findIndex((location) => {
            return location === row.id;
          }),
          1
        );
      }
    }

    this.props.updateSelectedLocations(this.state.selectedLocations);

    if (isSelected) {
      this.state.lastIndexUsers = rowIndex;
    }

    this.setState({ selectedLocations: this.state.selectedLocations });
  };

  onRowSelectAssignedLocations = (row, isSelected, e, rowIndex) => {
    if (this.state.ctrl) {
      if (isSelected) {
        this.state.selectedAssignedLocations = [row.id];
      } else {
        this.state.selectedAssignedLocations = [row.id];
      }
    } else if (this.state.shift) {
      if (isSelected) {
        document.getSelection().removeAllRanges();
        if (this.state.lastIndexAddUsers > rowIndex) {
          for (let i = rowIndex; i <= this.state.lastIndexAddUsers; i++) {
            if (
              !this.state.selectedAssignedLocations.includes(
                this.props.assignedLocations[i].id
              )
            ) {
              this.state.selectedAssignedLocations.push(
                this.props.assignedLocations[i].id
              );
            }
          }
        } else {
          for (let i = this.state.lastIndexAddUsers; i <= rowIndex; i++) {
            console.log(i);
            console.log(this.props.assignedLocations);
            console.log(this.props.assignedLocations[i]);
            if (
              !this.state.selectedAssignedLocations.includes(
                this.props.assignedLocations[i].id
              )
            ) {
              this.state.selectedAssignedLocations.push(
                this.props.assignedLocations[i].id
              );
            }
          }
        }
      }
    } else {
      if (isSelected) {
        this.state.selectedAssignedLocations.push(row.id);
      } else {
        this.state.selectedAssignedLocations.splice(
          this.state.selectedAssignedLocations.findIndex((locations) => {
            return locations === row.id;
          }),
          1
        );
      }
    }

    this.props.updateSelectedAssignedLocations(
      this.state.selectedAssignedLocations
    );

    if (isSelected) {
      this.state.lastIndexAssignedLocations = rowIndex;
    }

    this.setState({
      selectedAssignedLocations: this.state.selectedAssignedLocations,
    });
  };

  selectAllLocations = (select) => {
    if (select) {
      for (let i = 0; i < this.state.filteredLocations.length; i++) {
        if (
          !this.state.selectedLocations.includes(
            this.state.filteredLocations[i].id
          )
        ) {
          this.state.selectedLocations.push(this.state.filteredLocations[i].id);
        }
      }
      this.setState({ selectedLocations: this.state.selectedLocations });
      this.props.updateSelectedLocations(this.state.selectedLocations);
    } else {
      let selectedLocations = [];
      this.setState({ selectedLocations });

      this.props.updateSelectedLocations(selectedLocations);
    }
  };

  selectAllAssignedLocations = (select) => {
    if (select) {
      for (let i = 0; i < this.props.assignedLocations.length; i++) {
        if (
          !this.state.selectedAssignedLocations.includes(
            this.props.assignedLocations[i].id
          )
        ) {
          this.state.selectedAssignedLocations.push(
            this.props.assignedLocations[i].id
          );
        }
      }
      console.log(this.state.selectedAssignedLocations);
      this.setState({
        selectedAssignedLocations: this.state.selectedAssignedLocations,
      });

      this.props.updateSelectedAssignedLocations(
        this.state.selectedAssignedLocations
      );
    } else {
      let selectedAssignedLocations = [];
      this.setState({ selectedAssignedLocations });

      this.props.updateSelectedAssignedLocations(
        this.state.selectedAssignedLocations
      );
    }
  };

  addLocation = () => {
    let selectedAssignedLocations = [];
    this.setState({ selectedAssignedLocations });
    this.props.updateSelectedAssignedLocations(
      this.state.selectedAssignedLocations
    );

    this.props.addLocation();
  };

  //conditionally render according current step
  render() {
    const selectRowLocations = {
      mode: "checkbox",
      clickToSelect: true,
      bgColor: "cyan",
      //hideSelectColumn: true,
      selected: this.state.selectedLocations,
      onSelect: this.onRowSelectLocations,
      onSelectAll: this.selectAllLocations,
    };

    const selectRowAssignedLocations = {
      mode: "checkbox",
      clickToSelect: true,
      bgColor: "cyan",
      //hideSelectColumn: true,
      selected: this.state.selectedAssignedLocations,
      onSelect: this.onRowSelectAssignedLocations,
      onSelectAll: this.selectAllAssignedLocations,
    };

    if (this.state.filteredLocations === null) {
      this.state.filteredLocations = this.props.locations;
    }
    // if (this.state.assignedLocationsLoaded== false &&this.props.assignedLocations !== null ) {

    //     this.state.assignedLocations = this.props.assignedLocations;
    //     this.state.assignedLocationsLoaded = true;
    //   }
    return (
      <div className="assignuserform">
        <form onChange={this.handleChange}>
          <div className="filterproperty">
            <label> Filter: </label>

            <div className="searchfields">
              <input
                type="text"
                name="filter_string"
                className="filter_string"
                id="filter_string"
                defaultValue={this.state.filter_string}
              />
              <select
                name="filter_type"
                className="filter_type"
                id="filter_type"
              >
                <option disabled selected>
                  Choose a Filter
                </option>
                <option value="Name">Name</option>
                <option value="Region">Region</option>
                <option value="CRN">CRN</option>
                <option value="Address">Address</option>
                <option value="Postcode">Postcode</option>
              </select>

              <button type="button" onClick={this.applyFilter}>
                Apply
              </button>

              <button type="button" onClick={this.clearFilter}>
                Clear
              </button>
            </div>
          </div>
        </form>
        <div className="reassignuser">
          <div className="propertylist">
            <label>
              Select Properties (Ctrl + Left click) you'd like to add users to
            </label>
            <BootstrapTable
              className="table-smaller-locations"
              selectRow={selectRowLocations}
              data={this.state.filteredLocations}
              striped
              hover
            >
              <TableHeaderColumn width="0%" isKey dataField="id" hidden>
                ID
              </TableHeaderColumn>
              <TableHeaderColumn width="25%" dataField="name">
                Property Name
              </TableHeaderColumn>
              <TableHeaderColumn width="30%" dataField="address_street">
                Address
              </TableHeaderColumn>
              <TableHeaderColumn width="15%" dataField="address_postcode">
                Postcode
              </TableHeaderColumn>

              <TableHeaderColumn width="15%" dataField="region">
                Region
              </TableHeaderColumn>
              <TableHeaderColumn
                width="15%"
                dataField="property_customer_reference"
              >
                CRN
              </TableHeaderColumn>
            </BootstrapTable>
          </div>

          <div className="btn-center">
            <button
              onClick={() => this.addLocation()}
              type="button"
              className="btn btn-primary"
            >
              Add Location
            </button>
            <button
              onClick={this.props.removeLocation}
              type="button"
              className="btn btn-primary"
            >
              Remove Location
            </button>
          </div>

          <div className="userlist2">
            <label>Assigned properties:</label>
            {
              //this.displayAssignedLocations( selectRowAssignedLocations)
            }
            <BootstrapTable
              className="table-smaller-locations"
              selectRow={selectRowAssignedLocations}
              data={this.props.assignedLocations}
              striped
              hover
            >
              <TableHeaderColumn width="0%" isKey dataField="id" hidden>
                ID
              </TableHeaderColumn>
              <TableHeaderColumn width="25%" dataField="name">
                Property Name
              </TableHeaderColumn>
              <TableHeaderColumn width="30%" dataField="address_street">
                Address
              </TableHeaderColumn>

              <TableHeaderColumn
                width="15%"
                dataField="property_customer_reference"
              >
                CRN
              </TableHeaderColumn>
            </BootstrapTable>
          </div>
        </div>
      </div>
    );
  }
}
export default SingleUserLocationsAssign;
