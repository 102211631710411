import { systemConstants } from "../_constants";
import axios from "axios";
import * as urls from "../../components/maps/urls";

export async function getRiskTable(locations_list, secret, preferences) {
  let authstr = "Bearer " + secret;

  let payload = null;

  const headers = {
    "Content-Type": "application/json",
    Authorization: authstr,
  };

  let data = { location_list: locations_list };

  console.log(preferences);
  console.log(preferences);
  console.log(preferences.food_hygiene == true);
  console.log(preferences.food_hygiene == "True");

  if (
    preferences &&
    preferences &&
    (preferences.food_hygiene == true || preferences.food_hygiene == "True")
  ) {
    data.food_hygiene = true;
  }
  if (
    preferences &&
    preferences &&
    (preferences.pandemic == true || preferences.pandemic == "True")
  ) {
    data.pandemic = true;
  }

  console.log(data);

  await axios({
    method: "post",
    url: urls.API_GATEWAY + "locations/property-risk-table",
    headers: { Authorization: authstr },
    data: data,
  })
    .then((res) => {
      console.log(res.data);
      console.log("Properties by risk TABLE retrieved from the database.");
      payload = res.data;

      if (
        payload === "null" ||
        payload === null ||
        payload === "Failure" ||
        typeof payload === "undefined"
      ) {
        payload = null;
        console.log("risk TABLE getting ERROR");
      } else if (payload === "Invalid Details") {
        console.log("Credentials for getting risk TABLE, details are INVALID");
        payload = null;
      } else {
        console.log("SUCCESS!!");
        console.log("risk TABLE Data retrieved from the database.");
      }

      //this.state.rofrs = risk_table.rofrs;

      //this.state.rofsw = risk_table.rofsw;
    })
    .catch((err) => {
      payload = null;
      console.log("FAILURE!!");
      console.log(err);
    });
  return payload;
}
