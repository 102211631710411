/**
 * Common map filters.
 *
 * All filter objects MUST have the following properties:
 *     name - Name of the layer (will be shown in the sidebar)
 *     active - Default state of the filter (usually `true`)
 *     parentLayer - Name of the layer this filter belongs to
 *     func - Function to call to retreive filtered features
 *     args - Arguments to pass to `func`
 *
 *  A filter object MAY have the following properties:
 *      icon - OptionIcon to be displayed next to the name
 */
import axios from "axios";

import React from "react";
import { OptionIcon } from "./Options";
import { severity } from "./styles";

let headers = new Headers();
headers.append("pragma", "no-cache");
headers.append("cache-control", "no-cache");

const getNoCache = {
  method: "GET",
  headers: headers,
};

// Filter functions

/**
 * Filter by Risk of Flooding from Rivers and Sea
 * @param {string} url - Data URL for features to be filtered.
 *                       Typically taken from the layer object and provided
 *                       by `showFilter` or `hideFilter` function.
 * @param {number} value - Risk value to filter on.
 */
const rofrs = async function (url, value) {
  let query = "?" + [`rofrsValue=${value}`, "idOnly=1"].join("&");
  let requestURL = [url, query].join("");
  let res = await fetch(requestURL);
  let resJson = await res.json();

  return resJson;
};

// const properties = async function (url, values) {
//     console.log(values)
//     console.log(values[0])
//     console.log(values[1])

//     let query = '?' + [`risk=${values[1]}`, `type=${values[0]}` ,'ids=1'].join('&')
//     let requestURL = [url, query].join('');
//     let res = await fetch(requestURL);
//     let resJson = await res.json();

//     return resJson;
// }

/**
 * Filter by historical flooding.
 * @param {string} url - Data URL for features to be filtered.
 *                       Typically taken from the layer object and provided
 *                       by `showFilter` or `hideFilter` function.
 * @param {number} value - Value to filter on:
 *                         1 = flooded
 *                         2 = not flooded
 */
const historicallyFlooded = async function (url, value) {
  let query = "?" + [`historicallyFlooded=${value}`, "idOnly=1"].join("&");
  let requestURL = [url, query].join("");
  let res = await fetch(requestURL);
  let resJson = await res.json();

  return resJson;
};

/**
 * Filter by location within an active flood alert.
 * @param {string} url - Data URL for features to be filtered.
 *                       Typically taken from the layer object and provided
 *                       by `showFilter` or `hideFilter` function.
 * @param {number} severity - Severity to filter on:
 *                            0 = not within an active flood alert
 *                            1 = within a severe flood warning
 *                            2 = within a flood warning
 *                            3 = within a flood alert
 */
const floodAlerts = async function (url, severity1, locations, secret) {
  let authstr = "Bearer " + secret;

  let result = null;

  const headers = {
    "Content-Type": "application/json",
    Authorization: authstr,
  };
  let severity = 0;
  let severityTrue = severity1 instanceof Array;
  if (severityTrue && severity1.length > 0) {
    severity = severity1[0];
  }

  let data = { location_list: locations, severity: severity };

  await axios({
    method: "post",
    url: url,
    headers: { Authorization: authstr },
    data: data,
  })
    .then((res) => {
      // console.log("SUCCESS!!");
      // console.log(res.data)
      // console.log("Flood Alerts by risk retrieved from the database.");
      // result= res.data;
    })
    .catch((err) => {
      console.log("FAILURE!!");
      console.log(err);

      result = null;
    });

  // let formData = new FormData();
  // formData.append("location_list", locations);
  // let success = true;
  //   let result = null;
  //   let data = null;
  //   let query = '?' + `severity=${severity}`
  //   let requestURL = [url, query].join('');

  // await axios
  //   .post(requestURL, formData, {
  //     headers: {
  //       "Content-Type": "multipart/form-data"
  //     }
  //   })
  //   .then(res=> {
  //     console.log("SUCCESS!!");
  //     console.log(res.data)
  //     console.log("Properties by risk retrieved from the database.");
  //     result= res.data;
  //   })
  //   .catch(err=>{
  //     console.log("FAILURE!!");
  //     console.log(err);

  //       result= null;
  //   });

  //   console.log(data)

  //   let layer = new window.google.maps.Data();
  //   layer.addGeoJson(result);

  return result;

  // let res = await fetch(requestURL, getNoCache);
  // let resJson = await res.json();

  // return resJson;
};

// Filter objects

export const floodRiskHigh = {
  name: "High",
  icon: <OptionIcon box="#d63333" />,
  active: true,
  parentLayer: "Flood Risk",
  func: rofrs,
  args: [4],
};

export const floodRiskMed = {
  name: "Medium",
  icon: <OptionIcon box="#d68533" />,
  active: true,
  parentLayer: "Flood Risk",
  func: rofrs,
  args: [3],
};

export const floodRiskLow = {
  name: "Low",
  icon: <OptionIcon box="#ad5cff" />,
  active: true,
  parentLayer: "Flood Risk",
  func: rofrs,
  args: [2],
};

export const floodRiskVlow = {
  name: "Very Low",
  icon: <OptionIcon box="#5c5cff" />,
  active: true,
  parentLayer: "Flood Risk",
  func: rofrs,
  args: [1],
};

export const floodRiskNegligible = {
  name: "Negligible",
  icon: <OptionIcon box="rgba(0, 0, 0, 0)" />,
  active: true,
  parentLayer: "Flood Risk",
  func: rofrs,
  args: [0],
};

export const hasFlooded = {
  name: "Has Flooded",
  icon: <OptionIcon box="#005d82" />,
  active: true,
  parentLayer: "Has Flooded",
  func: historicallyFlooded,
  args: [1],
};

// export const hasNotFlooded = {
//     name: 'Has Not Flooded',
//     icon: <OptionIcon box="rgba(0, 0, 0, 0)"/>,
//     active: true,
//     parentLayer: 'Historical Flooding',
//     func: historicallyFlooded,
//     args: [0]
// }

export const floodAlertsSevere = {
  name: "Severe Warnings",
  icon: <OptionIcon src="static/img/severeFloodWarning.png" />,
  active: true,
  parentLayer: "Flood Alerts",
  func: floodAlerts,
  args: [1],
};

export const floodAlertsWarning = {
  name: "Warnings",
  icon: <OptionIcon src="static/img/floodWarning.png" />,
  active: true,
  parentLayer: "Flood Alerts",
  func: floodAlerts,
  args: [2],
};

export const floodAlertsAlert = {
  name: "Alerts",
  icon: <OptionIcon src="static/img/floodAlert.png" />,
  active: true,
  parentLayer: "Flood Alerts",
  func: floodAlerts,
  args: [3],
};
// export const propertiesRofrsHigh = {
//     name: 'Rofrs High',
//     icon: <OptionIcon box="#d63333"/>,
//     active: true,
//     parentLayer: 'Properties',
//     func: properties,
//     args: ["rofrs",4]
// }

// export const propertiesRofrsMedium = {
//     name: 'Rofrs Medium',
//     icon: <OptionIcon box="#d63333"/>,
//     active: true,
//     parentLayer: 'Properties',
//     func: properties,
//     args: ["rofrs",3]
// }

// export const propertiesRofrsLow = {
//     name: 'Rofrs Low',
//     icon: <OptionIcon box="#d63333"/>,
//     active: true,
//     parentLayer: 'Properties',
//     func: properties,
//     args: ["rofrs",1]
// }

// export const propertiesRofswHigh = {
//     name: 'Rofsw High',
//     icon: <OptionIcon box="#d63333"/>,
//     active: true,
//     parentLayer: 'Properties',
//     func: properties,
//     args: ["rofsw",4]
// }

// export const propertiesRofswMedium = {
//     name: 'Rofsw Medium',
//     icon: <OptionIcon box="#d63333"/>,
//     active: true,
//     parentLayer: 'Properties',
//     func: properties,
//     args: ["rofsw",3]
// }

// export const propertiesRofswLow = {
//     name: 'Rofsw Low',
//     icon: <OptionIcon box="#d63333"/>,
//     active: true,
//     parentLayer: 'Properties',
//     func: properties,
//     args: ["rofsw",1]
// }
