import React from "react";
import AddUsers from "../components/userManagement/AddUsers";
import AssignUsers from "../components/userManagement/AssignUsers";
import AssignUsersLastStep from "../components/userManagement/AssignUsersLastStep";
import axios from "axios";
import * as urls from "../components/maps/urls";
//import Home from './Home'
import { Auth } from "aws-amplify";

import { userActions } from "../redux/_actions";
import { connect } from "react-redux";
import { Logout } from "../components/Logout";
import Header from "../components/Header";
import Loader from "../components/spinner";
import { RefreshAuthTokens } from "../components/RefreshAuthTokens";
import "./MainAddUsers.css";

class MainReassignUsers extends React.Component {
  constructor(props) {
    super(props);
    //initial cunstructor for the master class containng the 3 forms

    this.handleChange = this.handleChange.bind(this);

    this.state = {
      currentStep: 2, // first Step 1

      //data arrays
      users: [],
      locations: [],
      addUsersList: [],
      selectedAddUsers: [],
      //selections in assign users form
      selectedLocations: [],
      selectedUsers: [],
      selectedLocationsIndex: 0,
      selectedUser: { fullName: "", email: "" },
      selectedUserIndex: 0,
      selectedAddUser: { fullName: "", email: "" },
      selectedAddUserIndex: 0,
      ctrl: false,
      shifts: false,

      org_id: 2,
      current_user_id: 2,

      //error displaying
      errorArray: [],
      errorText: "",
      isLoading: false,
      errMessage: "",
      success: true,
    };
    this.getUsers = this.getUsers.bind(this);
    this.reassignPropertyUsers = this.reassignPropertyUsers.bind(this);
  }

  showNotice = () => {
    if (this.state.success === false) {
      return <div className="alertbox">{this.state.errMessage}</div>;
    }
  };

  async reassignPropertyUsers(user_id, org_id) {
    let locations = null;

    let params = { org_id: org_id, user_id: user_id };

    await axios({
      method: "get",
      url: urls.API_GATEWAY + "locations/property-reassign-users",
      params: params,
    })
      .then((res) => {
        locations = res.data;

        console.log(locations);

        if (
          locations === "null" ||
          locations === null ||
          typeof locations === "undefined"
        ) {
          console.log("Error retrieving location");
          locations = null;
        } else if (locations === "Invalid Details") {
          console.log("Details for Location retrieved are INVALID");
          locations = null;
        } else if (locations === "No locations found") {
          console.log("No Locations Found");
          locations = null;
        } else {
          console.log("SUCCESSSSS");
          console.log("success getting property reassign users");
        }

        // locations = res.data;
        // console.log(locations);
      })
      .catch((err) => {
        locations = null;
        console.log("FAILURE!!");
        console.log(err);
        if (!err.message) {
          console.log("Error when loading alert-locations: ", err);
          //  Alert.alert('Error when signing in: ', err);
        } else {
          console.log("Error when loading alert-locations: ", err.message);
          //  Alert.alert('Error when signing in: ', err.message);
          this.setState({
            success: false,
            errMessage: "Could not Reach Server",
          });
        }
      });
    this.setState({ locations });

    return locations;
  }

  async componentDidMount() {
    this.state.org_id = this.props.userData.data.orgid;
    this.state.current_user_id = this.props.userData.data.id;
    this.state.group = this.props.userData.data.group;

    await RefreshAuthTokens(this.props);

    await this.reassignPropertyUsers(
      this.state.current_user_id,
      this.state.org_id
    );

    await this.getUsers(this.state.current_user_id, this.state.org_id);

    this.setState({ mounted: true });
  }

  async getUsers(current_user_id, org_id) {
    let users = null;

    let data = { user_id: current_user_id, org_id: org_id };

    await axios({
      method: "get",
      url: urls.API_GATEWAY + "users/get-users",
      params: data,
    })
      .then((res) => {
        users = res.data;
        console.log(users);

        if (
          users === "null" ||
          users === null ||
          users === "Failure" ||
          typeof users === "undefined"
        ) {
          users = [];
          console.log("Users retrieval FAILURE");
        } else if (users === "Invalid Details" || users === "No users found") {
          console.log(
            "Details for users retrieved are INVALID or NO USERS WERE FOUND"
          );
          users = [];
        } else {
          console.log("SUCCESS!!");
          console.log("Users retrieved from the database.");
        }

        this.setState({ users });
      })
      .catch((err) => {
        users = null;
        this.setState({ users });
        console.log("FAILURE!!");
        console.log(err);
        if (!err.message) {
          console.log("Error when loading users: ", err);
          //  Alert.alert('Error when signing in: ', err);
        } else {
          console.log("Error when loading users: ", err.message);
          //  Alert.alert('Error when signing in: ', err.message);
          this.setState({
            success: false,
            errMessage: "Could not Reach Server",
          });
        }
      });
  }

  //Validates the assigning (Adding users to location that same user can't get added twice to the same location)
  validateAssignUsers(user) {
    let validate = true;
    let errorMessage = [];
    if (this.state.addUsers && this.state.addUsers.length) {
      console.log("CHECKING");
      for (var i = 0; i < this.state.addUsers.length; i++) {
        console.log("CHECKING2");
        console.log(user.email);
        console.log(this.state.addUsers[i].email);
        //Checks if addUsers list already has the selected person in the list
        if (user.email === this.state.addUsers[i].email) {
          console.log("invalidated");
          validate = false;
          errorMessage.push(
            "User '" +
              this.state.addUsers[i].full_name +
              "' is already assigned to this location.\n"
          );
        }
      }
    }

    //return true or false as well as printing an error
    if (validate === true) {
      this.setState({
        errorText: "",
      });
      return true;
    } else {
      this.setState({
        errorText: this.printError(errorMessage),
      });
      return false;
    }
  }

  // Use the submitted data to set the state
  handleChange(name, value) {
    this.setState({
      [name]: value,
    });
  }

  //formats the error message to be on separate lines
  printError = (errorMessage) => {
    var lines = errorMessage;
    var br = lines.map(function (line) {
      return (
        <span>
          {line}
          <br />
        </span>
      );
    });
    return <div>{br}</div>;
  };

  //ASSIGNED Users

  //selects a user from user list by name and email
  selectUser = (name, email) => {
    let selectedAddUser = { full_name: name, email: email };
    this.setState({ selectedUser: selectedAddUser });
  };

  //selects a user from ADDuser list by name and email
  selectAddUser = (row) => {
    this.setState({ selectedAddUser: row });
  };

  //selects a location from user list by id number
  //   selectLocations = (id) =>{
  //     let selectedLocations = this.state.selectedLocations;

  //     if(this.state.ctrl){
  //      selectedLocations.push(id)
  //     }
  //     else{
  //      selectedLocations = [id]
  //     }

  //   this.setState({selectedLocations}, () => {
  //     this.updateAddUsers();
  // });
  //   }

  //   selectUsers = (id) =>{
  //     let selectedUsers = this.state.selectedUsers;

  //     if(this.state.ctrl){
  //       selectedUsers.push(id)
  //     }
  //     else{
  //       selectedUsers = [id]
  //     }

  //   this.setState({selectedUsers});
  //   }

  //   selectAddUsers = (id) =>{
  //     let selectedAddUsers = this.state.selectedAddUsers;

  //     if(this.state.ctrl){
  //       selectedAddUsers.push(id)
  //     }
  //     else{
  //       selectedAddUsers = [id]
  //     }

  //   this.setState({selectedAddUsers});
  //   }

  // selects a location from location list by index
  updateSelectedLocations = (array) => {
    this.state.selectedLocations = array;
    this.setState({ selectedLocations: this.state.selectedLocations });
    console.log(array);
    console.log(this.state.selectedLocations);
    this.updateAddUsers();
  };

  updateSelectedUsers = (array) => {
    this.setState({ selectedUsers: array });
  };

  updateSelectedAddUsers = (array) => {
    this.setState({ selectedAddUsers: array });
  };
  // selects a user from user list by index
  selectUserIndex = (index) => {
    this.setState({ selectedUserIndex: index });
  };

  // selects a user from ADDusers list by index
  selectAddUserIndex = (index) => {
    this.setState({ selectedAddUserIndex: index });
  };

  //updates theAddUsers list depending on the selected location and its users
  updateAddUsers = () => {
    console.log(this.state.selectedLocations[0]);
    console.log(this.state.selectedLocations);
    if (
      this.state.selectedLocations &&
      this.state.selectedLocations.length === 1
    ) {
      var location = this.state.locations.find((location) => {
        return this.state.selectedLocations[0] === location.id;
      });
      this.state.addUsersList = location.assignedusers;
      this.setState({ addUsers: this.state.addUsersList });
    }

    //this.render();
  };
  async addUserDatabase(propid, userid) {
    let added = false;

    let data = { propid: propid, user_id: userid };

    await axios({
      method: "post",
      url: urls.API_GATEWAY + "users/add-user-to-property",
      data: data,
    })
      .then((res) => {
        let result = res.data;
        console.log(result);

        if (result == "Success") {
          added = true;
        } else if (
          result === "null" ||
          result === null ||
          result === "Failure" ||
          typeof result === "undefined"
        ) {
          added = false;
          console.log("FAILURE adding user mappping");
        } else if (result === "Invalid Details") {
          console.log("Details for adding user prop mapping are INVALID");
          added = false;
        } else if (result === "Mapping not found") {
          console.log("MAPPING for adding already EXISTS");
          added = false;
        }
      })
      .catch((err) => {
        console.log("FAILURE adding user!!");
        console.log(err);
        added = false;
      });

    return added;
  }

  async removeUserDatabase(propid, userid) {
    let removed = false;

    let data = { propid: propid, user_id: userid };

    await axios({
      method: "post",
      url: urls.API_GATEWAY + "users/remove-user-from-property",
      data: data,
    })
      .then((res) => {
        let result = res.data;
        console.log(result);
        if (result == "Success") {
          removed = true;
        } else if (
          result === "null" ||
          result === null ||
          result === "Failure" ||
          typeof result === "undefined"
        ) {
          removed = false;
          console.log("FAILURE removing user mappping");
        } else if (result === "Invalid Details") {
          console.log("Details for removing user prop mapping are INVALID");
          removed = false;
        } else if (result === "Mapping not found") {
          console.log("MAPPING for deletion was NOT FOUND");
          removed = false;
        }
      })
      .catch((err) => {
        console.log("FAILURE removing user!!");
        console.log(err);
        removed = false;
      });

    return removed;
  }

  //adds a valid selected user from users array to the addUsers list by appending locations.assignedUsers
  addUsers = () => {
    this.setState({ isLoading: true });
    console.log("shot");
    for (var user1 in this.state.selectedUsers) {
      var user = this.state.users.find((user) => {
        return this.state.selectedUsers[user1] === user.email;
      });
      console.log(user);
      // var user =  this.state.selectedUsers[user1]
      console.log("user");
      if (
        this.state.selectedLocations &&
        this.state.selectedLocations.length > 0
      ) {
        //validates if already added if not undefined before adding
        console.log("adding");
        if (this.validateAssignUsers(user) === true) {
          console.log("validated");

          for (let location1 in this.state.selectedLocations) {
            var location = this.state.locations.find((location) => {
              console.log(this.state.selectedLocations[location1]);
              console.log(location.id);
              return this.state.selectedLocations[location1] === location.id;
            });
            console.log(location);

            // var location = this.state.selectedLocations[location1];
            let added = this.addUserDatabase(location.id, user.id);
            if (added) {
              location.assignedusers.push(user);
              alert("User successfully added to the database");
            } else {
              console.log("can't add, database error");
            }
          }
        }
      }
      this.setState({ isLoading: false });
    }
    this.updateAddUsers();
    this.setState({ locations: this.state.locations });
  };

  //adds a removes a user from locations.assignedUsers
  removeUser = () => {
    var selectedAddUsers = Array.from(this.state.selectedAddUsers);

    console.log();
    for (var user1 in this.state.selectedAddUsers) {
      var user = this.state.users.find((user) => {
        return selectedAddUsers[user1] === user.email;
      });
      console.log(user);
      if (
        this.state.selectedLocations &&
        this.state.selectedLocations.length === 1
      ) {
        //validates if already added if not undefined before adding
        console.log("removing");
        var location = this.state.locations.find((location) => {
          return this.state.selectedLocations[0] === location.id;
        });
        console.log(location);
        var result = location.assignedusers.find((assignedUser) => {
          return assignedUser.id === user.id;
        });
        console.log(result);
        console.log("REMOVE " + result);
        if (location && result) {
          console.log("REMOVING " + result);
          let removed = this.removeUserDatabase(location.id, result.id);
          if (removed) {
            selectedAddUsers = selectedAddUsers.filter((e) => e !== user.id);

            location.assignedusers = location.assignedusers.filter(
              (e) => e !== result
            );
          } else {
            alert("User successfully removed from the database");
            console.log("can't remove, database error");
          }

          console.log("REMOVE " + result);
        }
      }
    }
    this.state.selectedAddUsers = selectedAddUsers;

    this.updateAddUsers();
    this.setState({ locations: this.state.locations });
  };

  CheckLoader() {
    if (this.state.isLoading) return <Loader />;
  }
  //ASSIGNED USERS END

  render() {
    return (
      <React.Fragment>
        <Header
          user_permissions={this.props.userData.data.permissions}
          user_portfolio_type={this.props.userData.data.portfolio_type}
          user={this.props.userData.data.permissions}
          customstyle={this.props.userData.data.customstyle}
          organisation={this.props.userData.data.orgid}
          logout={() => Logout(this.props)}
        />
        <div className="adduserpage">
          <div className="addusersheading">
            <h2>Reassign Users</h2>
          </div>
          <form onSubmit={this.handleSubmit}>
            <AssignUsers
              currentStep={this.state.currentStep}
              handleChange={this.handleChange}
              users={this.state.users}
              locations={this.state.locations}
              selectedLocations={this.state.selectedLocations}
              selectedUsers={this.state.selectedUsers}
              selectedAddUsers={this.state.selectedAddUsers}
              addUsersList={this.state.addUsersList}
              selectUser={this.selectUser}
              selectLocations={this.selectLocations}
              selectAddUser={this.selectAddUser}
              selectUserIndex={this.selectUserIndex}
              selectLocationsIndex={this.selectLocationsIndex}
              selectAddUserIndex={this.selectAddUserIndex}
              updateAddUsers={this.updateAddUsers}
              setCtrl={this.setCtrl}
              setShift={this.setShift}
              addUsers={this.addUsers}
              removeUser={this.removeUser}
              updateSelectedLocations={this.updateSelectedLocations}
              updateSelectedAddUsers={this.updateSelectedAddUsers}
              updateSelectedUsers={this.updateSelectedUsers}
            />

            <div className="navigation text-center d-block mt-5 w-100 clearfix"></div>
          </form>
          {this.CheckLoader()}
          <div>{this.state.errorText}</div>
          {this.showNotice()}
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  //console.log(state);
  const { userLogged, user, userData, systemData, dashboardData } = state;

  return {
    userLogged,
    user,
    userData,
    systemData,
    dashboardData,
  };
}

//const connectedApp = connect(mapStateToProps)(MainMenu);
export default connect(mapStateToProps)(MainReassignUsers);
