import { systemConstants } from "../_constants";
import axios from "axios";
import * as urls from "../../components/maps/urls";

export async function getCrimeRisks(location_id, secret) {
  let data = null;

  //  months_ago = 1-6  add to get a previous months data incremented by number

  // average = 1 -6    add to get the average crime data for this many months

  let params = { location_id: location_id };

  await axios({
    method: "get",
    url: urls.API_GATEWAY + "crime/property",
    params: params,
  })
    .then((res) => {
      let crimeData = res.data;
      console.log(res);
      console.log(res.ok);
      if (res.status == 200 || res.status == 201 || res.status == 202) {
        console.log("SUCCESS!!");
        console.log(crimeData);
        console.log("crime Data retrieved from the database.");
        data = crimeData.data;
      } else {
        console.log("crime data is bad");
        data = null;
      }
    })
    .catch((err) => {
      data = null;
      console.log("FAILURE getting crime data!!");
      console.log(err);
    });

  return data;
}
