import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { withRouter } from 'react-router-dom'

import Home from '../temp/Home'
import TestDashboard from '../components/TestDashboard'
import PropertyHeader from '../components/HeaderProperty'

import axios from 'axios'
import * as urls from '../components/maps/urls'

import { userActions } from '../redux/_actions'
import { connect } from 'react-redux'
import { Logout } from '../components/Logout'
// import Loader from "react-loader-spinner";
import Loader from '../components/spinner.js'

import Footer from '../components/footer'
import './Userdashboard.css'

import { systemReduxDashboardHandler } from '../redux/_helpers/systemReduxDashboardHandler.js'
import { RefreshAuthTokens } from '../components/RefreshAuthTokens'
import { systemReduxHandler } from '../redux/_helpers/systemReduxHandler.js'

//Page that gets the data (props/backend), loadsand & contains the dashboard (test dashboard)

export class UserDashboardSolo extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      country: null,
      id: null,
      dashboardFeature: [],
      mounted: false,
      locationData: null,
      flood_risks: [],
      metal_theft_risks: [],
      messages: [],
      mitigations: [],
      weatherwarnings: [],
      weather_risks: [],
      crime_risks: [0],
      historical: null,
      alerts: null,
      errMessage: '',
      current_page: this.props.location.pathname || '',
      gaugereadings: [],
      success: true,
      formInstance: [],
    }
    this.updateLossIncidents = this.updateLossIncidents.bind(this)
    this.updateMitigations = this.updateMitigations.bind(this)
  }

  showNotice = () => {
    // if (this.state.success === false) {
    //   return (
    //     <div className="alertbox">
    //     {this.state.errMessage}
    //   </div>
    //   )
    //   }
  }

  async componentDidMount() {
    let user_id = 'Temp'

    await RefreshAuthTokens(this.props)

    console.log(this.props.userData)

    if (this.props.userData.data.aws_id) {
      user_id = this.props.userData.data.aws_id
    } else {
      user_id = this.props.user.user.username
    }

    this.state.org_id = this.props.userData.data.orgid
    this.state.current_user_id = this.props.userData.data.id
    let id = this.props.dashboardData.data.id

    if (
      this.props.dashboardData.data.id === 0 ||
      typeof this.props.dashboardData.data.id !== 'undefined' ||
      !this.props.dashboardData.data.id
    ) {
      let userLocations = this.props.userData.data.locations
      if (typeof userLocations[0] !== 'undefined' && userLocations[0]) {
        this.state.userLocation = userLocations[0]
        this.state.id = userLocations[0]
        id = userLocations[0]
      }

      this.state.country = this.props.dashboardData.data.country
      //this.state.id = this.props.dashboardData.data.id
      this.state.alerts = this.props.dashboardData.data.live_alerts
      this.state.historical = this.props.dashboardData.data.historical_alerts
    }

    //   if(this.props.location.state.alerts){
    //     this.state.alerts =this.props.location.state.alerts
    //     }
    //     else{
    //       this.state.alerts =this.props.dashboardData.data.live_alerts}

    //   if(this.props.location.state.historical){
    //     this.state.historical =this.props.location.state.historical
    //     }
    //     else{ this.state.historical =this.props.dashboardData.data.historical_alerts}

    // }
    // else{
    //   console.log("STATE IS FALSE")
    //   this.state.country = this.props.dashboardData.data.country
    //   this.state.id = this.props.dashboardData.data.id
    //   this.state.alerts =this.props.dashboardData.data.live_alerts
    //   this.state.historical =this.props.dashboardData.data.historical_alerts

    // }

    //get flood messages

    // let id = `?id=${this.state.id}`;

    // let message_url = urls.BACKEND + "/flood/messages" + id;

    // let messages = await axios.get(message_url).then(res => {
    //   const messages = res.data;
    //   console.log(messages);
    //   this.setState({ messages });
    // });

    //get location

    //await systemReduxDashboardHandler.setDashboardData(this.props,this.state.id,this.state.country);
    console.log(id)
    // if(this.props.dashboardData.data.location_data!== null){
    //   this.state.locationData = this.props.dashboardData.data.location_data

    //    }

    await systemReduxDashboardHandler.setDashboardDataSolo(
      this.props,
      id,
      user_id,
      this.props.userData.data.orgid,
      this.props.user.user.signInUserSession.idToken.jwtToken,
    )
    if (this.props.dashboardData.data.location_data !== null) {
      this.state.locationData = this.props.dashboardData.data.location_data
    } else if (this.state.success === true && this.props.dashboardData.data.location_data === null) {
      console.log('Error when loading dashboard data: ')
      //  Alert.alert('Error when signing in: ', err.message);
      this.setState({ success: false, errMessage: 'Failed to reach server.' })
    }

    if (!this.state.locationData) {
      await systemReduxDashboardHandler.setDashboardDataSolo(
        this.props,
        id,
        user_id,
        this.props.userData.data.orgid,
        this.props.user.user.signInUserSession.idToken.jwtToken,
      )
      if (this.props.dashboardData.data.location_data !== null) {
        this.state.locationData = this.props.dashboardData.data.location_data
      } else if (this.state.success === true && this.props.dashboardData.data.location_data === null) {
        console.log('Error when loading dashboard data: ')
        //  Alert.alert('Error when signing in: ', err.message);
        this.setState({
          success: false,
          errMessage: 'Failed to reach server.',
        })
      }
    }

    //let queryLocation = [`specific`, `id=${this.state.id}`].join("?");

    // let locationsToGetUrl = [
    //   urls.BACKEND,
    //   "locations",
    //   "property",
    //   queryLocation
    // ].join("/");
    // console.log("locations url" + locationsToGetUrl);

    // let locationReceived = await axios.get(locationsToGetUrl).then(res => {
    //   const locationData = res.data.features[0];
    //   console.log("location data below");
    //   console.log(locationData);
    //   this.setState({ locationData });
    // });

    console.log(this.props)
    await systemReduxDashboardHandler.updateLocationData(
      this.props,
      this.state.id,
      this.props.userData.data.orgid,
      this.props.user.user.signInUserSession.idToken.jwtToken,
    )
    if (this.props.dashboardData.data.location_data !== null) {
      this.state.locationData = this.props.dashboardData.data.location_data
    } else if (this.state.success === true && this.props.dashboardData.data.location_data === null) {
      console.log('Error when loading historical_alerts: ')
      //  Alert.alert('Error when signing in: ', err.message);
      this.setState({ success: false, errMessage: 'Failed to reach server.' })
    }
    //this.state.locationData = this.props.dashboardData.data.location_data

    await systemReduxDashboardHandler.updateLiveAlerts(
      this.props,
      this.state.id,
      this.state.country,
      this.props.user.user.signInUserSession.idToken.jwtToken,
    )
    if (this.props.dashboardData.data.live_alerts !== null) {
      this.state.alerts = this.props.dashboardData.data.live_alerts
    } else if (this.state.success === true && this.props.dashboardData.data.live_alerts === null) {
      console.log('Error when loading historical_alerts: ')
      //  Alert.alert('Error when signing in: ', err.message);
      this.setState({ success: false, errMessage: 'Failed to reach server.' })
    }
    //this.state.alerts = this.props.dashboardData.data.live_alerts

    await systemReduxDashboardHandler.updateHistoricalAlerts(
      this.props,
      this.state.id,
      this.state.country,
      this.props.user.user.signInUserSession.idToken.jwtToken,
    )
    if (this.props.dashboardData.data.historical_alerts !== null) {
      this.state.historical = this.props.dashboardData.data.historical_alerts
    } else if (this.state.success === true && this.props.dashboardData.data.historical_alerts === null) {
      console.log('Error when loading historical_alerts: ')
      //  Alert.alert('Error when signing in: ', err.message);
      this.setState({ success: false, errMessage: 'Failed to reach server.' })
    }
    //this.state.historical = this.props.dashboardData.data.historical_alerts

    await systemReduxHandler.updateWeatherWarnings(this.props, this.props.user.user.signInUserSession.idToken.jwtToken)

    await systemReduxDashboardHandler.updateFloodRisks(
      this.props,
      this.state.id,
      this.props.user.user.signInUserSession.idToken.jwtToken,
    )
    if (this.props.dashboardData.data.flood_risks !== null) {
      this.state.flood_risks = this.props.dashboardData.data.flood_risks
    } else if (this.state.success === true && this.props.dashboardData.data.flood_risks === null) {
      console.log('Error when loading flood_risks: ')
      //  Alert.alert('Error when signing in: ', err.message);
      this.setState({ success: false, errMessage: 'Failed to reach server.' })
    }

    //get metal risks for a property

    await systemReduxDashboardHandler.updateCrimeRisks(
      this.props,
      this.state.id,
      this.props.user.user.signInUserSession.idToken.jwtToken,
    )
    if (this.props.dashboardData.data.crime_risks !== null) {
      this.state.crime_risks = this.props.dashboardData.data.crime_risks
    } else if (this.state.success === true && this.props.dashboardData.data.crime_risks === null) {
      console.log('Error when loading crime_risks: ')
      //  Alert.alert('Error when signing in: ', err.message);
      this.setState({ success: false, errMessage: 'Failed to reach server.' })
    }
    //this.state.crime_risks = this.props.dashboardData.data.crime_risks

    // let query2 = [`?propid=${this.state.locationData.properties.id}`];

    // let metal_theft_url = [
    //   urls.BACKEND,
    //   "risks",
    //   "church",
    //   "metaltheft",
    //   query2
    // ].join("/");

    // let metal_theft_risks = await axios.get(metal_theft_url).then(res => {
    //   const metal_theft_risks = res.data;
    //   console.log("metal theft risks below");
    //   console.log(metal_theft_risks);
    //   this.setState({ metal_theft_risks });
    // });

    //get mitigations for a property

    await systemReduxDashboardHandler.updateMitigations(
      this.props,
      this.state.id,
      this.props.user.user.signInUserSession.idToken.jwtToken,
    )
    if (this.props.dashboardData.data.mitigations !== null) {
      this.state.mitigations = this.props.dashboardData.data.mitigations
    } else if (this.state.success === true && this.props.dashboardData.data.mitigations === null) {
      console.log('Error when loading mitigations: ')
      //  Alert.alert('Error when signing in: ', err.message);
      this.setState({ success: false, errMessage: 'Failed to reach server.' })
    }
    // this.state.mitigations = this.props.dashboardData.data.mitigations

    // let mitigations_url = [urls.BACKEND, "mitigations", "property", query].join(
    //   "/"
    // );

    // let mitigations = await axios.get(mitigations_url).then(res => {
    //   const mitigations = res.data.mitigations;
    //   console.log("mitigations below");
    //   console.log(mitigations);
    //   this.setState({ mitigations });
    // });

    //get loss incidents for a property

    await systemReduxDashboardHandler.updateLossIncidents(
      this.props,
      this.state.id,
      user_id,
      this.props.user.user.signInUserSession.idToken.jwtToken,
    )
    if (this.props.dashboardData.data.loss_incidents !== null) {
      this.state.loss_incidents = this.props.dashboardData.data.loss_incidents
    } else if (this.state.success === true && this.props.dashboardData.data.loss_incidents === null) {
      console.log('Error when loading loss_incidents: ')
      //  Alert.alert('Error when signing in: ', err.message);
      this.setState({ success: false, errMessage: 'Failed to reach server.' })
    }

    await systemReduxHandler.updateWeatherWarnings(this.props, this.props.user.user.signInUserSession.idToken.jwtToken)

    let weatherwarnings = this.props.systemData.data.weather_warnings

    if (weatherwarnings) {
      console.log('WEATHERWARNINGS TRUE')
      let filteredwarnings = weatherwarnings.filter(warning => this.checkWarningRegion(warning.summary))
      this.state.weatherwarnings = filteredwarnings
    } else {
      if (this.state.success === true && this.props.dashboardData.data.weather_warnings === null) {
        console.log('Error when loading weatherwarnings: ')
        //  Alert.alert('Error when signing in: ', err.message);
        this.setState({
          success: false,
          errMessage: 'Failed to reach server.',
        })
      }
    }

    //TEMP CRIME RETRIEVAL

    // let crimes_url = [urls.BACKEND, 'risks', 'crimes', query].join('/');
    //     let crime_risks = await axios.get(crimes_url)
    //         .then(res => {
    //         console.log("crime risks below");
    //         const crime_risks = res.data;
    //         console.log(crime_risks)
    //         this.setState({ crime_risks });
    //       });
    // const crime_risks = 0;
    // console.log(crime_risks);
    // this.setState({ crime_risks });

    // this.fetchGaugeData();

    //getFormData

    this.getLiveReports()

    console.log('MOUNTED')
    this.setState({ mounted: true })
  }

  async getLiveReports() {
    console.log('Form Instances Loaded')
    const propertyid = this.state.locationData.properties.id

    const fetchurl = 'https://r8fd6rsk66.execute-api.eu-west-1.amazonaws.com/Version1/forms/property/' + propertyid

    console.log(fetchurl)
    await axios.get(fetchurl).then(response => {
      const formInstances = response.data.data
      this.setState({ formInstance: formInstances })
    })
  }

  async updateLossIncidents() {
    await systemReduxDashboardHandler.resetLossIncidents(
      this.props,
      this.state.id,
      this.props.userData.data.id,
      this.props.user.user.signInUserSession.idToken.jwtToken,
    )
    // await systemReduxDashboardHandler.updateLossIncidents(this.props,this.state.id,user_id,true);
    if (this.props.dashboardData.data.loss_incidents !== null) {
      this.state.loss_incidents = this.props.dashboardData.data.loss_incidents
    }
  }

  async updateMitigations() {
    await systemReduxDashboardHandler.resetMitigations(
      this.props,
      this.state.id,
      this.props.user.user.signInUserSession.idToken.jwtToken,
    )
    if (this.props.dashboardData.data.mitigations !== null) {
      this.state.mitigations = this.props.dashboardData.data.mitigations
    }
  }
  async fetchGaugeData() {
    //Gauge data is here
    //console.log(this.locationData)
    if (this.state.locationData) {
      let temp_gaugereadings = null
      let params = { propid: this.state.locationData.properties.id }

      await axios({
        method: 'get',
        url: urls.API_GATEWAY + 'flood/gauges-latest',
        params: params,
      })
        .then(res => {
          temp_gaugereadings = res.data
          console.log(temp_gaugereadings)

          if (
            temp_gaugereadings === 'null' ||
            temp_gaugereadings === null ||
            temp_gaugereadings === 'Failure' ||
            typeof temp_gaugereadings === 'undefined'
          ) {
            temp_gaugereadings = null
            console.log('Error retrieving Latest river gauge readings')
          } else if (temp_gaugereadings === 'Invalid Details') {
            console.log('Details for Latest river gauge readings are INVALID')
            temp_gaugereadings = null
          } else if (temp_gaugereadings === 'No stations found') {
            console.log('No Latest river gauge reading (STATIONS) found for the property')
            temp_gaugereadings = null
          } else {
            console.log('SUCCESS!!')

            console.log('Latest river gauge readings retrieved from the database.')
          }
          // console.log(gaugereadings)
          this.setState({ gaugereadings: temp_gaugereadings })
        })
        .catch(err => {
          console.log(temp_gaugereadings)
          if (this.state.success === true) {
            console.log('Error when loading gauge readings list: ')
            //  Alert.alert('Error when signing in: ', err.message);
            this.setState({
              success: false,
              errMessage: 'Failed to reach server.',
            })
          }
          console.log('FAILURE for gauges!!')
          console.log(err)
        })
    } else {
      this.setState({ gaugereadings: [] })
      //   if(this.state.success === true){
      //     console.log("Error when location data missing: ");
      //       //  Alert.alert('Error when signing in: ', err.message);
      //       this.setState({success:false, errMessage:"Failed to reach server. (location data)"})
      // }
    }
  }

  // checks any text if it contains county information
  checkWarningRegion(warning) {
    console.log('see warnings below')
    console.log(warning)
    console.log(this.state.locationData.properties.address_county)
    //if warning contains location county/ region, return true
    if (
      this.state.locationData.properties.address_county == '' &&
      this.state.locationData.properties.address_town == ''
    )
      return false
    else {
      if (
        warning.includes(
          this.state.locationData.properties.address_county || this.state.locationData.properties.address_town,
        )
      )
        return true
      else return false
    }
  }

  getActiveAlerts = alerts => {
    this.state.activealerts = alerts
      .filter(item => item.active === true)
      .map(({ id, description, message, severityLevel, timeRaised, region }) => ({
        id,
        description,
        message,
        severityLevel,
        timeRaised,
        region,
      }))
    console.log(this.state.activealerts)
  }

  //Render the dahboard when mounted (loaded)

  render() {
    console.log(this.state.locationData)
    if (this.state.mounted === false) {
      return (
        <React.Fragment>
          <PropertyHeader
            user_permissions={this.props.userData.data.permissions}
            user_portfolio_type={this.props.userData.data.portfolio_type}
            organisation={this.props.userData.data.orgid}
            customstyle={this.props.userData.data.customstyle}
          />
          <Loader color="#0652dd" />
        </React.Fragment>
      )
    } else if (this.state.locationData === null) {
      return (
        <React.Fragment>
          <PropertyHeader
            user_permissions={this.props.userData.data.permissions}
            user_portfolio_type={this.props.userData.data.portfolio_type}
            organisation={this.props.userData.data.orgid}
            locationData2={this.state.locationData}
            alerts2={this.state.alerts}
            flood_risks={this.state.flood_risks}
            mitigations={this.state.mitigations}
            logout={() => Logout(this.props)}
            lossincidents={this.state.lossincidents}
            activeweatherwarnings={this.state.weatherwarnings}
            propid={this.state.id}
            customstyle={this.props.userData.data.customstyle}
            updateLossIncidents={this.updateLossIncidents}
            updateMitigations={this.updateMitigations}
            secret={this.props.user.user.signInUserSession.idToken.jwtToken}
          />
          {this.showNotice()}
          <Loader color="#0652dd" />
        </React.Fragment>
      )
    } else {
      console.log(this.state.locationData)
      console.log(this.state)
      return (
        <React.Fragment>
          <PropertyHeader
            user_permissions={this.props.userData.data.permissions}
            user_portfolio_type={this.props.userData.data.portfolio_type}
            locationData2={this.state.locationData}
            alerts2={this.state.alerts}
            flood_risks={this.state.flood_risks}
            mitigations={this.state.mitigations}
            logout={() => Logout(this.props)}
            lossincidents={this.state.lossincidents}
            activeweatherwarnings={this.state.weatherwarnings}
            propid={this.state.id}
            customstyle={this.props.userData.data.customstyle}
            updateLossIncidents={this.updateLossIncidents}
            updateMitigations={this.updateMitigations}
            currentpage={this.state.current_page}
            secret={this.props.user.user.signInUserSession.idToken.jwtToken}
          />
          {this.showNotice()}

          <TestDashboard
            user={this.props.userData.data}
            className="reset-this"
            onClose={e => {
              let target = e.target
              if (target.id === 'fade' || target.id === 'close') {
                this.closeDashboard()
              }
            }}
            feature={this.state.locationData.properties}
            geoFeature={this.state.locationData}
            flood_risks={this.state.flood_risks}
            metal_theft_risks={this.state.metal_theft_risks}
            messages={this.state.messages}
            weather_risks={this.state.weather_risks}
            crime_risks={this.state.crime_risks}
            alerts={this.state.alerts}
            mitigations={this.state.mitigations}
            lossincidents={this.state.lossincidents}
            historical={this.state.historical}
            formInstance={this.state.formInstance}
            // activeAlerts={this.activeAlerts}
            activeAlerts={this.state.alerts}
            activeweatherwarnings={this.state.weatherwarnings}
            gaugereadings={this.state.gaugereadings}
            secret={this.props.user.user.signInUserSession.idToken.jwtToken}
            user_permissions={this.props.userData.data.permissions}
          />
        </React.Fragment>
      )
    }
  }
}
function mapStateToProps(state) {
  //console.log(state);
  const { userLogged, user, userData, systemData, mapAlertData, mapLocationData, dashboardData } = state

  return {
    userLogged,
    user,
    userData,
    systemData,
    mapAlertData,
    mapLocationData,
    dashboardData,
  }
}
export default connect(mapStateToProps)(UserDashboardSolo)
