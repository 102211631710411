import React from "react";

import "./LastPageGetStarted.css";
import { Link } from "react-router-dom";
//
export class SubmitBulkProperty extends React.Component {
  constructor(props) {
    super(props);
    //setting initial user row
    this.state = {};
  }

  render() {
    if (this.props.currentStep !== 4) {
      return null;
    }

    if (this.props.databaseSuccess !== true) {
      return (
        <div>
          <label>
            There was a problem with adding your property. Please try again
          </label>
        </div>
      );
    }

    return (
      <div className="submissionProperty">
        <span id="submittedBulkProperty" class="glyphicon glyphicon-ok-sign" />
        <label>Property has been submitted for analysis</label>

        <div className="submit-navigate-buttons">
          <Link className="submit-navigate" to={"/"}>
            <button>Home</button>
          </Link>
          <Link className="submit-navigate" to={"/LocationListPage"}>
            <button>View All Properties</button>
          </Link>
          <Link className="submit-navigate" to={"/MainBulkAddProperties"}>
            <button> Add More Properties</button>
          </Link>
        </div>
      </div>
    );
  }
}
export default SubmitBulkProperty;
