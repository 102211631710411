import React from "react";
import "./Login.css";
import PortfolioLandingPage from "./PortfolioLandingPage";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Redirect } from "react-router-dom";
//import {MainMenu, Auth1} from './MainMenu';
import { userActions } from "../redux/_actions";
import { connect } from "react-redux";
import { getUserDetails } from "../redux/_helpers/getUserDetails";
import Auth from "@aws-amplify/auth";
import { systemActions } from "../redux/_actions";

import axios from "axios";
import * as urls from "../components/maps/urls";
import "./ResetPassword.css";

export class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toPortfolioLanding: false,
      email: "",
      v_code: "",
      password_new: "",
      password_confirm: "",

      user: "",
      step: 1,
      errMessage: "",
    };
    this.changePassword = this.changePassword.bind(this);
    this.changePasswordDatabase = this.changePasswordDatabase.bind(this);
    this.forgotPassword = this.forgotPassword.bind(this);
    this.next_form = this.next_form.bind(this);
    this.back_form = this.back_form.bind(this);
  }

  // async forgotPassword(){

  //   await Auth.forgotPassword(this.state.email)
  //   .then(data => {console.log(data);
  //   alert("Password reset sent to "+this.state.email
  //   )})
  //   .catch(err => console.log(err));

  // }

  async forgotPassword() {
    let email = this.state.email;
    if (
      email &&
      email.length > 3 &&
      !email.toLowerCase().endsWith("arcusfm.com")
    ) {
      email = email.toLowerCase();
    }

    if (this.validateEmail) {
      await Auth.forgotPassword(email)
        .then((data) => {
          // console.log(data);
          // alert("Password reset sent to " + this.state.email);
          this.setState({ errMessage: "" });
          this.next_form();
        })
        .catch((err) => {
          // console.log(err)

          this.setState({ errMessage: err.message });
          // alert("User not found in the database.");
        });
    }
  }

  async checkConnection() {
    let success = false;
    await axios({
      method: "get",
      url: urls.API_GATEWAY + "users/working-basic",
    })
      .then((res) => {
        if (res.data === "Success") {
          success = true;
          console.log("connection working");
        }
      })
      .catch((err) => {
        success = false;
        console.log("FAILURE!!");
        console.log(err);
        alert("No connection to the server");
        if (!err.message) {
          console.log("Error when loading locations: ", err);
          //  Alert.alert('Error when signing in: ', err);
        } else {
          console.log("Error when loading working connection: ", err.message);
          //  Alert.alert('Error when signing in: ', err.message);
          //this.setState({success:false, errMessage:"Cannot reach Server"})
        }
      });
    return success;
  }

  showNotice() {
    if (this.state.errMessage && this.state.errMessage.length) {
      return (
        <div className="alert alert-danger" role="alert">
          {this.state.errMessage}
        </div>
      );
    }
  }

  async changePassword() {
    let changed = false;
    let user = null;

    let email = this.state.email;
    if (
      email &&
      email.length > 3 &&
      !email.toLowerCase().endsWith("arcusfm.com")
    ) {
      email = email.toLowerCase();
    }

    if (this.state.password_new != this.state.password_confirm) {
      this.setState({ errMessage: "Passwords do not match, please try again" });
    } else {
      if (this.validateForm() == true) {
        let answer = await this.checkConnection();
        if (answer === true) {
          await Auth.forgotPasswordSubmit(
            email,
            this.state.v_code,
            this.state.password_new
          )
            .then((data) => {
              // console.log(data);

              changed = true;
            })
            .catch((err) => {
              // console.log(err);
              this.setState({ errMessage: err.message });

              changed = false;
              // console.log(changed);
            });

          if (changed === true) {
            await Auth.signIn(email, this.state.password_new)
              .then(async (user1) => {
                user = user1;
              })
              .catch((err) => {
                user = null;
                if (!err.message) {
                  // console.log('Error when signing in: ', err);
                  this.setState({ errMessage: err.message });
                  //  Alert.alert('Error when signing in: ', err);
                } else {
                  // console.log('Error when signing in: ', err.message);
                  //  Alert.alert('Error when signing in: ', err.message);
                  this.setState({ errMessage: err.message });
                }
              });
          }

          if (changed === true && user != null) {
            // console.log("Password change WORKED on cognito");
            changed = await this.changePasswordDatabase(
              email,
              this.state.password_new,
              user.signInUserSession.idToken.jwtToken,
              user.username.split("").reverse().join(""),
              user.userDataKey +
                " " +
                user.signInUserSession.accessToken.jwtToken +
                " " +
                user.pool.userPoolId
            );

            if (changed === true) {
              let userdata = await getUserDetails(
                email,
                this.state.password_new
              );

              document.getElementById("forgot-password2").reset();
              alert("Password successfully changed");
              this.props.dispatch(userActions.changedPasswordLogged());
              this.props.dispatch(userActions.changedPassword(user));
              this.props.dispatch(userActions.setUserData(userdata));

              // console.log("password change user below")
              // console.log(user)
              user.clearCachedTokens();

              Object.keys(sessionStorage).forEach((key) => {
                if (key.includes("Cognito")) {
                  sessionStorage.removeItem(key);
                }
              });

              this.props.history.push({
                pathname: "/",
              });
              // document.location.reload()
            }
          } else {
            // alert("No match for these credentials was found.")
          }
        } else {
          this.setState({ errMessage: "Cannot reach the server" });
        }
      } else {
        this.setState({
          errMessage: "Please ensure the password meets the above criteria",
        });
      }
    }
  }
  async changePasswordDatabase(
    email1,
    password,
    secret,
    aws_id,
    secret_key,
    user
  ) {
    let success = true;

    let email = email1;
    if (
      email &&
      email.length > 3 &&
      !email.toLowerCase().endsWith("arcusfm.com")
    ) {
      email = email.toLowerCase();
    }
    axios.defaults.headers.common["Authorization"] = "Bearer " + secret;
    let authstr = "Bearer " + secret;
    let headers = {
      "Content-Type": "application/json",
      Authorization: authstr,
    };

    let data = {
      email: email,
      aws_id: aws_id,
      secret_key: secret_key,
      new_password: password,
    };

    await axios({
      method: "post",
      url: urls.API_GATEWAY + "users/change-user-password",
      headers: headers,
      data: data,
    })
      .then((res) => {
        console.log(res.data);
        success = true;
        let result = res.data;

        if (
          result === "null" ||
          result === null ||
          result === "Failure" ||
          typeof result === "undefined"
        ) {
          success = false;
          console.log("ERROR processing password change!");
        } else if (result === "Invalid Details") {
          console.log("Details for changing password are INVALID");
          success = false;
        } else if (result === "Success") {
          console.log("PASSWORD CHANGE SUCCESS");
          success = true;
        } else {
          console.log("PASSWORD CHANGE FAILED");
          success = false;
        }
      })
      .catch((err) => {
        success = false;
        console.log("password change failed!!");
        console.log(err);
      });

    return success;
  }

  //this.props.navigation.navigate('Authloading');
  //  this.props.userHasAuthenticated(true);
  //   this.state.toPortfolioLanding = true;

  //  // Auth1.authenticate();
  //  // console.log(Auth1.isAuthenticated)

  //   this.setState({ user })

  validateForm = () => {
    return (
      this.state.v_code &&
      this.state.password_new &&
      this.state.v_code.length >= 3 &&
      this.state.password_new.length > 7
    );
  };

  validateEmail = () => {
    var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return (
      this.state.email.length && emailRegex.test(this.state.email.toLowerCase())
    );
  };

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  componentDidMount() {
    //console.log(userActions.getAll())
    //this.props.dispatch(userActions.getAll());
  }
  next_form() {
    if (this.state.step < 2) {
      this.setState({ step: this.state.step + 1 });
    }

    //console.log(userActions.getAll())
    //this.props.dispatch(userActions.getAll());
  }
  back_form() {
    if (this.state.step > 1) {
      this.setState({ step: this.state.step - 1 });
    }

    //console.log(userActions.getAll())
    //this.props.dispatch(userActions.getAll());
  }

  render() {
    if (this.state.toPortfolioLanding === true) {
      {
        console.log("redirecting");
      }
      return this.props.history.push({
        pathname: "/",
      });
    }

    if (this.state.step == 1) {
      return (
        <div id="content">
          <div className="wrapper fadeInDown">
            <div id="formContent">
              {/* <img className="login_logo" src={require("../images/Logo.png")} /> */}

              <form id="forgot-password1">
                <h1>Password Reset</h1>
                <div className="forgot-password-input1">
                  <div id="emailinput">
                    <label>
                      To reset your password, enter the email address you use to
                      sign in
                    </label>
                    <input
                      autoFocus
                      type="email"
                      id="email"
                      className="form-control"
                      name="login"
                      placeholder="you@example.com"
                      onChange={this.handleChange}
                    />

                    <input
                      type="button"
                      className="form-control"
                      id="sendverification"
                      value="Send verification code to my email"
                      onClick={this.forgotPassword}
                      disabled={!this.validateEmail()}
                    />
                    {/* <input
                  type="button"
                  className="fadeIn fourth1"
                  value="Next"
                  onClick={this.next_form}
                  disabled={!this.validateEmail()}
                /> */}
                  </div>
                </div>
              </form>
              {this.showNotice()}
            </div>
          </div>
        </div>
      );
    }
    if (this.state.step == 2) {
      return (
        <div id="content">
          <div className="wrapper fadeInDown">
            <div id="formContent">
              {/* <img className="login_logo" src={require("../images/Logo.png")} /> */}

              <form id="forgot-password2">
                <div id="userpasswordinput">
                  <div id="emailinput">
                    <h1>Email Sent!</h1>
                    <div className="forgot-password-input2">
                      <label>Check your email for verification code</label>

                      <div className="form-group">
                        <label>Verification Code</label>
                        <input
                          type="text"
                          id="v_code"
                          className="form-control"
                          name="v_code"
                          placeholder="verification code"
                          onChange={this.handleChange}
                        />
                      </div>

                      <div className="form-group">
                        <label>New Password </label>
                        <input
                          type="password"
                          id="password_new"
                          className="form-control"
                          name="password_new"
                          placeholder="new password"
                          onChange={this.handleChange}
                        />
                      </div>

                      <div className="form-group">
                        <label>Confirm Password </label>
                        <input
                          type="password"
                          id="password_confirm"
                          className="form-control"
                          name="password_confirm"
                          placeholder="confirm password"
                          onChange={this.handleChange}
                        />
                      </div>

                      <input
                        type="button"
                        id="sendverification"
                        className="form-control"
                        value="Change Password"
                        onClick={this.changePassword}
                        // disabled={!this.validateForm()}
                      />
                    </div>
                    <ul id="passwordinstruction">
                      In order to protect your account, make sure your password
                      :<li>Is longer than 7 characters</li>
                      <li>Contains uppercase letters</li>
                      <li>Contains lowercase letters</li>
                      <li>Contains numbers</li>
                    </ul>

                    {/* <input
                    type="button"
                    className="form-control"
                    value="Back"
                    onClick={this.back_form}
                 
                  /> */}
                  </div>
                </div>
              </form>
              {this.showNotice()}
            </div>
          </div>
        </div>
      );
    }

    return this.props.history.push({
      pathname: "/login",
    });
  }
}

function mapStateToProps(state) {
  //console.log(state)
  const { userLogged, user, userData } = state;

  return {
    userLogged,
    user,
    userData,
  };
}

//const connectedHomePage = connect(mapStateToProps)(HomePage);
export default connect(mapStateToProps)(ResetPassword);
