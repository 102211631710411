import React, { useEffect } from 'react'
import Table from 'react-bootstrap/Table'
import Search from 'balkerne-components/Search'
import Card from 'react-bootstrap/Card'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'balkerne-components/Button'
import Pagination from 'react-bootstrap/Pagination'
import { useState } from 'react'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import LoadForm from './LoadForm'
import ReactTooltip from 'react-tooltip'
import i from './TemplateManager.module.scss'

export const FormSelector = ({ ...props }) => {
  const [categorydisplay, setCategoryDisplay] = useState('block')
  const [subCategorydisplay, setSubCategoryDisplay] = useState('none')
  const [formdisplay, setFormDisplay] = useState('none')
  // AWS Main API
  const baseurl = 'https://r8fd6rsk66.execute-api.eu-west-1.amazonaws.com/Version1'
  //const baseurl = 'https://c2600835-8b88-409b-abd1-9b36ebc94cb5.mock.pstmn.io'
  const [searchterm, setSearchTerm] = useState('')
  const [categoryid, setCategoryId] = useState('')
  const [newCategories, setNewCategories] = useState([])
  const [newCategoriesList, setNewCategoriesList] = useState()
  const [newSubCategories, setNewSubCategories] = useState([])
  const [forms, setForms] = useState([])
  const [formSelectorTitle, setFormSelectorTitle] = useState('')

  let history = useHistory()

  const handleSearch = (event, searchkey) => {
    if (event.target.value != '') {
      let searchterm = event.target.value.toUpperCase()
      switch (searchkey) {
        case 'categories':
          let categoryrows = document.getElementById('categoryRows').children
          Object.values(categoryrows).map(categoryrow => {
            let rowterm = categoryrow.children[0].textContent.toUpperCase()
            if (rowterm.includes(searchterm)) {
              if (categoryrow.style.display == 'none') {
                categoryrow.style.display = 'table-row'
              }
            } else {
              categoryrow.style.display = 'none'
            }
          })
          break
        case 'subcategories':
          let subcategoryrows = document.getElementById('subcategoryRows').children
          Object.values(subcategoryrows).map(subcategoryrow => {
            let rowterm = subcategoryrow.children[0].textContent.toUpperCase()
            if (rowterm.includes(searchterm)) {
              if (subcategoryrow.style.display == 'none') {
                subcategoryrow.style.display = 'table-row'
              }
            } else {
              subcategoryrow.style.display = 'none'
            }
          })
          break
        case 'forms':
          let formrows = document.getElementById('formRows').children
          Object.values(formrows).map(formrow => {
            let rowterm = formrow.children[0].textContent.toUpperCase()
            if (rowterm.includes(searchterm)) {
              if (formrow.style.display == 'none') {
                formrow.style.display = 'table-row'
              }
            } else {
              formrow.style.display = 'none'
            }
          })
          break
      }
    }
    // Reset table display to show all rows
    else {
      switch (searchkey) {
        case 'categories':
          let categoryrows = document.getElementById('categoryRows').children
          Object.values(categoryrows).map(categoryrow => {
            categoryrow.style.display = 'table-row'
          })
          break
        case 'subcategories':
          let subcategoryrows = document.getElementById('subcategoryRows').children
          Object.values(subcategoryrows).map(subcategoryrow => {
            subcategoryrow.style.display = 'table-row'
          })
          break
        case 'forms':
          let formrows = document.getElementById('formRows').children
          Object.values(formrows).map(formrow => {
            formrow.style.display = 'table-row'
          })
          break
      }
    }
  }
  useEffect(() => {
    switch (props.typeID) {
      case 2:
        setFormSelectorTitle('Report Incident')
        break
      case 3:
        setFormSelectorTitle('Complete Survey')
        break
    }
    if (props.userData != undefined) {
      if (props.userData['data']['organisation']['id'] != null) {
        getCategories()
      }
    }
  }, [])

  const selectCategoryNew = category_id => {
    //console.log(newCategoriesList[category_id])
    if (props.typeID == 2) {
      setCategoryDisplay('none')
      setSubCategoryDisplay('block')
      setCategoryId(category_id)
      setNewSubCategories(newCategoriesList[category_id]['subcategories'])
    } else if (props.typeID == 3) {
      // for surveys
      selectSubCategory(category_id)
      setCategoryDisplay('none')
    }
  }

  const selectForm = formid => {
    getFormById(formid)
  }
  const backButton = nextDisplay => {
    switch (nextDisplay) {
      case 'category':
        setCategoryDisplay('block')
        setSubCategoryDisplay('none')
        setCategoryId('')
        break
      case 'subcategory':
        if (props.typeID == 3) {
          setCategoryDisplay('block')
          setCategoryId('')
          setFormDisplay('none')
        } else {
          setSubCategoryDisplay('block')
          setFormDisplay('none')
        }
        break
      default:
        break
    }
  }

  // Generate new form on Dynamic Form
  const getFormById = async templateid => {
    const path = '/forms/templates/' + templateid
    const fetchurl = baseurl + path
    await axios.get(fetchurl).then(response => {
      //console.log(response.data)
      // redirect to DynamicForm Route
      //setCategories(response.data.categories)
      const formDetails = JSON.stringify({
        title: response.data.data.title,
        template_id: response.data.data.id,
        version_id: response.data.data.version,
      })
      //console.log(formDetails)
      history.push({
        pathname: '/DynamicForm',
        state: {
          templateSchema: JSON.parse(response.data.data.template),
          uiSchema: JSON.parse(response.data.data.uiSchema),
          formDetails: formDetails,
          typeID: props.typeID,
        },
      })
    })
  }
  const getCategories = async () => {
    // New version of form seletor
    const categorypath = '/forms/categories?form_type=' + props.typeID
    const fetchurl = baseurl + categorypath
    var tempCategories = []
    var tempCategoriesList = {}
    const organisation_id = props.userData['data']['organisation']['id']

    await axios.get(fetchurl).then(response => {
      //console.log(response.data)
      // handle survey and reports
      const subcategorypath = '/forms/templates?organisation_id=' + organisation_id + '&subcategory_id='

      switch (props.typeID) {
        case 2:
          // Report incidents
          var categorycounter = 0
          var categoryresponse = response.data.data
          //console.log("Categories : " + categoryresponse.length)
          //console.log(categoryresponse)
          Object.values(categoryresponse).map(category => {
            var categoryObj = {}
            categoryObj['id'] = category.id
            categoryObj['title'] = category.title
            categoryObj['subcategories'] = []
            categoryObj['setDisplay'] = false
            categoryObj['formType'] = 'Report'
            // console.log(category.subcategories)
            if (category.subcategories.length > 0) {
              var subcategorycounter = 0
              Object.values(category.subcategories).map(async subcategory => {
                var fetchforms = baseurl + subcategorypath
                var subcategoryObj = {}
                subcategoryObj['id'] = subcategory.id
                subcategoryObj['title'] = subcategory.title
                subcategoryObj['forms'] = []

                fetchforms = fetchforms + subcategory.id
                await axios
                  .get(fetchforms)
                  .then(response => {
                    if (response.data.data.length > 0) {
                      categoryObj['setDisplay'] = true
                      Object.values(response.data.data).map(form => {
                        var formObj = {}
                        formObj['id'] = form.id
                        formObj['title'] = form.title
                        formObj['description'] = form.description

                        subcategoryObj['forms'].push(formObj)
                      })
                    }
                  })
                  .then(() => {
                    if (subcategorycounter == category.subcategories.length - 1) {
                      //console.log("Finished processing subcategories for this category")
                      //console.log("Processed category number : " + categorycounter)
                      //console.log(categoryObj)
                      if (categorycounter == categoryresponse.length - 1) {
                        setNewCategoriesList({ ...tempCategoriesList })
                        setNewCategories(tempCategories)
                        //console.log("Finished processing all categories")
                      }
                      categorycounter += 1
                    }
                    subcategorycounter += 1
                    categoryObj['subcategories'].push(subcategoryObj)
                  })
              })
              tempCategories.push(categoryObj)
              tempCategoriesList[categoryObj['id']] = categoryObj
            } else {
              // console.log("No subcategories for this category..", category.title)
              categorycounter += 1
            }
          })
          break
        case 3:
          // Complete a Survey
          var categorycounter = 0
          var categoryresponse = response.data.data

          Object.values(categoryresponse).map(category => {
            var surveypath = '/forms/templates?organisation_id=' + organisation_id + '&category_id=' + category.id
            var fetchsurveys = baseurl + surveypath
            var categoryObj = {}
            categoryObj['id'] = category.id
            categoryObj['title'] = category.title
            categoryObj['surveys'] = []
            categoryObj['setDisplay'] = false
            categoryObj['formType'] = 'Survey'

            axios
              .get(fetchsurveys)
              .then(response => {
                if (response.data.data.length > 0) {
                  //console.log(response.data.data)
                  categoryObj['setDisplay'] = true
                  Object.values(response.data.data).map(survey => {
                    var surveyObj = {}
                    surveyObj['id'] = survey.id
                    surveyObj['title'] = survey.title
                    surveyObj['description'] = survey.description

                    categoryObj['surveys'].push(surveyObj)
                  })
                }
              })
              .then(() => {
                //console.log(categorycounter)
                if (categorycounter == Array.from(categoryresponse).length - 1) {
                  setNewCategoriesList({ ...tempCategoriesList })
                  setNewCategories(tempCategories)
                }
              })
              .then(() => {
                categorycounter += 1
              })
            tempCategories.push(categoryObj)
            tempCategoriesList[categoryObj['id']] = categoryObj
          })
          break
      }
    })
  }

  const selectSubCategory = async subcategoryid => {
    if (props.typeID == 3) {
      setForms(newCategoriesList[subcategoryid]['surveys'])
    } else if (props.typeID == 2) {
      newCategoriesList[categoryid]['subcategories'].map(subcategory_obj => {
        if (subcategory_obj['id'] == subcategoryid) {
          setForms(subcategory_obj['forms'])
        }
      })
    }
    setSubCategoryDisplay('none')
    setFormDisplay('block')
  }

  const homeButton = () => {
    history.push('/')
  }

  return (
    <>
      <div
        style={{
          position: 'absolute',
          left: 0,
          marginLeft: 40,
          display: 'flex',
          alignItems: 'center',
          marginBottom: 20,
        }}>
        <a style={{ cursor: 'pointer', display: 'flex' }} onClick={homeButton}>
          <img style={{ marginRight: 10 }} src={require('../../images/icons/Back.svg')}></img>
          <p className={i.link}>Property Dashboard</p>
        </a>
        <p style={{ marginLeft: 10, marginRight: 10, marginBottom: 'auto', marginTop: 'auto' }}> / </p>
        <p style={{ marginTop: 'auto', marginBottom: 'auto' }}>{formSelectorTitle}</p>
      </div>
      <Card style={{ marginTop: 40 }}>
        <Card.Header>{formSelectorTitle}</Card.Header>
        <Card.Body style={{ display: categorydisplay }}>
          <Search placeholder="Search" onChange={e => handleSearch(e, 'categories')} />
          <Table>
            <tbody id="categoryRows">
              {newCategories.map(category => (
                <tr
                  style={{ cursor: 'pointer', display: category.setDisplay ? 'table-row' : 'none' }}
                  key={category.id}
                  id={category.id}
                  onClick={() => selectCategoryNew(category.id)}
                  searchdescription={category.title}>
                  <td style={{ width: '70%' }}>{category.title}</td>
                  <td style={{ color: '#95AAC9', display: category['formType'] == 'Survey' ? 'table-cell' : 'none' }}>
                    {category.formType == 'Survey'
                      ? category.surveys.length > 1
                        ? category.surveys.length + ' surveys'
                        : category.surveys.length + ' survey'
                      : ''}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>

        <Card.Body style={{ display: subCategorydisplay }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <a style={{ cursor: 'pointer' }} onClick={() => backButton('category')}>
              <img src={require('../../images/icons/Back.svg')}></img>
            </a>
            <Search style={{ width: '80%' }} placeholder="Search" onChange={e => handleSearch(e, 'subcategories')} />
          </div>
          <Table>
            <tbody id="subcategoryRows">
              {newSubCategories.map(subcategory => (
                <tr
                  style={{ cursor: 'pointer', display: subcategory['forms'].length > 0 ? 'table-row' : 'none' }}
                  key={subcategory.id}
                  id={subcategory.id + '-subcategory_row'}
                  onClick={() => selectSubCategory(subcategory.id)}>
                  <td style={{ paddingTop: 16, paddingBottom: 16, paddingRight: 0, paddingLeft: 16, width: '3%' }}>
                    <a data-tip data-for="exampleTip" style={{ cursor: 'pointer' }}>
                      <img style={{ width: 20, height: 20 }} src={require('../../images/icons/Info.svg')}></img>
                    </a>
                    <ReactTooltip id="exampleTip" place="top" effect="solid">
                      This is an example
                    </ReactTooltip>
                  </td>
                  <td style={{ width: '70%', paddingLeft: 10, verticalAlign: 'middle' }}>{subcategory.title}</td>
                  <td style={{ color: '#95AAC9', verticalAlign: 'middle' }}>
                    {subcategory['forms'].length > 1
                      ? subcategory['forms'].length + ' forms'
                      : subcategory['forms'].length + ' form'}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>

        <Card.Body style={{ display: formdisplay }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <a style={{ cursor: 'pointer' }} onClick={() => backButton('subcategory')}>
              <img src={require('../../images/icons/Back.svg')}></img>
            </a>
            <Search style={{ width: '80%' }} placeholder="Search" onChange={e => handleSearch(e, 'forms')} />
          </div>
          <Table>
            <tbody id="formRows">
              {forms.map(form => (
                <tr
                  style={{ cursor: 'pointer' }}
                  key={form.id}
                  id={form.id + '-form_row'}
                  onClick={() => selectForm(form.id)}>
                  <td style={{ paddingTop: 16, paddingBottom: 16, paddingRight: 0, paddingLeft: 16, width: '3%' }}>
                    <a data-tip data-for="exampleTip" style={{ cursor: 'pointer' }}>
                      <img style={{ width: 20, height: 20 }} src={require('../../images/icons/Info.svg')}></img>
                    </a>
                    <ReactTooltip id="exampleTip" place="top" effect="solid">
                      {form.description}
                    </ReactTooltip>
                  </td>
                  <td style={{ width: '70%', paddingLeft: 0, verticalAlign: 'middle' }}>{form.title}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>

        {/* <Card.Footer>
          <Container fluid>
            <Row className="d-flex align-items-center">
              <Col xs="1" className="d-flex justify-content-start align-items-center">
                <Button size="tiny" light>
                  Back
                </Button>
              </Col>
              <Col xs="10" className="d-flex justify-content-center align-items-center">
                <Pagination size="sm">
                  <Pagination.Item>{1}</Pagination.Item>
                  <Pagination.Item>{2}</Pagination.Item>
                  <Pagination.Item>{3}</Pagination.Item>
                </Pagination>
              </Col>
              <Col xs="1" className="d-flex justify-content-end align-items-center">
                <Button size="tiny" light>
                  Next
                </Button>
              </Col>
            </Row>
          </Container>
        </Card.Footer> */}
      </Card>
    </>
  )
}

export default FormSelector
