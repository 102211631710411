import axios from "axios";
import * as urls from "../../components/maps/urls";

export async function getPropertyDetails(prop_id, secret) {
  let payload = null;

  let params = { prop_id: prop_id };

  await axios({
    method: "get",
    url: urls.API_GATEWAY + "locations/property-details",
    params: params,
  })
    .then((res) => {
      console.log(res.data);
      payload = res.data;

      if (
        payload === "null" ||
        payload === null ||
        payload === "Failure" ||
        typeof payload === "undefined"
      ) {
        payload = null;
        console.log("Property Details getting ERROR");
      } else if (payload === "Invalid Details") {
        console.log("Credentials for getting Property Details are INVALID");
        payload = null;
      } else if (payload === "No details found") {
        console.log("No Property Details were found!");
        payload = null;
      } else {
        console.log("SUCCESS!!");
        console.log("Property Details Data retrieved from the database.");
      }
    })
    .catch((err) => {
      payload = null;
      console.log("FAILURE!!");
      console.log("Property Details getting ERROR");
      console.log(err);
    });
}
