import React from "react";
import "./PropertyDetails.scss";

const PropertyDetails = ({
  floorArea,
  roofDesc,
  propertyType,
  inspectionDate,
  floorLevel,
}) => {
  const date = inspectionDate
    ? new Date(inspectionDate).toLocaleDateString("en-UK")
    : null;
  return (
    <div className="property">
      <div className="property__header">
        Property Details | <span className="small">EPC</span>
      </div>
      <div className="property__details">
        <Detail label="Floor Area" testid="floor-area">
          {floorArea}
        </Detail>
        <Detail label="Roof Type" rows={2} testid="roof-type">
          {roofDesc}
        </Detail>
        <Detail label="Property Type" testid="property-type">
          {propertyType}
        </Detail>
        <Detail label="Inspection Date" testid="inspect-date">
          {date}
        </Detail>
        <Detail label="Floor Count" testid="floor-count">
          {floorLevel}
        </Detail>
      </div>
    </div>
  );
};

const Detail = ({ label, rows, testid, children }) => (
  <div
    className={"detail " + (rows != undefined ? `detail--rows-${rows}` : "")}
    data-testid={testid}
  >
    <span className="detail__label">{label}</span>
    <div className="detail__container">
      <span className="detail__text">{children ? children : "-"}</span>
    </div>
  </div>
);

export default PropertyDetails;
