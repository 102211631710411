import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form,Row, Col,Dropdown ,Card} from "react-bootstrap";

export default class ResultsSelect extends Component {
	handleSelect = event => {
		console.log(event.target.value)
		if (this.props.addressSelectedCallback != null) {
			console.log("updating1")
			console.log(event.target.value) 
			if(!Number.isNaN(event.target.value)){
				this.props.addressSelectedCallback(this.props.data[event.target.value]);
				this.props.updateSelected(event.target.value);
			}
			
		}
	};

	render() {

		if(this.props.refreshed ==false && this.props.data.length < 1 &&this.props.placeholder != null){
			console.log(this.props.selected)
			
			return (
				<React.Fragment key={this.props.id}>
				<Form.Label>Result:</Form.Label>
                <Form.Control  key={this.props.id} controlId={this.props.id} className="final-address"  size="sm"  as="select" onChange={(e) => (this.handleSelect(e))}>
				{this.props.data.map((address, index) => {
					return (
						<option key={this.props.id+""+index} selected={this.props.selected == index} value={index}>{address.summaryline}</option>
					);
				})}
               
                </Form.Control>
		
			{/* </div> */}
			</React.Fragment>
		// 		<div>
		// <label className="address-lookup-label" htmlFor={index}>
		// 		Result:
		// 	</label>
		// 		<select
				
		// 			style={{ display: "block" }}
		// 			onChange={this.handleSelect}
		// 			defaultValue="null"
		// 			id={this.props.id}
		// 		>
					
					
						
		// 					<option selected className="address-lookup-option" >
		// 						{this.props.placeholder}
		// 					</option>
						
		// 		</select>
		// 		</div>
		 	);
		 }
	 	else{
			 console.log(this.props.selected)
		return (
			<React.Fragment key={this.props.id}>
				<Form.Label>Result:</Form.Label>
                <Form.Control key={this.props.id}  controlId={this.props.id}   className="text-success"  size="sm" as="select" onChange={(e) => (this.handleSelect(e))}>
				{this.props.data.map((address, index) => {
					return (
						<option  key={this.props.id+""+index}  selected={this.props.selected == index} value={index}>{address.summaryline}</option>
					);
				})}
                </Form.Control>
		
			{/* <select
				
				style={{ display: "block" }}
				onChange={this.handleSelect}
				defaultValue="null"
				id={this.props.id}
			>
				<option value="null" disabled>
					Select an address...
				</option>
				
			</select> */}
			{/* </div> */}
			</React.Fragment>
		);
	}
}
}
ResultsSelect.propTypes = {
	data: PropTypes.array,
	addressSelectedCallback: PropTypes.func,
};
