import React from "react";
import "./Displayfireresponse.css";
import { GoogleMapMini } from "../maps/GoogleMapMini";
import * as styles from "../maps/styles";
import ProgressBar from "react-bootstrap/ProgressBar";
import moment from "moment";

export class Displayfirereponse extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      risk_rating: this.props.riskrating,
      stationid: this.props.id,
    };
  }

  ConvertDateTime(seconds) {
    let mm = moment.utc(seconds * 1000).format("mm");
    let ss = moment.utc(seconds * 1000).format("ss");

    let date = mm + "m " + ss + "s";

    // console.log(date)
    return date;
  }

  render() {
    let resp_time_high =
      parseInt(this.props.drive_time_max) +
      parseInt(this.props.avg_crew_turnout) +
      parseInt(this.props.avg_call_handling);

    let resp_time_low =
      parseInt(this.props.drive_time_low) +
      parseInt(this.props.avg_crew_turnout) +
      parseInt(this.props.avg_call_handling);

    console.log("resp_time_high");

    console.log(resp_time_high);
    console.log("resp_time_low");

    console.log(resp_time_low);

    let resp_time_max = 1852;
    let eng_resp_time_avg = 532;

    let EstRespRatio = (resp_time_low / resp_time_max) * 100;

    let avgRespRatio = (eng_resp_time_avg / resp_time_max) * 100;
    console.log("EstRespRatio");
    console.log(EstRespRatio);
    let picstyles = {
      position: "relative",
      left: EstRespRatio * 3.5 + "px",
      top: "10px",
    };
    let avgstyles = {
      position: "relative",
      left: avgRespRatio * 3.5 + "px",
      top: "10px",
    };
    // console.log(picstyles)

    // console.log(this.ConvertDateTime(1852));
    return (
      <div className="Fireresponse">
        <h3 className="Fireresponseheading">{this.props.title}</h3>
        <div className="Fireresponsecontent">
          <div>
            <div>
              <p>
                <b>Station Name: </b>
                {this.props.station_name}
              </p>
              <p>
                <b>Station Address:</b>
                {this.props.address}
              </p>
              <p>
                <b>Distance to Property: </b>
                {Math.round(this.props.distance_by_miles)} mile(s)
              </p>
            </div>
            <div className="firestationmap">
              <h4>Fire Station Location:</h4>
              <GoogleMapMini
                id={"mapDashboard" + String(this.props.id)}
                className="map"
                options={{
                  zoom: 15,
                  tilt: 0,
                  center: {
                    lat: this.props.stationlat,
                    lng: this.props.stationlon,
                  },
                  styles: styles.maps.mono,
                  disableDefaultUI: true,
                  zoomControl: true,
                  mapTypeControl: true,
                  fullscreenControl: true,
                }}
                apiKey="AIzaSyD9n6ddk1Z6E2L5hZqCVRo94I8lbHjm30Q"
                lat={this.props.stationlat}
                lon={this.props.stationlon}
              />
            </div>
          </div>
          <div className="fireriskright">
            <div>
              <div className="Responsetimeheading">
                <h4>Estimated Response Time</h4>
                <h2>{this.ConvertDateTime(resp_time_low)}</h2>
              </div>
              <div>
                <img
                  className="fireengineicon"
                  style={picstyles}
                  src={require("../../images/fireengine.png")}
                />
                <div>
                  <ProgressBar
                    id="firedataprogressbar"
                    variant="info"
                    now={EstRespRatio}
                    key={1}
                  />
                </div>
                <div>
                  <div className="engaverage" style={avgstyles}>
                    <span className="glyphicon glyphicon-triangle-top"></span>{" "}
                    <p>
                      England Average :{" "}
                      {this.ConvertDateTime(eng_resp_time_avg)}
                    </p>
                  </div>
                  <div className="engmaxmimum">
                    <span className="glyphicon glyphicon-triangle-top"></span>{" "}
                    <p>
                      England Maximum : {this.ConvertDateTime(resp_time_max)}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="firedetailsection">
              <div className="fireresponsedetail">
                <p>
                  <img src={require("../../images/idea.png")} />
                  The average call handling time in your area is{" "}
                  {this.ConvertDateTime(this.props.avg_call_handling)}, it takes{" "}
                  {this.ConvertDateTime(this.props.avg_crew_turnout)} on average
                  for crew turnout and the drive time is estimated to be between{" "}
                  {this.ConvertDateTime(this.props.drive_time_low)} and{" "}
                  {this.ConvertDateTime(this.props.drive_time_max)}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Displayfirereponse;
