import React from "react";
import { LabeledTextLine } from "../general/LabeledText";
import PropTypes from "prop-types";
import c from "./EPC.module.scss";

export const EPC = (props) => {
  const { info, recommendations } = props.data;

  const domesticRatingFn = (rating) => {
    let color = ["#FFFFFF", "X"];
    if (rating >= 92) {
      color = ["#0A8647", "A"];
    } else if (rating >= 81) {
      color = ["#2EA949", "B"];
    } else if (rating >= 69) {
      color = ["#95CA53", "C"];
    } else if (rating >= 55) {
      color = ["#F1EC37", "D"];
    } else if (rating >= 39) {
      color = ["#F5AD34", "E"];
    } else if (rating >= 21) {
      color = ["#EF6F2E", "F"];
    } else if (rating >= 1) {
      color = ["#E92730", "G"];
    }
    return color;
  };

  return (
    <div className={c.epc}>
      <div className={c.card}>
        <span className={c.title}>
          Energy Performance Rating | Domestic Property
        </span>
        <hr />
        <div className={c.content}>
          <LabeledTextRating
            label="Current Rating"
            rating={info.current_rating}
            ratingFn={domesticRatingFn}
          />
          <LabeledTextRating
            label="Potential Rating"
            rating={info.potential_rating}
            ratingFn={domesticRatingFn}
          />
        </div>
      </div>

      <div className={c.breakdowns}>
        <div className={c.breakdown}>
          <div className={c.outside}>
            <span className={c.title}>Performance Breakdown</span>
            <div className={c.label}>Yearly estimated consumption</div>
          </div>
          <div className={c.list}>
            <Consumption
              title={"Heating cost"}
              current={info.heating_cost_current}
              potential={info.heating_cost_potential}
              prefix={"£"}
            />
            <Consumption
              title={"Lighting cost"}
              current={info.lighting_cost_current}
              potential={info.lighting_cost_potential}
              prefix={"£"}
            />
            <Consumption
              title={"Hot water cost"}
              current={info.hot_water_cost_current}
              potential={info.hot_water_cost_potential}
              prefix={"£"}
            />
            <Consumption
              title={"CO2 emissions"}
              current={info.co2_emissions_current}
              potential={info.co2_emissions_potential}
              postfix={"ton"}
            />
            <Consumption
              title={"Energy consumption"}
              current={info.energy_consumption_current}
              potential={info.energy_consumption_potential}
              postfix={"kWh"}
            />
          </div>
        </div>
        <div className={c.breakdown}>
          <div className={c.outside}>
            <span className={c.title}>Recommendations</span>
            <div className={c.label}>Improvements ranked by impact</div>
          </div>
          <div className={c.list}>
            {recommendations.map((rec) => (
              <Recommendation
                action={rec.text}
                label={"Cost"}
                text={rec.cost}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const Recommendation = (props) => (
  <div className={c.recommendation}>
    <div className={c.left}>
      <span>{props.action}</span>
    </div>
    <div className={c.right}>
      {props.label ? (
        <LabeledTextLine label={props.label} rightAlign>
          <span className={c.text}>{props.text}</span>
        </LabeledTextLine>
      ) : (
        <span className={c.text}>{props.text}</span>
      )}
    </div>
  </div>
);

const Consumption = ({ current, potential, prefix, postfix, ...props }) => {
  let ratio;
  if (current > potential) {
    ratio = 1 - potential / current;
  } else {
    ratio = potential / current - 1;
  }
  const pct = Math.round(ratio * 100);
  const style = {
    backgroundColor: `rgba(254, 109, 89, ${Math.min(
      1.0,
      Math.max(0.0, ratio)
    )})`,
  };

  return (
    <div className={c.consumption}>
      <div className={c.left}>
        <span>{props.title}</span>
      </div>
      <div className={c.right}>
        {pct > 0 ? (
          <div className={c.percentage} style={style}>
            {"-" + pct + "%"}
          </div>
        ) : (
          <div />
        )}
        <div className={c.numbers}>
          <LabeledTextLine label="Current">
            <div className={c.number}>
              <span>{prefix}</span>
              <span>{current}</span>
              <span className={c.postfix}>{postfix}</span>
            </div>
          </LabeledTextLine>
          <div className={c.divider} />
          <LabeledTextLine label="Potential">
            <div className={c.number}>
              <span>{prefix}</span>
              <span>{potential}</span>
              <span className={c.postfix}>{postfix}</span>
            </div>
          </LabeledTextLine>
        </div>
      </div>
    </div>
  );
};

Consumption.defaultProps = {
  prefix: "",
  postfx: "",
};

const LabeledTextRating = ({ rating, ratingFn, ...other }) => {
  const [color, letter] = ratingFn(rating);
  const style = {
    backgroundColor: color,
  };
  return (
    <LabeledTextLine {...other}>
      <div className={c.rating}>
        <span className={c.number}>{rating}</span>
        <div className={c.letter} style={style}>
          {letter}
        </div>
      </div>
    </LabeledTextLine>
  );
};

export const CommercialEPC = (props) => {
  const { info, recommendations } = props.data;

  const commercialRatingFn = (rating) => {
    let color = ["#FFFFFF", "X"];
    if (rating > 150) {
      color = ["#FE0000", "G"];
    } else if (rating >= 126) {
      color = ["#FF6600", "F"];
    } else if (rating >= 101) {
      color = ["#FCA144", "E"];
    } else if (rating >= 76) {
      color = ["#FFCC00", "D"];
    } else if (rating >= 51) {
      color = ["#80C31C", "C"];
    } else if (rating >= 26) {
      color = ["#32A03D", "B"];
    } else if (rating >= 0) {
      color = ["#0A6925", "A"];
    }
    return color;
  };

  return (
    <div className={c.epc}>
      <div className={c.card}>
        <span className={c.title}>
          Energy Performance Rating | Commercial Property
        </span>
        <hr />
        <div className={c.content}>
          <LabeledTextRating
            label="Current Rating"
            rating={info.current_rating}
            ratingFn={commercialRatingFn}
          />
          <LabeledTextRating
            label="Rating if newly built"
            rating={info.built_newly_rating}
            ratingFn={commercialRatingFn}
          />
          <LabeledTextRating
            label="Typical of similar stock"
            rating={info.typical_rating}
            ratingFn={commercialRatingFn}
          />
        </div>
      </div>

      <div className={c.breakdowns}>
        <div className={c.breakdown}>
          <div className={c.outside}>
            <span className={c.title}>Property Information</span>
            <div className={c.label}>Commercial EPC information</div>
          </div>
          <div className={c.list}>
            <Recommendation
              action={"Property type"}
              text={info.property_type}
            />
            <Recommendation
              action={"Building environment"}
              text={info.building_environment}
            />
            <Recommendation
              action={"Main heating fuel"}
              text={info.main_heating_fuel}
            />
            <Recommendation
              action={"Building emissions"}
              text={info.co2_emissions}
            />
            <Recommendation
              action={"Total useful floor area"}
              text={info.floor_area}
            />
            <Recommendation
              action={"Building complexity (NOS level)"}
              text={info.building_complexity}
            />
          </div>
        </div>
        <div className={c.breakdown}>
          <div className={c.outside}>
            <span className={c.title}>Recommendations</span>
            <div className={c.label}>Improvements ranked by impact</div>
          </div>
          <div className={c.list}>
            {recommendations.map((rec) => (
              <Recommendation
                action={rec.text}
                label={"CO2 Impact"}
                text={rec.cost}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EPC;
