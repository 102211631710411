import React from 'react'
import Input from 'balkerne-components/Input'

const DateTimeWidget = ({ ...props }) => {
  const dateConverter = datevalue => {
    var date = new Date(datevalue)
    console.log(datevalue)
    date = date.toISOString()
    var tempdate = date.split('.')
    console.log(tempdate)
    props.onChange(date)
  }
  return (
    <Input
      name={props.name}
      id={props.id}
      placeholder={props.placeholder}
      type="datetime-local"
      onChange={event => dateConverter(event.target.value)}
    />
  )
}

export default DateTimeWidget
