import React from 'react'
import axios from 'axios'
import PropertySubHeader from '../components/PropertySubHeader'
import SideNav from '../components/riskReport/SideNav'
import Displayalerts from '../components/riskReport/displayalerts'
import Displayrisk from '../components/riskReport/displayrisk'
import Incident from '../components/riskReport/Incident'
import Displayfirerisk from '../components/riskReport/displayfirerisk'
import Mitigation from '../components/riskReport/Mitigation'
import PropertyHeader from '../components/HeaderProperty'
import Loader from '../components/spinner'
import recommendations_extract from '../tempjson/recommendations.json'
import firestats from '../tempjson/fire_stats.json'
import metaltheftrisk from '../tempjson/trinitas_data.json'
import mediacontent_extract from '../tempjson/media.json'
// import crime_data from "../tempjson/crime.json";
import airquality_match from '../tempjson/air_quality_site.json'
import Rivergaugechart from '../components/riskReport/Rivergaugechart'
import Displayfirereponse from '../components/riskReport/displafireresponse'
import Mediacontent from '../components/riskReport/mediacontent'
import './riskreport.css'
import * as urls from '../components/maps/urls'
import { Logout } from '../components/Logout'
import { connect } from 'react-redux'
import { systemReduxDashboardHandler } from '../redux/_helpers/systemReduxDashboardHandler.js'
import { systemReduxHandler } from '../redux/_helpers/systemReduxHandler.js'
import Displaycrimerisk from '../components/riskReport/displaycrimerisk'
import Displayhistoricalertsummary from '../components/riskReport/displayhistoricalertsummary'
import Weatherimport from '../components/riskReport/weatherimport'
import NewsCard from '../components/riskReport/NewsCard'
import DisplayMetalTheft from '../components/riskReport/displaymetaltheft'
import Air_Quality_Import from '../components/riskReport/air_quality_import'
import DisplayCovid from '../components/riskReport/displayCovid'
import PropertyDetails from '../components/riskReport/PropertyDetails'
import { EPC, CommercialEPC } from '../components/riskReport/EPC'
import { RefreshAuthTokens } from '../components/RefreshAuthTokens'
// import CovidTiers from "../components/riskReport/CovidTiers";
import FoodHygieneImport from '../components/riskReport/foodhygiene'
import Can from 'balkerne-components/Can'
import i from '../components/general/TemplateManager.module.scss'
import { useHistory } from 'react-router-dom'

import { getCrimeRisks } from '../redux/_helpers/getCrimeRisks.js'

class ErrorNotice extends React.Component {
  render() {
    return <div className="notice__error">{this.props.error}</div>
  }
}

class riskreport extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      gaugereadings: [],
      //get property location data from location dashboard
      currentmitigationList: this.props.dashboardData.data.mitigations,
      lossincidents: this.props.dashboardData.data.loss_incidents,

      geoFeature: this.props.dashboardData.data.location_data,
      locationData2: this.props.dashboardData.data.location_data,
      locationData:
        this.props.dashboardData.data.location_data !== null
          ? this.props.dashboardData.data.location_data.properties
          : null,

      currentalerts: this.props.dashboardData.data.live_alerts,
      historicalalerts: this.props.dashboardData.data.historical_alerts,
      historicalweatheralerts: this.props.dashboardData.data.historical_weather_alerts,
      epc: this.props.dashboardData.data.epc,
      activeweatherwarnings: null,
      flood_risks:
        this.props.dashboardData.data.flood_risks !== null
          ? this.props.dashboardData.data.flood_risks
          : {
              crime: 0,
              fire: 0,
              rofrs_note: '',
              rofrs_value: 0,
              rofsw_note: '',
              rofsw_value: 0,
              propid: this.props.dashboardData.data.id,
            },
      metal_theft_risks: this.props.dashboardData.data.crime_risks,
      id: this.props.dashboardData.data.id,
      country: this.props.dashboardData.data !== null ? this.props.dashboardData.data.country : null,
      isLoading: false,
      current_page: this.props.location.pathname || '',
      property_details: this.props.dashboardData.data.property_details,
      // changeMessage:"No Change",
      // currentcovidtiers: this.props.dashboardData.data.covid_tiers,
      powerDisruptionData: null,
      fireresponse: [],
      historicalWeatherLoaded: false,

      crime_data_month: null,

      filteredMedia: [],
      covidcases: [],
      covidcases_max: [],

      trackMenuClick: this.props.location.state.trackrisks || 2,
      errMessage: '',
      success: true,
    }
    this.updateLossIncidents = this.updateLossIncidents.bind(this)
    this.updateMitigations = this.updateMitigations.bind(this)
    this.fetchWeatherWarnings = this.fetchWeatherWarnings.bind(this)
    this.fetchFireResponseData = this.fetchFireResponseData.bind(this)
    this.fetchGaugeData = this.fetchGaugeData.bind(this)
    this.fetchNewsData = this.fetchNewsData.bind(this)
    this.fetchCovidUpdate = this.fetchCovidUpdate.bind(this)
    // this.updateCovidTiers = this.updateCovidTiers.bind(this);
    this.updateHistoricWeatherAlerts = this.updateHistoricWeatherAlerts.bind(this)
    this.handleAlertClicked = this.handleAlertClicked.bind(this)

    this.removeMediaItem = this.removeMediaItem.bind(this)
    this.deleteMitigationItem = this.deleteMitigationItem.bind(this)
    this.deleteLossIncidentItem = this.deleteLossIncidentItem.bind(this)

    this.handleReportComponents()
  }

  async updateLossIncidents() {
    await systemReduxDashboardHandler.resetLossIncidents(
      this.props,
      this.state.id,
      this.props.userData.data.id,
      this.props.user.user.signInUserSession.idToken.jwtToken,
    )
    // await systemReduxDashboardHandler.updateLossIncidents(this.props,this.state.id,user_id,true);
    if (this.props.dashboardData.data.loss_incidents !== null) {
      let loss_incidents = this.props.dashboardData.data.loss_incidents
      this.setState({ lossincidents: loss_incidents })
    }
  }

  async updateMitigations() {
    await systemReduxDashboardHandler.resetMitigations(
      this.props,
      this.state.id,
      this.props.user.user.signInUserSession.idToken.jwtToken,
    )
    if (this.props.dashboardData.data.mitigations !== null) {
      let mitigations = this.props.dashboardData.data.mitigations
      this.setState({ currentmitigationList: mitigations })
    }
  }

  async updateHistoricWeatherAlerts() {
    console.log('refreshing weather hist alerts0')
    await systemReduxDashboardHandler.updateHistoricalWeatherAlerts(
      this.props,
      this.state.id,
      this.props.user.user.signInUserSession.idToken.jwtToken,
    )
    console.log('refreshing weather hist alerts1')
    this.setState({
      historicalweatheralerts: this.props.dashboardData.data.historical_weather_alerts,
      historicalWeatherLoaded: true,
    })
  }

  async componentDidMount() {
    this.setState({ isLoading: true })

    await RefreshAuthTokens(this.props)

    await systemReduxDashboardHandler.updateLocationData(
      this.props,
      this.state.id,
      this.props.userData.data.orgid,
      this.props.user.user.signInUserSession.idToken.jwtToken,
    )

    //GET CRIME DATA

    if (this.state.id != null) {
      let crime_data_month = await getCrimeRisks(this.state.id, null)
      this.setState({ crime_data_month })
    }

    await systemReduxDashboardHandler.updateLiveAlerts(
      this.props,
      this.state.id,
      this.state.country,
      this.props.user.user.signInUserSession.idToken.jwtToken,
    )

    await systemReduxDashboardHandler.updateHistoricalAlerts(
      this.props,
      this.state.id,
      this.state.country,
      this.props.user.user.signInUserSession.idToken.jwtToken,
    )

    //updates historical weather alerts if loaded straight from property dashboard
    if (
      this.props.location.state &&
      this.props.location.state.trackrisks &&
      this.props.location.state.trackrisks === 1
    ) {
      this.updateHistoricWeatherAlerts()
    }

    await systemReduxDashboardHandler.updatePropertyDetails(
      this.props,
      this.state.id,
      this.props.user.user.signInUserSession.idToken.jwtToken,
    )

    await systemReduxDashboardHandler.updateFloodRisks(
      this.props,
      this.state.id,
      this.props.user.user.signInUserSession.idToken.jwtToken,
    )

    await systemReduxDashboardHandler.updateCrimeRisks(
      this.props,
      this.state.id,
      this.props.user.user.signInUserSession.idToken.jwtToken,
    )

    await systemReduxDashboardHandler.updateMitigations(
      this.props,
      this.state.id,
      this.props.user.user.signInUserSession.idToken.jwtToken,
    )

    await systemReduxDashboardHandler.updateLossIncidents(
      this.props,
      this.state.id,
      this.props.userData.data.id,
      this.props.user.user.signInUserSession.idToken.jwtToken,
    )

    await systemReduxDashboardHandler.updateEPC(
      this.props,
      this.state.id,
      this.props.user.user.signInUserSession.idToken.jwtToken,
    )

    //removed power disruption
    // await this.getIbmPowerDisruptions();

    //Weather warnings are obtained here

    await systemReduxHandler.updateWeatherWarnings(this.props, this.props.user.user.signInUserSession.idToken.jwtToken)

    if (this.props.dashboardData.data.flood_risks !== null) {
      this.state.flood_risks = this.props.dashboardData.data.flood_risks
    } else if (this.state.success === true && this.props.dashboardData.data.flood_risks === null) {
      console.log('Error when loading flood_risks: ')
      //  Alert.alert('Error when signing in: ', err.message);
      this.setState({ success: false, errMessage: 'Failed to reach server.' })
    }

    this.fetchWeatherWarnings()
    this.fetchGaugeData()
    this.fetchNewsData()
    //Waits until covid data is loaded
    await this.fetchCovidUpdate()
    //Waits until fire data is loaded
    await this.fetchFireResponseData()

    console.log('MOUNTED')
    this.setState({ isLoading: false })
  }

  async fetchWeatherWarnings() {
    let weatherwarnings = this.props.systemData.data.weather_warnings

    if (weatherwarnings) {
      console.log('WEATHERWARNINGS TRUE')
      let filteredwarnings = weatherwarnings.filter(warning => this.checkWarningRegion(warning.summary))
      this.state.activeweatherwarnings = filteredwarnings
    } else if (this.state.success === true && this.props.systemData.data.weather_warnings === null) {
      console.log('Error when loading weather_warnings: ')
      //  Alert.alert('Error when signing in: ', err.message);
      this.setState({ success: false, errMessage: 'Failed to reach server.' })
    }
  }

  // async getIbmPowerDisruptions(){
  //   if(this.state.locationData){
  //   let lat= this.state.locationData.lat
  //   let lon =this.state.locationData.lon

  //   if(lat && lon && lat > 0){

  // await fetch("https://api.weather.com/v2/indices/powerDisruption/daypart/15day?geocode="+lat+","+lon+"&language=en-US&format=json&apiKey=2d3c6f9cb2a94f67bc6f9cb2a98f678a")
  //       .then(response => response.json())
  //       .then(data => this.setState({ powerDisruptionData: data }));

  //   }

  // }

  // }

  async fetchCovidUpdate() {
    if (this.state.locationData) {
      let covid_query = [`?propid=${this.state.locationData.id}`]

      let covid_url = urls.API_GATEWAY + ['covid', 'cases' + covid_query].join('/')

      await axios
        .get(covid_url)
        .then(res => {
          const temp_covid_data = res.data
          console.log('COVID Cases Data Output')
          console.log(temp_covid_data)
          this.state.covidcases = temp_covid_data
        })
        .catch(err => {
          // console.log(this.state.temp_news_data)
          if (this.state.success === true) {
            console.log('Error when loading max covid data: ')
            this.setState({
              success: false,
              errMessage: 'Failed to reach server.',
            })
          }
          console.log('FAILURE for max covid data!!')
          console.log(err)
        })

      let covid_max_url = urls.API_GATEWAY + ['covid', 'cases-per-100k' + covid_query].join('/')
      console.log(covid_max_url)

      await axios
        .get(covid_max_url)
        .then(res => {
          console.log('GOOD FETCHING 100k')
          const temp_covid_max = res.data
          console.log('COVID Cases Max Data Output')
          console.log(temp_covid_max)
          this.state.covidcases_max = temp_covid_max
        })
        .catch(err => {
          console.log('ERROR FETCHING 100k')
          // console.log(this.state.temp_news_data)
          if (this.state.success === true) {
            console.log('Error when loading covid max data list: ')
            this.setState({
              success: false,
              errMessage: 'Failed to reach server.',
            })
          }
          console.log('FAILURE for temp covid data!!')
          console.log(err)
        })
    } else {
      this.state.covidcases = null
      if (this.state.success === true) {
        console.log('Error when location data missing: ')
        //  Alert.alert('Error when signing in: ', err.message);
        this.setState({
          success: false,
          errMessage: 'Failed to reach server.',
        })
      }
    }
  }

  async fetchCovidUpdate() {
    if (this.state.locationData) {
      let params = { propid: this.state.locationData.id }

      await axios({
        method: 'get',
        url: urls.API_GATEWAY + 'covid/cases',
        params: params,
      })
        .then(res => {
          let temp_covid_data = res.data
          console.log(temp_covid_data)
          console.log('Fire COVID Cases Data Output')

          if (temp_covid_data === 'null' || temp_covid_data === null || typeof temp_covid_data === 'undefined') {
            temp_covid_data = null
            console.log('Error retrieving covid data')
          } else if (temp_covid_data === 'Invalid Details') {
            console.log('Details for covid data are INVALID')
            temp_covid_data = null
          } else if (temp_covid_data === 'Covid results not found') {
            console.log('No covid data found for the property')
            temp_covid_data = null
          } else {
            console.log('SUCCESS!!')
            console.log('Covid retrieved from the database.')
          }

          this.setState({ covidcases: temp_covid_data })
        })
        .catch(err => {
          // console.log(this.state.temp_news_data)
          if (this.state.success === true) {
            console.log('Error when loading temp_covid_data list: ')
            this.setState({
              success: false,
              errMessage: 'Failed to reach server.',
            })
          }
          console.log('FAILURE for temp covid data!!')
          console.log(err)
        })

      await axios({
        method: 'get',
        url: urls.API_GATEWAY + 'covid/cases-per-100k',
        params: params,
      })
        .then(res => {
          const temp_covid_max = res.data
          console.log('COVID Cases Max Data Output')
          console.log(temp_covid_max)

          if (temp_covid_max === 'null' || temp_covid_max === null || typeof temp_covid_max === 'undefined') {
            temp_covid_max = null
            console.log('Error retrieving covid data')
          } else if (temp_covid_max === 'Invalid Details') {
            console.log('Details for covid data are INVALID')
            temp_covid_max = null
          } else if (temp_covid_max === 'Covid results not found') {
            console.log('No covid data found for the property')
            temp_covid_max = null
          } else {
            console.log('SUCCESS!!')
            console.log('Covid retrieved from the database.')
          }
          this.setState({ covidcases_max: temp_covid_max })
        })
        .catch(err => {
          // console.log(this.state.temp_news_data)
          if (this.state.success === true) {
            console.log('Error when loading temp_covid_data list: ')
            this.setState({
              success: false,
              errMessage: 'Failed to reach server.',
            })
          }
          console.log('FAILURE for temp covid data!!')
          console.log(err)
        })
    } else {
      this.setState({ covidcases: null })
      if (this.state.success === true) {
        console.log('Error when location data missing: ')
        //  Alert.alert('Error when signing in: ', err.message);
        this.setState({
          success: false,
          errMessage: 'Failed to reach server.',
        })
      }
    }
  }

  // checks any text if it contains county information
  checkWarningRegion(warning) {
    //if warning contains location county/ region, return true
    if (this.state.locationData && warning.includes(this.state.locationData.address_county)) return true
    else return false
  }

  async fetchGaugeData() {
    //Gauge data is here
    //console.log(this.locationData)
    if (this.state.locationData) {
      let temp_gaugereadings = null
      console.log(this.state.locationData)
      let params = { propid: this.state.locationData.id }

      await axios({
        method: 'get',
        url: urls.API_GATEWAY + 'flood/gauges',
        params: params,
      })
        .then(res => {
          temp_gaugereadings = res.data
          console.log(temp_gaugereadings)

          if (
            temp_gaugereadings === 'null' ||
            temp_gaugereadings === null ||
            temp_gaugereadings === 'Failure' ||
            typeof temp_gaugereadings === 'undefined'
          ) {
            temp_gaugereadings = null
            console.log('Error retrieving Latest river gauge readings')
          } else if (temp_gaugereadings === 'Invalid Details') {
            console.log('Details for Latest river gauge readings are INVALID')
            temp_gaugereadings = null
          } else if (temp_gaugereadings === 'No stations found' || temp_gaugereadings === 'No river gauge found') {
            console.log('No Latest river gauge data or stations found found for the property')
            temp_gaugereadings = null
          } else {
            console.log('SUCCESS!!')

            console.log('Latest river gauge readings retrieved from the database.')
          }
          // console.log(gaugereadings)
          this.setState({ gaugereadings: temp_gaugereadings })
          //this.state.gaugereadings = temp_gaugereadings
          //this.setState({ gaugereadings: temp_gaugereadings });
        })
        .catch(err => {
          // console.log(temp_gaugereadings)
          if (this.state.success === true) {
            console.log('Error when loading gauge readings list: ')
            //  Alert.alert('Error when signing in: ', err.message);
            this.setState({
              success: false,
              errMessage: 'Failed to reach server.',
            })
          }
          console.log('FAILURE for gauges!!')
          console.log(err)
        })
    } else {
      this.state.gaugereadings = null
      if (this.state.success === true) {
        console.log('Error when location data missing: ')
        //  Alert.alert('Error when signing in: ', err.message);
        this.setState({
          success: false,
          errMessage: 'Failed to reach server. (location data)',
        })
      }
    }
  }

  async fetchFireResponseData() {
    let fireresponse = null

    if (this.state.locationData) {
      let params = { propid: this.state.locationData.id }

      await axios({
        method: 'get',
        url: urls.API_GATEWAY + 'fire/response',
        params: params,
      })
        .then(res => {
          fireresponse = res.data
          console.log(fireresponse)

          if (fireresponse === 'null' || fireresponse === null || typeof fireresponse === 'undefined') {
            fireresponse = null
            console.log('Error retrieving fire data')
          } else if (fireresponse === 'Invalid Details') {
            console.log('Details for fire data are INVALID')
            fireresponse = null
          } else if (fireresponse === 'No fire data found') {
            console.log('No fire data found for the property')
            fireresponse = null
          } else {
            console.log('SUCCESS!!')
            console.log('Fire Response Data retrieved from the database.')
          }

          this.setState({ fireresponse })
        })
        .catch(err => {
          // console.log(this.state.temp_news_data)
          if (this.state.success === true) {
            console.log('Error when loading temp_fire_data list: ')
            //  Alert.alert('Error when signing in: ', err.message);
            this.setState({
              success: false,
              errMessage: 'Failed to reach server.',
            })
          }
          console.log('FAILURE for temp fire!!')
          console.log(err)
        })
    } else {
      fireresponse = null
      this.setState({ fireresponse })

      if (this.state.success === true) {
        console.log('Error when location data missing: ')
        //  Alert.alert('Error when signing in: ', err.message);
        this.setState({
          success: false,
          errMessage: 'Failed to reach server.',
        })
      }
    }
  }

  async fetchNewsData() {
    if (this.state.locationData) {
      let data = {
        propid: this.state.locationData.id,
        keywords: this.props.userData.data.keywords,
      }

      await axios({
        method: 'post',
        url: urls.API_GATEWAY + 'news/news-by-property',
        data: data,
      })
        .then(res => {
          let temp_news_data = res.data
          console.log(temp_news_data)

          if (temp_news_data === 'null' || temp_news_data === null || typeof temp_news_data === 'undefined') {
            console.log('Error retrieving news')
            temp_news_data = null
          } else if (temp_news_data === 'Invalid Details') {
            console.log('Details for news retrieval are INVALID')
            temp_news_data = null
          } else if (temp_news_data === 'No news found') {
            console.log('No news found for news retrieval')
            temp_news_data = null
          } else {
            console.log('SUCCESS!!')
            console.log('news Data retrieved from the database.')
          }

          this.setState({ filteredMedia: temp_news_data })
        })
        .catch(err => {
          // console.log(temp_news_data)
          if (this.state.success === true) {
            console.log('Error when loading temp_news_data list: ')
            //  Alert.alert('Error when signing in: ', err.message);
            this.setState({
              success: false,
              errMessage: 'Failed to reach server.',
            })
          }
          console.log('FAILURE for temp news!!')
          console.log(err)
        })
    } else {
      this.state.filteredMedia = null
      if (this.state.success === true) {
        console.log('Error when location data missing: ')
        //  Alert.alert('Error when signing in: ', err.message);
        this.setState({
          success: false,
          errMessage: 'Failed to reach server.',
        })
      }
    }
  }

  async removeMediaItem(newsid) {
    if (newsid !== null && this.state.filteredMedia) {
      let newmedia = this.state.filteredMedia.filter(mediaitem => mediaitem.id !== newsid)

      // console.log(newmedia)
      this.setState({ filteredMedia: newmedia })
      // console.log(this.state.changeMessage)

      let data = { newsid: newsid }

      await axios({
        method: 'post',
        url: urls.API_GATEWAY + 'news/exclude',
        data: data,
      })
        .then(res => {
          let temp_news_data = res.data
          console.log(temp_news_data)

          if (
            temp_news_data === 'null' ||
            temp_news_data === null ||
            temp_news_data === 'Failure' ||
            typeof temp_news_data === 'undefined'
          ) {
            console.log('Error deleting news')
            temp_news_data = null
          } else if (temp_news_data === 'No news found') {
            console.log('No news found for deletion')
            temp_news_data = null
          } else {
            console.log('SUCCESS!!')
            console.log('news Data deleted successfully.')
          }
        })
        .catch(err => {
          //console.log(this.state.temp_news_data)
          if (this.state.success === true) {
            console.log('Error when exluding news list: ')
            //  Alert.alert('Error when signing in: ', err.message);
            //this.setState({success:false, errMessage:"Failed to reach server."})
          }

          console.log(err)
        })
    }
  }

  async deleteMitigationItem(mitigation_id) {
    console.log('Mitigation Removed Sucessfully:' + mitigation_id)

    if (mitigation_id !== null) {
      let updatedMitigations = this.state.currentmitigationList.filter(mitigation => mitigation.id !== mitigation_id)

      this.setState({ currentmitigationList: updatedMitigations })

      let data = { mitigation_id: mitigation_id }

      await axios({
        method: 'post',
        url: urls.API_GATEWAY + 'mitigations/delete-mitigation',
        data: data,
      })
        .then(res => {
          let result = res.data
          console.log(result)

          if (result === 'null' || result === null || result === 'Failure' || typeof result === 'undefined') {
            result = null
            console.log('Error deleting mitigation document')
          } else if (result === 'Invalid Details') {
            console.log('Details for deleting mitigation are INVALID')
            result = null
          } else if (result === 'Not found') {
            console.log('Mitigation  for deletion was not found')
            result = null
          } else if (result === 'deleted') {
            console.log('SUCCESS!!')
            console.log('Mitigation successfully deleted')
          } else {
            console.log('Unknown response')
          }
        })
        .catch(err => {
          console.log(err)
          console.log('mitigation deletion error')
        })

      await systemReduxDashboardHandler.resetMitigations(
        this.props,
        this.state.id,
        this.props.user.user.signInUserSession.idToken.jwtToken,
      )
    }
  }

  async deleteLossIncidentItem(lossincident_id) {
    console.log('Loss Incident Removed Sucessfully:' + lossincident_id)
    let user_id = ''
    if (lossincident_id !== null) {
      if (this.props.userData.data.id) {
        user_id = this.props.userData.data.id
      } else {
        user_id = this.props.userData.data.aws_id
      }

      let updatedLossIncidents = this.state.lossincidents.filter(lossincident => lossincident.id !== lossincident_id)

      this.setState({ lossincidents: updatedLossIncidents })

      let data = { lossincident_id: lossincident_id }

      await axios({
        method: 'post',
        url: urls.API_GATEWAY + 'loss-incidents/delete-incident',
        data: data,
      })
        .then(res => {
          let result = res.data
          console.log(result)

          if (result === 'null' || result === null || result === 'Failure' || typeof result === 'undefined') {
            result = null
            console.log('Error deleting mitLoss-incidentigation document')
          } else if (result === 'Invalid Details') {
            console.log('Details for deleting Loss-incident are INVALID')
            result = null
          } else if (result === 'Not found') {
            console.log('Loss-incident  for deletion was not found')
            result = null
          } else if (result === 'deleted') {
            console.log('SUCCESS!!')
            console.log('Loss-incident successfully deleted')
          } else {
            console.log('Unknown response')
          }
        })
        .catch(err => {
          console.log(err)
          console.log('loss incident deletion error')
        })

      await systemReduxDashboardHandler.resetLossIncidents(
        this.props,
        this.state.id,
        user_id,
        this.props.user.user.signInUserSession.idToken.jwtToken,
      )
    }
  }
  //Sets the current state to 1 so that relevant alert information can be shown
  async handleAlertClicked() {
    //updates historical weather alerts if clicked in menu
    this.updateHistoricWeatherAlerts()
    this.setState({
      trackMenuClick: 1, // 1 is for alerts
    })
  }
  //Sets the current state to 2 so that relevant risk insights information can be shown
  handleInsightsClicked = () => {
    this.setState({
      trackMenuClick: 2, // 2 is for Risk Insights
    })
  }

  //Sets the current state to 3 so that relevant mitigation information can be shown

  handleMitigationsClicked = () => {
    this.setState({
      trackMenuClick: 3, // 3 is for Risk Mitigations
    })
  }
  //Sets the current state to 4 so that relevant Smart Integration information can be shown

  handleSmartIntegrationClicked = () => {
    this.setState({
      trackMenuClick: 4, // 4 is for Smart Integrations
    })
  }

  handleIncidentsClicked = () => {
    this.setState({
      trackMenuClick: 5, // 4 is for Smart Integrations
    })
  }

  handleMediaClicked = () => {
    this.setState({
      trackMenuClick: 6, // 5 is for News and Social Media
    })
  }

  //This function handles the clicks from Static Side Nav
  handleReportComponents = () => {
    switch (this.state.trackMenuClick) {
      case 1:
        return this.showAllAlerts()
        break

      case 2:
        return this.showRiskInsights()
        break

      case 3:
        return (
          <Can
            permissions={this.props.userData.data.permissions}
            actions="mitigations:read"
            yes={() => this.showMitigations()}
          />
        )

        //return this.showMitigations();
        break

      case 4:
        return this.showSmartIntegration()
        break
      case 5:
        return (
          <Can
            permissions={this.props.userData.data.permissions}
            actions="loss-incidents:read"
            yes={() => this.showIncidentHistory()}
          />
        )

        //return this.showIncidentHistory();
        break
      case 6:
        return this.showAdvancedMediaContent()
        break
    }
  }

  //  Functions related to display of alerts
  //Gets the alert title based on the severity level
  getAlertTitle(severity_level) {
    switch (severity_level) {
      case 1:
        return 'Severe Warning'

      case 2:
        return 'Warning'

      case 3:
        return 'Alert'

      case 4:
        return 'No Longer In Force'
    }
    return 'Alert'
  }
  //Gets the alert image based on the severity level
  getAlertImage(severity_level) {
    // console.log("severeity_level")
    // console.log(severity_level)

    switch (severity_level) {
      case 'Flood_1':
        return require('../images/Severe_Flood_Warning_small.png')

      case 'Flood_2':
        return require('../images/Flood_Warning_small.png')

      case 'Flood_3':
        return require('../images/Flood_Alert_small.png')
      case 'Arson_3':
      case 'Metal Theft_3':
      case 'Armed Robbery_3':
      case 8:
      case 9:
        return require('../images/crime_alert_small.png')
      case 'Rainfall_3':
        return require('../images/rainfall_alert.png')
      case 'Wind_3':
      case 'High Wind_3':
        return require('../images/wind_alert.png')

      case 'High Temperature_3':
        return require('../images/temp_alert.png')

      case 'YELLOW':
        return require('../images/yellowwarning.png')
      case 'AMBER':
        return require('../images/amberwarning.png')
      case 'RED':
        return require('../images/redwarning.png')
      case 'YELLOW':
        return require('../images/yellowwarning.png')
      case 'AMBER':
        return require('../images/amberwarning.png')
      case 'RED':
        return require('../images/redwarning.png')

      case 'Pandemic_3':
        return require('../images/pandemic_alert.png')
    }
    return '0'
  }

  //Gets the alert reccommendatiosn based on the severity level

  getCurrentRecommendations(severity_level) {
    switch (severity_level) {
      case 'Flood_1':
        return recommendations_extract.severewarningrecommendation

      case 'Flood_2':
        return recommendations_extract.warningrecommendation

      case 'Flood_3':
        return recommendations_extract.alertrecommendation
      case 'Arson_3':
        return recommendations_extract.arson_building

      case 'Metal Theft_3':
        return recommendations_extract.metaltheftguidance

      case 'Armed Robbery_3':
        return recommendations_extract.armedrobbery2

      case 8:
      case 9:
      case 'Wind_3':
      case 'High Wind_3':
      case 'WIND':
        return recommendations_extract.yellow_warning_wind
      case 'Rainfall_3':
      case 'THUNDERSTORM':
      case 'Heavy Rainfall_3':
      case 'RAIN':
        return recommendations_extract.yellow_warning_rain

      case 'FOG':
        return recommendations_extract.yellow_warning_fog
    }
    return recommendations_extract.norecommendation
  }

  showAllAlerts = () => {
    let ActiveWeatherAlerts = []
    if (this.state.locationData) {
      if (this.state.activeweatherwarnings && this.state.activeweatherwarnings.length)
        ActiveWeatherAlerts = this.state.activeweatherwarnings

      let filtereddemoalerts = []

      // filtereddemoalerts = tempalerts.alertlist.filter(
      //   alert =>
      //     alert.propid === this.state.locationData.id).map(({ title, message, severityLevel, timeRaised, type }) => ({ title, message, severityLevel, timeRaised, type }));

      let LiveAlerts = []
      if (this.state.currentalerts && this.state.currentalerts.length) LiveAlerts = this.state.currentalerts

      let historicalalertsvailable = false
      let HistoricalAlerts = []
      // if (this.state.historicalalerts && this.state.historicalalerts.length) {
      //   HistoricalAlerts = this.state.historicalalerts;
      //   historicalalertsvailable = true;
      // }

      let HistoricalWeatherAlerts = []
      if (this.state.historicalweatheralerts && this.state.historicalweatheralerts.length) {
        HistoricalWeatherAlerts = this.state.historicalweatheralerts
      }
      console.log('starting historical alerts')
      console.log(this.state.historicalalerts)
      console.log(this.state.historicalWeatherLoaded)
      return (
        <div>
          {ActiveWeatherAlerts.map((warnings, index) => (
            <Displayalerts
              key={index}
              activestate={true}
              timeraised={warnings.time_raised}
              title={warnings.title}
              imagepath={this.getAlertImage(warnings.warning_level)}
              text={warnings.summary}
              recommendations={this.getCurrentRecommendations(warnings.warning_type)}
            />
          ))}

          {LiveAlerts.map(alertdetails => (
            <Displayalerts
              activestate={alertdetails.isActive}
              timeraised={alertdetails.timeRaised}
              title={
                (alertdetails.type || 'Flood') +
                ' ' +
                this.getAlertTitle(parseInt(alertdetails.severityLevel)) +
                ': ' +
                alertdetails.description
              }
              imagepath={this.getAlertImage((alertdetails.type || 'Flood') + '_' + alertdetails.severityLevel)}
              text={alertdetails.message}
              recommendations={this.getCurrentRecommendations(
                (alertdetails.type || 'Flood') + '_' + alertdetails.severityLevel,
              )}
            />
          ))}

          {(this.state.historicalalerts && this.state.historicalalerts.length) || this.state.historicalWeatherLoaded ? (
            <Displayhistoricalertsummary
              HistoricalAlerts={HistoricalAlerts}
              HistoricalWeatherAlerts={HistoricalWeatherAlerts}
            />
          ) : (
            <Loader color="#0652dd" />
          )}
        </div>
      )
    } else {
      return ''
    }
  }

  getFloodRiskText(risk_rating) {
    switch (risk_rating) {
      case 1:
        return 'Very Low'
      case 2:
        return 'Low'
      case 3:
        return 'Medium'
      case 4:
        return 'High'
      default:
        return 'Negligible'
    }
  }

  getChurchRiskText() {
    switch (this.state.metal_theft_risks.risk_level) {
      case 1:
        return 'Low Risk'
      case 2:
        return 'Medium'
      case 3:
        return 'High Risk'
      case 4:
        return 'Very High'
      default:
        return 'Negligible'
    }
  }

  showRiskInsights() {
    let covidcases = this.state.covidcases
    let prop_cases_per_100k = this.state.covidcases_max.prop_cases_per_100k
    let max_cases_per_100k = this.state.covidcases_max.max_cases_per_100k
    console.log('PROP 100k ', prop_cases_per_100k)
    console.log('MAX 100k ', max_cases_per_100k)
    let country = this.state.locationData.country

    console.log('prop_cases_per_100k')
    console.log(prop_cases_per_100k)

    let maxdaterange = 0
    if (covidcases) {
      maxdaterange = Object(covidcases.daily_lab_confirmed_cases).length
    }
    console.log('maxdaterange')
    console.log(maxdaterange)

    let firestatistics = null

    if (this.state.locationData && this.state.locationData.lsoa) {
      firestatistics = firestats
        .filter(firestat => firestat.lsoa_code === this.state.locationData.lsoa)
        .map(
          ({
            lsoa_code,
            false_alarm_apparatus,
            primary_fire_dwelling,
            secondary_fire_grassland_woodland,
            secondary_fire_refuse_containers,
          }) => ({
            lsoa_code,
            false_alarm_apparatus,
            primary_fire_dwelling,
            secondary_fire_grassland_woodland,
            secondary_fire_refuse_containers,
          }),
        )
    }

    let fireriskavailable = false
    if (firestatistics && firestatistics.length) {
      fireriskavailable = true
    }

    let propertyfiredata = null
    if (this.state.fireresponse) {
      propertyfiredata = Object.values(this.state.fireresponse)
    }

    let propertyfiredataavailable = false
    if (propertyfiredata && propertyfiredata.length) {
      propertyfiredataavailable = true
    }

    let flood_risks = []

    flood_risks = this.state.flood_risks
    let churchmetaltheftid = null
    if (metaltheftrisk && this.state.locationData) {
      churchmetaltheftid = Object.keys(metaltheftrisk).filter(id => parseInt(id) === this.state.locationData.id)
    }

    let metaltheftdata = metaltheftrisk[churchmetaltheftid]
    if (!this.state.locationData) {
      return ''
    }

    const details = this.state.property_details

    return (
      <div className="riskReportContainer">
        {this.state.epc ? (
          this.state.epc.info.isDomestic ? (
            <EPC data={this.state.epc} />
          ) : (
            <CommercialEPC data={this.state.epc} />
          )
        ) : null}

        {details ? (
          <PropertyDetails
            floorArea={details ? details.floor_area : null}
            roofDesc={details ? details.roof_description : null}
            propertyType={details ? details.property_type : null}
            inspectionDate={details ? details.inspection_date : null}
            floorLevel={details ? details.floor_level : null}
          />
        ) : (
          ''
        )}

        {
          //if user has subscribed for flooding data, show flood risk
          this.props.userData.data.organisation.flooding == 'True' ? (
            <Displayrisk
              title="Rivers and Seas Flood Risk"
              riskratingdescription={this.getFloodRiskText(flood_risks.rofrs_value || 0)}
              riskrating={flood_risks.rofrs_value || 0}
              max_score={6}
              geoFeature={this.state.geoFeature}
              p={flood_risks.rofrs_note || 'No Further Detail'}
              source="Environment Agency"
            />
          ) : (
            ''
          )
        }

        {
          //if user has subscribed for flooding data, show flood risk
          this.props.userData.data.organisation.flooding == 'True' ? (
            <Displayrisk
              title="Surface Water Risk"
              riskratingdescription={this.getFloodRiskText(flood_risks.rofsw_value || 0)}
              riskrating={flood_risks.rofsw_value || 0}
              geoFeature={this.state.geoFeature}
              max_score={6}
              p={flood_risks.rofsw_note || 'No Further Detail'}
              source="Environment Agency"
            />
          ) : (
            ''
          )
        }

        {
          //if user has subscribed for COVID data, show pandemic risk

          this.props.userData.data.organisation.pandemic == 'True' && maxdaterange ? (
            <DisplayCovid
              title={'COVID-19 Update'}
              area={covidcases.area_name}
              riskrating={prop_cases_per_100k}
              max_score={max_cases_per_100k}
              historic_data={covidcases.daily_lab_confirmed_cases}
              timeUpdated={covidcases.daily_lab_confirmed_cases[maxdaterange - 1].date}
              p={
                'Cases per 100,000 population in the last 7 day period. Highest weekly cases of in England & Wales currently are :' +
                max_cases_per_100k +
                'cases per 100,000 population.Data for last 5 days may be incomplete.'
              }
            />
          ) : (
            ''
          )
        }

        {
          //if user has subscribed for food hygiene data, show foodhygiene risk
          this.props.userData.data.organisation.food_hygiene == 'True' ? (
            <FoodHygieneImport
              postcode={this.state.locationData.address_postcode}
              address={this.state.locationData.address_street}
              addname={this.state.locationData.name}
              addlat={this.state.locationData.lat}
              addlon={this.state.locationData.lon}
              foodratingdescription="The food hygiene rating reflects the standards of food hygiene found on the date of the inspection by the local authority. The food hygiene rating is not a guide to food quality."
            />
          ) : (
            ''
          )
        }

        {this.state.crime_data_month && this.props.userData.data.organisation.crime == 'True' ? (
          <Displaycrimerisk crime_data_prop={this.state.crime_data_month} orgid={this.props.userData.data.orgid} />
        ) : (
          ''
        )}
        {fireriskavailable && this.props.userData.data.organisation.fire == 'True' ? (
          <Displayfirerisk crime_data_prop={firestatistics[0]} orgid={this.props.userData.data.orgid} />
        ) : (
          ''
        )}
        {propertyfiredataavailable && this.props.userData.data.organisation.fire == 'True'
          ? propertyfiredata.map((firestationdata1, index) => (
              <Displayfirereponse
                id={index + 10}
                title={'Fire Station Response'}
                stationlat={firestationdata1.station_lat}
                stationlon={firestationdata1.station_lon}
                station_name={firestationdata1.station_name}
                address={firestationdata1.station_address}
                distance_by_miles={firestationdata1.miles_to_firestation}
                drive_time_max={firestationdata1.drive_time_max}
                drive_time_low={firestationdata1.drive_time_low}
                avg_crew_turnout={firestationdata1.crew_turnout}
                avg_call_handling={firestationdata1.call_handling}
              />
            ))
          : ''}

        {churchmetaltheftid != 0 && this.props.userData.data.organisation.metal_theft == 'True' ? (
          <DisplayMetalTheft Metaltheftdata={metaltheftdata} />
        ) : (
          ''
        )}
      </div>
    )
  }

  getMediaLogo(source_name) {
    switch (source_name) {
      case 1:
        return require('../images/twitter_icon.png')
      case 6:
        return require('../images/youtube_logo.png')
      case null:
        return require('../images/twitter_icon.png')
      default:
        return require('../images/balkerne_logo.png')
    }
  }

  showAdvancedMediaContent = () => {
    let media = this.state.filteredMedia
    if (this.state.locationData && media) {
      let demoMedia = mediacontent_extract.mediacontent
        .filter(item => item.store_number == this.state.locationData.property_customer_reference)
        .map(({ id, source, handle, description, link, date, logo_name }) => ({
          id,
          source,
          handle,
          description,
          link,
          date,
          logo_name,
        }))

      return (
        <div>
          {media.length != 0 ? (
            media.map((mediaitem, index) => (
              <NewsCard
                key={index}
                id={this.props.dashboardData.data.id}
                title={mediaitem.title}
                snippet={mediaitem.new_summary}
                date={mediaitem.processed_at}
                icon={mediaitem.image}
                link={mediaitem.link}
                news_id={mediaitem.id}
                keywords={mediaitem.keywords}
                removeMedia={this.removeMediaItem}
                updateLossIncidents={this.updateLossIncidents}
                secret={this.props.user.user.signInUserSession.idToken.jwtToken}
              />
            ))
          ) : (
            <ErrorNotice error="No Media Content Available" />
          )}
          {demoMedia.length != 0
            ? demoMedia.map((mediaitem, index) => (
                <Mediacontent
                  key={index}
                  sourcename={mediaitem.source}
                  handle={mediaitem.handle}
                  date={mediaitem.date}
                  description={mediaitem.description}
                  link={mediaitem.link}
                  icon={this.getMediaLogo(mediaitem.logo_name)}
                />
              ))
            : ''}
        </div>
      )
    } else {
      return ''
    }
  }

  showIncidentHistory = () => {
    if (this.state.lossincidents && this.state.lossincidents.length) {
      for (let i = 0; i < 10; i++) {
        console.log(`INCIDENTS !!!!!!!! ${i * '-'}`)
      }
      return this.state.lossincidents.map((incident, index) => (
        <Incident
          key={index}
          url={incident.url}
          lossincident_id={incident.id}
          textincidentname={incident.name}
          textdescription={incident.description}
          source={incident.source}
          textincidentdate={incident.incident_time}
          attachments={incident.attachments}
          deleteIncident={this.deleteLossIncidentItem}
          user_permissions={this.props.userData.data.permissions}
        />
      ))
    } else {
      return <ErrorNotice error="No Loss Incidents Reports" />
    }
  }

  getMitigationType(mitigation_type) {
    switch (mitigation_type) {
      case '1':
        return 'Survey'
      case '2':
        return 'Flood Barrier'
      case '3':
        return 'Temporary Flood Barriers'
      case '4':
        return 'Technician Response'
      default:
        return 'None'
    }
  }

  getRiskCategory(risk_value) {
    switch (risk_value) {
      case '1':
        return 'Flood'
      case '2':
        return 'Fire'
      case '3':
        return 'Crime'
      case '4':
        return 'Extreme Weather'
      default:
        return 'None'
    }
  }

  showMitigations = () => {
    const mitigations = this.state.currentmitigationList
    if (mitigations && mitigations.length) {
      for (let i = 0; i < 10; i++) {
        console.log(`MITIGATIONS !!!!!!!! ${'-'.repeat(i + 1)}`)
      }
      return mitigations.map((mitigation, index) => (
        <Mitigation
          key={index}
          property_id={this.state.id}
          mitigation_id={mitigation.id}
          textdescription={mitigation.description}
          textinstallationdate={mitigation.installation_date}
          textriskcategory={this.getRiskCategory(mitigation.risk_mitigation_category)}
          textmitigationcategory={this.getMitigationType(mitigation.mitigation_type)}
          serverurl={urls.MITIGATIONS}
          url={mitigation.url}
          attachment={mitigation.attachments}
          deleteMitigation={this.deleteMitigationItem}
          user_permissions={this.props.userData.data.permissions}
        />
      ))
    } else {
      return <ErrorNotice error="No Mitigations Installed" />
    }
  }

  showSmartIntegration = () => {
    let propertygaugereadings = []
    let readingsavaiable1 = false
    let readingsavaiable2 = false
    let airqualityreadings = false

    if (this.state.locationData) {
      propertygaugereadings = this.state.gaugereadings
      if (propertygaugereadings && Object.keys(propertygaugereadings).length != 0) {
        if (propertygaugereadings[1] != null) {
          if (Object.keys(propertygaugereadings[1]).length != 0) {
            if (Object.keys(propertygaugereadings[1].measurements).length != 0) {
              if (propertygaugereadings[1].measurements[0].typicalMax != null) {
                readingsavaiable1 = true
              }
            }
          }
        }
        if (propertygaugereadings[2] != null) {
          if (Object.keys(propertygaugereadings[2]).length != 0) {
            if (Object.keys(propertygaugereadings[2].measurements).length != 0) {
              if (propertygaugereadings[2].measurements[0].typicalMax != null) {
                readingsavaiable2 = true
              }
            }
          }
        }
      }

      let air_siteCode = airquality_match.airquality_sites
        .filter(site_match => site_match.balkerneRef === this.state.locationData.id)
        .map(({ siteCode }) => ({ siteCode }))

      if (air_siteCode.length && air_siteCode.length) {
        console.log(air_siteCode)
        airqualityreadings = true
      }

      return (
        <div>
          {readingsavaiable1
            ? propertygaugereadings[1].measurements.map((reading, index) => (
                <Rivergaugechart
                  key={index}
                  id={index}
                  gaugename={this.state.gaugereadings[1].name}
                  gaugelat={this.state.gaugereadings[1].lat}
                  gaugelon={this.state.gaugereadings[1].lon}
                  gaugereadings={reading.recent_readings}
                  gaugemax={reading.recordMax}
                  gaugetypicalmax={reading.typicalMax}
                  gaugemin={reading.recordMin}
                />
              ))
            : ''}
          {readingsavaiable2
            ? propertygaugereadings[2].measurements.map((reading, index) => (
                <Rivergaugechart
                  id={index + 5}
                  gaugename={this.state.gaugereadings[2].name}
                  gaugelat={this.state.gaugereadings[2].lat}
                  gaugelon={this.state.gaugereadings[2].lon}
                  gaugereadings={reading.recent_readings}
                  gaugemax={reading.recordMax}
                  gaugetypicalmax={reading.typicalMax}
                  gaugemin={reading.recordMin}
                />
              ))
            : ''}
          {airqualityreadings ? <Air_Quality_Import siteCode={air_siteCode[0].siteCode} /> : ''}

          <Weatherimport lat={this.state.locationData.lat} lon={this.state.locationData.lon} />
        </div>
      )
    } else {
      return ''
    }
  }

  render() {
    if (this.state.isLoading === true)
      return (
        <React.Fragment>
          <PropertyHeader
            user_permissions={this.props.userData.data.permissions}
            user_portfolio_type={this.props.userData.data.portfolio_type}
            organisation={this.props.userData.data.orgid}
            secret={this.props.user.user.signInUserSession.idToken.jwtToken}
            logout={() => Logout(this.props)}
            locationData2={this.state.locationData2}
            orgnisation={this.props.userData.data.org_id}
            customstyle={this.props.userData.data.customstyle}
            updateLossIncidents={this.updateLossIncidents}
            updateMitigations={this.updateMitigations}
            currentpage={this.state.current_page}
          />
          <Loader color="#0652dd" />
        </React.Fragment>
      )

    let org_image = require('../images/Logo.png')
    if (
      typeof this.props.userData.data.organisation.logo !== 'undefined' &&
      this.props.userData.data.organisation.logo !== 'None'
    ) {
      org_image = urls.ORGANISATIONS + this.props.userData.data.organisation.logo
    } else if (typeof this.props.userData.data.org_url !== 'undefined') {
      org_image = this.props.userData.data.org_url
    }

    return (
      <React.Fragment>
        <div>
          <div>
            {/* Displays header component with navigation links to other pages */}
            <PropertyHeader
              user_permissions={this.props.userData.data.permissions}
              user_portfolio_type={this.props.userData.data.portfolio_type}
              organisation={this.props.userData.data.orgid}
              logout={() => Logout(this.props)}
              locationData2={this.state.locationData2}
              customstyle={this.props.userData.data.customstyle}
              currentpage={this.state.current_page}
              updateLossIncidents={this.updateLossIncidents}
              updateMitigations={this.updateMitigations}
            />

            <div
              style={{
                left: 0,
                marginLeft: 40,
                display: 'flex',
                alignItems: 'center',
                marginBottom: 20,
                marginTop: 20,
              }}>
              <a style={{ cursor: 'pointer', display: 'flex' }}>
                <img style={{ marginRight: 10 }} src={require('../images/icons/Back.svg')}></img>
                <p className={i.link}>Property Dashboard</p>
              </a>
              <p style={{ marginLeft: 10, marginRight: 10, marginBottom: 'auto', marginTop: 'auto' }}> / </p>
              <p style={{ marginTop: 'auto', marginBottom: 'auto' }}>Risk Report</p>
            </div>

            {/* Diplays section with property name, address */}
            <div className="Propertydetailsection">
              {this.state.locationData !== null ? (
                <PropertySubHeader
                  propertyname={this.state.locationData.name || 'Data not loaded'}
                  propertyaddress={
                    this.state.locationData.address_street ||
                    'Address Unknown' + ', ' + this.state.locationData.address_postcode ||
                    'Postcode Unknown'
                  }
                  propertyreference={this.state.locationData.property_customer_reference}
                  organisationlogo={org_image}
                />
              ) : (
                <PropertySubHeader
                  propertyname={this.state.locationData !== null ? this.state.locationData.name : 'Data not loaded'}
                  propertyaddress={'Address Unknown' + ', ' + 'Postcode Unknown'}
                  propertyreference={''}
                  organisationlogo={org_image}
                />
              )}
            </div>
            <div className="riskReportWrap">
              {/* Enables side navigation bar to become visible */}
              <SideNav
                onClickAlert={this.handleAlertClicked}
                onClickRiskInsight={this.handleInsightsClicked}
                onClickMitigations={this.handleMitigationsClicked}
                onClickSmartIntegration={this.handleSmartIntegrationClicked}
                onClickMedia={this.handleMediaClicked}
                onClickIncidents={this.handleIncidentsClicked}
                // customStyle={this.props.userData.data.customstyle}
              />
              <div className="riskPagesWrap">{this.handleReportComponents()}</div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  const { userLogged, user, userData, systemData, mapAlertData, mapLocationData, dashboardData } = state
  return {
    userLogged,
    user,
    userData,
    systemData,
    mapAlertData,
    mapLocationData,
    dashboardData,
  }
}

export default connect(mapStateToProps)(riskreport)
