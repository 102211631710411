export const userConstants = {
  setUserData: "USERS_SET_USER_DATA",
  getUserData: "USERS_GET_USER_DATA",
  clearUserData: "USERS_CLEAR_USER_DATA",
  updateUserLocations: "UPDATE_USER_LOCATIONS",

  LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
  LOGIN_SUCCESS_LOGGED: "USERS_LOGIN_SUCCESS_LOGGED",

  UPDATE_TOKENS: "USERS_UPDATE_TOKENS",

  authenticate: "USERS_AUTHENTICATE",
  checkUser: "USERS_CHECK_USER",
  checkUserLogged: "USERS_CHECK_USER",

  LOGOUT: "USERS_LOGOUT",
  LOGOUT_LOGGED: "USERS_LOGOUT_LOGGED",
  changedPassword: "USERS_CHANGED_PASSWORD",
  changedPasswordLogged: "USERS_CHANGED_PASSWORD_LOGGED",

  GETALL_REQUEST: "USERS_GETALL_REQUEST",
  GETALL_SUCCESS: "USERS_GETALL_SUCCESS",
  GETALL_FAILURE: "USERS_GETALL_FAILURE",
};
