import React from "react";
import { Auth } from 'aws-amplify';

export class MyStorage {
    // the promise returned from sync function
    static syncPromise = sessionStorage;
    // set item with the key
    static setItem(key, value){
        console.log(key+"   SYNCING")
        sessionStorage.setItem(key,value)

    }
    // get item with the key
    static getItem(key){
        sessionStorage.getItem(key)
    }
    // remove item with the key
    static removeItem(key){
        console.log(key+"   SYNasdasdasCING")
        //sessionStorage.removeItem(key)
    }
    // clear out the storage
    static clear(){
        console.log("   SYNCasdasdasING")
    sessionStorage.clear();
    }
    // If the storage operations are async(i.e AsyncStorage)
    // Then you need to sync those items into the memory in this method
    static Sync(){
        console.log("HMMM?")
        if (!MyStorage.syncPromise) {
            console.log("SYNCING")
            MyStorage.syncPromise = new Promise((res, rej) => {
                if (true) {
                    res("Stuff worked!");
                  }
                  else {
                    rej(Error("It broke"));
                  }
            });
        }
        return MyStorage.syncPromise;
    }
}

Auth.configure({
    storage: sessionStorage 
});

