export const ROLES_ANY_SINGLE = [
  { label: "", value: "" },
  { label: "Property Manager", value: "Property Manager" },
  { label: "Property Admin", value: "Property Admin" },
];

export const ROLES = [
  { label: "", value: "" },
  { label: "Property Manager", value: "Property Manager" },
  // { label: "Property Admin", value: "Property Admin" },
  { label: "Risk Manager", value: "Risk Manager" },
  { label: "Portfolio Manager", value: "Portfolio Manager" },
  { label: "Regional Manager", value: "Regional Manager" },
];

export const ROLES_ANY = [
  { label: "", value: "" },
  { label: "Property Manager", value: "Property Manager" },
  // { label: "Property Admin", value: "Property Admin" },
  { label: "Risk Manager", value: "Risk Manager" },
  { label: "Portfolio Manager", value: "Portfolio Manager" },
  { label: "Regional Manager", value: "Regional Manager" },
  { label: "Organisation Manager", value: "Organisation Manager" },
];

export const ROLES_ALL = [
  { label: "", value: "" },
  { label: "Property Manager", value: "Property Manager" },
  { label: "Property Admin", value: "Property Admin" },
  { label: "Risk Manager", value: "Risk Manager" },
  { label: "Portfolio Manager", value: "Portfolio Manager" },
  { label: "Regional Manager", value: "Regional Manager" },
  { label: "Organisation Manager", value: "Organisation Manager" },
  { label: "Organisation Admin", value: "Organisation Admin" },
];

export const ROLES_ADMIN = [
  { label: "", value: "" },
  { label: "Property Manager", value: "Property Manager" },
  { label: "Property Admin", value: "Property Admin" },
  { label: "Risk Manager", value: "Risk Manager" },
  { label: "Portfolio Manager", value: "Portfolio Manager" },
  { label: "Regional Manager", value: "Regional Manager" },
  { label: "Organisation Manager", value: "Organisation Manager" },
  { label: "Organisation Admin", value: "Organisation Admin" },
];

export const PORTFOLIO_TYPES = [
  { label: "", value: "" },
  { label: "Single Property", value: "Single" },
  { label: "0-25 Properties", value: "Small" },
  { label: "26-100 Properties", value: "Medium" },
  { label: "100+ Properties", value: "Large" },
];

export default ROLES;
