import React, { useState } from "react"
import PropTypes from "prop-types"
import uniqueID from "lodash.uniqueid"
import c from "./Dropzone.module.scss"

/** 
  `import Dropzone from "balkerne-components/Dropzone"` 

  - Files can be drag & dropped or selected once clicked
  - Array of selected files are passed to the 'onChange' callback (https://developer.mozilla.org/en-US/docs/Web/API/File) 
**/

const Dropzone = props => {
  const [files, setFiles] = useState([])
  const callback = props.onChange
  const ID = uniqueID("file-import-")

  const handleSelect = event => {
    try {
      event.persist()
      const documents = Array.from(event.currentTarget.files)
      setFiles(documents)
      callback(documents)
    } catch (err) {
      console.error(err)
      callback(files)
    }
  }

  const handleDrop = event => {
    try {
      event.persist()
      const documents = Array.from(event.dataTransfer.files)
      setFiles(documents)
      callback(documents)
    } catch (err) {
      console.error(err)
      callback(files)
    } finally {
      event.preventDefault()
    }
  }

  return (
    <div className={c.dropzone}>
      <label htmlFor={ID} className={c.label} onDrop={e => handleDrop(e)} onDragOver={e => e.preventDefault()}>
        {files.length > 0 && props.showCounter
          ? `${files.length} file(s) uploaded`
          : `Click or drop file${props.multiple ? "s" : ""} to upload`}
      </label>
      <input id={ID} className={c.default} type='file' multiple={props.multiple} accept={props.accept} onChange={e => handleSelect(e)} />
    </div>
  )
}

Dropzone.propTypes = {
  onChange: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  accept: PropTypes.string,
  showCounter: PropTypes.bool,
}

Dropzone.defaultProps = {
  onChange: files => console.error(`[Dropzone] Default callback, no 'onChange' provided.`),
  multiple: false,
  accept: "*",
  showCounter: false,
}

export default Dropzone
