import React from "react";
import "./mediacontent.css";

export class Mediacontent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    // Returns an event based on menu click
    return (
      <div className="media-item">
        <div className="sourcelogo">
          <img className="icon" src={this.props.icon} alt="" />
        </div>

        <h4 className="sourcename">{this.props.sourcename}</h4>
        <h7 className="handle">{this.props.handle}</h7>
        <h7 className="date">{this.props.date}</h7>

        <h7 className="contentdescription">{this.props.description}</h7>
        <div className="overlay">
          <a onClick={() => window.open(this.props.link, "_blank")}>
            View Content
          </a>
        </div>
      </div>
    );
  }
}

export default Mediacontent;
