import React from "react";
import axios from "axios";
import * as urls from "../components/maps/urls";
import PropertiesGetStarted from "../components/userManagement/PropertiesGetStarted";
import SubmitBulkProperty from "../components/userManagement/submitBulkProperty";
import Loader from "../components/spinner";
import Header from "../components/Header";
import PageContainer from "balkerne-components/Container";
import { Logout } from "../components/Logout";
import { RefreshAuthTokens } from "../components/RefreshAuthTokens";
import "./MainBulkAddProperties.css";
import { connect } from "react-redux";
import { Prompt, Link } from "react-router-dom";
import { check } from "balkerne-components/Can";

export class MainBulkAddProperties extends React.Component {
  constructor(props) {
    super(props);
    //setting initial user row
    this.state = {
      currentStep: 3,
      org_id: 0,
      current_user_id: 0,
      databaseClear: true,
      databaseSuccess: false,
      cognitoSuccess: false,
      errorArray: [],
      errorText: "",
      properties: [],
    };

    this.handleSubmit2 = this.handleSubmit2.bind(this);
    this.handleChangeProperties = this.handleChangeProperties.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
    this.formIsHalfFilledOut = this.formIsHalfFilledOut.bind(this);
  }

  handleChangeProperties(name, value) {
    this.setState({
      [name]: value,
    });
  }

  validateUpload() {
    return true;
  }

  async handleUpload() {
    let success = false;
    console.log(axios.defaults.headers.common["Authorization"]);

    if (this.state.current_user_id !== 0) {
      let data = {
        org_id: this.state.org_id,
        user_id: this.state.current_user_id,
        location_list: JSON.stringify(this.state.properties),
      };

      await axios({
        method: "post",
        url: urls.API_GATEWAY + "organisation/bulk-add-properties",
        data: data,
      })
        .then((res) => {
          console.log(res.data);
          if (res.data === "Success") {
            success = true;
          } else if (
            res.data === null ||
            res.data === "null" ||
            res.data === "Failure" ||
            typeof res.data === "undefined"
          ) {
            this.setState({
              errorText: this.printError([
                "Failure to add properties. Please try again later.",
              ]),
            });
            success = false;
          } else if (res.data === "Invalid Details") {
            this.setState({
              errorText: this.printError([
                "Invalid details for adding properties. Please try again later.",
              ]),
            });
            success = false;
          } else {
            success = false;
            this.setState({
              errorText: this.printError(res.data),
            });
          }
        })
        .catch((err) => {
          success = false;

          this.setState({
            errorText: this.printError(["Cannot reach the Server."]),
          });
          console.log(err);
          this.setState({ success: false });
        });
    } else {
      console.log("user id not found");
      this.setState({ success: false });
      return success;
    }

    return success;
  }

  async componentDidMount() {
    this.state.org_id = this.props.userData.data.orgid;
    this.state.current_user_id = this.props.userData.data.id;
    this.state.group = this.props.userData.data.group;
    console.log(this.props.user.user.signInUserSession.idToken.jwtToken);

    await RefreshAuthTokens(this.props);
    console.log(axios.defaults.headers.common["Authorization"]);

    this.setState({ mounted: true });
  }

  updateErrorText = (errorMessage) => {
    if (errorMessage === null) {
      this.setState({
        errorText: "",
      });
    } else {
      this.setState({
        errorText: this.printError(errorMessage),
      });
    }
  };

  //formats the error message to be on separate lines
  printError = (errorMessage) => {
    var lines = errorMessage;
    console.log(lines);
    var br = lines.map(function (line) {
      return (
        <div className="alert alert-danger" role="alert">
          <span>
            {line}
            <br />
          </span>
        </div>
      );
    });
    return <div>{br}</div>;
  };
  //loads updated values into the users array
  updateFields = (users) => {
    this.setState(this.state.users, this.props.users);
  };
  // Use the submitted data to set the state
  handleChange = (event) => {
    this.setState({
      userData: {
        // object that we want to update
        ...this.state.userData, // keep all other key-value pairs
        [event.target.id]: event.target.value, // update the value of specific key
      },
    });
  };

  async checkConnection(secret) {
    let success = false;
    await axios({ method: "get", url: urls.API_GATEWAY + "users/working" })
      .then((res) => {
        if (res.data === "Success") {
          success = true;
          console.log("connection working");
        }
      })
      .catch((err) => {
        success = false;
        console.log("FAILURE!!");
        console.log(err);
        alert("No connection to the server");
        if (!err.message) {
          console.log("Error establishing working connection: ", err);
        } else {
          console.log("Error establishing working connection: ", err.message);

          this.setState({
            errorText: this.printError(["Cannot reach Server"]),
          });
        }
      });
    return success;
  }

  validateLastStep() {
    let success = true;
    let errorMessage = [];
    for (let propertyNo in this.state.properties) {
      let property = this.state.properties[propertyNo];
      console.log(property);
      if (!property.address || property.address === "") {
        property.success = false;
        success = false;
        errorMessage.push(
          "address missing for property " +
            propertyNo +
            " " +
            (typeof property.name === "undefined" ? "" : property.name)
        );
        console.log(
          "address missing for property " +
            propertyNo +
            " " +
            (typeof property.name === "undefined" ? "" : property.name)
        );
      }
      if (!property.name || property.name === "") {
        property.success = false;
        success = false;
        errorMessage.push(
          "name missing for property " +
            propertyNo +
            " " +
            (typeof property.name === "undefined" ? "" : property.name)
        );
        console.log(
          "name missing for property " +
            propertyNo +
            " " +
            (typeof property.name === "undefined" ? "" : property.name)
        );
      }
      if (!property.country || property.country === "") {
        property.success = false;
        success = false;
        errorMessage.push(
          "country missing for property " +
            propertyNo +
            " " +
            (typeof property.name === "undefined" ? "" : property.name)
        );
        console.log(
          "country missing for property " +
            propertyNo +
            " " +
            (typeof property.name === "undefined" ? "" : property.name)
        );
      }
      if (!property.processed) {
        property.success = false;
        success = false;
        errorMessage.push(
          "property not fully processed for property " +
            propertyNo +
            " " +
            (typeof property.name === "undefined" ? "" : property.name)
        );
        console.log(
          "property not fully processed for property " +
            propertyNo +
            " " +
            (typeof property.name === "undefined" ? "" : property.name)
        );
      }
    }

    if (this.state.properties.length < 1) {
      success = false;
      errorMessage.push("no properties added");
      console.log("no properties added");
    }

    if (success === false) {
      success = false;
      console.log(errorMessage);
      this.setState({
        errorText: this.printError(errorMessage),
      });
    }
    console.log("submitting " + success);
    console.log(this.state.userData);
    console.log(this.state.properties);
    return success;
  }

  async handleSubmit2() {
    let success = false;
    let successDatabase = false;
    success = this.validateLastStep();

    if (success) {
      success = this.checkConnection(
        this.props.user.user.signInUserSession.idToken.jwtToken
      );
    } else {
      console.log("Error with address form");
    }

    if (success) {
      console.log("last step success");
      successDatabase = await this.handleUpload();
      if (successDatabase) {
        console.log("Property submitted for analysis");
        let currentStep = 4;
        this.setState({ databaseSuccess: true });
        this.setState({ currentStep });
        console.log("All good all uploaded");
      } else {
        console.log("Error with uploading database");
        console.log(this.state.errorText);
      }
    } else {
      console.log("Error with backend connection");
    }
  }

  formIsHalfFilledOut() {
    let filled = false;
    for (let propertyNo in this.state.properties) {
      let property = this.state.properties[propertyNo];
      if (property.address || property.address !== "") {
        filled = true;
      }
      if (property.name || property.name !== "") {
        filled = true;
      }
      if (this.state.currentStep == 4) {
        filled = false;
      }
    }
    return filled;
  }

  sendToMainAddLocations() {
    if (check("locations:add", null, this.props.userData.data.permissions)) {
      this.props.history.push({
        pathname: "/MainAddLocation",
      });
    } else {
      console.log("User permissions failed");
    }
  }

  render() {
    if (this.state.mounted === false)
      return (
        <>
          <Header
            user_permissions={this.props.userData.data.permissions}
            user_portfolio_type={this.props.userData.data.portfolio_type}
            customstyle={this.props.userData.data.customstyle}
            currentpage={this.state.current_page}
            organisation={this.props.userData.data.orgid}
            logout={() => Logout(this.props)}
          />
          <Loader color="#0652dd" />
        </>
      );

    return (
      <>
        <Prompt
          when={this.formIsHalfFilledOut()}
          message="Are you sure you want to leave?"
        />

        <Header
          user_permissions={this.props.userData.data.permissions}
          user_portfolio_type={this.props.userData.data.portfolio_type}
          customstyle={this.props.userData.data.customstyle}
          currentpage={this.state.current_page}
          organisation={this.props.userData.data.orgid}
          logout={() => Logout(this.props)}
        />

        <PageContainer>
          <PageContainer.Center>
            <div className="createnewcompany">
              <div className="createnewcompanyheading mb-2">
                <h2>Add Bulk Properties</h2>
                <p>
                  Can't find the property ?{" "}
                  <span
                    className="link"
                    onClick={() => this.sendToMainAddLocations()}
                  >
                    Add manually
                  </span>
                </p>
              </div>

              <PropertiesGetStarted
                currentStep={this.state.currentStep}
                handleChangeProperties={this.handleChangeProperties}
                handleSubmit={this.handleSubmit2}
                printError={this.printError}
                updateErrorText={this.updateErrorText}
                source={"bulkpropertiesadd"}
              />

              <SubmitBulkProperty
                currentStep={this.state.currentStep}
                databaseSuccess={this.state.databaseSuccess}
              />

              <div>{this.state.errorText}</div>
            </div>
          </PageContainer.Center>
        </PageContainer>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { userLogged, user, userData } = state;
  return {
    userLogged,
    user,
    userData,
  };
}

export default connect(mapStateToProps)(MainBulkAddProperties);
