import React from "react";

//import Home from './Home'
import { Route, Redirect, BrowserRouter } from "react-router-dom";
import UserDashboard from "./UserDashboard";
import axios from "axios";
import * as urls from "../components/maps/urls";
import Table from "react-bootstrap/Table";

import { userActions } from "../redux/_actions";
import { connect } from "react-redux";
import { Logout } from "../components/Logout";
import { RefreshAuthTokens } from "../components/RefreshAuthTokens";
import Header from "../components/Header";
import Loader from "../components/spinner";
import { Button, DownloadButton } from "balkerne-components/Button";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import Can from "balkerne-components/Can";

import "./Locationlist.css";
//page that lists all locations and redirects to location when clicked

class LocationListPage extends React.Component {
  constructor(props) {
    super(props);
    this.theme = props.userData.data.customstyle;

    this.state = {
      locationList: [],
      filter_string: "",
      filter_type: "",
      selectedLocation: 1,
      selectedLocationIndex: 0,
      clicks: [],
      filteredLocations: null,

      redirect: false,
      redirectID: null,

      //error displaying
      errorArray: [],
      errorText: "",
      errMessage: "",
      success: true,

      mounted: false,
      isLoading: false,
    };
    this.handleDoubleClick = this.handleDoubleClick.bind(this);
    this.handleRedirect = this.handleRedirect.bind(this);
    this.getUserLocations = this.getUserLocations.bind(this);
    this.removeProperty = this.removeProperty.bind(this);
  }

  //redirects the user to the location given the clicked id

  handleChange = (e) => {
    let filter = {};
    filter[e.target.name] = e.target.value;
    this.setState(filter);
    //this.props.handleChange("users",this.state.users);
  };

  async getUserLocations(secret, user_id) {
    let locations = [];
    //let success = true;

    let params = { user_id: user_id };
    console.log("user id - " + user_id);

    await axios({
      method: "get",
      url: urls.API_GATEWAY + "locations/property",
      params: params,
    })
      .then((res) => {
        locations = res.data;
        console.log(locations);

        if (
          locations === "null" ||
          locations === null ||
          typeof locations === "undefined"
        ) {
          console.log("Error retrieving locations");
          locations = [];
        } else if (locations === "Invalid Details") {
          console.log("Details for Location retrieval are INVALID");
          locations = [];
        } else if (locations === "No properties found") {
          console.log("No properties was Found");
          locations = [];
        } else if (!Array.isArray(locations)) {
          console.log("Location retrieval ERROR");
          locations = [];
        } else {
          console.log("Properties succesfully retrieved");
        }

        this.setState({ locations });

        // if(res.data =='{"message":"The incoming token has expired"}'){
        //   console.log("TIMEOUT ERROR!!!!!!")
        // }
      })
      .catch((err) => {
        locations = [];
        console.log("FAILURE!!");
        console.log(err);
        // console.log(err.response.data);
        if (
          err.response &&
          err.response.data &&
          err.response.data &&
          err.response.data.message == "The incoming token has expired"
        ) {
          console.log("TIMEOUT ERROR!!!!!!");
        }
        //alert("No connection to the server")
        if (!err.message) {
          console.log("Error when getting locations: ", err);
          //  Alert.alert('Error when signing in: ', err);
        } else {
          console.log("Error when getting locations: ", err.message);
          //  Alert.alert('Error when signing in: ', err.message);
          this.setState({
            success: false,
            errMessage: "Could not retrieve locations. (Server)",
          });
        }
      });
  }

  //loads all the locations from the backend

  async componentDidMount() {
    this.setState({ isLoading: true });

    this.state.org_id = this.props.userData.data.orgid;
    this.state.current_user_id = this.props.userData.data.id;
    this.state.group = this.props.userData.data.group;

    await RefreshAuthTokens(this.props);

    await this.getUserLocations(
      this.props.user.user.signInUserSession.idToken.jwtToken,
      this.props.userData.data.id
    );

    this.setState({ mounted: true });

    this.setState({ isLoading: false });
  }

  // selects a location from location list by index

  selectLocationIndex = (index) => {
    this.setState({ selectedLocationIndex: index });
  };

  //render the locations as a clickable table
  onRowSelectLocations = (row, isSelected, e, rowIndex) => {
    if (isSelected) {
      this.state.selectedLocations = [row.id];
    } else {
      this.state.selectedLocations = [row.id];
      this.handleDoubleClick(row.id);
    }
    //console.log("row select")
    // this.state.clicks.push(new Date().getTime());
    // window.clearTimeout(this.state.timeout);
    // this.state.timeout = window.setTimeout(() => {
    //   if (
    //     this.state.clicks.length > 1 &&
    //     this.state.clicks[this.state.clicks.length - 1] -
    //       this.state.clicks[this.state.clicks.length - 2] <
    //       250
    //   ) {console.log("double click")
    //     //this.handleDoubleClick(event.target, location);
    //   } else {
    //     console.log("signle click")
    //     //this.handleClick(event.target);
    //   }
    // }, 250);
  };

  handleClick(event) {
    console.log("single click");
  }

  showNotice = () => {
    if (this.state.success === false) {
      return <div className="alertbox">{this.state.errMessage}</div>;
    }
  };

  //handles the actual redirect when double clicked a table row to a property dashboard

  async handleDoubleClick(location_id) {
    let location = await this.getLocationData(
      location_id,
      this.props.userData.data.orgid,
      this.props.user.user.signInUserSession.idToken.jwtToken
    );

    console.log(location);

    if (location !== null) {
      this.handleRedirect(location, null);
    }
  }

  async removeProperty() {
    let answer = window.confirm(
      "Are you sure you want to delete the property?"
    );
    if (answer) {
      if (
        this.state.selectedLocations &&
        this.state.selectedLocations.length > 0
      ) {
        let result = false;
        let propid = this.state.selectedLocations[0];

        let data = { propid: propid };
        console.log(propid);

        await axios({
          method: "post",
          url: urls.API_GATEWAY + "locations/remove-property",
          data: data,
        })
          .then((res) => {
            result = res.data;
            console.log(result);

            if (
              result === "null" ||
              result === null ||
              result === "Failure" ||
              typeof result === "undefined"
            ) {
              result = false;
              console.log("Property deletion FAILURE");
              this.setState({ errMessage: "Error deleting property." });
            } else if (result === "Invalid Details") {
              console.log("Property deletion credentials wrong ");
              this.setState({
                errMessage: "Invalid credentials for deleting property.",
              });
              result = false;
            } else if (result === "Property not found") {
              console.log("Property for deletion not found.");
              this.setState({ errMessage: "Property for deletion not found." });
              result = false;
            } else {
              console.log("SUCCESS!!");
              console.log("Users retrieved from the database.");
              result = true;
            }
          })
          .catch((err) => {
            result = false;

            console.log("FAILURE DELETING!!");
            console.log(err);
            if (!err.message) {
              console.log("Error deleting property: ", err);
              //  Alert.alert('Error when signing in: ', err);
            } else {
              console.log("Error deleting property: ", err.message);
              //  Alert.alert('Error when signing in: ', err.message);
              this.setState({ errMessage: "Error deleting property" });
            }
          });

        if (result == true) {
          let locations = this.state.locations.filter((location) => {
            if (location.id) {
              return location.id != propid;
            }
          });

          let filteredLocations = this.state.filteredLocations.filter(
            (location) => {
              if (location.id) {
                return location.id != propid;
              }
            }
          );

          this.setState({ locations });
          this.setState({ filteredLocations });
        }
      }
    } else {
      console.log("deletion cancelled");
    }
  }

  async handleRedirect(location1, alerts1) {
    console.log("Runnin");
    if (alerts1 === null) {
      let payload = null;

      let data = {
        propid: location1.properties.id,
        country: location1.properties.address_country,
      };

      await axios({
        method: "post",
        url: urls.API_GATEWAY + "flood/alerts-by-property",
        data: data,
      })
        .then((res) => {
          payload = res.data;

          if (
            payload === "null" ||
            payload === null ||
            payload === "Failure" ||
            typeof payload === "undefined"
          ) {
            payload = null;
            console.log("Error retrieving property live alerts");
          } else if (payload === "Invalid Details") {
            console.log(
              "Details for property live alerts retrieved are INVALID"
            );
            payload = null;
          } else {
            console.log("SUCCESS!!");
            this.setState({ redirectAlerts: payload });
            console.log(payload);
            console.log(
              "Live Alert Data for Property retrieved from the database."
            );
          }
        })
        .catch((err) => {
          console.log("FAILURE!!");
          console.log(err);
          this.setState({ redirectAlerts: [] });
          if (!err.message) {
            console.log("Error when loading redirectAlerts: ", err);
            //  Alert.alert('Error when signing in: ', err);
          } else {
            console.log("Error when loading redirectAlerts: ", err.message);
            //  Alert.alert('Error when signing in: ', err.message);
            this.setState({
              success: false,
              errMessage: "Cannot reach Server",
            });
          }
        });
    }
    this.state.redirectID = location1.properties.id;
    this.state.redirectAlerts = alerts1;
    this.state.redirectLocation = location1;
    console.log("pushing");
    this.props.history.push({
      pathname: "/UserDashboard",
      state: {
        redirectID: this.state.redirectID,
        historical: this.state.historical,
        alerts: this.state.redirectAlerts,
        location: this.state.redirectLocation,
        handleLogout: this.props.handleLogout,
      },
    });
  }

  async getLocationData(id) {
    let answer;
    // let checked = false;

    console.log(id);
    console.log(this.props.userData.data.orgid);

    let params = { propid: id, org_id: this.props.userData.data.orgid };

    await axios({
      method: "get",
      url: urls.API_GATEWAY + "locations/property-specific",
      params: params,
    })
      .then((res) => {
        let locationData = res.data;
        console.log(locationData);

        if (
          locationData === "null" ||
          locationData === null ||
          typeof locationData === "undefined"
        ) {
          console.log("Error retrieving location");
          answer = null;
        } else if (locationData === "Invalid Details") {
          console.log("Details for Location retrieved are INVALID");
          answer = null;
        } else if (locationData === "Property not found") {
          console.log("No Location was Found");
          answer = null;
          alert("No Location Found");
        } else {
          answer = locationData;
        }
      })
      .catch((err) => {
        answer = null;
        console.log("FAILURE!!");
        console.log(err);
        if (!err.message) {
          console.log("Error when loading locations: ", err);
          //  Alert.alert('Error when signing in: ', err);
        } else {
          console.log("Error when loading locations: ", err.message);
          //  Alert.alert('Error when signing in: ', err.message);
          this.setState({ success: false, errMessage: "Cannot reach Server" });
        }
      });

    return answer;
  }

  //handles the single/double click logic

  clickHandler(event, location) {
    event.preventDefault();
    console.log("click event");
    console.log(event);
    console.log(location);
  }

  applyFilter = () => {
    console.log("applied");
    this.state.filteredLocations = this.state.locations;
    let filteredLocations;
    switch (this.state.filter_type) {
      case "Name":
        filteredLocations = this.state.locations.filter((location) => {
          if (location.name) {
            return location.name
              .toLowerCase()
              .includes(this.state.filter_string.toLowerCase());
          }
        });
        break;
      case "Region":
        filteredLocations = this.state.locations.filter((location) => {
          if (location.region) {
            location.region
              .toLowerCase()
              .includes(this.state.filter_string.toLowerCase());
          }
        });
        break;
      case "CRN":
        filteredLocations = this.state.locations.filter((location) => {
          if (location.property_customer_reference) {
            if (location.property_customer_reference !== null) {
              return location.property_customer_reference
                .toLowerCase()
                .includes(this.state.filter_string.toLowerCase());
            }
          }
        });
        break;
      case "Address":
        filteredLocations = this.state.locations.filter((location) => {
          if (location.address_street) {
            return location.address_street
              .toLowerCase()
              .includes(this.state.filter_string.toLowerCase());
          }
        });
        break;
      case "Postcode":
        filteredLocations = this.state.locations.filter((location) => {
          if (location.address_postcode) {
            return location.address_postcode
              .toLowerCase()
              .includes(this.state.filter_string.toLowerCase());
          }
        });
        break;
      default:
        filteredLocations = this.state.locations;
    }

    this.setState({ filteredLocations });
  };

  clearFilter = () => {
    this.state.filter_string = "";

    let filteredLocations = this.state.locations;

    this.setState({ filteredLocations });
    console.log(this.state.filteredLocations);
  };

  displayDeleteProperty(user_permissions) {
    if (this.state.selectedLocation) {
      return (
        <div className="button-group">
          <Can
            permissions={user_permissions}
            actions={[
              "locations:delete",
              "locations:delete-any",
              "locations:delete-all",
            ]}
            yes={() => (
              <Button style={this.theme} onClick={this.removeProperty}>
                Delete
              </Button>
            )}
          />
        </div>
      );
    }
  }

  //Renders the page

  render() {
    //Redirecting
    // if(this.state.redirect==true){
    // console.log(this.state.filteredLocations);
    //   return(
    //     <Redirect to={{
    //   pathname: '/UserDashboard',
    //   state: { redirectID: this.state.redirectID }
    // }}/>
    //   )
    // }
    const user_permissions = this.props.userData.data.permissions;
    if (this.state.isLoading === true)
      return (
        <React.Fragment>
          <Header
            user_permissions={user_permissions}
            user_portfolio_type={this.props.userData.data.portfolio_type}
            customstyle={this.props.userData.data.customstyle}
            organisation={this.props.userData.data.orgid}
            logout={() => Logout(this.props)}
          />
          {this.showNotice()}
          <Loader color="#0652dd" />
        </React.Fragment>
      );

    const selectRowPropLocations = {
      mode: "radio",
      clickToSelect: true,
      bgColor: "cyan",
      selected: this.state.selectedLocations,
      hideSelectColumn: true,
      onSelect: this.onRowSelectLocations,
    };

    if (this.state.filteredLocations === null && this.state.locations) {
      this.state.filteredLocations = this.state.locations;
    }

    const downloadCSV =
      this.state.filteredLocations &&
      this.state.filteredLocations.map((location) => ({
        "Property Name": location.name,
        Address: location.address_street,
        Postcode: location.address_postcode,
        Region: location.region,
        "Ref. Number": location.property_customer_reference,
      }));

    return (
      <React.Fragment>
        <Header
          user_permissions={user_permissions}
          user_portfolio_type={this.props.userData.data.portfolio_type}
          customstyle={this.props.userData.data.customstyle}
          organisation={this.props.userData.data.orgid}
          logout={() => Logout(this.props)}
        />
        {this.showNotice()}

        <div className="locationlistform">
          <div className="filterpropertylist">
            <form onChange={this.handleChange}>
              <label> Filter: </label>
              <div className="searchfields">
                <input
                  type="text"
                  name="filter_string"
                  className="filter_string"
                  id="filter_string"
                  value={this.state.filter_string}
                />
                <select
                  name="filter_type"
                  className="filter_type"
                  id="filter_type"
                >
                  <option disabled selected>
                    Choose a Filter
                  </option>
                  <option value="Name">Name</option>
                  <option value="Region">Region</option>
                  <option value="CRN">Property Reference</option>
                  <option value="Address">Address</option>
                  <option value="Postcode">Postcode</option>
                </select>

                <Button light onClick={this.applyFilter}>
                  Apply
                </Button>

                <Button light onClick={this.clearFilter}>
                  Clear
                </Button>

                {this.state.filteredLocations && (
                  <DownloadButton
                    light
                    data={downloadCSV}
                    filename="properties.csv"
                  >
                    Download CSV
                  </DownloadButton>
                )}
              </div>
            </form>
          </div>
          <div className="propertylistresults">
            <BootstrapTable
              className="table-smaller-locations"
              selectRow={selectRowPropLocations}
              data={this.state.filteredLocations}
              striped
              hover
            >
              <TableHeaderColumn isKey dataField="id" hidden>
                ID
              </TableHeaderColumn>
              <TableHeaderColumn width="30%" dataField="name">
                Property Name
              </TableHeaderColumn>
              <TableHeaderColumn width="10%" dataField="address_postcode">
                Postcode
              </TableHeaderColumn>
              <TableHeaderColumn width="40%" d dataField="address_street">
                Address
              </TableHeaderColumn>
              <TableHeaderColumn width="10%" dataField="region">
                Region
              </TableHeaderColumn>
              <TableHeaderColumn
                width="10%"
                dataField="property_customer_reference"
              >
                Property Reference
              </TableHeaderColumn>
            </BootstrapTable>
          </div>
          <div>
            {this.state.errorText}
            {this.displayDeleteProperty(user_permissions)}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  // console.log(state);
  const { userLogged, user, userData } = state;

  return {
    userLogged,
    user,
    userData,
  };
}
export default connect(mapStateToProps)(LocationListPage);
