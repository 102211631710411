import React from "react";
import { OptionCategory, Option } from "./Options";
import { Sidebar } from "./Sidebar";
import { Dashboard } from "./Dashboard";
import { GoogleMap } from "./GoogleMap";
import * as styles from "./styles";
import * as gmaps from "./gmaps";

import "./App.css";

import { activeFilters, activeLayersHistorical } from "./config";
import { systemReduxMapHandler } from "../../redux/_helpers/systemReduxMapHandler.js";
//Map component that uses real alerts and filtering from portfolio dashboard

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      map: null,
      infoWindow: null,
      dashboardVisible: false,
      dashboardFeature: null,
      layers: activeLayersHistorical,
      filters: activeFilters,
      alertFilterSeverity: 0,
      locationRiskSeverity: 0,
      locationType: null,
    };
    this.onMainMapLoad = this.onMainMapLoad.bind(this);
    this.onLayerChange = this.onLayerChange.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.showFilter = this.showFilter.bind(this);
    this.hideFilter = this.hideFilter.bind(this);
  }

  //first function that is run when this component is rendered, sets the parent interaction function to set bubble filters
  //and invokes the said function, applying filters given selected alert bubble in the props
  //the activation of this can be found here: https://github.com/kriasoft/react-starter-kit/issues/909

  componentload() {
    this.props.setAlertFilter(this.setBubbleFilters.bind(this));
    this.props.setLocationFilter(this.setBubbleFilters.bind(this));
    this.setBubbleFilters();
    this.setLocationFilters();
  }

  setLocationFilters = () => {
    if (
      this.state.locationType !== "rofrs" &&
      this.props.selectedPropertyType === "rofrs"
    ) {
      this.state.locationType = "rofrs";

      if (
        this.props.selectedRiskSeverity === 4 &&
        this.state.selectedPropertyType !== "rofrs"
      ) {
        this.state.locationRiskSeverity = 4;

        //console.log("doing rofrs 4");

        this.setLocationLayers("properties_rofrs_high");
      }
      if (
        this.props.selectedRiskSeverity === 3 &&
        this.state.selectedPropertyType !== "rofrs"
      ) {
        this.state.locationRiskSeverity = 3;
        //console.log("doing rofrs 3");

        this.setLocationLayers("properties_rofrs_medium");
      }
      if (
        this.props.selectedRiskSeverity === 1 &&
        this.state.selectedPropertyType !== "rofrs"
      ) {
        this.state.locationRiskSeverity = 1;
        //console.log("doing rofrs 1");

        this.setLocationLayers("properties_rofrs_low");
      }
    } else if (
      this.state.locationType !== "rofsw" &&
      this.props.selectedPropertyType === "rofsw"
    ) {
      this.state.locationType = "rofsw";

      if (
        this.props.selectedRiskSeverity === 4 &&
        this.state.selectedPropertyType !== "rofsw"
      ) {
        this.state.locationRiskSeverity = 4;

        //console.log("doing rofsw 4");

        this.setLocationLayers("properties_rofsw_high");
      }
      if (
        this.props.selectedRiskSeverity === 3 &&
        this.state.selectedPropertyType !== "rofsw"
      ) {
        this.state.locationRiskSeverity = 3;

        //console.log("doing rofsw 3");

        this.setLocationLayers("properties_rofsw_medium");
      }
      if (
        this.props.selectedRiskSeverity === 1 &&
        this.state.selectedPropertyType !== "rofsw"
      ) {
        this.state.locationRiskSeverity = 1;

        //console.log("doing rofsw 1");
        this.setLocationLayers("properties_rofsw_low");
      }
    } else if (
      this.state.locationType !== "crime" &&
      this.props.selectedPropertyType === "crime"
    ) {
      this.state.locationType = "crime";

      if (
        this.props.selectedRiskSeverity === 4 &&
        this.state.selectedPropertyType !== "crime"
      ) {
        this.state.locationRiskSeverity = 4;

        //console.log("doing crime 4");

        this.setLocationLayers("properties_crime_high");
      }
      if (
        this.props.selectedRiskSeverity === 3 &&
        this.state.selectedPropertyType !== "crime"
      ) {
        this.state.locationRiskSeverity = 3;

        //console.log("doing crime 3");

        this.setLocationLayers("properties_crime_medium");
      }
      if (
        this.props.selectedRiskSeverity === 1 &&
        this.state.selectedPropertyType !== "crime"
      ) {
        this.state.locationRiskSeverity = 1;

        //console.log("doing crime 1");
        this.setLocationLayers("properties_crime_low");
      }
    } else if (
      this.state.locationType !== "fire" &&
      this.props.selectedPropertyType === "fire"
    ) {
      this.state.locationType = "fire";

      if (
        this.props.selectedRiskSeverity === 4 &&
        this.state.selectedPropertyType !== "fire"
      ) {
        this.state.locationRiskSeverity = 4;

        //console.log("doing fire 4");

        this.setLocationLayers("properties_fire_high");
      }
      if (
        this.props.selectedRiskSeverity === 3 &&
        this.state.selectedPropertyType !== "fire"
      ) {
        this.state.locationRiskSeverity = 3;

        //console.log("doing fire 3");

        this.setLocationLayers("properties_fire_medium");
      }
      if (
        this.props.selectedRiskSeverity === 1 &&
        this.state.selectedPropertyType !== "fire"
      ) {
        this.state.locationRiskSeverity = 1;

        //console.log("doing fire 1");
        this.setLocationLayers("properties_fire_low");
      }
    }

    // console.log(
    //   "setting location filters + " +
    //     this.state.locationRiskSeverity +
    //     " + " +
    //     this.props.selectedRiskSeverity +
    //     "     setting location type + " +
    //     this.state.locationType +
    //     " + " +
    //     this.props.selectedPropertyType
    // );

    if (
      this.state.locationType === "rofrs" &&
      this.state.locationRiskSeverity !== 4 &&
      this.props.selectedRiskSeverity === 4
    ) {
      this.state.locationRiskSeverity = 4;

      //console.log("doing rofrs 4");

      this.setLocationLayers("properties_rofrs_high");
    }
    if (
      this.state.locationType === "rofrs" &&
      this.state.locationRiskSeverity !== 3 &&
      this.props.selectedRiskSeverity === 3
    ) {
      this.state.locationRiskSeverity = 3;
      // console.log("doing rofrs 3");

      this.setLocationLayers("properties_rofrs_medium");
    }
    if (
      this.state.locationType === "rofrs" &&
      this.state.locationRiskSeverity !== 1 &&
      this.props.selectedRiskSeverity === 1
    ) {
      this.state.locationRiskSeverity = 1;
      // console.log("doing rofrs 1");

      this.setLocationLayers("properties_rofrs_low");
    }
    // if (this.state.locationType=="rofrs"  &&
    //   this.state.locationRiskSeverity !== 0 &&
    //   this.props.selectedRiskSeverity === 0
    // ) {
    //   this.state.locationRiskSeverity = 0;

    //   console.log("doing rofrs 0");
    //   let filterId = 0;
    //   let layers = this.state.layers;
    //   let filters = this.state.filters;
    //   for (let layer in layers) {

    //     if (layer.name === "properties_rofrs_negligible") {
    //       if (layer) {
    //         layer.visible = true;
    //       }
    //         this.showLayer(this.state.map, layer);
    //       }
    //     }
    //   // this.setState({filters})
    // }

    if (
      this.state.locationType === "rofsw" &&
      this.state.locationRiskSeverity !== 4 &&
      this.props.selectedRiskSeverity === 4
    ) {
      this.state.locationRiskSeverity = 4;

      //console.log("doing rofsw 4");

      this.setLocationLayers("properties_rofsw_high");
    }
    if (
      this.state.locationType === "rofsw" &&
      this.state.locationRiskSeverity !== 3 &&
      this.props.selectedRiskSeverity === 3
    ) {
      this.state.locationRiskSeverity = 3;

      //console.log("doing rofsw 3");

      this.setLocationLayers("properties_rofsw_medium");
    }
    if (
      this.state.locationType === "rofsw" &&
      this.state.locationRiskSeverity !== 1 &&
      this.props.selectedRiskSeverity === 1
    ) {
      this.state.locationRiskSeverity = 1;

      // console.log("doing rofsw 1");
      this.setLocationLayers("properties_rofsw_low");
    }

    if (
      this.state.locationType === "crime" &&
      this.state.locationRiskSeverity !== 4 &&
      this.props.selectedRiskSeverity === 4
    ) {
      this.state.locationRiskSeverity = 4;

      //console.log("doing crime 4");

      this.setLocationLayers("properties_crime_high");
    }
    if (
      this.state.locationType === "crime" &&
      this.state.locationRiskSeverity !== 3 &&
      this.props.selectedRiskSeverity === 3
    ) {
      this.state.locationRiskSeverity = 3;
      // console.log("doing crime 3");

      this.setLocationLayers("properties_crime_medium");
    }
    if (
      this.state.locationType === "crime" &&
      this.state.locationRiskSeverity !== 1 &&
      this.props.selectedRiskSeverity === 1
    ) {
      this.state.locationRiskSeverity = 1;
      // console.log("doing crime 1");

      this.setLocationLayers("properties_crime_low");
    }

    if (
      this.state.locationType === "fire" &&
      this.state.locationRiskSeverity !== 4 &&
      this.props.selectedRiskSeverity === 4
    ) {
      this.state.locationRiskSeverity = 4;

      //console.log("doing fire 4");

      this.setLocationLayers("properties_fire_high");
    }
    if (
      this.state.locationType === "fire" &&
      this.state.locationRiskSeverity !== 3 &&
      this.props.selectedRiskSeverity === 3
    ) {
      this.state.locationRiskSeverity = 3;
      // console.log("doing fire 3");

      this.setLocationLayers("properties_fire_medium");
    }
    if (
      this.state.locationType === "fire" &&
      this.state.locationRiskSeverity !== 1 &&
      this.props.selectedRiskSeverity === 1
    ) {
      this.state.locationRiskSeverity = 1;
      // console.log("doing fire 1");

      this.setLocationLayers("properties_fire_low");
    }
  };

  setLocationLayers = (layerstring) => {
    let layers = this.state.layers;

    for (let layer in layers) {
      if (layer === layerstring) {
        layer = activeLayersHistorical[layer];

        layer.visible = true;

        this.showLayer(this.state.map, layer);
      } else {
        layer = activeLayersHistorical[layer];
        if (layer.toggleable == false) {
          layer.visible = false;

          this.hideLayer(this.state.map, layer);
        }
      }
    }
  };

  //Sets the filters of the map alerts to match the selected severity in the props

  setBubbleFilters = () => {
    // console.log(
    //   "setting filters + " +
    //     this.state.alertFilterSeverity +
    //     " + " +
    //     this.props.selectedAlertSeverity
    // );
    if (
      this.state.alertFilterSeverity !== 1 &&
      this.props.selectedAlertSeverity === 1
    ) {
      this.state.alertFilterSeverity = 1;
      // console.log("filters severity 1");
      let filterId = 1;
      let layers = this.state.layers;
      let filters = this.state.filters;
      for (let filter1 in filters) {
        let filter = filters[filter1];

        if (filter.parentLayer === "Flood Alerts") {
          if (filter.args[0] === filterId) {
            filter.active = true;
            console.log("showing filter");
            console.log(filter);
            this.showFilter(filter, layers);
          } else if (filter.active === true) {
            console.log("hiding filter");
            console.log(filter);
            filter.active = false;
            this.hideFilter(filter, layers);
          }
        }
      }
      // this.setState({filters})
    }
    if (
      this.state.alertFilterSeverity !== 2 &&
      this.props.selectedAlertSeverity === 2
    ) {
      console.log("filters severity 2");

      this.state.alertFilterSeverity = 2;
      let filterId = 2;
      let layers = this.state.layers;
      let filters = this.state.filters;
      console.log(filters);
      for (let filter1 in filters) {
        let filter = filters[filter1];

        if (filter.parentLayer === "Flood Alerts") {
          if (filter.args[0] === filterId) {
            filter.active = true;
            console.log("showing filter");
            console.log(filter);
            this.showFilter(filter, layers);
          } else if (filter.active === true) {
            console.log("hiding filter");
            console.log(filter);
            filter.active = false;
            this.hideFilter(filter, layers);
          }
        }
      }
      // this.setState({filters})
    }
    if (
      this.state.alertFilterSeverity !== 3 &&
      this.props.selectedAlertSeverity === 3
    ) {
      console.log("filters severity 3");
      this.state.alertFilterSeverity = 3;
      let filterId = 3;
      let layers = this.state.layers;
      let filters = this.state.filters;
      for (let filter1 in filters) {
        let filter = filters[filter1];

        if (filter.parentLayer === "Flood Alerts") {
          if (filter.args[0] === filterId) {
            filter.active = true;
            console.log("showing filter");
            console.log(filter);
            this.showFilter(filter, layers);
          } else if (filter.active === true) {
            console.log("hiding filter");
            console.log(filter);
            filter.active = false;
            this.hideFilter(filter, layers);
          }
        }
      }
      // this.setState({filters})
    }
  };

  openDashboard(feature) {
    console.log("DASHBOAAARD");
    console.log("Sending to property Dahboard - requires mapping to work");
  }

  closeDashboard(feature) {
    console.log("DASHBOAAARDCLOSE");
  }

  //Loads the map with the needed data and tile layers

  async onMainMapLoad(map) {
    this.setState({ map });
    for (let layerName in this.state.layers) {
      let layer = this.state.layers[layerName];
      switch (layer.type) {
        case "data":
          let dataLayer = await systemReduxMapHandler.directLayer(
            this.props.props,
            layer,
            null,
            this.props.user.user.signInUserSession.idToken.jwtToken,
            this.props.userData.data.organisation
          );
          console.log(dataLayer);
          if (dataLayer) {
            if (layer.style) {
              dataLayer.setStyle(layer.style);
            }
            if (layer.onClick) {
              dataLayer.addListener("click", layer.onClick.bind(this));
            }

            this.setState((state) => {
              let layers = state.layers;
              let layer = layers[layerName];
              layer.layer = dataLayer;

              //Loading 20%

              if (layer.visible) {
                this.showLayer(state.map, layer);
              }
              return layers;
            });
          }
          break;

        case "property_risk":
          let propertyLayer = await systemReduxMapHandler.directLayer(
            this.props.props,
            layer,
            this.props.locations,
            this.props.user.user.signInUserSession.idToken.jwtToken,
            this.props.userData.data.organisation
          );

          if (propertyLayer) {
            if (layer.style) {
              propertyLayer.setStyle(layer.style);
            }
            if (layer.onClick) {
              propertyLayer.addListener("click", layer.onClick.bind(this));
            }

            this.setState((state) => {
              let layers = state.layers;
              let layer = layers[layerName];
              layer.layer = propertyLayer;

              //Loading 20%

              if (layer.visible) {
                this.showLayer(state.map, layer);
              }
              return layers;
            });
          }

          break;
        case "tile":
          gmaps.tileLayerFromURL(layer.url, layer).then((tileLayer) => {
            this.setState((state) => {
              let layers = state.layers;
              let layer = layers[layerName];
              layer.layer = tileLayer;
              if (layer.visible) {
                this.showLayer(state.map, layer);
              }
              return layers;
            });
          });
          break;
        default:
      }
    }
  }

  //changes the layer upon toggle clicks

  onLayerChange(e) {
    let layerId = e.target.id;
    this.setState((state) => {
      let layers = state.layers;
      let layer = layers[layerId];
      if (layer) {
        layer.visible = !layer.visible;
      }
      if (layer.visible) {
        this.showLayer(state.map, layer);
      } else {
        this.hideLayer(state.map, layer);
      }
      return { layers };
    });
  }

  //changes the filters upon toggle clicks

  onFilterChange(e) {
    this.setState({ mapLoadingstate: 0 });

    console.log("changing filters");
    let filterId = e.target.id;
    this.setState((state) => {
      let layers = state.layers;
      let filters = state.filters;
      let filter = filters[filterId];
      filter.active = !filter.active;

      if (filter.active) {
        this.showFilter(filter, layers);
      } else {
        this.hideFilter(filter, layers);
      }

      return { filters };
    });
    this.setState({ mapLoadingstate: 100 });
  }

  //makes the given layer visible
  showLayer(map, layer) {
    if (!layer) return;
    switch (layer.type) {
      case "data":
        gmaps.showDataLayer(map, layer.layer);
        break;
      case "property_risk":
        gmaps.showDataLayer(map, layer.layer);
        break;
      case "tile":
        gmaps.showTileLayer(map, layer.layer);
        break;
      default:
    }
    // this.setState({ mapLoadingstate: 100 });
  }
  //hides the given layer

  hideLayer(map, layer) {
    if (!layer) return;
    switch (layer.type) {
      case "data":
        gmaps.hideDataLayer(map, layer.layer);
        break;
      case "property_risk":
        gmaps.hideDataLayer(map, layer.layer);
        break;
      case "tile":
        gmaps.hideTileLayer(map, layer.layer);
        break;
      default:
    }
  }

  //displays the data of the given filter

  async showFilter(filter, layers) {
    if (!filter) return;
    for (let layerId in layers) {
      let layer = layers[layerId];
      console.log(layer);

      if (!layer.filterable) continue;
      if (layer.type !== "data") {
        console.log(`Unexpected layer type: ${layer.name} - ${layer.type}`);
        return;
      }
      if (layer.name === filter.parentLayer) {
        //console.log(filter)
        // console.log(layer)
        let filterResult = await filter.func(layer.url, filter.args);
        // console.log(filterResult)
        if (layer.layer !== null) {
          layer.layer.forEach((feature) => {
            //console.log(layer.layer)
            let id = feature.getProperty("id");
            if (filterResult.indexOf(id) > -1) {
              // Only show feature if no other filter is hiding it
              let filteredBy = feature.getProperty("filteredBy");
              if (!filteredBy) filteredBy = [];
              filteredBy.splice(filteredBy.indexOf(filter.id), 1);
              feature.setProperty("filteredBy", filteredBy);
              if (filteredBy.length === 0) layer.layer.revertStyle(feature);
            }
          });
        }
      }
    }
  }

  //hides the data of the given filter

  async hideFilter(filter, layers) {
    if (!filter) return;
    for (let layerId in layers) {
      let layer = layers[layerId];
      console.log(layer);
      if (!layer.filterable) continue;
      if (layer.type !== "data") {
        //  console.log(`Unexpected layer type: ${layer.name} - ${layer.type}`);
        return;
      }
      // console.log(filter)
      // console.log(layer)

      if (layer.name === filter.parentLayer) {
        // console.log(filter)
        // console.log(layer)
        let filterResult = await filter.func(layer.url, filter.args);
        //   console.log(filterResult)
        if (layer.layer !== null) {
          layer.layer.forEach((feature) => {
            //   console.log(layer.layer)
            let id = feature.getProperty("id");
            if (filterResult.indexOf(id) > -1) {
              // Keep track of which filters are filtering this feature
              let filteredBy = feature.getProperty("filteredBy");
              if (!filteredBy) filteredBy = [];
              filteredBy.push(filter);
              feature.setProperty("filteredBy", filteredBy);
              layer.layer.overrideStyle(feature, { visible: false });
            }
          });
        }
      }
    }
  }

  //firstly sets the parent child component function, then renders the map

  render() {
    {
      this.componentload();
    }
    let layers = [];
    for (let layerId in activeLayersHistorical) {
      let layer = activeLayersHistorical[layerId];

      let filters = [];
      for (let filterId in activeFilters) {
        let filter = activeFilters[filterId];
        if (filter.parentLayer === layer.name) {
          let option = (
            <Option
              key={filterId}
              label={filter.name}
              id={filterId}
              icon={filter.icon}
              checked={this.state.filters[filterId].active}
              onChange={this.onFilterChange}
            />
          );
          filters.push(option);
        }
      }
      if (layer.toggleable == true) {
        let category = (
          <OptionCategory
            key={layerId}
            icon={layer.icon}
            label={layer.name}
            id={layerId}
            checked={this.state.layers[layerId].visible}
            onChange={this.onLayerChange}
          >
            {filters}
          </OptionCategory>
        );
        layers.push(category);
      }
    }

    return (
      <div className="app">
        <GoogleMap
          id="map"
          className="map"
          options={{
            zoom: 7,
            center: { lat: 52.8, lng: -2.5 },
            styles: styles.maps.mono,
            fullscreenControl: false,
          }}
          apiKey="AIzaSyD9n6ddk1Z6E2L5hZqCVRo94I8lbHjm30Q"
          onMapLoad={this.onMainMapLoad}
        />

        <Sidebar>{layers}</Sidebar>
      </div>
    );
  }
}

export default App;
