import React from "react";
import { OptionCategory, Option } from "../components/maps/Options";
import { Main } from "../components/maps/Main";
import { GoogleMap } from "../components/maps/GoogleMap";
import * as styles from "../components/maps/styles";
import * as gmaps from "../components/maps/gmaps";
import mediacontent_extract from "../tempjson/media.json";

import "./Operationsmap.css";

import axios from "axios";
import * as urls from "../components/maps/urls";
import * as topojson from "topojson-client";

import {
  activeFiltersOperation,
  activeLayersOperation,
} from "../components/maps/config";
import Header from "../components/Header";
import Loader from "../components/spinner";
import { Logout } from "../components/Logout";
import { connect } from "react-redux";
import { systemReduxMapHandler } from "../redux/_helpers/systemReduxMapHandler.js";
import ProgressBar from "react-bootstrap/ProgressBar";
import { RefreshAuthTokens } from "../components/RefreshAuthTokens";

const MediaItem = (props) => (
  <div className="media_item">
    <div>
      <img className="media_icon" src={props.icon} alt={props.sourcename} />
    </div>
    <div className="media_headline">{props.description}</div>
    <div>{props.keywords}</div>
    <div className="media_date">{props.date}</div>
    {props.link != undefined ? (
      <div className="newsoverlay">
        <div>
          <a onClick={() => props.handleRedirect(props.propid)}>
            Affected Property: {props.assetnumber}
          </a>
        </div>
        <a href={props.link}>View Content</a>
        <button
          onClick={() => props.removeNews(props.newsid)}
          className="remove-button"
        >
          Irrelevant
        </button>
      </div>
    ) : (
      ""
    )}
  </div>
);

class Operationsmap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      news: [],
      map: null,
      infoWindow: null,
      //alertCounts: [0, 0, 0, 0],
      noSevere: 0,
      noWarning: 0,
      noAlert: 0,
      noNews: 0,
      noAll: 0,
      selectedAlertSeverity: 0,
      alertFilterSeverity: 0,
      redirectID: 1,
      redirectLocation: null,
      redirectAlerts: [],
      redirectCountry: "",
      layers: activeLayersOperation,
      filters: activeFiltersOperation,
      weatherwarnings: [],
      isLoaded: false,
      alertsLoading: false,
      alertsReceived: false,
      mapLoadingstate: 0,
      showingNews: false,
      errMessage: "",
      current_page: this.props.location.pathname || "",

      success: true,
    };
    this.handleRedirectNews = this.handleRedirectNews.bind(this);
    this.handleRedirect = this.handleRedirect.bind(this);
    this.onMainMapLoad = this.onMainMapLoad.bind(this);
    this.getOrganisationNews = this.getOrganisationNews.bind(this);
  }

  // getSearchKeywords(){
  //   switch (this.props.userData.data.orgid) {

  //     case 0:
  //         return "Sainsburys";

  //     case 2:
  //         return "Church";

  //     case 3:
  //         return "Waitrose";

  //     case 14:
  //         return "Asda";

  //     case 30:

  //         return "Aldi";

  //     case 11:

  //         return "Lidl";

  //     case 35:

  //        return "Morrisons";

  //     case 41:
  //         return "Tesco";

  //     case 42:
  //         return "Co-op";

  //     case 4:
  //     return "Caravan";

  //     default:
  //         return "flood";
  // }
  // }
  showNotice = () => {
    // if (this.state.success === false) {
    //   return (
    //     <div className="alertbox">
    //     {this.state.errMessage}
    //   </div>
    //   )
    //   }
  };

  async handleRedirectNews(locationID) {
    let location1 = null;

    let params = { propid: locationID, org_id: this.props.userData.data.orgid };

    await axios({
      method: "get",
      url: urls.API_GATEWAY + "locations/property-specific",
      params: params,
    })
      .then((res) => {
        location1 = res.data;
        console.log(location1);

        if (
          location1 === "null" ||
          location1 === null ||
          typeof location1 === "undefined"
        ) {
          console.log("Error retrieving location");
          location1 = null;
        } else if (location1 === "Invalid Details") {
          console.log("Details for Location retrieved are INVALID");
          location1 = null;
        } else if (location1 === "Property not found") {
          console.log("No Location was Found");
          location1 = null;
          alert("No Location Found");
        }
      })
      .catch((err) => {
        location1 = null;
        console.log("FAILURE!!");
        console.log(err);
        if (!err.message) {
          console.log("Error when loading locations: ", err);
          //  Alert.alert('Error when signing in: ', err);
        } else {
          console.log("Error when loading locations: ", err.message);
          //  Alert.alert('Error when signing in: ', err.message);
          this.setState({ success: false, errMessage: "Cannot reach Server" });
        }
      });

    if (location1) {
      let payload = null;

      let data = {
        propid: locationID,
        country: location1.properties.address_country,
      };

      await axios({
        method: "post",
        url: urls.API_GATEWAY + "flood/alerts-by-property",
        data: data,
      })
        .then((res) => {
          payload = res.data;

          if (
            payload === "null" ||
            payload === null ||
            payload === "Failure" ||
            typeof payload === "undefined"
          ) {
            payload = null;
            console.log("Error retrieving property live alerts");
          } else if (payload === "Invalid Details") {
            console.log(
              "Details for property live alerts retrieved are INVALID"
            );
            payload = null;
          } else {
            console.log("SUCCESS!!");
            this.setState({ redirectAlerts: payload });
            console.log(payload);
            console.log(
              "Live Alert Data for Property retrieved from the database."
            );
          }
        })
        .catch((err) => {
          payload = null;
          console.log("FAILURE!!");
          console.log(err);
          this.setState({ redirectAlerts: [] });
        });

      this.state.redirectCountry = location1.properties.address_country;
      this.state.redirectID = location1.properties.id;

      this.state.redirectLocation = location1;

      this.props.history.push({
        pathname: "/UserDashboard",
        state: {
          redirectCountry: this.state.redirectCountry,
          redirectID: this.state.redirectID,
          historical: this.state.historical,
          alerts: this.state.redirectAlerts,
          location: this.state.redirectLocation,
          handleLogout: this.props.handleLogout,
        },
      });
    } else {
      this.state.redirectAlerts = [];
      console.log("location retrieval failed");
    }
    // this.state.redirectCountry = location1.properties.address_country;
    // this.state.redirectID = location1.properties.id;

    // this.state.redirectLocation = location1;

    // this.props.history.push({
    //   pathname: "/UserDashboard",
    //   state: {
    //     redirectCountry: this.state.redirectCountry,
    //     redirectID: this.state.redirectID,
    //     historical: this.state.historical,
    //     alerts: this.state.redirectAlerts,
    //     location: this.state.redirectLocation,
    //     handleLogout: this.props.handleLogout
    //   }
    // });
  }

  async componentDidMount() {
    let userLocations = this.props.userData.data.locations;
    this.state.locations = userLocations;
    let dataCount = null;

    await RefreshAuthTokens(this.props);

    //this.props.systemData.data.locations;
    if (
      this.props.systemData.data.alert_locations !== typeof "undefined" &&
      this.props.systemData.data.alert_locations !== null
    ) {
      console.log("adding locations");
      this.state.locationsList = this.props.systemData.data.alert_locations;
    } else {
      console.log("Error when loading alert-locations: ");
      //  Alert.alert('Error when signing in: ', err.message);
      this.setState({ success: false, errMessage: "Failed to reach Server" });
    }

    // if(this.state.noSevere &&)

    // const alertCounts = dataCount
    // this.state.alertCounts = alertCounts

    // let alertCount_url =  [urls.BACKEND, "flood", "alerts-count"].join("/");

    //   let alertLocationTableFormData = new FormData();
    //   console.log(userLocations)
    //   alertLocationTableFormData.append(
    //     "location_list",
    //     userLocations
    //   );

    //   await axios
    //     .post(alertCount_url, alertLocationTableFormData, {
    //       headers: {
    //         "Content-Type": "multipart/form-data"
    //       }
    //     })
    //     .then(res => {
    //       console.log("SUCCESS!!");
    //       console.log(res);
    //       console.log("Alert-count retrieved from the database.");
    //       dataCount = res.data;

    //     //   this.state.alerts = alertsAndLocations.alerts.features;
    //     //   this.state.locations = alertsAndLocations.locations.features;
    //     })
    //     .catch(err => {
    //       if(this.state.success !== false){
    //       console.log("Error when loading alert-count: ");
    //       //  Alert.alert('Error when signing in: ', err.message);
    //       this.setState({success:false, errMessage:"Failed to reach Server"})
    //     }
    //       dataCount = null;
    //       console.log("FAILURE!!");
    //       console.log(err);
    //     });
    // if(dataCount){
    //   const alertCounts = dataCount
    //   this.state.alertCounts = alertCounts
    //   this.state.alertCounts[3] = alertCounts[0] + alertCounts[1] + alertCounts[2]

    // }

    // let alertsToGetUrl = [urls.BACKEND, "flood", "alerts-count"].join("/");
    // console.log("alerts url" + alertsToGetUrl);

    // let alertsReceived = await axios.get(alertsToGetUrl).then(res => {
    //   const alertCounts = res.data;
    //   this.state.alertCounts = alertCounts
    //   this.state.alertCounts[3] = alertCounts[0] + alertCounts[1] + alertCounts[2]

    // });

    let orgid = this.props.userData.data.orgid;

    let keywords = this.props.userData.data.keywords;

    let secret = this.props.user.user.signInUserSession.idToken.jwtToken;

    await this.getOrganisationNews(orgid, keywords, secret);

    // let news_query = ["?org_id="+orgid+"&keywords="+keywords];
    // let news

    // let newsurl = [urls.BACKEND,"news", "news_by_organisation"+news_query].join("/");
    // console.log(newsurl);
    // let newsoutput = await axios.get(newsurl).then(res => {

    //get weather warnings for a property

    let payload = null;

    let params = { active: true };
    let weatherwarnings = null;

    await axios({
      method: "get",
      url: urls.API_GATEWAY + "flood/weather-warnings",
      params: params,
    })
      .then((res) => {
        weatherwarnings = res.data;
        console.log(weatherwarnings);
        if (
          weatherwarnings === "null" ||
          weatherwarnings === null ||
          weatherwarnings === "Failure" ||
          typeof weatherwarnings === "undefined"
        ) {
          console.log("Error retrieving weather warning");
          weatherwarnings = null;
        } else if (weatherwarnings === "No weather warnings found") {
          console.log("No weather warnings found");
          weatherwarnings = null;
        }
        this.setState({ weatherwarnings });
        console.log("weather warnings Data retrieved from the database.");
      })
      .catch((err) => {
        if (this.state.success !== false) {
          console.log("Error when loading weather warnings: ");
          //  Alert.alert('Error when signing in: ', err.message);
          this.setState({
            success: false,
            errMessage: "Failed to reach Server",
          });
        }
        weatherwarnings = null;
        console.log("FAILURE!!");
        console.log(err);
      });

    this.setState({ isLoaded: true });
  }

  async getOrganisationNews(orgid, keywords, secret) {
    let news = null;

    let data = { org_id: orgid, keywords: keywords };

    await axios({
      method: "post",
      url: urls.API_GATEWAY + "news/news-by-organisation",
      data: data,
    })
      .then((res) => {
        news = res.data;
        console.log(news);

        if (news === "null" || news === null || typeof news === "undefined") {
          console.log("Error retrieving news");
          news = null;
        } else if (news === "Invalid Details") {
          console.log("Details for news retrieval are INVALID");
          news = null;
        } else if (news === "No news found") {
          console.log("No news found for news retrieval");
          news = null;
        } else {
          console.log("SUCCESS!!");
          console.log("news Data retrieved from the database.");
        }

        if (news) {
          this.setState({ news: news });

          if (news.length) {
            this.setState({ noNews: news.length });

            let newsList = [];
            for (let new_item in news) {
              let point = {
                type: "Point",
                coordinates: [
                  news[new_item].location_lon,
                  news[new_item].location_lat,
                ],
              };
              let item = {
                type: "Feature",
                geometry: point,
                properties: news[new_item],
              };
              newsList.push(item);
            }

            this.setState({
              newsFeatureCollection: {
                type: "FeatureCollection",
                features: newsList,
              },
            });
          }
        }
      })
      .catch((err) => {
        if (this.state.success !== false) {
          console.log("Error when loading news: ");
          //  Alert.alert('Error when signing in: ', err.message);
          this.setState({
            success: false,
            errMessage: "Failed to reach Server",
          });
        }
        news = null;
        console.log("FAILURE!!");
        console.log(err);
      });
  }

  setAlertSeverity = (severity) => {
    if (this.state.selectedAlertSeverity !== severity) {
      this.state.selectedAlertSeverity = severity;

      this.setState({ alertUpdated: true });
    }
  };

  setMapNews = () => {
    if (this.state.showingNews === false) {
      this.showLayer(this.state.map, this.state.layers["news_map_layer"]);
      this.setState({ showingNews: true });
    } else {
      this.hideLayer(this.state.map, this.state.layers["news_map_layer"]);
      this.setState({ showingNews: false });
    }
  };

  // onFilterChange(e) {
  //   this.setState({ mapLoadingstate: 0 });

  //   console.log("changing filters");
  //   console.log("changin to "+e)
  //   let filterId = e;
  //   this.setState(state => {
  //     let layers = state.layers;
  //     let filters = state.filters;
  //     let filter = filters[filterId];
  //     filter.active = !filter.active;

  //     if (filter.active) {
  //       this.showFilter(filter, layers);
  //     } else {
  //       this.hideFilter(filter, layers);
  //     }

  //   });
  //   this.setState({ mapLoadingstate: 100 });
  // }

  setBubbleFilters = () => {
    // console.log(
    //   "setting filters + " +
    //     this.state.alertFilterSeverity +
    //     " + " +
    //     this.props.selectedAlertSeverity
    // );
    if (
      this.state.alertFilterSeverity !== 0 &&
      this.state.selectedAlertSeverity === 0
    ) {
      this.state.alertFilterSeverity = 0;
      //console.log("filters severity 1");
      let filterId = 0;
      let layers = this.state.layers;
      let filters = this.state.filters;
      this.setLocationLayers("all");
      for (let filter1 in filters) {
        let filter = filters[filter1];

        if (filter.parentLayer === "Flood Alerts") {
          // if (filter.args[0] === filterId) {
          if (filter.active === false) {
            filter.active = true;
            console.log("showing filter");
            console.log(filter);
            this.showFilter(filter, layers);
          }
          // } else if (filter.active === true) {
          //   //  console.log("hiding filter");
          //   //  console.log(filter);
          //   filter.active = false;
          //   this.hideFilter(filter, layers);
          // }
        }
      }
      // this.setState({filters})
    }
    if (
      this.state.alertFilterSeverity !== 1 &&
      this.state.selectedAlertSeverity === 1
    ) {
      this.state.alertFilterSeverity = 1;
      //console.log("filters severity 1");
      let filterId = 1;
      let layers = this.state.layers;
      let filters = this.state.filters;
      this.setLocationLayers("alert_properties_severe");
      for (let filter1 in filters) {
        let filter = filters[filter1];

        if (filter.parentLayer === "Flood Alerts") {
          if (filter.args[0] === filterId) {
            filter.active = true;
            // console.log("showing filter");
            // console.log(filter);
            this.showFilter(filter, layers);
          } else if (filter.active === true) {
            //  console.log("hiding filter");
            //  console.log(filter);
            filter.active = false;
            this.hideFilter(filter, layers);
          }
        }
      }
      // this.setState({filters})
    }
    if (
      this.state.alertFilterSeverity !== 2 &&
      this.state.selectedAlertSeverity === 2
    ) {
      // console.log("filters severity 2");

      this.state.alertFilterSeverity = 2;
      let filterId = 2;
      let layers = this.state.layers;
      let filters = this.state.filters;
      this.setLocationLayers("alert_properties_warning");
      // console.log(filters);
      for (let filter1 in filters) {
        let filter = filters[filter1];

        if (filter.parentLayer === "Flood Alerts") {
          if (filter.args[0] === filterId) {
            filter.active = true;
            // console.log("showing filter");
            //  console.log(filter);
            this.showFilter(filter, layers);
          } else if (filter.active === true) {
            //   console.log("hiding filter");
            //   console.log(filter);
            filter.active = false;
            this.hideFilter(filter, layers);
          }
        }
      }
      // this.setState({filters})
    }
    if (
      this.state.alertFilterSeverity !== 3 &&
      this.state.selectedAlertSeverity === 3
    ) {
      //  console.log("filters severity 3");
      this.state.alertFilterSeverity = 3;
      let filterId = 3;
      let layers = this.state.layers;
      let filters = this.state.filters;
      this.setLocationLayers("alert_properties_alert");
      for (let filter1 in filters) {
        let filter = filters[filter1];

        if (filter.parentLayer === "Flood Alerts") {
          if (filter.args[0] === filterId) {
            filter.active = true;
            //   console.log("showing filter");
            //   console.log(filter);
            this.showFilter(filter, layers);
          } else if (filter.active === true) {
            //  console.log("hiding filter");
            //  console.log(filter);
            filter.active = false;
            this.hideFilter(filter, layers);
          }
        }
      }
      // this.setState({filters})
    }
  };

  async onMainMapLoad(map) {
    this.setState({ map });
    this.state.mapLoadingstate = 0;
    console.log(this.state.locationsList);
    if (this.state.locationsList) {
      let alertLocList = [];
      let warningLocList = [];
      let severeWarningLocList = [];
      let locations = this.state.locationsList;
      console.log(locations.alerts);
      if (locations.alerts !== null) {
        this.state.alertLocations = locations.alerts;
        if (locations.alerts.features.length > 0) {
          this.state.noAlert = locations.alerts.features.length;
        }
        console.log("Testing alerts1");
      } else {
        this.state.alertLocations = {
          type: "FeatureCollection",
          features: alertLocList,
        };
      }
      if (locations.warnings !== null) {
        this.state.warningLocations = locations.warnings;
        if (locations.warnings.features.length > 0) {
          this.state.noWarning = locations.warnings.features.length;
        }
        console.log("Testing alerts3");
      } else {
        this.state.warningLocations = {
          type: "FeatureCollection",
          features: warningLocList,
        };
      }
      if (locations.severe !== null) {
        this.state.severeWarningLocations = locations.severe;
        if (locations.severe.features.length > 0) {
          this.state.noSevere = locations.severe.features.length;
        }
        console.log("Testing alerts2");
      } else {
        this.state.severeWarningLocations = {
          type: "FeatureCollection",
          features: severeWarningLocList,
        };
      }
      this.state.noAll =
        this.state.noAlert + this.state.noWarning + this.state.noSevere;
      console.log(this.state.noAlert);
      console.log(this.state.noAll);
      console.log(this.state.alertLocations);
      console.log(this.state.warningLocations);
      console.log(this.state.severeWarningLocations);
    }

    for (let layerName in this.state.layers) {
      this.setState({
        mapLoadingstate:
          this.state.mapLoadingstate +
          parseInt(100 / Object.keys(this.state.layers).length),
      });

      let layer = this.state.layers[layerName];
      switch (layer.type) {
        case "data":
          if (layer.name === "Flood Alerts") {
            let alerts = await systemReduxMapHandler.directLayer(
              this.props,
              layer,
              this.state.locations,
              this.props.user.user.signInUserSession.idToken.jwtToken,
              this.props.userData.data.organisation
            );
            console.log(alerts);
            if (alerts !== null && alerts.features) {
              let dataLayer = new window.google.maps.Data();
              console.log(alerts);
              dataLayer.addGeoJson(alerts);

              if (dataLayer) {
                if (layer.style) {
                  dataLayer.setStyle(layer.style);
                }
                if (layer.onClick) {
                  dataLayer.addListener("click", layer.onClick.bind(this));
                }

                this.setState((state) => {
                  let layers = state.layers;
                  let layer = layers[layerName];
                  layer.layer = dataLayer;

                  //Loading 20%

                  if (layer.visible) {
                    this.showLayer(state.map, layer);
                  }
                  this.state.alertsLoaded = true;
                  return layers;
                });
              }
            }
          }

          if (layer.name === "Map News" && this.state.newsFeatureCollection) {
            if (
              this.state.newsFeatureCollection.features &&
              this.state.newsFeatureCollection.features.length &&
              this.state.news !== "Failure"
            ) {
              console.log("news");
              let news_map = this.state.newsFeatureCollection;

              let dataLayer = new window.google.maps.Data();
              console.log(news_map);
              if (news_map.features) {
                dataLayer.addGeoJson(news_map);
              }
              if (dataLayer) {
                if (layer.style) {
                  dataLayer.setStyle(layer.style);
                  console.log("setting style");
                }
                if (layer.onClick) {
                  dataLayer.addListener("click", layer.onClick.bind(this));
                }
                this.setState((state) => {
                  layer.layer = dataLayer;

                  //Loading 20%

                  // this.showLayer(state.map, layer);

                  //return layers;
                });
              }
            }
          }

          break;
        case "alert-locations":
          console.log("found");

          if (
            layer.name === "alert_properties_alert" &&
            this.state.locationsList
          ) {
            console.log("list");
            if (
              this.state.alertLocations &&
              this.state.alertLocations.features &&
              this.state.alertLocations.features.length
            ) {
              console.log("alerts");
              let alert_locations = this.state.alertLocations;
              console.log(this.state.alertLocations);
              let dataLayer = new window.google.maps.Data();
              console.log(alert_locations);
              if (alert_locations.features) {
                dataLayer.addGeoJson(alert_locations);
              }
              if (dataLayer) {
                if (layer.style) {
                  dataLayer.setStyle(layer.style);
                  console.log("setting style alerts");
                }
                if (layer.onClick) {
                  dataLayer.addListener("click", layer.onClick.bind(this));
                }
                this.setState((state) => {
                  layer.layer = dataLayer;

                  //Loading 20%

                  this.showLayer(state.map, layer);

                  //return layers;
                });
              }
            }
          }
          if (
            layer.name === "alert_properties_warning" &&
            this.state.warningLocations &&
            this.state.warningLocations.features &&
            this.state.warningLocations.features.length
          ) {
            let warning_locations = this.state.warningLocations;
            console.log("warning");
            let dataLayer = new window.google.maps.Data();
            console.log(warning_locations);
            if (warning_locations.features) {
              dataLayer.addGeoJson(warning_locations);
            }
            if (dataLayer) {
              if (layer.style) {
                dataLayer.setStyle(layer.style);
                console.log("setting style");
              }
              if (layer.onClick) {
                dataLayer.addListener("click", layer.onClick.bind(this));
              }
              this.setState((state) => {
                layer.layer = dataLayer;

                //Loading 20%

                this.showLayer(state.map, layer);

                //return layers;
              });
            }
          }
          if (
            layer.name === "alert_properties_severe" &&
            this.state.severeWarningLocations &&
            this.state.severeWarningLocations.features &&
            this.state.severeWarningLocations.features.length
          ) {
            let severe_warning_locations = this.state.severeWarningLocations;
            console.log("severe");
            let dataLayer = new window.google.maps.Data();
            console.log(severe_warning_locations);
            if (severe_warning_locations.features) {
              dataLayer.addGeoJson(severe_warning_locations);
            }
            if (dataLayer) {
              if (layer.style) {
                dataLayer.setStyle(layer.style);
                console.log("setting style");
              }
              if (layer.onClick) {
                dataLayer.addListener("click", layer.onClick.bind(this));
              }
              this.setState((state) => {
                layer.layer = dataLayer;

                //Loading 20%

                this.showLayer(state.map, layer);

                //return layers;
              });
            }
          }

          break;

        case "tile":
          gmaps.tileLayerFromURL(layer.url, layer).then((tileLayer) => {
            this.setState((state) => {
              let layers = state.layers;
              let layer = layers[layerName];
              layer.layer = tileLayer;

              if (layer.visible) {
                this.showLayer(state.map, layer);
              }
              return layers;
            });
          });
          break;
        default:
      }
    }
    this.setState({ mapLoadingstate: 100 });
  }

  async handleRedirect(location1) {
    console.log(location1);
    //this.setState({ isLoading: true });

    if (location1) {
      let payload = null;

      let data = {
        propid: location1.properties.id,
        country: location1.properties.address_country,
      };

      await axios({
        method: "post",
        url: urls.API_GATEWAY + "flood/alerts-by-property",
        data: data,
      })
        .then((res) => {
          payload = res.data;

          if (
            payload === "null" ||
            payload === null ||
            payload === "Failure" ||
            typeof payload === "undefined"
          ) {
            payload = null;
            console.log("Error retrieving property live alerts");
          } else if (payload === "Invalid Details") {
            console.log(
              "Details for property live alerts retrieved are INVALID"
            );
            payload = null;
          } else {
            console.log("SUCCESS!!");
            this.setState({ redirectAlerts: payload });
            console.log(payload);
            console.log(
              "Live Alert Data for Property retrieved from the database."
            );
          }
        })
        .catch((err) => {
          payload = null;
          console.log("FAILURE!!");
          console.log(err);
          this.setState({ redirectAlerts: [] });
        });
    } else {
      this.setState({ redirectAlerts: [] });
      //this.state.redirectAlerts = [];
    }
    this.state.redirectCountry = location1.properties.address_country;
    this.state.redirectID = location1.properties.id;

    this.state.redirectLocation = location1;

    this.props.history.push({
      pathname: "/UserDashboard",
      state: {
        redirectCountry: this.state.redirectCountry,
        redirectID: this.state.redirectID,
        historical: this.state.historical,
        alerts: this.state.redirectAlerts,
        location: this.state.redirectLocation,
        handleLogout: this.props.handleLogout,
      },
    });
    //this.setState({ isLoading: false });
  }

  showNotice() {
    if (this.state.success === false) {
      return <div className="alertbox">{this.state.errMessage}</div>;
    }
  }

  async openDashboard(feature) {
    // console.log("DASHBOAAARD");
    // console.log(feature);
    // console.log(feature.getProperty("id"));
    let location = await this.getLocationData(
      feature.getProperty("id"),
      this.props.userData.data.orgid,
      this.props.user.user.signInUserSession.idToken.jwtToken
    );

    // console.log(location);

    if (location !== null) {
      this.handleRedirect(location, null);
    }

    // console.log("DASHBOAAARD");
    // console.log("Sending to property Dahboard - requires mapping to work");
  }

  setLocationLayers = (layerstring) => {
    let layers = this.state.layers;

    if (layerstring === "all") {
      for (let layer in layers) {
        if (this.state.layers[layer].type === "alert-locations") {
          layer = this.state.layers[layer];
          if (layer.toggleable === false) {
            layer.visible = true;
            //console.log("hiding layers "+layer.name)
            this.showLayer(this.state.map, layer);
          }
        }
      }
    } else {
      for (let layer in layers) {
        // console.log(this.state.layers[layer].type)
        if (layer === layerstring) {
          layer = this.state.layers[layer];
          //console.log("showing "+layer.name)
          layer.visible = true;

          this.showLayer(this.state.map, layer);
        } else if (this.state.layers[layer].type === "alert-locations") {
          layer = this.state.layers[layer];
          // console.log("TESTING"+layer.name)
          // console.log(layer.toggleable)
          if (layer.toggleable === false) {
            layer.visible = false;
            // console.log("hiding layers "+layer.name)

            this.hideLayer(this.state.map, layer);
          }
        }
      }
    }
  };

  async getLocationData(id) {
    let answer;
    // let checked = false;

    let params = { propid: id, org_id: this.props.userData.data.orgid };

    await axios({
      method: "get",
      url: urls.API_GATEWAY + "locations/property-specific",
      params: params,
    })
      .then((res) => {
        let locationData = res.data;
        console.log(locationData);

        if (
          locationData === "null" ||
          locationData === null ||
          typeof locationData === "undefined"
        ) {
          console.log("Error retrieving location");
          answer = null;
        } else if (locationData === "Invalid Details") {
          console.log("Details for Location retrieved are INVALID");
          answer = null;
        } else if (locationData === "Property not found") {
          console.log("No Location was Found");
          answer = null;
          alert("No Location Found");
        } else {
          answer = locationData;
        }
      })
      .catch((err) => {
        answer = null;
        console.log("FAILURE!!");
        console.log(err);
        if (!err.message) {
          console.log("Error when loading locations: ", err);
          //  Alert.alert('Error when signing in: ', err);
        } else {
          console.log("Error when loading locations: ", err.message);
          //  Alert.alert('Error when signing in: ', err.message);
          this.setState({ success: false, errMessage: "Cannot reach Server" });
        }
      });

    return answer;
  }

  closeDashboard(feature) {
    console.log("DASHBOAAARDCLOSE");
  }

  async showLayer(map, layer) {
    if (!layer) return;
    switch (layer.type) {
      case "data":
        gmaps.showDataLayer(map, layer.layer);

        break;
      case "alert-locations":
        gmaps.showDataLayer(map, layer.layer);

        break;
      case "tile":
        gmaps.showTileLayer(map, layer.layer);
        break;
      default:
    }
  }

  async hideLayer(map, layer) {
    if (!layer) return;
    switch (layer.type) {
      case "data":
        gmaps.hideDataLayer(map, layer.layer);

        break;
      case "alert-locations":
        gmaps.hideDataLayer(map, layer.layer);

        break;
      case "tile":
        gmaps.hideTileLayer(map, layer.layer);
        break;
      default:
    }
  }

  //displays the data of the given filter

  async showFilter(filter, layers) {
    if (!filter) return;
    for (let layerId in layers) {
      let layer = layers[layerId];
      // console.log(layer);

      if (!layer.filterable) continue;
      if (layer.type !== "data") {
        console.log(`Unexpected layer type: ${layer.name} - ${layer.type}`);
        return;
      }
      if (layer.name === filter.parentLayer) {
        //console.log(filter)
        // console.log(layer)
        let filterResult = await filter.func(
          layer.url,
          filter.args,
          this.state.locations,
          this.props.user.user.signInUserSession.idToken.jwtToken
        );
        // console.log(filterResult)
        if (layer.layer !== null) {
          layer.layer.forEach((feature) => {
            //console.log(layer.layer)
            let id = feature.getProperty("id");
            if (typeof filterResult.indexOf !== "undefined") {
              if (filterResult.indexOf(id) > -1) {
                // Only show feature if no other filter is hiding it
                let filteredBy = feature.getProperty("filteredBy");
                if (!filteredBy) filteredBy = [];
                filteredBy.splice(filteredBy.indexOf(filter.id), 1);
                feature.setProperty("filteredBy", filteredBy);
                if (filteredBy.length === 0) layer.layer.revertStyle(feature);
              }
            }
          });
        }
      }
    }
  }

  //hides the data of the given filter

  async hideFilter(filter, layers) {
    if (!filter) return;
    for (let layerId in layers) {
      let layer = layers[layerId];
      //console.log(layer);
      if (!layer.filterable) continue;
      if (layer.type !== "data") {
        //  console.log(`Unexpected layer type: ${layer.name} - ${layer.type}`);
        return;
      }
      // console.log(filter)
      // console.log(layer)

      if (layer.name === filter.parentLayer) {
        // console.log(filter)
        // console.log(layer)
        let filterResult = await filter.func(
          layer.url,
          filter.args,
          this.state.locations,
          this.props.user.user.signInUserSession.idToken.jwtToken
        );
        //   console.log(filterResult)
        if (layer.layer !== null) {
          layer.layer.forEach((feature) => {
            //   console.log(layer.layer)
            let id = feature.getProperty("id");
            if (typeof filterResult.indexOf !== "undefined") {
              if (filterResult.indexOf(id) > -1) {
                // Keep track of which filters are filtering this feature
                let filteredBy = feature.getProperty("filteredBy");
                if (!filteredBy) filteredBy = [];
                filteredBy.push(filter);
                feature.setProperty("filteredBy", filteredBy);
                layer.layer.overrideStyle(feature, { visible: false });
              }
            }
          });
        }
      }
    }
  }

  // getMediaLogo(source_name) {
  //   switch (source_name) {
  //     case 1:
  //       return require("../images/twitter_icon.png");

  //     case "The Guardian":
  //       return require("../images/guardian_logo.jpg");

  //     case "Daily Mail":
  //       return require("../images/dailymail_logo.jpg");
  //     case 4:
  //       return require("../images/cityam_logo.jpg");
  //     case 5:
  //       return require("../images/weatherforecast_logo.png");
  //     case 6:
  //       return require("../images/youtube_logo.png");
  //     case 7:
  //       return require("../images/tonbridge_logo.jpg");
  //     case "Metoffice":
  //       return require("../images/metoffice.png");
  //     case "BBC News":
  //       return require("../images/bbc_icon.png");
  //     case "ITV News":
  //       return require("../images/itv_logo.png");
  //     case "Sky News":
  //       return require("../images/skynews_logo.png");
  //     case "Metro.co.uk":
  //       return require("../images/metro_logo.png");
  //     case "GOV.UK":
  //       return require("../images/gov_logo.png");
  //     default:
  //       return require("../images/balkerne_logo.png");
  //   }
  //   return "0";
  // }

  showWeatherWarnings = () => {
    if (this.state.weatherwarnings && this.state.weatherwarnings.length) {
      return this.state.weatherwarnings.map((warning, index) => (
        <MediaItem
          key={index}
          description={warning.summary}
          link={warning.warning_link}
          icon={require("../images/metoffice.png")}
          date={warning.time_raised}
        />
      ));
    }
  };

  removeNews = async (newsid) => {
    if (newsid !== null && this.state.news) {
      const newMedia = this.state.news.filter(
        (mediaItem) => mediaItem.id !== newsid
      );

      this.setState({ news: newMedia });

      const data = { newsid: newsid };

      await axios({
        method: "post",
        url: urls.API_GATEWAY + "news/exclude",
        data: data,
      })
        .then((res) => {
          let temp_news_data = res.data;
          console.log(temp_news_data);

          if (
            temp_news_data === "null" ||
            temp_news_data === null ||
            temp_news_data === "Failure"
          ) {
            console.log("Error deleting news");
            temp_news_data = null;
          } else if (temp_news_data === "No news found") {
            console.log("No news found for deletion");
            temp_news_data = null;
          } else {
            console.log("SUCCESS!!");
            console.log("news Data deleted successfully.");
          }
        })
        .catch((err) => {
          if (this.state.success === true) {
            console.error("Error when exluding news list: ");
          }
          console.error(err);
        });
    }
  };

  showMediaItems = () => {
    if (
      this.state.news &&
      this.state.news.length &&
      this.state.news !== "Failure"
    ) {
      return this.state.news.map((newsItem, index) => (
        <MediaItem
          key={index}
          removeNews={this.removeNews}
          newsid={newsItem.id}
          sourcename={newsItem.source_name}
          description={newsItem.title}
          keywords={newsItem.filter}
          handleRedirect={this.handleRedirectNews}
          link={newsItem.link}
          icon={newsItem.image}
          date={newsItem.published}
          assetnumber={newsItem.property_reference}
          propid={newsItem.propid}
        />
      ));
    }
  };
  render() {
    {
      if (this.state.isLoaded === false) {
        return (
          <React.Fragment>
            <Loader color="#0652dd" />
          </React.Fragment>
        );
      } else {
        this.setBubbleFilters();
      }
    }
    return (
      <div className="opsapp">
        <Header
          logout={() => Logout(this.props)}
          organisation={this.props.userData.data.orgid}
          customstyle={this.props.userData.data.customstyle}
          currentpage={this.state.current_page}
          user_permissions={this.props.userData.data.permissions}
          user_portfolio_type={this.props.userData.data.portfolio_type}
        />

        <div className="pagelayout">
          <div className="boardleft">
            <div className="board_map">
              <Main>
                <GoogleMap
                  id="mapOperations"
                  className="map"
                  options={{
                    zoom: 7,
                    center: { lat: 52.8, lng: -2.5 },
                    styles: styles.maps.mono,
                    fullscreenControl: false,
                  }}
                  apiKey="AIzaSyD9n6ddk1Z6E2L5hZqCVRo94I8lbHjm30Q"
                  onMapLoad={this.onMainMapLoad}
                />
                <ProgressBar variant="info" now={this.state.mapLoadingstate} />
              </Main>
            </div>
            <div className="mediaBar">
              <div className="media_content">
                <div>
                  <h3 className="mediaBar_title">Weather Warnings</h3>
                  {this.showWeatherWarnings()}
                </div>
                <div>
                  <h3 className="mediaBar_title">Media Updates</h3>
                  {this.showMediaItems()}
                </div>
              </div>
            </div>
          </div>
          {this.showNotice()}

          <div className="opsSideBar">
            <div className="opsBubbleContainer">
              <div
                className="bubbleCritical"
                onClick={() => this.setAlertSeverity(1)}
              >
                {this.state.noSevere}
                <h6>Severe Warnings</h6>
              </div>
              <div
                className="bubbleWarning"
                onClick={() => this.setAlertSeverity(2)}
              >
                {this.state.noWarning}
                <h6>Warnings</h6>
              </div>
              <div
                className="bubbleAlert"
                onClick={() => this.setAlertSeverity(3)}
              >
                {this.state.noAlert}
                <h6>Alerts</h6>
              </div>
              <div
                className="bubbleAll"
                onClick={() => this.setAlertSeverity(0)}
              >
                {this.state.noAll}
                <h6>All</h6>
              </div>
              <div className="bubbleNews" onClick={() => this.setMapNews()}>
                {this.state.noNews}
                <h6>News</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    userLogged,
    user,
    userData,
    systemData,
    mapAlertData,
    mapLocationData,
    dashboardData,
  } = state;

  return {
    userLogged,
    user,
    userData,
    systemData,
    mapAlertData,
    mapLocationData,
    dashboardData,
  };
}
export default connect(mapStateToProps)(Operationsmap);
