export const systemConstants = {
  setSystemData: "SYSTEM_SET_SYSTEM_DATA",
  updateAlertLocations: "SYSTEM_UPDATE_ALERT_LOCATIONS",
  updateRiskTable: "SYSTEM_UPDATE_RISK_TABLE",
  updateWeatherWarnings: "SYSTEM_UPDATE_WEATHER_WARNINGS",
  clearSystemData: "SYSTEM_CLEAR_DATA",

  setDashboardData: "DASHBOARD_SET_DASHBOARD_DATA",
  updateLocationData: "DASHBOARD_UPDATE_LOCATION_DATA",

  updateLiveAlerts: "DASHBOARD_UPDATE_LIVE_ALERTS",
  updateHistoricalAlerts: "DASHBOARD_UPDATE_HISTORICAL_ALERTS",
  updateHistoricalWeatherAlerts: "DASHBOARD_UPDATE_HISTORICAL_WEATHER_ALERTS",
  updateEPC: "UPDATE_EPC",
  updatePropertyDetails: "DASHBOARD_UPDATE_PROPERTY_DETAILS",
  updateCrimeRisks: "DASHBOARD_UPDATE_CRIME_RISKS",
  updateFloodRisks: "DASHBOARD_UPDATE_FLOOD_RISKS",
  updateLossIncidents: "DASHBOARD_UPDATE_LOSS_INCIDENTS",
  // resetLossIncidents:'DASHBOARD_RESET_LOSS_INCIDENTS',
  updateMitigations: "DASHBOARD_UPDATE_MITIGATIONS",
  clearDashboardData: "DASHBOARD_CLEAR_DATA",
  updateCovidTiers: "DASHBOARD_UPDATE_COVID_TIERS",

  updateMapAlerts: "MAP_UPDATE_ALERTS",
  clearMapData: "MAP_ALERTS_CLEAR_DATA",

  updateRiskLocationsData: "MAP_UPDATE_RISK_LOCATIONS_DATA",
  clearRiskLocationsData: "MAP_RISK_LOCATIONS_CLEAR_DATA",
};
