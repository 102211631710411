import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import Form from '@rjsf/core'
import axios from 'axios'
import Card from 'react-bootstrap/Card'
import Badge from 'react-bootstrap/Badge'
import InputBox from './InputBox'
import Button from './Button'
import FormComment from './FormComment'
import { BrowserRouter as Router, Link, useHistory } from 'react-router-dom'
import AWS from 'aws-sdk'
import FileWidget from '../widgets/FileWidget'
import DateTimeWidget from '../widgets/DateTimeWidget'
import AttachedFile from './AttachedFile'
import c from './DynamicForm.module.scss'

export const DynamicForm = ({ templateSchema, uiSchema, formDetails, ...props }) => {
  //const baseurl = 'https://c2600835-8b88-409b-abd1-9b36ebc94cb5.mock.pstmn.io'
  const baseurl = 'https://r8fd6rsk66.execute-api.eu-west-1.amazonaws.com/Version1'

  const [formPage, setFormPage] = useState({ current: 1 })
  const [formdisabled, setFormDisabled] = useState(props.readonly ? true : false)
  const [alertDisplay, setAlertDisplay] = useState('none')
  const [pageButtonDisplay, setPageButtonDisplay] = useState('none')
  const [alertMessage, setAlertMessage] = useState('')
  const [alertType, setAlertType] = useState('')
  const [progressLabel, setProgressLabel] = useState('')
  const [progressDisplay, setProgressDisplay] = useState('none')
  const [displayComment, setDisplayComment] = useState('none')
  const [submitDisplay, setSubmitDisplay] = useState('none')
  const [nextButtonDisplay, setNextButtonDisplay] = useState('inline-flex')
  //const [attachedFiles, setAttachedFiles] = useState({})
  const [uploadFlag, setUploadFlag] = useState(false)
  const [testState, setTestState] = useState('Test')

  var prepareDates = []
  var attachedFiles = {}
  var attachedFilesData = {}
  var trackAttachedFiles = {}
  let formTypeID = props.typeID
  var mainFormData = props.formData
  let tempFormPages = {}
  var filesArray = []
  let comments = []
  let commentsComponent = []
  const history = useHistory()
  let savedformdata = {}
  let formschema = {}
  let uischema = {}
  let draftdata = {}
  let savedInSession = false
  let disableform = formdisabled
  let commentFlag = false
  // AWS S3 Bucket File upload start
  // Temporary
  const S3_BUCKET = 'balkerne-forms-files'
  const REGION = 'eu-west-1'

  AWS.config.update({
    accessKeyId: 'AKIA2ACGFIPPVHK3NRVG',
    secretAccessKey: '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',
  })
  const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  })
  const s3 = new AWS.S3()
  const bucketURL = 'https://' + S3_BUCKET + '.s3.' + REGION + '.amazonaws.com/'
  let uploadedFiles = []

  const getFileObject = filekey => {
    const params_get = {
      Bucket: S3_BUCKET,
      Key: filekey,
    }
    console.log(S3_BUCKET)
    myBucket.getObject(params_get, function (err, data) {
      if (err) console.log(err, err.stack)
      // an error occurred
      else console.log('Getting file: ' + filekey + ' Response: ' + data) // successful response
    })
  }
  // AWS S3 Bucket File upload end
  if (templateSchema !== undefined && uiSchema !== undefined && formDetails !== undefined) {
    //formschema = testSurvey
    //uischema = testSurveyUI
    console.log(templateSchema)
    formschema = templateSchema
    uischema = uiSchema
    console.log(formschema)
    if (props.formData !== undefined) {
      draftdata = JSON.parse(props.formData)
      commentFlag = true

      //console.log(draftdata)
    }
    savedformdata = {
      template_id: JSON.parse(formDetails).template_id,
      version_id: JSON.parse(formDetails).version_id,
      user_id: props.userData.data.id,
      propid: props.dashboardData.data.id,
      title: JSON.parse(formDetails).title,
      form_action: 'SAVED',
      form_state: 'CLOSED',
      data: '',
    }

    // Initialise formPages based on sections from formschema
    if (formschema['properties'] != undefined) {
      let page = 1
      Object.values(formschema['properties']).map(section => {
        tempFormPages[section.title] = page
        page += 1
      })
    }
  }
  useEffect(() => {
    // Adding a margin-right to all radio buttons
    const radioelements = Array.from(document.getElementsByClassName('field-radio-group'))
    const checkboxelements = Array.from(document.getElementsByClassName('field-boolean'))
    const selectelements = Array.from(document.getElementsByTagName('select'))

    radioelements.map(radioelement => {
      Array.from(radioelement.children).map(children => {
        Array.from(children.getElementsByTagName('input')).map(input => {
          input.style = 'margin-right: 5px'
        })
      })
    })
    // Adding a margin-right to all checkboxes
    checkboxelements.map(checkboxelement => {
      Array.from(checkboxelement.children).map(children => {
        Array.from(children.getElementsByTagName('input')).map(input => {
          input.style = 'margin-right: 10px'
        })
      })
    })
    // Map through each select element and set their width and margins
    selectelements.map(selectelement => {
      selectelement.style = 'margin-right: auto; margin-left: auto; width: 83.5%;'
    })

    // Check for attached files
    if (draftdata['attached_files'] != undefined) {
      // files attached
      console.log(draftdata['attached_files'])
      var attached_files = draftdata['attached_files']
      var display_files = []
      var display_filesData = []
      Object.keys(draftdata['attached_files']).map(file_inputID => {
        if (attached_files[file_inputID] != undefined) {
          Object.values(attached_files[file_inputID]).map(file => {
            var display_file = (
              <AttachedFile
                key={file.id}
                fileKey={file.id}
                filename={file.name}
                filesize={file.size}
                url={file.url}
                bucketURL={bucketURL}
                resetAttachedFiles={resetAttachedFiles}
              />
            )
            let fileDict = {
              id: file.id,
              component: display_file,
            }
            display_files.push(fileDict)
            attachedFiles[file_inputID] = display_files

            let fileData = {
              id: file.id,
              name: file.name,
              size: file.size,
              url: file.url,
            }
            display_filesData.push(fileData)
            attachedFilesData[file_inputID] = display_filesData

            if (document.getElementById(file_inputID) !== null) {
              let componentsArray = []
              display_files.map(fileItem => {
                componentsArray.push(fileItem.component)
              })
              ReactDOM.render(Object.values(componentsArray), document.getElementById(file_inputID + '-container'))
            }
          })
        }
      })
    }
    // Check for comments on form
    if (draftdata['comments'] != undefined) {
      Object.values(
        draftdata['comments'].map(comment => {
          //comment = JSON.parse(comment)
          let element = <FormComment key={comment.key} comment={comment} />
          commentsComponent.push(element)
        }),
      )
      const rootElement = document.getElementById('comments_list')
      ReactDOM.render(Object.values(commentsComponent), rootElement)
    }
    if (commentFlag) {
      setDisplayComment('block')
    }
    console.log(tempFormPages)
    if (props.formData != undefined) {
      Object.values(prepareDates).map(dateObj => {
        if (dateObj.data != undefined) {
          const tempDate = dateObj.data.split('.')
          console.log(tempDate[0])
          document.getElementById(dateObj.id).value = tempDate[0]
          if (formdisabled) {
            document.getElementById(dateObj.id).disabled = true
          }
        }
      })
    }
  })
  useEffect(() => {
    // run  on page change
    // run for surveys only
    if (props.typeID == 3) {
      setProgressDisplay('flex')
      setPageButtonDisplay('flex')
      console.log('survey')
      console.log('Next page is ' + formPage.current)
      console.log('Previous page was ' + formPage.previous)
      if (formPage.previous != undefined) {
        console.log('No prev page yet')
        document.getElementById(`page-${formPage.previous}`).style.display = 'none'
      }
      let curr_page = document.getElementById(`page-${formPage.current}`)
      if (curr_page !== null) {
        curr_page.style.display = 'block'
      }

      let progresslabel = `${formPage.current} of ${Object.values(tempFormPages).length}`
      setProgressLabel(progresslabel)
    }
  }, [formPage])
  const CustomTitleField = ({ title, required }) => {
    const legend = required ? title + '*' : title
    if (title === formschema.title) {
      //console.log("This is the first title. Skip and do not display this title")
      return <></>
    } else {
      return (
        <div className="my-1">
          <h5 style={{ fontSize: 1.25 + 'rem' }}>{legend}</h5>
        </div>
      )
    }
  }
  const CustomArrayField = ({ title, required }) => {
    const legend = required ? title + '*' : title
    if (title === formschema.title) {
      //console.log("This is the first title. Skip and do not display this title")
      return <></>
    } else {
      return (
        <div className="my-1">
          <h5 style={{ fontSize: 1.25 + 'rem' }}>{legend}</h5>
        </div>
      )
    }
  }
  const CustomDescriptionField = ({ description }) => {
    if (description !== undefined) {
      //console.log("This field has a description")
      if (description === formschema.description) {
        return <></>
      } else {
        return (
          <>
            <div style={{ marginBottom: 2, fontSize: 15 }}>{description}</div>
          </>
        )
      }
    } else {
      return <></>
    }
  }
  const DateTimeField = ({ ...props }) => {}
  const fields = {
    TitleField: CustomTitleField,
    DescriptionField: CustomDescriptionField,
  }

  const postFormData = async formData => {
    console.log('Posting form data:' + formData)
    const path = '/forms/'
    const fetchurl = baseurl + path
    formDetails = JSON.parse(formDetails)
    const data = {
      template_id: formDetails.template_id,
      version_id: formDetails.version_id,
      user_id: props.userData.data.id,
      propid: props.dashboardData.data.id,
      title: formDetails.title,
      form_action: 'SUBMITTED',
      form_state: 'CLOSED',
      data: formData,
    }
    console.log(data)
    if (formDetails.instance_id !== undefined) {
      console.log('Submitting saved form edited')
      const path = '/forms/'
      let fetchurl = baseurl + path
      fetchurl = fetchurl + JSON.stringify(formDetails.instance_id)
      await axios
        .put(fetchurl, data)
        .then(response => {
          setAlertDisplay('block')
          setAlertMessage('Form has been successfully submitted with new changes.')
          setAlertType('alert alert-success alert-dismissible fade show')
          console.log(response)
        })
        .catch(error => {
          console.error('Error submitting form data: ', error)
          setAlertDisplay('block')
          setAlertMessage('Error submitting form.')
          setAlertType('alert alert-danger alert-dismissible fade show')
        })
    } else {
      console.log('test')
      await axios
        .post(fetchurl, data)
        .then(response => {
          console.log('test2')
          console.log(response)

          setAlertDisplay('block')
          setAlertMessage('Form has been successfully submitted.')
          setAlertType('alert alert-success alert-dismissible fade show')
        })
        .catch(error => {
          console.error('Error submitting form data: ', error)
          setAlertDisplay('block')
          setAlertMessage('Error submitting form.')
          setAlertType('alert alert-danger alert-dismissible fade show')
        })
    }
  }
  const handleSubmit = async ({ formData }, e) => {
    if (!disableform) {
      // get all file inputs and check their attachment container
      const inputs = document.getElementsByTagName('input')
      let file_inputs = []
      Object.values(inputs).map(input => {
        if (input.type == 'file') {
          file_inputs.push(input)
        }
      })
      let filesdata = {}
      let filesdataarray = []
      Object.values(file_inputs).map(file_input => {
        if (file_input.id in attachedFilesData) {
          // there are attached files for this field
          Object.values(attachedFilesData[file_input.id]).map(attachedfileData => {
            filesdataarray.push(attachedfileData)
          })
          filesdata[file_input.id] = filesdataarray
        }
      })

      console.log(filesdata)
      formData['attached_files'] = filesdata

      // Attach comments to formdata
      if (comments.length > 0) {
        let commentsdata = []
        comments.map(comment => {
          commentsdata.push(comment)
        })
        console.log(commentsdata)
        formData['comments'] = commentsdata
      }
      console.log(JSON.stringify(formData))
      await postFormData(JSON.stringify(formData))
    } else {
      console.log('This form is disabled. To edit it, please press edit!')
    }
  }
  const handleSave = async () => {
    const path = '/forms/'
    let fetchurl = baseurl + path
    // if editing a saved form instance
    if (props.formData !== undefined) {
      console.log('Editing a saved form. PUT request to update draft instance')
      fetchurl = fetchurl + props.formDetails.instance_id
      await axios
        .put(fetchurl, savedformdata)
        .then(response => {
          setAlertDisplay('block')
          setAlertMessage('Form has been saved successfully.')
          setAlertType('alert alert-success alert-dismissible fade show')
          console.log(response)
        })
        .catch(error => {
          console.error('Error saving form data: ', error)
          setAlertDisplay('block')
          setAlertMessage('Error saving form.')
          setAlertType('alert alert-danger alert-dismissible fade show')
        })
    }
    // If user has already saved form in this session
    else if (savedInSession) {
      //console.log('Form already saved in this session. Editing the saved form. PUT request to update draft instance')
      fetchurl = fetchurl + savedformdata.instance_id
      await axios
        .put(fetchurl, savedformdata)
        .then(response => {
          setAlertDisplay('block')
          setAlertMessage('Form has been saved successfully.')
          setAlertType('alert alert-success alert-dismissible fade show')
          console.log(response)
        })
        .catch(error => {
          console.error('Error saving form data: ', error)
          setAlertDisplay('block')
          setAlertMessage('Error saving form.')
          setAlertType('alert alert-danger alert-dismissible fade show')
        })
    }
    // This is a new form. will need to post
    else {
      //console.log('This is a new form. Saving new draft instance')
      await axios
        .post(fetchurl, savedformdata)
        .then(response => {
          savedformdata['instance_id'] = response.data.data
          setAlertDisplay('block')
          setAlertMessage('Form has been saved successfully.')
          setAlertType('alert alert-success alert-dismissible fade show')
          console.log(response)
        })
        .catch(error => {
          console.error('Error saving form data: ', error)
          setAlertDisplay('block')
          setAlertMessage('Error saving form.')
          setAlertType('alert alert-danger alert-dismissible fade show')
        })
    }
    // Let component know that user has saved draft in this session
    // so that if they press save again, it updates the form instance
    if (!savedInSession) {
      savedInSession = true
    }
  }
  const handleChange = ({ formData, ...props }) => {
    savedformdata.data = JSON.stringify(formData)
    console.log(formData)
  }

  const displayIcons = () => {
    if (!formdisabled) {
      if (props.typeID == 3) {
        return (
          <div className={c.buttonsForm}>
            <a style={{ cursor: 'pointer', marginRight: 20 }} onClick={handleSave}>
              <img src={require('../../images/icons/Save.png')} style={{ width: 18, height: 18 }}></img>
            </a>
            <a style={{ cursor: 'pointer' }} onClick={() => history.push('/')}>
              <img src={require('../../images/icons/Close.svg')}></img>
            </a>
          </div>
        )
      }
    }
  }

  const displayButtons = () => {
    if (formdisabled) {
      return (
        <div className={c.buttonsForm}>
          <button
            className={['btn btn-primary', c.buttonOthers].join(' ')}
            type="button"
            onClick={e => editCompletedForm(e)}>
            Edit
          </button>
          <button
            onClick={() => history.go(-1)}
            className={['btn btn-secondary', c.buttonOthers].join(' ')}
            style={{ marginLeft: 'auto' }}
            type="button">
            Back
          </button>
        </div>
      )
    } else {
      console.log(props.typeID)
      if (props.typeID == 2) {
        return (
          <div className={c.buttonsForm}>
            <button
              className={['btn btn-secondary', c.buttonCancel].join(' ')}
              onClick={() => history.push('/')}
              type="button">
              Cancel
            </button>

            <button className={['btn btn-primary'].join(' ')} onClick={handleSave} type="submit">
              Save as Draft
            </button>

            <button id="original-submit" className={['btn btn-primary', c.buttonOthers].join(' ')} type="submit">
              Submit
            </button>
          </div>
        )
      }
    }
  }
  const editCompletedForm = e => {
    e.preventDefault()
    Object.values(document.getElementsByTagName('input')).map(inputelement => {
      if (inputelement.type == 'datetime-local') {
        inputelement.disabled = false
      }
    })

    setFormDisabled(false)
  }

  const widgets = {
    FileWidget: props => (
      <FileWidget onFileSelect={e => onFileSelect(e, props.onChange)} clearFileUpload={clearFileUpload} {...props} />
    ),
    DateTimeWidget: DateTimeWidget,
  }
  function CustomFieldTemplate(props) {
    const { id, classNames, label, help, required, description, errors, children } = props
    if (props.schema.type === 'object') {
      if (props.schema.title === formschema.title) {
        // Not displaying the title and description
        return (
          <div className={classNames}>
            {children}
            {errors}
            {help}
          </div>
        )
      } else {
        // Style the section titles and description here
        let sectionDisplay = 'block'
        if (formTypeID == 3) {
          sectionDisplay = 'none'
        }
        return (
          <div
            id={`page-${tempFormPages[props.schema.title]}`}
            style={{ display: sectionDisplay }}
            className={classNames}>
            {children}
            {errors}
            {help}
          </div>
        )
      }
    } else if (props.schema.type === 'boolean') {
      return (
        <div style={{ marginBottom: 0 }} className={classNames}>
          {description}
          {children}
          {errors}
          {help}
        </div>
      )
    } else if (props.schema.type === 'array' && props.schema.items !== undefined) {
      // multiple files
      return (
        <div className={classNames}>
          {children}
          {errors}
          {help}
        </div>
      )
    } else if (props.schema.type === 'string' && props.schema.format == 'data-url') {
      // single file
      return (
        <div className={classNames}>
          {children}
          {errors}
          {help}
        </div>
      )
    } else if (props.schema.format == 'date-time') {
      const tempObj = {
        id: id,
        data: props.formData,
      }
      prepareDates.push(tempObj)
      return (
        <div className={classNames}>
          <label style={{ marginLeft: 0, marginTop: 10, marginBottom: 10 }} htmlFor={id}>
            {label}
            {required ? '*' : null}
          </label>
          {description}
          {children}
          {errors}
          {help}
        </div>
      )
    } else {
      return (
        <div className={classNames}>
          <label style={{ marginLeft: 0, marginTop: 10, marginBottom: 10 }} htmlFor={id}>
            {label}
            {required ? '*' : null}
          </label>
          {description}
          {children}
          {errors}
          {help}
        </div>
      )
    }
  }
  const dismissNotification = () => {
    setAlertDisplay('none')
  }
  const uploadFile = async file => {
    let org_id = 0
    let type_id = 1
    let category_id = 3
    let subcategory_id = 6
    let url = '' + org_id + '/' + type_id + '/'
    if (category_id !== null) {
      url = url + category_id + '/'
    }
    if (subcategory_id !== null) {
      url = url + subcategory_id + '/'
    }
    const params = {
      ACL: 'public-read',
      Body: file,
      Bucket: S3_BUCKET,
      Key: url + file.name,
    }

    //let res = await putObjectWrapper(file, params)
    let putObjectPromise = myBucket.putObject(params).promise()

    await putObjectPromise
      .then(data => {
        console.log('Success: ' + data)
        //uploadedFiles.push(params.Key)
        //console.log(uploadedFiles)
        //console.log(tempFiles)
        uploadedFiles.push(params.Key)
        //setUploadedFiles([...uploadedfiles, params.Key])
      })
      .catch(error => {
        console.log('Error: ' + error)
        console.log(error.stack)
      })
    // Get a file from bucket
    /* 
   
     */
  }
  const resetAttachedFiles = (fileinputID, fileKey) => {
    console.log('Resetting: ' + fileKey + ' id: ' + fileinputID)
    // loop through files container for this file input
    // and reset attachedFiles dictionary using ID
    var tempArray = []
    Object.values(attachedFiles[fileinputID]).map(fileDict => {
      if (fileDict.id != fileKey) {
        tempArray.push(fileDict)
      }
    })
    var tempDataArray = []
    Object.values(attachedFilesData[fileinputID]).map(fileDataDict => {
      if (fileDataDict.id != fileKey) {
        tempDataArray.push(fileDataDict)
      }
    })
    attachedFiles[fileinputID] = tempArray
    attachedFilesData[fileinputID] = tempDataArray
    console.log(attachedFiles[fileinputID])
  }
  const onFileSelect = async (e, handleChange) => {
    //e.preventDefault()
    const file = e.target.files[0]
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.addEventListener(
      'load',
      function () {
        // convert image file to base64 string
        handleChange([reader.result])
      },
      false,
    )

    let fileinput = e.target
    await uploadFiles(e).then(() => {
      console.log('Clearing file input')
      //fileinput.value = null
    })
  }
  const clearFileUpload = e => {
    e.target.value = ''
  }
  const uploadFiles = async e => {
    var file_input = e.target
    console.log('File changed')
    var display_files = []
    var display_filesData = []
    var key = 1

    if (file_input.id in attachedFiles) {
      display_files = [...attachedFiles[file_input.id]]
      display_filesData = [...attachedFilesData[file_input.id]]
      // console.log(display_files)
      key = attachedFiles[file_input.id].length + 1
    }

    Array.from(e.target.files).map(async selectedfile => {
      console.log('File: ' + String(selectedfile.name) + ' size: ' + selectedfile.size + ' key:' + key)

      await uploadFile(selectedfile)
        .then(() => {
          //console.log(uploadedFiles)

          var display_file = (
            <AttachedFile
              key={key}
              fileKey={key}
              filename={selectedfile.name}
              filesize={selectedfile.size}
              url={uploadedFiles[uploadedFiles.length - 1]}
              bucketURL={bucketURL}
              resetAttachedFiles={resetAttachedFiles}
            />
          )
          console.log(display_file)
          let fileDict = {
            id: key,
            component: display_file,
          }
          display_files.push(fileDict)
          attachedFiles[file_input.id] = display_files

          let fileData = {
            id: key,
            name: selectedfile.name,
            size: selectedfile.size,
            url: uploadedFiles[uploadedFiles.length - 1],
          }
          display_filesData.push(fileData)
          attachedFilesData[file_input.id] = display_filesData

          if (document.getElementById(file_input.id) !== null) {
            //console.log(display_files)
            let componentsArray = []
            display_files.map(fileItem => {
              componentsArray.push(fileItem.component)
            })
            ReactDOM.render(Object.values(componentsArray), document.getElementById(file_input.id + '-container'))
          }

          key += 1
        })
        .catch(error => {
          console.error(error, error.stack)
        })
    })
  }

  const addComment = () => {
    let comment = document.getElementById('comment_textbox').value

    if (comment.length > 0) {
      const currentDate = new Date()
      const commentkey = comments.length

      const newComment = {
        key: commentkey,
        full_name: props.userData.data.full_name,
        email: props.userData.data.email,
        comment: comment,
        date_created: currentDate,
      }
      comments.push(newComment)

      // add comment to card
      const commentElement = <FormComment key={commentkey} comment={newComment} />
      console.log(commentElement)
      commentsComponent.push(commentElement)
      //console.log(commentElement)
      const rootElement = document.getElementById('comments_list')
      console.log(commentsComponent)
      ReactDOM.render(Object.values(commentsComponent), rootElement)
      //let commentslist = Object.values(rootElement)
      //console.log(elements)
      //elements.push(commentElement)
      document.getElementById('comment_textbox').value = ''
    }
  }
  const changePage = (key, e) => {
    e.preventDefault()
    let currPage = formPage.current
    let lastPage = formPage.current
    switch (key) {
      case 'prev':
        if (currPage > 1) {
          if (currPage == Object.values(tempFormPages).length) {
            setNextButtonDisplay('inline-flex')
            setSubmitDisplay('none')
          }
          currPage -= 1
          setFormPage({
            current: currPage,
            previous: lastPage,
          })
        }
        break
      case 'next':
        if (currPage < Object.values(tempFormPages).length) {
          console.log('this it the object.values tempformpages.length' + Object.values(tempFormPages).length)
          currPage += 1
          if (currPage == Object.values(tempFormPages).length) {
            setNextButtonDisplay('none')
            setSubmitDisplay('inline-flex')
          }
          setFormPage({
            current: currPage,
            previous: lastPage,
          })
        }

        break
    }
  }
  const removeAttachedFile = e => {
    e.preventDefault()
    let attached_files = document.getElementById('attached_files')
    attached_files.removeChild(e.currentTarget.parentNode)
  }
  const pressSubmit = () => {
    const button = document.getElementById('original-submit')

    if (button) {
      button.click()
    }
  }
  const onError = errors => {
    document.getElementById(`page-${Object.values(tempFormPages).length}`).style.display = 'block'
    console.error('Form validation failed', errors)
  }
  return (
    <>
      <div className={c.progressBadge} style={{ display: progressDisplay }}>
        <Badge pill variant="primary">
          Step {progressLabel}
        </Badge>
      </div>
      <Card>
        <Card.Header>
          {formschema.title} - {formschema.description}
        </Card.Header>
        <Card.Body>
          <div className={c.containerForm}>
            <div>{displayIcons()}</div>
            <Form
              disabled={formdisabled}
              fields={fields}
              widgets={widgets}
              schema={formschema}
              uiSchema={uischema}
              onSubmit={handleSubmit}
              onChange={handleChange}
              onError={onError}
              noHtml5Validate
              formData={draftdata}
              showErrorList={false}
              FieldTemplate={CustomFieldTemplate}>
              <div style={{ display: 'block' }}>
                <div style={{ display: alertDisplay }}>
                  <div className={alertType} style={{ textAlign: 'center' }} role="alert">
                    {alertMessage}
                    <button type="button" className="close" onClick={dismissNotification} aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </div>
              </div>
              {displayButtons()}
            </Form>

            <div id="display_attached_files"></div>
          </div>
        </Card.Body>
      </Card>
      <div
        style={{
          display: pageButtonDisplay,
        }}
        className={c.containerNextPrevious}>
        <Button onClick={e => changePage('prev', e)}>Previous</Button>
        <Button style={{ display: nextButtonDisplay }} onClick={e => changePage('next', e)}>
          Next
        </Button>
        <Button style={{ display: submitDisplay }} onClick={pressSubmit}>
          Submit
        </Button>
      </div>
      {/* <button onClick={testSubmit}> Test submit functionality </button> */}
      <Card style={{ marginTop: '30px', display: displayComment }}>
        <Card.Body>
          <div id="comments_list"></div>

          <div className={c.containerComments}>
            <InputBox type="text" name="comment" label="Comment" id="comment_textbox"></InputBox>
            <Button onClick={addComment} type="submit" className={['btn btn-primary', c.buttonAddComment].join(' ')}>
              Add Comment
            </Button>
          </div>
        </Card.Body>
      </Card>
    </>
  )
}

export default DynamicForm
