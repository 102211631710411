import React from "react";
import "./PropertiesInputs.css";
import AddressLookup from "./AddressLookup";
import { Form, Row, Col, Dropdown, Card } from "react-bootstrap";
import Button from "balkerne-components/Button";

const PropertiesInputs = (props) => {
  // render a whole row for the user with all the values from the dataset
  return props.properties.map((val, index) => {
    var name = "name-" + { index },
      address = "address-" + { index },
      country = "country-" + { index };
    return (
      <div
        key={props.properties[index].id}
        className="d-flex justify-content-between pb-4"
      >
        <Card key={props.properties[index].id} id={props.properties[index].id}>
          {props.properties[index] &&
          props.properties[index].processed &&
          props.properties[index].address ? (
            <Card.Header className=" d-flex justify-content-between bg-primary text-white">
              <div>
                {props.properties[index] &&
                props.properties[index].processed &&
                props.properties[index].address ? (
                  <div>{props.properties[index].address}</div>
                ) : (
                  <div>Property {index + 1}</div>
                )}
              </div>

              {index > 0 ? (
                <div className="float-right">
                  <Button
                    size="tiny"
                    light
                    onClick={() => props.removePropertyIndex(index)}
                  >
                    ✘
                  </Button>
                </div>
              ) : (
                ""
              )}
            </Card.Header>
          ) : (
            <Card.Header className=" d-flex justify-content-between">
              <div>
                {props.properties[index] &&
                props.properties[index].processed &&
                props.properties[index].address ? (
                  <div>{props.properties[index].address} </div>
                ) : (
                  <div>Property {index + 1}</div>
                )}
              </div>

              {index > 0 ? (
                <div className="float-right">
                  <Button
                    size="tiny"
                    light
                    onClick={() => props.removePropertyIndex(index)}
                  >
                    ✘
                  </Button>
                </div>
              ) : (
                ""
              )}
            </Card.Header>
          )}

          <Card.Body>
            {/* 
<button id="delete" type="button" id="property-remove-button"className="btn btn-primary btn-lg "><span class="glyphicon glyphicon-remove"/></button> */}

            <Form key={props.properties[index].id}>
              <Row
                key={props.properties[index].id}
                className="align-items-center"
              >
                <Col xs="auto">
                  <Form.Group
                    controlId={props.properties[index].id}
                    className="pr-3"
                  >
                    <Form.Label>Name:</Form.Label>
                    <Form.Control
                      value={props.properties[index].name}
                      className="property-name mr-5"
                      type="name"
                      onChange={(e) => props.handleChange(e, index)}
                      placeholder="Company HQ, Factory, Branch"
                    />
                  </Form.Group>
                </Col>
                <Col xs="auto">
                  <Form.Group
                    controlId={props.properties[index].id}
                    className="pr-3 pl-3"
                  >
                    <Form.Label>Country:</Form.Label>
                    <Form.Control
                      value={props.properties[index].country}
                      size="sm"
                      className="property-country mr-5"
                      as="select"
                      onChange={(e) => props.handleChange(e, index)}
                    >
                      <optgroup label="United Kingdom: ">
                        <option defaultValue value="England">
                          England
                        </option>
                        <option value="Wales">Wales</option>
                        <option value="Scotland">Scotland</option>
                      </optgroup>

                      <optgroup label="Europe: ">
                        <option value="Netherlands">Netherlands</option>
                        <option value="Poland">Poland</option>
                        <option value="France">France</option>
                        <option value="Germany">Germany</option>
                      </optgroup>
                    </Form.Control>
                  </Form.Group>
                </Col>

                <AddressLookup
                  key={props.properties[index].id}
                  updatePropAddress={props.updatePropAddress}
                  index={index}
                  country={props.properties[index].country}
                  id={props.properties[index].id}
                  address={props.properties[index].address}
                />
              </Row>
            </Form>
          </Card.Body>
        </Card>
      </div>
    );
  });
};
export default PropertiesInputs;
