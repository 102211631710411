import React from "react";
import AddUsers from "../components/userManagement/AddUsers";
import AssignUsers from "../components/userManagement/AssignUsers";
import AssignUsersSingle from "../components/userManagement/AssignUsersSingle";
import AssignUsersLastStep from "../components/userManagement/AssignUsersLastStep";
import axios from "axios";
import * as urls from "../components/maps/urls";
import { Auth } from "aws-amplify";
import { connect } from "react-redux";
import { Logout } from "../components/Logout";
import { RefreshAuthTokens } from "../components/RefreshAuthTokens";
import Header from "../components/Header";
import Loader from "../components/spinner";
import PageContainer from "balkerne-components/Container";
import PageHeader from "balkerne-components/PageHeader";
import Button from "balkerne-components/Button";
import Badge from "balkerne-components/Badge";
import Select from "balkerne-components/Select";
import Card from "react-bootstrap/Card";
import "./MainAddUsers.css";

class MasterForm extends React.Component {
  constructor(props) {
    super(props);
    //initial cunstructor for the master class containng the 3 forms
    this._next = this._next.bind(this);
    this._prev = this._prev.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.sendEmail = this.sendEmail.bind(this);
    this.postDatabaseUser = this.postDatabaseUser.bind(this);
    this.addUsersToDatabaseAndCognito = this.addUsersToDatabaseAndCognito.bind(
      this
    );

    this.state = {
      currentStep: 1, // first Step 1
      //data arrays
      users: [],
      locations: [],
      addUsersList: [],
      selectedAddUsers: [],
      //selections in assign users form
      selectedLocations: [],
      selectedUsers: [],
      selectedLocationsIndex: 0,
      selectedUser: { fullName: "", email: "" },
      selectedUserIndex: 0,
      selectedAddUser: { fullName: "", email: "" },
      selectedAddUserIndex: 0,
      ctrl: false,
      shifts: false,
      assignedPropertyManagers: [],

      org_id: 2,
      current_user_id: 2,
      role: null,
      portfolio_type: null,

      //error displaying
      errorArray: [],
      errorText: "",
      isLoading: false,
      errMessage: "",
      loadingstate: 0,
      success: true,
      event_message:
        "Assigned users will be shown when a single property is selected.",
    };
    this.getOrganisationLocations = this.getOrganisationLocations.bind(this);
  }

  async getOrganisationLocations(secret, org_id) {
    let locations = [];

    let params = { org_id: org_id };

    await axios({
      method: "get",
      url: urls.API_GATEWAY + "locations/property",
      params: params,
    })
      .then((res) => {
        locations = res.data;
        console.log(locations);

        if (
          locations === "null" ||
          locations === null ||
          typeof locations === "undefined"
        ) {
          console.log("Error retrieving locations");
          locations = [];
        } else if (locations === "Invalid Details") {
          console.log("Details for Location retrieval are INVALID");
          locations = [];
        } else if (locations === "No properties found") {
          console.log("No properties was Found");
          locations = [];
        } else if (!Array.isArray(locations)) {
          console.log("Location retrieval ERROR");
          locations = [];
        } else {
          console.log("Properties succesfully retrieved");
        }

        this.setState({ locations });
      })
      .catch((err) => {
        locations = [];
        console.log("FAILURE!!");
        console.log(err);
        //alert("No connection to the server")
        if (!err.message) {
          console.log("Error when getting locations: ", err);
          //  Alert.alert('Error when signing in: ', err);
        } else {
          console.log("Error when getting locations: ", err.message);
          //  Alert.alert('Error when signing in: ', err.message);
          this.setState({
            success: false,
            errMessage: "Could not retrieve locations. (Server)",
          });
        }
      });
  }

  async componentDidMount() {
    console.log(this.props.userData);
    this.state.org_id = this.props.userData.data.orgid;
    this.state.current_user_id = this.props.userData.data.id;
    this.state.role = this.props.userData.data.role;
    this.state.portfolio_type = this.props.userData.data.portfolio_type;
    this.state.user_permissions = this.props.userData.data.permissions;

    await RefreshAuthTokens(this.props);

    //DISPALY REGION, customer prop reference, name, address, postcode

    await this.getOrganisationLocations(
      this.props.user.user.signInUserSession.idToken.jwtToken,
      this.props.userData.data.orgid
    );

    this.setState({ mounted: true });
  }

  async checkDatabase(email1) {
    let success = true;
    let checked = "exists";
    let password = null;

    let email = email1;
    if (email && email.length > 3) {
      email = email.toLowerCase();
    }

    let params = { email: email };

    await axios({
      method: "get",
      url: urls.API_GATEWAY + "users/check-email",
      params: params,
    })
      .then((res) => {
        checked = res.data;
        if (
          checked === "null" ||
          checked === null ||
          typeof checked === "undefined"
        ) {
          checked = null;
          console.log("FAILURE!!");
        }
      })
      .catch((err) => {
        success = false;
        console.log("FAILURE!!");
        console.log(err);
        //alert("No connection to the server")
        if (!err.message) {
          console.log("Error when checking emails: ", err);
          //  Alert.alert('Error when signing in: ', err);
        } else {
          console.log("Error when loading working connection: ", err.message);
          //  Alert.alert('Error when signing in: ', err.message);
          this.setState({ success: false, errMessage: "Cannot reach Server" });
        }
      });

    if (checked === "available") {
      console.log("email succeeded " + email);

      return true;
      // this.postDatabase();
    } else {
      console.log("email failed " + email);

      return false;
    }
  }

  //function moving the user to the next form
  _next() {
    console.log(this.state.users);

    let currentStep = this.state.currentStep;

    if (currentStep === 1) {
      //validate added users
      this.validateAddUsers(currentStep);
    } else {
      // If the current step is 1 or 2, then add one on "next" button click
      currentStep = currentStep >= 2 ? 3 : currentStep + 1;
      this.setState({
        currentStep: currentStep,
      });
      this.setState({
        errorText: "",
      });
    }
  }

  //function moving the user to the previous form
  _prev() {
    let currentStep = this.state.currentStep;
    // If the current step is 2 or 3, then subtract one on "previous" button click
    if (currentStep === 3 && this.state.portfolio_type === "Single") {
      this.setState({
        currentStep: 1,
      });
    } else {
      currentStep = currentStep <= 1 ? 1 : currentStep - 1;
      this.setState({
        currentStep: currentStep,
      });
    }
  }

  //Validates the assigning (Adding users to location that same user can't get added twice to the same location)
  validateAssignUsers(user, location) {
    let validate = true;
    let errorMessage = [];

    if (
      user &&
      (user.role === "Property Manager" || user.role === "Property Admin") &&
      this.state.assignedPropertyManagers.indexOf(user.email) >= 0
    ) {
      console.log("invalidated");
      validate = false;
      errorMessage.push(
        "User '" +
          user.full_name +
          "' is already assigned a location. (Property Managers can only have one)\n"
      );
    } else {
      if (location.assignedusers && location.assignedusers.length) {
        console.log("CHECKING");
        for (var i = 0; i < location.assignedusers.length; i++) {
          console.log("CHECKING2");
          console.log(user.email);
          console.log(location.assignedusers[i].email);
          //Checks if addUsers list already has the selected person in the list
          if (user.email === location.assignedusers[i].email) {
            console.log("invalidated");
            validate = false;
            errorMessage.push(
              "User '" +
                location.assignedusers[i].full_name +
                "' is already assigned to this location.\n"
            );
          }
        }
      }
    }

    //return true or false as well as printing an error
    if (validate === true) {
      if (
        user &&
        (user.role === "Property Manager" || user.role === "Property Admin")
      ) {
        this.state.assignedPropertyManagers.push(user.email);
        this.setState({
          assignedPropertyManagers: this.state.assignedPropertyManagers,
        });
      }

      this.setState({
        errorText: "",
      });
      return true;
    } else {
      this.setState({
        errorText: this.printError(errorMessage),
      });
      return false;
    }
  }

  async sendEmail(email1, password) {
    let success = true;
    let secret = this.props.user.user.pool.userPoolId;
    let email = email1;
    if (email && email.length > 3) {
      email = email.toLowerCase();
    }
    let data = { email: email, password: password, secret: secret };
    await axios({
      method: "post",
      url: urls.API_GATEWAY + "users/send-temp-psw",
      data: data,
    })
      .then((res) => {
        console.log(res.data);
        let response = res.data;

        if (
          response === "null" ||
          response === null ||
          response === "Failure" ||
          typeof response === "undefined"
        ) {
          success = false;
          console.log("Email sending FAILURE");
        } else if (response === "Invalid Details") {
          console.log("Details for sending temp emails are INVALID");
          success = false;
        } else {
          success = true;
          console.log("SUCCESS!! PSW successfully sent.");
          console.log(res.data);
        }
      })
      .catch((err) => {
        success = false;
        console.log("FAILURE!!");
        console.log(err);
        this.setState({
          errorText: "Database email sending error",
        });
      });
    return success;
  }

  async checkConnection(secret) {
    let success = false;
    await axios({ method: "get", url: urls.API_GATEWAY + "users/working" })
      .then((res) => {
        if (res.data === "Success") {
          success = true;
          console.log("connection working");
        }
      })
      .catch((err) => {
        success = false;
        console.log("FAILURE!!");
        console.log(err);
        alert("No connection to the server");
        if (!err.message) {
          console.log("Error when loading locations: ", err);
          //  Alert.alert('Error when signing in: ', err);
        } else {
          console.log("Error when loading working connection: ", err.message);
          //  Alert.alert('Error when signing in: ', err.message);
          this.setState({ success: false, errMessage: "Cannot reach Server" });
        }
      });
    return success;
  }

  //Validates the form for adding users - check if all fields are filled in, emails and phone numbers are valid, and if no users have the same email and name
  async validateAddUsers(currentStep) {
    let validate = true;
    let errorMessage = [];

    //regex for validating emails and phoneNumbers - not final!!!
    var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    let phoneRegex = /^(?:0|\+?44)(?:\d\s?){9,10}$/;

    for (var i = 0; i < this.state.users.length; i++) {
      //checks for same names and emails
      for (var x = 0; x < this.state.users.length; x++) {
        if (x !== i) {
          if (
            this.state.users[i].full_name === this.state.users[x].full_name &&
            this.state.users[i].email === this.state.users[x].email
          ) {
            if (validate === true) {
              errorMessage.push(
                "Users " +
                  (i + 1) +
                  " and " +
                  (x + 1) +
                  " cannot have identical names and emails.\n"
              );
            }
            validate = false;
          }
        }
      }
      //test if any fields are undefined or empty (missing) as well as checking if email and phone number are valid
      if (
        typeof this.state.users[i].full_name === "undefined" ||
        this.state.users[i].full_name.length < 1
      ) {
        validate = false;
        errorMessage.push(
          "User " + (i + 1) + " field 'Full Name' cannot be empty.\n"
        );
      }
      if (
        typeof this.state.users[i].email === "undefined" ||
        this.state.users[i].email.length < 1
      ) {
        validate = false;
        errorMessage.push(
          "User " + (i + 1) + " field 'Email' cannot be empty.\n"
        );
      } else if (!emailRegex.test(this.state.users[i].email.toLowerCase())) {
        validate = false;
        errorMessage.push("User " + (i + 1) + " field 'Email' is not valid.\n");
      }

      //phone validation
      if (
        typeof this.state.users[i].phone !== "undefined" &&
        this.state.users[i].phone !== null &&
        this.state.users[i].phone.length > 0
      ) {
        if (!phoneRegex.test(this.state.users[i].phone)) {
          validate = false;
          errorMessage.push(
            "User " + (i + 1) + " field 'Phone Number' is not valid.\n"
          );
        }
        if (this.state.users[i].phone.toString().substring(0, 1) === "0") {
          this.state.users[i].phone = this.state.users[i].phone =
            "+44" + this.state.users[i].phone.slice(1);
        }
      }

      if (
        typeof this.state.users[i].position === "undefined" ||
        this.state.users[i].position.length < 1
      ) {
        validate = false;
        errorMessage.push(
          "User " + (i + 1) + " field 'Position' cannot be empty.\n"
        );
      }
      if (
        typeof this.state.users[i].role === "undefined" ||
        this.state.users[i].role.length < 1
      ) {
        validate = false;
        errorMessage.push(
          "User " + (i + 1) + " field 'Role' cannot be empty.\n"
        );
      }
    }

    //checking if the whole form is empty
    if (this.state.users.length < 1) {
      validate = false;
      errorMessage.push("Add Users first.");
    }

    if (validate === true) {
      let validateDatabase = true;
      for (var i = 0; i < this.state.users.length; i++) {
        validateDatabase = await this.checkDatabase(this.state.users[i].email);
        if (validateDatabase === false && this.state.success === false) {
          validate = false;
          errorMessage.push("There is no connection to the server.");
        } else if (validateDatabase === false) {
          validate = false;
          errorMessage.push(
            "The following email is already on the system: " +
              this.state.users[i].email
          );
        }
      }
    }

    //if everything valid, move to the next form, if not then print an error message.
    if (validate === true) {
      if (this.state.portfolio_type == "Single") {
        currentStep = currentStep >= 1 ? 3 : currentStep + 1;
        this.setState({
          currentStep: currentStep,
        });
        this.setState({
          errorText: "",
        });
      } else {
        currentStep = currentStep >= 2 ? 3 : currentStep + 1;
        this.setState({
          currentStep: currentStep,
        });
        this.setState({
          errorText: "",
        });
      }
    } else {
      this.setState({
        errorText: this.printError(errorMessage),
      });
    }
  }

  // Use the submitted data to set the state
  handleChange(name, value) {
    this.setState({
      [name]: value,
    });
  }

  // trigger submission (saving to database later - saving) - prints dataset values at the moment
  async handleSubmit(event) {
    event.preventDefault();
    let usersAddedSuccess = false;
    let mappingsAddedSuccess = false;
    let answer = await this.checkConnection(
      this.props.user.user.signInUserSession.idToken.jwtToken
    );
    if (answer === true) {
      if (
        this.state.users &&
        this.state.users.length === 1 &&
        this.state.portfolio_type != "Single"
      ) {
        if (
          this.state.selectedLocations &&
          this.state.selectedLocations.length >= 1
        ) {
          for (var location1 in this.state.selectedLocations) {
            let selected_location = this.state.selectedLocations[location1];
            var location = this.state.locations.find((location) => {
              return selected_location === location.id;
            });
            if (location) {
              location.assignedusers = [this.state.users[0]];
            } else {
              console.log("selected location is null");
            }
          }
          // this.setState({ addUsers: this.state.addUsersList })
        }
      } else if (
        this.state.users &&
        this.state.locations &&
        this.state.locations.length > 0 &&
        this.state.portfolio_type === "Single"
      ) {
        let location = this.state.locations[0];

        // console.log(location)
        // console.log(this.state.users[0].email)
        for (var user in this.state.users) {
          var user = this.state.users[user];
          // console.log(user)
          location.assignedusers = [user];
        }
      } else {
        console.log("not a single user or single portfolio");
      }

      usersAddedSuccess = await this.addUsersToDatabaseAndCognito();

      if (usersAddedSuccess === true) {
        let payload = {
          user_list: [],
          location_list: [],
        };
        for (var location1 in this.state.locations) {
          var location = this.state.locations[location1];
          if (location.assignedusers && location.assignedusers.length) {
            console.log("found location");
            console.log("location " + location.name);

            for (var assignedUser1 in location.assignedusers) {
              var assignedUser = location.assignedusers[assignedUser1];
              console.log(assignedUser);
              for (var user in this.state.users) {
                var user = this.state.users[user];
                console.log(user);
                if (user.email === assignedUser.email) {
                  console.log(user.email);
                  console.log(user.id);
                  payload.user_list.push(user.id);
                }
              }

              payload.location_list.push(location.id); //// OR ID HMMMMMMMMMMMMMm ARE U SUREEEEEEE
            }
          }
        }
        console.log("submited");
        console.log(this.state.users);
        console.log(this.state.locations);
        console.log(payload);

        mappingsAddedSuccess = await this.postDatabaseUserPropertyMatch(
          payload
        );
        if (mappingsAddedSuccess === true) {
          console.log("ADDING USERS COMPLETE!!!!!!!!");
          alert("Users Succesfully added.");
          this.props.history.push({
            pathname: "/",
            state: { org_id: this.state.org_id },
          });
        } else {
          console.log("adding USER PROPERTY MATCHES FAILED");
        }
      } else {
        console.log("FAILED VIA USERS");
        console.log(this.state.users);
        console.log(this.state.locations);
        this.setState({
          errorText: this.state.errorText,
        });
      }
    } else {
      alert("No connection to Server");
    }
  }

  async postDatabaseUserPropertyMatch(user_locations_map) {
    let success = true;

    if (
      user_locations_map.location_list &&
      user_locations_map.location_list.length > 0
    ) {
      let data = {
        user_list: user_locations_map.user_list,
        location_list: user_locations_map.location_list,
      };

      await axios({
        method: "post",
        url: urls.API_GATEWAY + "users/map-user-locations",
        data: data,
      })
        .then((res) => {
          console.log(res.data);
          let response = res.data;

          if (
            response === "null" ||
            response === null ||
            response === "Failure" ||
            typeof response === "undefined"
          ) {
            success = false;
            console.log("Mapping users FAILURE");
          } else if (
            response === "Invalid Details" ||
            response === "No locations or users"
          ) {
            console.log(
              "Details for mapping users to locations are INVALID or NO LOCATIONS OR USERS"
            );
            success = false;
          } else if (response == "Success") {
            success = true;
            console.log("SUCCESS!!");
            console.log("PROPERTY-USER  Successfully added to database");
          } else {
            console.log("FAILURE, UNKNOWN RESPONSE");
            success = false;
          }
        })
        .catch((err) => {
          console.log("FAILURE!!");
          console.log(err);
          this.setState({
            errorText: "Database adding error",
          });
          success = false;
        });

      return success;
    } else {
      return true;
    }
  }

  async addUsersToDatabaseAndCognito() {
    let success = true;

    for (var user in this.state.users) {
      success = await this.checkCognito(this.state.users[user]);
      console.log(success);
      if (success === false) {
        console.log("Adding users failed.");
        return false;
      }
    }
    console.log(
      "Successfully added all the users to the database and cognito."
    );
    return true;
  }

  password_generator = () => {
    var length = 12;
    var string = "abcdefghijklmnopqrstuvwxyz"; //to upper
    var numeric = "0123456789";
    var punctuation = "!@#$%^&*";
    var password = "";
    var character = "";
    var crunch = true;
    while (password.length < length) {
      let entity1 = Math.ceil(string.length * Math.random() * Math.random());
      let entity2 = Math.ceil(numeric.length * Math.random() * Math.random());
      let entity3 = Math.ceil(
        punctuation.length * Math.random() * Math.random()
      );
      let hold = string.charAt(entity1);
      hold = password.length % 2 === 0 ? hold.toUpperCase() : hold;
      character += hold;
      character += numeric.charAt(entity2);
      character += punctuation.charAt(entity3);
      password = character;
    }
    password = password
      .split("")
      .sort(function () {
        return 0.5 - Math.random();
      })
      .join("");
    return password.substr(0, length);
  };

  async checkCognito(details) {
    let success = true;
    details.password = this.password_generator();
    console.log(details.phone);

    let email = details.email;
    if (email && email.length > 3) {
      email = email.toLowerCase();
    }

    await Auth.signUp({
      username: email,
      password: details.password,
      attributes: {
        email: email,
      },
      region: "region eu-west-1",
    })
      .then((data) => {
        console.log(data);
        console.log("success cognito");
        console.log(email);

        details.aws_id = data.userSub;
        success = true;
      })
      .catch((err) => {
        console.log(err);
        console.log("Adding users - cognito failed at user adding " + email);
        success = false;
        if (err.code === "UsernameExistsException") {
          this.state.errorText = "User email" + email + "already exists";
        } else {
          this.state.errorText = "Cognito adding error";
        }
      });

    if (success === true) {
      success = await this.sendEmail(email, details.password);
      console.log(success);
      if (success) {
        success = await this.postDatabaseUser(details);
        console.log(success);
      }

      console.log(success);
    }

    return success;
  }

  async postDatabaseUser(user) {
    console.log(user);
    let success = true;
    let data = {};

    Object.assign(data, { org_id: this.state.org_id });
    Object.assign(data, { password: user.password });
    Object.assign(data, { manager: this.state.current_user_id });
    console.log(data);

    for (var [key, value] of Object.entries(user)) {
      console.log(key + " " + value);
      if (key === "phone") {
        if (
          typeof value === "undefined" ||
          value === null ||
          value.length < 3
        ) {
          Object.assign(data, { [key]: "" });
          console.log(key);
          console.log("empty phone");
        } else {
          Object.assign(data, { [key]: value });
          console.log(key);
          console.log(value);
          console.log(data);
        }
      } else if (key === "email") {
        let email = value;
        if (email && email.length > 3) {
          email = email.toLowerCase();
        }
        Object.assign(data, { [key]: email });
        console.log(key);
        console.log(value);
        console.log(data);
      } else {
        Object.assign(data, { [key]: value });
        console.log(key);
        console.log(value);
        console.log(data);
      }
    }

    console.log(data);

    await axios({
      method: "post",
      url: urls.API_GATEWAY + "users/add-user",
      data: data,
    })
      .then((res) => {
        console.log(res.data);
        let response = res.data;
        if (
          response === "null" ||
          response === null ||
          response === "Failure" ||
          typeof response === "undefined" ||
          typeof response === "None"
        ) {
          success = false;
          console.log("Users adding FAILURE");
        } else if (
          response === "Invalid Details" ||
          response === "Password too short"
        ) {
          console.log(
            "Details for adding users are INVALID or PASSWORD TOO SHORT"
          );
          success = false;
        } else {
          console.log("SUCCESS adding user!!");
          console.log("User added to the database.");
          success = true;
          user.id = res.data;
        }
      })
      .catch((err) => {
        console.log("FAILURE adding user to database!!");
        console.log(err);
        this.setState({
          errorText: "Database adding error",
        });
        success = false;
      });

    return success;
  }

  // Render previous page button
  get previousButton() {
    return this.state.currentStep !== 1 ? (
      <Button light onClick={this._prev}>
        Previous
      </Button>
    ) : (
      <div></div>
    );
  }

  // Render next page button
  get nextButton() {
    return this.state.currentStep < 3 ? (
      <Button onClick={this._next}>Next</Button>
    ) : (
      <div></div>
    );
  }

  //formats the error message to be on separate lines
  printError = (errorMessage) => {
    var lines = errorMessage;
    var br = lines.map(function (line) {
      return (
        <div className="alert alert-danger" role="alert">
          <span>
            {line}
            <br />
          </span>
        </div>
      );
    });
    return <div>{br}</div>;
  };

  //ASSIGNED Users

  //selects a user from user list by name and email
  selectUser = (name, email) => {
    let selectedAddUser = { full_name: name, email: email };
    this.setState({ selectedUser: selectedAddUser });
  };

  //selects a user from ADDuser list by name and email
  selectAddUser = (row) => {
    this.setState({ selectedAddUser: row });
  };

  //resets all the changes for tables assigning
  resetChanges = () => {
    console.log("properties were reset to their default state");
    for (let i = 0; i < this.state.locations.length; i++) {
      if (
        this.state.locations[i].assignedusers &&
        this.state.locations[i].assignedusers.length > 0
      ) {
        this.state.locations[i].assignedusers = [];
      }
    }
    this.setState({ locations: this.state.locations });

    let assignedPropertyManagers = [];
    this.setState({ assignedPropertyManagers });
  };

  // selects a location from location list by index
  updateSelectedLocations = (array) => {
    this.state.selectedLocations = array;
    this.setState({ selectedLocations: this.state.selectedLocations });
    // console.log(array)
    // console.log(this.state.selectedLocations)
    this.updateAddUsers();

    let message =
      "Assigned users will be shown when a single property is selected.";
    this.setState({ event_message: message });
  };

  updateSelectedUsers = (array) => {
    this.setState({ selectedUsers: array });
  };

  updateSelectedAddUsers = (array) => {
    this.setState({ selectedAddUsers: array });
  };
  // Selects a user from user list by index
  selectUserIndex = (index) => {
    this.setState({ selectedUserIndex: index });
  };

  // Selects a user from ADDusers list by index
  selectAddUserIndex = (index) => {
    this.setState({ selectedAddUserIndex: index });
  };

  // Updates theAddUsers list depending on the selected location and its users
  updateAddUsers = () => {
    // console.log(this.state.selectedLocations[0])
    // console.log(this.state.selectedLocations)
    if (
      this.state.selectedLocations &&
      this.state.selectedLocations.length === 1
    ) {
      var location = this.state.locations.find((location) => {
        return this.state.selectedLocations[0] === location.id;
      });
      this.state.addUsersList = location.assignedusers;
      this.setState({ addUsers: this.state.addUsersList });
    }
  };

  // Adds a valid selected user from users array to the addUsers list by appending locations.assignedUsers
  addUsers = () => {
    this.setState({ isLoading: true });
    let count_users = 0;
    for (var user1 in this.state.selectedUsers) {
      var user = this.state.users.find((user) => {
        return this.state.selectedUsers[user1] === user.email;
      });
      console.log(user);
      console.log("user");
      if (
        this.state.selectedLocations &&
        this.state.selectedLocations.length > 0
      ) {
        // Validates if already added if not undefined before adding
        console.log("adding");

        console.log("validated");

        for (let location1 in this.state.selectedLocations) {
          var location = this.state.locations.find((location) => {
            return this.state.selectedLocations[location1] === location.id;
          });

          if (this.validateAssignUsers(user, location) === true) {
            console.log(location);
            location.assignedusers.push(user);
            count_users += 1;
          }
        }
      }
    }
    this.setState({ isLoading: false });

    this.updateAddUsers();

    //
    this.setState({ locations: this.state.locations });

    if (count_users > 0) {
      let message =
        this.state.selectedUsers.length +
        " users were assigned to " +
        this.state.selectedLocations.length +
        " properties.";
      this.setState({ event_message: message });
    } else {
      let message = "Users already assigned to these locations.";
      this.setState({ event_message: message });
    }
  };

  // Adds a removes a user from locations.assignedUsers
  removeUser = () => {
    var selectedAddUsers = Array.from(this.state.selectedAddUsers);
    let count_users = 0;
    for (var user1 in this.state.selectedAddUsers) {
      var user = this.state.users.find((user) => {
        return selectedAddUsers[user1] === user.email;
      });
      console.log(user);
      if (
        this.state.selectedLocations &&
        this.state.selectedLocations.length === 1
      ) {
        // Validates if already added if not undefined before adding
        var location = this.state.locations.find((location) => {
          return this.state.selectedLocations[0] === location.id;
        });
        var result = location.assignedusers.find((assignedUser) => {
          return assignedUser === user;
        });
        console.log("REMOVE: " + result);
        if (location && result) {
          console.log("REMOVING: " + result);
          selectedAddUsers = selectedAddUsers.filter((e) => e !== user.id);

          location.assignedusers = location.assignedusers.filter(
            (e) => e !== result
          );

          if (
            user &&
            (user.role === "Property Manager" || user.role === "Property Admin")
          ) {
            this.state.assignedPropertyManagers = this.state.assignedPropertyManagers.filter(
              (e) => e !== user.email
            );
            this.setState({
              assignedPropertyManagers: this.state.assignedPropertyManagers,
            });
          }

          count_users += 1;
          console.log("REMOVED: " + result);
        }
      }
    }
    this.state.selectedAddUsers = selectedAddUsers;

    this.updateAddUsers();
    this.setState({ locations: this.state.locations });

    if (count_users > 0) {
      let message =
        this.state.selectedAddUsers.length +
        " users were removed from the property.";
      this.setState({ event_message: message });
    }
  };

  CheckLoader() {
    if (this.state.isLoading) return <Loader />;
  }
  //ASSIGNED USERS END

  render() {
    return (
      <>
        {/* Default header */}
        <Header
          user_permissions={this.props.userData.data.permissions}
          user_portfolio_type={this.props.userData.data.portfolio_type}
          customstyle={this.props.userData.data.customstyle}
          organisation={this.props.userData.data.orgid}
          logout={() => Logout(this.props)}
        />

        <PageContainer>
          <PageContainer.Center>
            {/* Page header */}
            <PageHeader>
              <PageHeader.Title pretitle="Form">
                <h2>Add Management</h2>
              </PageHeader.Title>
              <div>
                <Badge pill variant="primary">
                  <span>Step {this.state.currentStep} of 3</span>
                </Badge>
              </div>
            </PageHeader>

            <form onSubmit={this.handleSubmit}>
              {/* Page 1 */}
              <AddUsers
                currentStep={this.state.currentStep}
                handleChange={this.handleChange}
                users={this.state.users}
                user_permissions={this.state.user_permissions}
                portfolio_type={this.state.portfolio_type}
              />

              {/* Page 2 */}
              {this.state.users && this.state.users.length < 2 ? (
                <AssignUsersSingle
                  currentStep={this.state.currentStep}
                  handleChange={this.handleChange}
                  users={this.state.users}
                  locations={this.state.locations}
                  selectedLocations={this.state.selectedLocations}
                  selectedUsers={this.state.selectedUsers}
                  selectedAddUsers={this.state.selectedAddUsers}
                  addUsersList={this.state.addUsersList}
                  selectUser={this.selectUser}
                  selectLocations={this.selectLocations}
                  selectAddUser={this.selectAddUser}
                  selectUserIndex={this.selectUserIndex}
                  selectLocationsIndex={this.selectLocationsIndex}
                  selectAddUserIndex={this.selectAddUserIndex}
                  updateAddUsers={this.updateAddUsers}
                  setCtrl={this.setCtrl}
                  setShift={this.setShift}
                  event_message={this.state.event_message}
                  addUsers={this.addUsers}
                  removeUser={this.removeUser}
                  updateSelectedLocations={this.updateSelectedLocations}
                  resetChanges={this.resetChanges}
                />
              ) : (
                <AssignUsers
                  currentStep={this.state.currentStep}
                  handleChange={this.handleChange}
                  users={this.state.users}
                  locations={this.state.locations}
                  selectedLocations={this.state.selectedLocations}
                  selectedUsers={this.state.selectedUsers}
                  selectedAddUsers={this.state.selectedAddUsers}
                  addUsersList={this.state.addUsersList}
                  selectUser={this.selectUser}
                  selectLocations={this.selectLocations}
                  selectAddUser={this.selectAddUser}
                  selectUserIndex={this.selectUserIndex}
                  selectLocationsIndex={this.selectLocationsIndex}
                  selectAddUserIndex={this.selectAddUserIndex}
                  updateAddUsers={this.updateAddUsers}
                  setCtrl={this.setCtrl}
                  setShift={this.setShift}
                  event_message={this.state.event_message}
                  addUsers={this.addUsers}
                  removeUser={this.removeUser}
                  updateSelectedLocations={this.updateSelectedLocations}
                  updateSelectedAddUsers={this.updateSelectedAddUsers}
                  updateSelectedUsers={this.updateSelectedUsers}
                  resetChanges={this.resetChanges}
                />
              )}

              {/* Page 3 */}
              <AssignUsersLastStep
                currentStep={this.state.currentStep}
                handleSubmit={this.handleSubmit}
              />

              {/* Navigation */}
              <div className="d-flex justify-content-between mt-4 mb-4">
                {this.previousButton}
                {this.nextButton}
              </div>
            </form>

            {this.CheckLoader()}

            <div>{this.state.errorText}</div>
          </PageContainer.Center>
        </PageContainer>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { userLogged, user, userData } = state;
  return {
    userLogged,
    user,
    userData,
  };
}

export default connect(mapStateToProps)(MasterForm);
