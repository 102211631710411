import React from "react";
import UserInputs from "./UserInputs";
import Axios from "axios";
import AssignUsers from "./AssignUsers";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Redirect } from "react-router";

import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import c from "../../pages/GetStarted.module.scss";
import CheckBox from "balkerne-components/CheckBox";
import Dropzone from "balkerne-components/Dropzone";

import "./OrgGetStarted.css";
//
export class OrgGetStarted extends React.Component {
  constructor(props) {
    super(props);
    //setting initial user row
    this.state = {
      orgData: {
        flooding: true,
        fire: true,
        crime: true,
        weather: true,
        pandemic: false,
        metal_theft: false,
        name: this.props.organisation === true ? this.props.organisation : "",
        uploadFile: null,
      },

      mounted: false,
    };
  }

  handleFile(e) {
    console.log("adding file");
    console.log(e);
    if (e && e.length > 0) {
      let uploadfile = e[0];
      console.log(uploadfile);

      this.setState({
        orgData: {
          // object that we want to update
          ...this.state.orgData, // keep all other key-value pairs
          uploadFile: uploadfile, // update the value of specific key
        },
      });

      this.props.updateOrgLogo(uploadfile);
    }
  }

  loadOrg() {
    // this.state.orgData.organisation = this.props.organisation
    let orgData = this.state.orgData;
    orgData.organisation = this.props.organisation;
    this.setState({ orgData });
    console.log(this.props.organisation);
    console.log(this.state.orgData);

    this.props.updateOrgData(orgData);
    window.document.addEventListener("DOMContentLoaded", this.handleLoad());
    this.setState({ mounted: true });
  }
  async componentDidMount() {
    // var bingCoordinates = require('bing-coordinates');
    // bingCoordinates.setMapKey("AmOxhmMY4diBdT6YCH8nf3wqSeSRu8w1pG9GbLOh4GmIaeq8V_RPY2SQY5DphnmN");
    // bingCoordinates.getCoordinates("191 avon way, colchester", function(err, coordinates){
    //   console.log('Latitude: ' + coordinates.latitude, 'Longitude: ' + coordinates.longitude);
    // });
  }

  //   //loads updated values into the users array
  //   updateFields = users => {
  //     this.setState(this.state.users, this.props.users);
  //   };

  //handles changes made to the user, updates the users array state
  handleChange = (event) => {
    let newState = {
      orgData: {
        // object that we want to update
        ...this.state.orgData, // keep all other key-value pairs
        [event.target.id]: event.target.value, // update the value of specific key
      },
    };
    this.setState(newState);
    this.props.updateOrgData(newState.orgData);
  };

  initializeOrg = () => {
    let newState = {
      orgData: {
        // object that we want to update
        ...this.state.orgData, // keep all other key-value pairs
        ["name"]: this.props.name, // update the value of specific key
      },
    };
    this.setState({ mounted: true });
    this.setState(newState);
    this.props.updateOrgData(newState.orgData);
  };

  handleChangeCheckbox = (name, checked) => {
    console.log(name);
    console.log(checked);

    //console.log(this.state.orgData[name])

    let value = checked;
    // console.log(value)
    //  // console.log(!this.state.orgData[name])
    //   console.log(event.target.value)
    //   console.log(value)

    let newState = {
      orgData: {
        // object that we want to update
        ...this.state.orgData, // keep all other key-value pairs
        [name]: value, // update the value of specific key
      },
    };
    console.log(newState);
    this.setState(newState);
    this.props.updateOrgData(newState.orgData);
  };
  //   componentDidMount() {
  //     window.addEventListener('load', this.handleLoad);
  //  }

  //conditionally render based on state, add the userInputs component for row of fields and set functions
  render() {
    // if (window.Microsoft && this.state.mounted ===false){
    //   this.loadMicrosoft()
    // }

    if (this.props.currentStep !== 2) {
      // if(this.state.mounted===true){
      // this.setState({mounted:false})
      // }
      return null;
    }

    if (this.state.mounted === false) {
      this.initializeOrg();
      // this.loadOrg()
    }
    return (
      <div className={c.page}>
        <Container>
          <Card className={c.chart}>
            <Card.Header>Create Organisation: </Card.Header>
            <Card.Body>
              <div id="orgupload">
                <div>
                  <form
                    name="my-form"
                    type="button"
                    onChange={this.handleChange}
                  >
                    <h3 className="pb-2">Company</h3>

                    <input
                      type="text"
                      id="name"
                      className="form-control"
                      name="name"
                      value={this.state.orgData.name}
                      //defaultValue={this.props.name}
                    />
                  </form>
                </div>

                <div>
                  <h3 className="pb-2">Logo</h3>

                  <Dropzone
                    showCounter={true}
                    onChange={(e) => {
                      this.handleFile(e);
                    }}
                  />
                </div>

                <div>
                  <div>
                    <h3>Monitor Risks</h3>
                    <p>Select relevant risks you'd like to monitor</p>
                  </div>
                  <form name="my-form-checkbox" type="button">
                    <div className="checkboxes  pb-3 pt-2">
                      <CheckBox
                        size="large"
                        name="flooding"
                        value
                        onChange={(e) =>
                          this.handleChangeCheckbox("flooding", e)
                        }
                      >
                        Flooding
                      </CheckBox>

                      <CheckBox
                        size="large"
                        name="crime"
                        value
                        onChange={(e) => this.handleChangeCheckbox("crime", e)}
                      >
                        Crime
                      </CheckBox>

                      <CheckBox
                        size="large"
                        name="fire"
                        value
                        onChange={(e) => this.handleChangeCheckbox("fire", e)}
                      >
                        Fire
                      </CheckBox>

                      <CheckBox
                        size="large"
                        name="weather"
                        value
                        onChange={(e) =>
                          this.handleChangeCheckbox("weather", e)
                        }
                      >
                        Weather
                      </CheckBox>

                      <CheckBox
                        size="large"
                        name="pandemic"
                        value
                        onChange={(e) =>
                          this.handleChangeCheckbox("pandemic", e)
                        }
                      >
                        Pandemic
                      </CheckBox>

                      <CheckBox
                        size="large"
                        name="metal_theft"
                        value
                        onChange={(e) =>
                          this.handleChangeCheckbox("metal_theft", e)
                        }
                      >
                        Metal Theft
                      </CheckBox>
                    </div>

                    <hr></hr>

                    <p>Under Development</p>
                    <div className="checkboxes  pt-3">
                      <CheckBox size="large" value disabled={true}>
                        Air Pollution
                      </CheckBox>

                      <CheckBox size="large" value disabled={true}>
                        Earthquake
                      </CheckBox>

                      <CheckBox size="large" value disabled={true}>
                        Power Failure
                      </CheckBox>

                      <CheckBox size="large" value disabled={true}>
                        Terrorism
                      </CheckBox>
                    </div>
                  </form>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Container>
      </div>
    );
  }
}
export default OrgGetStarted;
