import React from "react";
import UserInputs from "./UserInputs";
import Axios from "axios";
import AssignUsers from "./AssignUsers";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Redirect } from "react-router";

import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import c from "../../pages/GetStarted.module.scss";
import Select from "balkerne-components/Select";

import { PORTFOLIO_TYPES } from "./roles.js";

//import "./UserGetStarted.css";
//
export class UserGetStarted extends React.Component {
  constructor(props) {
    super(props);
    //setting initial user row
    this.state = {
      userData: {
        full_name: "",
        email: "",
        phone: "01150000000",
        organisation: "",
        portfolio_type: "",
        position: "Insurance Self-Service",
      },
    };
  }

  //   //loads updated values into the users array
  //   updateFields = users => {
  //     this.setState(this.state.users, this.props.users);
  //   };

  //handles changes made to the user, updates the users array state
  handleChange = (event) => {
    console.log(event.target.value);
    console.log(event.target.id);
    let newState = {
      userData: {
        // object that we want to update
        ...this.state.userData, // keep all other key-value pairs
        [event.target.id]: event.target.value, // update the value of specific key
      },
    };
    this.setState(newState);
    this.props.updateUserData(newState.userData);
  };

  //conditionally render based on state, add the userInputs component for row of fields and set functions
  render() {
    if (this.props.currentStep !== 1) {
      return null;
    }
    return (
      <div className={c.page}>
        <Container>
          <Card className={c.chart}>
            <Card.Header>Create User: </Card.Header>
            <Card.Body>
              <form name="my-form" type="button" onChange={this.handleChange}>
                <div>
                  <label htmlFor="email">E-Mail Address</label>

                  <input
                    type="text"
                    id="email"
                    className="form-control"
                    name="userData.email"
                  />
                </div>

                <div className="form-group row">
                  <label htmlFor="fullName">Your Name</label>

                  <input
                    type="text"
                    id="full_name"
                    className="form-control"
                    name="full_name"
                  />
                </div>
                {/* <div className="form-group row">
                  <label for="position">Position</label>
  
                  <input
                    type="text"
                    id="position"
                    className="form-control"
                    name="position"
                  />
                </div>
                 */}

                <div className="form-group row">
                  <label htmlFor="org_id">Your Company Name</label>

                  <input
                    type="text"
                    id="organisation"
                    className="form-control"
                  />
                </div>

                <div className="form-group row">
                  {/* <label htmlFor="org_id">Your Portfolio Type:</label> */}
                  <Select
                    label="Your Portfolio Type"
                    name="portfolio_type"
                    id="portfolio_type"
                    onChange={({ value }) =>
                      this.handleChange({
                        target: {
                          value: value,
                          name: "portfolio_type",
                          id: "portfolio_type",
                        },
                      })
                    }
                    options={PORTFOLIO_TYPES}
                  />
                </div>

                {/* <div className="form-group row">
                  <label for="phone">Phone Number</label>
  
                  <input type="text" id="phone" className="form-control" />
                </div> */}
              </form>
            </Card.Body>
          </Card>
        </Container>
      </div>
    );
  }
}
export default UserGetStarted;
