import React from "react";
import Button from "balkerne-components/Button";
import "./AssignUsersLastStep.css";

export const AssignUsersLastStep = (props) => {
  if (props.currentStep !== 3) {
    return null;
  }

  return (
    <div className="d-flex flex-column justify-content-center align-items-center mt-5 mb-5">
      <h3>You've completed the form</h3>
      <div className="mb-2">Submit to save the property</div>
      <Button onClick={props.handleSubmit}>Submit</Button>
    </div>
  );
};

export default AssignUsersLastStep;
