import React from "react";
import EditMap from "../components/userManagement/EditMap";
import LocationAssignUsers from "../components/userManagement/LocationAssignUsers";
import AssignUsersLastStep from "../components/userManagement/AssignUsersLastStep";
import axios from "axios";
import * as urls from "../components/maps/urls";
import PageContainer from "balkerne-components/Container";
import PageHeader from "balkerne-components/PageHeader";
import Button from "balkerne-components/Button";
import Badge from "balkerne-components/Badge";
import Card from "react-bootstrap/Card";
import { connect } from "react-redux";
import { Logout } from "../components/Logout";
import { RefreshAuthTokens } from "../components/RefreshAuthTokens";
import PropertyHeader from "../components/HeaderProperty";
import { systemActions } from "../redux/_actions";
import ReactGA from "react-ga";

import "./MainAddLocation.css";

class MainEditLocation extends React.Component {
  constructor(props) {
    super(props);
    //initial cunstructor for the master class containng the 3 forms
    this._next = this._next.bind(this);
    this._prev = this._prev.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    // this.addUsersToDatabaseAndCognito = this.addUsersToDatabaseAndCognito.bind(this)
    this.state = {
      currentStep: 1, // first Step 1
      property_id: this.props.dashboardData.data.id || null, //this.props.dashboardData.data.id?(this.props.dashboardData.data.id):(null),

      //data arrays
      users: [],
      location: null,
      assignedusers: [],

      selectedAssignedUsers: [],
      assignedusers: [],
      //selections in assign users form
      selectedUsers: [],

      ctrl: false,
      shifts: false,

      org_id: 0,
      current_user_id: 0,

      //error displaying
      errorArray: [],
      errorText: "",
      errMessage: "",
      success: true,
    };
    this.getUsers = this.getUsers.bind(this);
    this.propertyUsers = this.propertyUsers.bind(this);
  }

  showNotice = () => {
    if (this.state.success === false) {
      return <div className="alertbox">{this.state.errMessage}</div>;
    }
  };

  async getLocationData(location_id) {
    let data = null;

    let params = {
      propid: location_id,
      org_id: this.props.userData.data.orgid,
    };

    await axios({
      method: "get",
      url: urls.API_GATEWAY + "locations/property-specific-edit",
      params: params,
    })
      .then((res) => {
        let locationData = res.data;
        console.log(locationData);

        if (
          locationData === "null" ||
          locationData === null ||
          typeof locationData === "undefined"
        ) {
          console.log("Error retrieving location");
          data = null;
          this.setState({
            success: false,
            errMessage: "Could not retrieve property. (Server)",
          });
        } else if (locationData === "Invalid Details") {
          console.log("Details for Location retrieved are INVALID");
          data = null;
          this.setState({
            success: false,
            errMessage: "Cannot access property specified.",
          });
        } else if (locationData === "Property not found") {
          console.log("No Location was Found");
          data = null;
          this.setState({ success: false, errMessage: "Property not found." });
        } else {
          data = locationData;
          console.log("SUCCESS!!");

          console.log("Dashboard Data retrieved from the database.");
        }
      })
      .catch((err) => {
        console.log("FAILURE!!");
        console.log(err);
        data = null;
        if (!err.message) {
          console.log("Error when loading location: ", err);
          //  Alert.alert('Error when signing in: ', err);
        } else {
          console.log("Error when loading location: ", err.message);
          //  Alert.alert('Error when signing in: ', err.message);
          this.setState({
            success: false,
            errMessage: "Could not retrieve location. (Server)",
          });
        }
      });

    return data;
  }
  //  72^C#bB12#$b
  async componentDidMount() {
    await RefreshAuthTokens(this.props);

    if (this.state.property_id) {
      let location = await this.getLocationData(this.state.property_id);
      if (location) {
        this.state.property_customer_reference =
          location.properties.property_customer_reference;

        this.state.location = location;
        this.state.location.id = this.state.property_id;
      }
      // if (typeof this.state.location.assignedusers === 'undefined'){
      //   this.state.location.assignedusers = []
      // }

      console.log(this.props.userData);
      this.state.org_id = this.props.userData.data.orgid;
      this.state.current_user_id = this.props.userData.data.id;
      this.state.group = this.props.userData.data.group;

      await this.getUsers(this.state.current_user_id, this.state.org_id);

      await this.propertyUsers(
        this.state.property_id,
        this.state.current_user_id
      );
    }
    // console.log(this.state.location.assignedusers);
    //console.log(this.state.addUsersList);

    this.setState({ mounted: true });

    // if(this.state.mounted===true && this.state.location === null){
    //   this.setState({success:false, errMessage:"Could not retrieve location. (Server)"})
    // }
  }

  async getUsers(current_user_id, org_id) {
    let users = null;

    let data = {
      user_id: current_user_id,
      org_id: org_id,
      no_prop_manager: true,
    };

    await axios({
      method: "get",
      url: urls.API_GATEWAY + "users/get-users",
      params: data,
    })
      .then((res) => {
        users = res.data;
        console.log(users);

        if (
          users === "null" ||
          users === null ||
          users === "Failure" ||
          typeof users === "undefined"
        ) {
          users = [];
          console.log("Users retrieval FAILURE");
          this.setState({
            success: false,
            errMessage: "Failed to load property data (Server)",
          });
        } else if (users === "Invalid Details" || users === "No users found") {
          console.log(
            "Details for users retrieved are INVALID or NO USERS WERE FOUND"
          );
          this.setState({
            success: false,
            errMessage: "Failed to load property data (Server)",
          });
          users = [];
        } else {
          console.log("SUCCESS!!");
          console.log("Users retrieved from the database.");
        }

        this.setState({ users });
      })
      .catch((err) => {
        users = null;
        this.setState({ users });
        console.log("FAILURE!!");
        console.log(err);
        if (!err.message) {
          console.log("Error when loading users: ", err);
          //  Alert.alert('Error when signing in: ', err);
        } else {
          console.log("Error when loading users: ", err.message);
          //  Alert.alert('Error when signing in: ', err.message);
          this.setState({
            success: false,
            errMessage: "Could not Reach Server",
          });
        }
      });
  }

  async propertyUsers(propid, user_id) {
    let assignedusers2 = null;

    let data = { propid: propid, user_id: user_id };

    await axios({
      method: "get",
      url: urls.API_GATEWAY + "users/property-users",
      params: data,
    })
      .then((res) => {
        assignedusers2 = res.data;
        console.log(assignedusers2);

        if (
          assignedusers2 === "null" ||
          assignedusers2 === null ||
          assignedusers2 === "Failure" ||
          typeof assignedusers2 === "undefined"
        ) {
          assignedusers2 = [];
          console.log("Users retrieval FAILURE");
          this.setState({
            success: false,
            errMessage: "Failed to load property data. (Server)",
          });
        } else if (
          assignedusers2 === "Invalid Details" ||
          assignedusers2 === "No users found"
        ) {
          console.log(
            "Details for property users retrieved are INVALID or NO USERS WERE FOUND"
          );
          assignedusers2 = [];
          this.setState({
            success: false,
            errMessage: "Failed to load property data. (Server)",
          });
        } else {
          console.log("SUCCESS!!");
          console.log("Users retrieved from the database.");
        }

        this.setState({ assignedusers: assignedusers2 });

        //this.state.location.assignedusers = assignedusers2;
        //console.log(this.state.location.assignedusers);
        //this.state.addUsersList = assignedusers2;
      })
      .catch((err) => {
        console.log("FAILURE!!");
        console.log(err);
        assignedusers2 = [];
        if (!err.message) {
          console.log("Error when loading users: ", err);
          //  Alert.alert('Error when signing in: ', err);
        } else {
          console.log("Error when loading users: ", err.message);
          //  Alert.alert('Error when signing in: ', err.message);
          this.setState({
            success: false,
            errMessage: "Could not retrieve users. (Server)",
          });
        }
      });
  }

  //function moving the user to the next form
  _next() {
    console.log(this.state.users);

    let currentStep = this.state.currentStep;

    if (currentStep === 1) {
      //validate added users
      this.validateAddLocation(currentStep);
    } else {
      // If the current step is 1 or 2, then add one on "next" button click
      currentStep = currentStep >= 2 ? 3 : currentStep + 1;
      this.setState({
        currentStep: currentStep,
      });
      this.setState({
        errorText: "",
      });
    }
  }

  //function moving the user to the previous form
  _prev() {
    let currentStep = this.state.currentStep;
    // If the current step is 2 or 3, then subtract one on "previous" button click
    currentStep = currentStep <= 1 ? 1 : currentStep - 1;
    this.setState({
      currentStep: currentStep,
    });
  }

  //Validates the assigning (Adding users to location that same user can't get added twice to the same location)
  validateAssignUsers(user) {
    let validate = true;
    let errorMessage = [];
    if (this.state.assignedusers && this.state.assignedusers.length) {
      console.log("CHECKING");
      for (var i = 0; i < this.state.assignedusers.length; i++) {
        console.log("CHECKING2");
        console.log(user.email);
        console.log(this.state.assignedusers[i].email);
        //Checks if assignedusers list already has the selected person in the list
        if (user.email === this.state.assignedusers[i].email) {
          console.log("invalidated");
          validate = false;
          errorMessage.push(
            "User '" +
              this.state.assignedusers[i].full_name +
              "' is already assigned to this location.\n"
          );
        }
      }
    }

    //return true or false as well as printing an error
    if (validate === true) {
      this.setState({
        errorText: "",
      });
      return true;
    } else {
      this.setState({
        errorText: this.printError(errorMessage),
      });
      return false;
    }
  }

  //Validates the form for adding users - check if all fields are filled in, emails and phone numbers are valid, and if no users have the same email and name
  async validateAddLocation(currentStep) {
    let validate = true;
    let errorMessage = [];

    //regex for validating emails and phoneNumbers - not final!!!
    //test if any fields are undefined or empty (missing) as well as checking if email and phone number are valid
    if (this.state.location) {
      if (
        typeof this.state.location.name === "undefined" ||
        this.state.location.name < 1
      ) {
        validate = false;
        errorMessage.push("Field 'Name' cannot be empty.\n");
      }
      if (
        typeof this.state.location.region === "undefined" ||
        this.state.location.region < 1
      ) {
        validate = false;
        errorMessage.push("Field 'Region' cannot be empty.\n");
      }
      if (
        typeof this.state.location.address_street === "undefined" ||
        this.state.location.address_street < 1
      ) {
        validate = false;
        errorMessage.push("Field 'Street' cannot be empty.\n");
      }
      if (
        typeof this.state.location.address_town === "undefined" ||
        this.state.location.address_town < 1
      ) {
        validate = false;
        errorMessage.push("Field 'Town' cannot be empty.\n");
      }
      if (
        typeof this.state.location.address_country === "undefined" ||
        this.state.location.address_country < 1
      ) {
        validate = false;
        errorMessage.push("Field 'Country' cannot be empty.\n");
      }
      if (
        typeof this.state.location.address_postcode === "undefined" ||
        this.state.location.address_postcode < 1
      ) {
        validate = false;
        errorMessage.push("Field 'Postcode' cannot be empty.\n");
      }
      if (
        typeof this.state.location.property_customer_reference ===
          "undefined" ||
        this.state.location.property_customer_reference < 1
      ) {
        validate = false;
        errorMessage.push(
          "Field 'Property customer Reference No.' cannot be empty.\n"
        );
      }

      if (
        typeof this.state.location.polygon === "undefined" ||
        this.state.location.polygon === null
      ) {
        validate = false;
        errorMessage.push(
          "Please draw a valid property polygon or press 'Done' and ensure polygon turns green.\n"
        );
      } else if (
        typeof this.state.location.area === "undefined" ||
        this.state.location.area === 0
      ) {
        validate = false;
        errorMessage.push(
          "Please draw a valid property polygon or press 'Done' and ensure polygon turns green.\n"
        );
      } else if (
        typeof this.state.location.lat === "undefined" ||
        this.state.location.lat === null
      ) {
        validate = false;
        errorMessage.push(
          "Please draw a valid property polygon or press 'Done' and ensure polygon turns green.\n"
        );
      } else if (
        typeof this.state.location.lon === "undefined" ||
        this.state.location.lon === null
      ) {
        validate = false;
        errorMessage.push(
          "Please draw a valid property polygon or press 'Done' and ensure polygon turns green.\n"
        );
      }
    } else {
      validate = false;
      errorMessage.push("Please enter the required details.\n");
    }
    if (validate === true) {
      let validateDatabase = true;

      if (
        this.state.location.property_customer_reference !=
        this.state.property_customer_reference
      ) {
        validateDatabase = await this.checkDatabase(
          this.state.location.property_customer_reference
        );
        if (validateDatabase === false) {
          validate = false;
          errorMessage.push(
            "The following property customer reference No is already on the system: " +
              this.state.location.property_customer_reference
          );
        }
      }
    }

    //if everything valid, move to the next form, if not then print an error message.
    if (validate === true) {
      currentStep = currentStep >= 2 ? 3 : currentStep + 1;
      this.setState({
        currentStep: currentStep,
      });
      this.setState({
        errorText: "",
      });
    } else {
      this.setState({
        errorText: this.printError(errorMessage),
      });
    }
  }

  async checkDatabase(property_customer_reference) {
    let success = true;
    let checked = false;

    let params = { property_customer_reference: property_customer_reference };

    await axios({
      method: "get",
      url: urls.API_GATEWAY + "locations/check",
      params: params,
    })
      .then((res) => {
        checked = res.data;
        console.log(checked);
        if (
          checked === "null" ||
          checked === null ||
          typeof checked === "undefined"
        ) {
          checked = false;
          success = false;
          console.log("CRN CHECK FAILURE!!");
        } else if (checked === "available") {
          console.log("PCR succeeded " + property_customer_reference);
          success = true;
          checked = true;
          // this.postDatabase();
        } else {
          console.log("PCR failed " + property_customer_reference);
          checked = false;
          success = false;
        }
        this.setState({ checked });
      })
      .catch((err) => {
        console.log("FAILURE!!");
        console.log(err);
        checked = false;
        if (!err.message) {
          console.log("Error when loading users: ", err);
          //  Alert.alert('Error when signing in: ', err);
        } else {
          console.log("Error when loading users: ", err.message);
          //  Alert.alert('Error when signing in: ', err.message);
          // this.setState({success:false, errMessage:"Could not retrieve users. (Server)"})
        }
      });

    return success;
  }

  // Use the submitted data to set the state
  handleChange(name, value) {
    this.setState({
      [name]: value,
    });
  }

  submitLocation = (location) => {
    this.setState({
      location,
    });
  };

  // trigger submission (saving to database later - saving) - prints dataset values at the moment
  async handleSubmit(event) {
    event.preventDefault();
    let locationAddedSuccess = false;
    let mappingsAddedSuccess = false;
    let error = false;
    let location = this.state.location;
    console.log(this.state.location);
    //location.assignedusers.push(this.state.current_user_id)
    let analyticOutcome = "";

    let answer = await this.checkConnection();
    if (answer === true) {
      locationAddedSuccess = await this.addLocationDatabase(location);
      console.log(locationAddedSuccess);

      if (locationAddedSuccess === true) {
        let payload = {
          user_list: [],
          location_list: [],
        };

        if (this.state.assignedusers && this.state.assignedusers.length) {
          console.log("found location");

          if (this.state.assignedusers) {
            payload.user_list.push(this.state.current_user_id);

            payload.location_list.push(this.state.property_id);
          }

          for (var assignedUser1 in this.state.assignedusers) {
            var assignedUser = this.state.assignedusers[assignedUser1];
            console.log(assignedUser);

            payload.user_list.push(assignedUser.id);

            payload.location_list.push(this.state.property_id);
          }
        } else {
          console.log("Location users array empty.");
          alert("Failure processing assigned users.");
          return "";
        }

        mappingsAddedSuccess = await this.postDatabaseUserPropertyMatch(
          payload
        );
        if (mappingsAddedSuccess === true) {
          // console.log("ADDING USERS-mappings COMPLETE!!!!!!!!");
          alert("Success.");
          analyticOutcome = " Successfully added property";
          this.props.dispatch(systemActions.clearDashboardData());
          this.props.history.push({
            pathname: "/",
            state: { org_id: this.state.org_id },
          });
        } else {
          console.log("adding USER PROPERTY MATCHES FAILED");
          analyticOutcome = " Failed to add property";
        }
      } else {
        console.log("FAILED VIA adding database");
        analyticOutcome = " Failed to add property";
      }
    } else {
      alert("No connection to Server.");
    }

    ReactGA.event({
      category: "AddNewProperty",
      action: analyticOutcome,
    });
  }

  async addLocationDatabase(location) {
    let data = {};

    let success = true;

    for (var [key, value] of Object.entries(location)) {
      if (value !== "" && !Array.isArray(value)) {
        console.log(key + " " + value);
        data[key] = value;
        //formData.append(key, value);
      }
    }

    // if?
    data["created_by"] = this.state.current_user_id;

    // if?
    data["org_id"] = this.state.org_id;

    await axios({
      method: "post",
      url: urls.API_GATEWAY + "locations/edit-location",
      data: data,
    })
      .then((res) => {
        console.log(res.data);
        let payload = res.data;

        if (
          payload === "null" ||
          payload === null ||
          payload === "Failure" ||
          typeof payload === "undefined"
        ) {
          success = false;
          console.log("Adding property ERROR");
          this.setState({
            errorText:
              "Failure to edit property. Please contact Technical Support.",
          });
        } else if (
          payload === "Invalid Details" ||
          payload === "Property not found"
        ) {
          console.log(
            "Credentials for editing property, details are INVALID or property NOT FOUND"
          );
          success = false;
          this.setState({
            errorText:
              "Failure to edit property. Please contact Technical Support.",
          });
        } else if (isNaN(res.data)) {
          success = false;
          console.log("Adding property ERROR not a number");
          this.setState({
            errorText:
              "Failure to edit property. Please contact Technical Support.",
          });
        } else {
          console.log("SUCCESS!!");
          console.log("Property eddited succesfully to the database.");
          location.id = res.data;
          success = true;
        }
      })
      .catch((err) => {
        console.log("FAILURE editing location to database!!");
        console.log(err);
        this.setState({
          errorText:
            "Failure to add property. Please contact Technical Support.",
        });
        success = false;
      });

    return success;
  }

  async postDatabaseUserPropertyMatch(user_locations_map) {
    let user = this.props.user.user;

    console.log(user_locations_map.user_list);
    console.log(user_locations_map.location_list);

    let success = true;

    let data = {
      aws_id: user.username.split("").reverse().join(""),
      secret_key:
        user.userDataKey +
        " " +
        user.signInUserSession.accessToken.jwtToken +
        " " +
        user.pool.userPoolId,
      user_list: user_locations_map.user_list,
      location_list: user_locations_map.location_list,
    };

    await axios({
      method: "post",
      url: urls.API_GATEWAY + "users/map-user-locations-edit",
      data: data,
    })
      .then((res) => {
        console.log(res.data);
        let response = res.data;

        if (
          response === "null" ||
          response === null ||
          response === "Failure" ||
          typeof response === "undefined"
        ) {
          success = false;
          console.log("Mapping users FAILURE");
        } else if (
          response === "Invalid Details" ||
          response === "No locations or users"
        ) {
          console.log(
            "Details for mapping users to locations are INVALID or NO LOCATIONS OR USERS"
          );
          success = false;
        } else if (response == "Success") {
          success = true;
          console.log("SUCCESS!!");
          console.log("PROPERTY-USER  Successfully added to database");
        } else {
          console.log("FAILURE, UNKNOWN RESPONSE");
          success = false;
        }
      })
      .catch((err) => {
        console.log("FAILURE!!");
        console.log(err);
        this.setState({
          errorText: "Database adding error",
        });
        success = false;
      });

    return success;
  }

  async checkConnection() {
    let success = false;
    await axios({ method: "get", url: urls.API_GATEWAY + "users/working" })
      .then((res) => {
        if (res.data === "Success") {
          success = true;
          console.log("connection working");
        }
      })
      .catch((err) => {
        success = false;
        console.log("FAILURE!!");
        console.log(err);
        alert("No connection to the server");
        if (!err.message) {
          console.log("Error when loading locations: ", err);
          //  Alert.alert('Error when signing in: ', err);
        } else {
          console.log("Error when loading working connection: ", err.message);
          //  Alert.alert('Error when signing in: ', err.message);
          this.setState({ success: false, errMessage: "Cannot reach Server" });
        }
      });
    return success;
  }

  // Render previous page button
  get previousButton() {
    return this.state.currentStep !== 1 ? (
      <Button light onClick={this._prev}>
        Previous
      </Button>
    ) : (
      <div></div>
    );
  }

  // Render next page button
  get nextButton() {
    return this.state.currentStep < 3 ? (
      <Button onClick={this._next}>Next</Button>
    ) : (
      <div></div>
    );
  }

  //formats the error message to be on separate lines
  printError = (errorMessage) => {
    var lines = errorMessage;
    var br = lines.map(function (line) {
      return (
        <div className="alert alert-danger" role="alert">
          <span>
            {line}
            <br />
          </span>
        </div>
      );
    });
    return <div>{br}</div>;
  };

  //ASSIGNED Users

  // selects a location from location list by index
  updateSelectedUsers = (array) => {
    this.setState({ selectedUsers: array });
  };

  updateSelectedAssignedUsers = (array) => {
    this.setState({ selectedAssignedUsers: array });
  };

  // Adds a valid selected user from users array to the addUsers list by appending locations.assignedusers
  addUsers = () => {
    if (this.state.assignedusers) {
      console.log(this.state.assignedusers);
      for (var user1 in this.state.selectedUsers) {
        var user = this.state.users.find((user) => {
          return this.state.selectedUsers[user1] === user.email;
        });
        if (!user) {
          user = this.state.assignedusers.find((user) => {
            return this.state.selectedUsers[user1] === user.email;
          });
        }

        if (this.validateAssignUsers(user) === true) {
          this.state.assignedusers.push(user);
        }
      }
    }
  };

  // Adds a removes a user from locations.assignedusers
  removeUsers = () => {
    console.log("removing users");
    if (this.state.assignedusers) {
      var selectedAssignedUsers = Array.from(this.state.selectedAssignedUsers);
      console.log("true");
      for (var user1 in this.state.selectedAssignedUsers) {
        var user = this.state.users.find((user) => {
          return selectedAssignedUsers[user1] === user.email;
        });
        console.log(user);
        console.log(selectedAssignedUsers[user1]);
        if (!user) {
          user = this.state.assignedusers.find((user) => {
            return this.state.selectedUsers[user1] === user.email;
          });
        }

        if (user) {
          var result = this.state.assignedusers.find((assignedUser) => {
            return assignedUser.email === user.email;
          });
        }

        if (user && result) {
          this.state.selectedAssignedUsers = selectedAssignedUsers.filter(
            (e) => e !== user.email
          );

          this.state.assignedusers = this.state.assignedusers.filter(
            (e) => e !== result
          );
        }
      }
      this.state.selectedAssignedUsers = selectedAssignedUsers;
      this.setState({ assignedusers: this.state.assignedusers });
    }
  };

  //ASSIGNED USERS END

  render() {
    if (this.state.location) {
      console.log(this.state.assignedusers);
      console.log(this.state.assignedusers);
    }
    console.log("loaded");
    if (this.state.mounted && this.state.location && this.state.success) {
      return (
        <>
          {/* Property header */}
          <PropertyHeader
            user_permissions={this.props.userData.data.permissions}
            user_portfolio_type={this.props.userData.data.portfolio_type}
            customstyle={this.props.userData.data.customstyle}
            secret={this.props.user.user.signInUserSession.idToken.jwtToken}
            organisation={this.props.userData.data.orgid}
            logout={() => Logout(this.props)}
          />

          {/* Page content container */}
          <PageContainer>
            <PageContainer.Center>
              {/* Page header */}
              <PageHeader>
                <PageHeader.Title pretitle="Form">
                  <h2>Edit Property</h2>
                </PageHeader.Title>
                <div>
                  <Badge pill variant="primary">
                    <span>Step {this.state.currentStep} of 3</span>
                  </Badge>
                </div>
              </PageHeader>

              {/* Page 1 */}
              <EditMap
                currentStep={this.state.currentStep}
                handleChange={this.handleChange}
                users={this.state.users}
                submitLocation={this.submitLocation}
                location={this.state.location.properties}
                feature={this.state.location}
              />

              {/* Page 2 */}
              <LocationAssignUsers
                currentStep={this.state.currentStep}
                handleChange={this.handleChange}
                assignedusers={this.state.assignedusers}
                users={this.state.users}
                location={this.state.location}
                selectedUsers={this.state.selectedUsers}
                selectedAssignedUsers={this.state.selectedAssignedUsers}
                updateAssignedUsers={this.updateAssignedUsers}
                addUsers={this.addUsers}
                removeUsers={this.removeUsers}
                updateSelectedAssignedUsers={this.updateSelectedAssignedUsers}
                updateSelectedUsers={this.updateSelectedUsers}
                current_user_id={this.state.current_user_id}
              />

              {/* Page 3 */}
              <AssignUsersLastStep
                currentStep={this.state.currentStep}
                handleSubmit={this.handleSubmit}
              />

              {/* Navigation */}
              <div className="d-flex justify-content-between mt-4 mb-4">
                {this.previousButton}
                {this.nextButton}
              </div>

              {/* Errors */}
              <div>{this.state.errorText}</div>
            </PageContainer.Center>
          </PageContainer>
        </>
      );
    } else {
      return (
        <>
          <PropertyHeader
            user_permissions={this.props.userData.data.permissions}
            user_portfolio_type={this.props.userData.data.portfolio_type}
            logout={() => Logout(this.props)}
          />
          <div>{this.state.errorText}</div>
          {this.showNotice()}
        </>
      );
    }
  }
}

function mapStateToProps(state) {
  const { userLogged, user, userData, systemData, dashboardData } = state;
  return {
    userLogged,
    user,
    userData,
    systemData,
    dashboardData,
  };
}

export default connect(mapStateToProps)(MainEditLocation);
