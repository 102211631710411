import React from "react";
import "./HistoricalAlertComponent.scss";

const HistoricAlertComponent = (props) => {
  const unitMeasure = (type) => {
    if (["wind_gust"].includes(type)) {
      return "km/h";
    } else if (["flood", "low_visibility"].includes(type)) {
      return "m";
    } else if (["rainfall", "snowfall"].includes(type)) {
      return "mm";
    } else if (["cold", "heat"].includes(type)) {
      return "C";
    } else {
      return "";
    }
  };

  const whatMeasured = (type) => {
    if (["wind_gust"].includes(type)) {
      return "speed";
    } else if (["flood"].includes(type)) {
      return "height";
    } else if (["low_visibility"].includes(type)) {
      return "distance";
    } else if (["rainfall", "snowfall"].includes(type)) {
      return "amount";
    } else if (["cold", "heat"].includes(type)) {
      return "temperature";
    } else {
      return "";
    }
  };

  const getTitle = (type, severity) => {
    const title = prettyType(type);
    if (severity == 1) {
      return `Severe ${title} Warning`;
    } else if (severity == 2) {
      return `${title} Warning`;
    } else if (severity == 3) {
      return `${title} Alert`;
    } else {
      return title;
    }
  };

  const howMeasured = (calculation) => {
    if (calculation == "night_mean") {
      return "avg. over night";
    } else if (calculation == "24h_max_diff") {
      return "max. change over 24 hours";
    } else if (calculation == "1h") {
      return "once per 1 hour";
    } else if (calculation == "24h_sum") {
      return "sum over 24 hours";
    } else if (calculation == "1h_max") {
      return "max. over 1 hour";
    } else if (calculation == "day_mean") {
      return "avg. over a day";
    } else if (calculation == "24h_mean") {
      return "avg. over 24 hours";
    } else if (calculation == "1h_sum") {
      return "sum over 1 hour";
    } else if (calculation == "12h_sum") {
      return "sum over 12 hours";
    } else {
      return "";
    }
  };

  const prettyType = (type) => {
    return type
      .split("_")
      .map((word) => word[0].toUpperCase() + word.substring(1))
      .join(" ");
  };

  return (
    <div className="alert-historical" data-testid="alert">
      <img
        className="alert-historical__icon"
        src={require(`../../images/alert_icons/${props.type}_${props.severity}.png`)}
      />
      <div>
        <h2 className="alert-historical__title">
          {getTitle(props.type, props.severity)}
        </h2>
        <div className="alert-historical__measurements">
          <span className="measurement">
            <span className="measurement__type">Alert raised:</span>
            <span className="measurement__measure" data-testid="alert-date">
              {props.date}
            </span>
          </span>
          <span className="measurement">
            {props.type != "flood" ? (
              <>
                <span className="measurement__type">{`Measured ${whatMeasured(
                  props.type
                )}`}</span>
                <span className="measurement__measure">
                  {props.measurement.toFixed(1) + unitMeasure(props.type)}
                </span>
              </>
            ) : (
              ""
            )}
          </span>
          <span className="measurement">
            {props.type != "flood" ? (
              <>
                <span className="measurement__type">How measured:</span>
                <span className="measurement__measure" data-testid="alert-date">
                  {howMeasured(props.calculation)}
                </span>
              </>
            ) : (
              ""
            )}
          </span>
        </div>
        <p className="alert-historical__text">{props.text}</p>
      </div>
    </div>
  );
};

export default HistoricAlertComponent;
