import React from 'react'

//import Home from './Home'
import { Route, Redirect, BrowserRouter } from 'react-router-dom'
import UserDashboard from './UserDashboard'
import axios from 'axios'
import * as urls from '../components/maps/urls'
import Table from 'react-bootstrap/Table'

import { userActions } from '../redux/_actions'
import { connect } from 'react-redux'
import { Logout } from '../components/Logout'
import Header from '../components/Header'
import Loader from '../components/spinner'
import { RefreshAuthTokens } from '../components/RefreshAuthTokens'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import Button from 'balkerne-components/Button'
import Can from 'balkerne-components/Can'
import { check } from 'balkerne-components/Can'
import './Locationlist.css'
//page that lists all locations and redirects to location when clicked

class UserListPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      users: [],
      filter_string: '',
      filter_type: '',
      selectedUsers: [],
      selectedUsersIndex: [],
      clicks: [],
      filteredUsers: null,

      redirect: false,
      redirectID: null,

      //error displaying
      errorArray: [],
      errorText: '',
      errMessage: '',
      success: true,

      mounted: false,
      isLoading: true,
    }
    this.handleDoubleClick = this.handleDoubleClick.bind(this)
    this.handleRedirect = this.handleRedirect.bind(this)
    this.getUsers = this.getUsers.bind(this)
    this.deleteCellFormatter = this.deleteCellFormatter.bind(this)
    this.deleteUser = this.deleteUser.bind(this)
  }

  //redirects the user to the location given the clicked id
  showNotice = () => {
    // if (this.state.success === false) {
    //   return (
    //     <div className="alertbox">
    //     {this.state.errMessage}
    //   </div>
    //   )
    //   }
  }

  handleChange = e => {
    let filter = {}
    filter[e.target.name] = e.target.value
    this.setState(filter)
    //this.props.handleChange("users",this.state.users);
  }

  //loads all the locations from the backend

  async componentDidMount() {
    this.setState({ isLoading: true })

    this.state.org_id = this.props.userData.data.orgid
    this.state.current_user_id = this.props.userData.data.id
    this.state.group = this.props.userData.data.group

    await RefreshAuthTokens(this.props)

    await this.getUsers(this.state.current_user_id, this.state.org_id)

    this.state.mounted = true

    this.setState({ isLoading: false })
  }
  async deleteUser(userID) {
    const baseurl = 'https://r8fd6rsk66.execute-api.eu-west-1.amazonaws.com/Version1'
    const path = '/users/deactivate-user?user_id=' + userID
    const fetchurl = baseurl + path

    await axios.delete(fetchurl).then(response => {
      alert('Deleting user with ID: ' + userID)
      console.log(response)
      let users = this.state.filteredUsers.filter(user => user.id !== userID)
      this.setState({ filteredUsers: users })
    })
  }

  deleteCellFormatter(cell, row) {
    return (
      <div>
        <a onClick={() => this.deleteUser(row.id)}>{cell}</a>
      </div>
    )
  }
  async getUsers(current_user_id, org_id) {
    let users = null

    let data = { user_id: current_user_id, org_id: org_id }

    await axios({
      method: 'get',
      url: urls.API_GATEWAY + 'users/get-users',
      params: data,
    })
      .then(res => {
        Object.values(res.data).map(user => {
          user['newbutton'] = 'Delete'
        })
        users = res.data
        console.log(users)

        if (users === 'null' || users === null || users === 'Failure' || typeof users === 'undefined') {
          users = []
          console.log('Users retrieval FAILURE')
        } else if (users === 'Invalid Details' || users === 'No users found') {
          console.log('Details for users retrieved are INVALID or NO USERS WERE FOUND')
          users = []
        } else if (users === 'permissions failed') {
          console.log('Invalid Permissions')
          users = []
        } else if (!Array.isArray(users)) {
          console.log('Return user object not a list')
          users = []
        } else {
          console.log('SUCCESS!!')
          console.log('Users retrieved from the database.')
        }

        this.setState({ users })
      })
      .catch(err => {
        users = null
        this.setState({ users })
        console.log('FAILURE!!')
        console.log(err)
        if (!err.message) {
          console.log('Error when loading users: ', err)
          //  Alert.alert('Error when signing in: ', err);
        } else {
          console.log('Error when loading users: ', err.message)
          //  Alert.alert('Error when signing in: ', err.message);
          this.setState({
            success: false,
            errMessage: 'Could not Reach Server',
          })
        }
      })
  }

  // selects a location from location list by index

  selectUsersIndex = index => {
    this.setState({ selectedUsersIndex: index })
  }

  //render the locations as a clickable table
  onRowSelectUsers = (row, isSelected, e, rowIndex) => {
    if (isSelected) {
      this.state.selectedUsers = [row.id]
    } else {
      this.state.selectedUsers = [row.id]
      this.handleDoubleClick(row.id, row.group, row.full_name)
    }
    //console.log("row select")
    // this.state.clicks.push(new Date().getTime());
    // window.clearTimeout(this.state.timeout);
    // this.state.timeout = window.setTimeout(() => {
    //   if (
    //     this.state.clicks.length > 1 &&
    //     this.state.clicks[this.state.clicks.length - 1] -
    //       this.state.clicks[this.state.clicks.length - 2] <
    //       250
    //   ) {console.log("double click")
    //     //this.handleDoubleClick(event.target, location);
    //   } else {
    //     console.log("signle click")
    //     //this.handleClick(event.target);
    //   }
    // }, 250);
  }

  handleClick(event) {
    console.log('single click')
  }

  //handles the actual redirect when double clicked a table row to a property dashboard

  async handleDoubleClick(user, group, name) {
    // let user = await this.getLocationData(user_id);

    console.log(user)

    if (user !== null && group !== null) {
      this.handleRedirect(user, group, name)
    }
  }

  async handleRedirect(user, group, name) {
    if (check(['users:assign', 'users:assign-any', 'users:assign-all'], null, this.props.userData.data.permissions)) {
      this.props.history.push({
        pathname: '/ReassignUserProperties',
        state: {
          userRedirectId: user,
          userRedirectGroup: group,
          userRedirectName: name,
        },
      })
    } else {
      console.log('User permissions failed')
    }
  }

  //handles the single/double click logic

  clickHandler(event, user) {
    event.preventDefault()
    console.log('click event')
    console.log(event)
    console.log(user)
  }

  applyFilter = () => {
    console.log('applied')
    this.state.filteredUsers = this.state.users
    let filteredUsers
    switch (this.state.filter_type) {
      case 'Name':
        filteredUsers = this.state.filteredUsers.filter(user => {
          if (user.full_name) {
            return user.full_name.toLowerCase().includes(this.state.filter_string.toLowerCase())
          }
        })
        break
      case 'Email':
        filteredUsers = this.state.filteredUsers.filter(user => {
          if (user.email) {
            return user.email.toLowerCase().includes(this.state.filter_string.toLowerCase())
          }
        })
        break
      case 'Position':
        filteredUsers = this.state.filteredUsers.filter(user => {
          if (user.position) {
            return user.position.toLowerCase().includes(this.state.filter_string.toLowerCase())
          }
        })
        break
      case 'Group':
        filteredUsers = this.state.filteredUsers.filter(user => {
          if (user.group) {
            return user.group.toLowerCase().includes(this.state.filter_string.toLowerCase())
          }
        })
        break
      case 'My Added':
        filteredUsers = this.state.filteredUsers.filter(user => {
          if (user.manager) {
            return user.manager === this.state.current_user_id
          }
        })
        break

      default:
        filteredUsers = this.state.users
    }

    this.setState({ filteredUsers })
  }

  clearFilter = () => {
    this.state.filter_string = ''

    let filteredUsers = this.state.users

    this.setState({ filteredUsers })
  }

  //Renders the page

  render() {
    //Redirecting
    // if(this.state.redirect==true){
    //   console.log("Redirecting");
    //   return(
    //     <Redirect to={{
    //   pathname: '/UserDashboard',
    //   state: { redirectID: this.state.redirectID }
    // }}/>
    //   )
    // }
    if (this.state.isLoading === true)
      return (
        <React.Fragment>
          <Header
            user_permissions={this.props.userData.data.permissions}
            user_portfolio_type={this.props.userData.data.portfolio_type}
            organisation={this.props.userData.data.orgid}
            customstyle={this.props.userData.data.customstyle}
            logout={() => Logout(this.props)}
          />
          <Loader color="#0652dd" />
        </React.Fragment>
      )
    console.log('users below')
    console.log(this.state.filteredUsers)

    const selectRowPropUsers = {
      mode: 'radio',
      clickToSelect: true,
      bgColor: 'cyan',
      selected: this.state.selectedUsers,
      hideSelectColumn: true,
      onSelect: this.onRowSelectUsers,
    }

    if (this.state.filteredUsers === null) {
      this.state.filteredUsers = this.state.users
    }
    if (this.state.mounted == true) {
      this.state.filteredUsers = this.state.users
      this.state.mounted = false
    }

    //     this.state.assignedLocations = this.props.assignedLocations;
    //     this.state.assignedLocationsLoaded = true;
    //   }
    return (
      <React.Fragment>
        <Header
          user_permissions={this.props.userData.data.permissions}
          user_portfolio_type={this.props.userData.data.portfolio_type}
          logout={() => Logout(this.props)}
          organisation={this.props.userData.data.orgid}
          customstyle={this.props.userData.data.customstyle}
        />

        <div className="locationlistform">
          <div className="filterpropertylist">
            <form onChange={this.handleChange}>
              <label> Filter: </label>
              <div className="searchfields">
                <input
                  type="text"
                  name="filter_string"
                  className="filter_string"
                  id="filter_string"
                  defaultValue={this.state.filter_string}
                />
                <select name="filter_type" className="filter_type" id="filter_type">
                  <option disabled selected>
                    Choose a Filter
                  </option>
                  <option value="Name">Name</option>
                  <option value="Email">Email</option>
                  <option value="Position">Position</option>
                  <option value="Group">Group</option>
                  <option value="My Added">My Added</option>
                </select>

                <Button light onClick={this.applyFilter}>
                  Apply
                </Button>

                <Button light onClick={this.clearFilter}>
                  Clear
                </Button>
              </div>
            </form>
          </div>
          <div className="propertylistresults">
            {console.log('below')}
            {console.log(this.state.filteredUsers)}
            {console.log(this.state.filteredUsers !== null)}
            {console.log(typeof this.state.filteredUsers !== 'undefined')}
            {console.log('above')}
            {this.state.filteredUsers &&
            this.state.filteredUsers !== null &&
            typeof this.state.filteredUsers !== 'undefined' ? (
              <BootstrapTable
                className="table-smaller-locations"
                selectRow={selectRowPropUsers}
                data={this.state.filteredUsers}
                striped
                hover>
                <TableHeaderColumn isKey dataField="id" hidden>
                  ID
                </TableHeaderColumn>
                <TableHeaderColumn width="30%" dataField="full_name">
                  Name
                </TableHeaderColumn>
                <TableHeaderColumn width="10%" dataField="email">
                  Email
                </TableHeaderColumn>
                <TableHeaderColumn width="40%" dataField="position">
                  Position
                </TableHeaderColumn>
                <TableHeaderColumn width="10%" dataField="group">
                  Group
                </TableHeaderColumn>
                <TableHeaderColumn width="10%" dataFormat={this.deleteCellFormatter} dataField="newbutton">
                  Remove User
                </TableHeaderColumn>
              </BootstrapTable>
            ) : (
              'Invalid Permissions'
            )}
          </div>
          <div>{this.state.errorText}</div>
          {this.showNotice()}
        </div>
      </React.Fragment>
    )
  }
}
function mapStateToProps(state) {
  //console.log(state);
  const { userLogged, user, userData } = state

  return {
    userLogged,
    user,
    userData,
  }
}
export default connect(mapStateToProps)(UserListPage)
