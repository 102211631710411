import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import allowRender from "./allowRender";


export const PrivateRoute = ({ component: Component, userLogged: UserLogged, portfolioType: portfolioType, path: path, exact: exact }) => (
    <Route path={path} exact={exact} render={props => (
        console.log(portfolioType),
        console.log(path),
        console.log(allowRender(path, portfolioType)),
        (UserLogged.loggedIn && allowRender(path, portfolioType))
            ? <Component {...props} />
            : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    )} />
)



export default PrivateRoute;