import React from "react";
import moment from "moment";
import ModalImage from "react-modal-image";
import makeURL from "balkerne-fn/makeURL";
import IconButton from "../general/IconButton";
import FileImage from "balkerne-components/FileImage";
import { LabeledTextLine } from "../general/LabeledText";
import c from "./Mitigation.module.scss";
import * as urls from "../maps/urls";
import Can from "balkerne-components/Can";

const Mitigation = (props) => {
  console.info(urls.MITIGATIONS);
  console.info(props);

  const showMitigationAttachments = () => {
    //NEW format if .url present
    if (props.url && props.attachment && props.attachment.length > 0) {
      return (
        <div>
          <div className={c.images}>
            {props.attachment.map((attachment) => (
              <ImageViewer path={props.url} filename={attachment} />
            ))}
          </div>
        </div>
      );
    }
    //OLD format if .url missing
    else if (props.attachment && props.attachment.length > 0) {
      return (
        <div>
          <div className={c.images}>
            {props.attachment.map((attachment) => (
              <ImageViewer
                path={[
                  props.property_id,
                  props.textmitigationcategory,
                  "",
                ].join("/")}
                filename={attachment}
              />
            ))}
          </div>
        </div>
      );
    }
  };

  const deleteItem = () => {
    if (window.confirm("Are you sure you wish to delete this mitigation?")) {
      props.deleteMitigation(props.mitigation_id);
    }
  };

  return (
    <div className={c.mitigation}>
      <div className={c.top}>
        <div className={c.left}>
          <LabeledTextLine label="Mitigation">
            {props.textmitigationcategory}
          </LabeledTextLine>
          <LabeledTextLine label="Risk category">
            {props.textriskcategory}
          </LabeledTextLine>
          <LabeledTextLine label="Installed">
            {moment(props.textinstallationdate).format("DD/MM/YYYY hh:mm")}
          </LabeledTextLine>
        </div>
        <div className={c.right}>
          <Can
            permissions={props.user_permissions}
            actions={[
              "mititgations:delete",
              "mititgations:delete-all",
              "mititgations:delete-any",
            ]}
            yes={() => <IconButton icon="trash" onClick={deleteItem} />}
          />
        </div>
      </div>
      <span className={c.description}>
        <hr />
        {props.textdescription}
      </span>
      {showMitigationAttachments()}
    </div>
  );
};

const ImageViewer = ({ path, filename }) => {
  console.log("ImageViewer");
  console.log(path);
  console.log(filename);
  const imageURL = makeURL([urls.MITIGATIONS, path, filename]);
  return (
    <div>
      <FileImage url={imageURL} />
    </div>
  );
};

export default Mitigation;
