export const COMMON_RENDERS = [
  '/',
  '/UserDashboard',
  '/MainAddUsers',
  '/CreateAccount',
  '/ReassignUserProperties',
  '/UserListPage',
  '/ChangeAccDetails',
  '/MainReassignUsers',
  '/MainEditLocation',
  '/riskreport',
  '/SmartVault',
  '/FormSelector',
  '/DynamicForm',
  '/LoadForm',
  '/ListIncidents',
  '/AdminTool',
  '/TemplateManager',
  '/QuickSightDashboard',
]

export default COMMON_RENDERS
