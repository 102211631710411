import React from "react";
export class RiskBar extends React.Component {
  render() {
    let level;
    if (this.props.value / this.props.max <= 0.33) level = "low";
    else if (this.props.value / this.props.max <= 0.66) level = "medium";
    else level = "high";

    let classes = ["dashboard__risk_bar", `dashboard__risk_bar--${level}`].join(
      " "
    );

    return (
      <div>
        <h4 className="dashboard__risk_score dashboard__risk_score--sub">
          <span className="dashboard__risk_category">{this.props.label}</span>
          <progress
            id={this.props.id}
            className={classes}
            max={this.props.max}
            value={this.props.value}
          />
        </h4>
      </div>
    );
  }
}

export default RiskBar;
