/**
 * Convinience functions for interacting with the Google Maps API
 */

import axios from "axios";
import { UK_POLYGON } from "./urls";

let headers = new Headers();
headers.append("pragma", "no-cache");
headers.append("cache-control", "no-cache");

const getNoCache = {
  method: "GET",
  headers: headers,
};

/**
 * Create a new tile layer.
 * @param {string} url - Base URL of tiles.
 * @param {object} layer - Layer object of tile layer.
 * @return {ImageMapType} Tile layer.
 */
export const tileLayerFromURL = async function (url, layer) {
  if (!url.endsWith("/")) url = url + "/";
  // if(layer.name=="River Floods" ){
  //     let tileLayer = new window.google.maps.ImageMapType({
  //         name: layer.name,
  //         opacity: layer.opacity || 1,
  //         getTileUrl: (coord, zoom) => {
  //             if(layer.name=="Surface Water Floods" && zoom < 15){
  //                 return null;
  //             }
  //             else if(layer.name=="River Floods" ){

  //                 console.log("test 101")
  //                 let test=fromPointToLatLn(coord)
  //                 console.log(test)
  //                 if(insidePolygon([  test.x, test.y ], UK_POLYGON)){
  //                     console.log("fits")
  //                     return [url, zoom, '/', coord.x, '/', coord.y, '.png'].join('');
  //                 }
  //                 else{
  //                     console.log("doesnt fit")
  //                     return null;
  //                 }

  //             }
  //             else{
  //             return [url, zoom, '/', coord.x, '/', coord.y, '.png'].join('');
  //             }
  //         },
  //         tileSize: new window.google.maps.Size(256, 256)
  //     });
  // }

  let tileLayer = new window.google.maps.ImageMapType({
    name: layer.name,
    opacity: layer.opacity || 1,
    getTileUrl: (coord, zoom) => {
      if (layer.name == "Surface Water Floods" && zoom < 15) {
        return null;
      } else if (layer.name == "River Floods") {
        //new window.google.maps.Projection.getProjection().fromLatLngToPoint(latLng)

        console.log("test 101");
        let test = fromPointToLatLn(coord, zoom);
        console.log(test);
        if (insidePolygon([test.lat, test.lng], UK_POLYGON)) {
          console.log("fits");
          //return null
          return [url, zoom, "/", coord.x, "/", coord.y, ".png"].join("");
        } else {
          console.log("doesnt fit");
          //return null
          return [
            "https://europetile.balkerne.com/",
            zoom,
            "/",
            coord.x,
            "/",
            coord.y,
            ".png",
          ].join("");
          //return ["https://api.climacell.co/v3/weather/layers/precipitation/now/", zoom, '/', coord.x, '/', coord.y, '.png?apikey=6DHI8crkn8GSO7F1W3SvIaJzZ1PGmOIq'].join('');
        }
      } else {
        return [url, zoom, "/", coord.x, "/", coord.y, ".png"].join("");
      }
    },
    tileSize: new window.google.maps.Size(256, 256),
  });

  return tileLayer;
};

function fromPointToLatLn(point, zoom) {
  // return {
  //  lat: (2 * Math.atan(Math.exp((point.y - 128) / -(256 / (2 * Math.PI)))) -
  //         Math.PI / 2)/ (Math.PI / 180),
  //  lng:  (point.x - 128) / (256 / 360)
  // };
  var n = Math.PI - (2 * Math.PI * point.y) / Math.pow(2, zoom);
  return {
    lat: (180 / Math.PI) * Math.atan(0.5 * (Math.exp(n) - Math.exp(-n))),
    lng: (point.x / Math.pow(2, zoom)) * 360 - 180,
  };
}

//CHECKS IF A POINT IS INSIDE OF A POLYGON
function insidePolygon(point, vs) {
  // ray-casting algorithm based on
  // https://wrf.ecse.rpi.edu/Research/Short_Notes/pnpoly.html/pnpoly.html

  var x = point[0],
    y = point[1];

  var inside = false;
  for (var i = 0, j = vs.length - 1; i < vs.length; j = i++) {
    var xi = vs[i][0],
      yi = vs[i][1];
    var xj = vs[j][0],
      yj = vs[j][1];

    var intersect =
      yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
    if (intersect) inside = !inside;
  }

  return inside;
}

/**
 * Load GeoJSON data and add it to a new data layer
 * @param {string} url - Base URL of data.
 * @return {Data} Data layer.
 */
export const dataLayerFromURL = async function (url, secret) {
  let authstr = "Bearer " + secret;

  console.log("url");
  console.log(url);

  let result = null;

  const headers = {
    "Content-Type": "application/json",
    Authorization: authstr,
  };

  await axios({ method: "get", url: url, headers: { Authorization: authstr } })
    .then((res) => {
      result = res.data;
      console.log("data retrieved");
      console.log(res);
    })
    .catch((err) => {
      console.log("FAILURE data!!");
      console.log(err);
    });

  //let dataRes = await fetch(url, getNoCache);
  //let data = await dataRes.json();
  console.log(url);
  console.log(result);

  let layer = new window.google.maps.Data();
  layer.addGeoJson(result);

  return layer;
};

/**
 * Load GeoJSON data and add it to a new data layer
 * @param {string} url - Base URL of data.
 * @return {Data} Data layer.
 */
export const dataLayerFromURLFormPropertyRisk = async function (
  url,
  locations,
  secret
) {
  let authstr = "Bearer " + secret;

  let result = null;

  const headers = {
    "Content-Type": "application/json",
    Authorization: authstr,
  };

  let data = { location_list: locations };

  await axios({
    method: "post",
    url: url,
    headers: { Authorization: authstr },
    data: data,
  })
    .then((res) => {
      console.log("SUCCESS!!");
      console.log(res.data);
      console.log("Properties by risk retrieved from the database.");
      result = res.data;
    })
    .catch((err) => {
      console.log("FAILURE!!");
      console.log(err);

      result = null;
    });

  console.log(result);

  let layer = new window.google.maps.Data();
  layer.addGeoJson(result);

  return layer;
};

/**
 * Load GeoJSON data and add it to a heatmap layer
 * @param {string} url - Base URL of data.
 * @return {HeatmapLayer} Heatmap layer.
 */
export const heatmapLayerFromURL = async function (url) {
  let dataRes = await fetch(url, getNoCache);
  let data = await dataRes.json();

  let points = [];
  data.features.forEach((feature) => {
    let geom = feature.geometry;
    if (geom) {
      let coords = feature.geometry.coordinates;
      let lng = coords[0];
      let lat = coords[1];
      points.push(new window.google.maps.LatLng(lat, lng));
    }
  });

  let layer = new window.google.maps.visualization.HeatmapLayer({
    data: points,
  });

  return layer;
};
/**
 * Show a data layer on a map.
 * @param {Map} map - Map to show layer on.
 * @param {Data} layer - Layer to show.
 */
export const showDataLayer = async function (map, layer) {
  if (layer) {
    if (!layer.getMap()) layer.setMap(map);
  }
};

/**
 * Show a tile layer on a map.
 * @param {Map} map - Map to show layer on.
 * @param {ImageMapType} layer - Layer to show.
 */
export const showTileLayer = async function (map, layer) {
  if (layer) {
    let idx = map.overlayMapTypes.indexOf(layer);
    if (idx === -1) map.overlayMapTypes.push(layer);
  }
};

/**
 * Hide a data layer on a map.
 * @param {Map} map - Map to hide layer on.
 * @param {Data} layer - Layer to hide.
 */
export const hideDataLayer = async function (map, layer) {
  if (layer) {
    if (layer.getMap()) layer.setMap(null);
  }
};

/**
 * Hide a tile layer on a map.
 * @param {Map} map - Map to hide layer on.
 * @param {ImageMapType} layer - Layer to hide.
 */
export const hideTileLayer = async function (map, layer) {
  if (layer) {
    let idx = map.overlayMapTypes.indexOf(layer);
    if (idx > -1) map.overlayMapTypes.removeAt(idx);
  }
};

/**
 * Toggle a data layer on a map.
 * @param {Map} map - Map to toggle layer on.
 * @param {Data} layer - Layer to toggle.
 */
export const toggleDataLayer = async function (map, layer) {
  if (layer) {
    if (layer.getMap()) layer.setMap(null);
    else layer.setMap(map);
  }
};

/**
 * Toggle a data layer on a map.
 * @param {Map} map - Map to toggle layer on.
 * @param {ImageMapType} layer - Layer to toggle.
 */
export const toggleTileLayer = async function (map, layer) {
  if (layer) {
    let idx = map.overlayMapTypes.indexOf(layer);
    if (idx === -1) map.overlayMapTypes.push(layer);
    else map.overlayMapTypes.removeAt(idx);
  }
};

export const processPoints = function (geometry, callback, thisArg) {
  if (geometry instanceof window.google.maps.LatLng) {
    callback.call(thisArg, geometry);
  } else if (geometry instanceof window.google.maps.Data.Point) {
    callback.call(thisArg, geometry.get());
  } else {
    geometry.getArray().forEach(function (g) {
      processPoints(g, callback, thisArg);
    });
  }
};

export const zoom = function (map, layer) {
  var bounds = new window.google.maps.LatLngBounds();
  layer.forEach(function (feature) {
    processPoints(feature.getGeometry(), bounds.extend, bounds);
  });
  map.fitBounds(bounds);
};
