import React, { useState, useEffect } from 'react'

const FileWidget = ({ ...props }) => {
  return (
    <div id={props.id}>
      <input
        type="file"
        id={props.id}
        multiple="multiple"
        onChange={props.onFileSelect}
        onClick={props.clearFileUpload}
      />
      <div id={`${props.id}-container`} style={{ display: 'flex', flexWrap: 'wrap' }}></div>
    </div>
  )
}

export default FileWidget
