import React from "react";
import "./Displaycrimerisk.css";
import { Riskrating } from "../maps/riskrating";
import recommendations_extract from "../../tempjson/recommendations.json";

class Recommendation_notice extends React.Component {
  render() {
    return (
      <div className="notice_text">
        <div className="notice_grid">
          <div>
            {" "}
            <img src={require("../../images/check-mark.jpg")} />
          </div>
          <div>
            {" "}
            {this.props.recommendation}
            <a href={this.props.link}>{this.props.filename}</a>
          </div>
        </div>
      </div>
    );
  }
}

export class Displayfirerisk extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      risk_rating: this.props.riskrating,
      currentrecommendations: [],
      selected_crime: "None selected",
    };
  }
  async componentDidMount() {
    this.setState({
      currentrecommendations: recommendations_extract.defaultfirerecommendation,
    });
  }

  Riskchartscore() {
    switch (this.state.risk_rating) {
      case 1:
        return 1;

      case 2:
        return 2;

      case 3:
        return 3;

      case 4:
        return 6;

      default:
        return 0;
    }
  }
  setCurrentRecommendations(crime_type) {
    console.log("Map Recommendaitons called");

    let setrecommendation = recommendations_extract.norecommendation;

    this.setState({ selected_crime: crime_type });

    if (this.props.orgid == 4) {
      switch (crime_type) {
        case "Fire false alarm - due to apparatus":
          setrecommendation =
            recommendations_extract.long_term_caravan_false_alarm;
          break;

        case "Primary Fire - dewewlling or other building":
          setrecommendation =
            recommendations_extract.long_term_caravan_primary_fire;
          break;

        case "Secondary Fire - woodland and crops":
          setrecommendation =
            recommendations_extract.long_term_caravan_secondary_fire_woodland;
          break;

        case "Secondary Fire - refuse and refuse sites":
          setrecommendation =
            recommendations_extract.long_term_caravan_secondary_fire_refuse;
          break;
        default:
          setrecommendation = recommendations_extract.norecommendation;
          break;
      }
    } else {
      switch (crime_type) {
        case "Fire false alarm - due to apparatus":
          setrecommendation =
            recommendations_extract.long_term_retail_false_alarm;
          break;

        case "Primary Fire - dewewlling or other building":
          setrecommendation =
            recommendations_extract.long_term_retail_primary_fire;
          break;

        case "Secondary Fire - woodland and crops":
          setrecommendation =
            recommendations_extract.long_term_retail_secondary_fire_woodland;
          break;

        case "Secondary Fire - refuse and refuse sites":
          setrecommendation =
            recommendations_extract.long_term_retail_secondary_fire_refuse;
          break;
        default:
          setrecommendation = recommendations_extract.norecommendation;
          break;
      }
    }

    this.setState({ currentrecommendations: setrecommendation });
  }
  mapRecommendations = () => {
    {
    }
    if (
      this.state.currentrecommendations &&
      this.state.currentrecommendations.length
    ) {
      return this.state.currentrecommendations.map(
        (recommendationList, index) => (
          <Recommendation_notice
            recommendation={recommendationList.description}
            filename={recommendationList.filename}
            link={recommendationList.link}
            key={index}
          />
        )
      );
    }
  };

  render() {
    return (
      <div className="Crimeriskhighlights">
        <h3 className="Crimeriskhighlightssheading">Fire Statistics</h3>
        <div className="crimeoverview">
          <p>
            The data below shows fire rates in your area. 0 equals lowest and
            100 equals highest ranked fire rate.
          </p>
        </div>
        <div className="Crimeriskcontent">
          <div className="crimstats">
            <div
              id="stat_crime"
              onClick={() =>
                this.setCurrentRecommendations(
                  "Fire false alarm - due to apparatus"
                )
              }
            >
              <Riskrating
                title={"Fire false alarm - due to apparatus"}
                risk_rating={
                  Math.round(
                    this.props.crime_data_prop.false_alarm_apparatus
                  ) || 0
                }
                max_score={100}
                rating_text={
                  Math.round(
                    this.props.crime_data_prop.false_alarm_apparatus
                  ) || 0
                }
              />
            </div>

            <div
              id="stat_crime"
              onClick={() =>
                this.setCurrentRecommendations(
                  "Primary Fire - dewewlling or other building"
                )
              }
            >
              <Riskrating
                title={"Primary fire - dwelling or other building"}
                risk_rating={
                  Math.round(
                    this.props.crime_data_prop.primary_fire_dwelling
                  ) || 0
                }
                max_score={100}
                rating_text={
                  Math.round(
                    this.props.crime_data_prop.primary_fire_dwelling
                  ) || 0
                }
              />
            </div>

            <div
              id="stat_crime"
              onClick={() =>
                this.setCurrentRecommendations(
                  "Secondary Fire - woodland and crops"
                )
              }
            >
              <Riskrating
                title={"Secondary Fire - woodland and crops"}
                risk_rating={
                  Math.round(
                    this.props.crime_data_prop.secondary_fire_grassland_woodland
                  ) || 0
                }
                max_score={100}
                rating_text={
                  Math.round(
                    this.props.crime_data_prop.secondary_fire_grassland_woodland
                  ) || 0
                }
              />
            </div>

            <div
              id="stat_crime"
              onClick={() =>
                this.setCurrentRecommendations(
                  "Secondary Fire - refuse and refuse sites"
                )
              }
            >
              <Riskrating
                title={"Secondary Fire - refuse and refuse sites"}
                risk_rating={
                  Math.round(
                    this.props.crime_data_prop.secondary_fire_refuse_containers
                  ) || 0
                }
                max_score={100}
                rating_text={
                  Math.round(
                    this.props.crime_data_prop.secondary_fire_refuse_containers
                  ) || 0
                }
              />
            </div>
          </div>
          <div className="crimerecommendation">
            <h4 className="crimerecommendationheading">
              Recommendations for : {this.state.selected_crime}{" "}
            </h4>
            {this.mapRecommendations()}
          </div>
        </div>
      </div>
    );
  }
}
export default Displayfirerisk;
