import { systemConstants } from "../_constants";
import axios from "axios";
import * as urls from "../../components/maps/urls";
import demoAlerts from "../../tempjson/alertsNew.json";

export async function getAlertLocations(locations_list, secret) {
  var t0 = performance.now();

  //let authstr = "Bearer "+ secret

  let payload = null;

  //   const headers = {
  //     'Content-Type': 'application/json',
  //     'Authorization': authstr,

  // };

  let data = { location_list: locations_list };
  await axios({
    method: "post",
    url: urls.API_GATEWAY + "locations/alert-locations",
    data: data,
  })
    .then((res) => {
      console.log(res.data);
      payload = res.data;

      if (
        payload === "null" ||
        payload === null ||
        payload === "Failure" ||
        typeof payload === "undefined"
      ) {
        payload = null;
        console.log("Alert-locations getting ERROR");
      } else if (payload === "Invalid Details") {
        console.log(
          "Credentials for getting alert-locations, details are INVALID"
        );
        payload = null;
      } else if (payload === "No alert locations found") {
        console.log("No alert-locations were found!");
        payload = null;
      } else {
        console.log("SUCCESS!!");
        console.log("Alert-Locations Data retrieved from the database.");
      }
    })
    .catch((err) => {
      payload = null;
      console.log("FAILURE!!");
      console.log(err);
    });

  var t1 = performance.now();
  console.log("Call to doSomething took " + (t1 - t0) + " milliseconds.");

  console.log("got alert locs");

  ////UNCOMMENT FOR DEMO ALERTs
  //payload = demoAlerts

  return payload;
}
