import { userConstants } from "../_constants";

// export function users( state={loggedIn: false, user : {}, userdata : {}}, action) {
//   console.log(action)
//   switch (action.type) {
//     case userConstants.getUserData:
//       return state;

//       // case userConstants.authenticate:
//       // return {
//       //   userdata
//       // };

//       case userConstants.checkUser:
//         console.log(loggedIn)
//         console.log(user)

//         console.log(loggedIn==true)
//         console.log(user==true)

//         if(!(loggedIn||user)){
//           return Object.assign({}, state, {
//             loggedIn:false,
//             user:{},
//             userData:{}
//           });

//         }

//       case userConstants.LOGIN_SUCCESS:
//         return Object.assign({}, state, {
//           user: action.data,
//           loggedIn: true
//         });

//     case userConstants.setUserData:
//       return Object.assign({}, state, {
//         userdata: action.data
//       });
//     case userConstants.changedPassword:
//       return Object.assign({}, state, {
//         loggedIn: true,
//         user: action.data
//       });
//     case userConstants.LOGOUT:
//       return Object.assign({}, state, {
//         loggedIn:false,
//         user:{},
//         userdata: {}
//       });
//       default:
//       return state

//   }
// }

export function userData(data = {}, action) {
  // console.log(action)
  switch (action.type) {
    case userConstants.getUserData:
      return data;

    case userConstants.clearUserData:
      return { data: null };

    case userConstants.setUserData:
      return {
        data: action.data,
      };
    case userConstants.updateUserLocations:
      return { data: action.data };
    default:
      return data;
  }
}

export function user(user = {}, action) {
  console.log(action);
  switch (action.type) {
    case userConstants.checkUser:
      console.log(user);

      console.log(user == true);

      if (!user) {
        return {
          user: null,
        };
      } else {
        return {
          user,
        };
      }

    case userConstants.UPDATE_TOKENS:
      return {
        user: action.data,
      };

    case userConstants.LOGIN_SUCCESS:
      return {
        user: action.data,
      };

    case userConstants.changedPassword:
      return {
        user: action.data,
      };
    case userConstants.LOGOUT:
      return {
        user: null,
      };
    default:
      return user;
  }
}

export function userLogged(
  state = { loggedIn: false, organisation: null },
  action
) {
  //  console.log(action)
  switch (action.type) {
    case userConstants.checkUserLogged:
      console.log(state.loggedIn);

      console.log(state.loggedIn == true);

      if (!state.loggedIn) {
        return Object.assign({}, state, {
          loggedIn: false,
          organisation: null,
        });
      } else {
        return Object.assign({}, state, {
          state,
        });
      }
    case userConstants.LOGIN_SUCCESS_LOGGED:
      return Object.assign({}, state, {
        loggedIn: true,
        organisation: null,
      });

    case userConstants.changedPasswordLogged:
      return Object.assign({}, state, {
        loggedIn: true,
        organisation: null,
      });
    case userConstants.LOGOUT_LOGGED:
      return Object.assign({}, state, {
        loggedIn: false,
        organisation: null,
      });
    default:
      return state;
  }
}
