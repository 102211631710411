import React, { Component } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import AddIncident from "./addincident";
import TimePassed from "../general/TimePassed";
import IconButton from "../general/IconButton";
import c from "./NewsCard.module.scss";

export class NewsCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAddIncidents: false,
    };
  }

  toggleIncidentPopup = () => {
    this.setState({ showAddIncidents: !this.state.showAddIncidents });
  };

  displayIncidentPopup = () => (
    <div>
      {this.state.showAddIncidents ? (
        <>
          <AddIncident
            text='Click "Close Button" to hide popup'
            onClickClose={this.toggleIncidentPopup.bind(this)}
            propid={this.props.id}
            incident={this.props.link}
            description={this.props.snippet}
            updateLossIncidents={this.props.updateLossIncidents}
            secret={this.props.secret}
          />
        </>
      ) : null}
    </div>
  );

  markIrrelevant = () => {
    this.props.removeMedia(this.props.news_id);
  };

  displayActions = () => (
    <DropdownButton
      alignRight
      className={c.dropdown}
      title={
        <img src={require("../../images/card_icons/card-arrow-down.png")} />
      }
    >
      <Dropdown.Item onClick={this.toggleIncidentPopup.bind(this)}>
        Create Loss Incident
      </Dropdown.Item>
      <Dropdown.Item onClick={() => this.markIrrelevant()}>
        Mark Irrelevant
      </Dropdown.Item>
    </DropdownButton>
  );

  render = () => (
    <div className={c.card}>
      <div className={c.content}>
        <div className={c.top}>
          <TimePassed date={this.props.date} />
          <div className={c.controls}>
            <IconButton
              icon="external"
              onClick={() => window.open(this.props.link, "_blank")}
            />
            {this.displayActions()}
          </div>
        </div>
        <span className={c.title}>{this.props.title}</span>
        <p className={c.snippet}>
          "
          {this.props.snippet.length > 450
            ? this.props.snippet.substring(0, 450) + "..."
            : this.props.snippet}
          "
        </p>
      </div>
      <img className={c.img} src={this.props.icon} />
      {this.displayIncidentPopup()}
    </div>
  );
}

export default NewsCard;
