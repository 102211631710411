import React from 'react'
import PropTypes from 'prop-types'
import c from './TimePassed.module.scss'

const TimePassed = ({ date }) => {

  const convert = (date) => {
    const hours = (Date.now() - new Date(date)) / 3.6e6;
    const fn = (num, timeUnit) => {
      var num = Math.floor(num);
      return num + " " + (num == 1 ? timeUnit : timeUnit + "s");
    };
    let display;
    if (hours < 1) {
      display = "<1 hour";
    } else if (hours < 24) {
      display = fn(hours, "hour");
    } else if (hours < 720) {
      display = fn(hours / 24, "day");
    } else if (hours < 8640) {
      display = fn(hours / 720, "month");
    } else {
      display = fn(hours / 8640, "year");
    }
    return display + " ago";
  }

  let timePassed
  try {
    timePassed = convert(date)
  } catch {
    timePassed = ''
  }

  return (
    <span className={c.timePassed}>
      {timePassed}
    </span>
  )
}

TimePassed.propTypes = {
  date: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date)
  ])
}

export default TimePassed

