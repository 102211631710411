import React, { useState } from 'react'
import cn from 'classnames'
import c from './HeaderProperty.module.scss'
import { BrowserRouter as Router, Link, useHistory } from 'react-router-dom'
import Dropdown from 'react-bootstrap/Dropdown'
import { HeaderToggle, TextToggle, BurgerToggle } from 'balkerne-components/DropDown'
import AddMitigation from './riskReport/addMitigation'
import AddIncident from './riskReport/addincident'
import Can from 'balkerne-components/Can'

const PropertyHeader = props => {
  const [showAddMitigation, setShowAddMitigation] = useState(false)
  const [showAddIncidents, setShowAddIncidents] = useState(false)

  const renderPopups = () => (
    <div>
      {showAddMitigation ? (
        <AddMitigation
          text='Click "Close Button" to hide popup'
          onClickClose={() => setShowAddMitigation(false)}
          propid={props.locationData2.properties.id}
          updateMitigations={props.updateMitigations}
          secret={props.secret}
        />
      ) : null}
      {showAddIncidents ? (
        <AddIncident
          text='Click "Close Button" to hide popup'
          onClickClose={() => setShowAddIncidents(false)}
          propid={props.locationData2.properties.id}
          updateIncidents={props.updateIncidents}
          updateLossIncidents={props.updateLossIncidents}
          secret={props.secret}
        />
      ) : null}
    </div>
  )

  const riskReport = {
    pathname: '/riskreport',
    state: {
      alerts: props.alerts2,
      locationData: props.locationData2,
      activeweatherwarnings: props.activeweatherwarnings,
      flood_risks: props.flood_risks,
      mitigations: props.mitigations,
      lossincidents: props.lossincidents,
    },
  }

  const user_permissions = props.user_permissions
  const user_portfolio_type = props.user_portfolio_type
  const history = useHistory()
  const linkClass = url => cn(c.item, { [c.selected]: url === props.currentpage || url === props.currentpage })

  return (
    <div className={c.header} style={props.customstyle}>
      {/* Desktop Header */}
      <div className={c.container}>
        <div className={c.left}>
          <Dropdown>
            <Dropdown.Toggle as={HeaderToggle} src={require('../images/icons/SquaresWhite.svg')} />
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => history.push('/')}>Smart Resilience</Dropdown.Item>
              {/* <Dropdown.Item onClick={() => history.push('/SmartVault')}>Smart Vault</Dropdown.Item> */}
            </Dropdown.Menu>
          </Dropdown>
          <div className={c.logo}>
            <Link to="/">
              <img className={c.logoImage} src={require('../images/balkerne-logo.png')} />
            </Link>
          </div>

          <div className={c.navigation}>
            {user_portfolio_type != 'Single' ? (
              <Can
                portfolio_type={user_portfolio_type}
                actions="/"
                yes={() => (
                  <Link to={'/'} className={linkClass('/')}>
                    <span>Portfolio Dashboard</span>
                  </Link>
                )}
              />
            ) : (
              <Can
                portfolio_type={user_portfolio_type}
                actions="/"
                yes={() => (
                  <Link to={'/'} className={linkClass('/')}>
                    <span>Property Dashboard</span>
                  </Link>
                )}
              />
            )}
            {/* <Can portfolio_type={user_portfolio_type} actions='/'
              yes={() => <Link to={"/"} className={linkClass("/")}><span>Portfolio Dashboard</span></Link>}
              no={() => <Link to={"/"} className={linkClass("/")}><span>Property Dashboard</span></Link>}
            /> */}
            <Can
              portfolio_type={user_portfolio_type}
              actions="/riskreport"
              yes={() => (
                <Link to={riskReport} className={linkClass('/Operationsmap')}>
                  <span>Risk Report</span>
                </Link>
              )}
            />
          </div>
        </div>

        <div className={c.right}>
          <div className={c.dropdowns}>
            <Dropdown>
              <Dropdown.Toggle as={TextToggle} light={true}>
                <span className={c.item}>Add</span>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Can
                  permissions={user_permissions}
                  actions="mitigations:add"
                  yes={() => (
                    <Dropdown.Item onClick={() => setShowAddMitigation(true)}>Add New Mitigation</Dropdown.Item>
                  )}
                />
                <Dropdown.Item
                  onClick={() =>
                    history.push({
                      pathname: '/FormSelector',
                      state: {
                        typeID: 2,
                      },
                    })
                  }>
                  Report an Incident
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() =>
                    history.push({
                      pathname: '/FormSelector',
                      state: {
                        typeID: 3,
                      },
                    })
                  }>
                  Complete a Survey
                </Dropdown.Item>
                <Can
                  permissions={user_permissions}
                  actions="loss-incidents:add"
                  yes={() => <Dropdown.Item onClick={() => setShowAddIncidents(true)}>Add New Incident</Dropdown.Item>}
                />

                <Can
                  permissions={user_permissions}
                  actions="locations:add"
                  yes={() => (
                    <Dropdown.Item onClick={() => history.push('/MainAddLocation')}>Add New Property</Dropdown.Item>
                  )}
                />
                <Can
                  permissions={user_permissions}
                  actions={['users:add', 'users:add-any', 'users:add-all', 'users:add-admin']}
                  yes={() => (
                    <Dropdown.Item onClick={() => history.push('/MainAddUsers')}>Add Management User</Dropdown.Item>
                  )}
                />
                <Can
                  permissions={user_permissions}
                  actions="locations:add"
                  yes={() => (
                    <Dropdown.Item onClick={() => history.push('/MainBulkAddProperties')}>
                      Add Bulk Properties
                    </Dropdown.Item>
                  )}
                />
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown>
              <Dropdown.Toggle as={TextToggle} light={true}>
                <span className={c.item}>Options</span>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Can
                  permissions={user_permissions}
                  actions="pass"
                  yes={() => (
                    <Dropdown.Item onClick={() => history.push('/ChangeAccDetails')}>Account Settings</Dropdown.Item>
                  )}
                />
                <Can
                  permissions={user_permissions}
                  actions="users:read"
                  yes={() => (
                    <Dropdown.Item onClick={() => history.push('/UserListPage')}>User List Page</Dropdown.Item>
                  )}
                />
                <Can
                  permissions={user_permissions}
                  actions="locations:edit"
                  yes={() => (
                    <Dropdown.Item onClick={() => history.push('/MainEditLocation')}>Edit Property</Dropdown.Item>
                  )}
                />
                <Can
                  permissions={user_permissions}
                  actions="users:assign"
                  yes={() => (
                    <Dropdown.Item onClick={() => history.push('/ReassignUserProperties')}>
                      Reassign User Properties
                    </Dropdown.Item>
                  )}
                />
              </Dropdown.Menu>
            </Dropdown>
          </div>

          <Link to="/" onClick={props.logout}>
            <img className={c.signout} src={require('../images/sign-out.png')} />
          </Link>
        </div>
      </div>

      {/* Mobile header */}
      <div className={c.smallContainer}>
        <div className={c.smallLeft}>
          <Dropdown>
            <Dropdown.Toggle as={HeaderToggle} src={require('../images/icons/SquaresWhite.svg')} />
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => history.push('/')}>Smart Resilience</Dropdown.Item>
              {/* <Dropdown.Item onClick={() => history.push('/SmartVault')}>Smart Vault</Dropdown.Item> */}
            </Dropdown.Menu>
          </Dropdown>
          <Link to="/">
            <img className={c.smallLogo} src={require('../images/balkerne-logo.png')} />
          </Link>
        </div>
        <Dropdown>
          <Dropdown.Toggle as={BurgerToggle} />
          <Dropdown.Menu>
            <Can
              portfolio_type={user_portfolio_type}
              actions="/"
              yes={() => (
                <Dropdown.Item onClick={() => history.push('/UserDashboard')}>Property Dashboard</Dropdown.Item>
              )}
              no={() => <Dropdown.Item onClick={() => history.push('/')}>Portfolio Dashboard</Dropdown.Item>}
            />
            <Can
              portfolio_type={user_portfolio_type}
              actions="/riskreport"
              yes={() => (
                <Dropdown.Item className="d-none d-md-block" onClick={() => history.push(riskReport)}>
                  Risk Report
                </Dropdown.Item>
              )}
            />
            <Dropdown.Divider />
            <Can
              permissions={user_permissions}
              actions="pass"
              yes={() => (
                <Dropdown.Item className="d-none d-md-block" onClick={() => history.push('/ChangeAccDetails')}>
                  Account Settings
                </Dropdown.Item>
              )}
            />
            <Can
              permissions={user_permissions}
              actions="users:read"
              yes={() => (
                <Dropdown.Item className="d-none d-md-block" onClick={() => history.push('/UserListPage')}>
                  User List Page
                </Dropdown.Item>
              )}
            />
            <Can
              permissions={user_permissions}
              actions="locations:edit"
              yes={() => (
                <Dropdown.Item className="d-none d-md-block" onClick={() => history.push('/MainEditLocation')}>
                  Edit Property
                </Dropdown.Item>
              )}
            />
            <Can
              permissions={user_permissions}
              actions="users:assign"
              yes={() => (
                <Dropdown.Item className="d-none d-md-block" onClick={() => history.push('/ReassignUserProperties')}>
                  Reassign User Properties
                </Dropdown.Item>
              )}
            />
            <Can
              permissions={user_permissions}
              actions="mitigations:add"
              yes={() => (
                <Dropdown.Item className="d-none d-md-block" onClick={() => setShowAddMitigation(true)}>
                  Add New Mitigation
                </Dropdown.Item>
              )}
            />
            <Can
              permissions={user_permissions}
              actions="loss-incidents:add"
              yes={() => (
                <Dropdown.Item className="d-none d-md-block" onClick={() => setShowAddIncidents(true)}>
                  Add New Incident
                </Dropdown.Item>
              )}
            />
            <Dropdown.Item
              onClick={() =>
                history.push({
                  pathname: '/FormSelector',
                  state: {
                    typeID: 2,
                  },
                })
              }>
              Report an Incident
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item
              onClick={() =>
                history.push({
                  pathname: '/FormSelector',
                  state: {
                    typeID: 3,
                  },
                })
              }>
              Complete a Survey
            </Dropdown.Item>
            {/* <Dropdown.Item onClick={() => history.push('/FormSelector')}>Report an Incident</Dropdown.Item> */}
            <Dropdown.Divider />
            <Dropdown.Item onClick={() => props.logout()}>Sign Out</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>

      {/* Popups */}
      {renderPopups()}
    </div>
  )
}

export default PropertyHeader
