import React from "react"
import c from "./PropertySubHeader.module.scss"

const PropertySubHeader = ({
  propertyname,
  propertyaddress,
  propertyreference,
  organisationlogo,
}) => (
  <div className={c.header}>
    <div className={c.company}>
      <div className={c.property}>{ propertyname }</div>
      <div className={c.details}>
        <div className={c.detail}>
          <img src={require("../images/header-loc.svg")} />
          <span>{ propertyaddress }</span>
        </div>
        <div className={c.detail}>
          <img src={require("../images/header-hash.svg")} />
          <span>{ propertyreference }</span>
        </div>
      </div>
    </div>
    
    <img src={organisationlogo} className={c.logo} />
  </div>
);

export default PropertySubHeader
