import React from "react";

import "./Main.css";

/**
 * Main window of the application, containing the map.
 */
export class Main extends React.Component {
  render() {
    return <div className="main">{this.props.children}</div>;
  }
}

export default Main;
