import React from 'react'
import { Dropdown, DropdownButton } from 'react-bootstrap'
import c from './DropDown.module.scss'
import cn from 'classnames'

export const DropDown = props => (
  <div className={c.dropdown}>
    <DropdownButton title={<span className={cn([c.text, { [c.lightText]: props.light }])}>{props.text}</span>}>
      {props.options.map(([text, dest]) => {
        const attr = typeof dest === 'function' ? { onClick: dest } : { href: dest }
        return <Dropdown.Item {...attr}>{text}</Dropdown.Item>
      })}
    </DropdownButton>
  </div>
)

export const TextToggle = React.forwardRef(({ children, onClick, light, ...props }, ref) => (
  <div
    ref={ref}
    onClick={e => {
      e.preventDefault()
      onClick(e)
    }}
    className={c.textToggle}>
    <div className={cn([c.text, { [c.lightText]: light }])}>{children}</div>
    <div className={cn([c.arrow, { [c.lightBackground]: light }])}></div>
  </div>
))

export const HeaderToggle = React.forwardRef(({ onClick, src }, ref) => (
  <div
    ref={ref}
    onClick={e => {
      e.preventDefault()
      onClick(e)
    }}
    className={c.headerToggle}>
    <img className={c.icon} src={src} />
  </div>
))

export const OptionToggle = React.forwardRef(({ onClick, src }, ref) => (
  <div
    ref={ref}
    onClick={e => {
      e.preventDefault()
      onClick(e)
    }}
    className={c.optionToggle}>
    <img className={c.icon} src={src} />
  </div>
))

export const BurgerToggle = React.forwardRef(({ onClick, src }, ref) => (
  <div
    ref={ref}
    onClick={e => {
      e.preventDefault()
      onClick(e)
    }}
    className={c.burgerToggle}>
    <div className={c.burger}>
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>
))

export default DropDown
