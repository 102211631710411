import React from 'react'
import PropTypes from 'prop-types'
import c from './Container.module.scss'

const Container = ({ children }) => {
  return <div className={c.container}>{children}</div>
}

Container.Center = ({ children }) => (
  <div className={c.centerContainer}>
    <div className={c.center}>{children}</div>
  </div>
)

Container.centerResponsive = ({ children }) => (
  <div className={c.centerContainer}>
    <div className={c.centerResponsive}>{children}</div>
  </div>
)

Container.Center.displayName = 'Container.Center'
Container.centerResponsive.displayName = 'Container.centerResponsive'

export default Container
