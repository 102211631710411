import { systemConstants } from "../_constants";
import axios from "axios";
import * as urls from "../../components/maps/urls";

export async function getFloodRisks(location_id, secret) {
  let data = null;

  let params = { propid: location_id };

  await axios({
    method: "get",
    url: urls.API_GATEWAY + "flood/floodrisks",
    params: params,
  })
    .then((res) => {
      data = res.data;
      console.log(data);
      if (
        data === "null" ||
        data === null ||
        data === "Failure" ||
        typeof data === "undefined"
      ) {
        console.log("Error retrieving flood risks ");
        data = null;
      } else if (data === "Invalid Details") {
        console.log("Details for floodrisk retrieval are INVALID");
        data = null;
      } else if (data === "Flood risks not found") {
        console.log("Flood risk data was not found");
        data = null;
      } else {
        console.log("SUCCESS!!");
        console.log("flood risk Data retrieved from the database.");
      }
    })
    .catch((err) => {
      data = null;
      console.log("FAILURE!!");
      console.log(err);
    });

  return data;
}
