import { systemConstants } from "../_constants";
import axios from "axios";
import * as urls from "../../components/maps/urls";

export async function getWeatherWarnings(secret) {
  let payload = null;

  let params = { active: true };

  await axios({
    method: "get",
    url: urls.API_GATEWAY + "flood/weather-warnings",
    params: params,
  })
    .then((res) => {
      payload = res.data;
      console.log(payload);
      if (
        payload === "null" ||
        payload === null ||
        payload === "Failure" ||
        typeof payload === "undefined"
      ) {
        console.log("Error retrieving weather warning");
        payload = null;
      } else if (payload === "No weather warnings found") {
        console.log("No weather warnings found");
        payload = null;
      } else {
        console.log("SUCCESS!!");
        console.log("weather warnings Data retrieved from the database.");
      }
    })
    .catch((err) => {
      payload = null;
      console.log("FAILURE!!");
      console.log(err);
    });

  return payload;
}
