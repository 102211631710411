import React from "react";
import "./Controls.css";

export class ToggleSwitch extends React.Component {
  render() {
    return (
      <span>
        <input
          type="checkbox"
          className="input__switch"
          id={this.props.id}
          checked={this.props.checked}
          onChange={this.props.onChange}
        />
        <label className="input__switch_label" htmlFor={this.props.id}>
          Toggle
        </label>
      </span>
    );
  }
}

export class CloseButton extends React.Component {
  render() {
    return (
      <button
        id={this.props.id}
        className="button__close"
        onClick={this.props.onClick}
      />
    );
  }
}
