import React from 'react'
import registerServiceWorker from './registerServiceWorker'
import Amplify from '@aws-amplify/core'
import Config from './aws-exports'
import Mainmenu from './pages/MainMenu'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { store, persistor } from './redux/_helpers'
import { PersistGate } from 'redux-persist/integration/react'
import './index.scss'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
/*
Sentry.init({
  dsn: 'https://9ae9d0eb61fe428a9fc14a41d41404c9@o399562.ingest.sentry.io/6064497',
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
})
*/
Amplify.configure(Config)
require('dotenv').config()

render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Mainmenu />{' '}
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
)
registerServiceWorker()
