import React from "react";
import "./Displaymetaltheftrisk.css";
import { Riskrating } from "../maps/riskrating";

export class DisplayMetalTheft extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      serverurl: this.props.serverurl,
      attachment: this.props.attachment,
    };
  }

  Riskchartscore() {
    if (this.props.Metaltheftdata) {
      switch (this.props.Metaltheftdata.overall_risk_level) {
        case "Very low":
          return 1;

        case "Low":
          return 2;

        case "Medium":
          return 3;

        case "High":
          return 6;

        default:
          return 0;
      }
    } else {
      return 0;
    }
  }

  render() {
    let metaltheftdata = this.props.Metaltheftdata;
    if (metaltheftdata) {
      return (
        <div className="Metathefts">
          <h3 className="Metatheftheading">Metal Theft Risk</h3>
          <div className="Metatheftcontainer">
            <div>
              <Riskrating
                title="Overall Risk"
                risk_rating={this.Riskchartscore()}
                max_score={6}
                rating_text={metaltheftdata.overall_risk_level}
              />
            </div>
            <div className="Metaltheftpart1">
              <p className="Labeltitle"></p>
              <b className="Columntitle">Feature Present</b>
              <b className="Columntitle">Lead Present(m2)</b>
              <p className="Labeltitle">Main Roof:</p>
              <p className="Labeltext">{metaltheftdata.main_roof.present}</p>
              <p className="Labeltext">
                {metaltheftdata.main_roof.amount_lead}
              </p>
              <p className="Labeltitle">Tower:</p>
              <p className="Labeltext">{metaltheftdata.tower.present}</p>
              <p className="Labeltext">{metaltheftdata.tower.amount_lead}</p>
              <p className="Labeltitle">North Isle</p>
              <p className="Labeltext">{metaltheftdata.north_aisle.present}</p>
              <p className="Labeltext">
                {metaltheftdata.north_aisle.amount_lead}
              </p>
              <p className="Labeltitle">South Isle</p>
              <p className="Labeltext">{metaltheftdata.south_aisle.present}</p>
              <p className="Labeltext">
                {metaltheftdata.south_aisle.amount_lead}
              </p>
              <p className="Labeltitle">Porch</p>
              <p className="Labeltext">{metaltheftdata.porch.present}</p>
              <p className="Labeltext">{metaltheftdata.porch.amount_lead}</p>
              <p className="Labeltitle">Other</p>
              <p className="Labeltext">{metaltheftdata.other.present}</p>
              <p className="Labeltext">{metaltheftdata.other.amount_lead}</p>
            </div>
            <div className="Metatheftlist">
              {/* <p className="Labeltitle">{props.titlename}:</p>
    <p className="Labeltext">{props.textname}</p> */}

              <p className="Labeltitle">Total Footprint :</p>
              <p className="Labeltext">{metaltheftdata.total_footprint}</p>
              <p className="Labeltitle">Area of Lead m2</p>
              <p className="Labeltext"> {metaltheftdata.lead_area} </p>
              <p className="Labeltitle">% of Roof with Lead</p>
              <p className="Labeltext"> {metaltheftdata.roof_lead_percent} </p>
              <p className="Labeltitle">Lead Volume in m3</p>
              <p className="Labeltext"> {metaltheftdata.lead_volume} </p>
              <p className="Labeltitle">Lead Tonnage</p>
              <p className="Labeltext"> {metaltheftdata.lead_tonnage} </p>
              <p className="Labeltitle">Ease of Access to Lead</p>
              <p className="Labeltext"> {metaltheftdata.lead_easy_access} </p>
              <p className="Labeltitle">Availability of Parking Locations</p>
              <p className="Labeltext">
                {" "}
                {metaltheftdata.parking_availability}{" "}
              </p>
              <p className="Labeltitle">Parking Location 1</p>
              <p className="Labeltext">
                {" "}
                {metaltheftdata.parking_location_1.lat},{" "}
                {metaltheftdata.parking_location_1.lon}{" "}
              </p>
              <p className="Labeltitle">Parking Location 2</p>
              <p className="Labeltext">
                {" "}
                {metaltheftdata.parking_location_2.lat},{" "}
                {metaltheftdata.parking_location_2.lon}{" "}
              </p>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="Metathefts">
          <h3 className="Metatheftheading">Metal Theft Risk</h3>
          <div className="Metatheftcontainer">
            <div>
              <Riskrating
                title="Overall Risk"
                risk_rating={this.Riskchartscore()}
                max_score={6}
                rating_text={""}
              />
            </div>
            <div className="Metaltheftpart1">
              <p className="Labeltitle"></p>
              <b className="Columntitle">Feature Present</b>
              <b className="Columntitle">Lead Present(m2)</b>
              <p className="Labeltitle">Main Roof:</p>
              <p className="Labeltext">{"Data not Loaded"}</p>
              <p className="Labeltext">{"Data not Loaded"}</p>
              <p className="Labeltitle">Tower:</p>
              <p className="Labeltext">{"Data not Loaded"}</p>
              <p className="Labeltext">{"Data not Loaded"}</p>
              <p className="Labeltitle">North Isle</p>
              <p className="Labeltext">{"Data not Loaded"}</p>
              <p className="Labeltext">{"Data not Loaded"}</p>
              <p className="Labeltitle">South Isle</p>
              <p className="Labeltext">{"Data not Loaded"}</p>
              <p className="Labeltext">{"Data not Loaded"}</p>
              <p className="Labeltitle">Porch</p>
              <p className="Labeltext">{"Data not Loaded"}</p>
              <p className="Labeltext">{"Data not Loaded"}</p>
              <p className="Labeltitle">Other</p>
              <p className="Labeltext">{"Data not Loaded"}</p>
              <p className="Labeltext">{"Data not Loaded"}</p>
            </div>
            <div className="Metatheftlist">
              {/* <p className="Labeltitle">{props.titlename}:</p>
    <p className="Labeltext">{props.textname}</p> */}

              <p className="Labeltitle">Total Footprint :</p>
              <p className="Labeltext">{"Data not Loaded"}</p>
              <p className="Labeltitle">Area of Lead m2</p>
              <p className="Labeltext"> {"Data not Loaded"}</p>
              <p className="Labeltitle">% of Roof with Lead</p>
              <p className="Labeltext"> {"Data not Loaded"}</p>
              <p className="Labeltitle">Lead Volume in m3</p>
              <p className="Labeltext"> {"Data not Loaded"}</p>
              <p className="Labeltitle">Lead Tonnage</p>
              <p className="Labeltext"> {"Data not Loaded"}</p>
              <p className="Labeltitle">Ease of Access to Lead</p>
              <p className="Labeltext"> {"Data not Loaded"}</p>
              <p className="Labeltitle">Availability of Parking Locations</p>
              <p className="Labeltext"> {"Data not Loaded"}</p>
              <p className="Labeltitle">Parking Location 1</p>
              <p className="Labeltext"> {"Data not Loaded"} </p>
              <p className="Labeltitle">Parking Location 2</p>
              <p className="Labeltext"> {"Data not Loaded"}} </p>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default DisplayMetalTheft;
