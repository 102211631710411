import { systemConstants } from "../_constants";
import axios from "axios";
import * as urls from "../../components/maps/urls";

export async function getHistoricalAlerts(location_id, country, secret) {
  console.log(country);
  let payload = null;

  let data = { propid: location_id, country: country };

  await axios({
    method: "post",
    url: urls.API_GATEWAY + "flood/historical-alerts-by-property",
    data: data,
  })
    .then((res) => {
      payload = res.data;

      if (
        payload === "null" ||
        payload === null ||
        payload === "Failure" ||
        typeof payload === "undefined"
      ) {
        payload = null;
        console.log("Error retrieving property historical alerts");
      } else if (payload === "Invalid Details") {
        console.log(
          "Details for property historical alerts retrieved are INVALID"
        );
        payload = null;
      } else if (payload === "No alerts found") {
        console.log("No alerts were found for that property.");
        payload = null;
      } else {
        console.log("SUCCESS!!");
        console.log(payload);
        console.log(
          "Historical Alert Data for Property retrieved from the database."
        );
      }
    })
    .catch((err) => {
      payload = null;
      console.log("FAILURE!!");
      console.log(err);
    });

  return payload;
}

export async function getHistoricalWeatherAlerts(location_id, secret) {
  let payload = null;

  let data = { propid: location_id };

  await axios({
    method: "post",
    url: urls.API_GATEWAY + "weather/historical-alerts-by-property",
    data: data,
  })
    .then((res) => {
      payload = res.data;
      console.log("SUCCESS!!");
      console.log(payload);
      console.log(
        "Historical Weather Alert Data for Property retrieved from the database."
      );

      if (
        payload === "null" ||
        payload === null ||
        payload === "Failure" ||
        typeof payload === "undefined"
      ) {
        payload = null;
        console.log("Error retrieving historic weather alerts");
      } else if (payload === "Invalid Details") {
        console.log(
          "Details for property historical alerts retrieved are INVALID"
        );
        payload = null;
      } else if (payload === "No weather alerts found") {
        console.log("No historic weather alerts found for the property");
        payload = null;
      } else {
        console.log("SUCCESS!!");
        console.log(payload);
        console.log(
          "Historical Alert Data for Property retrieved from the database."
        );
      }
    })
    .catch((err) => {
      payload = null;
      console.log("FAILURE!!");
      console.log(err);
    });

  return payload;
}
