import React from "react";
import {
  CircularTrack,
  CircularProgress,
  CircularInput,
} from "react-circular-input";
import "./Riskrating.css";

export class Riskrating extends React.Component {
  constructor(props) {
    super(props);
  }

  Riskcircle() {
    let value = this.props.risk_rating / this.props.max_score;

    return (
      <div className="riskratingitems">
        <div className="risk_rating">
          <p className="rating_text">{this.props.title}</p>
        </div>
        <div className="circularrating">
          <CircularInput value={value} radius="60">
            <CircularTrack strokeWidth={5} stroke="#eee" />
            <CircularProgress
              stroke={`hsl(${(1 - value) * 100}, 100%, 50%)`}
              strokeWidth={10}
            />
            <text
              x={60}
              y={50}
              textAnchor="middle"
              dy="0.3em"
              fontWeight="bold"
            >
              {this.props.rating_text}
            </text>
          </CircularInput>
        </div>
      </div>
    );
  }

  render() {
    return this.Riskcircle();
  }
}
