import React from "react";
import "./GoogleMap.css";
import * as styles from "./styles";
import _ from "lodash";
import "./GoogleMapEdit.css";
const google = window.google;
export class GoogleMapEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      feature: this.props.feature,
      polygon: null,
      marker: null,
      area: null,
      center: null,
      coords: null,
      done: false,
    };
    this.onScriptLoad = this.onScriptLoad.bind(this);
  }

  async onScriptLoad() {
    this.state.coords = this.props.coords;

    const map = await new window.google.maps.Map(
      document.getElementById(this.props.id),
      this.props.options
    );

    var centerControlDiv = document.createElement("div");
    // console.log(centerControlDiv)
    centerControlDiv = this.deleteButton(centerControlDiv, map);
    centerControlDiv = this.doneButton(centerControlDiv, map);
    //console.log(centerControlDiv)
    //centerControlDiv.index = 1;
    map.controls[window.google.maps.ControlPosition.RIGHT_CENTER].push(
      centerControlDiv
    );
    //console.log(centerControlDiv)
    this.state.map = map;

    // var drawingManager = await new window.google.maps.drawing.DrawingManager({
    //     drawingMode: window.google.maps.drawing.OverlayType.POLYGON,
    //     drawingControl: true,
    //     drawingControlOptions: {
    //       position: window.google.maps.ControlPosition.TOP_CENTER,
    //       drawingModes: ['polygon'],
    //       editable: true
    //     },
    //     markerOptions: {icon: 'https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png'},
    //     circleOptions: {
    //       fillColor: '#ffff00',
    //       fillOpacity: 1,
    //       strokeWeight: 5,
    //       clickable: false,
    //       editable: true,
    //       zIndex: 1
    //     }
    //   });
    //   drawingManager.setMap(map);

    //    window.google.maps.event.addListener(drawingManager, 'overlaycomplete', (event)=> {
    //     switch (event.type) {
    //     case window.google.maps.drawing.OverlayType.POLYGON:
    //             // if(this.state.polygon!=null){
    //             //     this.state.polygon.setMap(null);
    //             // }

    //         let polygon=event.overlay;
    //         this.state.reset = true;
    //         this.state.area = window.google.maps.geometry.spherical.computeArea(polygon.getPath());

    //             var bounds = new window.google.maps.LatLngBounds()
    //             polygon.getPath().forEach(function(element,index){bounds.extend(element)})

    //             this.state.center = bounds.getCenter()
    //            console.log(this.state.center)

    //            this.state.polygon = polygon;
    //            this.props.getCenter(this.state.center)
    //         this.props.getPolygon(polygon)
    //         this.props.getArea(this.state.area)
    //         //this.overlayClickListener(polygon);
    //     case window.google.maps.drawing.OverlayType.CIRCLE:
    //        // verifyMarkers(event.overlay, markers);
    //         break;
    //     case window.google.maps.drawing.OverlayType.MARKER:
    //         //marker=event.overlay;
    //         ///this.props.getMarker(marker)
    //         //output.innerHTML += "Marker #" + markers.length + " has been added <br />";
    //         break;
    //     default:
    //     }
    // }

    await createDeleteMenu();
    // );
    if (this.state.feature) {
      this.createPolygon();
    }

    // window.google.maps.event.addListener(drawingManager, 'click', () =>{
    //     if(reset==true&&tpolygon!=null){
    //         polygon.setMap(null);
    //         reset=false
    //     }
    //     console.log('Vertex removed from inner path.');
    //   });

    // if(center!= null){
    // var marker = new window.google.maps.Marker({
    //     position: { lat: 53.885501551104400, lng: -1.262298812800550 },
    //     map: map,
    //     title: 'Target Location'
    //   });

    //}

    this.props.onMapLoad(map);
  }
  createPolygon = () => {
    var map = this.state.map;

    window.myns = _.noConflict();
    var polygonsArray = _.map(
      this.state.feature.geometry.coordinates,
      function (entry) {
        return _.reduce(
          entry,
          function (list, polygon) {
            // This map() only transforms the data.
            _.each(
              _.map(polygon, function (point) {
                // Important: the lat/lng are vice-versa in GeoJSON
                return new window.google.maps.LatLng(point[1], point[0]);
              }),
              function (point) {
                list.push(point);
              }
            );

            return list;
          },
          []
        );
      }
    );

    let layer = new window.google.maps.Polygon({
      paths: polygonsArray,
      strokeColor: "#FF0000",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#FF0000",
      fillOpacity: 0.35,
      editable: true,
    });

    this.state.polygon = layer;

    //bermudaTriangle.setMap(map);
    console.log("123123123");
    //let layer = new window.google.maps.Data();

    //layer.setStyle(styles.dashboardLocation);
    //layer.addGeoJson(this.state.feature);

    layer.getPaths().forEach((path, index) => {
      window.google.maps.event.addListener(path, "insert_at", () => {
        console.log("points was added");
        this.state.polygon = layer;
      });

      window.google.maps.event.addListener(path, "remove_at", () => {
        console.log("Point was removed");
        this.state.polygon = layer;
      });

      window.google.maps.event.addListener(path, "set_at", () => {
        console.log("Point was moved");
        this.state.polygon = layer;
      });
    });

    var deleteMenu = new DeleteMenu();

    window.google.maps.event.addListener(layer, "rightclick", function (e) {
      // Check if click was on a vertex control point
      if (e.vertex === undefined) {
        return;
      }
      deleteMenu.open(map, layer.getPath(), e.vertex);
    });

    layer.setMap(map);
  };

  async componentDidMount() {
    if (!window.google) {
      console.log("LOAD 1");
      var s = document.createElement("script");

      s.type = "text/javascript";
      s.src = `https://maps.google.com/maps/api/js?v=quarterly&key=${this.props.apiKey}&libraries=drawing,visualization`;
      var x = document.getElementsByTagName("script")[0];
      x.parentNode.insertBefore(s, x);
      // Below is important.
      // We cannot access google.maps until it's finished loading
      s.addEventListener("load", async (e) => {
        await this.onScriptLoad();
      });
    }
    // else if (document.getElementsByTagName('Google Map Normal')[0]) {
    //     console.log("LOAD 3")
    //     let element = (document.getElementsByTagName('Google Map Normal')[0]);
    //     // element.tagName = "Google Map Draw"
    //     element.src =`https://maps.google.com/maps/api/js?v=quarterly&key=${this.props.apiKey}&libraries=drawing,visualization`;
    //     element.addEventListener('load',async e => {
    //         await this.onScriptLoad()
    //    })
    // }
    else {
      console.log("LOAD 3");
      await this.onScriptLoad();
    }
  }

  deleteShapes = () => {
    if (this.state.polygon != null) {
      this.state.polygon.setMap(null);
      this.createPolygon();
    }
    if (this.state.marker != null) {
      this.state.marker = null;
    }
    if (this.state.area != null) {
      this.state.area = null;
    }
    if (this.state.center != null) {
      this.state.center = null;
    }
  };

  savePolygon = () => {
    let polygon = this.state.polygon;

    this.state.area = window.google.maps.geometry.spherical.computeArea(
      polygon.getPath()
    );

    var bounds = new window.google.maps.LatLngBounds();
    polygon.getPath().forEach(function (element, index) {
      bounds.extend(element);
    });

    this.state.center = bounds.getCenter();
    console.log(this.state.center);

    this.props.getCenter(this.state.center);
    this.props.getPolygon(polygon, this.state.done);
    this.props.getArea(this.state.area);
  };

  deleteButton = (controlDiv, map) => {
    console.log(controlDiv);
    // Set CSS for the control border.
    var controlUI = document.createElement("div");
    controlUI.className = "Delete Button";
    controlUI.style.backgroundColor = "#fff";
    controlUI.style.border = "2px solid #fff";
    controlUI.style.borderRadius = "3px";
    controlUI.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
    controlUI.style.cursor = "pointer";
    controlUI.style.marginBottom = "22px";
    controlUI.style.textAlign = "center";

    controlUI.title = "Click to clear the polygons";
    controlDiv.appendChild(controlUI);

    // Set CSS for the control interior.
    var controlText = document.createElement("div");
    controlText.style.color = "rgb(25,25,25)";
    controlText.style.fontFamily = "Roboto,Arial,sans-serif";
    controlText.style.fontSize = "16px";
    controlText.style.lineHeight = "38px";
    controlText.style.paddingLeft = "5px";
    controlText.style.paddingRight = "5px";
    controlText.innerHTML = "Reset";
    controlUI.appendChild(controlText);

    // Setup the click event listeners: simply set the map to Chicago.
    controlUI.addEventListener("click", () => {
      this.deleteShapes();
    });

    controlDiv.index = 1;
    return controlDiv;
  };

  doneButton = (controlDiv, map) => {
    console.log(controlDiv);
    // Set CSS for the control border.
    var controlUI = document.createElement("div");
    controlUI.className = "Done Button";
    controlUI.style.backgroundColor = "#fff";
    controlUI.style.border = "2px solid #fff";
    controlUI.style.borderRadius = "3px";
    controlUI.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
    controlUI.style.cursor = "pointer";
    controlUI.style.marginBottom = "22px";
    controlUI.style.textAlign = "center";

    controlUI.title = "Click to Finish editing";
    controlDiv.appendChild(controlUI);

    // Set CSS for the control interior.
    var controlText = document.createElement("div");
    controlText.style.color = "rgb(25,25,25)";
    controlText.style.fontFamily = "Roboto,Arial,sans-serif";
    controlText.style.fontSize = "16px";
    controlText.style.lineHeight = "38px";
    controlText.style.paddingLeft = "5px";
    controlText.style.paddingRight = "5px";
    controlText.innerHTML = "Done";
    controlUI.appendChild(controlText);

    // Setup the click event listeners: simply set the map to Chicago.
    controlUI.addEventListener("click", () => {
      if (this.state.done == false) {
        this.state.done = true;
        console.log("DONE EDITING----------!!!!!!!!!");
        this.savePolygon();
        this.state.polygon.setOptions({
          strokeWeight: 2.0,
          fillColor: "green",
          strokeColor: "#66CD00",
          editable: false,
        });
      } else {
        this.state.done = false;
        console.log("DONE EDITING----------!!!!!!!!!");

        this.state.polygon.setOptions({
          strokeColor: "#FF0000",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#FF0000",
          fillOpacity: 0.35,
          editable: true,
        });
      }
    });

    controlDiv.index = 1;
    return controlDiv;
  };

  render() {
    return <div className={this.props.className} id={this.props.id} />;
  }
}

/**
 * A menu that lets a user delete a selected vertex of a path.
 * @constructor
 */
function DeleteMenu() {
  this.div_ = document.createElement("div");
  this.div_.className = "delete-menu";
  this.div_.innerHTML = "Delete";

  var menu = this;
  window.google.maps.event.addDomListener(this.div_, "click", function () {
    menu.removeVertex();
  });
}

function createDeleteMenu() {
  DeleteMenu.prototype = new window.google.maps.OverlayView();

  DeleteMenu.prototype.onAdd = function () {
    var deleteMenu = this;
    var map = this.getMap();
    this.getPanes().floatPane.appendChild(this.div_);

    // mousedown anywhere on the map except on the menu div will close the
    // menu.
    this.divListener_ = window.google.maps.event.addDomListener(
      map.getDiv(),
      "mousedown",
      (e) => {
        if (e.target !== deleteMenu.div_) {
          console.log("Closing menu");
          deleteMenu.close();
        }
      },
      true
    );
  };

  DeleteMenu.prototype.onRemove = function () {
    window.google.maps.event.removeListener(this.divListener_);
    this.div_.parentNode.removeChild(this.div_);

    // clean up
    this.set("position");
    this.set("path");
    this.set("vertex");
  };

  DeleteMenu.prototype.close = function () {
    this.setMap(null);
  };

  DeleteMenu.prototype.draw = function () {
    var position = this.get("position");
    var projection = this.getProjection();

    if (!position || !projection) {
      return;
    }

    var point = projection.fromLatLngToDivPixel(position);
    this.div_.style.top = point.y + "px";
    this.div_.style.left = point.x + "px";
  };

  /**
   * Opens the menu at a vertex of a given path.
   */
  DeleteMenu.prototype.open = function (map, path, vertex) {
    this.set("position", path.getAt(vertex));
    this.set("path", path);
    this.set("vertex", vertex);
    this.setMap(map);
    this.draw();
  };

  /**
   * Deletes the vertex from the path.
   */
  DeleteMenu.prototype.removeVertex = function () {
    var path = this.get("path");
    var vertex = this.get("vertex");

    if (!path || vertex === undefined) {
      this.close();
      return;
    }

    path.removeAt(vertex);
    this.close();
  };
}
