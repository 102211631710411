import React from 'react'

//import Home from './Home'
import { Route, Redirect, BrowserRouter } from 'react-router-dom'
import UserDashboard from './UserDashboard'
import axios from 'axios'
import * as urls from '../components/maps/urls'
import Table from 'react-bootstrap/Table'
import AddDocument from '../components/addDocument'

import { userActions } from '../redux/_actions'
import { connect } from 'react-redux'
import { Logout } from '../components/Logout'
import { RefreshAuthTokens } from '../components/RefreshAuthTokens'
import Header from '../components/Header'
import Loader from '../components/spinner'
import Button from '../components/general/Button'
import Can from 'balkerne-components/Can'

import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import './SmartVault.css'
//page that lists all locations and redirects to location when clicked

class SmartVault extends React.Component {
  constructor(props) {
    super(props)
    this.theme = props.userData.data.customstyle

    this.state = {
      filter_string: '',
      filter_type: '',
      selectedDocument: null,
      selectedDocumentIndex: null,
      clicks: [],
      filteredDocuments: null,
      documents: [],

      redirect: false,
      redirectID: null,

      //error displaying
      errorArray: [],
      errorText: '',
      errMessage: '',
      success: true,

      mounted: false,
      isLoading: false,
      showAddDocuments: false,
    }
    this.handleDoubleClick = this.handleDoubleClick.bind(this)
    this.getUserDocuments = this.getUserDocuments.bind(this)
    this.deleteDocument = this.deleteDocument.bind(this)
    this.handleAddDocument = this.handleAddDocument.bind(this)
  }

  //redirects the user to the location given the clicked id

  handleChange = e => {
    let filter = {}
    filter[e.target.name] = e.target.value
    this.setState(filter)
  }

  async getUserDocuments() {
    let secret = this.props.user.user.signInUserSession.idToken.jwtToken
    let user_id = this.props.userData.data.id

    //let success = true;

    let documents = null

    let data = { user_id: user_id }

    await axios({
      method: 'post',
      url: urls.API_GATEWAY + 'documents/get-documents',
      data: data,
    })
      .then(res => {
        documents = res.data
        console.log(documents)

        if (documents === 'null' || documents === null || documents === 'Failure' || typeof documents === 'undefined') {
          documents = []
          console.log('Error getting documents')
        } else if (documents === 'Invalid Details') {
          console.log('Details for getting documents are INVALID')
          documents = []
        } else {
          console.log('SUCCESS!!')
          console.log('Documents successfully retrieved from the database')
        }

        if (documents == '{"message":"The incoming token has expired"}') {
          console.log('TIMEOUT ERROR!!!!!!')
        }

        this.setState({ documents: documents, filteredDocuments: documents })
      })
      .catch(err => {
        documents = []
        console.log('FAILURE!!')
        console.log(err)
        console.log(err.response)
        if (
          err.response &&
          err.response.data &&
          err.response.data &&
          err.response.data.message == 'The incoming token has expired'
        ) {
          console.log('TIMEOUT ERROR!!!!!!')
        }
        //alert("No connection to the server")
        if (!err.message) {
          console.log('Error when getting documents: ', err)
          //  Alert.alert('Error when signing in: ', err);
        } else {
          console.log('Error when getting documents: ', err.message)
          //  Alert.alert('Error when signing in: ', err.message);
          this.setState({
            success: false,
            errMessage: 'Could not retrieve documents. (Server)',
          })
        }
      })
  }

  async deleteDocument() {
    console.log('deleting document')
    let secret = this.props.user.user.signInUserSession.idToken.jwtToken

    //let success = true;

    let data = { doc_id: this.state.selectedDocument.id }

    await axios({
      method: 'post',
      url: urls.API_GATEWAY + 'documents/delete-document',
      data: data,
    })
      .then(res => {
        console.log(res.data)
        if (res.data === 'deleted') {
          console.log('Document successfully deleted')
          this.setState({ selectedDocument: null })
          this.getUserDocuments()
        } else {
          console.log('FAILURE!!')
          //  Alert.alert('Error when signing in: ', err.message);
          this.setState({
            success: false,
            errMessage: 'Failure deleting document. (Server)',
          })
        }
        if (res.data == '{"message":"The incoming token has expired"}') {
          console.log('TIMEOUT ERROR!!!!!!')
        }

        //this.setState({documents:documents,filteredDocuments:documents });
      })
      .catch(err => {
        console.log('FAILURE!!')
        console.log(err)
        console.log(err.response)
        if (
          err.response &&
          err.response.data &&
          err.response.data &&
          err.response.data.message == 'The incoming token has expired'
        ) {
          console.log('TIMEOUT ERROR!!!!!!')
        }
        //alert("No connection to the server")
        if (!err.message) {
          console.log('Error deleting document: ', err)
          //  Alert.alert('Error when signing in: ', err);
        } else {
          console.log('Error deleting document: ', err.message)
          //  Alert.alert('Error when signing in: ', err.message);
          this.setState({
            success: false,
            errMessage: 'Failure deleting document. (Server)',
          })
        }
      })
  }

  //loads all the locations from the backend

  async componentDidMount() {
    this.setState({ isLoading: true })

    this.state.org_id = this.props.userData.data.orgid
    this.state.current_user_id = this.props.userData.data.id
    this.state.group = this.props.userData.data.group

    await RefreshAuthTokens(this.props)

    await this.getUserDocuments()

    this.setState({ mounted: true })

    this.setState({ isLoading: false })
  }

  // selects a location from location list by index

  selectDocumentIndex = index => {
    this.setState({ selectedDocumentIndex: index })
  }

  //render the locations as a clickable table
  onRowSelectDocuments = (row, isSelected, e, rowIndex) => {
    console.log(row)
    if (isSelected) {
      this.setState({ selectedDocument: row })
      // this.state.selectedDocuments = [row.id];
    } else {
      this.setState({ selectedDocument: row })
      this.handleDoubleClick(row.id)
    }
    //console.log("row select")
    // this.state.clicks.push(new Date().getTime());
    // window.clearTimeout(this.state.timeout);
    // this.state.timeout = window.setTimeout(() => {
    //   if (
    //     this.state.clicks.length > 1 &&
    //     this.state.clicks[this.state.clicks.length - 1] -
    //       this.state.clicks[this.state.clicks.length - 2] <
    //       250
    //   ) {console.log("double click")
    //     //this.handleDoubleClick(event.target, location);
    //   } else {
    //     console.log("signle click")
    //     //this.handleClick(event.target);
    //   }
    // }, 250);
  }

  handleClick(event) {
    console.log('single click')
  }

  showNotice = () => {
    if (this.state.success === false) {
      return <div className="alertbox">{this.state.errMessage}</div>
    }
  }

  //handles the actual redirect when double clicked a table row to a property dashboard

  async handleDoubleClick(location_id) {
    console.log('Double Clicked')
    this.downloadDocument()
  }

  //handles the single/double click logic

  clickHandler(event, location) {
    event.preventDefault()
    console.log('click event')
    console.log(event)
    console.log(location)
  }

  applyFilter = () => {
    console.log('applied')
    this.state.filteredDocuments = this.state.documents
    let filteredDocuments
    switch (this.state.filter_type) {
      case 'Name':
        filteredDocuments = this.state.documents.filter(document => {
          if (document.name) {
            return document.name.toLowerCase().includes(this.state.filter_string.toLowerCase())
          }
        })
        break
      case 'Category':
        filteredDocuments = this.state.documents.filter(document => {
          if (document.category) {
            return document.category.toLowerCase().includes(this.state.filter_string.toLowerCase())
          }
        })
        break
      case 'Description':
        filteredDocuments = this.state.documents.filter(document => {
          if (document.description) {
            return document.description.toLowerCase().includes(this.state.filter_string.toLowerCase())
          }
        })
        break

      default:
        filteredDocuments = this.state.documents
    }

    this.setState({ filteredDocuments })
  }

  clearFilter = () => {
    this.state.filter_string = ''

    let filteredDocuments = this.state.documents

    this.setState({ filteredDocuments })
  }

  downloadDocument() {
    console.log('DONWLOADING FILE')
    window.location.href = urls.DOCUMENTS + this.state.selectedDocument.attachments
  }

  editDocument() {
    console.log('EDITING FILE')
  }

  handleAddDocument = () => {
    this.setState({ showAddDocument: !this.state.showAddDocument })
  }

  displayAddDocument() {
    return (
      <div>
        {this.state.showAddDocument ? (
          <AddDocument
            text='Click "Close Button" to hide popup'
            onClickClose={this.handleAddDocument.bind(this)}
            orgid={this.props.userData.data.orgid}
            userid={this.props.userData.data.id}
            updateDocuments={() => this.getUserDocuments()}
            secret={this.props.user.user.signInUserSession.idToken.jwtToken}
          />
        ) : null}
      </div>
    )
  }

  displayDocumentActions(user_permissions) {
    if (this.state.selectedDocument) {
      return (
        <div className="button-group">
          <Can
            permissions={user_permissions}
            actions="documents:read"
            yes={
              <Button light onClick={() => this.downloadDocument()}>
                {' '}
                Download{' '}
              </Button>
            }
          />

          {/* <Button light onClick={()=>this.downloadDocument()}>
            Download
          </Button> */}

          {/* <button type="button" className="button" onClick={this.editDocument}>
            Edit
          </button> */}

          <Can
            permissions={user_permissions}
            actions={['documents:delete', 'documents:delete-any', 'documents:delete-all']}
            yes={() => (
              <Button light onClick={this.deleteDocument}>
                {' '}
                Delete{' '}
              </Button>
            )}
          />
        </div>
      )
    }
  }

  render() {
    //Redirecting
    // if(this.state.redirect==true){
    // console.log(this.state.filteredLocations);
    //   return(
    //     <Redirect to={{
    //   pathname: '/UserDashboard',
    //   state: { redirectID: this.state.redirectID }
    // }}/>
    //   )
    // }
    const user_permissions = this.props.userData.data.permissions
    if (this.state.isLoading === true)
      return (
        <React.Fragment>
          <Header
            user_permissions={user_permissions}
            user_portfolio_type={this.props.userData.data.portfolio_type}
            customstyle={this.props.userData.data.customstyle}
            organisation={this.props.userData.data.orgid}
            currentpage={this.props.location.pathname || ''}
            logout={() => Logout(this.props)}
          />
          {this.showNotice()}
          <Loader color="#0652dd" />
        </React.Fragment>
      )

    const selectRowPropDocuments = {
      mode: 'radio',
      clickToSelect: true,
      bgColor: 'cyan',
      selected: this.state.selectedDocuments,
      hideSelectColumn: true,
      onSelect: this.onRowSelectDocuments,
    }

    if (this.state.filteredDocuments === null && this.state.documents) {
      this.state.filteredDocuments = this.state.documents
    }
    return (
      <React.Fragment>
        <Header
          user_permissions={user_permissions}
          user_portfolio_type={this.props.userData.data.portfolio_type}
          customstyle={this.props.userData.data.customstyle}
          organisation={this.props.userData.data.orgid}
          currentpage={this.props.location.pathname || ''}
          logout={() => Logout(this.props)}
        />
        {this.showNotice()}

        <div className="locationlistform">
          <div className="filterpropertylist">
            <form onChange={this.handleChange}>
              <label> Filter: </label>
              <div className="searchfields">
                <input
                  type="text"
                  name="filter_string"
                  className="filter_string"
                  id="filter_string"
                  value={this.state.filter_string}
                />
                <select name="filter_type" className="filter_type" id="filter_type">
                  <option disabled selected>
                    Choose a Filter
                  </option>
                  <option value="Name">Name</option>
                  <option value="Category">Category</option>
                  <option value="Description">Description</option>
                </select>

                <Button light onClick={this.applyFilter}>
                  Apply
                </Button>

                <Button light onClick={this.clearFilter}>
                  Clear
                </Button>
              </div>
            </form>
          </div>
          <div className="propertylistresults">
            <BootstrapTable
              className="table-smaller-locations"
              selectRow={selectRowPropDocuments}
              data={this.state.filteredDocuments}
              striped
              hover>
              <TableHeaderColumn isKey dataField="id" hidden>
                ID
              </TableHeaderColumn>
              <TableHeaderColumn width="30%" dataField="name">
                Document Name
              </TableHeaderColumn>
              <TableHeaderColumn width="10%" dataField="category">
                Category
              </TableHeaderColumn>
              <TableHeaderColumn width="40%" d dataField="description">
                Description
              </TableHeaderColumn>
              <TableHeaderColumn width="10%" dataField="valid_from">
                Valid From
              </TableHeaderColumn>
              <TableHeaderColumn width="10%" dataField="valid_from">
                Expiry Date
              </TableHeaderColumn>
            </BootstrapTable>
          </div>
          <div className="buttons">
            <Can
              permissions={user_permissions}
              actions="documents:add"
              yes={() => (
                <Button style={this.theme} onClick={() => this.handleAddDocument()}>
                  Add Document
                </Button>
              )}
            />

            {this.displayDocumentActions(user_permissions)}
            <div>{this.state.errorText}</div>
          </div>
          {this.displayAddDocument()}
        </div>
      </React.Fragment>
    )
  }
}
function mapStateToProps(state) {
  //console.log(state);
  const { userLogged, user, userData } = state

  return {
    userLogged,
    user,
    userData,
  }
}
export default connect(mapStateToProps)(SmartVault)
