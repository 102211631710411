import React from 'react'
import UserInputs from './UserInputs'
import Button from 'balkerne-components/Button'
import './AddUsers.css'
import { ConsoleLogger } from '@aws-amplify/core'

export class AddUsers extends React.Component {
  constructor(props) {
    super(props)
    //setting initial user row
    this.state = {
      users: [{ full_name: '', email: '', phone: '', role: '', position: '' }],
    }
  }

  //adds another empty user row
  addUser = () => {
    this.setState(prevState => ({
      users: [
        ...prevState.users,
        {
          full_name: prevState.users.full_name,
          email: prevState.users.email,
          phone: prevState.users.phone,
          role: prevState.users.role,
          position: prevState.users.position,
        },
      ],
    }))
  }

  removeUser = i => {
    let users = [...this.state.users]
    console.log(users)
    users.pop()
    this.setState({ users })
    console.log(users)
    this.props.handleChange('users', users)
  }

  // Loads updated values into the users array
  updateFields = () => {
    this.setState(this.state.users, this.props.users)
  }

  // Handles changes made to the user, updates the users array state
  handleChange = e => {
    // Get the type of input and the value
    const type = e.target.name.split('-')[0]
    const value = e.target.value

    if (['full_name', 'email', 'phone', 'role', 'role', 'position'].includes(type)) {
      //console.log(value)
      let users = [...this.state.users]
      users[e.target.dataset.id][type] = value
      this.setState({ users })
    } else {
      this.setState({ [e.target.name]: value })
    }
    this.props.handleChange('users', this.state.users)
  }

  render() {
    // Return null if not current page
    if (this.props.currentStep !== 1) {
      return null
    }

    return (
      <div>
        {/* Add new user */}
        <Button size="small" light onClick={this.addUser} className="mb-4">
          Add User
        </Button>

        {/* User list container */}
        <form onChange={this.handleChange} className="d-flex flex-column flex-gap-2">
          {/* Renders user cards */}
          <UserInputs
            handleChange={this.handleChange}
            users={this.state.users}
            removeUser={i => this.removeUser(i)}
            user_permissions={this.props.user_permissions}
            portfolio_type={this.props.portfolio_type}
          />
        </form>

        {/* Remove last user */}
        <Button size="small" light onClick={this.removeUser} className="mt-4">
          Remove User
        </Button>
      </div>
    )
  }
}

export default AddUsers
