import React from 'react';

import Auth from "@aws-amplify/auth";
import axios from "axios";
import { userActions } from '../redux/_actions';
import { Logout } from "./Logout";
export async function RefreshAuthTokens (props) {
  
        if(props.user && props.user.user && ((props.user.user.signInUserSession.idToken.payload.exp -1200) < (Date.now()/1000)) && ((props.user.user.signInUserSession.idToken.payload.exp ) >(Date.now()/1000)) ){ 
            console.log("REFRESHING AXIOS HEADERS")
  
            await Auth.currentSession()
               .then((session) => {
                 var refreshToken = session.getRefreshToken();
                 console.log(refreshToken)
                  Auth.currentAuthenticatedUser()
                     .then((res) => {
                       res.refreshSession(refreshToken, (err, session_data) => {
                         if (err) {
                           //HANDLES FAILURE AND LOGS THE PERSON OUT
                           console.log(err)
                           console.log("Failure refreshing user session tokens.")
                           console.log("-------------------------------------------")
                           //axios.defaults.headers.common['Authorization'] = ""
                           alert("Unexpected Error, Please try again.")
                           Logout()
                         } else {
                           //HANDLES SUCCESS AND SETS THE DEFAULT AUTH HEADER AND USER REDUX STATE
                          let jwtToken = null
                          if(session_data ===null){
                            console.log("session data passed but is NULL")
                          }
                        // let refresh = false
                        //   if(((props.user.user.signInUserSession.idToken.payload.exp) < (Date.now()/1000))){ //checks if the api calls will not work and refreshes if so
                        //         refresh = true
                        //         console.log("refreshing page")
                        //   }
   
                            jwtToken =session_data.getIdToken().getJwtToken()
                            //console.log(jwtToken)
                            
                            axios.defaults.headers.common['Authorization'] =  "Bearer " + jwtToken;
                           //config.headers.Authorization = "Bearer " + jwtToken;
                           console.log("Jwt Token successfully refreshed!!!")
                           console.log("-------------------------------------------")
                           if(jwtToken !== null){
                              props.dispatch(userActions.update_jwt_token(props.user.user,jwtToken));
                              console.log("submitted jwt token SUCCESSFULLY")
                            }

                            // if(refresh){
                            //     console.log("Auth token timed out, refresh needed.")
                            //     //alert("You have been inactive for too long. Please reload the page.")
                            //     //document.location.reload()
                            // }
                         }
                       });
                     })
                     .catch((err) => {
                       //HANDLES FAILURE AND LOGS THE PERSON OUT
                      console.log(err)
                      console.log("Failure refreshing user session tokens.")
                      console.log("-------------------------------------------")
                      //axios.defaults.headers.common['Authorization'] = ""
                      alert("Unexpected Error, Please try again.")
                    });
               })
               .catch(() => {
                 console.log("NO USER FOUND TO REFRESH")
                 // No logged-in user: don't set auth header
               });
          }
          // else{
          //   if(typeof axios.defaults.headers.common['Authorization'] === 'undefined' && props.user && props.user.user ){
          //     axios.defaults.headers.common['Authorization'] =  "Bearer " + props.user.user.signInUserSession.idToken.jwtToken
          //     console.log("Set the authhorization header again via refresh tokens")
          //   }
          //   console.log("TOKEN CLOCK STILL VALID")
          // }
     
    };
    