import React from "react";
import "./Displayalerts.css";

class Recommendationnotice extends React.Component {
  render() {
    return (
      <div className="notice_text">
        <div className="notice_grid">
          <div>
            <img src={require("../../images/check-mark.jpg")} alt="checkbox" />
          </div>
          <div>
            {this.props.recommendation}
            <a href={this.props.link}>{this.props.filename}</a>
          </div>
        </div>
      </div>
    );
  }
}

export class Displayalerts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentrecommendations: this.props.recommendations,
    };
  }

  mapRecommendations = () => {
    if (
      this.state.currentrecommendations &&
      this.state.currentrecommendations.length
    ) {
      return this.state.currentrecommendations.map(
        (recommendationList, index) => (
          <Recommendationnotice
            key={index}
            recommendation={recommendationList.description}
            filename={recommendationList.filename}
            link={recommendationList.link}
          />
        )
      );
    }
  };

  showLiveAlert = () => {
    return (
      <div className="Riskreportsection">
        <h3 className="Riskreportsectionheading">
          {this.props.title + "    Raised at: " + this.props.timeraised}
        </h3>
        <div className="alertcontent">
          <div className="alert-left">
            <img
              className="alertimage"
              src={this.props.imagepath}
              alt="alert image"
            />
            <div className="alerttext">
              <p>{this.props.text}</p>
            </div>
          </div>
          <div className="recommednationsection">
            <h4 className="recommendationsectionheading">Recommendations</h4>
            {this.mapRecommendations()}
          </div>
        </div>
      </div>
    );
  };

  render() {
    return this.showLiveAlert();
  }
}
export default Displayalerts;
