import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { DropdownButton, Dropdown } from 'react-bootstrap'

export const DropdownBootstrap = ({ ...props }) => {
  const [value, setValue] = useState(props.title)

  /* useEffect(() => {
    const dropdownbuttons = document.getElementById('dropdown-basic-button')
    Object.values(dropdownbuttons).map(button => {
      if (button.type == 'button') {
        button.style.backgroundColor = 'transparent'
      }
    })
  })*/
  const selectOption = option => {
    setValue(option.label)
    props.updateOption(option.value)
  }

  return (
    <DropdownButton style={props.style} id="dropdown-basic-button" title={value} disabled={props.disabled}>
      {props.options.map((option, key) => (
        <Dropdown.Item key={key} onClick={() => selectOption(option)}>
          {option.label}
        </Dropdown.Item>
      ))}
    </DropdownButton>
  )
}

export default DropdownBootstrap
