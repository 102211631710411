import { userConstants } from "../_constants";
import axios from "axios";
import * as urls from "../../components/maps/urls";

export async function getUserDetails(email, password, secret) {
  let response = null;

  let data = { email: email, password: password };

  await axios({
    method: "post",
    url: urls.API_GATEWAY + "users/get-user-details",
    data: data,
  })
    .then((res) => {
      response = res.data;
      console.log(response);
      if (
        response == "Invalid Details" ||
        response == "Failure" ||
        typeof response === "undefined"
      ) {
        response = null;
        console.log("Invalid credentials, userData not received!!");
        console.log(res.data);
      } else {
        console.log("user data received from database!!");
        console.log(response);
      }
    })
    .catch((err) => {
      response = null;
      console.log("user data failed to retrieve from database!!");
      console.log(err);
    });

  return response;
}
