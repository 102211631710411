import React from "react"
import Select from "balkerne-components/Select"
import Input from "balkerne-components/Input"
import Button from "balkerne-components/Button"
import Card from "react-bootstrap/Card"
import c from "./UserInput.module.scss"
import ROLES, { ROLES_ANY_SINGLE, ROLES_ANY,ROLES_ALL,ROLES_ADMIN } from "./roles.js"

const UserInputs = ({ users, handleChange, removeUser, user_permissions,portfolio_type }) => {
  // Return list of cards for user details
  return users.map((_, index) => {
    const name     = "full_name-" + { index },
          email    = "email-" + { index },
          phone    = "phone-" + { index },
          role    = "role-" + { index },
          position = "position-" + { index }

    return (
      <Card key={index} >
        <Card.Body>
          <form 
            name='my-form'
            className={c.user}  
          >
            {/* First input row */}
            <div className={c.row_1}>
              <Input 
                id={name}
                name={name}
                data-id={index}
                label="Full name"
                defaultValue={users[index].full_name}
                onChange={handleChange}
              />
              <Input 
                id={email}
                name={email}
                data-id={index}
                label="Email"
                defaultValue={users[index].email}
              />
            </div>

            {/* Second input row */}
            <div className={c.row_2}>
              <Input 
                id={phone}
                name={phone}
                data-id={index}
                label="Phone number (+44)"
                defaultValue={users[index].phone}
              />
              <Input 
                id={position}
                name={position}
                data-id={index}
                label="Position"
                defaultValue={users[index].position}
              />
              {renderRoleChoices(user_permissions,role,handleChange,index,portfolio_type)}
            </div>
          </form>
        </Card.Body>
      </Card>
    )
  })
}

const renderRoleChoices = ( user_permissions,role,handleChange,index,portfolio_type) => {
if(user_permissions && portfolio_type == "Single"){
  return (
    <Select
    label='Role'
    name={role} 
    id={role}
    onChange={({ value }) => (
      handleChange({ 
        target: { 
          value: value,
          name: role,
          dataset: {
            id: index
          }
          },
      }))
    }
    options={ROLES_ANY_SINGLE}
  />
  );

}
else if(user_permissions && portfolio_type != "Single"){

  if( user_permissions.includes('users:add')){
    return (
      <Select
      label='Role'
      name={role} 
      id={role}
      onChange={({ value }) => (
        handleChange({ 
          target: { 
            value: value,
            name: role,
            dataset: {
              id: index
            }
            },
        }))
      }
      options={ROLES}
    />
    );
}
else if(   user_permissions.includes("users:add-any") ){
        return (
        
        <Select
        label='Role'
        name={role} 
        id={role}
        onChange={({ value }) => (
          handleChange({ 
            target: { 
              value: value,
              name: role,
              dataset: {
                id: index
              }
              },
          }))
        }
        options={ROLES_ANY}
      />
        )
    }
  else if(   user_permissions.includes("users:add-all")){
      return (
      
      <Select
      label='Role'
      name={role} 
      id={role}
      onChange={({ value }) => (
        handleChange({ 
          target: { 
            value: value,
            name: role,
            dataset: {
              id: index
            }
            },
        }))
      }
      options={ROLES_ALL}
    />
      )
  }
  else if(   user_permissions.includes("users:add-admin")){
    return (
    
    <Select
    label='Role'
    name={role} 
    id={role}
    onChange={({ value }) => (
      handleChange({ 
        target: { 
          value: value,
          name: role,
          dataset: {
            id: index
          }
          },
      }))
    }
    options={ROLES_ADMIN}
  />
    )
}
  else{
    return null
  }

}
else{
  console.log("user permission missing")
  return null
}
  
}
    
   
 



export default UserInputs
