import React from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import ReactTooltip from "react-tooltip";
import Card from "react-bootstrap/Card";
import Select from "balkerne-components/Select";
import Input from "balkerne-components/Input";
import Button from "balkerne-components/Button";

import "./LocationAssignUsers.css";
import c from "./LocationAssignUsers.module.scss";

export class LocationAssignUsers extends React.Component {
  constructor(props) {
    super(props);
    //setting TEMPORARY STATE
    this.state = {
      users: this.props.users,
      assignedusers: this.props.assignedusers,
      filter_string: "",
      filter_type: "",
      filter_selection: { label: "", value: "" },
      filteredUsers: null,

      selectedUsers: [],
      selectedAssignedUsers: [],
      ctrl: false,
      shifts: false,
      lastIndexLocation: 0,
      lastIndexUsers: 0,
      lastIndexAssignedUsers: 0,
    };
  }

  componentDidMount() {
    document.addEventListener("keydown", this.handleKeyDown);
    document.addEventListener("keyup", this.handleKeyUp);
    if (this.props.currentStep === 2) {
      this.clearFilter();
    }
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown);
    document.removeEventListener("keyup", this.handleKeyUp);
  }

  applyFilter = () => {
    this.state.filteredUsers = this.props.users;

    let filteredUsers;
    switch (this.state.filter_type) {
      case "Name":
        filteredUsers = this.state.filteredUsers.filter((user) => {
          if (user.full_name) {
            return user.full_name
              .toLowerCase()
              .includes(this.state.filter_string.toLowerCase());
          }
        });
        break;
      case "Email":
        filteredUsers = this.state.filteredUsers.filter((user) => {
          if (user.email) {
            return user.email
              .toLowerCase()
              .includes(this.state.filter_string.toLowerCase());
          }
        });
        break;
      case "Position":
        filteredUsers = this.state.filteredUsers.filter((user) => {
          if (user.position) {
            return user.position
              .toLowerCase()
              .includes(this.state.filter_string.toLowerCase());
          }
        });
        break;
      case "Group":
        filteredUsers = this.state.filteredUsers.filter((user) => {
          if (user.group) {
            return user.group
              .toLowerCase()
              .includes(this.state.filter_string.toLowerCase());
          }
        });
        break;
      case "My Added":
        filteredUsers = this.state.filteredUsers.filter((user) => {
          if (user.manager) {
            return user.manager === this.props.current_user_id;
          }
        });
        break;

      default:
        filteredUsers = this.props.users;
    }

    this.setState({ filteredUsers });
  };

  clearFilter = () => {
    let filteredUsers =
      this.props.users && this.props.users.length && this.props.users.length > 0
        ? this.props.users
        : null;
    this.setState({
      filter_selection: { label: "", value: "" },
      filter_string: "",
      filteredUsers: filteredUsers,
    });
    console.log(filteredUsers);
    console.log(this.state.filteredUsers);
  };

  handleKeyDown = (event) => {
    if (event.ctrlKey) {
      this.state.ctrl = true;
    }
    if (event.shiftKey) {
      this.state.shift = true;
    }
  };

  handleKeyUp = (event) => {
    if (event.keyCode === 17) {
      this.state.ctrl = false;
    }
    if (event.keyCode === 16) {
      this.state.shift = false;
    }
  };

  handleChange = (e) => {
    let filter = {};
    filter[e.target.name] = e.target.value;
    console.log("filter: ", filter);
    this.setState(filter);
  };

  setCtrl = (press) => {
    this.state.ctrl = press;
  };

  setShift = (press) => {
    this.state.shift = press;
  };

  onRowSelectUsers = (row, isSelected, e, rowIndex) => {
    if (this.state.ctrl) {
      if (isSelected) {
        this.state.selectedUsers = [row.email];
      } else {
        this.state.selectedUsers = [row.email];
      }
    } else if (this.state.shift) {
      if (isSelected) {
        document.getSelection().removeAllRanges();
        if (this.state.lastIndexUsers > rowIndex) {
          for (let i = rowIndex; i <= this.state.lastIndexUsers; i++) {
            if (!this.state.selectedUsers.includes(this.props.users[i].email)) {
              this.state.selectedUsers.push(this.props.users[i].email);
            }
          }
        } else {
          for (let i = this.state.lastIndexUsers; i <= rowIndex; i++) {
            if (!this.state.selectedUsers.includes(this.props.users[i].email)) {
              this.state.selectedUsers.push(this.props.users[i].email);
            }
          }
        }
      }
    } else {
      if (isSelected) {
        this.state.selectedUsers.push(row.email);
      } else {
        this.state.selectedUsers.splice(
          this.state.selectedUsers.findIndex((user) => {
            return user === row.email;
          }),
          1
        );
      }
    }

    this.props.updateSelectedUsers(this.state.selectedUsers);

    if (isSelected) {
      this.state.lastIndexUsers = rowIndex;
    }

    this.setState({ selectedUsers: this.state.selectedUsers });
  };

  onRowSelectAssignedUsers = (row, isSelected, e, rowIndex) => {
    if (this.state.ctrl) {
      if (isSelected) {
        this.state.selectedAssignedUsers = [row.email];
      } else {
        this.state.selectedAssignedUsers = [row.email];
      }
    } else if (this.state.shift) {
      if (isSelected) {
        document.getSelection().removeAllRanges();
        if (this.state.selectedAssignedUsers > rowIndex) {
          for (let i = rowIndex; i <= this.state.lastIndexAssignedUsers; i++) {
            if (
              !this.state.selectedAssignedUsers.includes(
                this.props.assignedusers[i].email
              )
            ) {
              this.state.selectedAssignedUsers.push(
                this.props.assignedusers[i].email
              );
            }
          }
        } else {
          for (let i = this.state.lastIndexAssignedUsers; i <= rowIndex; i++) {
            console.log(i);
            console.log(this.props.assignedusers);
            console.log(this.props.assignedusers[i]);
            if (
              !this.state.selectedAssignedUsers.includes(
                this.props.assignedusers[i].email
              )
            ) {
              this.state.selectedAssignedUsers.push(
                this.props.assignedusers[i].email
              );
            }
          }
        }
      }
    } else {
      if (isSelected) {
        this.state.selectedAssignedUsers.push(row.email);
      } else {
        this.state.selectedAssignedUsers.splice(
          this.state.selectedAssignedUsers.findIndex((user) => {
            return user === row.email;
          }),
          1
        );
      }
    }

    this.props.updateSelectedAssignedUsers(this.state.selectedAssignedUsers);

    if (isSelected) {
      this.state.lastIndexAsignedUsers = rowIndex;
    }

    this.setState({ selectedAssignedUsers: this.state.selectedAssignedUsers });
  };

  selectAllUsers = (select) => {
    if (select) {
      for (let i = 0; i < this.state.filteredUsers.length; i++) {
        if (
          !this.state.selectedUsers.includes(this.state.filteredUsers[i].email)
        ) {
          this.state.selectedUsers.push(this.state.filteredUsers[i].email);
        }
      }
      this.setState({ selectedUsers: this.state.selectedUsers });
      this.props.updateSelectedUsers(this.state.selectedUsers);
    } else {
      let selectedUsers = [];
      this.setState({ selectedUsers });

      this.props.updateSelectedUsers(selectedUsers);
    }
  };

  selectAllAssignedUsers = (select) => {
    console.log(select);
    console.log("doing select all assigned users");

    if (select) {
      for (let i = 0; i < this.props.assignedusers.length; i++) {
        if (
          !this.state.selectedAssignedUsers.includes(
            this.props.assignedusers[i].email
          )
        ) {
          this.state.selectedAssignedUsers.push(
            this.props.assignedusers[i].email
          );
        }
      }
      this.setState({
        selectedAssignedUsers: this.state.selectedAssignedUsers,
      });
      this.props.updateSelectedAssignedUsers(this.state.selectedAssignedUsers);
    } else {
      let selectedAssignedUsers = [];
      this.setState({ selectedAssignedUsers });

      this.props.updateSelectedAssignedUsers(selectedAssignedUsers);
    }
  };

  //conditionally render according current step
  render() {
    if (this.props.currentStep !== 2) {
      return null;
    }

    const selectRowPropUsers = {
      mode: "checkbox",
      clickToSelect: true,
      bgColor: "var(--focus-background)",
      selected: this.state.selectedUsers,
      onSelect: this.onRowSelectUsers,
      onSelectAll: this.selectAllUsers,
    };

    const selectRowPropAssignedUsers = {
      mode: "checkbox",
      clickToSelect: true,
      bgColor: "var(--focus-background)",
      selected: this.state.selectedAssignedUsers,
      onSelect: this.onRowSelectAssignedUsers,
      onSelectAll: this.selectAllAssignedUsers,
    };

    if (this.state.filteredUsers === null) {
      this.setState({ filteredUsers: this.props.users });
    }

    return (
      <div className="d-flex flex-column flex-gap-2">
        <Card>
          <Card.Body>
            <form className={c.filter} onChange={this.handleChange}>
              <Input
                label="Search"
                placeholder="Search users"
                id="filter_string"
                name="filter_string"
                value={this.state.filter_string}
                defaultValue={this.state.filter_string}
              />
              <Select
                label="Filter"
                id="filter_type"
                value={this.state.filter_selection}
                onChange={(selection) => {
                  console.log(selection);
                  this.handleChange({
                    target: {
                      value: selection.value,
                      name: "filter_type",
                    },
                  });
                  this.setState({ filter_selection: selection });
                }}
                options={[
                  { label: "", value: "" },
                  { label: "Name", value: "Name" },
                  { label: "Email", value: "Email" },
                  { label: "Position", value: "Position" },
                  { label: "Group", value: "Group" },
                  { label: "My Added", value: "My Added" },
                ]}
              />
              <Button light onClick={this.applyFilter}>
                Apply
              </Button>
              <Button light onClick={this.clearFilter}>
                Clear
              </Button>
            </form>
          </Card.Body>
        </Card>

        <Card>
          <Card.Body>
            <div className="d-flex align-items-center justify-content-between">
              <h4>Assigned Users</h4>
              <Button size="small" light onClick={this.props.removeUsers}>
                Remove User
              </Button>
            </div>
          </Card.Body>
          <BootstrapTable
            selectRow={selectRowPropAssignedUsers}
            data={this.props.assignedusers}
            striped
            hover
            className=""
          >
            <TableHeaderColumn isKey hidden dataField="email">
              Email
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="full_name"
              data-tip
              data-for="nametooltip"
            >
              Name <label>(Ctrl + Select : for multiple users)</label>
            </TableHeaderColumn>
          </BootstrapTable>
        </Card>

        <Card>
          <Card.Body>
            <div className="d-flex align-items-center justify-content-between">
              <h4>User Pool</h4>
              <Button size="small" onClick={this.props.addUsers}>
                Add User
              </Button>
            </div>
          </Card.Body>
          <BootstrapTable
            selectRow={selectRowPropUsers}
            data={this.state.filteredUsers}
            striped
            hover
            className=""
          >
            <TableHeaderColumn isKey hidden dataField="email">
              Email
            </TableHeaderColumn>
            <TableHeaderColumn dataField="full_name">
              Name <label>(Ctrl + Select : for multiple users)</label>
            </TableHeaderColumn>
          </BootstrapTable>
          <ReactTooltip id="nametooltip" aria-haspopup="true" role="example">
            <p>Ctrl + Select : for multiple users</p>
          </ReactTooltip>
        </Card>
      </div>
    );
  }
}
export default LocationAssignUsers;
