import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from '../_reducers';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import createEncryptor from 'redux-persist-transform-encrypt'


const encryptor = createEncryptor({
    secretKey:   checkSessionStorage(),
    onError: function(error) {
      console.log(error);
     
    }
  })

  function checkSessionStorage(){

    let data = sessionStorage.getItem('session');
  //  console.log(data)
    if(!data){
      console.log("clearing local storage")
      localStorage.clear()
      let session = password_generator();
      sessionStorage.setItem('session',session)
     return session;
    }
    else{
      return data;
    }
}

function password_generator(  ) {
  var length = (20);
  var string = "abcdefghijklmnopqrstuvwxyz"; //to upper 
  var numeric = '0123456789';
  var punctuation = '!@#$%^&*';
  var password = "";
  var character = "";
  var crunch = true;
  while( password.length<length ) {
     let  entity1 = Math.ceil(string.length * Math.random()*Math.random());
      let entity2 = Math.ceil(numeric.length * Math.random()*Math.random());
      let entity3 = Math.ceil(punctuation.length * Math.random()*Math.random());
      let hold = string.charAt( entity1 );
      hold = (password.length%2==0)?(hold.toUpperCase()):(hold);
      character += hold;
      character += numeric.charAt( entity2 );
      character += punctuation.charAt( entity3 );
      password = character;
  }
  password=password.split('').sort(function(){return 0.5-Math.random()}).join('');
  return password.substr(0,length);
}

const loggerMiddleware = createLogger();

const persistConfig = {
    key: 'root',
    storage,
    timeout: null
  }

  const persistedReducer = persistReducer(
    {key: 'root',
    storage,
      transforms: [encryptor]
    },
    rootReducer
  )
  
  
  
//   (persistConfig, rootReducer)

export const store = createStore(
    persistedReducer,
    undefined,
    applyMiddleware(
      thunk,
        loggerMiddleware
    )
);

export const persistor = persistStore(store);



// export default () => {
//     let store = createStore( persistedReducer,applyMiddleware( thunkMiddleware, loggerMiddleware ));
//     let persistor = persistStore(store)
//     return { store, persistor }
//   }