import React, { useState } from "react";
import moment from "moment";
import Loader from "../spinner";
import c from "./foodhygiene.module.scss";
import star from "../../images/rating_star.png";
import warning from "../../images/covidwarning.png";
import awaiting from "../../images/awaiting_inspection.jpg";
import rating0 from "../../images/rating_0.png";
import rating1 from "../../images/rating_1.png";
import rating2 from "../../images/rating_2.png";
import rating3 from "../../images/rating_3.png";
import rating4 from "../../images/rating_4.png";
import rating5 from "../../images/rating_5.png";
import { LabeledTextLine } from "../general/LabeledText";

export class FoodHygieneImport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  async componentDidMount() {
    console.log("Food Hygiene Address");
    console.log(this.props.addname);
    console.log(this.props.address);
    console.log(this.props.postcode);
    console.log(this.props.addlat);
    console.log(this.props.addlon);

    //Initially tired buildname but it is far too inaccurate
    let buildname = this.props.addname.replace(/ /g, "%20");
    let address_street = this.props.address
      ? this.props.address.replace(/ /g, "%20") + "%20"
      : "";
    let postcode = this.props.postcode
      ? this.props.postcode.replace(/ /g, "%20")
      : "";
    let address = address_street + postcode;
    // const [establishments, setEstablishments] = useState([]);
    this.setState({ isLoading: true });
    let url = "https://api.ratings.food.gov.uk";
    let params =
      "/establishments?" + "&address=" + address + "&maxDistanceLimit=2";
    let urlpass = url + params;
    console.log("url pass");

    console.log(urlpass);

    await fetch(urlpass, {
      method: "GET",
      headers: { "x-api-version": 2 },
    })
      .then((response) => {
        return response.json();
        // return JSON.parse(response);
      })
      .then((hygienedata) => {
        this.setState({ foodData: hygienedata });
        // hygienedata.map(establishment)

        console.log("foodhygieneData");

        console.log(hygienedata);

        this.setState({ isLoading: false });
      });
  }

  componentFoodHygiene() {
    let foodData = this.state.foodData;
    if (foodData != undefined) {
      let FoodHygieneData = foodData["establishments"][0];
      if (FoodHygieneData != undefined) {
        let FoodHygieneScores = FoodHygieneData["scores"];

        if (FoodHygieneScores != undefined) {
          return (
            <div className={c.foodhygiene}>
              <div className={c.top}>
                <div className={c.left}>
                  <LabeledTextLine label="Displaying">
                    {"Food Hygiene Rating"}
                  </LabeledTextLine>
                  <LabeledTextLine label="Business Type">
                    {FoodHygieneData.BusinessType}
                  </LabeledTextLine>
                  <LabeledTextLine label="Local Authority">
                    {FoodHygieneData.LocalAuthorityName}
                  </LabeledTextLine>
                </div>
              </div>
              <hr />
              <div className={c.foodhygieneHighlight}>
                <div className={c.foodhygienecontent}>
                  <div className={c.foodhygienedetailsection}>
                    {this.displayRating(FoodHygieneData.RatingValue)}
                    <div>
                      {FoodHygieneScores.ConfidenceInManagement ? (
                        <h3>
                          Confidence in Management:{" "}
                          {FoodHygieneScores.ConfidenceInManagement}
                        </h3>
                      ) : (
                        ""
                      )}

                      {FoodHygieneScores.Hygiene ? (
                        <h3>Hygiene: {FoodHygieneScores.Hygiene}</h3>
                      ) : (
                        ""
                      )}

                      {FoodHygieneScores.Structural ? (
                        <h3>Structural: {FoodHygieneScores.Structural}</h3>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div className={c.tierright}>
                    <div className={c.foodhygienedetailsection}>
                      <div className={c.foodhygiene_insight_text}>
                        <p>{this.props.foodratingdescription}</p>
                        <p>For more information, please click below.</p>
                      </div>
                      <div class="button_cont" align="center">
                        <a
                          class={c.button_read}
                          href="https://www.food.gov.uk/safety-hygiene/food-hygiene-rating-scheme"
                          target="_blank"
                          rel="nofollow noopener"
                        >
                          Scheme
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        }
      }
    }
  }

  displayRating(RatingValue) {
    return (
      <div>
        {RatingValue == "5" && (
          <div className={c.card}>
            <img src={rating5} className={c.rating_img} />
            <p>
              <img src={star} className={c.star_img} />
              <img src={star} className={c.star_img} />
              <img src={star} className={c.star_img} />
              <img src={star} className={c.star_img} />
              <img src={star} className={c.star_img} />

              <p>Very good</p>
            </p>
          </div>
        )}
        {RatingValue == "4" && (
          <div className={c.card}>
            <img src={rating4} className={c.rating_img} />

            <p>
              <img src={star} className={c.star_img} />
              <img src={star} className={c.star_img} />
              <img src={star} className={c.star_img} />
              <img src={star} className={c.star_img} />
              <p>Good</p>
            </p>
          </div>
        )}
        {RatingValue == "3" && (
          <div className={c.card}>
            <img src={rating3} className={c.rating_img} />

            <p>
              <img src={star} className={c.star_img} />
              <img src={star} className={c.star_img} />
              <img src={star} className={c.star_img} />
              <p>Generally satisfactory</p>
            </p>
          </div>
        )}
        {RatingValue == "2" && (
          <div className={c.card}>
            <img src={rating2} className={c.rating_img} />

            <p>
              <img src={star} className={c.star_img} />
              <img src={star} className={c.star_img} />
              <p>Some improvement is necessary</p>
            </p>
          </div>
        )}
        {RatingValue == "1" && (
          <div className={c.card}>
            <img src={rating1} className={c.rating_img} />

            <p>
              <img src={star} className={c.star_img} />
              <p>Major improvement is necessary</p>
            </p>
          </div>
        )}
        {RatingValue == "0" && (
          <div className={c.card}>
            <img src={rating0} className={c.rating_img} />

            <p>
              <p>Urgent improvement is required</p>
            </p>
          </div>
        )}
        {RatingValue == "Pass" && (
          <div className={c.card}>
            <p>Pass</p>
          </div>
        )}
        {RatingValue == "ImprovementRequired" && (
          <div className={c.card}>
            <img src={warning} className={c.star_img} />

            <p>Improvement Required</p>
          </div>
        )}
        {RatingValue == "AwaitingInspection" && (
          <div className={c.card}>
            <img src={awaiting} />
          </div>
        )}
        {RatingValue == "Exempt" && (
          <div className={c.card}>
            <p>Exempt</p>
          </div>
        )}
      </div>
    );
  }

  render() {
    if (this.state.isLoading === true)
      return (
        <React.Fragment>
          <Loader color="#0652dd" />
        </React.Fragment>
      );
    else return <div>{this.componentFoodHygiene()}</div>;
  }
}

export default FoodHygieneImport;
