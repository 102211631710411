import { systemConstants } from "../_constants";
import axios from "axios";
import * as urls from "../../components/maps/urls";

export async function getLocationData(location_id, org_id, secret) {
  let data = null;

  let params = { propid: location_id, org_id: org_id };

  await axios({
    method: "get",
    url: urls.API_GATEWAY + "locations/property-specific",
    params: params,
  })
    .then((res) => {
      let locationData = res.data;
      console.log("SUCCESS!!");
      console.log(locationData);
      console.log("Dashboard Data retrieved from the database.");

      if (
        locationData === "null" ||
        locationData === null ||
        typeof locationData === "undefined"
      ) {
        console.log("Error retrieving location");
        data = null;
      } else if (locationData === "Invalid Details") {
        console.log("Details for Location retrieved are INVALID");
        data = null;
      } else if (locationData === "Property not found") {
        console.log("No Location was Found");
        data = null;
        //alert("No Location Found")
      } else {
        data = locationData;
      }
    })
    .catch((err) => {
      data = null;
      console.log("FAILURE getting location data!!");
      console.log(err);
    });

  return data;
}
