import { systemConstants } from '../_constants'
import axios from 'axios'
import * as urls from '../../components/maps/urls'
import demoAlerts from '../../tempjson/alerts.json'

export async function getLiveAlerts(location_id, country, secret) {
  let payload = null

  let data = { propid: location_id, country: country }

  await axios({
    method: 'post',
    url: urls.API_GATEWAY + 'flood/alerts-by-property',
    data: data,
  })
    .then(res => {
      payload = res.data

      if (payload === 'null' || payload === null || payload === 'Failure' || typeof payload === 'undefined') {
        payload = null
        console.log('Error retrieving property live alerts')
      } else if (payload === 'Invalid Details') {
        console.log('Details for property live alerts retrieved are INVALID')
        payload = null
      } else {
        console.log('SUCCESS!!')
        console.log(payload)
        console.log('Live Alert Data for Property retrieved from the database.')
      }
    })
    .catch(err => {
      payload = null
      console.log('FAILURE!!')
      console.log(err)
    })

  //console.log(coming)

  console.log(payload)

  // UNCOMMENT FOR DEMO ALERTS

  for (let alert1 in demoAlerts.alertlist) {
    let alert = demoAlerts.alertlist[alert1]
    if (location_id === alert.propid) {
      payload.push(alert)
    }
  }

  return payload
}
