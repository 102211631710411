/**
 * Takes an array of paths and constructs a full path
 * Paths in array must be in the order the way it's to be constructed
 * @param {array} paths
 * @throws {TypeError}
 */

export const makeURL = (paths) => {
  if (Array.isArray(paths)) {
    if (paths.length == 0) {
      console.warn("balkerne-fn/makeURL was provided with empty array");
      return "";
    }
    const [initial, ...rest] = paths;
    return rest.reduce(
      (url, path) => (url.endsWith("/") ? url + path : url + "/" + path),
      initial
    );
  } else {
    throw TypeError("balkerne-fn/makeURL only accepts parameter of type Array");
  }
};

export default makeURL;
