import React from "react";
import Weatherchart from "./weatherchart";
import moment from "moment";
import Loader from "../spinner";
import "./Weatherchart.css";

export class Weatherimport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      weatherData: [],
      LocationName: "Location",
      isLoading: false,
    };
  }

  async componentDidMount() {
    this.setState({ isLoading: true });

    let climacellurl =
      "https://data.climacell.co/v4/timelines?timesteps=1h&" +
      "fields=windSpeed&fields=precipitationIntensity&fields=temperature&units=metric&apikey=okxkhE8dMto5dfVbTDIZqr1Vjs6KE21X&location" +
      "=" +
      this.props.lat +
      "," +
      this.props.lon;

    await fetch(climacellurl)
      .then((response) => {
        return response.json();
      })
      .then((climacellweather) => {
        this.setState({ weatherData: climacellweather });

        // console.log("climacellweather");

        // console.log(climacellweather);
      });

    this.setState({ isLoading: false });
  }

  plotWeatherData() {
    if (this.state.weatherData.data != null) {
      let ForecastWeatherData = this.state.weatherData.data.timelines[0]
        .intervals;

      let Windreadings = [];

      let Rainreadings = [];

      let Temperaturereadings = [];

      for (var i = 0; i < ForecastWeatherData.length; i++) {
        let windvalue = {
          maxreading: 48,
          "wind_speed(kph)": ForecastWeatherData[i].values.windSpeed.toFixed(1),
          forecasttime: moment(ForecastWeatherData[i].startTime).format(
            "DD/MM/YYYY HH:mm"
          ),
        };

        let rainvalue = {
          maxreading: 13,
          rain: ForecastWeatherData[i].values.precipitationIntensity.toFixed(1),
          forecasttime: moment(ForecastWeatherData[i].startTime).format(
            "DD/MM/YYYY HH:mm"
          ),
        };
        let temperaturevalue = {
          maxreading: 35,
          minreading: -5,
          temp: ForecastWeatherData[i].values.temperature.toFixed(1),
          forecasttime: moment(ForecastWeatherData[i].startTime).format(
            "DD/MM/YYYY HH:mm"
          ),
        };
        Windreadings.push(windvalue);
        Rainreadings.push(rainvalue);
        Temperaturereadings.push(temperaturevalue);
      }
      // console.log(Windreadings);

      return (
        <div className="weatherContainer">
          <Weatherchart
            locationName={this.state.LocationName}
            charttype="Bar"
            readingtitle="Wind"
            readingtype="wind_speed(kph)"
            weatherreadings={Windreadings}
            chartcolour="#60a3bc"
          />
          <Weatherchart
            locationName={this.state.LocationName}
            charttype="Area"
            readingtitle="Temperature"
            readingtype="temp"
            weatherreadings={Temperaturereadings}
            chartcolour="#f0932b"
          />
          <Weatherchart
            locationName={this.state.LocationName}
            charttype="Bar"
            readingtitle="Rainfall"
            readingtype="rain"
            weatherreadings={Rainreadings}
            chartcolour="#54a0ff"
          />
        </div>
      );
    }
  }

  render() {
    // Returns an event based on menu click

    if (this.state.isLoading === true)
      return (
        <React.Fragment>
          <Loader color="#0652dd" />
        </React.Fragment>
      );
    else return <div>{this.plotWeatherData()}</div>;
  }
}

export default Weatherimport;
