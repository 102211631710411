/**
 * Common styles for any part of Google Maps.
 */

import * as resources from "./resources";

/**
 * Map themes.
 */
export const maps = {
  // Black/white theme
  mono: [
    {
      featureType: "water",
      elementType: "geometry.fill",
      stylers: [{ color: "#bbbbbb" }],
    },
    {
      featureType: "transit",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "road.highway",
      elementType: "geometry.stroke",
      stylers: [{ color: "#b3b3b3" }],
    },
    {
      featureType: "road.highway",
      elementType: "geometry.fill",
      stylers: [{ color: "#ffffff" }],
    },
    {
      featureType: "road.arterial",
      elementType: "geometry.stroke",
      stylers: [{ color: "#d6d6d6" }],
    },
    {
      featureType: "road.arterial",
      elementType: "geometry.fill",
      stylers: [{ color: "#ffffff" }],
    },
    {
      featureType: "road.local",
      elementType: "geometry.stroke",
      stylers: [{ color: "#d7d7d7" }],
    },
    {
      featureType: "road.local",
      elementType: "geometry.fill",
      stylers: [{ color: "#ffffff" }, { weight: 1.8 }],
    },
    {
      featureType: "road",
      elementType: "labels.text.fill",
      stylers: [{ color: "#696969" }],
    },
    {
      featureType: "road",
      elementType: "labels.icon",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "administrative",
      elementType: "geometry",
      stylers: [{ color: "#a7a7a7" }],
    },
    {
      featureType: "administrative",
      elementType: "labels.text.fill",
      stylers: [{ color: "#737373" }],
    },
    {
      featureType: "landscape",
      elementType: "geometry.fill",
      stylers: [{ color: "#efefef" }],
    },
    {
      featureType: "poi",
      elementType: "geometry.fill",
      stylers: [{ color: "#dadada" }],
    },
    {
      featureType: "poi",
      elementType: "labels",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "poi",
      elementType: "labels.icon",
      stylers: [{ visibility: "off" }],
    },
  ],
};

export const dashboardLocation = {
  zIndex: 10,
  strokeColor: "#0A55A0",
  strokeOpacity: 0.8,
  strokeWeight: 4,
  fillColor: "#0A55A0",
  fillOpacity: 0.4,
  clickable: false,
};

export const editLocation = {
  zIndex: 10,
  strokeColor: "#0A55A0",
  strokeOpacity: 0.8,
  strokeWeight: 2,
  fillColor: "#0A55A0",
  fillOpacity: 0.4,
  clickable: false,
  editable: true,
};

export const churchLandArea = {
  zIndex: 10,
  strokeColor: "#7CFC00",
  strokeOpacity: 0.8,
  strokeWeight: 1,
  fillColor: "#7CFC00",
  fillOpacity: 0.6,
  clickable: false,
};

export const churchBuildingArea = {
  zIndex: 12,
  strokeColor: "#989898",
  strokeOpacity: 0.4,
  strokeWeight: 2,
  fillColor: "#989898",
  fillOpacity: 0.7,
  clickable: false,
};

export const churchCopperArea = {
  zIndex: 13,
  strokeColor: "#D2691E",
  strokeOpacity: 0.7,
  strokeWeight: 1,
  fillColor: "#D2691E",
  fillOpacity: 0.8,
  clickable: false,
};

export const churchLeadArea = {
  zIndex: 14,
  strokeColor: "	#404040",
  strokeOpacity: 0.7,
  strokeWeight: 1,
  fillColor: "	#404040",
  fillOpacity: 0.8,
  clickable: false,
};

export const churchCarParkArea = {
  zIndex: 11,
  strokeColor: "#ffd500",
  strokeOpacity: 0.9,
  strokeWeight: 12,
  fillColor: "#ffd500",
  fillOpacity: 0.9,
  clickable: false,
};

export const churchRoofAccess = {
  zIndex: 15,
  strokeColor: "#cc0000",
  strokeOpacity: 0.9,
  strokeWeight: 7,
  fillColor: "#cc0000",
  fillOpacity: 0.9,
  clickable: false,
};

export const churchCamera = {
  zIndex: 15,
  strokeColor: "#cc0000",
  strokeOpacity: 0.9,
  strokeWeight: 7,
  fillColor: "#cc0000",
  fillOpacity: 0.9,
  clickable: false,
};

export const churchSiteBuilding = {
  zIndex: 15,
  strokeColor: "#3232ff",
  strokeOpacity: 0.9,
  strokeWeight: 7,
  fillColor: "#3232ff",
  fillOpacity: 0.8,
  clickable: false,
};

export const map_news_style = {
  zIndex: 15,
  strokeColor: "#3232ff",
  strokeOpacity: 0.9,
  strokeWeight: 7,
  fillColor: "#3232ff",
  fillOpacity: 0.8,
  clickable: false,
};

//FOR SEVERITY - from lvel to name
export const severity = ["", "Severe Warning", "Warning", "Alert", "Advisory"];

export const news_map_style = function (feature) {
  let type = feature.getProperty("filter");
  let index = 1;

  if (type == "flooding rain uk") {
    index = 1;
  } else if (type == "criminal damage") {
    index = 2;
  } else if (type == "arson") {
    index = 3;
  } else if (type == "theft") {
    index = 2;
  } else if (type == "robbery") {
    index = 2;
  } else {
    index = 1;
  }

  let icon = resources.news_icons[index];

  return {
    title: feature.getProperty("title"),
    icon: icon,
  };
};

/**
 * Style for flood alerts data layer.
 */
export const floodAlert = function (feature) {
  let type = feature.getGeometry().getType();
  switch (type) {
    case "Point":
      let title = [
        severity[feature.getProperty("severityLevel")],
        "-",
        feature.getProperty("description"),
      ].join(" ");

      let severityLevel = feature.getProperty("severityLevel");
      let icon = resources.floodIcons[severityLevel];

      //console.log(title)

      return {
        title: title,
        icon: icon,
      };

    default:
      return {
        zIndex: 1,
        clickable: false,
        strokeColor: "#060087",
        strokeOpacity: 0.8,
        strokeWeight: 1,
        fillColor: "#060087",
        fillOpacity: 0.4,
      };
  }
};

/* CHANGE THE LAYER STYLE HERE FOR POLYGONS-BUBBLES RISK PROPERTIES FOR EACH, LAYERS STYLES AND BUBBLES STYLES WERE MERGED */
/* BUBBLE STYLES AND COLORS HERE */

export const riskLocationDataRofrs = function (feature) {
  let title = feature.getProperty("name");

  let severity = feature.getProperty("rofrs_severity");
  let icon = null;
  let zIndex = 10;
  let strokeColor = null;
  let strokeOpacity = 0.8;
  let strokeWeight = 4;
  let fillColor = null;
  let fillOpacity = 0.4;

  if (severity === 4) {
    icon = resources.propertyRiskTableIcons[1];
    strokeColor = "#E52922";
    fillColor = "#E52922";
  } else if (severity === 3) {
    icon = resources.propertyRiskTableIcons[2];
    strokeColor = "#ff9900";
    fillColor = "#ff9900";
  } else {
    icon = resources.propertyRiskTableIcons[3];
    strokeColor = "#00cc00";
    fillColor = "#00cc00";
  }

  console.log(title);
  console.log(icon);

  return {
    title: title,
    icon: icon,
    zIndex: zIndex,
    strokeColor: strokeColor,
    strokeOpacity: strokeOpacity,
    strokeWeight: strokeWeight,
    fillColor: fillColor,
    fillOpacity: fillOpacity,
  };
};

export const riskLocationDataRofsw = function (feature) {
  let title = feature.getProperty("name");

  let severity = feature.getProperty("rofsw_severity");
  let icon = null;
  let zIndex = 10;
  let strokeColor = null;
  let strokeOpacity = 0.8;
  let strokeWeight = 4;
  let fillColor = null;
  let fillOpacity = 0.4;

  if (severity === 4) {
    icon = resources.propertyRiskTableIcons[1];
    strokeColor = "#E52922";
    fillColor = "#E52922";
  } else if (severity === 3) {
    icon = resources.propertyRiskTableIcons[2];
    strokeColor = "#ff9900";
    fillColor = "#ff9900";
  } else {
    icon = resources.propertyRiskTableIcons[3];
    strokeColor = "#00cc00";
    fillColor = "#00cc00";
  }

  return {
    title: title,
    icon: icon,
    zIndex: zIndex,
    strokeColor: strokeColor,
    strokeOpacity: strokeOpacity,
    strokeWeight: strokeWeight,
    fillColor: fillColor,
    fillOpacity: fillOpacity,
  };
};

export const riskLocationDataCrime = function (feature) {
  let title = feature.getProperty("name");

  let severity = feature.getProperty("crime_severity");
  let icon = null;
  let zIndex = 10;
  let strokeColor = null;
  let strokeOpacity = 0.8;
  let strokeWeight = 4;
  let fillColor = null;
  let fillOpacity = 0.4;

  if (severity === 4) {
    icon = resources.propertyRiskTableIcons[1];
    strokeColor = "#E52922";
    fillColor = "#E52922";
  } else if (severity === 3) {
    icon = resources.propertyRiskTableIcons[2];
    strokeColor = "#ff9900";
    fillColor = "#ff9900";
  } else {
    icon = resources.propertyRiskTableIcons[3];
    strokeColor = "#00cc00";
    fillColor = "#00cc00";
  }

  return {
    title: title,
    icon: icon,
    zIndex: zIndex,
    strokeColor: strokeColor,
    strokeOpacity: strokeOpacity,
    strokeWeight: strokeWeight,
    fillColor: fillColor,
    fillOpacity: fillOpacity,
  };
};

export const riskLocationDataFire = function (feature) {
  let title = feature.getProperty("name");

  let severity = feature.getProperty("fire_severity");
  let icon = null;
  let zIndex = 10;
  let strokeColor = null;
  let strokeOpacity = 0.8;
  let strokeWeight = 4;
  let fillColor = null;
  let fillOpacity = 0.4;

  if (severity === 4) {
    icon = resources.propertyRiskTableIcons[1];
    strokeColor = "#E52922";
    fillColor = "#E52922";
  } else if (severity === 3) {
    icon = resources.propertyRiskTableIcons[2];
    strokeColor = "#ff9900";
    fillColor = "#ff9900";
  } else {
    icon = resources.propertyRiskTableIcons[3];
    strokeColor = "#00cc00";
    fillColor = "#00cc00";
  }

  return {
    title: title,
    icon: icon,
    zIndex: zIndex,
    strokeColor: strokeColor,
    strokeOpacity: strokeOpacity,
    strokeWeight: strokeWeight,
    fillColor: fillColor,
    fillOpacity: fillOpacity,
  };
};

export const riskLocationDataFoodHygiene = function (feature) {
  let title = feature.getProperty("name");

  let severity = feature.getProperty("food_hygiene_severity");
  let icon = null;
  let zIndex = 10;
  let strokeColor = null;
  let strokeOpacity = 0.8;
  let strokeWeight = 4;
  let fillColor = null;
  let fillOpacity = 0.4;

  if (severity === 4) {
    icon = resources.propertyRiskTableIcons[1];
    strokeColor = "#E52922";
    fillColor = "#E52922";
  } else if (severity === 3) {
    icon = resources.propertyRiskTableIcons[2];
    strokeColor = "#ff9900";
    fillColor = "#ff9900";
  } else {
    icon = resources.propertyRiskTableIcons[3];
    strokeColor = "#00cc00";
    fillColor = "#00cc00";
  }

  return {
    title: title,
    icon: icon,
    zIndex: zIndex,
    strokeColor: strokeColor,
    strokeOpacity: strokeOpacity,
    strokeWeight: strokeWeight,
    fillColor: fillColor,
    fillOpacity: fillOpacity,
  };
};

export const riskLocationDataPandemic = function (feature) {
  let title = feature.getProperty("name");

  let severity = feature.getProperty("pandemic_severity");
  let icon = null;
  let zIndex = 10;
  let strokeColor = null;
  let strokeOpacity = 0.8;
  let strokeWeight = 4;
  let fillColor = null;
  let fillOpacity = 0.4;

  if (severity === 4) {
    icon = resources.propertyRiskTableIcons[1];
    strokeColor = "#E52922";
    fillColor = "#E52922";
  } else if (severity === 3) {
    icon = resources.propertyRiskTableIcons[2];
    strokeColor = "#ff9900";
    fillColor = "#ff9900";
  } else {
    icon = resources.propertyRiskTableIcons[3];
    strokeColor = "#00cc00";
    fillColor = "#00cc00";
  }

  return {
    title: title,
    icon: icon,
    zIndex: zIndex,
    strokeColor: strokeColor,
    strokeOpacity: strokeOpacity,
    strokeWeight: strokeWeight,
    fillColor: fillColor,
    fillOpacity: fillOpacity,
  };
};

// export const riskLocationData = function(feature) {

//             let title = feature.getProperty('name')
//             switch (type) {
//             case 'rofrs':
//                 let severity = feature.getProperty('rofrs_value')
//                 let icon = null
//                 if(severity===4){
//                     icon = resources.propertyRiskTableIcons[1];
//                 }
//                 else if(severity===3){
//                     icon = resources.propertyRiskTableIcons[2];
//                 }
//                 else{
//                     icon = resources.propertyRiskTableIcons[3];
//                 }

//                 return {

//                     title: title,
//                     icon: icon
//                 }

//             case 'rofsw':
//                 let severity = feature.getProperty('rofsw_value')
//                 let icon = null
//                 if(severity===4){
//                     icon = resources.propertyRiskTableIcons[1];
//                 }
//                 else if(severity===3){
//                     icon = resources.propertyRiskTableIcons[2];
//                 }
//                 else{
//                     icon = resources.propertyRiskTableIcons[3];
//                 }

//                 return {

//                     title: title,
//                     icon: icon
//                 }

//             case 'crime':
//                 let severity = feature.getProperty('crime')
//                 let icon = null
//                 if(severity===4){
//                     icon = resources.propertyRiskTableIcons[1];
//                 }
//                 else if(severity===3){
//                     icon = resources.propertyRiskTableIcons[2];
//                 }
//                 else{
//                     icon = resources.propertyRiskTableIcons[3];
//                 }

//                 return {

//                     title: title,
//                     icon: icon
//                 }

//             case 'fire':
//                 let severity = feature.getProperty('fire')
//                 let icon = null
//                 if(severity===4){
//                     icon = resources.propertyRiskTableIcons[1];
//                 }
//                 else if(severity===3){
//                     icon = resources.propertyRiskTableIcons[2];
//                 }
//                 else{
//                     icon = resources.propertyRiskTableIcons[3];
//                 }

//                 return {

//                     title: title,
//                     icon: icon
//                 }

//             default:
//                 return {

//                     title: title,
//                     icon: resources.propertyRiskTableIcons[3]
//                 }
//             }

// }
// }
