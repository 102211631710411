import React from "react";
import "./Displaycrimerisk.css";
import { Riskrating } from "../maps/riskrating";
import recommendations_extract from "../../tempjson/recommendations.json";

class Recommendation_notice extends React.Component {
  render() {
    return (
      <div className="notice_text">
        <div className="notice_grid">
          <div>
            {" "}
            <img src={require("../../images/check-mark.jpg")} />
          </div>
          <div>
            {" "}
            {this.props.recommendation}
            <a href={this.props.link}>{this.props.filename}</a>
          </div>
        </div>
      </div>
    );
  }
}

export class Displaycrimerisk extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      risk_rating: this.props.riskrating,
      currentrecommendations: [],
      selected_crime: "None selected",
    };
  }

  async componentDidMount() {
    this.setState({
      currentrecommendations: recommendations_extract.defaultrecommendation,
    });
  }

  Riskchartscore() {
    switch (this.state.risk_rating) {
      case 1:
        return 1;

      case 2:
        return 2;

      case 3:
        return 3;

      case 4:
        return 6;

      default:
        return 0;
    }
  }

  setCurrentRecommendations(crime_type) {
    console.log("Map Recommendaitons called");

    let setrecommendation = recommendations_extract.norecommendation;

    this.setState({ selected_crime: crime_type });

    if (this.props.orgid == 4) {
      switch (crime_type) {
        case "Antisocial behaviour":
          setrecommendation = recommendations_extract.long_term_caravan_asb;
          break;

        case "Burglary":
          setrecommendation =
            recommendations_extract.long_term_caravan_burglary;
          break;

        case "Criminal Damage and Arson":
          setrecommendation =
            recommendations_extract.long_term_caravan_criminal_damage;
          break;

        case "Possession of Weapons":
          setrecommendation =
            recommendations_extract.long_term_caravan_possession_weapons;
          break;

        case "Robbery":
          setrecommendation = recommendations_extract.long_term_caravan_robbery;
          break;

        case "Shoplifting":
          setrecommendation =
            recommendations_extract.long_term_caravan_shoplifting;
          break;
        case "Vehicle_Crime":
          setrecommendation =
            recommendations_extract.long_term_caravan_vehicle_damage;
          break;

        case "Theft from the Person":
          setrecommendation =
            recommendations_extract.long_term_caravan_theft_person;
          break;
      }
    } else if (this.props.orgid == 2) {
      switch (crime_type) {
        case "Antisocial behaviour":
          setrecommendation = recommendations_extract.long_term_church_asb;
          break;

        case "Burglary":
          setrecommendation = recommendations_extract.long_term_church_burglary;
          break;

        case "Criminal Damage and Arson":
          setrecommendation =
            recommendations_extract.long_term_church_criminal_damage;
          break;

        case "Possession of Weapons":
          setrecommendation =
            recommendations_extract.long_term_church_possession_weapons;
          break;

        case "Robbery":
          setrecommendation = recommendations_extract.long_term_church_robbery;
          break;

        case "Shoplifting":
          setrecommendation =
            recommendations_extract.long_term_church_shoplifting;
          break;
        case "Vehicle_Crime":
          setrecommendation =
            recommendations_extract.long_term_church_vehicle_damage;
          break;

        case "Theft from the Person":
          setrecommendation =
            recommendations_extract.long_term_church_theft_person;
          break;
      }
    } else {
      switch (crime_type) {
        case "Antisocial behaviour":
          setrecommendation = recommendations_extract.long_term_retail_asb;
          break;

        case "Burglary":
          setrecommendation = recommendations_extract.long_term_retail_burglary;
          break;

        case "Criminal Damage and Arson":
          setrecommendation =
            recommendations_extract.long_term_retail_criminal_damage;
          break;

        case "Possession of Weapons":
          setrecommendation =
            recommendations_extract.long_term_retail_possession_weapons;
          break;

        case "Robbery":
          setrecommendation = recommendations_extract.long_term_retail_robbery;
          break;

        case "Shoplifting":
          setrecommendation =
            recommendations_extract.long_term_retail_shoplifting;
          break;
        case "Vehicle_Crime":
          setrecommendation =
            recommendations_extract.long_term_retail_vehicle_damage;
          break;

        case "Theft from the Person":
          setrecommendation =
            recommendations_extract.long_term_retail_theft_person;
          break;
        default:
          setrecommendation = recommendations_extract.norecommendation;
          break;
      }
    }

    this.setState({ currentrecommendations: setrecommendation });
  }

  mapRecommendations = () => {
    {
    }
    if (
      this.state.currentrecommendations &&
      this.state.currentrecommendations.length
    ) {
      return this.state.currentrecommendations.map(
        (recommendationList, index) => (
          <Recommendation_notice
            recommendation={recommendationList.description}
            filename={recommendationList.filename}
            link={recommendationList.link}
            key={index}
          />
        )
      );
    }
  };

  render() {
    return (
      <div className="Crimeriskhighlights">
        <h3 className="Crimeriskhighlightssheading">
          Crime Statistics | Last Updated: {this.props.crime_data_prop.date}{" "}
        </h3>
        <div className="crimeoverview">
          {/* <p><b>LSOA Name </b> : {this.props.crime_data_prop.LSOA_Name}</p> */}
          <p>
            The data below shows crime rates in your area. 0 equals lowest and
            100 equals highest ranked crime rate.
          </p>
        </div>
        <div className="Crimeriskcontent">
          <div className="crimstats">
            {/* <div id ="stat_crime">
          <Riskrating
            title={"Overall"}
            risk_rating={Math.round(this.props.crime_data_prop.Overall)||0}
            max_score={100}
            rating_text={Math.round(this.props.crime_data_prop.Overall)||0}
          />
          </div> */}
            <div
              id="stat_crime"
              onClick={() =>
                this.setCurrentRecommendations("Antisocial behaviour")
              }
            >
              <Riskrating
                title={"Antisocial behaviour"}
                risk_rating={
                  Math.round(
                    this.props.crime_data_prop.anti_social_behaviour
                  ) || 0
                }
                max_score={100}
                rating_text={
                  Math.round(
                    this.props.crime_data_prop.anti_social_behaviour
                  ) || 0
                }
              />
            </div>
            {/* <Riskrating
            title={"Bicycle Theft"}
            risk_rating={Math.round(this.props.crime_data_prop.Bicycle_Theft)||0}
            max_score={100}
            rating_text={Math.round(this.props.crime_data_prop.Bicycle_Theft)||0}
          /> */}

            <div
              id="stat_crime"
              onClick={() => this.setCurrentRecommendations("Burglary")}
            >
              <Riskrating
                title={"Burglary"}
                risk_rating={
                  Math.round(this.props.crime_data_prop.burglary) || 0
                }
                max_score={100}
                rating_text={
                  Math.round(this.props.crime_data_prop.burglary) || 0
                }
              />
            </div>

            <div
              id="stat_crime"
              onClick={() =>
                this.setCurrentRecommendations("Criminal Damage and Arson")
              }
            >
              <Riskrating
                title={"Criminal Damage and Arson"}
                risk_rating={
                  Math.round(
                    this.props.crime_data_prop.criminal_damage_and_arson
                  ) || 0
                }
                max_score={100}
                rating_text={
                  Math.round(
                    this.props.crime_data_prop.criminal_damage_and_arson
                  ) || 0
                }
              />
            </div>
            {/* <Riskrating
            title={"Other Crime"}
            risk_rating={Math.round(this.props.crime_data_prop.Other_Crime)||0}
            max_score={100}
            rating_text={Math.round(this.props.crime_data_prop.Other_Crime)||0}
          /> */}
            <div
              id="stat_crime"
              onClick={() =>
                this.setCurrentRecommendations("Possession of Weapons")
              }
            >
              <Riskrating
                title={"Possession of Weapons"}
                risk_rating={
                  Math.round(this.props.crime_data_prop.weapons_possesion) || 0
                }
                max_score={100}
                rating_text={
                  Math.round(this.props.crime_data_prop.weapons_possesion) || 0
                }
              />
            </div>
            {/* <Riskrating
            title={"Public Order"}
            risk_rating={Math.round(this.props.crime_data_prop.Public_Order)||0}
            max_score={100}
            rating_text={Math.round(this.props.crime_data_prop.Public_Order)||0}
          /> */}
            <div
              id="stat_crime"
              onClick={() => this.setCurrentRecommendations("Robbery")}
            >
              <Riskrating
                title={"Robbery"}
                risk_rating={
                  Math.round(this.props.crime_data_prop.robbery) || 0
                }
                max_score={100}
                rating_text={
                  Math.round(this.props.crime_data_prop.robbery) || 0
                }
              />
            </div>
            <div
              id="stat_crime"
              onClick={() => this.setCurrentRecommendations("Shoplifting")}
            >
              <Riskrating
                title={"Shoplifting"}
                risk_rating={
                  Math.round(this.props.crime_data_prop.shoplifting) || 0
                }
                max_score={100}
                rating_text={
                  Math.round(this.props.crime_data_prop.shoplifting) || 0
                }
              />
            </div>
            <div
              id="stat_crime"
              onClick={() =>
                this.setCurrentRecommendations("Theft from the Person")
              }
            >
              <Riskrating
                title={"Theft from the Person"}
                risk_rating={
                  Math.round(this.props.crime_data_prop.theft_from_person) || 0
                }
                max_score={100}
                rating_text={
                  Math.round(this.props.crime_data_prop.theft_from_person) || 0
                }
              />
            </div>
            <div
              id="stat_crime"
              onClick={() => this.setCurrentRecommendations("Vehicle_Crime")}
            >
              <Riskrating
                title={"Vehicle_Crime"}
                risk_rating={
                  Math.round(this.props.crime_data_prop.vehicle) || 0
                }
                max_score={100}
                rating_text={
                  Math.round(this.props.crime_data_prop.vehicle) || 0
                }
              />
            </div>
          </div>
          <div className="crimerecommendation">
            <h4 className="crimerecommendationheading">
              Recommendations for : {this.state.selected_crime}{" "}
            </h4>
            {this.mapRecommendations()}
          </div>
        </div>
      </div>
    );
  }
}
export default Displaycrimerisk;
