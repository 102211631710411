import React from "react";

import axios from "axios";
import * as urls from "../components/maps/urls";
import { Auth } from "aws-amplify";
//  import AWS from 'aws-sdk';
// // const AWS = require('aws-sdk')
// // const AWS = require('aws-sdk');
// // AWS.config.loadFromPath('C:\Users\Lukas\Documents\risk-management-application\frontend\config.json');
// const ses = new AWS.SES()

// load aws config
import { userActions } from "../redux/_actions";
import { connect } from "react-redux";
import { getUserDetails } from "../redux/_helpers/getUserDetails";
import Header from "../components/Header";
import { RefreshAuthTokens } from "../components/RefreshAuthTokens";
import { Logout } from "../components/Logout";
import "./CreateAccount.css";

// import { ROLES_ADMIN } from "../components/user_management/roles.js"
import { ROLES_ADMIN } from "./../components/userManagement/roles";
import Select from "balkerne-components/Select";

export class Register extends React.Component {
  constructor(props) {
    super(props);
    //setting initial user row
    this.state = {
      userData: {
        email: "",
        full_name: "",
        position: "",
        group: "",
        org_id: "",
        phone: "",
        password: "",
        manager: "",
      },
      databaseClear: true,
      databaseSuccess: false,
      cognitoSuccess: false,
      errorArray: [],
      errorText: "",
    };
    this.sendForgotPassword = this.sendForgotPassword.bind(this);
    this.postDatabase = this.postDatabase.bind(this);
    this.checkCognito = this.checkCognito.bind(this);
    this.checkDatabase = this.checkDatabase.bind(this);
    this.sendEmail = this.sendEmail.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validateAddUsers = this.validateAddUsers.bind(this);
  }

  // createEmail=(email, password)=>{
  //   return {
  //     Destination: {
  //       ToAddresses: [email]
  //     },
  //     Message: {
  //       Body: {
  //         Html: {
  //           Charset: 'UTF-8',
  //           Data:
  //             'Your user account was created. Please log in with the following password: '+password
  //         },
  //         Text: {
  //           Charset: 'UTF-8',
  //           Data: 'This is the message body in text format.'
  //         }
  //       },
  //       Subject: {
  //         Charset: 'UTF-8',
  //         Data: 'Temporary Pass'
  //       }
  //     },
  //     ReturnPath: "noreply@balkerne.com",
  //     Source: "noreply@balkerne.com"
  //   }

  // }

  async componentDidMount() {
    await RefreshAuthTokens(this.props);

    this.state.userData.manager = this.props.userData.data.id;

    this.setState({ mounted: true });
  }

  async sendEmail() {
    let success = true;

    let secret = this.props.user.user.pool.userPoolId;

    let email = this.state.userData.email;
    if (email && email.length > 3) {
      email = email.toLowerCase();
    }

    let data = {
      email: email,
      password: this.state.userData.password,
      secret: secret,
    };

    await axios({
      method: "post",
      url: urls.API_GATEWAY + "users/send-temp-psw",
      data: data,
    })
      .then((res) => {
        console.log(res.data);
        let response = res.data;

        if (
          response === "null" ||
          response === null ||
          response === "Failure" ||
          typeof response === "undefined"
        ) {
          success = false;
          console.log("Email sending FAILURE");
        } else if (response === "Invalid Details") {
          console.log("Details for sending temp emails are INVALID");
          success = false;
        } else {
          success = true;
          console.log("SUCCESS!! PSW successfully sent.");
          console.log(res.data);
        }
      })
      .catch((err) => {
        success = false;
        console.log("FAILURE!!");
        console.log(err);
        this.setState({
          errorText: "Database email sending error",
        });
      });
    return success;
  }

  async validateAddUsers() {
    let success = true;
    let validate = true;
    let errorMessage = [];
    let user = this.state.userData;

    //regex for validating emails and phoneNumbers - not final!!!
    var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    let phoneRegex = /^(?:0|\+?44)(?:\d\s?){9,10}$/;

    //test if any fields are undefined or empty (missing) as well as checking if email and phone number are valid
    if (typeof user.full_name === "undefined" || user.full_name.length < 1) {
      validate = false;
      errorMessage.push("Field 'Full Name' cannot be empty.\n");
    }
    if (typeof user.email === "undefined" || user.email.length < 1) {
      validate = false;
      errorMessage.push("Field 'Email' cannot be empty.\n");
    } else if (!emailRegex.test(user.email.toLowerCase())) {
      validate = false;
      errorMessage.push("Field 'Email' is not valid.\n");
    }
    if (typeof user.phone === "undefined" || user.phone.length < 1) {
      validate = false;
      errorMessage.push("Field 'Phone Number' cannot be empty.\n");
    } else if (!phoneRegex.test(user.phone)) {
      validate = false;
      errorMessage.push("Field 'Phone Number' is not valid.\n");
    } else if (user.phone.toString().substring(0, 1) == "0") {
      user.phone = user.phone = "+44" + user.phone.slice(1);
    }
    if (typeof user.position === "undefined" || user.position.length < 1) {
      validate = false;
      errorMessage.push("Field 'Position' cannot be empty.\n");
    }
    if (typeof user.group === "undefined" || user.group.length < 1) {
      validate = false;
      errorMessage.push("Field 'Group' cannot be empty.\n");
    }
    if (typeof user.org_id === "undefined" || user.org_id.length < 1) {
      validate = false;
      errorMessage.push("Field 'Org Id' cannot be empty.\n");
    }

    //if everything valid, move to the next form, if not then print an error message.
    if (validate === true) {
      success = true;
      success = await this.checkDatabase(this.state.userData.email);
      if (success === false) {
        console.log("these credentials already exist in the database");
      }
    } else {
      success = false;
      this.setState({
        errorText: this.printError(errorMessage),
      });
    }
    return success;
  }

  //formats the error message to be on separate lines
  printError = (errorMessage) => {
    var lines = errorMessage;
    var br = lines.map(function (line) {
      return (
        <span>
          {line}
          <br />
        </span>
      );
    });
    return <div>{br}</div>;
  };

  //loads updated values into the users array
  updateFields = (users) => {
    this.setState(this.state.users, this.props.users);
  };

  // Use the submitted data to set the state
  handleChange = (event) => {
    this.setState({
      userData: {
        // object that we want to update
        ...this.state.userData, // keep all other key-value pairs
        [event.target.id]: event.target.value, // update the value of specific key
      },
    });

    // this.setState({userData:{
    //   [event.target.id]: event.target.value
    // }});
  };

  async checkConnection(secret) {
    let success = false;

    await axios({ method: "get", url: urls.API_GATEWAY + "users/working" })
      .then((res) => {
        if (res.data === "Success") {
          success = true;
          console.log("connection working");
        }
      })
      .catch((err) => {
        success = false;
        console.log("FAILURE!!");
        console.log(err);
        alert("No connection to the server");
        if (!err.message) {
          console.log("Error establishing working connection: ", err);
          //  Alert.alert('Error when signing in: ', err);
        } else {
          console.log("Error establishing working connection: ", err.message);
          //  Alert.alert('Error when signing in: ', err.message);

          this.setState({
            errorText: this.printError("Cannot reach Server"),
          });
        }
      });
    return success;
  }

  async handleSubmit(event) {
    event.preventDefault();
    let usersAddedSuccess = false;
    let sendMailSuccess = false;
    let userValidation = false;
    let answer = await this.checkConnection(
      this.props.user.user.signInUserSession.idToken.jwtToken
    );
    if (answer === true) {
      userValidation = await this.validateAddUsers();
      if (userValidation) {
        usersAddedSuccess = await this.postDatabase();
        if (usersAddedSuccess) {
          sendMailSuccess = await this.sendEmail();
          if (sendMailSuccess) {
            alert("User Successfully added to database");
            document.location.reload();
          } else {
            console.log("email sending failed");
          }
        } else {
          console.log("database addition failed");
        }
      } else {
        console.log("user validation failed");
      }
    } else {
      alert("No connection to Server");
    }
  }

  // this.setState({
  //     errorText: this.printError(errorMessage)
  //   })
  async sendForgotPassword(email1) {
    let email = email1;
    if (email && email.length > 3) {
      email = email.toLowerCase();
    }
    try {
      await Auth.forgotPassword(email);
      console.log("EMAIL SENT");
    } catch (e) {
      alert(e.message);
      console.log("EMAIL SEND FAIL");
    }
  }

  async checkDatabase(email1) {
    let success = true;
    let checked = "exists";
    let password = null;

    let email = email1;
    if (email && email.length > 3) {
      email = email.toLowerCase();
    }

    let params = { email: email };

    await axios({
      method: "get",
      url: urls.API_GATEWAY + "users/check-email",
      params: params,
    })
      .then((res) => {
        checked = res.data;
        if (
          checked === "null" ||
          checked === null ||
          typeof checked === "undefined"
        ) {
          checked = null;
          console.log("FAILURE!!");
        }
      })
      .catch((err) => {
        console.log("FAILURE adding user!!");
        console.log(err);
        success = false;
      });

    if (checked === "available") {
      success = true;
      password = this.password_generator();
      let userData = this.state.userData;
      userData.password = password;
      this.setState({ userData });
      console.log("checking cognito");
      success = await this.checkCognito(this.state.userData, password);
      if (success === false) {
        console.log("this email is taken in cognito");
      }

      // this.postDatabase();
    } else {
      success = false;
      console.log("username already in database");
      this.setState({
        errorText: "User with this email already exists",
      });
    }

    return success;
  }

  password_generator = () => {
    var length = 12;
    var string = "abcdefghijklmnopqrstuvwxyz"; //to upper
    var numeric = "0123456789";
    var punctuation = "!@#$%^&*";
    var password = "";
    var character = "";
    var crunch = true;
    while (password.length < length) {
      let entity1 = Math.ceil(string.length * Math.random() * Math.random());
      let entity2 = Math.ceil(numeric.length * Math.random() * Math.random());
      let entity3 = Math.ceil(
        punctuation.length * Math.random() * Math.random()
      );
      let hold = string.charAt(entity1);
      hold = password.length % 2 === 0 ? hold.toUpperCase() : hold;
      character += hold;
      character += numeric.charAt(entity2);
      character += punctuation.charAt(entity3);
      password = character;
    }
    password = password
      .split("")
      .sort(function () {
        return 0.5 - Math.random();
      })
      .join("");
    return password.substr(0, length);
  };

  async checkCognito(details, password) {
    let email = details.email;
    if (email && email.length > 3) {
      email = email.toLowerCase();
    }
    let success = true;
    console.log(password);
    await Auth.signUp({
      username: email,
      password: password,
      attributes: {
        email: email,
      },
      region: "region eu-west-1",
    })
      .then((data) => {
        console.log(data);

        this.state.cognitoSuccess = true;
        details.aws_id = data.userSub;
        // this.sendEmail();
        // this.postDatabase();
        success = true;
        // this.sendForgotPassword(email)
      })
      .catch((err) => {
        console.log(err);
        success = false;
        this.state.cognitoSuccess = false;

        if (err.code === "UsernameExistsException") {
          this.setState({
            errorText: "User with this email already exists",
          });
        } else {
          this.setState({
            errorText: "Cognito adding error",
          });
        }
      });

    return success;

    // // After retrieving the confirmation code from the user
    // Auth.confirmSignUp(username, code, {
    //   // Optional. Force user confirmation irrespective of existing alias. By default set to True.
    //   forceAliasCreation: true
    // })
    //   .then(data => console.log(data))
    //   .catch(err => console.log(err));

    // Auth.resendSignUp(username)
    //   .then(() => {
    //     console.log("code resent successfully");
    //   })
    //   .catch(e => {
    //     console.log(e);
    //   });
  }

  async postDatabase() {
    let success = true;
    let data = {};

    console.log(this.state.userData);

    for (var [key, value] of Object.entries(this.state.userData)) {
      if (key === "phone") {
        if (
          typeof value === "undefined" ||
          value === null ||
          value.length < 3
        ) {
          Object.assign(data, { [key]: "" });
          //formData.append(key, "");
          console.log(key);
          console.log("empty phone");
        } else {
          Object.assign(data, { [key]: value });
          //formData.append(key, value);
          console.log(key);
          console.log(value);
          console.log(data);
        }
      } else if (key === "email") {
        let email = value;
        if (email && email.length > 3) {
          email = email.toLowerCase();
        }
        Object.assign(data, { [key]: email });
        //formData.append(key, value);
        console.log(key);
        console.log(value);
        console.log(data);
      } else {
        Object.assign(data, { [key]: value });
        //formData.append(key, value);
        console.log(key);
        console.log(value);
        console.log(data);
      }
    }

    await axios({
      method: "post",
      url: urls.API_GATEWAY + "users/add-user",
      data: data,
    })
      .then((res) => {
        console.log(res.data);

        let response = res.data;

        if (
          response === "null" ||
          response === null ||
          response === "Failure" ||
          typeof response === "undefined"
        ) {
          success = false;
          console.log("Users adding FAILURE");
        } else if (
          response === "Invalid Details" ||
          response === "Password too short"
        ) {
          console.log(
            "Details for adding users are INVALID or PASSWORD TOO SHORT"
          );
          success = false;
        } else {
          console.log("SUCCESS adding user!!");
          console.log("User added to the database.");
          success = true;
        }
      })
      .catch((err) => {
        console.log("FAILURE adding user to database!!");
        console.log(err);
        this.setState({
          errorText: "Database adding error",
        });
        success = false;
      });

    return success;
  }

  render() {
    return (
      <div>
        <Header
          user_permissions={this.props.userData.data.permissions}
          user_portfolio_type={this.props.userData.data.portfolio_type}
          customstyle={this.props.userData.data.customstyle}
          organisation={this.props.userData.data.orgid}
          logout={() => Logout(this.props)}
        />
        <div className="adduserbackground">
          <h2>Create Admin User</h2>
          <div className="formcontent">
            <form name="my-form" type="button" onChange={this.handleChange}>
              <div>
                <label for="email">E-Mail Address</label>

                <input
                  type="text"
                  id="email"
                  className="form-control"
                  name="userData.email"
                />
              </div>

              <div className="form-group row">
                <label for="fullName">Full Name</label>

                <input
                  type="text"
                  id="full_name"
                  className="form-control"
                  name="full_name"
                />
              </div>
              <div className="form-group row">
                <label for="position">Position</label>

                <input
                  type="text"
                  id="position"
                  className="form-control"
                  name="position"
                />
              </div>
              <div className="form-group row">
                {/* <label for="group">Group</label> */}

                <Select
                  label="Role"
                  name="role"
                  id="role"
                  onChange={({ value }) =>
                    this.handleChange({
                      target: {
                        value: value,
                        name: "role",
                        id: "role",
                      },
                    })
                  }
                  options={ROLES_ADMIN}
                />

                {/* <select name="role" className="form-control" id="role">
                  <option disabled selected>
                    Select the role:
                  </option>
                  {this.props.userData.data.role === "Balkerne Admin" ? (
                    <option value="admin">Admin</option>
                  ) : (
                    ""
                  )}
                  <option value="Property Manager">Property Manager</option> 
                  <option value="Property Admin">Property Admin</option> 
                  
                  <option value="Risk Manager">Risk Manager</option>
                  <option value="Portfolio Manager">Portfolio Manager</option>
                  <option value="Regional Manager">Regional Manager</option>
                </select> */}
              </div>

              <div className="form-group row">
                <label for="org_id">Org ID</label>

                <input type="text" id="org_id" className="form-control" />
              </div>

              <div className="form-group row">
                <label for="phone">Phone Number</label>

                <input type="text" id="phone" className="form-control" />
              </div>

              <div className="form-group">
                <button type="button" onClick={this.handleSubmit}>
                  Register
                </button>
              </div>
            </form>
          </div>
        </div>
        <div>{this.state.errorText}</div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  const { userLogged, user, userData } = state;

  return {
    userLogged,
    user,
    userData,
  };
}
export default connect(mapStateToProps)(Register);
