import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import c from './AttachedFile.module.scss'

const AttachedFile = ({ ...props }) => {
  const [fileDisplay, setFileDisplay] = useState('flex')
  var fileSize = props.filesize / 1024
  fileSize = (fileSize / 1024).toFixed(2)
  const removeAttachedFile = e => {
    // attach code to delete file from bucket

    e.preventDefault()
    console.log(e.currentTarget.parentNode.parentNode.id)
    props.resetAttachedFiles(e.currentTarget.parentNode.parentNode.parentNode.id, props.fileKey)
    //e.currentTarget.parentNode.parentNode.removeChild(e.currentTarget.parentNode)
    e.currentTarget.parentElement.style.display = 'none'
    //ReactDOM.unmountComponentAtNode(e.currentTarget.parentNode.parentNode)
  }
  return (
    <div className={c.containerAttachedImages} id={props.fileKey}>
      <div className={c.containerSize}>
        <a href={`${props.bucketURL}${props.url}`} className={c.attachName}>
          File: '{props.filename}'
        </a>
        <p className={c.attachSize}>{fileSize} MB</p>
      </div>
      <a onClick={e => removeAttachedFile(e)} className={['close', c.close].join(' ')} title="Remove">
        <span className={c.closeSpan} aria-hidden="true">
          &times;
        </span>
      </a>
    </div>
  )
}

export default AttachedFile
