import React, { Fragment } from 'react'
import ReactDOM from 'react-dom'
import { Router, Route, Switch, Link, Redirect } from 'react-router-dom'
// import { PropTypes } from 'prop-types';
import axios from 'axios'
import MainAddUsers from '../pages/MainAddUsers'
import MainAddLocation from '../pages/MainAddLocation'
import MainEditLocation from '../pages/MainEditLocation'
import MainReassignUsers from '../pages/MainReassignUsers'
import ReassignUserProperties from '../pages/ReassignUserProperties'
import UserListPage from '../pages/UserListPage'
import QuickSightDashboard from '../pages/QuickSightDashboard'
import UserDashboard from '../pages/UserDashboard'
import UserDashboardSolo from '../pages/UserDashboardSolo'
import PortfolioLandingPage from '../pages/PortfolioLandingPage'
import riskreport from '../pages/riskreport'
import LocationListPage from '../pages/LocationListPage'
import SmartVault from '../pages/SmartVault'
import OperationsMap from '../pages/Operationsmap'
import CreateAccount from './CreateAccount'
import ResetPassword from './ResetPassword'
import ChangeAccDetails from './ChangeAccountDetails'
import MainBulkAddProperties from './MainBulkAddProperties'
import TweetTraining from './TweetTraining'
import FormSelectorPage from './FormSelectorPage'
import DynamicFormPage from './DynamicFormPage'
import AdminToolPage from './AdminToolPage'
import LoadFormPage from './LoadFormPage'
import ListIncidentsPage from './ListIncidentsPage'
import TemplateManagerPage from './TemplateManagerPage'
import ReactGA from 'react-ga'

import { connect } from 'react-redux'

import { history } from '../redux/_helpers'
import { alertActions } from '../redux/_actions'
import GetStarted from './GetStarted'
import { Logout } from '../components/Logout'
import { userActions } from '../redux/_actions'

import Login from './Login'

import Amplify from '@aws-amplify/core'
//import Config from './src/aws-exports'
import { PrivateRoute } from '../components/userManagement/PrivateRoute'

import Auth from '@aws-amplify/auth'

import './MainMenu.css'

//x

const initialState = { user: 'lol' }

//ReactGA.initialize("UA-146992643-1");

const location = history.location

// Initialize google analytics page view tracking
history.listen((location, action) => {
  console.log('history is being tracked')

  ReactGA.set({ page: location.pathname }) // Update the user's current page
  ReactGA.pageview(location.pathname) // Record a pageview for the given page
  console.log('location path name for page tracking is below')
  console.log(location.pathname)
})

// class GAListener  extends React.Component {
//   static contextTypes = {
//     router: PropTypes.object
//   };

//   componentDidMount() {
//     this.sendPageView(this.context.router.history.location);
//     this.context.router.history.listen(this.sendPageView);
//   }

//   sendPageView(location) {
//     ReactGA.set({ page: location.pathname });
//     ReactGA.pageview(location.pathname);
//   }

//   render() {
//     return this.props.children;
//   }
// }

export class MainMenu extends React.Component {
  constructor(props) {
    super(props)

    const { dispatch } = this.props
    // history.listen((location, action) => {
    //   // clear alert on location change
    //   dispatch(alertActions.clear());
    // });

    this.state = {
      isAuthenticated: false,
      user: null,
    }
  }

  // userHasAuthenticated = authenticated => {
  //   console.log("AUTHENTICATING");
  //   this.setState({ isAuthenticated: authenticated });
  // };

  // authenticate() {
  //   Auth.currentAuthenticatedUser({
  //     bypassCache: false // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
  //   })
  //     .then(user => this.setUser(user))
  //     .then(() => this.userHasAuthenticated(true))
  //     .catch(err => console.log(err));
  // }

  handleLogout = () => {
    // console.log("logging out!!!!!!!!!!!!!!");
    this.userHasAuthenticated(false)
    this.user = null
    Auth.signOut()
      .then(() => {
        console.log('Sign out complete')
      })
      .catch(err => console.log('Error while signing out!', err))
  }

  setUser = user => {
    this.state.user = user
  }

  setAuthenticated = () => {
    this.state.isAuthenticated = true
  }
  async componentDidMount() {
    // START an AXIOS Interceptor to refresh tokens
    //console.log("STARTING INTERCEPTOOOOOOR-------------------------------")
    await axios.interceptors.request.use(config => {
      //console.log("Intercetor run")
      if (
        this.props.user &&
        this.props.user.user &&
        this.props.user.user.signInUserSession.idToken.payload.exp < Date.now() / 1000
      ) {
        //1200000
        //console.log("AXIOS INTERCEPTOR")
        return new Promise((resolve, reject) => {
          Auth.currentSession()
            .then(session => {
              var idTokenExpire = session.getIdToken().getExpiration()
              var refreshToken = session.getRefreshToken()
              //  console.log(idTokenExpire)
              //  console.log(refreshToken)
              Auth.currentAuthenticatedUser()
                .then(res => {
                  res.refreshSession(refreshToken, (err, session_data) => {
                    if (err) {
                      //HANDLES FAILURE AND LOGS THE PERSON OUT
                      console.log(err)
                      console.log('Failure refreshing user session tokens.')
                      console.log('-------------------------------------------')
                      axios.defaults.headers.common['Authorization'] = ''
                      alert('Unexpected Error, Please try again.')
                      Logout()
                      resolve(config)
                    } else {
                      //HANDLES SUCCESS AND SETS THE DEFAULT AUTH HEADER AND USER REDUX STATE
                      let jwtToken = null
                      if (session_data === null) {
                        //console.log("session data passed but is NULL")
                      }

                      jwtToken = session_data.getIdToken().getJwtToken()
                      //console.log(jwtToken)

                      //config.headers.Authorization = "Bearer " + jwtToken;
                      axios.defaults.headers.common['Authorization'] = 'Bearer ' + jwtToken
                      //  console.log("Jwt Token successfully refreshed!!!")
                      //  console.log("-------------------------------------------")
                      if (jwtToken !== null) {
                        this.props.dispatch(userActions.update_jwt_token(this.props.user.user, jwtToken))
                        // console.log("submitted jwt token")
                      }
                      resolve(config)
                    }
                  })
                })
                .catch(err => {
                  //HANDLES FAILURE AND LOGS THE PERSON OUT
                  console.log(err)
                  console.log('Failure refreshing user session tokens.')
                  console.log('-------------------------------------------')
                  axios.defaults.headers.common['Authorization'] = ''
                  alert('Unexpected Error, Please try again.')
                  Logout()
                  resolve(config)
                })
            })
            .catch(() => {
              // No logged-in user: don't set auth header
              console.log('No logged in user')
              resolve(config)
            })
        })
      } else if (
        typeof axios.defaults.headers.common['Authorization'] === 'undefined' &&
        this.props.user &&
        this.props.user.user &&
        this.props.user.user.signInUserSession.idToken.payload.exp > Date.now() / 1000
      ) {
        axios.defaults.headers.common['Authorization'] =
          'Bearer ' + this.props.user.user.signInUserSession.idToken.jwtToken
        //console.log("Set the authhorization header again")
        //console.log(" NO AUTH HEADER FOUND, TIME STILL VALID")

        return new Promise((resolve, reject) => {
          config.headers.Authorization = 'Bearer ' + this.props.user.user.signInUserSession.idToken.jwtToken
          resolve(config)
        })
      } else {
        //console.log("Timeout other")
        //console.log(Date.now()/1000)
        // console.log(this.props.user.user.signInUserSession.idToken.payload.exp)
        // console.log(axios.defaults.headers.common['Authorization'])
        //console.log("Timeout other")
        return new Promise((resolve, reject) => {
          config.headers.Authorization = axios.defaults.headers.common['Authorization']
          resolve(config)
        })
      }

      return config
    })

    // this.authenticate();
  }

  render() {
    const { userLogged } = this.props

    const { userData } = this.props

    if (typeof userLogged !== undefined && userLogged !== null) {
      //    console.log("moved");
      let loggedIn = userLogged.loggedIn

      if (loggedIn === true && typeof userData.data !== undefined && userData.data) {
        let portfolio_type = userData.data.portfolio_type

        return (
          <div className="appContainer">
            <Router history={history}>
              {portfolio_type == 'Single' ? (
                <PrivateRoute
                  exact
                  path="/"
                  component={UserDashboardSolo}
                  userLogged={userLogged}
                  portfolioType={portfolio_type}
                />
              ) : (
                <PrivateRoute
                  exact
                  path="/"
                  component={PortfolioLandingPage}
                  userLogged={userLogged}
                  portfolioType={portfolio_type}
                />
              )}
              <PrivateRoute
                exact={false}
                path="/CreateAdmin"
                component={CreateAccount}
                userLogged={userLogged}
                portfolioType={portfolio_type}
              />
              <PrivateRoute
                exact={false}
                path="/UserDashboard"
                component={UserDashboard}
                userLogged={userLogged}
                portfolioType={portfolio_type}
              />
              <PrivateRoute
                exact={false}
                path="/MainBulkAddProperties"
                component={MainBulkAddProperties}
                userLogged={userLogged}
                portfolioType={portfolio_type}
              />

              <PrivateRoute
                exact={false}
                path="/SmartVault"
                component={SmartVault}
                userLogged={userLogged}
                portfolioType={portfolio_type}
              />

              <PrivateRoute
                exact={false}
                path="/Operationsmap"
                component={OperationsMap}
                userLogged={userLogged}
                portfolioType={portfolio_type}
              />
              <PrivateRoute
                exact={false}
                path="/LocationListPage"
                component={LocationListPage}
                userLogged={userLogged}
                portfolioType={portfolio_type}
              />
              <PrivateRoute
                exact={false}
                path="/riskreport"
                component={riskreport}
                userLogged={userLogged}
                portfolioType={portfolio_type}
              />

              <PrivateRoute
                exact={false}
                path="/MainAddLocation"
                component={MainAddLocation}
                userLogged={userLogged}
                portfolioType={portfolio_type}
              />
              <PrivateRoute
                exact={false}
                path="/MainEditLocation"
                component={MainEditLocation}
                userLogged={userLogged}
                portfolioType={portfolio_type}
              />
              <PrivateRoute
                exact={false}
                path="/MainReassignUsers"
                component={MainReassignUsers}
                userLogged={userLogged}
                portfolioType={portfolio_type}
              />
              <PrivateRoute
                exact={false}
                path="/ChangeAccDetails"
                component={ChangeAccDetails}
                userLogged={userLogged}
                portfolioType={portfolio_type}
              />

              <Route exact={false} path="/login" component={Login} portfolioType={portfolio_type} />
              <Route exact={false} path="/ResetPassword" component={ResetPassword} portfolioType={portfolio_type} />
              <PrivateRoute
                exact={false}
                path="/MainAddUsers"
                component={MainAddUsers}
                userLogged={userLogged}
                portfolioType={portfolio_type}
              />
              <PrivateRoute
                exact={false}
                path="/CreateAccount"
                component={CreateAccount}
                userLogged={userLogged}
                portfolioType={portfolio_type}
              />
              <PrivateRoute
                exact={false}
                path="/ReassignUserProperties"
                component={ReassignUserProperties}
                userLogged={userLogged}
                portfolioType={portfolio_type}
              />
              <PrivateRoute
                exact={false}
                path="/UserListPage"
                component={UserListPage}
                userLogged={userLogged}
                portfolioType={portfolio_type}
              />
              <PrivateRoute
                exact={false}
                path="/FormSelector"
                component={FormSelectorPage}
                userLogged={userLogged}
                portfolioType={portfolio_type}
              />
              <PrivateRoute
                exact={false}
                path="/DynamicForm"
                component={DynamicFormPage}
                render={props => <DynamicFormPage {...props} />}
                userLogged={userLogged}
                portfolioType={portfolio_type}
              />
              <PrivateRoute
                exact={false}
                path="/AdminTool"
                component={AdminToolPage}
                render={props => <AdminToolPage {...props} />}
                userLogged={userLogged}
                portfolioType={portfolio_type}
              />

              <PrivateRoute
                exact={false}
                path="/LoadForm"
                component={LoadFormPage}
                render={props => <LoadFormPage {...props} />}
                userLogged={userLogged}
                portfolioType={portfolio_type}
              />

              <PrivateRoute
                exact={false}
                path="/ListIncidents"
                component={ListIncidentsPage}
                render={props => <ListIncidentsPage {...props} />}
                userLogged={userLogged}
                portfolioType={portfolio_type}
              />

              <PrivateRoute
                exact={false}
                path="/QuickSightDashboard"
                component={QuickSightDashboard}
                userLogged={userLogged}
                portfolioType={portfolio_type}
              />
              <PrivateRoute
                exact={false}
                path="/TemplateManager"
                component={TemplateManagerPage}
                render={props => <TemplateManagerPage {...props} />}
                userLogged={userLogged}
                portfolioType={portfolio_type}
              />
            </Router>
          </div>
        )
      } else {
        return (
          <div className="appContainer">
            <Router history={history}>
              <Route exact={true} path="/" component={Login} />
              <Route exact={false} path="/login" component={Login} />
              <Route exact={false} path="/GetStarted" component={GetStarted} />

              <Route exact={false} path="/ResetPassword" component={ResetPassword} />

              <Route exact={false} path="/TweetTraining" component={TweetTraining} />
            </Router>
          </div>
        )
      }
    }

    return (
      <div className="appContainer">
        <Router history={history}>
          <Route exact={true} path="/" component={Login} />
          <Route exact={false} path="/login" component={Login} />
          <Route exact={false} path="/GetStarted" component={GetStarted} />
          <Route exact={false} path="/TweetTraining" component={TweetTraining} />
          <Route exact={false} path="/ResetPassword" component={ResetPassword} />
        </Router>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { userLogged, user, userData, systemData, mapAlertData, mapLocationData, dashboardData } = state

  return {
    userLogged,
    user,
    userData,
    systemData,
    mapAlertData,
    mapLocationData,
    dashboardData,
  }
}

//const connectedApp = connect(mapStateToProps)(MainMenu);
export default connect(mapStateToProps)(MainMenu)
