import React from "react";
import PropertiesInputs from "./PropertiesInputs";
import Axios from "axios";
import AssignUsers from "./AssignUsers";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Redirect } from "react-router";

import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";
import uniqueId from "lodash.uniqueid";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import c from "../../pages/GetStarted.module.scss";
import CheckBox from "balkerne-components/CheckBox";

import "./PropertiesGetStarted.css";
//
export class PropertiesGetStarted extends React.Component {
  constructor(props) {
    super(props);
    //setting initial user row
    this.state = {
      agreedTerms: false,
      properties: [
        {
          id: uniqueId("property-line-"),
          postcode: "",
          address: null,
          lat: "",
          lon: "",
          country: "England",
          processed: false,
          building_no: "",
        },
      ],
    };
    //this.processAddressClick = this.processAddressClick.bind(this);
    this.updatePropAddress = this.updatePropAddress.bind(this);
    this.handleSubmit1 = this.handleSubmit1.bind(this);
    this.setbuildingBox = this.setbuildingBox.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
  }

  //adds another empty user row
  addProperty = (e) => {
    this.setState((prevState) => ({
      properties: [
        ...prevState.properties,
        {
          id: uniqueId("property-line-"),
          postcode: "",
          address: null,
          name: "",
          country: "England",
          lat: "",
          lon: "",
          processed: false,
          building_no: "",
          agreedTerms: false,
        },
      ],
    }));
  };

  setbuildingBox(bool, index) {
    let properties = [...this.state.properties];
    console.log(properties);
    console.log(index);
    console.log(properties[index]);
    console.log(properties[index]["building_no_bool"]);
    properties[index]["building_no_bool"] = bool;

    this.setState({ properties });
  }

  removeProperty = (e) => {
    let properties = this.state.properties;
    properties.pop();
    this.setState({ properties });
    this.props.handleChangeProperties("properties", this.state.properties);
  };
  handleCheck(checked) {
    this.setState({ agreedTerms: checked });
    console.log(checked);
  }
  removePropertyIndex = (index) => {
    let properties = this.state.properties;
    console.log(index);
    properties.splice(index, 1);
    this.setState({ properties });
    this.props.handleChangeProperties("properties", this.state.properties);
  };

  async updatePropAddress(address, index) {
    console.log("updating prop address");

    let properties = this.state.properties;
    let success = true;
    let errormessages = [];
    console.log(address);
    console.log(index);

    try {
      if (address.postcode) {
        properties[index].postcode = address.postcode;
      }
      if (address.number) {
        properties[index].building_no = address.number;
        properties[index].address_street =
          address.number + " " + address.street;
      } else {
        properties[index].address_street = address.street;
      }

      if (address.posttown) {
        properties[index].address_town = address.posttown;
      }
      if (address.county) {
        properties[index].address_county = address.county;
      }
      if (address.longitude) {
        properties[index].lon = address.longitude;
      }
      if (address.latitude) {
        properties[index].lat = address.latitude;
      }
      if (address.uprn) {
        properties[index].land_reg_id = address.uprn;
      }
      if (address.lsoa11) {
        properties[index].lsoa = address.lsoa11;
      }
      if (address.msoa11) {
        properties[index].msoa = address.msoa11;
      }

      // if(address.addressline3){
      //   properties[index].address = address.addressline1+", "+address.addressline2+", "+address.addressline3
      // }
      // else if(address.addressline2){
      //   properties[index].address = address.addressline1+", "+address.addressline2
      // }
      // else if(address.addressline1){
      //   properties[index].address = address.addressline1
      // }
      // else{
      properties[index].address = address.summaryline;
      //}

      //properties[index].address = address.ADDRESS
      properties[index].processed = true;
    } catch (error) {
      // else{
      //   errormessages.push("There was an error locating "+ address2)
      //   console.log(errormessages)

      //   this.props.updateErrorText(errormessages)
      // }
      //}

      success = false;
      console.log(error);
      if (
        !("There was an error locating address no. " + (parseInt(index) + 1)) in
        errormessages
      ) {
        errormessages.push(
          "There was an error locating address no. " + (parseInt(index) + 1)
        );
        console.log(errormessages);
        this.props.updateErrorText(errormessages);
      }
    }
    this.setState({ properties });
    return success;
  }

  async handleSubmit1() {
    let success = true;
    let errormessages = [];
    console.log("processing");
    this.props.updateErrorText(null);
    for (let property1 in this.state.properties) {
      if (
        this.state.agreedTerms == false &&
        this.props.source != "bulkpropertiesadd"
      ) {
        success = false;

        errormessages.push(
          "Please agree terms and conditions before completion"
        );
      }

      let property = this.state.properties[property1];
      console.log(property.processed);
      if (property.processed === false) {
        success = false;

        errormessages.push(
          "There was an error with property no. " + (parseInt(property1) + 1)
        );
      }
      // console.log("------")
      // console.log(property.search_string)
      // console.log(property.processed)
      // console.log(property.address)
      // console.log(property)
      // console.log("--------")
    }
    if (success === true) {
      console.log("all processed");
      this.props.handleSubmit();
    } else {
      this.props.updateErrorText(errormessages);
    }
  }

  //loads updated values into the users array
  updateFields = (properties) => {
    this.setState(this.state.properties, this.props.usepropertiesrs);
  };

  //handles changes made to the user, updates the users array state
  handleChange = (e, index) => {
    // console.log(index)
    // console.log(e.target.value)
    // console.log(e.target.className)

    if (e.target.className !== "address-lookup-input") {
      if (index || index === 0) {
        // if (
        //   ["name","postcode", "address", "lat", "lon", "country"].includes(
        //     e.target.className
        //   )
        // ) {

        //   let properties = [...this.state.properties];
        //   properties[e.target.id][e.target.className] = e.target.value;

        //   this.setState({ properties });
        // }

        if (e.target.className.includes("property-name")) {
          //console.log("found name")

          let properties = [...this.state.properties];
          properties[index]["name"] = e.target.value;

          this.setState({ properties });
        } else if (e.target.className.includes("property-country")) {
          //console.log("found country")

          let properties = [...this.state.properties];
          properties[index]["country"] = e.target.value;

          this.setState({ properties });
        }

        // if (
        //   "location-search-input"=== e.target.className || "building_no" ===e.target.className
        //   )
        //  {

        //   let properties = [...this.state.properties];
        //   properties[e.target.id][e.target.className] = e.target.value;
        //   properties[e.target.id]["processed"] = false;
        //   this.setState({ properties });
        // }

        // else {
        //   this.setState({ [e.target.name]: e.target.value });
        // }
        this.props.handleChangeProperties("properties", this.state.properties);
      }
    }
  };

  //conditionally render based on state, add the userInputs component for row of fields and set functions
  render() {
    if (this.props.currentStep !== 3) {
      return null;
    }
    return (
      <Card>
        <Card.Header>Add Properties: </Card.Header>
        <Card.Body>
          <form onChange={this.handleChange}>
            <PropertiesInputs
              properties={this.state.properties}
              handleChange={this.handleChange}
              updatePropAddress={this.updatePropAddress}
              removePropertyIndex={this.removePropertyIndex}
            />
          </form>
          <div className="adduserrows">
            <div className="col-md-6 offset-md-4">
              {this.state.properties &&
              this.state.properties.length > 0 &&
              this.props.portfolio_type === "Single" ? (
                ""
              ) : (
                <button
                  onClick={this.addProperty}
                  type="button"
                  className="addpropertybutton"
                >
                  <span className="glyphicon glyphicon-plus-sign" /> Add
                  Property ▼
                </button>
              )}

              {this.state.properties &&
              this.state.properties.length > 0 &&
              this.props.portfolio_type === "Single" ? (
                ""
              ) : (
                <button
                  onClick={this.removeProperty}
                  type="button"
                  className="removepropertybutton"
                >
                  <span className="glyphicon glyphicon-minus-sign" /> Remove
                  Property ▲
                </button>
              )}
            </div>
          </div>
          {this.props.source == "bulkpropertiesadd" ? (
            ""
          ) : (
            <div className="pt-3" id="wrapper">
              <CheckBox
                size="large"
                id="termscheckbox"
                name="hasAgreedTcsCs"
                type="checkbox"
                value={this.state.agreedTerms}
                onChange={(event) => this.handleCheck(event)}
              >
                <a
                  href="https://www.balkerne.com/privacypolicy/ "
                  target="_blank"
                >
                  I agree to the Terms and Privacy Policy
                </a>
              </CheckBox>
            </div>
          )}

          <button
            id="getstartedsubmit"
            onClick={() => this.handleSubmit1()}
            type="button"
            className="btn btn-primary btn-lg float-right"
          >
            Complete
          </button>
        </Card.Body>
      </Card>
    );
  }
}
export default PropertiesGetStarted;
