import React from "react";
import { OptionCategory, Option } from "./Options";
import { Sidebar } from "./Sidebar";
// import { Dashboard } from "./Dashboard";
import { GoogleMap } from "./GoogleMap";
import * as styles from "./styles";
import * as gmaps from "./gmaps";

import axios from "axios";
import * as urls from "./urls";
import * as topojson from "topojson-client";

import "./App.css";
import { connect } from "react-redux";
import { activeFilters, activeLayers } from "./config";
import ProgressBar from "react-bootstrap/ProgressBar";
import { systemReduxMapHandler } from "../../redux/_helpers/systemReduxMapHandler.js";

//Map component that uses real alerts and filtering from portfolio dashboard

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      map: null,
      infoWindow: null,
      dashboardVisible: false,
      dashboardFeature: null,
      layers: activeLayers,
      filters: activeFilters,
      alertFilterSeverity: 0,
      locationRiskSeverity: 0,
      locationType: null,
      severeWarningLocations: null,
      warningLocations: null,
      alertLocations: null,
      locations: null,
      showingBubblesState: false,
      showingRiskState: false,
      riskLocationsUpdated: false,
      mapLocationDataListLength: 0,
      mapLoadingstate: 0,
      mounted: false,
    };
    this.onMainMapLoad = this.onMainMapLoad.bind(this);
    this.onLayerChange = this.onLayerChange.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.showLayer = this.showLayer.bind(this);
    this.showFilter = this.showFilter.bind(this);
    this.hideFilter = this.hideFilter.bind(this);
    this.openDashboard = this.openDashboard.bind(this);
    this.loadRiskLocations = this.loadRiskLocations.bind(this);
  }

  //first function that is run when this component is rendered, sets the parent interaction function to set bubble filters
  //and invokes the said function, applying filters given selected alert bubble in the props
  //the activation of this can be found here: https://github.com/kriasoft/react-starter-kit/issues/909

  componentload() {
    this.props.setAlertFilter(this.setBubbleFilters.bind(this));
    this.props.setLocationFilter(this.setBubbleFilters.bind(this));
    this.setBubbleFilters();
    this.setLocationFilters();
  }

  setLocationFilters = () => {
    if (
      this.props.showingRisk === true &&
      this.state.showingRiskState == false
    ) {
      this.hideLayer(this.state.map, this.state.layers["floodAlerts"]);
      // this.state.locationRiskSeverity = 0;
      this.state.alertFilterSeverity = 0;
    }

    if (
      this.state.locationType !== "rofrs" &&
      this.props.selectedPropertyType === "rofrs"
    ) {
      this.state.locationType = "rofrs";

      if (
        this.props.selectedRiskSeverity === 4 &&
        this.state.selectedPropertyType !== "rofrs"
      ) {
        this.state.showingRiskState = true;
        this.state.showingBubblesState = false;
        this.state.locationRiskSeverity = 4;

        //console.log("doing rofrs 4");

        this.setLocationLayers("properties_rofrs_high");
      }
      if (
        this.props.selectedRiskSeverity === 3 &&
        this.state.selectedPropertyType !== "rofrs"
      ) {
        this.state.locationRiskSeverity = 3;
        //console.log("doing rofrs 3");
        this.state.showingRiskState = true;
        this.state.showingBubblesState = false;
        this.setLocationLayers("properties_rofrs_medium");
      }
      if (
        this.props.selectedRiskSeverity === 1 &&
        this.state.selectedPropertyType !== "rofrs"
      ) {
        this.state.locationRiskSeverity = 1;
        //console.log("doing rofrs 1");
        this.state.showingRiskState = true;
        this.state.showingBubblesState = false;
        this.setLocationLayers("properties_rofrs_low");
      }
    } else if (
      this.state.locationType !== "rofsw" &&
      this.props.selectedPropertyType === "rofsw"
    ) {
      this.state.locationType = "rofsw";

      if (
        this.props.selectedRiskSeverity === 4 &&
        this.state.selectedPropertyType !== "rofsw"
      ) {
        this.state.locationRiskSeverity = 4;

        //console.log("doing rofsw 4");
        this.state.showingRiskState = true;
        this.state.showingBubblesState = false;
        this.setLocationLayers("properties_rofsw_high");
      }
      if (
        this.props.selectedRiskSeverity === 3 &&
        this.state.selectedPropertyType !== "rofsw"
      ) {
        this.state.locationRiskSeverity = 3;

        //console.log("doing rofsw 3");
        this.state.showingRiskState = true;
        this.state.showingBubblesState = false;
        this.setLocationLayers("properties_rofsw_medium");
      }
      if (
        this.props.selectedRiskSeverity === 1 &&
        this.state.selectedPropertyType !== "rofsw"
      ) {
        this.state.locationRiskSeverity = 1;
        this.state.showingRiskState = true;
        this.state.showingBubblesState = false;
        //console.log("doing rofsw 1");
        this.setLocationLayers("properties_rofsw_low");
      }
    } else if (
      this.state.locationType !== "crime" &&
      this.props.selectedPropertyType === "crime"
    ) {
      this.state.locationType = "crime";

      if (
        this.props.selectedRiskSeverity === 4 &&
        this.state.selectedPropertyType !== "crime"
      ) {
        this.state.locationRiskSeverity = 4;

        //console.log("doing crime 4");
        this.state.showingRiskState = true;
        this.state.showingBubblesState = false;
        this.setLocationLayers("properties_crime_high");
      }
      if (
        this.props.selectedRiskSeverity === 3 &&
        this.state.selectedPropertyType !== "crime"
      ) {
        this.state.locationRiskSeverity = 3;

        //console.log("doing crime 3");
        this.state.showingRisk = true;
        this.setLocationLayers("properties_crime_medium");
      }
      if (
        this.props.selectedRiskSeverity === 1 &&
        this.state.selectedPropertyType !== "crime"
      ) {
        this.state.locationRiskSeverity = 1;
        this.state.showingRiskState = true;
        this.state.showingBubblesState = false;
        //console.log("doing crime 1");
        this.setLocationLayers("properties_crime_low");
      }
    } else if (
      this.state.locationType !== "fire" &&
      this.props.selectedPropertyType === "fire"
    ) {
      this.state.locationType = "fire";

      if (
        this.props.selectedRiskSeverity === 4 &&
        this.state.selectedPropertyType !== "fire"
      ) {
        this.state.locationRiskSeverity = 4;

        //console.log("doing fire 4");
        this.state.showingRiskState = true;
        this.state.showingBubblesState = false;
        this.setLocationLayers("properties_fire_high");
      }
      if (
        this.props.selectedRiskSeverity === 3 &&
        this.state.selectedPropertyType !== "fire"
      ) {
        this.state.locationRiskSeverity = 3;

        //console.log("doing fire 3");
        this.state.showingRiskState = true;
        this.state.showingBubblesState = false;
        this.setLocationLayers("properties_fire_medium");
      }
      if (
        this.props.selectedRiskSeverity === 1 &&
        this.state.selectedPropertyType !== "fire"
      ) {
        this.state.locationRiskSeverity = 1;
        this.state.showingRiskState = true;
        this.state.showingBubblesState = false;
        //console.log("doing fire 1");
        this.setLocationLayers("properties_fire_low");
      }
    } else if (
      this.state.locationType !== "food_hygiene" &&
      this.props.selectedPropertyType === "food_hygiene"
    ) {
      this.state.locationType = "food_hygiene";

      if (
        this.props.selectedRiskSeverity === 4 &&
        this.state.selectedPropertyType !== "food_hygiene"
      ) {
        this.state.locationRiskSeverity = 4;

        //console.log("doing food_hygiene 4");
        this.state.showingRiskState = true;
        this.state.showingBubblesState = false;
        this.setLocationLayers("properties_food_hygiene_high");
      }
      if (
        this.props.selectedRiskSeverity === 3 &&
        this.state.selectedPropertyType !== "food_hygiene"
      ) {
        this.state.locationRiskSeverity = 3;

        //console.log("doing food_hygiene 3");
        this.state.showingRisk = true;
        this.setLocationLayers("properties_food_hygiene_medium");
      }
      if (
        this.props.selectedRiskSeverity === 1 &&
        this.state.selectedPropertyType !== "food_hygiene"
      ) {
        this.state.locationRiskSeverity = 1;
        this.state.showingRiskState = true;
        this.state.showingBubblesState = false;
        //console.log("doing food_hygiene 1");
        this.setLocationLayers("properties_food_hygiene_low");
      }
    } else if (
      this.state.locationType !== "pandemic" &&
      this.props.selectedPropertyType === "pandemic"
    ) {
      this.state.locationType = "pandemic";

      if (
        this.props.selectedRiskSeverity === 4 &&
        this.state.selectedPropertyType !== "pandemic"
      ) {
        this.state.locationRiskSeverity = 4;

        //console.log("doing pandemic 4");
        this.state.showingRiskState = true;
        this.state.showingBubblesState = false;
        this.setLocationLayers("properties_pandemic_high");
      }
      if (
        this.props.selectedRiskSeverity === 3 &&
        this.state.selectedPropertyType !== "pandemic"
      ) {
        this.state.locationRiskSeverity = 3;

        //console.log("doing pandemic 3");
        this.state.showingRisk = true;
        this.setLocationLayers("properties_pandemic_medium");
      }
      if (
        this.props.selectedRiskSeverity === 1 &&
        this.state.selectedPropertyType !== "pandemic"
      ) {
        this.state.locationRiskSeverity = 1;
        this.state.showingRiskState = true;
        this.state.showingBubblesState = false;
        //console.log("doing pandemic 1");
        this.setLocationLayers("properties_pandemic_low");
      }
    }

    if (
      this.state.locationType === "rofrs" &&
      this.state.locationRiskSeverity !== 4 &&
      this.props.selectedRiskSeverity === 4
    ) {
      this.state.locationRiskSeverity = 4;

      //console.log("doing rofrs 4");
      this.state.showingRiskState = true;
      this.state.showingBubblesState = false;
      this.setLocationLayers("properties_rofrs_high");
    }
    if (
      this.state.locationType === "rofrs" &&
      this.state.locationRiskSeverity !== 3 &&
      this.props.selectedRiskSeverity === 3
    ) {
      this.state.locationRiskSeverity = 3;
      // console.log("doing rofrs 3");
      this.state.showingRiskState = true;
      this.state.showingBubblesState = false;
      this.setLocationLayers("properties_rofrs_medium");
    }
    if (
      this.state.locationType === "rofrs" &&
      this.state.locationRiskSeverity !== 1 &&
      this.props.selectedRiskSeverity === 1
    ) {
      this.state.locationRiskSeverity = 1;
      // console.log("doing rofrs 1");
      this.state.showingRiskState = true;
      this.state.showingBubblesState = false;
      this.setLocationLayers("properties_rofrs_low");
    }

    if (
      this.state.locationType === "rofsw" &&
      this.state.locationRiskSeverity !== 4 &&
      this.props.selectedRiskSeverity === 4
    ) {
      this.state.locationRiskSeverity = 4;

      //console.log("doing rofsw 4");
      this.state.showingRiskState = true;
      this.state.showingBubblesState = false;
      this.setLocationLayers("properties_rofsw_high");
    }
    if (
      this.state.locationType === "rofsw" &&
      this.state.locationRiskSeverity !== 3 &&
      this.props.selectedRiskSeverity === 3
    ) {
      this.state.locationRiskSeverity = 3;

      //console.log("doing rofsw 3");
      this.state.showingRiskState = true;
      this.state.showingBubblesState = false;
      this.setLocationLayers("properties_rofsw_medium");
    }
    if (
      this.state.locationType === "rofsw" &&
      this.state.locationRiskSeverity !== 1 &&
      this.props.selectedRiskSeverity === 1
    ) {
      this.state.locationRiskSeverity = 1;
      this.state.showingRiskState = true;
      this.state.showingBubblesState = false;
      // console.log("doing rofsw 1");
      this.setLocationLayers("properties_rofsw_low");
    }

    if (
      this.state.locationType === "crime" &&
      this.state.locationRiskSeverity !== 4 &&
      this.props.selectedRiskSeverity === 4
    ) {
      this.state.locationRiskSeverity = 4;

      //console.log("doing crime 4");
      this.state.showingRiskState = true;
      this.state.showingBubblesState = false;
      this.setLocationLayers("properties_crime_high");
    }
    if (
      this.state.locationType === "crime" &&
      this.state.locationRiskSeverity !== 3 &&
      this.props.selectedRiskSeverity === 3
    ) {
      this.state.locationRiskSeverity = 3;
      // console.log("doing crime 3");
      this.state.showingRiskState = true;
      this.state.showingBubblesState = false;
      this.setLocationLayers("properties_crime_medium");
    }
    if (
      this.state.locationType === "crime" &&
      this.state.locationRiskSeverity !== 1 &&
      this.props.selectedRiskSeverity === 1
    ) {
      this.state.locationRiskSeverity = 1;
      // console.log("doing crime 1");
      this.state.showingRiskState = true;
      this.state.showingBubblesState = false;
      this.setLocationLayers("properties_crime_low");
    }

    if (
      this.state.locationType === "fire" &&
      this.state.locationRiskSeverity !== 4 &&
      this.props.selectedRiskSeverity === 4
    ) {
      this.state.locationRiskSeverity = 4;

      //console.log("doing fire 4");
      this.state.showingRiskState = true;
      this.state.showingBubblesState = false;
      this.setLocationLayers("properties_fire_high");
    }
    if (
      this.state.locationType === "fire" &&
      this.state.locationRiskSeverity !== 3 &&
      this.props.selectedRiskSeverity === 3
    ) {
      this.state.locationRiskSeverity = 3;
      // console.log("doing fire 3");
      this.state.showingRiskState = true;
      this.state.showingBubblesState = false;
      this.setLocationLayers("properties_fire_medium");
    }
    if (
      this.state.locationType === "fire" &&
      this.state.locationRiskSeverity !== 1 &&
      this.props.selectedRiskSeverity === 1
    ) {
      this.state.locationRiskSeverity = 1;
      // console.log("doing fire 1");
      this.state.showingRiskState = true;
      this.state.showingBubblesState = false;
      this.setLocationLayers("properties_fire_low");
    }

    if (
      this.state.locationType === "food_hygiene" &&
      this.state.locationRiskSeverity !== 4 &&
      this.props.selectedRiskSeverity === 4
    ) {
      this.state.locationRiskSeverity = 4;

      //console.log("doing food_hygiene 4");
      this.state.showingRiskState = true;
      this.state.showingBubblesState = false;
      this.setLocationLayers("properties_food_hygiene_high");
    }
    if (
      this.state.locationType === "food_hygiene" &&
      this.state.locationRiskSeverity !== 3 &&
      this.props.selectedRiskSeverity === 3
    ) {
      this.state.locationRiskSeverity = 3;
      // console.log("doing food_hygiene 3");
      this.state.showingRiskState = true;
      this.state.showingBubblesState = false;
      this.setLocationLayers("properties_food_hygiene_medium");
    }
    if (
      this.state.locationType === "food_hygiene" &&
      this.state.locationRiskSeverity !== 1 &&
      this.props.selectedRiskSeverity === 1
    ) {
      this.state.locationRiskSeverity = 1;
      // console.log("doing fifood_hygienere 1");
      this.state.showingRiskState = true;
      this.state.showingBubblesState = false;
      this.setLocationLayers("properties_food_hygiene_low");
    }

    if (
      this.state.locationType === "pandemic" &&
      this.state.locationRiskSeverity !== 4 &&
      this.props.selectedRiskSeverity === 4
    ) {
      this.state.locationRiskSeverity = 4;

      //console.log("doing pandemic 4");
      this.state.showingRiskState = true;
      this.state.showingBubblesState = false;
      this.setLocationLayers("properties_pandemic_high");
    }
    if (
      this.state.locationType === "pandemic" &&
      this.state.locationRiskSeverity !== 3 &&
      this.props.selectedRiskSeverity === 3
    ) {
      this.state.locationRiskSeverity = 3;
      // console.log("doing pandemic 3");
      this.state.showingRiskState = true;
      this.state.showingBubblesState = false;
      this.setLocationLayers("properties_pandemic_medium");
    }
    if (
      this.state.locationType === "pandemic" &&
      this.state.locationRiskSeverity !== 1 &&
      this.props.selectedRiskSeverity === 1
    ) {
      this.state.locationRiskSeverity = 1;
      // console.log("doing pandemic 1");
      this.state.showingRiskState = true;
      this.state.showingBubblesState = false;
      this.setLocationLayers("properties_pandemic_low");
    }
  };

  setLocationLayers = (layerstring) => {
    let layers = this.state.layers;

    for (let layer in layers) {
      if (layer === layerstring) {
        layer = activeLayers[layer];
        console.log("showing " + layer.name);
        layer.visible = true;

        this.showLayer(this.state.map, layer);
      } else {
        layer = activeLayers[layer];
        if (layer.toggleable === false) {
          layer.visible = false;

          this.hideLayer(this.state.map, layer);
        }
      }
    }
  };

  //Sets the filters of the map alerts to match the selected severity in the props

  setBubbleFilters = () => {
    // console.log(
    //   "setting filters + " +
    //     this.state.alertFilterSeverity +
    //     " + " +
    //     this.props.selectedAlertSeverity
    // );
    if (this.props.showingBubbles && this.state.showingBubblesState === false) {
      this.state.locationRiskSeverity = 0;
      let layers = this.state.layers;

      for (let layer in layers) {
        layer = activeLayers[layer];
        if (layer.toggleable === false) {
          layer.visible = false;

          this.hideLayer(this.state.map, layer);
        }
      }
    }

    if (
      this.state.alertFilterSeverity !== 1 &&
      this.props.selectedAlertSeverity === 1
    ) {
      this.state.showingBubblesState = true;
      this.state.showingRiskState = false;
      //this.state.locationRiskSeverity = 0
      this.state.alertFilterSeverity = 1;
      //console.log("filters severity 1");
      let filterId = 1;
      let layers = this.state.layers;
      let filters = this.state.filters;
      this.setLocationLayers("alert_properties_severe");
      this.showLayer(this.state.map, this.state.layers["floodAlerts"]);
      this.showLayer(this.state.map, this.state.layers["floodAlertsPolygons"]);
      for (let filter1 in filters) {
        let filter = filters[filter1];

        if (filter.parentLayer === "Flood Alerts") {
          if (filter.args[0] === filterId) {
            filter.active = true;
            // console.log("showing filter");
            // console.log(filter);
            this.showFilter(filter, layers);
          } else if (filter.active === true) {
            //  console.log("hiding filter");
            //  console.log(filter);
            filter.active = false;
            this.hideFilter(filter, layers);
          }
        }
      }

      // this.setState({filters})
    }
    if (
      this.state.alertFilterSeverity !== 2 &&
      this.props.selectedAlertSeverity === 2
    ) {
      this.state.showingBubblesState = true;
      this.state.showingRiskState = false;
      //this.state.locationRiskSeverity = 0
      // console.log("filters severity 2");

      this.state.alertFilterSeverity = 2;
      let filterId = 2;
      let layers = this.state.layers;
      let filters = this.state.filters;
      // console.log(filters);
      this.setLocationLayers("alert_properties_warning");
      this.showLayer(this.state.map, this.state.layers["floodAlerts"]);
      this.showLayer(this.state.map, this.state.layers["floodAlertsPolygons"]);
      for (let filter1 in filters) {
        let filter = filters[filter1];

        if (filter.parentLayer === "Flood Alerts") {
          if (filter.args[0] === filterId) {
            filter.active = true;
            // console.log("showing filter");
            //  console.log(filter);
            this.showFilter(filter, layers);
          } else if (filter.active === true) {
            //   console.log("hiding filter");
            //   console.log(filter);
            filter.active = false;
            this.hideFilter(filter, layers);
          }
        }
      }

      // this.setState({filters})
    }
    if (
      this.state.alertFilterSeverity !== 3 &&
      this.props.selectedAlertSeverity === 3
    ) {
      this.state.showingBubblesState = true;
      this.state.showingRiskState = false;
      //this.state.locationRiskSeverity = 0
      //  console.log("filters severity 3");
      this.state.alertFilterSeverity = 3;
      let filterId = 3;
      let layers = this.state.layers;
      let filters = this.state.filters;
      this.setLocationLayers("alert_properties_alert");
      this.showLayer(this.state.map, this.state.layers["floodAlerts"]);
      this.showLayer(this.state.map, this.state.layers["floodAlertsPolygons"]);
      for (let filter1 in filters) {
        let filter = filters[filter1];

        if (filter.parentLayer === "Flood Alerts") {
          if (filter.args[0] === filterId) {
            filter.active = true;
            //   console.log("showing filter");
            //   console.log(filter);
            this.showFilter(filter, layers);
          } else if (filter.active === true) {
            //  console.log("hiding filter");
            //  console.log(filter);
            filter.active = false;
            this.hideFilter(filter, layers);
          }
        }
      }

      // this.setState({filters})
    }
  };

  async openDashboard(feature) {
    let location = await this.getLocationData(
      feature.getProperty("id"),
      this.props.userData.data.orgid,
      this.props.user.user.signInUserSession.idToken.jwtToken
    );

    // console.log(location);

    if (location !== null) {
      this.props.redirect(location, null);
    }
  }

  async getLocationData(id) {
    let answer;
    // let checked = false;

    let params = { propid: id, org_id: this.props.userData.data.orgid };

    await axios({
      method: "get",
      url: urls.API_GATEWAY + "locations/property-specific",
      params: params,
    })
      .then((res) => {
        let locationData = res.data;
        console.log(locationData);
        if (
          locationData === "null" ||
          locationData === null ||
          typeof locationData === "undefined"
        ) {
          console.log("Error retrieving location");
          answer = null;
        } else if (locationData === "Invalid Details") {
          console.log("Details for Location retrieved are INVALID");
          answer = null;
        } else if (locationData === "Property not found") {
          console.log("No Location was Found");
          answer = null;
          alert("No Location Found");
        } else {
          answer = locationData;
        }
      })
      .catch((err) => {
        answer = null;
        console.log("FAILURE getting property!!");
        console.log(err);
      });

    // OR MAYBE DISPLAY FOR CATCH??

    // answer = null;
    // console.log("FAILURE!!");
    // console.log(err);
    // if (!err.message) {
    //   console.log("Error when loading locations: ", err);
    //   //  Alert.alert('Error when signing in: ', err);
    // } else {
    //   console.log("Error when loading locations: ", err.message);
    //   //  Alert.alert('Error when signing in: ', err.message);
    //   this.setState({success:false, errMessage:"Cannot reach Server"})
    // }

    return answer;
  }

  closeDashboard(feature) {
    console.log("DASHBOAAARDCLOSE");
  }

  async loadRiskLocations(mapLocationDataList) {
    let rofrs = { low: [], medium: [], high: [] };
    let rofsw = { low: [], medium: [], high: [] };
    let crime = { low: [], medium: [], high: [] };
    let fire = { low: [], medium: [], high: [] };
    let food_hygiene = { low: [], medium: [], high: [] };
    let pandemic = { low: [], medium: [], high: [] };
    let locations = [];

    //console.log(mapLocationDataList)
    //console.log(mapLocationDataList===true)

    if (mapLocationDataList && mapLocationDataList.length > 0) {
      locations = mapLocationDataList;
      this.state.mapLocationDataListLength = mapLocationDataList.length;

      let rofrs_high = {
        type: "FeatureCollection",
        features: [],
      };
      let rofrs_medium = {
        type: "FeatureCollection",
        features: [],
      };
      let rofrs_low = {
        type: "FeatureCollection",
        features: [],
      };

      let rofsw_high = {
        type: "FeatureCollection",
        features: [],
      };
      let rofsw_medium = {
        type: "FeatureCollection",
        features: [],
      };
      let rofsw_low = {
        type: "FeatureCollection",
        features: [],
      };

      let crime_high = {
        type: "FeatureCollection",
        features: [],
      };
      let crime_medium = {
        type: "FeatureCollection",
        features: [],
      };
      let crime_low = {
        type: "FeatureCollection",
        features: [],
      };

      let fire_high = {
        type: "FeatureCollection",
        features: [],
      };
      let fire_medium = {
        type: "FeatureCollection",
        features: [],
      };
      let fire_low = {
        type: "FeatureCollection",
        features: [],
      };

      let food_hygiene_high = {
        type: "FeatureCollection",
        features: [],
      };
      let food_hygiene_medium = {
        type: "FeatureCollection",
        features: [],
      };
      let food_hygiene_low = {
        type: "FeatureCollection",
        features: [],
      };

      let pandemic_high = {
        type: "FeatureCollection",
        features: [],
      };
      let pandemic_medium = {
        type: "FeatureCollection",
        features: [],
      };
      let pandemic_low = {
        type: "FeatureCollection",
        features: [],
      };

      rofrs = { low: rofrs_low, medium: rofrs_medium, high: rofrs_high };
      rofsw = { low: rofsw_low, medium: rofsw_medium, high: rofsw_high };
      crime = { low: crime_low, medium: crime_medium, high: crime_high };
      fire = { low: fire_low, medium: fire_medium, high: fire_high };
      food_hygiene = {
        low: food_hygiene_low,
        medium: food_hygiene_medium,
        high: food_hygiene_high,
      };
      pandemic = {
        low: pandemic_low,
        medium: pandemic_medium,
        high: pandemic_high,
      };

      //  let warningLocList=[]
      //  let severeWarningLocList=[]

      for (let location1 in locations) {
        let location = locations[location1];
        //console.log(location)
        if (location.properties !== typeof "undefined" && location.properties) {
          if (location.properties.rofrs_severity === 2) {
            rofrs_low["features"].push(location);
          } else if (location.properties.rofrs_severity === 3) {
            rofrs_medium["features"].push(location);
          } else if (location.properties.rofrs_severity === 4) {
            rofrs_high["features"].push(location);
          }

          if (location.properties.rofsw_severity === 2) {
            rofsw_low["features"].push(location);
          } else if (location.properties.rofsw_severity === 3) {
            rofsw_medium["features"].push(location);
          } else if (location.properties.rofsw_severity === 4) {
            rofsw_high["features"].push(location);
          }

          if (location.properties.crime_severity === 2) {
            crime_low["features"].push(location);
          } else if (location.properties.crime_severity === 3) {
            crime_medium["features"].push(location);
          } else if (location.properties.crime_severity === 4) {
            crime_high["features"].push(location);
          }

          if (location.properties.fire_severity === 2) {
            fire_low["features"].push(location);
          } else if (location.properties.fire_severity === 3) {
            fire_medium["features"].push(location);
          } else if (location.properties.fire_severity === 4) {
            fire_high["features"].push(location);
          }

          //FOOD HYGIENE

          if (location.properties.food_hygiene_severity === 2) {
            food_hygiene_low["features"].push(location);
          } else if (location.properties.food_hygiene_severity === 3) {
            food_hygiene_medium["features"].push(location);
          } else if (location.properties.food_hygiene_severity === 4) {
            console.log(location.properties.food_hygiene_severity);
            food_hygiene_high["features"].push(location);
          }

          //PANDEMIC

          if (location.properties.pandemic_severity === 2) {
            pandemic_low["features"].push(location);
          } else if (location.properties.pandemic_severity === 3) {
            pandemic_medium["features"].push(location);
          } else if (location.properties.pandemic_severity === 4) {
            pandemic_high["features"].push(location);
          }
        }
      }
    }

    for (let layerName in this.state.layers) {
      let layer = this.state.layers[layerName];
      switch (layer.type) {
        case "property_risk":
          if (
            layer.name === "properties_rofrs_low" &&
            locations &&
            locations.length &&
            rofrs &&
            rofrs["low"]
          ) {
            if (rofrs["low"].features && rofrs["low"].features.length) {
              let rofrs_low_properties = rofrs["low"];

              let dataLayer = new window.google.maps.Data();
              // console.log(rofrs_low_properties)
              if (rofrs_low_properties.features) {
                dataLayer.addGeoJson(rofrs_low_properties);
              }
              if (dataLayer) {
                if (layer.styleRofrs) {
                  dataLayer.setStyle(layer.styleRofrs);
                } else {
                  dataLayer.setStyle(layer.style);
                }

                if (layer.onClick) {
                  dataLayer.addListener("click", layer.onClick.bind(this));
                }
                this.setState((state) => {
                  layer.layer = dataLayer;

                  //Loading 20%

                  if (layer.visible) {
                    this.showLayer(state.map, layer);
                  }

                  //return layers;
                });
              }
            }
          } else if (
            layer.name === "properties_rofrs_medium" &&
            locations &&
            locations.length &&
            rofrs &&
            rofrs["medium"]
          ) {
            if (rofrs["medium"].features && rofrs["medium"].features.length) {
              let rofrs_medium_properties = rofrs["medium"];

              let dataLayer = new window.google.maps.Data();
              //console.log(rofrs_medium_properties)
              if (rofrs_medium_properties.features) {
                dataLayer.addGeoJson(rofrs_medium_properties);
              }
              if (dataLayer) {
                if (layer.styleRofrs) {
                  dataLayer.setStyle(layer.styleRofrs);
                } else {
                  dataLayer.setStyle(layer.style);
                }

                if (layer.onClick) {
                  dataLayer.addListener("click", layer.onClick.bind(this));
                }
                this.setState((state) => {
                  layer.layer = dataLayer;

                  //Loading 20%

                  if (layer.visible) {
                    this.showLayer(state.map, layer);
                  }

                  //return layers;
                });
              }
            }
          } else if (
            layer.name === "properties_rofrs_high" &&
            locations &&
            locations.length &&
            rofrs &&
            rofrs["high"]
          ) {
            if (rofrs["high"].features && rofrs["high"].features.length) {
              let rofrs_high_properties = rofrs["high"];

              let dataLayer = new window.google.maps.Data();
              //console.log(rofrs_high_properties)
              if (rofrs_high_properties.features) {
                dataLayer.addGeoJson(rofrs_high_properties);
              }
              if (dataLayer) {
                if (layer.styleRofrs) {
                  dataLayer.setStyle(layer.styleRofrs);
                } else {
                  dataLayer.setStyle(layer.style);
                }

                if (layer.onClick) {
                  dataLayer.addListener("click", layer.onClick.bind(this));
                }
                this.setState((state) => {
                  layer.layer = dataLayer;

                  //Loading 20%

                  if (layer.visible) {
                    this.showLayer(state.map, layer);
                  }

                  //return layers;
                });
              }
            }
          } else if (
            layer.name === "properties_rofsw_low" &&
            locations &&
            locations.length &&
            rofsw &&
            rofsw["low"]
          ) {
            if (rofsw["low"].features && rofsw["low"].features.length) {
              let rofsw_low_properties = rofsw["low"];

              let dataLayer = new window.google.maps.Data();
              // console.log(rofsw_low_properties)
              if (rofsw_low_properties.features) {
                dataLayer.addGeoJson(rofsw_low_properties);
              }
              if (dataLayer) {
                if (layer.styleRofsw) {
                  dataLayer.setStyle(layer.styleRofsw);
                } else {
                  dataLayer.setStyle(layer.style);
                }

                if (layer.onClick) {
                  dataLayer.addListener("click", layer.onClick.bind(this));
                }
                this.setState((state) => {
                  layer.layer = dataLayer;

                  //Loading 20%

                  if (layer.visible) {
                    this.showLayer(state.map, layer);
                  }

                  //return layers;
                });
              }
            }
          } else if (
            layer.name === "properties_rofsw_medium" &&
            locations &&
            locations.length &&
            rofsw &&
            rofsw["medium"]
          ) {
            if (rofsw["medium"].features && rofsw["medium"].features.length) {
              let rofsw_medium_properties = rofsw["medium"];

              let dataLayer = new window.google.maps.Data();
              // console.log(rofsw_medium_properties)
              if (rofsw_medium_properties.features) {
                dataLayer.addGeoJson(rofsw_medium_properties);
              }
              if (dataLayer) {
                if (layer.styleRofsw) {
                  dataLayer.setStyle(layer.styleRofsw);
                } else {
                  dataLayer.setStyle(layer.style);
                }

                if (layer.onClick) {
                  dataLayer.addListener("click", layer.onClick.bind(this));
                }
                this.setState((state) => {
                  layer.layer = dataLayer;

                  //Loading 20%

                  if (layer.visible) {
                    this.showLayer(state.map, layer);
                  }

                  //return layers;
                });
              }
            }
          } else if (
            layer.name === "properties_rofsw_high" &&
            locations &&
            locations.length &&
            rofsw &&
            rofsw["high"]
          ) {
            if (rofsw["high"].features && rofsw["high"].features.length) {
              let rofsw_high_properties = rofsw["high"];

              let dataLayer = new window.google.maps.Data();
              //console.log(rofsw_high_properties)
              if (rofsw_high_properties.features) {
                dataLayer.addGeoJson(rofsw_high_properties);
              }
              if (dataLayer) {
                if (layer.styleRofsw) {
                  dataLayer.setStyle(layer.styleRofsw);
                } else {
                  dataLayer.setStyle(layer.style);
                }

                if (layer.onClick) {
                  dataLayer.addListener("click", layer.onClick.bind(this));
                }
                this.setState((state) => {
                  layer.layer = dataLayer;

                  //Loading 20%

                  if (layer.visible) {
                    this.showLayer(state.map, layer);
                  }

                  //return layers;
                });
              }
            }
          } else if (
            layer.name === "properties_crime_low" &&
            locations &&
            locations.length &&
            crime &&
            crime["low"]
          ) {
            if (crime["low"].features && crime["low"].features.length) {
              let crime_low_properties = crime["low"];

              let dataLayer = new window.google.maps.Data();
              //console.log(crime_low_properties)
              if (crime_low_properties.features) {
                dataLayer.addGeoJson(crime_low_properties);
              }
              if (dataLayer) {
                if (layer.styleCrime) {
                  dataLayer.setStyle(layer.styleCrime);
                } else {
                  dataLayer.setStyle(layer.style);
                }

                if (layer.onClick) {
                  dataLayer.addListener("click", layer.onClick.bind(this));
                }
                this.setState((state) => {
                  layer.layer = dataLayer;

                  //Loading 20%

                  if (layer.visible) {
                    this.showLayer(state.map, layer);
                  }

                  //return layers;
                });
              }
            }
          } else if (
            layer.name === "properties_crime_medium" &&
            locations &&
            locations.length &&
            crime &&
            crime["medium"]
          ) {
            if (crime["medium"].features && crime["medium"].features.length) {
              let crime_medium_properties = crime["medium"];

              let dataLayer = new window.google.maps.Data();
              //console.log(crime_medium_properties)
              if (crime_medium_properties.features) {
                dataLayer.addGeoJson(crime_medium_properties);
              }
              if (dataLayer) {
                if (layer.styleCrime) {
                  dataLayer.setStyle(layer.styleCrime);
                } else {
                  dataLayer.setStyle(layer.style);
                }

                if (layer.onClick) {
                  dataLayer.addListener("click", layer.onClick.bind(this));
                }
                this.setState((state) => {
                  layer.layer = dataLayer;

                  //Loading 20%

                  if (layer.visible) {
                    this.showLayer(state.map, layer);
                  }

                  //return layers;
                });
              }
            }
          } else if (
            layer.name === "properties_crime_high" &&
            locations &&
            locations.length &&
            crime &&
            crime["high"]
          ) {
            //console.log("list")
            if (crime["high"].features && crime["high"].features.length) {
              // console.log("properties")
              let crime_high_properties = crime["high"];

              let dataLayer = new window.google.maps.Data();
              //console.log(crime_high_properties)
              if (crime_high_properties.features) {
                dataLayer.addGeoJson(crime_high_properties);
              }
              if (dataLayer) {
                if (layer.styleCrime) {
                  dataLayer.setStyle(layer.styleCrime);
                } else {
                  dataLayer.setStyle(layer.style);
                }

                if (layer.onClick) {
                  dataLayer.addListener("click", layer.onClick.bind(this));
                }
                this.setState((state) => {
                  layer.layer = dataLayer;

                  //Loading 20%

                  if (layer.visible) {
                    this.showLayer(state.map, layer);
                  }

                  //return layers;
                });
              }
            }
          } else if (
            layer.name === "properties_fire_low" &&
            locations &&
            locations.length &&
            fire &&
            fire["low"]
          ) {
            if (fire["low"].features && fire["low"].features.length) {
              let fire_low_properties = fire["low"];

              let dataLayer = new window.google.maps.Data();
              // console.log(fire_low_properties)
              if (fire_low_properties.features) {
                dataLayer.addGeoJson(fire_low_properties);
              }
              if (dataLayer) {
                if (layer.styleFire) {
                  dataLayer.setStyle(layer.styleFire);
                } else {
                  dataLayer.setStyle(layer.style);
                }

                if (layer.onClick) {
                  dataLayer.addListener("click", layer.onClick.bind(this));
                }
                this.setState((state) => {
                  layer.layer = dataLayer;

                  //Loading 20%

                  if (layer.visible) {
                    this.showLayer(state.map, layer);
                  }

                  //return layers;
                });
              }
            }
          } else if (
            layer.name === "properties_fire_medium" &&
            locations &&
            locations.length &&
            fire &&
            fire["medium"]
          ) {
            if (fire["medium"].features && fire["medium"].features.length) {
              let fire_medium_properties = fire["medium"];

              let dataLayer = new window.google.maps.Data();
              // console.log(fire_medium_properties)
              if (fire_medium_properties.features) {
                dataLayer.addGeoJson(fire_medium_properties);
              }
              if (dataLayer) {
                if (layer.styleFire) {
                  dataLayer.setStyle(layer.styleFire);
                } else {
                  dataLayer.setStyle(layer.style);
                }

                if (layer.onClick) {
                  dataLayer.addListener("click", layer.onClick.bind(this));
                }
                this.setState((state) => {
                  layer.layer = dataLayer;

                  //Loading 20%

                  if (layer.visible) {
                    this.showLayer(state.map, layer);
                  }

                  //return layers;
                });
              }
            }
          } else if (
            layer.name === "properties_fire_high" &&
            locations &&
            locations.length &&
            fire &&
            fire["high"]
          ) {
            if (fire["high"].features && fire["high"].features.length) {
              let fire_high_properties = fire["high"];

              let dataLayer = new window.google.maps.Data();
              //console.log(fire_high_properties)
              if (fire_high_properties.features) {
                dataLayer.addGeoJson(fire_high_properties);
              }
              if (dataLayer) {
                if (layer.styleFire) {
                  dataLayer.setStyle(layer.styleFire);
                } else {
                  dataLayer.setStyle(layer.style);
                }

                if (layer.onClick) {
                  dataLayer.addListener("click", layer.onClick.bind(this));
                }
                this.setState((state) => {
                  layer.layer = dataLayer;

                  //Loading 20%

                  if (layer.visible) {
                    this.showLayer(state.map, layer);
                  }

                  //return layers;
                });
              }
            }
          } else if (
            layer.name === "properties_food_hygiene_low" &&
            locations &&
            locations.length &&
            food_hygiene &&
            food_hygiene["low"]
          ) {
            if (
              food_hygiene["low"].features &&
              food_hygiene["low"].features.length
            ) {
              let food_hygiene_low_properties = food_hygiene["low"];

              let dataLayer = new window.google.maps.Data();
              // console.log(food_hygiene_low_properties)
              if (food_hygiene_low_properties.features) {
                dataLayer.addGeoJson(food_hygiene_low_properties);
              }
              if (dataLayer) {
                if (layer.styleFoodHygiene) {
                  dataLayer.setStyle(layer.styleFoodHygiene);
                } else {
                  dataLayer.setStyle(layer.style);
                }

                if (layer.onClick) {
                  dataLayer.addListener("click", layer.onClick.bind(this));
                }
                this.setState((state) => {
                  layer.layer = dataLayer;

                  //Loading 20%

                  if (layer.visible) {
                    this.showLayer(state.map, layer);
                  }

                  //return layers;
                });
              }
            }
          } else if (
            layer.name === "properties_food_hygiene_medium" &&
            locations &&
            locations.length &&
            food_hygiene &&
            food_hygiene["medium"]
          ) {
            if (
              food_hygiene["medium"].features &&
              food_hygiene["medium"].features.length
            ) {
              let food_hygiene_medium_properties = food_hygiene["medium"];

              let dataLayer = new window.google.maps.Data();
              // console.log(food_hygiene_medium_properties)
              if (food_hygiene_medium_properties.features) {
                dataLayer.addGeoJson(food_hygiene_medium_properties);
              }
              if (dataLayer) {
                if (layer.styleFoodHygiene) {
                  dataLayer.setStyle(layer.styleFoodHygiene);
                } else {
                  dataLayer.setStyle(layer.style);
                }

                if (layer.onClick) {
                  dataLayer.addListener("click", layer.onClick.bind(this));
                }
                this.setState((state) => {
                  layer.layer = dataLayer;

                  //Loading 20%

                  if (layer.visible) {
                    this.showLayer(state.map, layer);
                  }

                  //return layers;
                });
              }
            }
          } else if (
            layer.name === "properties_food_hygiene_high" &&
            locations &&
            locations.length &&
            food_hygiene &&
            food_hygiene["high"]
          ) {
            if (
              food_hygiene["high"].features &&
              food_hygiene["high"].features.length
            ) {
              let food_hygiene_high_properties = food_hygiene["high"];

              let dataLayer = new window.google.maps.Data();
              //console.log(food_hygiene_high_properties)
              if (food_hygiene_high_properties.features) {
                dataLayer.addGeoJson(food_hygiene_high_properties);
              }
              if (dataLayer) {
                if (layer.styleFoodHygiene) {
                  dataLayer.setStyle(layer.styleFoodHygiene);
                } else {
                  dataLayer.setStyle(layer.style);
                }

                if (layer.onClick) {
                  dataLayer.addListener("click", layer.onClick.bind(this));
                }
                this.setState((state) => {
                  layer.layer = dataLayer;

                  //Loading 20%

                  if (layer.visible) {
                    this.showLayer(state.map, layer);
                  }

                  //return layers;
                });
              }
            }
          } else if (
            layer.name === "properties_pandemic_low" &&
            locations &&
            locations.length &&
            pandemic &&
            pandemic["low"]
          ) {
            if (pandemic["low"].features && pandemic["low"].features.length) {
              let pandemic_low_properties = pandemic["low"];

              let dataLayer = new window.google.maps.Data();
              // console.log(pandemic_low_properties)
              if (pandemic_low_properties.features) {
                dataLayer.addGeoJson(pandemic_low_properties);
              }
              if (dataLayer) {
                if (layer.stylePandemic) {
                  dataLayer.setStyle(layer.stylePandemic);
                } else {
                  dataLayer.setStyle(layer.style);
                }

                if (layer.onClick) {
                  dataLayer.addListener("click", layer.onClick.bind(this));
                }
                this.setState((state) => {
                  layer.layer = dataLayer;

                  //Loading 20%

                  if (layer.visible) {
                    this.showLayer(state.map, layer);
                  }

                  //return layers;
                });
              }
            }
          } else if (
            layer.name === "properties_pandemic_medium" &&
            locations &&
            locations.length &&
            pandemic &&
            pandemic["medium"]
          ) {
            if (
              pandemic["medium"].features &&
              pandemic["medium"].features.length
            ) {
              let pandemic_medium_properties = pandemic["medium"];

              let dataLayer = new window.google.maps.Data();
              // console.log(pandemic_medium_properties)
              if (pandemic_medium_properties.features) {
                dataLayer.addGeoJson(pandemic_medium_properties);
              }
              if (dataLayer) {
                if (layer.stylePandemic) {
                  dataLayer.setStyle(layer.stylePandemic);
                } else {
                  dataLayer.setStyle(layer.style);
                }

                if (layer.onClick) {
                  dataLayer.addListener("click", layer.onClick.bind(this));
                }
                this.setState((state) => {
                  layer.layer = dataLayer;

                  //Loading 20%

                  if (layer.visible) {
                    this.showLayer(state.map, layer);
                  }

                  //return layers;
                });
              }
            }
          } else if (
            layer.name === "properties_pandemic_high" &&
            locations &&
            locations.length &&
            pandemic &&
            pandemic["high"]
          ) {
            if (pandemic["high"].features && pandemic["high"].features.length) {
              let pandemic_high_properties = pandemic["high"];

              let dataLayer = new window.google.maps.Data();
              //console.log(pandemic_high_properties)
              if (pandemic_high_properties.features) {
                dataLayer.addGeoJson(pandemic_high_properties);
              }
              if (dataLayer) {
                if (layer.stylePandemic) {
                  dataLayer.setStyle(layer.stylePandemic);
                } else {
                  dataLayer.setStyle(layer.style);
                }

                if (layer.onClick) {
                  dataLayer.addListener("click", layer.onClick.bind(this));
                }
                this.setState((state) => {
                  layer.layer = dataLayer;

                  //Loading 20%

                  if (layer.visible) {
                    this.showLayer(state.map, layer);
                  }

                  //return layers;
                });
              }
            }
          }

        default:
      }
    }
    this.setState({ riskLocationsUpdated: true });
  }

  componentWillUnmount() {
    for (let layerName in this.state.layers) {
      let layer = this.state.layers[layerName];

      if (layer.visible) {
        //       console.log(layer.layer)
        // console.log("hiding")
        this.hideLayer(this.state.map, layer);
        layer.visible = false;
      }
    }
    this.setState({ layers: this.state.layers });
  }

  //Loads the map with the needed data and tile layers

  async onMainMapLoad(map) {
    this.setState({ map });
    this.state.mapLoadingstate = 0;

    if (this.props.locationsList) {
      let alertLocList = [];
      let warningLocList = [];
      let severeWarningLocList = [];
      let locations = this.props.locationsList;
      if (locations.alerts !== null) {
        this.state.alertLocations = locations.alerts;
      } else {
        this.state.alertLocations = {
          type: "FeatureCollection",
          features: alertLocList,
        };
      }
      if (locations.warnings !== null) {
        this.state.warningLocations = locations.warnings;
      } else {
        this.state.warningLocations = {
          type: "FeatureCollection",
          features: warningLocList,
        };
      }
      if (locations.severe !== null) {
        this.state.severeWarningLocations = locations.severe;
      } else {
        this.state.severeWarningLocations = {
          type: "FeatureCollection",
          features: severeWarningLocList,
        };
      }
    }

    for (let layerName in this.state.layers) {
      this.setState({
        mapLoadingstate:
          this.state.mapLoadingstate +
          parseInt(100 / Object.keys(this.state.layers).length),
      });
      let layer = this.state.layers[layerName];
      switch (layer.type) {
        case "data":
          // console.log("flood alerts layer ")
          // console.log(layer.layer)
          // console.log(layer)

          if (layer.name === "Flood Alerts") {
            //&& !layer.layer){

            let data = await systemReduxMapHandler.directLayer(
              this.props,
              layer,
              this.props.locations,
              this.props.user.user.signInUserSession.idToken.jwtToken,
              this.props.userData.data.organisation
            );
            // console.log(alerts)

            // let totalalerts = alerts.features.length||0;

            //   for (let i = 1;i < alerts.features.length; i+=2) {

            //     let alert = alerts.features[i]

            //     if(typeof alert.geometry  === 'string' && typeof alert.properties.compressed !=='undefined' &&alert.properties.compressed==true){
            //     //console.log(alert.geometry)
            //     let geometry = JSON.parse(alert.geometry)
            //     console.log(geometry)
            //     alert.geometry = topojson.feature(geometry, geometry.objects.data).features[0].geometry
            //     console.log(alert.geometry)

            //     this.state.mapLoadingstate = 100*(i/totalalerts)
            //     }
            //   }
            // }
            if (data != null) {
              let dataLayer = new window.google.maps.Data();
              //console.log(data)
              if (data.features) {
                dataLayer.addGeoJson(data);
              }
              if (dataLayer) {
                if (layer.style) {
                  dataLayer.setStyle(layer.style);
                }
                if (layer.onClick) {
                  dataLayer.addListener("click", layer.onClick.bind(this));
                }

                this.setState((state) => {
                  layer.layer = dataLayer;

                  //Loading 20%

                  this.showLayer(state.map, layer);

                  //return layers;
                });
              }
            }
          } else {
            // console.log(layer.name)
            // console.log("ALERTS EMPTY")
            this.setState((state) => {
              //layer.layer = dataLayer;

              //Loading 20%

              this.showLayer(state.map, layer.layer);

              //return layers;
            });
            //this.showLayer(this.state.map, layer.layer);
          }

          break;
        case "alert-locations":
          if (layer.name === "alert_properties_alert") {
            if (
              this.state.alertLocations &&
              this.state.alertLocations.features &&
              this.state.alertLocations.features.length
            ) {
              let alert_locations = this.state.alertLocations;

              let dataLayer = new window.google.maps.Data();
              if (alert_locations.features) {
                dataLayer.addGeoJson(alert_locations);
              }
              if (dataLayer) {
                if (layer.style) {
                  dataLayer.setStyle(layer.style);
                }
                if (layer.onClick) {
                  dataLayer.addListener("click", layer.onClick.bind(this));
                }
                this.setState((state) => {
                  layer.layer = dataLayer;

                  //Loading 20%

                  this.showLayer(state.map, layer);

                  //return layers;
                });
              }
            }
          }
          if (
            layer.name === "alert_properties_warning" &&
            this.state.warningLocations &&
            this.state.warningLocations.features &&
            this.state.warningLocations.features.length
          ) {
            let warning_locations = this.state.warningLocations;

            let dataLayer = new window.google.maps.Data();
            if (warning_locations.features) {
              dataLayer.addGeoJson(warning_locations);
            }
            if (dataLayer) {
              if (layer.style) {
                dataLayer.setStyle(layer.style);
              }
              if (layer.onClick) {
                dataLayer.addListener("click", layer.onClick.bind(this));
              }
              this.setState((state) => {
                layer.layer = dataLayer;

                //Loading 20%

                this.showLayer(state.map, layer);

                //return layers;
              });
            }
          }
          if (
            layer.name === "alert_properties_severe" &&
            this.state.severeWarningLocations &&
            this.state.severeWarningLocations.features &&
            this.state.severeWarningLocations.features.length
          ) {
            let severe_warning_locations = this.state.severeWarningLocations;

            let dataLayer = new window.google.maps.Data();
            //  console.log(severe_warning_locations)
            if (severe_warning_locations.features) {
              dataLayer.addGeoJson(severe_warning_locations);
            }
            if (dataLayer) {
              if (layer.style) {
                dataLayer.setStyle(layer.style);
              }
              if (layer.onClick) {
                dataLayer.addListener("click", layer.onClick.bind(this));
              }
              this.setState((state) => {
                layer.layer = dataLayer;

                //Loading 20%

                this.showLayer(state.map, layer);

                //return layers;
              });
            }
          }

          break;

        case "tile":
          gmaps.tileLayerFromURL(layer.url, layer).then((tileLayer) => {
            this.setState((state) => {
              let layers = state.layers;
              let layer = layers[layerName];
              layer.layer = tileLayer;

              if (layer.visible) {
                //       console.log(layer.layer)
                // console.log("showing")
                this.showLayer(state.map, layer);
              }
              return layers;
            });
          });

          break;
        default:
      }
    }
    this.state.mounted = true;
    this.setState({ mapLoadingstate: 100 });
  }

  //changes the layer upon toggle clicks

  onLayerChange(e) {
    let layerId = e.target.id;
    this.setState((state) => {
      let layers = state.layers;
      let layer = layers[layerId];
      if (layer) {
        layer.visible = !layer.visible;
      }
      if (layer.visible) {
        this.showLayer(state.map, layer);
      } else {
        this.hideLayer(state.map, layer);
      }

      return { layers };
    });
  }

  //changes the filters upon toggle clicks

  onFilterChange(e) {
    this.setState({ mapLoadingstate: 0 });

    //console.log("changing filters");
    let filterId = e.target.id;
    this.setState((state) => {
      let layers = state.layers;
      let filters = state.filters;
      let filter = filters[filterId];
      filter.active = !filter.active;

      if (filter.active) {
        this.showFilter(filter, layers);
      } else {
        this.hideFilter(filter, layers);
      }

      return { filters };
    });
    this.setState({ mapLoadingstate: 100 });
  }

  //makes the given layer visible

  async showLayer(map, layer) {
    this.state.mapLoadingstate = 0;

    if (!layer) return;
    switch (layer.type) {
      case "data":
        if (layer.name === "Flood Alerts") {
          if (!this.state.layers["floodAlertsPolygons"].visible) {
            gmaps.showTileLayer(
              map,
              this.state.layers["floodAlertsPolygons"].layer
            );
          }

          gmaps.showDataLayer(map, layer.layer);
        } else {
          gmaps.showDataLayer(map, layer.layer);
        }

        break;
      case "alert-locations":
        gmaps.showDataLayer(map, layer.layer);

        break;
      case "property_risk":
        gmaps.showDataLayer(map, layer.layer);
        break;
      case "tile":
        gmaps.showTileLayer(map, layer.layer);
        break;
      default:
    }
    // this.setState({ mapLoadingstate: 100 });
  }

  //hides the given layer

  hideLayer(map, layer) {
    if (!layer) return;
    switch (layer.type) {
      case "data":
        if (layer.name === "Flood Alerts") {
          gmaps.hideTileLayer(
            map,
            this.state.layers["floodAlertsPolygons"].layer
          );

          gmaps.hideDataLayer(map, layer.layer);
        } else {
          gmaps.hideDataLayer(map, layer.layer);
        }

        break;
      case "alert-locations":
        gmaps.hideDataLayer(map, layer.layer);

        break;
      case "property_risk":
        gmaps.hideDataLayer(map, layer.layer);
        break;
      case "tile":
        gmaps.hideTileLayer(map, layer.layer);
        break;
      default:
    }
  }

  //displays the data of the given filter

  async showFilter(filter, layers) {
    if (!filter) return;
    for (let layerId in layers) {
      let layer = layers[layerId];

      if (!layer.filterable) continue;
      if (layer.type !== "data") {
        console.log(`Unexpected layer type: ${layer.name} - ${layer.type}`);
        return;
      }
      if (layer.name === filter.parentLayer) {
        //console.log(filter)
        // console.log(layer)
        let filterResult = await filter.func(
          layer.url,
          filter.args,
          this.props.locations,
          this.props.user.user.signInUserSession.idToken.jwtToken
        );
        // console.log(filterResult)
        if (layer.layer !== null && filterResult !== null) {
          layer.layer.forEach((feature) => {
            //console.log(layer.layer)
            let id = feature.getProperty("id");
            if (typeof filterResult.indexOf !== "undefined") {
              if (filterResult.indexOf(id) > -1) {
                // Only show feature if no other filter is hiding it
                let filteredBy = feature.getProperty("filteredBy");
                if (!filteredBy) filteredBy = [];
                filteredBy.splice(filteredBy.indexOf(filter.id), 1);
                feature.setProperty("filteredBy", filteredBy);
                if (filteredBy.length === 0) layer.layer.revertStyle(feature);
              }
            }
          });
        }
      }
    }
  }

  //hides the data of the given filter

  async hideFilter(filter, layers) {
    if (!filter) return;
    for (let layerId in layers) {
      let layer = layers[layerId];
      // console.log(layer);
      if (!layer.filterable) continue;
      if (layer.type !== "data") {
        //  console.log(`Unexpected layer type: ${layer.name} - ${layer.type}`);
        return;
      }
      // console.log(filter)
      // console.log(layer)

      if (layer.name === filter.parentLayer) {
        // console.log(filter)
        // console.log(layer)
        let filterResult = await filter.func(
          layer.url,
          filter.args,
          this.props.locations,
          this.props.user.user.signInUserSession.idToken.jwtToken
        );
        //   console.log(filterResult)
        if (layer.layer !== null && filterResult !== null) {
          layer.layer.forEach((feature) => {
            //   console.log(layer.layer)
            let id = feature.getProperty("id");
            if (typeof filterResult.indexOf !== "undefined") {
              if (filterResult.indexOf(id) > -1) {
                // Keep track of which filters are filtering this feature
                let filteredBy = feature.getProperty("filteredBy");
                if (!filteredBy) filteredBy = [];
                filteredBy.push(filter);
                feature.setProperty("filteredBy", filteredBy);
                layer.layer.overrideStyle(feature, { visible: false });
              }
            }
          });
        }
      }
    }
  }

  //firstly sets the parent child component function, then renders the map

  render() {
    this.componentload();
    if (
      this.state.mounted === true &&
      typeof this.props.mapLocationDataList !== "undefined" &&
      this.props.mapLocationDataList !== null &&
      this.props.mapLocationDataList.length > 0 &&
      (this.state.riskLocationsUpdated === false ||
        this.state.mapLocationDataListLength !==
          this.props.mapLocationDataList.length)
    ) {
      this.loadRiskLocations(this.props.mapLocationDataList);
    }

    let layers = [];
    for (let layerId in activeLayers) {
      let layer = activeLayers[layerId];

      let filters = [];
      for (let filterId in activeFilters) {
        let filter = activeFilters[filterId];
        if (filter.parentLayer === layer.name) {
          let option = (
            <Option
              key={filterId}
              label={filter.name}
              id={filterId}
              icon={filter.icon}
              checked={this.state.filters[filterId].active}
              onChange={this.onFilterChange}
            />
          );
          filters.push(option);
        }
      }
      if (layer.toggleable === true) {
        let category = (
          <OptionCategory
            key={layerId}
            icon={layer.icon}
            label={layer.name}
            id={layerId}
            checked={this.state.layers[layerId].visible}
            onChange={this.onLayerChange}
          >
            {filters}
          </OptionCategory>
        );
        layers.push(category);
      }
    }

    return (
      <div className="app">
        <ProgressBar variant="info" now={this.state.mapLoadingstate} />
        <GoogleMap
          id="map"
          className="map"
          options={{
            zoom: 7,
            center: { lat: 52.8, lng: -2.5 },
            styles: styles.maps.mono,
            fullscreenControl: true,
          }}
          apiKey="AIzaSyD9n6ddk1Z6E2L5hZqCVRo94I8lbHjm30Q"
          onMapLoad={this.onMainMapLoad}
        />

        <Sidebar>{layers}</Sidebar>
      </div>
    );
  }
}
function mapStateToProps(state) {
  const {
    userLogged,
    user,
    userData,
    systemData,
    mapAlertData,
    mapLocationData,
    dashboardData,
  } = state;

  return {
    userLogged,
    user,
    userData,
    systemData,
    mapAlertData,
    mapLocationData,
    dashboardData,
  };
}
export default connect(mapStateToProps)(App);
