import { systemConstants } from "../_constants";
import axios from "axios";
import * as urls from "../../components/maps/urls";

export async function getMitigations(location_id, secret) {
  let payload = null;

  let data = { propid: location_id };

  await axios({
    method: "post",
    url: urls.API_GATEWAY + "mitigations/get-mitigations",
    data: data,
  })
    .then((res) => {
      console.log(res.data);
      payload = res.data;

      if (
        payload === "null" ||
        payload === null ||
        payload === "Failure" ||
        typeof payload === "undefined"
      ) {
        payload = null;
        console.log("Mitigations getting ERROR");
      } else if (payload === "Invalid Details") {
        console.log("Credentials for getting mitigations, details are INVALID");
        payload = null;
      } else if (payload === "No mitigations found") {
        console.log("No Mitigations were found!");
        payload = null;
      } else {
        console.log("SUCCESS!!");
        console.log("Mitigations Data retrieved from the database.");
      }
    })
    .catch((err) => {
      payload = null;
      console.log("FAILURE!!");
      console.log(err);
    });

  return payload;
}
