/**
 * Common handlers for map events
 */
import * as style from "./styles";
/**
 * Generate information window content for a flood alert feature.
 * @param {DataFeature} feature - Data feature of information to be generated.
 */
const genFloodAlertInfo = function (feature) {
  console.log("event genfloodinfo");
  let div = document.createElement("div");
  div.classList.add("info_window");

  let title = document.createElement("h1");
  title.classList.add("info_window__heading");
  title.textContent = style.severity[feature.getProperty("severityLevel")];
  div.appendChild(title);

  let desc = document.createElement("h2");
  desc.classList.add("info_window__subheading");
  desc.textContent = feature.getProperty("description");
  div.appendChild(desc);

  let message = document.createElement("p");
  message.classList.add("info_window__text");
  message.textContent = feature.getProperty("message");
  div.appendChild(message);

  // Find latest timestamp
  let dates = [
    new Date(feature.getProperty("timeRaised")),
    new Date(feature.getProperty("timeMessageChanged")),
    new Date(feature.getProperty("timeSeverityChanged")),
  ];
  let latest = new Date(Math.max(...dates));

  let updated = document.createElement("p");
  updated.classList.add("info_window__text");
  updated.textContent = ["Updated:", latest.toLocaleString("en-GB")].join(" ");
  div.appendChild(updated);

  return div;
};

const genNewsMapInfo = function (feature) {
  console.log("event genfloodinfo");
  let div = document.createElement("div");
  div.classList.add("info_window");

  let title = document.createElement("h1");
  title.classList.add("info_window__heading");
  title.textContent = feature.getProperty("title");
  div.appendChild(title);

  let desc = document.createElement("h2");
  desc.classList.add("info_window__subheading");
  desc.textContent = feature.getProperty("keywords");
  div.appendChild(desc);

  let message = document.createElement("p");
  message.classList.add("info_window__text");
  message.textContent = feature.getProperty("link");
  div.appendChild(message);

  // Find latest timestamp
  let dates = [new Date(feature.getProperty("processed_at"))];
  let latest = new Date(Math.max(...dates));

  let updated = document.createElement("p");
  updated.classList.add("info_window__text");
  updated.textContent = ["Updated:", latest.toLocaleString("en-GB")].join(" ");
  div.appendChild(updated);

  return div;
};

/**
 * On click event for flood alerts.
 * @param {Event} event - Event which occured.
 */
export const floodAlertOnClick = function (event) {
  let feature = event.feature;
  let type = feature.getGeometry().getType();

  switch (type) {
    case "Point":
      // Display info window for flood warning.
      if (this.state.infoWindow) this.state.infoWindow.close();
      let infoWindow = new window.google.maps.InfoWindow({
        content: genFloodAlertInfo(feature),
        pixelOffset: new window.google.maps.Size(0, -10),
        position: feature.getGeometry().get(),
      });

      infoWindow.open(this.state.map);
      this.setState({ infoWindow });
      break;
    default:
  }
};

export const news_map_on_click = function (event) {
  let feature = event.feature;
  let type = feature.getGeometry().getType();

  switch (type) {
    case "Point":
      // Display info window for flood warning.
      if (this.state.infoWindow) this.state.infoWindow.close();
      let infoWindow = new window.google.maps.InfoWindow({
        content: genNewsMapInfo(feature),
        pixelOffset: new window.google.maps.Size(0, -10),
        position: feature.getGeometry().get(),
      });

      infoWindow.open(this.state.map);
      this.setState({ infoWindow });
      break;
    default:
  }
};
