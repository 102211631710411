import React, { Component } from "react";
import Postcoder from "react-address-lookup";
import PostcodeLookup from "../external/react-address-lookup-master/src/postcode_lookup/PostcodeLookup.js";

export default class AddressLookup extends Component {
  // logAddress = (addr) => {
  // 	console.log(addr);
  // }

  render() {
    // {console.log(this.props.country)}

    // 	{this.props.updatePropAddress("bla",3)}
    return (
      <PostcodeLookup
        apiKey="PCW4Q-M4RF7-9FPNJ-RJHX9" //correct one
        country={this.props.country}
        id={this.props.id}
        index={this.props.index}
        parentAddress={this.props.address}
        key={this.props.id}
        //addressSelectedCallback={(addr)=>console.log(addr)}

        addressSelectedCallback={(addr) =>
          this.props.updatePropAddress(addr, this.props.index)
        }
      />
    );
  }
}
