import React from "react";
import Card from "react-bootstrap/Card";
import Button from "balkerne-components/Button";
import Select from "balkerne-components/Select";
import Input from "balkerne-components/Input";
import { GoogleMapDraw } from "../maps/GoogleMapDraw";
import * as styles from "../maps/styles";
import c from "./AddMap.module.scss";

export class AddMap extends React.Component {
  constructor(props) {
    super(props);
    //setting initial user row
    this.state = {
      location: {
        id: "",
        name: "",
        wkb_geometry: "",
        lat: "",
        lon: "",
        building_area: "",
        land_area: "",
        property_class: "None",
        commercial_priority: "None",
        region: "",
        address_street: "",
        address_town: "",
        address_county: "",
        address_country: "",
        address_postcode: "",
        property_customer_reference: "",
        polygon: null,
        marker: "",
        area: 0,
        lat: null,
        lon: null,
        assignedusers: [],
      },
      geocoder: null,
      addressArray: [],
      geo_lat: null,
      geo_lon: null,
      map: null,
      coords: { lat: 52.180455, lng: -1.67333 },
    };
    this.onMapLoad = this.onMapLoad.bind(this);
    this.checkPostcode = this.checkPostcode.bind(this);
    this.geocode = this.geocode.bind(this);
  }

  async geocode() {
    await this.state.geocoder.geocode(
      { address: this.state.location.address_postcode },
      (results, status) => {
        if (status === window.google.maps.GeocoderStatus.OK) {
          console.log("got coords");
          console.log(results[0]);
          this.state.geo_lat = results[0].geometry.location.lat();
          this.state.geo_lon = results[0].geometry.location.lng();
          this.state.addressArray = results[0].address_components;
          this.state.location.address_street = this.getStreet(
            this.state.addressArray
          );
          this.state.location.address_town = this.getCity(
            this.state.addressArray
          );
          this.state.location.address_county = this.getCounty(
            this.state.addressArray
          );
          this.state.location.address_country = this.getCountry(
            this.state.addressArray
          );
          this.setMapCenter();
        }
      }
    );
  }

  getCity = (addressArray) => {
    let city = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (
        addressArray[i].types[0] &&
        "postal_town" === addressArray[i].types[0]
      ) {
        city = addressArray[i].long_name;
        return city;
      }
    }
  };

  getStreet = (addressArray) => {
    let street = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0] && "route" === addressArray[i].types[0]) {
        street = addressArray[i].long_name;
        return street;
      }
    }
  };

  getCounty = (addressArray) => {
    let county = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0]) {
        for (let j = 0; j < addressArray[i].types.length; j++) {
          if (
            "administrative_area_level_2" === addressArray[i].types[j] ||
            "political" === addressArray[i].types[j]
          ) {
            county = addressArray[i].long_name;
            return county;
          }
        }
      }
    }
  };

  getCountry = (addressArray) => {
    let country = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (
        addressArray[i].types[0] &&
        "administrative_area_level_1" === addressArray[i].types[0]
      ) {
        country = addressArray[i].long_name;
        return country;
      }
    }
  };

  setMapCenter = () => {
    let map = this.state.map;
    console.log(
      "setting map " + this.state.geo_lat + "  " + this.state.geo_lon
    );
    map.setCenter({ lat: this.state.geo_lat, lng: this.state.geo_lon });
    map.setZoom(18);
    this.setState({ map });
  };

  async onMapLoad(map) {
    this.setState({ map });
    this.state.geocoder = await new window.google.maps.Geocoder();
  }

  GMapPolygonToWKT = (poly) => {
    // Start the Polygon Well Known Text (WKT) expression
    var wkt = "POLYGON(";
    var paths = poly.getPaths();

    for (var i = 0; i < paths.getLength(); i++) {
      var path = paths.getAt(i); // Open a ring grouping in the Polygon Well Known Text
      wkt += "(";
      for (var j = 0; j < path.getLength(); j++) {
        // Add each vertice and anticipate another vertice (trailing comma)
        wkt +=
          path.getAt(j).lng().toString() +
          " " +
          path.getAt(j).lat().toString() +
          ",";
      } // Google's approach assumes the closing point is the same as the opening // point for any given ring, so we have to refer back to the initial point // and append it to the end of our polygon wkt, properly closing it. // // Also close the ring grouping and anticipate another ring (trailing comma)
      wkt +=
        path.getAt(0).lng().toString() +
        " " +
        path.getAt(0).lat().toString() +
        "),";
    }

    // Resolve the last trailing "," and close the Polygon
    return wkt.substring(0, wkt.length - 1) + ")";
  };

  getPolygon = (polygon1) => {
    let polygon = this.GMapPolygonToWKT(polygon1);
    let obj = this.state.location;
    obj["polygon"] = polygon;
    this.setState({ location: obj });
  };

  getArea = (area) => {
    let obj = this.state.location;
    obj["area"] = area;
    this.setState({ location: obj });
  };

  getCenter = (center) => {
    let lat = center.lat();
    let lon = center.lng();
    let obj = this.state.location;
    obj["lat"] = lat;
    obj["lon"] = lon;
    this.setState({ location: obj });
  };

  getMarker = (marker) => {
    let obj = this.state.location;
    obj["marker"] = marker;
    this.setState({ location: obj });
  };

  // Loads updated values into the users array
  updateFields = (users) => {
    this.setState(this.state.users, this.props.users);
  };

  // Handles changes made to the user, updates the users array state
  handleChange = (e) => {
    let obj = this.state.location;
    obj[e.target.id] = e.target.value;
    console.log(e.target.value);
    this.setState({ location: obj });
    this.props.handleChange("location", this.state.location);
  };

  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      this.checkPostcode();
    }
  };

  async checkPostcode() {
    await this.geocode();
  }

  submitLocation = () => {
    let location = this.state.location;
    location.polygon = this.state.polygon;
    location.area = this.state.area;
    location.lat = this.state.lat;
    location.lon = this.state.lon;
    location.marker = this.state.marker;
    this.props.submitLocation(location);
  };

  // Conditionally render based on state, add the userInputs component for row of fields and set functions
  render() {
    if (this.props.currentStep !== 1) {
      return null;
    }
    return (
      <div className="d-flex flex-column flex-gap-2">
        {/* Map Area */}
        <Card>
          <Card.Header>
            <h4>Draw Property Area</h4>
          </Card.Header>
          <Card.Body>
            <div>
              {/* Postcode input */}
              <form
                className="d-flex align-items-end mb-2"
                onChange={this.handleChange}
                onSubmit={(e) =>
                  this.props.currentStep !== 3 ? e.preventDefault() : null
                }
              >
                <Input
                  label="Postcode"
                  className="flex-grow-1 mr-2"
                  id="address_postcode"
                  name="address_postcode"
                  onKeyPress={this.handleKeyPress}
                  placeholder="Search by postcode"
                />
                <Button light onClick={this.checkPostcode} className="mb-1">
                  Search
                </Button>
              </form>

              {/* Map */}
              <div className={c.map_container}>
                <GoogleMapDraw
                  className={c.map}
                  id="mapDashboard"
                  options={{
                    zoom: 6,
                    tilt: 0,
                    center: this.state.coords,
                    styles: styles.maps.mono,
                    zoomControl: true,
                    mapTypeControl: true,
                    streetViewControl: false,
                    styles: [
                      {
                        featureType: "poi",
                        elementType: "labels",
                        stylers: [
                          {
                            visibility: "simplified",
                          },
                        ],
                      },
                    ],
                  }}
                  apiKey="AIzaSyD9n6ddk1Z6E2L5hZqCVRo94I8lbHjm30Q"
                  onMapLoad={this.onMapLoad}
                  getPolygon={this.getPolygon}
                  getMarker={this.getMarker}
                  getArea={this.getArea}
                  getCenter={this.getCenter}
                  coords={this.state.coords}
                />
              </div>
            </div>
          </Card.Body>
        </Card>

        {/* Property Input */}
        <div>
          <form
            name="my-form"
            className="d-flex flex-column flex-gap-2"
            onChange={this.handleChange}
          >
            {/* Address */}
            <Card>
              <Card.Header>
                <h4>Address</h4>
              </Card.Header>
              <Card.Body>
                <div className={c.input_grid}>
                  <Input
                    label="Street"
                    id="address_street"
                    name="address_street"
                    defaultValue={this.state.location.address_street}
                  />
                  <Input
                    label="Town"
                    id="address_town"
                    name="address_town"
                    defaultValue={this.state.location.address_town}
                  />
                  <Input
                    label="County"
                    id="address_county"
                    name="address_county"
                    defaultValue={this.state.location.address_county}
                  />
                  <Input
                    label="Country"
                    id="address_country"
                    name="address_country"
                    defaultValue={this.state.location.address_country}
                  />
                </div>
              </Card.Body>
            </Card>

            {/* Property Details */}
            <Card>
              <Card.Header>
                <h4>Details</h4>
              </Card.Header>
              <Card.Body>
                <div className={c.input_grid}>
                  <Input label="Property Name" id="name" name="name" />
                  <Select
                    label="Property Class"
                    id="property_class"
                    name="property_class"
                    onChange={({ value }) =>
                      this.handleChange({ target: { value } })
                    }
                    options={[
                      { label: "None", value: "None" },
                      { label: "Supermarket", value: "Supermarket" },
                      { label: "Convenience", value: "Convenience" },
                      { label: "Depot", value: "Depot" },
                      { label: "Store", value: "Store" },
                      {
                        label: "Distribution Centre",
                        value: "Distribution Centre",
                      },
                    ]}
                  />
                  <Input label="Region" id="region" name="region" />
                  <Select
                    label="Commercial Priority"
                    id="commercial_priority"
                    name="commercial_priority"
                    onChange={({ value }) =>
                      this.handleChange({ target: { value } })
                    }
                    options={[
                      { label: "None", value: "None" },
                      { label: "Platinum", value: "Platinum" },
                      { label: "Gold", value: "Gold" },
                      { label: "Silver", value: "Silver" },
                      { label: "Bronze", value: "Bronze" },
                    ]}
                  />
                  <Input
                    label="Property Customer Reference No"
                    id="property_customer_reference"
                    name="property_customer_reference"
                  />
                </div>
              </Card.Body>
            </Card>
          </form>
        </div>
      </div>
    );
  }
}

export default AddMap;
