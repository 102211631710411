import { systemActions } from "../_actions";


import { getLocationData } from "./getLocationData";
import { getCrimeRisks } from "./getCrimeRisks";
import { getFloodRisks } from "./getFloodRisks";
import { getLiveAlerts } from "./getLiveAlerts";
import { getHistoricalAlerts,
  getHistoricalWeatherAlerts } from "./getHistoricalAlerts";
import { getEPC } from './getEPC';
import { getPropertyDetails } from "./getPropertyDetails";
import { getLossIncidents } from "./getLossIncidents";
import { getMitigations } from "./getMitigations";
import { getCovidTiers } from "./getCovidTiers";




const TIME40 = 1000 * 60 * 40;
const TIME10 = 1000 * 60 * 10;
const TIME15 = 1000 * 60 * 15;
const TIME50 = 1000 * 60 * 60;
const TIME60 = 1000 * 60 * 60;
let date;

export const systemReduxDashboardHandler = {
  setDashboardData,
  setDashboardDataSolo,
  //setDashboardDataLocationAlerts,
  updateLocationData,
  updateLiveAlerts,
  updateHistoricalAlerts,
  updateHistoricalWeatherAlerts,
  updateEPC,
  updatePropertyDetails,
  updateLossIncidents,
  updateFloodRisks,
  updateMitigations,
  updateCrimeRisks,
  resetLossIncidents,
  resetMitigations,
  updateCovidTiers
};


async function setDashboardDataSolo(props,location_id,user_id,org_id,secret) {
  console.log(props)
 
   let state =  props.dashboardData.data
   let country = "England"

   if( state.loaded === false  || state.id !== location_id){

     console.log("load system data normal 1")


       let location_data1 = await getLocationData(location_id,org_id,secret)
      //  console.log(location_data1.address_country)
      //  console.log(location_data1)
       country = location_data1.properties.address_country;
       let live_alerts1 = await getLiveAlerts(location_id,country, secret)
       let historical_alerts1 = await getHistoricalAlerts(location_id,country, secret)
       //let historical_weather_alerts = await getHistoricalWeatherAlerts(location_id, secret);
       //let epc = await getEPC(location_id, secret);
       let property_details = await getPropertyDetails(location_id,secret);
       let flood_risks1 = await getFloodRisks(location_id,secret)
       let crime_risks1 = await getCrimeRisks(location_id,secret)
       let loss_incidents1 = await getLossIncidents(location_id,user_id,secret)
       let mitigations1 = await getMitigations(location_id,secret)
       let covid_tiers1 = await getCovidTiers(location_id,secret)


   let state_new = state;
   state_new.id = location_id;
   state_new.country = country;
   state_new.location_data = location_data1;
   state_new.live_alerts= live_alerts1;
   state_new.historical_alerts= historical_alerts1;
   state_new.historical_weather_alerts = null;
   state_new.epc = null;
   state_new.property_details = property_details;
   state_new.flood_risks= flood_risks1;
   state_new.crime_risks= crime_risks1;
   state_new.loss_incidents= loss_incidents1;
   state_new.mitigations= mitigations1;
   state_new.covid_tiers = covid_tiers1;
   state_new.loaded = true;
   state_new.time_loaded = new Date().getTime();
   
    props.dispatch(systemActions.setDashboardData(state_new))
    //console.log(state_new)
   return state_new;
   }
   
   date = new Date() - TIME60
   if(date >state.time_loaded){

     console.log("load system data normal 2")

       

     let location_data1 = await getLocationData(location_id,org_id,secret)
     country = location_data1.properties.address_country;
     let live_alerts1 = await getLiveAlerts(location_id,country,secret)
     let historical_alerts1 = await getHistoricalAlerts(location_id,country,secret)
     //let historical_weather_alerts = await getHistoricalWeatherAlerts(location_id,secret);
     //let epc = await getEPC(location_id, secret);
     let property_details = await getPropertyDetails(location_id,secret);
     let flood_risks1 = await getFloodRisks(location_id,secret)
     let crime_risks1 = await getCrimeRisks(location_id,secret)
    let loss_incidents1 = await getLossIncidents(location_id,user_id,secret)
     let mitigations1 = await getMitigations(location_id,secret)
     let covid_tiers1 = await getCovidTiers(location_id,secret)


 let state_new = state;
 state_new.id = location_id;
 state_new.country = country;
 state_new.location_data = location_data1;
 state_new.live_alerts= live_alerts1;
 state_new.historical_alerts= historical_alerts1;
 state_new.historical_weather_alerts = null;
 state_new.epc = null;
 state_new.property_details = property_details;
 state_new.flood_risks= flood_risks1;
 state_new.crime_risks= crime_risks1;
 state_new.loss_incidents= loss_incidents1;
 state_new.mitigations= mitigations1;
 state_new.covid_tiers = covid_tiers1;
 state_new.loaded = true;
 state_new.time_loaded = new Date().getTime();
 
  props.dispatch(systemActions.setDashboardData(state_new))
 // console.log(state_new)
   return state_new;
   }
   console.log("return system data normal 3")
   return state;

}


 async function setDashboardData(props,location_id,country,user_id,org_id,secret) {
   console.log(props)
  
    let state =  props.dashboardData.data
  

    if( state.loaded === false  || state.id !== location_id){

      console.log("load system data normal 1")


        let location_data1 = await getLocationData(location_id,org_id,secret)
        let live_alerts1 = await getLiveAlerts(location_id,country,secret)
        let historical_alerts1 = await getHistoricalAlerts(location_id,country,secret)
        //let historical_weather_alerts = await getHistoricalWeatherAlerts(location_id,secret);
       // let epc = await getEPC(location_id, secret);
        let property_details = await getPropertyDetails(location_id,secret);
        let flood_risks1 = await getFloodRisks(location_id,secret)
        let crime_risks1 = await getCrimeRisks(location_id,secret)
        let loss_incidents1 = await getLossIncidents(location_id,user_id,secret)
        let mitigations1 = await getMitigations(location_id,secret)
        let covid_tiers1 = await getCovidTiers(location_id,secret)


    let state_new = state;
    state_new.id = location_id;
    state_new.country = country;
    state_new.location_data = location_data1;
    state_new.live_alerts= live_alerts1;
    state_new.historical_alerts= historical_alerts1;
    state_new.historical_weather_alerts = null;
    state_new.epc = null;
    state_new.property_details = property_details;
    state_new.flood_risks= flood_risks1;
    state_new.crime_risks= crime_risks1;
    state_new.loss_incidents= loss_incidents1;
    state_new.mitigations= mitigations1;
    state_new.covid_tiers = covid_tiers1;
    state_new.loaded = true;
    state_new.time_loaded = new Date().getTime();
    
     props.dispatch(systemActions.setDashboardData(state_new))
     //console.log(state_new)
    return state_new;
    }
    
    date = new Date() - TIME60
    if(date >state.time_loaded){

      console.log("load system data normal 2")

        

      let location_data1 = await getLocationData(location_id,org_id,secret)
      let live_alerts1 = await getLiveAlerts(location_id,country,secret)
      let historical_alerts1 = await getHistoricalAlerts(location_id,country,secret)
      //let historical_weather_alerts = await getHistoricalWeatherAlerts(location_id,secret);
     // let epc = await getEPC(location_id, secret);
      let property_details = await getPropertyDetails(location_id,secret);
       let flood_risks1 = await getFloodRisks(location_id,secret)
      let crime_risks1 = await getCrimeRisks(location_id,secret)
     let loss_incidents1 = await getLossIncidents(location_id,user_id,secret)
      let mitigations1 = await getMitigations(location_id,secret)
      let covid_tiers1 = await getCovidTiers(location_id,secret)


  let state_new = state;
  state_new.id = location_id;
  state_new.country = country;
  state_new.location_data = location_data1;
  state_new.live_alerts= live_alerts1;
  state_new.historical_alerts= historical_alerts1;
  state_new.historical_weather_alerts = null;
  state_new.epc = null;
  state_new.property_details = property_details;
  state_new.flood_risks= flood_risks1;
  state_new.crime_risks= crime_risks1;
  state_new.loss_incidents= loss_incidents1;
  state_new.mitigations= mitigations1;
  state_new.covid_tiers = covid_tiers1;
  state_new.loaded = true;
  state_new.time_loaded = new Date().getTime();
  
   props.dispatch(systemActions.setDashboardData(state_new))
  // console.log(state_new)
    return state_new;
    }
    console.log("return system data normal 3")
    return state;

}

// async function setDashboardDataLocationAlerts(props,location_id,country,location,alerts,user_id,org_id,secret) {
  
//   let state =  props.dashboardData.data

//   console.log(country)


//   if( state.loaded === false  || state.id !== location_id){

//     console.log("load with data system with Locals 1")
//     let location_data1=null
//       if(location !== null){
        
//         location_data1 = await getLocationData(location_id,org_id,secret)
//       }
//       else{
        
//         location_data1 = location
//       }
//       let live_alerts1 = null
//       if(alerts !== null && alerts.length > 0){
//         live_alerts1 = alerts
//       }
//       else{

//         live_alerts1 =  await getLiveAlerts(location_id,country,secret)
//       }

      
//       //let live_alerts1 = await getLiveAlerts(location_id,country)

      
//        let historical_alerts1 = await getHistoricalAlerts(location_id,country,secret)
//        let historical_weather_alerts = await getHistoricalWeatherAlerts(location_id,secret);
//        let property_details = await getPropertyDetails(location_id,secret);
//        let flood_risks1 = await getFloodRisks(location_id,secret)
//        let crime_risks1 = await getCrimeRisks(location_id,secret)
//        let loss_incidents1 = await getLossIncidents(location_id,user_id,secret)
//        let mitigations1 = await getMitigations(location_id,secret)

     


//   let state_new = state;
//   state_new.id = location_id;
//   state_new.country = country;
//   state_new.location_data = location_data1;
//   state_new.live_alerts= live_alerts1;
//   state_new.historical_alerts= historical_alerts1;
//   state_new.historical_weather_alerts = null;
//   state_new.property_details = property_details;
//   state_new.flood_risks= flood_risks1;
//   state_new.crime_risks= crime_risks1;
//   state_new.loss_incidents= loss_incidents1;
//   state_new.mitigations= mitigations1;
//   state_new.loaded = true;
//   state_new.time_loaded = new Date().getTime();
  
//    props.dispatch(systemActions.setDashboardData(state_new))
//   // console.log(state_new)
//   return state_new;
//   }
  
//   date = new Date() - TIME60
//   if(date >state.time_loaded){

//     console.log("load with data system with Locals 2")
      
//     let location_data1=null
//       if(location !== null){
        
//         location_data1 = await getLocationData(location_id,org_id,secret)
//       }
//       else{
        
//         location_data1 = location
//       }
//       let live_alerts1 = null
//       if(alerts !== null && alerts.length > 0 ){
//         live_alerts1 = alerts
//       }
//       else{

//         live_alerts1 =  await getLiveAlerts(location_id,country,secret)
//       }

//     let historical_alerts1 = await getHistoricalAlerts(location_id,country,secret)
//     let historical_weather_alerts = await getHistoricalWeatherAlerts(location_id,secret);
//     let property_details = await getPropertyDetails(location_id,secret);
//     let flood_risks1 = await getFloodRisks(location_id,secret)
//     let crime_risks1 = await getCrimeRisks(location_id,secret)
//     let loss_incidents1 = await getLossIncidents(location_id,user_id,secret)
//     let mitigations1 = await getMitigations(location_id,secret)


// let state_new = state;
// state_new.id = location_id;
// state_new.country = country;
// state_new.location_data = location_data1;
// state_new.live_alerts= live_alerts1;
// state_new.historical_alerts= historical_alerts1;
// state_new.historical_weather_alerts = null;
// state_new.property_details = property_details;
// state_new.flood_risks= flood_risks1;
// state_new.crime_risks= crime_risks1;
// state_new.loss_incidents= loss_incidents1;
// state_new.mitigations= mitigations1;
// state_new.loaded = true;
// state_new.time_loaded = new Date().getTime();

//  props.dispatch(systemActions.setDashboardData(state_new))
//  //console.log(state_new)
//   return state_new;
//   }
//   console.log("return with data system with Locals 3")
//   return state;

// }

async function updateLocationData(props,location_id,org_id,secret) {

     let state =  props.dashboardData.data

    if(typeof state.location_data === 'undefined' || state.location_data === null){

        console.log("update LocationData 0")

        let  location_data1 = await getLocationData(location_id,org_id,secret)
        state.location_data    =location_data1;
        state.location_data_updated    =new Date().getTime();
         props.dispatch(systemActions.updateLocationData(state))

        return state.location_data;
      }

      date = new Date() - TIME50
      
      if((typeof state.location_data_updated === 'undefined') &&  date > state.time_loaded){

        console.log("update LocationData 1")


        let  location_data1 = await getLocationData(location_id,org_id,secret)
        state.location_data    =location_data1;
        state.location_data_updated    =new Date().getTime();
         props.dispatch(systemActions.updateLocationData(state))
        return state.location_data;

      }
      if (state.location_data_updated && date>state.location_data_updated){

        console.log("update LocationData 2")


        let  location_data1 = await getLocationData(location_id,org_id,secret)
        state.location_data    =location_data1;
        state.location_data_updated    =new Date().getTime();
         props.dispatch(systemActions.updateLocationData(state))

        return state.location_data;
      }


    return state.location_data;

}


async function updateLiveAlerts(props,location_id,country,secret) {

  let state =  props.dashboardData.data

 if(typeof state.live_alerts === 'undefined'|| state.live_alerts === null){

     console.log("update live_alerts 0")

     let  live_alerts1 = await getLiveAlerts(location_id,country,secret)
     state.live_alerts    =live_alerts1;
     state.live_alerts_updated    =new Date().getTime();
      props.dispatch(systemActions.updateLiveAlerts(state))
     
     return state.live_alerts;
   }

   date = new Date()- TIME10

   
   if((typeof state.live_alerts_updated === 'undefined') &&  date > state.time_loaded){

     console.log("update live_alerts 1")


     let  live_alerts1 = await getLiveAlerts(location_id,country,secret)
     state.live_alerts    =live_alerts1;
     state.live_alerts_updated    =new Date().getTime();
      props.dispatch(systemActions.updateLiveAlerts(state))
     return state.live_alerts;

   }
  //  console.log("update alerts "+state.live_alerts_updated)
  //  console.log("update alerts "+date )
  //  console.log("update alerts = true  "+state.live_alerts_updated )

   if (state.live_alerts_updated && date>state.live_alerts_updated){

     console.log("update live_alerts 2")


     let  live_alerts1 = await getLiveAlerts(location_id,country,secret)
     state.live_alerts    =live_alerts1;
     state.live_alerts_updated    =new Date().getTime();
      props.dispatch(systemActions.updateLiveAlerts(state))

     return state.live_alerts;
   }


 return state.live_alerts;

}


async function updateHistoricalAlerts(props,location_id,country,secret) {

  let state =  props.dashboardData.data

 if(typeof state.historical_alerts === 'undefined'|| state.historical_alerts === null){

     console.log("update historical_alerts 0")

     let  historical_alerts1 = await getHistoricalAlerts(location_id,country,secret)
     state.historical_alerts    =historical_alerts1;
     state.historical_alerts_updated    =new Date().getTime();
      props.dispatch(systemActions.updateHistoricalAlerts(state))

     return state.historical_alerts;
   }

   date = new Date() - TIME40
   
   if((typeof state.historical_alerts_updated === 'undefined' )&&  date > state.time_loaded){

     console.log("update historical_alerts 1")


     let  historical_alerts1 = await getHistoricalAlerts(location_id,country,secret)
     state.historical_alerts    =historical_alerts1;
     state.historical_alerts_updated    =new Date().getTime();
      props.dispatch(systemActions.updateHistoricalAlerts(state))
     return state.historical_alerts;

   }
   if (state.historical_alerts_updated && date>state.historical_alerts_updated){

     console.log("update historical_alerts 2")


     let  historical_alerts1 = await getHistoricalAlerts(location_id,country,secret)
     state.historical_alerts    =historical_alerts1;
     state.historical_alerts_updated    =new Date().getTime();
      props.dispatch(systemActions.updateHistoricalAlerts(state))

     return state.historical_alerts;
   }


 return state.historical_alerts;
}

async function updateEPC(props, location_id, secret) {
  let state = props.dashboardData.data;
  console.log("updating EPC")
  if(!state.epc || typeof state.epc === 'undefined' || state.epc === null){
    console.log("getting EPC")
    state.epc = await getEPC(location_id, secret);
    props.dispatch(systemActions.updateEPC(state))
    return state.epc;
  }

}

async function updateHistoricalWeatherAlerts(props,location_id,secret) {

  let state =  props.dashboardData.data

  console.log("updating historical weather alerts")
  console.log(state)

 if(!state.historical_weather_alerts || typeof state.historical_weather_alerts === 'undefined'|| state.historical_weather_alerts === null ){

     console.log("update historical_weather_alerts 0")

     let  historical_alerts1 = await getHistoricalWeatherAlerts(location_id,secret)
     state.historical_weather_alerts    =historical_alerts1;
     state.historical_weather_alerts_updated    =new Date().getTime();
      props.dispatch(systemActions.updateHistoricalWeatherAlerts(state))

     return state.historical_alerts;
   }

   


 return state.historical_weather_alerts;

}


// async function updateHistoricalWeatherAlerts(props, location_id) {

//   state.historical_weather_alerts = await getHistoricalWeatherAlerts(location_id);
//   props.dispatch(systemActions.updateHistoricalWeatherAlerts(state));
// }

async function updatePropertyDetails(props,location_id,secret) {

  let state =  props.dashboardData.data

 if(typeof state.property_details === 'undefined'|| state.property_details === null){

     console.log("update property details 0")

     let property_details = await getPropertyDetails(location_id,secret);
     state.property_details    =property_details;
     state.property_details_updated    =new Date().getTime();
      props.dispatch(systemActions.updatePropertyDetails(state))

     return state.property_details;
   }

   date = new Date() - TIME40


 return state.property_details;

}


async function resetLossIncidents(props,location_id,user_id,secret) {

  let state =  props.dashboardData.data



     let  loss_incidents1 = await getLossIncidents(location_id,user_id,secret)
     state.loss_incidents    =loss_incidents1;
     state.loss_incidents_updated    =new Date().getTime();
      props.dispatch(systemActions.updateLossIncidents(state))

     return state.loss_incidents;
   }





async function updateLossIncidents(props,location_id,user_id,secret) {

  let state =  props.dashboardData.data

 if(typeof state.loss_incidents === 'undefined'|| state.loss_incidents === null){

     console.log("update loss_incidents 0")

     let  loss_incidents1 = await getLossIncidents(location_id,user_id,secret)
     state.loss_incidents    =loss_incidents1;
     state.loss_incidents_updated    =new Date().getTime();
      props.dispatch(systemActions.updateLossIncidents(state))

     return state.loss_incidents;
   }

   date = new Date() - TIME15
   
   if((typeof state.loss_incidents_updated === 'undefined') &&  date > state.time_loaded){

     console.log("update loss_incidents 1")


     let  loss_incidents1 = await getLossIncidents(location_id,user_id,secret)
     state.loss_incidents    =loss_incidents1;
     state.loss_incidents_updated    =new Date().getTime();
      props.dispatch(systemActions.updateLossIncidents(state))
     return state.location_data;

   }
   if (state.loss_incidents_updated && date>state.loss_incidents_updated){

     console.log("update loss_incidents 2")


     let  loss_incidents1 = await getLossIncidents(location_id,user_id,secret)
     state.loss_incidents    =loss_incidents1;
     state.loss_incidents_updated    =new Date().getTime();
      props.dispatch(systemActions.updateLossIncidents(state))

     return state.loss_incidents;
   }


 return state.loss_incidents;

}

async function updateFloodRisks(props,location_id,secret) {

  let state =  props.dashboardData.data

 if(typeof state.flood_risks === 'undefined'|| state.flood_risks === null){

     console.log("update flood_risks 0")

     let  flood_risks1 = await getFloodRisks(location_id,secret)
     state.flood_risks    =flood_risks1;
     state.flood_risks_updated    =new Date().getTime();
      props.dispatch(systemActions.updateFloodRisks(state))

     return state.flood_risks;
   }

   date = new Date() - TIME15
   
   if((typeof state.flood_risks_updated === 'undefined') &&  date > state.time_loaded){

     console.log("update flood_risks 1")


     let  flood_risks1 = await getFloodRisks(location_id,secret)
     state.flood_risks    =flood_risks1;
     state.flood_risks_updated    =new Date().getTime();
      props.dispatch(systemActions.updateFloodRisks(state))
     return state.flood_risks;

   }
   if (state.flood_risks_updated && date>state.flood_risks_updated){

     console.log("update flood_risks 2")


     let  flood_risks1 = await getFloodRisks(location_id,secret)
     state.flood_risks    =flood_risks1;
     state.flood_risks_updated    =new Date().getTime();
      props.dispatch(systemActions.updateFloodRisks(state))

     return state.flood_risks;
   }


 return state.flood_risks;

}

async function resetMitigations(props,location_id,secret) {

  let state =  props.dashboardData.data



  let  mitigations1 = await getMitigations(location_id,secret)
  state.mitigations    =mitigations1;
  state.mitigations_updated    =new Date().getTime();
   props.dispatch(systemActions.updateMitigations(state))

  return state.mitigations;
   }

async function updateMitigations(props,location_id,secret) {

  let state =  props.dashboardData.data

 if(typeof state.mitigations === 'undefined'|| state.mitigations === null){

     console.log("update mitigations 0")

     let  mitigations1 = await getMitigations(location_id,secret)
     state.mitigations    =mitigations1;
     state.mitigations_updated    =new Date().getTime();
      props.dispatch(systemActions.updateMitigations(state))

     return state.mitigations;
   }

   date = new Date() - TIME15
   
   if(typeof state.mitigations_updated === 'undefined' &&  date > state.time_loaded){

     console.log("update mitigations 1")


     let  mitigations1 = await getMitigations(location_id,secret)
     state.mitigations    =mitigations1;
     state.location_data_updated    =new Date().getTime();
      props.dispatch(systemActions.updateMitigations(state))
     return state.mitigations;

   }
   if (state.mitigations_updated && date>state.mitigations_updated){

     console.log("update mitigations 2")


     let  mitigations1 = await getMitigations(location_id,secret)
     state.mitigations    =mitigations1;
     state.mitigations_updated    =new Date().getTime();
      props.dispatch(systemActions.updateMitigations(state))

     return state.mitigations;
   }


 return state.mitigations;

}

async function updateCrimeRisks(props,location_id,secret) {

  let state =  props.dashboardData.data

 if(typeof state.crime_risks === 'undefined'|| state.crime_risks === null){

     console.log("update crime_risks 0")

     let  crime_risks1 = await getCrimeRisks(location_id,secret)
     state.crime_risks    =crime_risks1;
     state.crime_risks_updated    =new Date().getTime();
      props.dispatch(systemActions.updateCrimeRisks(state))

     return state.crime_risks;
   }

   date = new Date() - TIME15
   
   if(typeof state.crime_risks_updated === 'undefined' &&  date > state.time_loaded){

     console.log("update crime_risks 1")


     let  crime_risks1 = await getCrimeRisks(location_id,secret)
     state.crime_risks    =crime_risks1;
     state.crime_risks_updated    =new Date().getTime();
      props.dispatch(systemActions.updateCrimeRisks(state))
     return state.crime_risks;

   }
   if (state.crime_risks_updated && date>state.crime_risks_updated){

     console.log("update crime_risks 2")


     let  crime_risks1 = await getCrimeRisks(location_id,secret)
     state.crime_risks    =crime_risks1;
     state.crime_risks_updated    =new Date().getTime();
      props.dispatch(systemActions.updateCrimeRisks(state))

     return state.crime_risks;
   }


 return state.crime_risks;

}

async function updateCovidTiers(props,location_id,secret) {

  let state =  props.dashboardData.data

 if(typeof state.covid_tiers === 'undefined'|| state.covid_tiers === null){

     console.log("update covid_tiers 0")

     let  covid_tiers1 = await getCovidTiers(location_id,secret)
     state.covid_tiers    =covid_tiers1;
     state.covid_tiers_updated    =new Date().getTime();
      props.dispatch(systemActions.updateCovidTiers(state))

     return state.covid_tiers;
   }

   date = new Date() - TIME40


 return state.covid_tiers;

}
