import React, { Component } from "react";
import HistoricalAlertPagination from "./HistoricalAlertPagination";
import HistoricalAlertComponent from "./HistoricalAlertComponent";
import MySelector from "./MySelector";
import "./DisplayHistoricAlertSummary.scss";

const SEVERITY = { SEVERE: 1, WARNING: 2, ALERT: 3 };
const alertSelection = {
  all: "All",
  flood: "Flood",
  rainfall: "Rainfall",
  wind_gust: "Wind Gust",
  heat: "Heat",
  cold: "Cold",
  snowfall: "Snowfall",
  low_visibility: "Low Visibility",
};
const timeSelection = {
  3: "3 months",
  6: "6 months",
  12: "1 year",
  60: "5 years",
  120: "10 years",
  180: "15 years",
  inf: "All time",
};

export class Displayhistoricalertsummary extends Component {
  constructor(props) {
    super(props);
    const alerts = this.mergeAlerts(
      this.props.HistoricalAlerts,
      this.props.HistoricalWeatherAlerts
    ).sort((a, b) => a.timeraised < b.timeraised);
    let selected = this.filterByAlert(alerts, "flood");
    selected = this.filterBySeverity(selected, SEVERITY.ALERT);

    this.state = {
      defaultAlerts: alerts,
      selectedAlerts: selected,
      alertType: "flood",
      alertSeverity: SEVERITY.ALERT,
      alertMonthRange: "inf",
      pageShowAmount: 20,
      pageIndex: 0,
    };
  }

  mergeAlerts = (flood, weather) => {
    const weatherSeverityMap = { yellow: 3, amber: 2, red: 1 };
    const merged = [];
    for (const f of flood) {
      merged.push({
        ...f,
        timeUpdated: f.timeupdated,
        type: "flood",
        measurement: 0,
        calculation: "",
      });
    }
    for (const w of weather) {
      const severity = w.severity[0].toUpperCase() + w.severity.slice(1);
      const GMTDate = new Date(w.start_time).toGMTString();
      merged.push({
        description: `${severity} alert (${w.type}) was raised, reaching ${w.measurements}`,
        isActive: false,
        message: "",
        region: "",
        severityLevel: weatherSeverityMap[w.severity],
        timeEnded: null,
        timeMessageChanged: GMTDate,
        timeRaised: GMTDate,
        timeSeverityChanged: GMTDate,
        timeUpdated: GMTDate,
        type: w.type,
        message: "Alert message not available",
        measurement: w.measurements,
        calculation: w.calculation,
      });
    }
    return merged.sort(
      (a, b) => new Date(b.timeUpdated) - new Date(a.timeUpdated)
    );
  };

  filterBySeverity = (alerts, severity) => {
    return alerts.filter((myAlert) => myAlert.severityLevel === severity);
  };

  filterByAlert = (alerts, eventType) => {
    if (eventType === "all") {
      return alerts;
    } else {
      return alerts.filter((myAlert) => myAlert.type === eventType);
    }
  };

  filterByMonths = (alerts, monthRange) => {
    const millisecondsInMonth = 8.64e7 * 30.43;
    if (monthRange == "inf") {
      return alerts;
    } else {
      const months = parseInt(monthRange);
      return alerts.filter(
        (myAlert) =>
          (Date.now() - new Date(myAlert.timeRaised)) / millisecondsInMonth <
          months
      );
    }
  };

  setMonthRange = (months) => {
    this.setState({ alertMonthRange: months });
    this.updateSelectedAlerts(
      this.state.alertType,
      this.state.alertSeverity,
      months
    );
  };

  setAlertType = (type) => {
    this.setState({ alertType: type });
    this.updateSelectedAlerts(
      type,
      this.state.alertSeverity,
      this.state.alertMonthRange
    );
  };

  setAlertSeverity = (severity) => {
    this.setState({ alertSeverity: severity });
    this.updateSelectedAlerts(
      this.state.alertType,
      severity,
      this.state.alertMonthRange
    );
  };

  updateSelectedAlerts = (alertType, severity, months) => {
    const defaultAlerts = this.state.defaultAlerts;
    let alerts = this.filterByMonths(defaultAlerts, months);
    alerts = this.filterBySeverity(alerts, severity);
    alerts = this.filterByAlert(alerts, alertType);
    this.setState({
      pageIndex: 0,
      alertSeverity: severity,
      selectedAlerts: alerts,
    });
  };

  getAlertTypeCounts = () => {
    let alerts = this.filterByMonths(
      this.state.defaultAlerts,
      this.state.alertMonthRange
    );
    alerts = this.filterByAlert(alerts, this.state.alertType);
    return {
      alert: alerts.reduce(
        (sum, event) => (event.severityLevel === SEVERITY.ALERT ? ++sum : sum),
        0
      ),
      warning: alerts.reduce(
        (sum, event) =>
          event.severityLevel === SEVERITY.WARNING ? ++sum : sum,
        0
      ),
      severe: alerts.reduce(
        (sum, event) => (event.severityLevel === SEVERITY.SEVERE ? ++sum : sum),
        0
      ),
    };
  };

  setPageIndex = (i) => {
    this.setState({ pageIndex: i });
  };

  prettyType = (type) => {
    return type
      .split("_")
      .map((word) => word[0].toUpperCase() + word.substring(1))
      .join(" ");
  };

  showHistoricAlertSummary = () => {
    const counts = this.getAlertTypeCounts();
    return (
      <div className="summary">
        <div className="header">
          <h4 className="header__title">Historic Alert Summary</h4>
          <div className="header__selected" data-testid="selected-type">
            {this.prettyType(this.state.alertType)}
          </div>
          <div className="header__selected" data-testid="selected-time">
            {timeSelection[this.state.alertMonthRange]}
          </div>

          <div className="header__filters">
            <MySelector
              title="Filter by Alert"
              options={alertSelection}
              onSelect={this.setAlertType}
              data_testid="alert-selection"
            />
            <MySelector
              title="Filter by Time"
              options={timeSelection}
              onSelect={this.setMonthRange}
              data_testid="time-selection"
            />
          </div>
        </div>

        <div className="historicalertsummarycontent">
          <div className="historicalertnotices" data-testid="filter-severe">
            <img
              data-testid="filter-severe-button"
              className="historicalertimage"
              src={require("../../images/darkredwarning.png")}
              onClick={() => this.setAlertSeverity(SEVERITY.SEVERE)}
            />
            <h2>{counts.severe}</h2>
            <p>Severe Warnings</p>
          </div>

          <div className="historicalertnotices" data-testid="filter-warning">
            <img
              data-testid="filter-warning-button"
              className="historicalertimage"
              src={require("../../images/redwarning.png")}
              onClick={() => this.setAlertSeverity(SEVERITY.WARNING)}
            />
            <h2>{counts.warning}</h2>
            <p>Warnings</p>
          </div>

          <div className="historicalertnotices" data-testid="filter-alert">
            <img
              data-testid="filter-alert-button"
              className="historicalertimage"
              src={require("../../images/amberwarning.png")}
              onClick={() => this.setAlertSeverity(SEVERITY.ALERT)}
            />
            <h2>{counts.alert}</h2>
            <p>Alerts</p>
          </div>
        </div>

        <HistoricalAlertPagination
          index={this.state.pageIndex}
          setIndex={this.setPageIndex}
          maxPages={Math.floor(
            this.state.selectedAlerts.length / this.state.pageShowAmount
          )}
        />
      </div>
    );
  };

  render() {
    const start = Math.max(this.state.pageIndex * this.state.pageShowAmount, 0);
    const end = Math.min(
      this.state.pageIndex * this.state.pageShowAmount +
        this.state.pageShowAmount,
      this.state.selectedAlerts.length
    );
    const alerts = this.state.selectedAlerts.slice(start, end);

    console.log(alerts[0]);
    return (
      <div>
        {this.showHistoricAlertSummary()}
        {alerts.length > 0
          ? alerts.map((event, index) => (
              <HistoricalAlertComponent
                key={index}
                type={event.type}
                severity={event.severityLevel}
                date={event.timeUpdated ? event.timeUpdated : event.timeRaised}
                description={event.description}
                text={event.message}
                measurement={event.measurement}
                calculation={event.calculation}
              />
            ))
          : ""}
      </div>
    );
  }
}

export default Displayhistoricalertsummary;
